import {
  CalculationsCollection,
  CurrentSetupCollection,
  TemplatesCollection,
} from '../../swagger/collections';
import { signalrClient } from '../../signalr/client';
import { apiSlice, APITAGS, TemplateOrCalculation, wrongTemplateInHeaders } from '../../apis/api';
import { ApiOperationType, TargetListResponse } from '@ydistri/api-sdk';
import { SignalConfigurationChanged } from '../../signalr/signalrInterfaces';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { apiTargetLists } from '../ProjectAdministration/TargetLists/apiTargetLists';
import { getTags } from '../../apis/apiLib';
import { ReduxState } from '../../store';
import { apiEntityLists } from '../ProjectAdministration/EntityListAdministration/apiEntityLists';

const { TAGS, TAGS_ARRAY } = getTags('targetListConfiguration');

interface TargetListConfigurationPayload {
  templateId: number;
  targetListId: number;
}

/**
 * Manages the target list configuration for the current setup.
 * There may be more target lists used in configuration but the UI only allows one to be selected.
 * The API is designed to support multiple target lists in the future.
 *
 * - getTargetListsForConfiguration: returns the list of target lists that are currently configured.
 * - removeTargetListForConfiguration: removes the target list from the configuration. Needs a targetListId that is currently configured.
 * - addTargetListForConfiguration: adds the target list in the configuration. Needs a targetListId that is not currently configured.
 * - replaceTargetListForConfiguration: encapsulates the remove and set operations in a single call to avoid
 * a visible change to "none" and then back to the new target list in the UI.
 */
export const apiTargetListConfiguration = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      getTargetListsForConfiguration: builder.query<TargetListResponse[], TemplateOrCalculation>({
        queryFn: async ({ type, id }) => {
          if (wrongTemplateInHeaders({ type, id })) return { data: [] };
          const { data } = await (type === 'Calculation' && id
            ? CalculationsCollection.getCalculationTargetLists(id)
            : CurrentSetupCollection.getCurrentTargetListConfigurations());

          return data;
        },
        providesTags: [TAGS.availableTargetLists],
        async onCacheEntryAdded({ id }, api) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              if (id !== signal.templateId) return;

              if (signal.entityType === 'TargetListConfiguration') {
                console.log('TargetListConfiguration signal', signal);
                api.updateCachedData(data => {
                  switch (signal.operationType) {
                    case ApiOperationType.Create: {
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      const state = api.getState() as ReduxState;
                      const queryKeys = apiTargetLists.util.selectCachedArgsForQuery(
                        state,
                        'getTargetLists',
                      );

                      const response = [...data];
                      const newTargetList = queryKeys.find(e => {
                        const targetList = apiTargetLists.endpoints.getTargetLists
                          .select(e)(state)
                          .data?.find(x => x.targetListId === signal.entityId);

                        if (targetList) {
                          response.push(targetList);
                        }
                        return !!targetList;
                      });

                      if (!newTargetList) {
                        //we should have the cache current most of the time due to signalr subscription
                        //so this is just a fallback in case the cache is out of sync
                        //target list not found in our cache, invalidate the tag to force a reload
                        api.dispatch(
                          apiTargetLists.util.invalidateTags([
                            APITAGS.targetLists.targetListResponse,
                          ]),
                        );
                        api.dispatch(
                          apiTargetListConfiguration.util.invalidateTags([
                            TAGS.targetListConfiguration,
                          ]),
                        );
                      }

                      return response;
                    }
                    case ApiOperationType.Delete:
                      return data.filter(dataItem => dataItem.targetListId !== signal.entityId);
                    default:
                      break;
                  }
                });
              }
            };

            signalrClient.on('TemplateConfigurationChanged', listener);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener);
            });
          } catch (error) {
            console.error(error);
          }
        },
      }),
      removeTargetListForConfiguration: builder.mutation<number, TargetListConfigurationPayload>({
        queryFn: async ({ templateId, targetListId }) => {
          await TemplatesCollection.deleteTemplatesTargetList(templateId, targetListId);
          return { data: targetListId };
        },
        invalidatesTags: [TAGS.targetListConfiguration],
      }),
      addTargetListForConfiguration: builder.mutation<
        TargetListResponse,
        TargetListConfigurationPayload
      >({
        queryFn: async ({ templateId, targetListId }) => {
          const response = await TemplatesCollection.postTemplatesTargetList(
            templateId,
            targetListId,
            {},
          );
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.targetListConfiguration],
      }),
      replaceTargetListForConfiguration: builder.mutation<
        TargetListResponse,
        TargetListConfigurationPayload
      >({
        queryFn: async ({ templateId, targetListId }) => {
          const response = await TemplatesCollection.patchTemplatesTargetList(
            templateId,
            targetListId,
            {},
          );
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.targetListConfiguration],
      }),
    }),
  });

export const {
  useGetTargetListsForConfigurationQuery,
  useRemoveTargetListForConfigurationMutation,
  useAddTargetListForConfigurationMutation,
  useReplaceTargetListForConfigurationMutation,
} = apiTargetListConfiguration;
