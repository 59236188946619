import React, { useCallback, useMemo } from 'react';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';
import { useScope } from './useScope';
import { Button, Column, Row, Text } from '@ydistri/ds';
import { scopeEntityTitle, ViewMode } from './scopeLib';
import { iterableConfigurationRuleScopeEntityType } from '../../../helpers/types/iterableEnums';
import { useScopeOptionsData } from './useScopeOptionsData';
import { MdWarning } from 'react-icons/md';
import { Popover } from 'antd';
import { plural } from '@ydistri/utils';
import { updateScopeEntitySelectedIds } from './scopeConfigurationSlice';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useOpenScopeModal } from './useOpenScopeModal';
import { ReduxState } from '../../../store';

interface ScopeMissingEntityViewProps {
  scopeId: number;
  viewMode: ViewMode;
}

type MissingDataIds = Record<
  ConfigurationRuleScopeEntityType,
  { missing: number[]; fixed: number[] }
>;

interface MissingData {
  ids: MissingDataIds;
  missingStrings: string[];
  totalCount: number;
}

const ScopeMissingEntityView: React.FC<ScopeMissingEntityViewProps> = ({ scopeId, viewMode }) => {
  const scopeById = useScope(scopeId);
  const modalScope = useSelector((state: ReduxState) => state.scope.modalScope);

  const scope = useMemo(
    () =>
      [ViewMode.ADJUST_SCOPE_MODAL, ViewMode.NEW_EXCEPTION_MODAL].includes(viewMode)
        ? modalScope
        : scopeById,
    [modalScope, scopeById, viewMode],
  );

  const dispatch = useDispatch();
  const openModalHandler = useOpenScopeModal(scope);

  const { regionOptions, storeOptions, storeTypeOptions, departmentOptions, loading } =
    useScopeOptionsData(scopeId, viewMode);

  const missingData: MissingData = useMemo(() => {
    const md: Partial<MissingDataIds> = {};
    const strings: string[] = [];
    let totalCount = 0;

    iterableConfigurationRuleScopeEntityType.forEach(et => {
      md[et] = { missing: [], fixed: [] };
      if (loading) {
        return;
      }

      const selectedIds = scope?.entities[et].selectedIds ?? [];

      let options =
        et === ConfigurationRuleScopeEntityType.Stores ? storeOptions : storeTypeOptions;
      if (et === ConfigurationRuleScopeEntityType.Departments) {
        options = departmentOptions;
      } else if (et === ConfigurationRuleScopeEntityType.Regions) {
        options = regionOptions;
      }

      selectedIds.forEach(id => {
        if (options.find(o => id === o.id)) {
          md[et]?.fixed.push(id);
        } else {
          md[et]?.missing.push(id);
        }
      });

      if ((md[et]?.missing.length ?? 0) > 0) {
        strings.push(
          `${md[et]?.missing.length} ${plural(
            md[et]?.missing.length ?? 0,
            scopeEntityTitle[et].toLowerCase(),
          )}`,
        );
        totalCount += md[et]?.missing.length ?? 0;
      }
    });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return { ids: md as MissingDataIds, missingStrings: strings, totalCount };
  }, [loading, scope?.entities, storeOptions, storeTypeOptions, departmentOptions, regionOptions]);

  const text = `There ${missingData.totalCount === 1 ? 'is' : 'are'} ${missingData.missingStrings
    .map((s, i) => {
      const len = missingData.missingStrings.length;
      if (i < len - 2 && len > 2) return s + ', ';
      if (i < len - 1 || len === 1) return s;

      return ' and ' + s;
    })
    .join()} that ${missingData.totalCount === 1 ? 'is' : 'are'} not in scope`;

  const fixOutOfScopeDataHandler = useCallback(() => {
    batch(() => {
      iterableConfigurationRuleScopeEntityType.forEach(et => {
        if (missingData.ids[et].missing.length > 0) {
          dispatch(
            updateScopeEntitySelectedIds({
              entityType: et,
              selectedIds: missingData.ids[et].fixed,
            }),
          );
        }
      });
    });
  }, [dispatch, missingData.ids]);

  if (missingData.totalCount === 0) return null;

  if (viewMode === ViewMode.EXCEPTION) {
    return (
      <Popover content={text}>
        <Button onClick={openModalHandler} $colorVariant="selected">
          <MdWarning />
          Warning
        </Button>
      </Popover>
    );
  }

  return (
    <Column $gap="0.5rem">
      <Row $gap="0.25rem" $flexGrow={1} $flexWrap="wrap" $alignItems="center">
        <Text $type="danger">{text}</Text>
        <Button onClick={fixOutOfScopeDataHandler} $sizeVariant="small" $colorVariant="ghostDanger">
          Clean up scope
        </Button>
      </Row>
    </Column>

    // options
    //   .filter(option => selectedIds.includes(option.id))
    //   .map(option => <Tag>{option.title}</Tag>)
  );
};

export default ScopeMissingEntityView;
