import React from 'react';
import { Chart, ChartDataset, TooltipModel } from 'chart.js';
import { formatNumber, fullMonthDiff, padLeft } from '@ydistri/utils';
import './saleChartTooltip.scss';
import { computeRemSize } from '@ydistri/ds';
import {
  LINE_TYPES,
  lineTypeTooltipConfig,
  SkuSaleGraphValueColumnType,
  TLineType,
  TParsedTransactions,
} from '../../../lib/charts/saleChartsTypes';
import { NonSaleDataByDay, ParseTransactionsInput } from '../../../lib/charts/saleChartsLib';

const addVat = (value: number, vat: number) =>
  !isNaN(vat) ? Math.floor((value + (value / 100) * vat) * 100) / 100 : value;

// eslint-disable-next-line max-params
export default function saleChartTooltip(
  tooltipModel: TooltipModel<'line'>,
  lines: TLineType[],
  datasets: ChartDataset[],
  parsedTransactions: TParsedTransactions[],
  applicationDate: Date,
  chartRef: React.RefObject<Chart<'line'>>,
  currency: string,
  vat: number,
  valueColumnType: SkuSaleGraphValueColumnType,
  chartType: ParseTransactionsInput['type'] = 'monthly',
  nonSaleDataByDay: NonSaleDataByDay,
): Date | undefined {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.className = 'graph-tooltip';
    document.body.appendChild(tooltipEl);
  }
  tooltipEl.innerHTML = '<div class="innerDivStyle"></div><table></table>';

  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltipModel.dataPoints.length > 0) {
    const dataPoint = tooltipModel.dataPoints[0];

    const monthIndex: number = dataPoint.dataIndex || 0;
    const saleData: TParsedTransactions = parsedTransactions[monthIndex];

    const dateFrom = saleData.dates.dateFrom.toLocaleDateString();
    const dateTo = saleData.dates.dateTo.toLocaleDateString();

    const isFuture = saleData.dates.dateTo > applicationDate;
    const spaceRow = `<tr><td colSpan="5" style={{ paddingBottom: '0' }}></td></tr>`;

    const datePart = chartType === 'monthly' ? `(${dateFrom} - ${dateTo})` : dateFrom;

    let totalQuantityForSalesCumulative = 0;

    const tableParts = LINE_TYPES.map(type => {
      const lineTooltipConfig = lineTypeTooltipConfig[type];
      let tablePart = '';
      let lineTypeHasData = false;

      let totalQuantityForSales = 0;
      let totalValueForSalesCumulative = 0;

      const valueColumn =
        type === 'Sale'
          ? `<td style="text-align: right">${valueColumnType ? 'Avg. price' : 'Value'}</td>`
          : '<td></td>';

      const header = `<tr class="underline header"><td colspan="2">${lineTooltipConfig.title}</td><td style="text-align: right">Qty</td>${
        isFuture ? '<td style="text-align: right">Cumulative</td>' : ''
      }${valueColumnType === 'none' ? '' : valueColumn}</tr>`;

      let noOfVisibleLines = 0;

      lines.forEach((line, i) => {
        const lineData = saleData.data[line.name];
        let lineRow = '';
        const info = 'lineInfo' in line ? line.lineInfo : line.barInfo;

        if (line.type === type && lineData && info.hidden !== true) {
          //Quantity for given month - in case of "SaleUsedForForecast", we must take "QuantityToThisPriority"
          let quantityMonth = null;

          if (line.name === 'SaleUsedForForecast') {
            if (lineData.quantityToThisPriority !== null && lineData.quantityToThisPriority > 0) {
              quantityMonth = lineData.quantityToThisPriority;
            }
          } else {
            quantityMonth = lineData.quantity;
          }

          //we check if there is data for this month from this line type (so we wont show "forecast" in tooltips before calculation date and sales after calculation date if there are none)
          if (quantityMonth !== null) {
            lineTypeHasData = true;
          }

          //cumulative quantity, shown only in case of "future"
          const quantityCumulative = lineData.quantityCumulative;

          //avg value of sales - not shown in case of row "sales used for forecast", "dash" in case of Quantity = 0, value with currency otherwise
          let avgValue = '';

          if (line.name !== 'SaleUsedForForecast') {
            if (quantityMonth !== null && quantityMonth > 0) {
              avgValue = addVat(lineData.avgValue, vat).toString();
            } else {
              avgValue = '-';
            }
          }

          const value = lineData.value ?? 0;

          //for TOTAL sale quantity
          if (quantityMonth !== null && line.name !== 'SaleUsedForForecast' && type === 'Sale') {
            noOfVisibleLines++;
            totalQuantityForSales += quantityMonth;
            totalQuantityForSalesCumulative += lineData.quantityCumulative ?? 0;
            totalValueForSalesCumulative += value;
          }

          lineRow += `<tr>`;

          //DIV styled to look like line style used in graph
          let height;
          let border = '';
          let backgroundColor = '';

          const shouldFill = 'fill' in info ? info.fill !== false : false;
          const isDashed = 'borderDash' in info ? (info.borderDash ?? []).length > 0 : false;

          if (shouldFill) {
            height = computeRemSize(12);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            border = `border: ${computeRemSize((info.borderWidth ?? 1) as number)} ${
              isDashed ? 'dashed' : 'solid'
            } ${info.borderColor?.toString() ?? 'black'}`;
            backgroundColor = `background-color: ${info.backgroundColor?.toString() ?? 'black'}`;
          } else {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            height = computeRemSize((info.borderWidth ?? 2) as number);
            if (isDashed) {
              border = `border-left: ${computeRemSize(8)} solid ${
                info.borderColor?.toString() ?? 'black'
              }; border-right: ${computeRemSize(8)} solid ${
                info.borderColor?.toString() ?? 'black'
              }; background-color: white;`;
            } else {
              backgroundColor = `background-color: ${info.borderColor?.toString() ?? 'black'};`;
            }
          }

          lineRow += `<td><div style="
              width: ${computeRemSize(20)};
              height: ${height};
              ${border};
              ${backgroundColor};">
              &nbsp;
            </div></td>`;

          lineRow += `<td class="header">${line.label}</td>`;
          lineRow += `<td style="text-align: right">${formatNumber(quantityMonth ?? 0)}</td>`;
          if (isFuture) {
            lineRow += `<td style="text-align: right">${formatNumber(
              quantityCumulative ?? 0,
            )}</td>`;
          }
          if (
            type === 'Sale' &&
            line.name !== 'SaleUsedForForecast' &&
            valueColumnType !== 'none'
          ) {
            let cellContent;

            if (valueColumnType === 'avg') {
              if (isNaN(+avgValue)) {
                cellContent = avgValue;
              } else {
                cellContent = formatNumber(avgValue, 2);
              }
            } else {
              cellContent = formatNumber(value);
            }

            const cell = `<td style="text-align: right">${cellContent} ${currency}</td>`;
            lineRow += cell;
          }
          lineRow += '</tr>';
        }

        if (lineRow.length > 0) {
          if (line.name === 'SaleUsedForForecast' && isFuture) {
            lineRow = '';
          }
        }

        if (lineTooltipConfig.showTotal) {
          const isLastLine =
            lines.find(tmpLine => tmpLine.name === 'SaleUsedForForecast') === undefined &&
            i + 1 === lines.length;
          if ((line.name === 'SaleUsedForForecast' && noOfVisibleLines > 0) || isLastLine) {
            let totalLine = `<tr class="overline"><td></td><td class="header">Total</td><td style="text-align: right">${formatNumber(
              totalQuantityForSales,
            )}</td>`;
            if (isFuture) {
              totalLine += `<td style="text-align: right">${totalQuantityForSalesCumulative}</td><td></td>`;
            } else {
              if (valueColumnType !== 'none') {
                totalLine += `<td colspan="2" style="text-align: right">${
                  valueColumnType === 'avg'
                    ? ''
                    : `${formatNumber(totalValueForSalesCumulative)} ${currency}`
                }</td>`;
              }
            }
            totalLine += `</tr>`;
            if (line.name === 'SaleUsedForForecast') {
              lineRow = totalLine + lineRow;
            } else if (isLastLine) {
              lineRow += totalLine;
            }
          }
        }

        if (type === 'InboundOutbound' && (lineData?.quantity ?? 0) > 0) {
          if (line.name === 'Outbound') {
            (nonSaleDataByDay[saleData.dates.stringFrom]?.outbounds ?? []).forEach(l => {
              lineRow += `<tr style="text-align: right; font-size: 0.85rem; color: #666"><td></td><td><i>${l.customerTypeId}</i></td><td><i>${l.quantity}</i></td></tr>`;
            });
          }
          if (line.name === 'Inbound') {
            (nonSaleDataByDay[saleData.dates.stringFrom]?.inbounds ?? []).forEach(l => {
              lineRow += `<tr style="text-align: right; font-size: 0.85rem; color: #666"><td></td><td><i>${l.customerTypeId}</i></td><td><i>${l.quantity}</i></td></tr>`;
            });
          }
        }

        if (lineRow.length > 0) {
          tablePart += lineRow;
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (lineTypeHasData) {
        tablePart = spaceRow + header + tablePart;
      } else {
        tablePart = '';
      }

      return tablePart;
    });

    const tableRoot = tooltipEl.querySelector('table');
    const titleRoot = tooltipEl.querySelector('div');
    if (titleRoot) {
      let title;
      if (applicationDate <= saleData.dates.dateFrom) {
        title = `OUTLOOK ${fullMonthDiff(applicationDate, saleData.dates.dateTo) + 1}`;
      } else {
        if (chartType === 'monthly') {
          title = `${saleData.dates.dateTo.getFullYear()} / ${padLeft(
            String(saleData.dates.dateTo.getMonth() + 1),
            '0',
            2,
          )}`;
        } else {
          title = datePart;
        }
      }
      if (chartType === 'monthly') {
        title += `<br /><span style="font-size: ${computeRemSize(
          10,
        )}; font-style: italic; color: darkgray;">${datePart}</span>`;
      }
      titleRoot.innerHTML = title;
    }

    if (tableRoot) {
      tableRoot.innerHTML = tableParts.join('');
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.pointerEvents = 'none';
    if (chartRef.current !== null) {
      const chartPosition = chartRef.current.canvas.getBoundingClientRect();

      // set position of tooltip
      tooltipEl.style.top = computeRemSize(chartPosition.top + 40);
      if (tooltipModel.caretX > (chartPosition.width * 2) / 3 - chartPosition.width / 8) {
        tooltipEl.style.right = computeRemSize(
          document.body.clientWidth -
            chartPosition.right +
            (chartPosition.width - tooltipModel.caretX) +
            20,
        );
        tooltipEl.style.left = 'auto';
      } else {
        tooltipEl.style.left = computeRemSize(chartPosition.left + tooltipModel.caretX + 15);
        tooltipEl.style.right = 'auto';
      }
    }
    return saleData.dates.dateFrom;
  }
}
