import React from 'react';
import NoAdministrationItemSelected from '../../common/NoAdministrationItemSelected';
import { getDefaultEntityListsParams, useGetEntityListsQuery } from '../apiEntityLists';
import { EntityListType } from '@ydistri/api-sdk';
import { titleByEntityListType } from '../entityListsLib';

interface NoEntityListSelectedProps {
  entityListType: EntityListType;
}

/**
 * Component for main part of the screen when no Entity List is selected.
 * Displays a message based on whether there are any Entity Lists or not.
 * @constructor
 */
const NoEntityListSelected: React.FC<NoEntityListSelectedProps> = React.memo(
  ({ entityListType }) => {
    const { data: entityLists } = useGetEntityListsQuery(
      getDefaultEntityListsParams(entityListType),
    );
    return (
      <NoAdministrationItemSelected
        message={`No ${titleByEntityListType[entityListType]} selected`}
        secondaryMessage={
          entityLists && entityLists.length > 0
            ? `Click on a ${titleByEntityListType[entityListType]} from the list`
            : `Click on the 'Create new ${titleByEntityListType[entityListType]}' button on the left to create new ${titleByEntityListType[entityListType]}`
        }
      />
    );
  },
);

NoEntityListSelected.displayName = 'NoEntityListSelected';
export default NoEntityListSelected;
