import { Text } from '@ydistri/ds';
import React from 'react';

interface CalculationDescriptionProps {
  value: string;
  $width?: string;
  $maxWidth?: string;
}

const CalculationDescription: React.FC<CalculationDescriptionProps> = ({
  value,
  $width,
  $maxWidth,
}) => {
  return (
    <Text $width={$width} $maxWidth={$maxWidth} $type="light">
      {value}
    </Text>
  );
};

export default CalculationDescription;
