import { IconButton, IconButton_IconSizes, IconButtonProps, IconButtonSize } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { MdAutorenew } from 'react-icons/md';
interface LoadIntoTemplateActionProps {
  onClick: () => void;
  disabled?: boolean;
  tooltipPosition?: IconButtonProps['tooltipPosition'];
  size?: IconButtonSize;
}

const LoadIntoTemplateAction: React.FC<LoadIntoTemplateActionProps> = ({
  onClick,
  disabled,
  tooltipPosition = 'top',
  size = 'normal',
}: LoadIntoTemplateActionProps) => {
  const icon = useMemo(() => <MdAutorenew size={IconButton_IconSizes[size]} />, [size]);

  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      onClick();
    },
    [onClick],
  );

  return (
    <IconButton
      tooltip="Load into template..."
      tooltipPosition={tooltipPosition}
      icon={icon}
      size={size}
      onClick={clickHandler}
      disabled={disabled}
    />
  );
};

export default LoadIntoTemplateAction;
