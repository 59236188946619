import { apiSlice, TemplateOrCalculation } from '../../../apis/api';
import { CalculationsCollection, CurrentSetupCollection } from '../../../swagger/collections';
import {
  parseCategoryAttributeValues,
  ParsedCategoryInsights,
  parseCategorySummaries,
  parseCategoryRedistribution,
} from './categoryInsightsLib';
import { getTags } from '../../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('categoryInsights');

export interface GetCategoryInsightsParams {
  templateOrCalculation: TemplateOrCalculation;
  categoryId: number;
}

export const apiCategoryInsights = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getCategoryInsights: builder.query<ParsedCategoryInsights, GetCategoryInsightsParams>({
        queryFn: async ({ templateOrCalculation, categoryId }) => {
          const { data: categoryData } = await (templateOrCalculation.type === 'Template'
            ? CurrentSetupCollection.getCurrentCategoryInsights(categoryId)
            : CalculationsCollection.getCalculationCategoryInsights(
                templateOrCalculation.id,
                categoryId,
              ));

          const parsedData: ParsedCategoryInsights = {
            summaries: parseCategorySummaries(categoryData.data.summaries),
            attributes: parseCategoryAttributeValues(categoryData.data.attributeValues),
            redistributions: categoryData.data.categoryRedistributions
              ? parseCategoryRedistribution(categoryData.data.categoryRedistributions)
              : undefined,
          };

          return { data: parsedData };
        },
        providesTags: [TAGS.categoryInsights],
      }),
    }),
  });

export const { useGetCategoryInsightsQuery } = apiCategoryInsights;
