import React, { useMemo } from 'react';
import { ExcessiveStockPotentialInfo, LOCKED_BY_TITLES } from '../../categoryInsightsLib';
import { ExcessiveStockBarSection } from './excessiveStockBarLib';
import { useCurrency } from '../../../../../hooks/useCurrency';
import { ArrayTooltipContent } from '@ydistri/ds';

interface ExcessiveStockBarTooltipContentProps {
  data: ExcessiveStockPotentialInfo;
  section: ExcessiveStockBarSection;
}

const ExcessiveStockBarTooltipContent: React.FC<ExcessiveStockBarTooltipContentProps> = ({
  data,
  section,
}) => {
  const currency = useCurrency();
  const sum = section.fields.reduce((p, c) => p + data[c], 0);

  const arrayTooltipData = useMemo(() => {
    return [
      {
        title: section.title,
        value: sum,
        isTitle: true,
        postfix: currency,
      },
      ...(section.fields.length > 1
        ? section.fields.map(s => ({
            title: LOCKED_BY_TITLES[s],
            value: data[s],
            postfix: currency,
          }))
        : []),
    ];
  }, [currency, data, section.fields, section.title, sum]);

  return <ArrayTooltipContent data={arrayTooltipData} />;
};

export default ExcessiveStockBarTooltipContent;
