import { styled, css } from 'styled-components';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import OverlayMessage, { OverlayDiv } from './ContentOverlay/OverlayComponents';

const OverlayWrapper = styled.div<{
  $isFlex: boolean;
  $isRow: boolean;
  $isCentered: boolean;
}>`
  ${p =>
    p.$isFlex
      ? css`
          display: flex;
          flex-direction: ${p.$isRow ? 'row' : 'column'};
        `
      : css`
          display: block;
        `};

  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

interface OverlayProps extends PropsWithChildren {
  active?: boolean;
  /** Either a text or custom component to be displayed. If string, it will be wrapped in a <OverlayMessage> */
  overlayContent?: ReactNode;
  contentIsFlex?: boolean;
  contentIsRow?: boolean;
  contentIsCentered?: boolean;
}

export const Overlay: React.FC<OverlayProps> = ({
  children,
  active,
  overlayContent,
  contentIsFlex = true,
  contentIsRow = true,
  contentIsCentered = true,
}) => {
  const content = useMemo(() => {
    if (typeof overlayContent === 'string') {
      return <OverlayMessage title={overlayContent} />;
    } else {
      return overlayContent;
    }
  }, [overlayContent]);

  return (
    <OverlayWrapper
      data-testid="overlay-wrapper"
      $isFlex={contentIsFlex}
      $isRow={contentIsRow}
      $isCentered={contentIsCentered}
    >
      {active && <OverlayDiv data-testid="overlay-div">{content}</OverlayDiv>}
      {children}
    </OverlayWrapper>
  );
};
