import React, { useCallback, useMemo } from 'react';
import { BalancingFieldType } from './balancingLib';
import { CalculationConfigurationExperimentalType } from '@ydistri/api-sdk';
import { ConfigurationInput, ConfigurationSwitch } from '@ydistri/ds';
import { rtkQueryPatchStatusToConfigurationFieldStatus } from '@ydistri/utils';
import { usePatchBalancingConfigurationMutation } from './apiBalancingConfiguration';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { Popover } from 'antd';

interface BalancingFieldProps {
  dataType: BalancingFieldType;
  field: CalculationConfigurationExperimentalType;
  value: number;
  itemSequence: number;
  disabled?: boolean;
  customSuffix?: React.ReactNode;
  min?: number;
  max?: number;
  replaceWith?: string;
}

const BalancingField: React.FC<BalancingFieldProps> = ({
  disabled = false,
  dataType,
  field,
  value,
  itemSequence,
  customSuffix,
  min,
  max,
  replaceWith,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [patchConfiguration, patchConfigurationStatus] = usePatchBalancingConfigurationMutation();

  const status = useMemo(
    () => rtkQueryPatchStatusToConfigurationFieldStatus(patchConfigurationStatus),
    [patchConfigurationStatus],
  );

  const updateConfigHandler = useCallback(
    (value: number | string) => {
      let numberValue: number = typeof value === 'number' ? value : parseFloat(value);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }

      patchConfiguration({
        templateOrCalculation,
        data: {
          key: field,
          /** @format int32 */
          itemSequence,
          value: numberValue.toFixed(dataType === 'Decimal' ? 2 : 0),
        },
      });
    },
    [patchConfiguration, templateOrCalculation, field, itemSequence, dataType],
  );

  const popoverText = `${min !== undefined ? `Min: ${min}; ` : ''}${
    max !== undefined ? `Max: ${max}; ` : ''
  }${dataType === 'Decimal' ? `Decimals allowed ` : ''}`;

  switch (dataType) {
    case 'Boolean':
      return (
        <ConfigurationSwitch
          status={status}
          value={value === 1}
          disabled={disabled}
          onChange={updateConfigHandler}
        />
      );
    case 'Decimal':
    case 'Int':
      if (replaceWith) {
        return (
          <ConfigurationInput disabled={true} placeholder={replaceWith} status={status} value={0} />
        );
      }
      return (
        <Popover content={popoverText !== '' ? popoverText : undefined} placement="right">
          <div>
            <ConfigurationInput
              status={status}
              disabled={disabled}
              value={value}
              onChange={updateConfigHandler}
              customSuffix={customSuffix}
              displayZero={true}
              allowDecimals={dataType === 'Decimal'}
              min={min}
              max={max}
            />
          </div>
        </Popover>
      );
  }
};

export default BalancingField;
