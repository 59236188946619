import { SectionHeaderRowSpaceBetweenNoBorder } from '@ydistri/ds';
import React, { useMemo } from 'react';
import MenuSubpages from '../../../components/menu/MenuSubpages';
import { RoutingPage } from '../../../routes/routerSlice';
import { ProjectAdministrationSubpage } from '../projectAdministrationLib';
import useSubpage from '../../../hooks/useSubpage';

const getMenuOptions = () => {
  return [
    {
      value: ProjectAdministrationSubpage.PRODUCTLISTS,
      label: 'Product lists',
    },
    {
      value: ProjectAdministrationSubpage.TARGETLISTS,
      label: 'Target lists',
    },
    {
      value: ProjectAdministrationSubpage.MINLAYERLISTS,
      label: 'Min layer lists',
    },
  ];
};

/**
 * Contains navigation to subpages within Administration part of the app.
 */
const AdministrationHeader: React.FC = () => {
  const subpage = useSubpage();

  const menuOptions = useMemo(() => getMenuOptions(), []);

  return (
    <SectionHeaderRowSpaceBetweenNoBorder>
      <MenuSubpages
        routingPage={RoutingPage.PROJECT_ADMINISTRATION}
        submenuOptions={menuOptions}
        selectedOption={subpage}
      />
    </SectionHeaderRowSpaceBetweenNoBorder>
  );
};

export default AdministrationHeader;
