import React from 'react';

import { useDetailSkuEntity } from '../../../../hooks/useDetail';
import DetailSkuAttributes from './DetailSkuAttributes';

const DetailSkuAttributesController: React.FC = () => {
  const skuEntity = useDetailSkuEntity();

  if (skuEntity) {
    return <DetailSkuAttributes skuEntity={skuEntity} />;
  } else {
    return null;
  }
};

export default DetailSkuAttributesController;
