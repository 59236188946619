import React, { useMemo } from 'react';
import { AdministrationItemAction } from '../administrationItemsTypes';
import { styled } from 'styled-components';
import { Text } from '@ydistri/ds';
import { Popover } from 'antd';

interface ValidatedItemActionProps {
  error?: boolean;
  duplicate?: boolean;
  action?: AdministrationItemAction;
  tooltip?: string;
}

const SuccessText = styled(Text).attrs({ $semiBold: true })`
  color: green;
`;
const ErrorText = styled(Text)`
  color: red;
`;
const UpdatedText = styled(Text).attrs({ $semiBold: true })`
  color: #f5940d;
`;

const ValidatedItemAction: React.FC<ValidatedItemActionProps> = ({
  action = AdministrationItemAction.NONE,
  error = false,
  duplicate = false,
  tooltip,
}) => {
  const component = useMemo(() => {
    if (!error) {
      switch (action) {
        case AdministrationItemAction.REMOVE:
          return <ErrorText>- REMOVE</ErrorText>;
        case AdministrationItemAction.INSERT:
          return <SuccessText>+ INSERT</SuccessText>;
        case AdministrationItemAction.UPDATE:
          return <UpdatedText>+ UPDATE</UpdatedText>;
        case AdministrationItemAction.NONE:
          return <Text>- NO CHANGE</Text>;
        default:
          return null;
      }
    } else {
      if (duplicate) {
        return <Text>- DUPLICATE</Text>;
      } else {
        return <ErrorText>- SKIP</ErrorText>;
      }
    }
  }, [action, duplicate, error]);

  if (tooltip && tooltip.length > 0) {
    return (
      <Popover content={tooltip} placement="topRight">
        {component}
      </Popover>
    );
  } else {
    return component;
  }
};

export default ValidatedItemAction;
