import { Select, SelectProps } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Column, Text } from '@ydistri/ds';
import { CalculationMode } from '@ydistri/api-sdk';
import { styled } from 'styled-components';

const SelectWrapper = styled(Select<CalculationMode>)`
  min-width: 10rem;
`;

type CalculationModeHints = Record<CalculationMode, string>;

const calculationModeHints: CalculationModeHints = {
  [CalculationMode.Computed]: 'Calculation computed from single template.',
  [CalculationMode.Manual]: 'Calculation is imported manually and is not computed.',
  [CalculationMode.Merged]:
    'Calculation computed and merged from multiple templates. Add templates to this calculation below.',
};

interface CalculationModeSelectProps {
  value: CalculationMode;
  onChange: (value: CalculationMode) => void;
  includeManualCalculation?: boolean;
}

const CalculationModeSelect: React.FC<CalculationModeSelectProps> = ({
  value = CalculationMode.Computed,
  onChange,
  includeManualCalculation = false,
}) => {
  const options: SelectProps['options'] = useMemo(() => {
    const opts: SelectProps['options'] = [
      {
        label: `General calculation`,
        value: CalculationMode.Computed,
      },
      {
        label: 'Merged calculation',
        value: CalculationMode.Merged,
      },
    ];

    if (includeManualCalculation) {
      opts.push({
        label: 'Manual calculation',
        value: CalculationMode.Manual,
      });
    }

    return opts;
  }, [includeManualCalculation]);

  const changeHandler = useCallback(
    (newValue: CalculationMode) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Column $gap="0.5rem">
      <SelectWrapper
        defaultValue={value}
        value={value}
        options={options}
        onChange={changeHandler}
        variant="outlined"
      />
      <Text>{calculationModeHints[value]}</Text>
    </Column>
  );
};

export default CalculationModeSelect;
