import { useCallback, useMemo } from 'react';

export type ValidationLevel = 'error' | 'warning' | 'info';

export enum ValidationSection {
  GLOBAL = 'global',
  CATEGORIES = 'categories',
  REGIONS = 'regions',
  STORES = 'stores',
  SKUCLASSES = 'skuclasses',
  PRODUCTLISTS = 'productlists',
  BRANDS = 'brands',
  DRILLDOWN = 'drilldown',
}

export interface ValidationMessageDefinition {
  msg: string;
  type: ValidationLevel;
  sections: ValidationSection[];
}

export type ValidationMessage = Omit<ValidationMessageDefinition, 'sections'> & {
  key: number;
};

export enum ValidationChecks {
  NO_REGIONS,
  SUPER_AND_CLOSING_AT_ONCE,
  PRODUCT_LISTS_ALL_DISABLED,
  BRANDS_ALL_DISABLED,
  NO_REGION_IN_GROUPS,
  NO_STORES_AS_SOURCE,
  NO_STORES_AS_TARGET,
  INFO_CHECK_TEST,
}

export const VALIDATION_MESSAGES: Record<ValidationChecks, ValidationMessageDefinition> = {
  [ValidationChecks.NO_REGIONS]: {
    msg: 'No regions enabled!',
    type: 'warning',
    sections: [ValidationSection.REGIONS],
  },
  [ValidationChecks.SUPER_AND_CLOSING_AT_ONCE]: {
    msg: 'Some stores are set as super target AND closing at once!',
    type: 'error',
    sections: [ValidationSection.STORES],
  },
  [ValidationChecks.PRODUCT_LISTS_ALL_DISABLED]: {
    msg: 'Product list settings are "All disabled, except..." but none of them is enabled!',
    type: 'warning',
    sections: [ValidationSection.PRODUCTLISTS],
  },
  [ValidationChecks.BRANDS_ALL_DISABLED]: {
    msg: 'Brand settings are "All disabled, except..." but none of them is enabled!',
    type: 'warning',
    sections: [ValidationSection.BRANDS],
  },
  [ValidationChecks.NO_REGION_IN_GROUPS]: {
    msg: 'Regions are set to "Custom groups" but no region is in any group!',
    type: 'error',
    sections: [ValidationSection.REGIONS],
  },
  [ValidationChecks.NO_STORES_AS_SOURCE]: {
    msg: 'No stores are set as source!',
    type: 'warning',
    sections: [ValidationSection.STORES],
  },
  [ValidationChecks.NO_STORES_AS_TARGET]: {
    msg: 'No stores are set as target!',
    type: 'warning',
    sections: [ValidationSection.STORES],
  },
  [ValidationChecks.INFO_CHECK_TEST]: {
    msg: 'This is only info validation for testing...',
    type: 'info',
    sections: [ValidationSection.CATEGORIES],
  },
};

export const createWarnMessage = (
  validationCheck: ValidationChecks,
  bonusText?: string,
): ValidationMessage => ({
  key: validationCheck,
  msg: bonusText
    ? `${VALIDATION_MESSAGES[validationCheck].msg} [ ${bonusText} ]`
    : VALIDATION_MESSAGES[validationCheck].msg,
  type: VALIDATION_MESSAGES[validationCheck].type,
});

export type Validations = Record<ValidationSection, ValidationMessage[]>;

export function useValidation(): Validations {
  const applyValidationCheck = useCallback(
    (validations: Validations, check: ValidationChecks, bonusText?: string) => {
      VALIDATION_MESSAGES[check].sections.forEach(section =>
        validations[section].push(createWarnMessage(check, bonusText)),
      );
    },
    [],
  );

  return useMemo(() => {
    const initValidations = {
      [ValidationSection.GLOBAL]: [],
      [ValidationSection.CATEGORIES]: [],
      [ValidationSection.REGIONS]: [],
      [ValidationSection.STORES]: [],
      [ValidationSection.SKUCLASSES]: [],
      [ValidationSection.PRODUCTLISTS]: [],
      [ValidationSection.BRANDS]: [],
      [ValidationSection.DRILLDOWN]: [],
    };

    applyValidationCheck(initValidations, ValidationChecks.NO_REGIONS);
    applyValidationCheck(initValidations, ValidationChecks.SUPER_AND_CLOSING_AT_ONCE);
    applyValidationCheck(initValidations, ValidationChecks.INFO_CHECK_TEST);
    applyValidationCheck(initValidations, ValidationChecks.NO_STORES_AS_TARGET);

    return initValidations;
  }, [applyValidationCheck]);
}
