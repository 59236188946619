import { format } from 'date-fns';
import { TimeUnit } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';

export const scaleTicksCallback = (scale: TimeUnit) => {
  if (scale === 'day') {
    return (value: string | number, index: number) => {
      const newDateLabel = new Date(value);
      let label = format(newDateLabel, 'dd MMM yyyy');
      if (index % 2 > 0) label = '';
      return label;
    };
  } else if (scale === 'week') {
    return (value: string | number) => {
      return format(new Date(value), 'dd MMM yyyy');
    };
  } else {
    //month
    return (value: string | number) => {
      return format(new Date(value), 'MMM yyyy');
    };
  }
};

export interface DateRange {
  min: Dayjs;
  max: Dayjs;
}

export interface DateRangeData {
  data: DateRange;
  chart: DateRange;
}

export const createInitialDateRangeData = (): DateRangeData => ({
  data: {
    min: dayjs(),
    max: dayjs(),
  },
  chart: {
    min: dayjs(),
    max: dayjs(),
  },
});
