import { EntityListInfo } from '../../../../ProjectAdministration/EntityLists/entityListsTypes';
import React, { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { styled } from 'styled-components';
import { Button, Popover } from 'antd';
import { AvailableModals, openModal } from '../../../../Modals/modalSlice';
import { useDispatch } from 'react-redux';
import { CategoryConfigurationDefinitionResponse } from '@ydistri/api-sdk';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';

const ClearButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

const deleteIcon = <AiOutlineDelete />;

interface EntityListDeleteButtonProps {
  entityListInfo: EntityListInfo;
  configurationDefinition: CategoryConfigurationDefinitionResponse;
}

const EntityListDeleteButton: React.FC<EntityListDeleteButtonProps> = ({
  entityListInfo: { entityList, categoryId },
  configurationDefinition,
}) => {
  const dispatch = useDispatch();
  const { type } = useTemplateOrCalculation();

  const openEntityListDeletionModal = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.ENTITY_LIST_CONFIG_DELETE,
        data: {
          entityList,
          configurationDefinition,
        },
      }),
    );
  }, [configurationDefinition, dispatch, entityList]);

  const disabled = categoryId > 1 || type === 'Calculation';

  return (
    <Popover
      content="Removing lists is possible only from root category."
      open={categoryId > 1 && type === 'Template' ? undefined : false}
    >
      <ClearButton
        type="primary"
        danger
        icon={deleteIcon}
        onClick={openEntityListDeletionModal}
        loading={false}
        disabled={disabled}
      />
    </Popover>
  );
};

export default EntityListDeleteButton;
