import { ImCross } from 'react-icons/im';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { IconButton, IconButtonSize } from '@ydistri/ds';

interface ExitEditModeButtonProps {
  disabled?: boolean;
  onClick: () => void;
  size?: IconButtonSize;
  tooltip?: string;
}

const ExitEditModeButton: React.FC<ExitEditModeButtonProps> = ({
  disabled,
  onClick,
  size = 'small',
  tooltip = 'Cancel edit',
}) => {
  const theme = useTheme();

  const icon = useMemo(() => <ImCross size={14} />, []);

  return (
    <IconButton
      size={size}
      icon={icon}
      tooltip={tooltip}
      tooltipPosition="left"
      onClick={onClick}
      disabled={disabled}
      colorScheme={theme.iconColorSchemes.default}
      data-testid="ExitEditMode"
    />
  );
};

export default ExitEditModeButton;
