import React from 'react';
import { Section, Column, Row } from '@ydistri/ds';
import RedistributionAnalysis from './RedistributionAnalysis/RedistributionAnalysis';
import { PotentialSourceAnalysis } from './PotentialSourceAnalysis/PotentialSourceAnalysis';
import { PotentialTargetAnalysis } from './PotentialTargetAnalysis/PotentialTargetAnalysis';

const CategoryInsights: React.FC = () => {
  return (
    <Column>
      <Row>
        <Section $basis={49} header="Source potential analysis">
          <PotentialSourceAnalysis />
        </Section>
        <Section $basis={49} header="Target potential analysis">
          <PotentialTargetAnalysis />
        </Section>
      </Row>
      <Row $padding={4}>
        <RedistributionAnalysis />
      </Row>
    </Column>
  );
};

export default CategoryInsights;
