import React, { useMemo } from 'react';
import { useCurrency } from '../../../../hooks/useCurrency';
import { getStatisticsRouteTableColumns } from './statisticsModalTableColumns';
import { computeRemSize, getDefaultSorting, InfiniteScrollTable } from '@ydistri/ds';
import { CalculationStatisticRouteResponse } from '@ydistri/api-sdk';
import { ModalDataInfiniteScrollParams, useGetStatsRoutesQuery } from '../apiCalculationStatistics';
import {
  EntityColumns,
  useStoreTableColumnsProvider,
} from '../../../../hooks/useEntityTableColumnsProvider';

interface StatisticsModalTableRouteProps {
  params: ModalDataInfiniteScrollParams;
  setParams: React.Dispatch<React.SetStateAction<ModalDataInfiniteScrollParams>>;
  displayDepartment?: boolean;
}

const StatisticsModalTableRoute: React.FC<StatisticsModalTableRouteProps> = ({
  params,
  setParams,
  displayDepartment = false,
}) => {
  const currencyId = useCurrency();
  const storeColumnsProvider = useStoreTableColumnsProvider();

  const columns = useMemo(() => {
    const columnConfig = {
      [EntityColumns.ID]: {
        width: computeRemSize(120),
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(120),
      },
    };
    const sourceEntityColumns = storeColumnsProvider<CalculationStatisticRouteResponse>(
      columnConfig,
      'sourceStore',
      'Source Store',
    );

    const targetEntityColumns = storeColumnsProvider<CalculationStatisticRouteResponse>(
      columnConfig,
      'targetStore',
      'Target Store',
    );

    return getStatisticsRouteTableColumns(
      currencyId ?? '',
      displayDepartment,
      sourceEntityColumns,
      targetEntityColumns,
    );
  }, [currencyId, displayDepartment, storeColumnsProvider]);

  const { data, isFetching } = useGetStatsRoutesQuery({
    ...params,
    tableOrChart: 'table',
    sortings: params.sortings ?? getDefaultSorting(columns),
  });

  return (
    <InfiniteScrollTable<CalculationStatisticRouteResponse, ModalDataInfiniteScrollParams>
      id="statistics-modal-route-table"
      columns={columns}
      dataSource={data}
      loading={isFetching}
      size="small"
      setParams={setParams}
      height={computeRemSize(400)}
    />
  );
};

export default StatisticsModalTableRoute;
