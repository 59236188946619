import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { NO_VALUE } from '../../../../../../../lib/utils/utilsTypes';

/**
 * Returns true if at least one of the redistribution filters for calculation is set
 */
export const useIsRedistributionFilterSet = (): boolean => {
  const redistributionFilterBrandId = useSelector(
    state => state.calculations.redistributionFilterBrandId,
  );
  const redistributionFilterOutOfStock = useSelector(
    state => state.calculations.redistributionFilterOutOfStock,
  );
  const redistributionFilterForced = useSelector(
    state => state.calculations.redistributionFilterForced,
  );

  return useMemo(() => {
    if (redistributionFilterBrandId !== undefined && redistributionFilterBrandId !== NO_VALUE) {
      return true;
    }

    if (redistributionFilterOutOfStock) {
      return true;
    }

    if (redistributionFilterForced) {
      return true;
    }

    return false;
  }, [redistributionFilterBrandId, redistributionFilterForced, redistributionFilterOutOfStock]);
};
