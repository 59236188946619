import React, { useCallback } from 'react';
import { IconButton, IconButton_IconSizes } from '@ydistri/ds';
import { MdOpenInNew } from 'react-icons/md';

interface ShowSKUAsMainProps {
  skuId: number;
  onShowSkuAsMain: (skuId: number) => void;
}

const icon = <MdOpenInNew size={IconButton_IconSizes.xSmall} />;

/**
 * Icon button to show SKU as main.
 * @param skuId
 * @param onShowSkuAsMain
 * @constructor
 */
const ShowSKUAsMain: React.FC<ShowSKUAsMainProps> = ({ skuId, onShowSkuAsMain }) => {
  const clickHandler = useCallback(() => {
    onShowSkuAsMain(skuId);
  }, [onShowSkuAsMain, skuId]);

  return <IconButton icon={icon} onClick={clickHandler} size="xSmall" tooltip="Show in detail" />;
};

export default ShowSKUAsMain;
