import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Column, computeRemSize, Text } from '@ydistri/ds';
import { SimpleTable } from '../../CalculationDetail/Statistics/components/SimpleTable';
import { BalancingField, BalancingGroupKey, BalancingGroupType } from './balancingLib';
import BalancingFieldTable from './BalancingFieldTable';
import { styled } from 'styled-components';
import { useGetBalancingConfigurationQuery } from './apiBalancingConfiguration';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { CalculationConfigurationExperimentalType } from '@ydistri/api-sdk';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';

const BalancingFieldsTableWrapper = styled(SimpleTable<BalancingField>)`
  .ant-table-tbody .ant-table-cell {
    vertical-align: top;
  }
`;

interface BalancingFieldsTableProps {
  groups: BalancingGroupType;
  groupId: BalancingGroupKey;
}

const BalancingFieldsTable: React.FC<BalancingFieldsTableProps> = ({ groups, groupId }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: balancingData, isFetching } =
    useGetBalancingConfigurationQuery(templateOrCalculation);

  const columns: ColumnsType<BalancingField> = useMemo(() => {
    return [
      {
        title: `Label`,
        dataIndex: ['label'],
        key: 'label',
        // width: computeRemSize(300),
        render: (value, row) => (
          <Column>
            <b>{value}</b>
            {row.description ? (
              <Text $size="small" $type="light">
                {row.description}
              </Text>
            ) : null}
          </Column>
        ),
      },
      {
        title: `Fields`,
        dataIndex: ['fieldKey'],
        key: 'fieldKey',
        width: computeRemSize(240),
        render: (value: CalculationConfigurationExperimentalType) => (
          <BalancingFieldTable fieldKey={value} />
        ),
      },
    ];
  }, []);

  const data = useMemo(() => {
    if (!balancingData) return [];

    const mainSettingType = groups[groupId]?.enableSetting;
    const hasMainSettingType = mainSettingType !== undefined;
    const mainSetting = mainSettingType
      ? balancingData.configuration[mainSettingType]?.values[0]
      : undefined;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const fields = Object.keys(
      balancingData.configuration,
    ) as CalculationConfigurationExperimentalType[];

    let fieldsToHide: CalculationConfigurationExperimentalType[] = [];
    if (hasMainSettingType && mainSetting !== 1) {
      fieldsToHide = fields.filter(d => d !== mainSettingType).flat();
    } else {
      fieldsToHide = fields.map(d => balancingData.configuration[d]?.groupedWith ?? []).flat();
    }

    return (
      fields
        .filter(
          d => !fieldsToHide.includes(d) && balancingData.configuration[d]?.groupId === groupId,
        )
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(d => balancingData.configuration[d]!)
    );
  }, [balancingData, groupId, groups]);

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <BalancingFieldsTableWrapper
      id="balancing-admin"
      rowKey="key"
      dataSource={data}
      columns={columns}
      loading={false}
      minRowHeight="30px"
    />
  );
};

export default BalancingFieldsTable;
