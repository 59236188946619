import { Label, Row } from '@ydistri/ds';
import React, { useCallback } from 'react';
import { Segmented, SegmentedProps } from 'antd';

const defaultSortingOptions: SegmentedProps<string>['options'] = [
  {
    label: 'Recency',
    value: 'CreatedAt',
  },
  {
    label: 'Name',
    value: 'Name',
  },
];

interface SortSelectorProps {
  defaultValue?: string;
  sortingOptions?: SegmentedProps<string>['options'];
  onSortChange: (s: string) => void;
}

const SortSelector: React.FC<SortSelectorProps> = ({
  defaultValue,
  sortingOptions = defaultSortingOptions,
  onSortChange,
}) => {
  const [value, setValue] = React.useState(defaultValue ?? 'CreatedAt');

  const onChangeHandler = useCallback(
    (v: string) => {
      setValue(v);
      onSortChange(v);
    },
    [onSortChange],
  );

  return (
    <Row $gap="1rem" $alignItems="center">
      <Label>Sorted by: </Label>{' '}
      <Segmented options={sortingOptions} value={value} onChange={onChangeHandler} />
    </Row>
  );
};

export default SortSelector;
