import {
  CategoryTree,
  CategoryTreeNode,
} from '../../components/global/CategoryTree/apiCategoryTree';

export const isRootCategory = (categoryId: number): boolean => categoryId === 1;

/**
 * Return the ID's of the categories that are in the path of the leafId from the root to the leaf.
 * If the leafId is not found in the categories, it will return an array with the leafId.
 * @param leafId
 * @param categories
 */
export const constructCategoryPath = (leafId: number, categories: CategoryTree): number[] => {
  const path = [leafId];
  let categoryLeaf = categories[leafId];
  if (!categoryLeaf) {
    return path;
  }

  while (categoryLeaf.parent) {
    path.push(categoryLeaf.parent.id);
    categoryLeaf = categoryLeaf.parent;
  }

  //the root is now the last element of the array, let's reverse it
  //so the root is the first element of the array and leaf is the last
  return path.reverse();
};

/**
 * Function to return a category title based on the category ID.
 * <p>Function of this type can be passed to the getCategoryTitles function</p>
 * @param categoryId The ID of the category for which to return the title
 * @param categories List of all categories
 * @return The title of the category
 */
export type CategoryTitleMapper = (categoryId: number, categories: CategoryTree) => string;

/**
 * From the category tree returns only the nodes corresponding to the categoryIds.
 * @param categoryIds IDs of the categories to return
 * @param categories All categories
 */
export const getCategories = (
  categoryIds: number[],
  categories: CategoryTree,
): CategoryTreeNode[] => {
  return categoryIds.map(id => categories[id]).filter(category => category !== undefined);
};

/**
 * Return the titles of the categories that are in the path of the leafId from the root to the leaf.
 * @param categoryIds The ID's of the categories for which to return titles
 * @param categories List of all categories
 * @param mapper optional function to map the category ID to a title
 */
export const getCategoryTitles = (
  categoryIds: number[],
  categories: CategoryTree,
  mapper?: CategoryTitleMapper,
): string[] => {
  return categoryIds.map(id => {
    if (mapper) {
      return mapper(id, categories);
    } else {
      return categories[id]?.title ?? id.toString();
    }
  });
};

/**
 * Return the titles of the categories that are in the path of the leafId from the root to the leaf together with the customer category ID.
 * @param categoryIds The ID's of the categories for which to return titles
 * @param categories List of all categories
 */
export const getCategoryTitleWithCustomerId = (
  categoryIds: number[],
  categories: CategoryTree,
): string[] => {
  return getCategoryTitles(categoryIds, categories, (categoryId, categories) => {
    const category = categories[categoryId];
    if (category) {
      const customerId = category.customerCategoryId;
      if (customerId) {
        return `${category.title} [ID: ${customerId}]`;
      } else {
        return category.title;
      }
    } else {
      return categoryId.toString();
    }
  });
};
