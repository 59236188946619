import React, { useMemo } from 'react';
import { Chart, ChartData, ChartOptions, TooltipModel } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { customChartTooltip } from './customChartTooltip';
import {
  ChartPropertyType,
  defaultDatasetStyle,
  StatisticsChartDataType,
  valueSorter,
} from './statisticsModalChartLib';
import { styled } from 'styled-components';
import { StatisticsModalType } from '../statisticsLib';
import { computeRemSize } from '@ydistri/ds';

const ChartWrapper = styled.div`
  height: ${computeRemSize(477)};
  width: 100%;
`;

interface StatisticsModalChartProps<U extends StatisticsModalType> {
  statisticsModalType: U;
  data: StatisticsChartDataType[U];
  customTooltipId: string;
  chartValueType: ChartPropertyType<U>;
}

export const StatisticsModalChart = <U extends StatisticsModalType>({
  data,
  customTooltipId,
  chartValueType,
}: StatisticsModalChartProps<U>): React.ReactElement => {
  const sortedData = useMemo(
    () => [...data].sort((a, b) => valueSorter(a, b, chartValueType)),
    [chartValueType, data],
  );

  const chartData: ChartData<'line', number[], string> = useMemo(() => {
    return {
      labels: sortedData.map(() => ''),
      datasets: [
        {
          ...defaultDatasetStyle,
          data: sortedData.map(x => {
            if (chartValueType in x) {
              const value = x[chartValueType];
              if (typeof value === 'number') return value;
              throw Error(`Trying to show non-number value in chart (${String(chartValueType)})!`);
            }
            throw Error(`${String(chartValueType)} doesnt exist in X!`);
          }),
        },
      ],
    };
  }, [chartValueType, sortedData]);

  const options: ChartOptions<'line'> = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          intersect: false,
          position: 'average',
          enabled: false,
          external(args: { chart: Chart; tooltip: TooltipModel<'line'> }) {
            customChartTooltip<U>(args.chart, args.tooltip, customTooltipId, sortedData);
          },
        },
      },
      scales: {
        x: {
          display: false,
        },
      },
    }),
    [customTooltipId, sortedData],
  );

  return (
    <ChartWrapper>
      <Line options={options} data={chartData} />
    </ChartWrapper>
  );
};
