import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { AiOutlineMergeCells } from 'react-icons/ai';

interface ToggleSubmergedCalculationsActionProps {
  onClick: () => void;
  disabled?: boolean;
  size?: IconButtonSize;
}

const ToggleSubmergedCalculationsAction: React.FC<ToggleSubmergedCalculationsActionProps> = ({
  onClick,
  disabled,
  size = 'normal',
}: ToggleSubmergedCalculationsActionProps) => {
  const icon = useMemo(() => <AiOutlineMergeCells size={IconButton_IconSizes[size]} />, [size]);

  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      onClick();
    },
    [onClick],
  );

  return (
    <IconButton
      size={size}
      tooltip="Toggle submerged calculations"
      icon={icon}
      onClick={clickHandler}
      disabled={disabled}
    />
  );
};

export default ToggleSubmergedCalculationsAction;
