import React, { useMemo } from 'react';
import {
  ModalDataInfiniteScrollParams,
  useGetStatsSourceStoresQuery,
} from '../apiCalculationStatistics';
import { useCurrency } from '../../../../hooks/useCurrency';
import { getStatisticsSourceStoresTableColumns } from './statisticsModalTableColumns';
import { computeRemSize, getDefaultSorting, InfiniteScrollTable } from '@ydistri/ds';
import { CalculationStatisticStoreSourceResponse } from '@ydistri/api-sdk';
import {
  EntityColumns,
  useStoreTableColumnsProvider,
} from '../../../../hooks/useEntityTableColumnsProvider';

interface StatisticsModalTableSourceStoresProps {
  params: ModalDataInfiniteScrollParams;
  setParams: React.Dispatch<React.SetStateAction<ModalDataInfiniteScrollParams>>;
  displayDepartment?: boolean;
}

const StatisticsModalTableSourceStores: React.FC<StatisticsModalTableSourceStoresProps> = ({
  params,
  setParams,
  displayDepartment = false,
}) => {
  const currencyId = useCurrency();
  const storeColumnsProvider = useStoreTableColumnsProvider();

  const columns = useMemo(() => {
    const columnConfig = {
      [EntityColumns.ID]: {
        width: computeRemSize(120),
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(120),
      },
    };
    const storeColumns = storeColumnsProvider<CalculationStatisticStoreSourceResponse>(
      columnConfig,
      'sourceStore',
      'Source Store',
    );

    return getStatisticsSourceStoresTableColumns(currencyId ?? '', displayDepartment, storeColumns);
  }, [currencyId, displayDepartment, storeColumnsProvider]);

  const { data, isFetching } = useGetStatsSourceStoresQuery({
    ...params,
    sortings: params.sortings ?? getDefaultSorting(columns),
  });

  return (
    <InfiniteScrollTable<CalculationStatisticStoreSourceResponse, ModalDataInfiniteScrollParams>
      id="statistics-modal-source-stores-table"
      columns={columns}
      dataSource={data}
      loading={isFetching}
      size="small"
      setParams={setParams}
      height={computeRemSize(400)}
    />
  );
};

export default StatisticsModalTableSourceStores;
