import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormInfo, InputFieldType, NotLoggedInPage, OrangeInfo } from '@ydistri/ds';
import { MeCollection } from '../../swagger/collections-identity';

const resetPasswordInputs: InputFieldType[] = [
  {
    title: 'New password',
    type: 'password',
    name: 'confirm_new_pass_1',
    validation: { type: 'password' },
  },
  {
    title: 'Confirm password',
    type: 'password',
    name: 'confirm_new_pass_2',
    validation: { type: 'must_match', field: 'confirm_new_pass_1' },
  },
];

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token') ?? '';
  const email = new URLSearchParams(search).get('email') ?? '';
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const infoTop = useMemo(
    () => (
      <FormInfo>
        Password needs to be at least 8 characters long and contain lowercase, uppercase, numeric
        and special character: -@_~?!,.^$#%&*)(
      </FormInfo>
    ),
    [],
  );

  const infoBottom = useMemo(() => (error ? <OrangeInfo>{error}</OrangeInfo> : null), [error]);

  const onSubmit = useCallback(
    (values: Record<string, string>) => {
      setError(undefined);
      setSubmitting(true);
      MeCollection.resetPasswordCreate(
        {
          login: email,
          newPassword: values.confirm_new_pass_1,
        },
        {
          headers: { resetToken: token },
        },
      )
        .then(() => {
          setSubmitting(false);
          navigate(`/login?passReset=${Date.now()}`);
        })
        .catch(e => {
          setSubmitting(false);
          //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
          setError(e.response?.data?.error_description ?? e.message);
        });
    },
    [token, email, navigate],
  );

  if (token === '' || email === '')
    return <p>An error has occurred - no login or token present.</p>;

  return (
    <NotLoggedInPage
      title="Reset password"
      inputs={resetPasswordInputs}
      button="Reset"
      onSubmit={onSubmit}
      submitting={submitting}
      infoTop={infoTop}
      infoBottom={infoBottom}
    >
      {null}
    </NotLoggedInPage>
  );
};

export default ResetPassword;
