import { IconButtonColorScheme, IconButtonProps, IconButton } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

export interface ToggleIconButtonProps extends IconButtonProps {
  checked: boolean;
  colorSchemeChecked?: IconButtonColorScheme;
}

const ToggleIconButton: React.FC<ToggleIconButtonProps> = ({
  checked,
  colorSchemeChecked,
  colorScheme,
  ...rest
}) => {
  const theme = useTheme();
  const usedColorScheme = useMemo(() => {
    if (checked) {
      return colorSchemeChecked ? colorSchemeChecked : theme.iconColorSchemes.defaultInverse;
    } else {
      return colorScheme ? colorScheme : theme.iconColorSchemes.default;
    }
  }, [theme, checked, colorSchemeChecked, colorScheme]);

  return (
    <IconButton
      {...rest}
      colorScheme={usedColorScheme}
      size={rest.size ? rest.size : 'small'}
      tooltipPosition="topRight"
    />
  );
};

export default ToggleIconButton;
