import { IconButton, IconButton_IconSizes, IconButtonProps, IconButtonSize } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { MdEdit } from 'react-icons/md';

interface EditIconButtonProps {
  disabled?: boolean;
  tooltip: string;
  tooltipPosition?: IconButtonProps['tooltipPosition'];
  onClick: () => void;
  size?: IconButtonSize;
}

const EditIconButton: React.FC<EditIconButtonProps> = ({
  disabled = false,
  tooltip,
  tooltipPosition = 'left',
  onClick,
  size = 'normal',
  ...rest
}: EditIconButtonProps) => {
  const icon = useMemo(() => <MdEdit size={IconButton_IconSizes[size]} />, [size]);

  return (
    <IconButton
      icon={icon}
      size={size}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      onClick={onClick}
      {...rest}
    />
  );
};

export default EditIconButton;
