import { apiSlice } from '../../../apis/api';
import { CalculationsCollection } from '../../../swagger/collections';
import {
  CalculationStatisticClusterType,
  CalculationStatisticRegionResponse,
  CalculationStatisticRouteResponse,
  CalculationStatisticRouteSummaryResponse,
  CalculationStatisticStoreSourceResponse,
  CalculationStatisticStoreSummaryResponse,
  CalculationStatisticStoreTargetResponse,
  CalculationStatisticSummaryResponse,
} from '@ydistri/api-sdk';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
} from '@ydistri/ds';
import { getTags } from '../../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('calculationStatistics2');

export interface GetStatisticsRequest {
  calculationId: number;
  departmentId?: number;
  clusterType?: CalculationStatisticClusterType;
}

export interface ModalDataInfiniteScrollParams extends InfiniteScrollParams, GetStatisticsRequest {
  tableOrChart: 'table' | 'chart';
}

const statisticsRequestKey = (a: GetStatisticsRequest) =>
  `${a.calculationId}-${a.departmentId ?? 'x'}`;

export const apiCalculationStatistics = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getStatistics: builder.query<CalculationStatisticSummaryResponse, GetStatisticsRequest>({
        queryFn: async arg => {
          const { departmentId, calculationId, clusterType } = arg;
          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatistics(calculationId, {
                  clusterType,
                })
              : await CalculationsCollection.getCalculationStatisticsByDepartment(
                  calculationId,
                  departmentId,
                  {
                    clusterType,
                  },
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.calculationStats, id: statisticsRequestKey(arg) },
        ],
      }),

      getStatsRouteSummaries: builder.query<
        CalculationStatisticRouteSummaryResponse[],
        GetStatisticsRequest
      >({
        queryFn: async arg => {
          const { departmentId, calculationId, clusterType } = arg;
          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatisticRouteSummaries(calculationId, {
                  clusterType,
                })
              : await CalculationsCollection.getCalculationStatisticRouteSummariesByDepartment(
                  calculationId,
                  departmentId,
                  { clusterType },
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.statsRouteSummaries, id: statisticsRequestKey(arg) },
        ],
      }),

      getStatsRoutes: builder.query<
        CalculationStatisticRouteResponse[],
        ModalDataInfiniteScrollParams
      >({
        queryFn: async ({ calculationId, departmentId, clusterType, ...args }) => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
            clusterType,
          };

          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatisticRoutes(calculationId, query)
              : await CalculationsCollection.getCalculationStatisticRoutesByDepartment(
                  calculationId,
                  departmentId,
                  query,
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.statsRouteSummaries, id: statisticsRequestKey(arg) },
        ],
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs.tableOrChart}`,
        merge: mergeForInfiniteScroll<
          CalculationStatisticRouteResponse,
          ModalDataInfiniteScrollParams
        >(),
        forceRefetch: forceRefetchForInfiniteScroll<ModalDataInfiniteScrollParams | undefined>(),
      }),

      getStatsStoreSummaries: builder.query<
        CalculationStatisticStoreSummaryResponse,
        GetStatisticsRequest
      >({
        queryFn: async arg => {
          const { departmentId, calculationId, clusterType } = arg;
          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatisticSourceStoreSummaries(
                  calculationId,
                  {
                    clusterType,
                  },
                )
              : await CalculationsCollection.getCalculationStatisticSourceStoreSummariesByDepartment(
                  calculationId,
                  departmentId,
                  {
                    clusterType,
                  },
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.statsStoreSummaries, id: statisticsRequestKey(arg) },
        ],
      }),

      getStatsSourceStores: builder.query<
        CalculationStatisticStoreSourceResponse[],
        ModalDataInfiniteScrollParams
      >({
        queryFn: async ({ calculationId, departmentId, clusterType, ...args }) => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
            clusterType,
          };

          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatisticSourceStores(
                  calculationId,
                  query,
                )
              : await CalculationsCollection.getCalculationStatisticSourceStoresByDepartment(
                  calculationId,
                  departmentId,
                  query,
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.statsSourceStores, id: statisticsRequestKey(arg) },
        ],
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs.tableOrChart}`,
        merge: mergeForInfiniteScroll<
          CalculationStatisticStoreSourceResponse,
          ModalDataInfiniteScrollParams
        >(),
        forceRefetch: forceRefetchForInfiniteScroll<ModalDataInfiniteScrollParams | undefined>(),
      }),

      getStatsTargetStores: builder.query<
        CalculationStatisticStoreTargetResponse[],
        ModalDataInfiniteScrollParams
      >({
        queryFn: async ({ calculationId, departmentId, clusterType, ...args }) => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
            clusterType,
          };
          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatisticTargetStores(
                  calculationId,
                  query,
                )
              : await CalculationsCollection.getCalculationStatisticTargetStoresByDepartment(
                  calculationId,
                  departmentId,
                  query,
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.statsTargetStores, id: statisticsRequestKey(arg) },
        ],
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs.tableOrChart}`,
        merge: mergeForInfiniteScroll<
          CalculationStatisticStoreTargetResponse,
          ModalDataInfiniteScrollParams
        >(),
        forceRefetch: forceRefetchForInfiniteScroll<ModalDataInfiniteScrollParams | undefined>(),
      }),

      getStatsRegions: builder.query<CalculationStatisticRegionResponse[], GetStatisticsRequest>({
        queryFn: async arg => {
          const { departmentId, calculationId } = arg;
          const { data } =
            departmentId === undefined
              ? await CalculationsCollection.getCalculationStatisticRegions(calculationId)
              : await CalculationsCollection.getCalculationStatisticRegionsByDepartment(
                  calculationId,
                  departmentId,
                );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.statsRegions, id: statisticsRequestKey(arg) },
        ],
      }),
    }),
  });

export const {
  useGetStatisticsQuery,
  useGetStatsRouteSummariesQuery,
  useGetStatsRoutesQuery,
  useGetStatsStoreSummariesQuery,
  useGetStatsSourceStoresQuery,
  useGetStatsTargetStoresQuery,
  useGetStatsRegionsQuery,
} = apiCalculationStatistics;
