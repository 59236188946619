import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import { Column, computeRemSize, Spinner } from '@ydistri/ds';
import { COLORS } from '../../../styles/style';
import { AiOutlineFieldTime, AiOutlineLock } from 'react-icons/ai';
import { FaRunning } from 'react-icons/fa';

const Message = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 2;
`;

const MessageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: ${COLORS.PRIMARY};

  svg {
    font-size: 3rem;
  }
`;

const MessageContent = styled.div`
  font-size: 1.2rem;
  max-width: ${computeRemSize(500)};
  font-weight: 500;
  text-align: center;
`;

export const OverlayDiv = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: -0.5rem;
  right: -0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(${computeRemSize(4)});
  z-index: 999;
`;

interface OverlayCoverDivProps {
  $topOffset?: number;
}

export const OverlayCoverDiv = styled(OverlayDiv)<OverlayCoverDivProps>`
  background-color: rgba(255, 255, 255, 0.9);
  top: ${p => (p.$topOffset ? computeRemSize(p.$topOffset) : 0)};
  left: 0;
  right: 0;
  border-radius: 0;
`;

export enum OverlayMessageIcon {
  LOCKED = 'locked',
  PENDING = 'pending',
  RUNNING = 'running',
  SPINNER = 'spinner',
}

const overlayIcons: Record<OverlayMessageIcon, ReactNode> = {
  [OverlayMessageIcon.LOCKED]: <AiOutlineLock />,
  [OverlayMessageIcon.PENDING]: <AiOutlineFieldTime />,
  [OverlayMessageIcon.RUNNING]: <FaRunning />,
  [OverlayMessageIcon.SPINNER]: <Spinner />,
};

interface OverlayProps {
  icon?: OverlayMessageIcon;
  title?: string;
  content?: string;
}

const OverlayMessage: React.FC<OverlayProps> = ({ icon, title, content }) => {
  return (
    <Message>
      <MessageTitle>
        {icon && overlayIcons[icon]} {title ?? ''}
      </MessageTitle>
      {content && <MessageContent>{content}</MessageContent>}
    </Message>
  );
};

export default OverlayMessage;
