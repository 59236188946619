import React, { useMemo } from 'react';
import { translateRedistributionIssue } from '../pickingIssuesLib';
import PickingTableExport from '../../../components/PickingTableExport';
import { PapRedistributionIssue, PickingIssueResponse } from '@ydistri/api-sdk';
import { ColumnsType } from 'antd/es/table';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { useGetPickingIssuesQuery } from '../../../apiCalculationPickingResults';
import { SKU_ID_LABEL } from '../../../../../../components/global/_constants';
import {
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../../hooks/useEntityTableColumnsProvider';

const PickingIssuesExport: React.FC = () => {
  const calculation = useTemplateOrCalculation();

  const { data, isFetching } = useGetPickingIssuesQuery(calculation.id);
  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const tableColumns: ColumnsType<PickingIssueResponse> =
    useMemo((): ColumnsType<PickingIssueResponse> => {
      const storeColumns: ColumnsType<PickingIssueResponse> = storeColumnsProvider(
        {},
        'sourceStore',
      );

      const productColumns = productColumnsProvider<PickingIssueResponse>();

      const result = [
        ...storeColumns,
        { title: `Source ${SKU_ID_LABEL}`, dataIndex: 'sourceSkuId' },
        { title: `Target ${SKU_ID_LABEL}`, dataIndex: 'targetSkuId' },
        ...productColumns,
        { title: 'Brand', dataIndex: ['product', 'brand', 'name'] },
      ];

      const targetStoreColumns: ColumnsType<PickingIssueResponse> = storeColumnsProvider(
        {},
        'targetStore',
        'Target Store',
      );
      result.push(...targetStoreColumns);

      result.push(
        {
          title: 'Issue',
          dataIndex: 'papRedistributionIssueId',
          render: (value: PapRedistributionIssue) => translateRedistributionIssue(value),
        },
        { title: 'Quantity Executed', dataIndex: 'quantityExecuted' },
        { title: 'Quantity Requested', dataIndex: 'quantityRequested' },
      );

      return result;
    }, [productColumnsProvider, storeColumnsProvider]);

  if (!isFetching && data) {
    return (
      <PickingTableExport<PickingIssueResponse>
        dataSource={data}
        columns={tableColumns}
        sheetName="Picking Issues"
        fileNameBase={`PickingIssues_${calculation.id}`}
      />
    );
  }
};

export default PickingIssuesExport;
