import { Column, Text } from '@ydistri/ds';
import React from 'react';

interface SKUTableColumnHeaderTitleProps {
  title: string | string[];
  wordSeparator?: string;
}

/**
 * Component to render a column header title with multiple lines based on a separator
 * <p>By default it uses a space as separator</p>
 * <p>If the title is an array then it will render each element in a separate line ignoring the wordSeparator</p>
 * @param title Title where every word is rendered on a separate line
 * @param wordSeparator Separator to use to split the title into multiple lines, by default it uses a space
 * @constructor
 */
const SKUTableColumnHeaderTitle: React.FC<SKUTableColumnHeaderTitleProps> = ({
  title,
  wordSeparator = ' ',
}) => {
  const parts = Array.isArray(title) ? Array.from(title) : title.split(wordSeparator);
  return (
    <Column $alignItems="flex-start" $alignSelf="center">
      {parts.map(part => (
        <Text key={part}>{part}</Text>
      ))}
    </Column>
  );
};

export default SKUTableColumnHeaderTitle;
