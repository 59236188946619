import React, { useCallback, useMemo } from 'react';
import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import { MdOpenInNew } from 'react-icons/md';
import { AvailableModals, openModal } from '../../../../../../../Modals/modalSlice';
import { useDispatch } from 'react-redux';

interface ShowActualDataActionProps {
  skuId: number;
  size?: IconButtonSize;
}

const OpenDailySalesAction: React.FC<ShowActualDataActionProps> = ({ skuId, size = 'small' }) => {
  const dispatch = useDispatch();
  const openModalIcon = useMemo(() => <MdOpenInNew size={IconButton_IconSizes[size]} />, [size]);

  const onClickHandler = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.SKU_DAILY_SALES,
        data: {
          skuId,
        },
      }),
    );
  }, [dispatch, skuId]);

  return (
    <IconButton
      size={size}
      tooltipPosition="topRight"
      tooltip="Open daily data "
      onClick={onClickHandler}
      icon={openModalIcon}
    />
  );
};

export default OpenDailySalesAction;
