import { styled } from 'styled-components';
import { Badge } from 'antd';
import { CSSProperties } from 'react';

export const CountBadge = styled(Badge)<{ $color: CSSProperties['color'] }>`
  & .ant-badge-count,
  & .ant-badge-dot {
    background-color: ${p => p.$color} !important;
  }
`;
