import * as signalR from '@microsoft/signalr';
import { getApiUrl } from '../helpers';
import { getItem, LSKey } from '../store/localStore';
import { store } from '../store';
import { setSignalrConnected } from '../store/appSlice';
import { createDebugLog } from '../lib/utils/logging';
// import { apiSlice, APITAGS, getAllTagsExcept } from '../apis/api';

export const debugLogSignalr = createDebugLog('SignalR');

export const signalrClient = new signalR.HubConnectionBuilder()
  .withUrl(`${getApiUrl()}/hubs/app`, {
    accessTokenFactory: () => `${getItem(LSKey.TOKEN) ?? ''}`,
    transport: signalR.HttpTransportType.ServerSentEvents,
  })
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: () => 5000,
  })
  .configureLogging(signalR.LogLevel.Information)
  .build();

signalrClient.onreconnected(e => {
  debugLogSignalr('Reconnected!', e);
  window.location.reload();
  // store.dispatch(setSignalrConnected(true));
  // store.dispatch(
  //   apiSlice.util.invalidateTags(
  //     getAllTagsExcept([APITAGS.application.projects, APITAGS.application.templates]),
  //   ),
  // );
});

signalrClient.onreconnecting(e => {
  debugLogSignalr('Reconnecting...', e);
  store.dispatch(setSignalrConnected(false));
});

signalrClient.onclose(e => {
  debugLogSignalr('Closed.', e);
  store.dispatch(setSignalrConnected(false));
});
