import ConfigurationMultiselect, {
  MultiselectCustomerPartialEntity,
} from './ConfigurationMultiselect';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateScopeEntitySelectedIds } from './scopeConfigurationSlice';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';
import { useApplicationConfiguration } from '../../../hooks/useApplicationConfiguration';

interface ScopeConfigurationMultiselectProps {
  selectedIds?: number[];
  entityType: ConfigurationRuleScopeEntityType;
  data: MultiselectCustomerPartialEntity[];
  loading?: boolean;
}

const ScopeConfigurationMultiselect: React.FC<ScopeConfigurationMultiselectProps> = ({
  selectedIds = [],
  entityType,
  data,
  loading,
}) => {
  const dispatch = useDispatch();
  const { mdbWarehouseIdAutoIncrement } = useApplicationConfiguration();
  const compareAsInt = mdbWarehouseIdAutoIncrement === 'INT';

  const onChangeHandler = useCallback(
    (selectedIds: number[]) => {
      dispatch(updateScopeEntitySelectedIds({ entityType, selectedIds }));
    },
    [dispatch, entityType],
  );

  return (
    <ConfigurationMultiselect
      data={data}
      value={selectedIds}
      onValueChange={onChangeHandler}
      loading={loading}
      entityType={entityType}
      compareAsInt={compareAsInt}
    />
  );
};

export default ScopeConfigurationMultiselect;
