import ExitEditModeButton from '../../../../../../components/buttons/ExitEditModeButton';
import React, { useCallback } from 'react';
import useResetImport from '../components/common/useResetImport';

const ExitImportDisplayModeAction: React.FC = () => {
  const resetImport = useResetImport();

  const clickHandler = useCallback(() => {
    resetImport();
  }, [resetImport]);

  return <ExitEditModeButton onClick={clickHandler} tooltip="Cancel import" />;
};

export default ExitImportDisplayModeAction;
