import React, { useCallback, useMemo } from 'react';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTemplate } from '../../hooks/useTemplate';
import { PatchTemplatePayload, usePatchTemplateMutation } from './apiTemplate';

interface EditTemplateModalProps {
  open: boolean;
  templateId: number;
  onCancel: () => void;
}

interface FormFields {
  title: string;
  description: string;
}

const titleInputRules = [{ required: true, message: 'Please enter a title for your template' }];
const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

const EditTemplateModal: React.FC<EditTemplateModalProps> = ({ open, templateId, onCancel }) => {
  const template = useTemplate(templateId);
  const [form] = Form.useForm<FormFields>();
  const [editTemplate, { isLoading }] = usePatchTemplateMutation();

  const initialValues: FormFields = useMemo(
    () => ({
      title: template?.title ?? '',
      description: template?.description ?? '',
    }),
    [template?.description, template?.title],
  );

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const payload: PatchTemplatePayload = {
        templateId,
        data: values,
      };

      editTemplate(payload).then(() => {
        form.resetFields();
        onCancel();
      });
    });
  }, [editTemplate, form, onCancel, templateId]);

  const modalTitle = useMemo(
    () => `Edit template #${template?.id ?? ''} ${template?.title ?? ''}`,
    [template?.id, template?.title],
  );

  return (
    <Modal
      data-type="modal"
      title={modalTitle}
      open={open}
      width={1000}
      okText="Save"
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Form
        data-type="edit-template-form"
        form={form}
        labelCol={formLayout.labelCol}
        wrapperCol={formLayout.wrapperCol}
        name="edit_template_modal"
        layout="horizontal"
        initialValues={initialValues}
      >
        <Form.Item name="title" label="Title" rules={titleInputRules}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTemplateModal;
