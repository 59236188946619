import { FieldConfigurationDeleteRequest, FieldConfigurationRequest } from '@ydistri/api-sdk';
import { SkuTypeColumn } from '@ydistri/utils';

/**
 * Create the payload for the API call.
 * Right now, if the skuTypeColumn is 'Target', we need to create 3 requests, one for each skuType.
 * If target configs won't be stored in 3 different sku types in the future, this function can be removed.
 *
 * @param item
 * @param skuTypeColumn
 */

export const createPayload = <
  T extends FieldConfigurationDeleteRequest | FieldConfigurationRequest,
>(
  item: T,
  skuTypeColumn: SkuTypeColumn,
): T[] => {
  return [
    {
      ...item,
      skuType: skuTypeColumn === 'Target' ? undefined : skuTypeColumn,
    },
  ];
};
