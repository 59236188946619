import { apiSlice, TemplateOrCalculation } from '../../../apis/api';
import {
  apiCategoryTree,
  CategoryTree,
  CategoryTreeNode,
} from '../../../components/global/CategoryTree/apiCategoryTree';
import {
  CategoryDrillDownData,
  createCategoryDrillDownData,
  SkuTypeForDrilldown,
  TCategoryOverviewLoaded,
} from './drillDownLib';
import { getTags } from '../../../apis/apiLib';
import { apiCategoryInsights } from '../../../components/global/CategoryInsights/apiCategoryInsights';

const { TAGS, TAGS_ARRAY } = getTags('drillDown');

export interface GetCategoryOverviewForDrilldownRequest {
  templateOrCalculation: TemplateOrCalculation;
  openedCategoryKey: string;
  selectedSkuType: SkuTypeForDrilldown;
  takeSupplyAndRevenueFromAttributes?: boolean;
}

export const apiDrillDown = apiSlice.enhanceEndpoints({ addTagTypes: TAGS_ARRAY }).injectEndpoints({
  endpoints: builder => ({
    getCategoryOverviewForDrilldown: builder.query<
      CategoryDrillDownData[],
      GetCategoryOverviewForDrilldownRequest
    >({
      queryFn: async (
        {
          templateOrCalculation,
          selectedSkuType,
          openedCategoryKey,
          takeSupplyAndRevenueFromAttributes = false,
        },
        { dispatch },
      ) => {
        if (!templateOrCalculation.id) return { data: [] };

        const categoryTree = await dispatch(
          apiCategoryTree.endpoints.getCategoryTree.initiate(templateOrCalculation),
        );

        const openedCategories = openedCategoryKey.split('-').map(Number);

        function addChildrenNodes(
          currentNodeId: number,
          categoryTree: CategoryTree | undefined,
          neededCategoryNodes: CategoryTreeNode[],
          parentIsOpened = false,
        ): void {
          if (!categoryTree) return;
          const currentNode = categoryTree[currentNodeId];
          if (!currentNode || (!parentIsOpened && currentNodeId !== 1)) {
            return;
          }

          const nodeIsOpened = openedCategories.includes(currentNodeId);
          if (parentIsOpened || nodeIsOpened) {
            neededCategoryNodes.push(currentNode);
            for (const childId of currentNode.children) {
              addChildrenNodes(childId, categoryTree, neededCategoryNodes, nodeIsOpened);
            }
          }
        }

        const neededCategoryNodes: CategoryTreeNode[] = [];
        addChildrenNodes(1, categoryTree.data, neededCategoryNodes);

        const requests = neededCategoryNodes.map(categoryNode =>
          dispatch(
            apiCategoryInsights.endpoints.getCategoryInsights.initiate({
              templateOrCalculation,
              categoryId: categoryNode.id,
            }),
          ),
        );

        const loadedOverviews: TCategoryOverviewLoaded = {};

        (await Promise.all(requests)).forEach(r => {
          if (r.originalArgs) loadedOverviews[r.originalArgs.categoryId] = r.data;
        });

        return {
          data: createCategoryDrillDownData(
            selectedSkuType,
            neededCategoryNodes,
            loadedOverviews,
            takeSupplyAndRevenueFromAttributes,
          ),
        };
      },
      providesTags: (result, error, arg) => [
        {
          type: TAGS.categoryOverviewForDrilldown,
          id: `${arg.openedCategoryKey}-${arg.templateOrCalculation.type}-${arg.templateOrCalculation.id} ?? 'x'}`,
        },
      ],
    }),
  }),
});

export const { useGetCategoryOverviewForDrilldownQuery } = apiDrillDown;
