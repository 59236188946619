import React, { useMemo } from 'react';
import {
  getMessageInfoFromValidatorMessages,
  ValidatorMessageSeverity,
  ValidatorSection,
  validatorSectionTitles,
  ValidatorStatus,
  validatorStatusBadgeColors,
  validatorStatusTextColors,
} from './validatorLib';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import { Column, Row, RowCentered, Text } from '@ydistri/ds';
import { AiOutlineCheck } from 'react-icons/ai';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { ReduxState } from '../../../store';

interface ValidatorContentSectionProps {
  section: ValidatorSection;
}

const ValidatorContentSection: React.FC<ValidatorContentSectionProps> = ({ section }) => {
  const { departments: hasDepartments } = useApplicationData();
  const messages = useSelector((state: ReduxState) => state.validator.sectionMessages[section]);
  const overviewMessage = useSelector(
    (state: ReduxState) => state.validator.overviewMessages[section],
  );

  const messageInfo = useMemo(() => getMessageInfoFromValidatorMessages(messages), [messages]);
  const errorCount = messageInfo[ValidatorMessageSeverity.ERROR];
  const warningCount = messageInfo[ValidatorMessageSeverity.WARNING];

  if (messages.length === 0 && overviewMessage === '') {
    return null;
  }

  return (
    <Column $gap="0.5rem">
      <RowCentered $gap="0.5rem">
        <b>{validatorSectionTitles(hasDepartments)[section]}</b>

        {errorCount === 0 && warningCount === 0 && (
          <Text $type={validatorStatusTextColors[ValidatorStatus.INFO]}>
            <AiOutlineCheck />
          </Text>
        )}

        {errorCount > 0 && (
          <Text $type="danger">
            {errorCount} error{errorCount === 1 ? '' : 's'}
          </Text>
        )}
        {warningCount > 0 && (
          <Text $type="warning">
            {warningCount} warning{warningCount === 1 ? '' : 's'}
          </Text>
        )}
      </RowCentered>
      {overviewMessage}
      {messages.map(m => (
        <Row key={m.key} $alignItems="start" $gap="1rem" $flexWrap="nowrap">
          <Badge color={validatorStatusBadgeColors[m.status]} />
          {m.message}
        </Row>
      ))}
    </Column>
  );
};

export default ValidatorContentSection;
