import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AdministrationItemAction } from '../../../common/administrationItemsTypes';
import { ProductListValidatedItem } from '../../productListTypes';

import {
  computeRemSize,
  Panel,
  ScrolledTable,
  useInjectLocalFilters,
  YColumnsType,
} from '@ydistri/ds';
import { styled } from 'styled-components';
import { CompareType } from '@ydistri/api-sdk';
import ValidatedItemAction from '../../../common/validation-results/ValidatedItemAction';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

const errorSort = (left: ProductListValidatedItem, right: ProductListValidatedItem): number => {
  if (!left.isError && right.isError) {
    return -1;
  }
  if (left.isError && !right.isError) {
    return 1;
  }

  return 0;
};

const ValidatedItemsTable = styled(ScrolledTable<ProductListValidatedItem>)`
  tr:hover td {
    background: none !important;
  }

  tr.errorItem {
    background-color: rgba(195, 14, 5, 0.3);

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background-color: rgba(179, 13, 5, 0.35);
    }

    td {
      border-bottom: ${computeRemSize(1)} solid rgba(176, 13, 5, 0.3);
    }
  }

  tr.newItem {
    background-color: #dff1df;

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background-color: #d4ecd4;
    }

    td {
      border-bottom: ${computeRemSize(1)} solid #c9d9c9;
    }
  }

  tr.removedItem {
    background-color: rgba(195, 14, 5, 0.15);

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background: rgba(179, 13, 5, 0.2);
    }

    td {
      border-bottom: ${computeRemSize(1)} solid rgba(195, 14, 5, 0.2);
    }
  }

  tr.updatedItem {
    background-color: #fdf0dd;

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background: #fce6c5;
    }

    td {
      border-bottom: ${computeRemSize(1)} solid #e4d8c7;
    }
  }
`;

interface ProductListValidatedItemsGridProps {
  data: ProductListValidatedItem[];
}

const ProductListValidatedItemsGrid: React.FC<ProductListValidatedItemsGridProps> = ({ data }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handlingMethod = useSelector(
    (state: ReduxState) => state.productListsAdministration.handlingMethod,
  );

  const { productIdColumnWidth } = useColumnWidth();
  const [tableHeight, setTableHeight] = useState(480);

  const resizeTable = useCallback(() => {
    const TABLE_OFFSET = 16;

    if (ref.current) {
      let tableHeaderSize: number = 37; //to compensate for the table's header as it is rendered outside the table's container
      const tableHeader = ref.current.querySelector('.ant-table-header');
      if (tableHeader) {
        tableHeaderSize = tableHeader.clientHeight;
      }

      //initial height
      setTableHeight(ref.current.clientHeight - (tableHeaderSize + TABLE_OFFSET));
    }
  }, []);

  useEffect(() => {
    resizeTable();

    window.addEventListener('resize', resizeTable);
    return () => window.removeEventListener('resize', resizeTable);
  }, [ref, resizeTable]);

  const getActionTooltip = useCallback(
    (validatedItem: ProductListValidatedItem) => {
      if (validatedItem.isError) {
        return validatedItem.errors?.join('; ') ?? '';
      } else {
        switch (validatedItem.compareType) {
          case CompareType.Missing:
            return 'New item';
          case CompareType.Contains:
            if (handlingMethod) {
              const action = validatedItem.actions[handlingMethod];
              if (action === AdministrationItemAction.REMOVE) {
                return 'Item was not included in the source data and will be removed';
              }
            }

            return 'The Product List already contains this product of the same quantity';
          case CompareType.Updated:
            return 'The quantity will be changed';
          case undefined: {
            throw new Error('Not implemented yet: undefined case');
          }
        }
      }
    },
    [handlingMethod],
  );

  const actionCellRenderer = useCallback(
    (value: string, record: ProductListValidatedItem) => {
      if (handlingMethod) {
        const action = record.actions[handlingMethod];
        const tooltip = getActionTooltip(record);
        return (
          <ValidatedItemAction
            action={action}
            error={record.isError}
            duplicate={record.isDuplicate}
            tooltip={tooltip}
          />
        );
      }
    },
    [getActionTooltip, handlingMethod],
  );

  const productColumnsProvider = useProductTableColumnsProvider();

  const columns = useMemo(() => {
    const productColumnsConfig: EntityColumnsConfig<ProductListValidatedItem> = {
      [EntityColumns.ID]: {
        width: productIdColumnWidth,
        apiFilterable: true,
        render: (value: string, record: ProductListValidatedItem) =>
          value && value.length > 0 ? value : record.inputData.productId,
      },
      [EntityColumns.NAME]: {
        apiFilterable: true,
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }

          return left.data.name.localeCompare(right.data.name);
        },
      },
      [EntityColumns.CODE]: {
        width: '12rem',
        apiFilterable: true,
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }

          return left.data.code.localeCompare(right.data.code);
        },
      },
    };
    const productColumns = productColumnsProvider<ProductListValidatedItem>(productColumnsConfig, [
      'data',
    ]);

    const tmpColumns: YColumnsType<ProductListValidatedItem>[] = [
      ...productColumns,
      {
        title: 'Action',
        key: 'action',
        width: '7rem',
        align: 'center',
        dataIndex: ['compareType'],
        render: actionCellRenderer,
      },
    ];

    return tmpColumns;
  }, [productIdColumnWidth, productColumnsProvider, actionCellRenderer]);
  const filterableColumns = useInjectLocalFilters<ProductListValidatedItem>(columns);

  const rowClassNameProvider = useCallback(
    (validatedItem: ProductListValidatedItem) => {
      if (validatedItem.isError) {
        return 'errorItem';
      }

      if (validatedItem.compareType === CompareType.Missing) {
        return 'newItem';
      }

      if (handlingMethod) {
        if (validatedItem.actions[handlingMethod] === AdministrationItemAction.REMOVE) {
          return 'removedItem';
        }
      }

      if (validatedItem.compareType === CompareType.Updated) {
        return 'updatedItem';
      }

      return '';
    },
    [handlingMethod],
  );

  const rowKey = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (record: ProductListValidatedItem) => record?.data?.id ?? record.inputData.productId,
    [],
  );

  return (
    <Panel ref={ref} id="ValidatedItemsTableWrapper">
      <ValidatedItemsTable
        rowKey={rowKey}
        id="ValidatedItemsTable"
        dataSource={data}
        columns={filterableColumns}
        size="small"
        rowClassName={rowClassNameProvider}
        height={computeRemSize(tableHeight)}
      />
    </Panel>
  );
};
export default ProductListValidatedItemsGrid;
