import React, { useMemo } from 'react';
import { Title, Column, Row, ArrayTooltipContent } from '@ydistri/ds';
import { ALIGN_SELF_CENTER } from '../categoryInsightsLib';
import BarSourceByReason from './BarSourceByReason';
import BarSourceByInventoryType from './BarSourceByInventoryType';
import BarTargetByInventoryType from './BarTargetByInventoryType';
import BarTargetByReason from './BarTargetByReason';
import useCategoryId from '../../../../hooks/useCategoryId';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { GetCategoryInsightsParams, useGetCategoryInsightsQuery } from '../apiCategoryInsights';
import { shortenNumber } from '@ydistri/utils';
import { Popover } from 'antd';
import { useCurrency } from '../../../../hooks/useCurrency';

const RedistributionAnalysis: React.FC = () => {
  const categoryId = useCategoryId();
  const currency = useCurrency();
  const templateOrCalculation = useTemplateOrCalculation();
  const categoryInsightsParam: GetCategoryInsightsParams = {
    categoryId,
    templateOrCalculation,
  };

  const { data: categoryData, isLoading: isCategoryInsightsLoading } =
    useGetCategoryInsightsQuery(categoryInsightsParam);

  const redistributionData = useMemo(
    () => categoryData?.redistributions,
    [categoryData?.redistributions],
  );

  const finalRedistributionTooltipData = useMemo(
    () => [
      {
        title: 'Final redistribution',
        value: redistributionData?.total ?? 0,
        postfix: currency,
      },
    ],
    [redistributionData?.total, currency],
  );

  const tooltipContent = useMemo(
    () => <ArrayTooltipContent data={finalRedistributionTooltipData} />,
    [finalRedistributionTooltipData],
  );

  if (!redistributionData || isCategoryInsightsLoading) {
    return null;
  }

  return (
    <Row $gap="5rem" $flexGrow={1} $justifyContent="center" $flexWrap="wrap">
      <Row $gap="5rem" $justifyContent="center">
        <BarSourceByReason data={redistributionData} />
        <BarSourceByInventoryType data={redistributionData} />
      </Row>
      <Column $alignItems="center" $justifyContent="center">
        <Title $size="xLarge" style={ALIGN_SELF_CENTER}>
          Final redistribution
        </Title>
        <Popover content={tooltipContent}>
          <Title $size="xxLarge" style={ALIGN_SELF_CENTER}>
            {shortenNumber(redistributionData.total)} {currency}
          </Title>
        </Popover>
      </Column>
      <Row $gap="5rem" $justifyContent="center">
        <BarTargetByInventoryType data={redistributionData} />
        <BarTargetByReason data={redistributionData} />
      </Row>
    </Row>
  );
};

export default RedistributionAnalysis;
