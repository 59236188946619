import React, { useCallback, useMemo } from 'react';
import { Row, ActionsBar, Button } from '@ydistri/ds';
import SearchBox from '../../../../../components/global/SearchBox/SearchBox';
import { isDigitsOnly } from '@ydistri/utils';

interface SearchPanelProps {
  value?: string;
  onSearch: (query: string) => void;
  onSkuSearch: (skuId: number) => void;
}

const SearchPanel: React.FC<SearchPanelProps> = ({ value, onSearch, onSkuSearch }) => {
  const renderSkuButton = useMemo(() => {
    if (value) {
      return isDigitsOnly(value);
    } else {
      return false;
    }
  }, [value]);

  const skuSearchHandler = useCallback(() => {
    if (!value) {
      return;
    }

    const skuId = parseInt(value);
    onSkuSearch(skuId);
  }, [onSkuSearch, value]);

  return (
    <Row $flexGrow={0} $gap="1rem">
      <SearchBox
        onSearch={onSearch}
        placeholder="Search products"
        value={value}
        flexGrow={1}
        id="product-search-box"
      />
      {renderSkuButton && (
        <ActionsBar>
          <Button onClick={skuSearchHandler} data-testid="SKUSearchButton">
            SKU
          </Button>
        </ActionsBar>
      )}
    </Row>
  );
};

export default SearchPanel;
