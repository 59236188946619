import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { Button, Title } from '@ydistri/ds';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store';
import { AvailableModals, closeModal } from './modalSlice';

const modalId = AvailableModals.NEW_APP_VERSION;

const NewAppVersionModal: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const handleReload = useCallback((e: React.MouseEvent<HTMLElement>) => {
    window.location.reload();
    e.stopPropagation();
  }, []);

  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      footer={null}
      title="New version of Ydistri application is available!"
    >
      <Title $size="large"></Title>
      <Button onMouseUp={handleReload}>Reload application</Button>
    </Modal>
  );
};

export default NewAppVersionModal;
