import React, { useCallback, useMemo, useState } from 'react';
import {
  CalculationStatisticTargetListResponseWithKey,
  useGetTargetListItemsQuery,
} from './apiCalculationTargetListStatistics';
import { SimpleTable } from './components/SimpleTable';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import {
  Column,
  CompoundSelectedKey,
  computeRemSize,
  injectLocalFilters,
  LabeledToggle,
  LabelPlacement,
  Row,
  tableScroll,
} from '@ydistri/ds';
import TableTargetListItemsExport from './TableTargetListItemsExport';
import { useGetTargetListsForConfigurationQuery } from '../../Configuration/apiTargetListConfiguration';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { TablePaginationConfig } from 'antd/lib';
import { FilterValue } from 'antd/lib/table/interface';
import { styled } from 'styled-components';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../hooks/useEntityTableColumnsProvider';

const tableMarginTop = { marginTop: '-28px' };

const rowClassName = (tl: CalculationStatisticTargetListResponseWithKey) => {
  if (tl.receivedQuantityPercentage >= 100) return 'green';
  if (tl.receivedQuantityPercentage === 0) return 'red';
  return 'orange';
};

interface TableTargetListItemsProps {
  selectedTargetListSku?: CalculationStatisticTargetListResponseWithKey;
  setSelectedTargetListSku: React.Dispatch<
    React.SetStateAction<CalculationStatisticTargetListResponseWithKey | undefined>
  >;
}

const TableWrapper = styled.div`
  min-height: ${computeRemSize(180)};

  .ant-table-row {
    cursor: pointer;
`;

const TableTargetListItems: React.FC<TableTargetListItemsProps> = ({
  selectedTargetListSku,
  setSelectedTargetListSku,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { productIdColumnWidth } = useColumnWidth();
  const { data, isFetching } = useGetTargetListItemsQuery(templateOrCalculation.id);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [omitFullFilled, setOmitFullFilled] = useState<boolean>(true);
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null> | undefined
  >();

  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const targetListItemsColumns: ColumnsType<CalculationStatisticTargetListResponseWithKey> =
    useMemo(() => {
      const columnsConfig: EntityColumnsConfig<CalculationStatisticTargetListResponseWithKey> = {
        [EntityColumns.ID]: {
          width: computeRemSize(150),
          shouldCellUpdate: (record, prevRecord) =>
            record.store.customerStoreId !== prevRecord.store.customerStoreId,
        },
        [EntityColumns.NAME]: {
          shouldCellUpdate: (record, prevRecord) => record.store.name !== prevRecord.store.name,
        },
        [EntityColumns.CODE]: {
          width: computeRemSize(150),
          shouldCellUpdate: (record, prevRecord) => record.store.code !== prevRecord.store.code,
        },
      };
      const storeColumns = storeColumnsProvider(columnsConfig);

      const productColumnsConfig: EntityColumnsConfig<CalculationStatisticTargetListResponseWithKey> =
        {
          [EntityColumns.ID]: {
            width: productIdColumnWidth,
            shouldCellUpdate: (record, prevRecord) =>
              record.product.customerId !== prevRecord.product.customerId,
          },
          [EntityColumns.NAME]: {
            shouldCellUpdate: (record, prevRecord) =>
              record.product.name !== prevRecord.product.name,
          },
          [EntityColumns.CODE]: {
            width: computeRemSize(150),
            shouldCellUpdate: (record, prevRecord) =>
              record.product.code !== prevRecord.product.code,
          },
        };
      const productColumns = productColumnsProvider(productColumnsConfig);

      return [
        ...storeColumns,
        ...productColumns,
        {
          title: 'Top up to defined quantity [MU]',
          key: 'originalRequestedQuantity',
          dataIndex: ['originalRequestedQuantity'],
          width: computeRemSize(125),
          sorter: (a, b) => a.originalRequestedQuantity - b.originalRequestedQuantity,
          shouldCellUpdate: (record, prevRecord) =>
            record.originalRequestedQuantity !== prevRecord.originalRequestedQuantity,
        },
        {
          title: 'Available supply [MU]',
          key: 'availableSupply',
          dataIndex: ['availableSupply'],
          width: computeRemSize(125),
          sorter: (a, b) => a.availableSupply - b.availableSupply,
          shouldCellUpdate: (record, prevRecord) =>
            record.availableSupply !== prevRecord.availableSupply,
        },
        {
          title: 'Requested [MU]',
          key: 'requestedQuantity',
          dataIndex: ['requestedQuantity'],
          width: computeRemSize(125),
          sorter: (a, b) => a.requestedQuantity - b.requestedQuantity,
          shouldCellUpdate: (record, prevRecord) =>
            record.requestedQuantity !== prevRecord.requestedQuantity,
        },
        {
          title: 'Received [MU]',
          key: 'receivedQuantity',
          dataIndex: ['receivedQuantity'],
          width: computeRemSize(125),
          sorter: (a, b) => a.receivedQuantity - b.receivedQuantity,
          shouldCellUpdate: (record, prevRecord) =>
            record.receivedQuantity !== prevRecord.receivedQuantity,
        },
        {
          title: '%',
          key: 'receivedQuantityPercentage',
          dataIndex: ['receivedQuantityPercentage'],
          width: computeRemSize(50),
          shouldCellUpdate: (record, prevRecord) =>
            record.receivedQuantityPercentage !== prevRecord.receivedQuantityPercentage,
        },
      ];
    }, [productColumnsProvider, productIdColumnWidth, storeColumnsProvider]);

  const { data: targetListConfig, isFetching: isFetchingTargetLists } =
    useGetTargetListsForConfigurationQuery(templateOrCalculation);

  const targetList = useMemo(() => {
    if (targetListConfig && targetListConfig.length > 0) {
      return targetListConfig[0];
    }
  }, [targetListConfig]);

  const columns = useMemo(() => {
    const colKeysToFilterOut: React.Key[] = ['originalRequestedQuantity'];
    return injectLocalFilters(
      targetListItemsColumns.filter(col => !col.key || !colKeysToFilterOut.includes(col.key)),
    );
  }, [targetListItemsColumns]);

  const dataWithKeys: CalculationStatisticTargetListResponseWithKey[] = useMemo(() => {
    if (!data) {
      return [];
    }

    let result = Array.from(data);

    if (omitFullFilled) {
      const firstNotFullFilled = result.findIndex(item => item.receivedQuantityPercentage < 100);
      if (firstNotFullFilled !== -1) {
        result = result.slice(firstNotFullFilled);
      }
    }

    if (tableFilters) {
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (value === null) return;

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const compoundKey = JSON.parse(value.toString()) as CompoundSelectedKey;
        switch (key) {
          case 'productId':
            result = result.filter(item => {
              const containsValue = item.product.customerId?.includes(
                compoundKey.value[0].toString(),
              );
              if (compoundKey.comparisonType === 'contains') {
                return containsValue;
              } else {
                return !containsValue;
              }
            });
            break;
        }
      });
    }

    return result;
  }, [data, omitFullFilled, tableFilters]);

  const setSelection = useCallback(
    (record: CalculationStatisticTargetListResponseWithKey) => {
      setSelectedRowKeys([record.key]);
      setSelectedTargetListSku(record);
    },
    [setSelectedTargetListSku],
  );

  const tableOnRow = useCallback(
    (record: CalculationStatisticTargetListResponseWithKey) => ({
      onClick: () => setSelection(record),
    }),
    [setSelection],
  );

  const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  }, []);

  const rowSelection: TableRowSelection<CalculationStatisticTargetListResponseWithKey> = useMemo(
    () => ({
      type: 'radio',
      selectedRowKeys,
      onChange: onSelectChange,
      columnWidth: 0,
      renderCell: () => null,
    }),
    [onSelectChange, selectedRowKeys],
  );

  const toggleOmitFullFilled = useCallback(() => {
    setOmitFullFilled(prevValue => !prevValue);
  }, []);

  const tableChangeHandler = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      setTableFilters(filters);
    },
    [],
  );

  return (
    <Column $gap="1rem" $alignItems="flex-end" style={tableMarginTop}>
      <Row $gap="1rem">
        <LabeledToggle
          label="Hide fullfilled"
          checked={omitFullFilled}
          labelPlacement={LabelPlacement.LEFT}
          onClick={toggleOmitFullFilled}
        />
        <TableTargetListItemsExport
          title={`Target list statistics - ${targetList?.name} (#${templateOrCalculation.id})`}
          dataSource={dataWithKeys}
          columns={columns}
        />
      </Row>
      <TableWrapper>
        <SimpleTable<CalculationStatisticTargetListResponseWithKey>
          id="target-list-items"
          scroll={tableScroll}
          dataSource={dataWithKeys}
          columns={columns}
          loading={isFetching || isFetchingTargetLists}
          showHeader={true}
          rowClassName={rowClassName}
          onRow={tableOnRow}
          rowSelection={rowSelection}
          hideSelectionColumn={true}
          showSorterTooltip={false}
          onChange={tableChangeHandler}
          height={selectedTargetListSku ? computeRemSize(120) : computeRemSize(500)}
        />
      </TableWrapper>
    </Column>
  );
};

export default TableTargetListItems;
