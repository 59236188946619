import React, { useMemo } from 'react';
import { useGetSourceStoreTargetStoresCategoriesEvaluationRatesQuery } from './apiCalculationEvaluations';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import {
  evaluationsRowClassName,
  EvaluationSummaryExtended,
  getBaseExecutionColumns,
} from './evaluationsLib';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import { useCurrency } from '../../../hooks/useCurrency';
import { SimpleTable } from '../Statistics/components/SimpleTable';
import { computeRemSize } from '@ydistri/ds';
import { styled } from 'styled-components';
import { COLORS } from '../../../styles/style';
import {
  EntityColumns,
  EntityColumnsConfig,
  useStoreTableColumnsProvider,
} from '../../../hooks/useEntityTableColumnsProvider';

const Header = styled.b`
  margin-left: ${computeRemSize(48)};
`;
const TableWrapper = styled.div`
  margin-left: ${computeRemSize(48)};
  margin-right: ${computeRemSize(300)};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  border: 2px solid ${COLORS.BORDER};
`;

interface TargetStoreDetailTableProps {
  sourceStoreId: number;
}

const TargetStoreDetailTable: React.FC<TargetStoreDetailTableProps> = ({ sourceStoreId }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const clusterType = useSelector((state: ReduxState) => state.evaluations.clusterType);
  const currencyId = useCurrency() ?? '...';
  const columnsProvider = useStoreTableColumnsProvider();

  const { data, isFetching: isFetchingStatistics } =
    useGetSourceStoreTargetStoresCategoriesEvaluationRatesQuery({
      calculationId: templateOrCalculation.id,
      clusterType,
      sourceStoreId,
    });

  const columns = useMemo(() => {
    const columnsConfig: EntityColumnsConfig<EvaluationSummaryExtended> = {
      [EntityColumns.ID]: {
        width: computeRemSize(100),
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(100),
      },
    };
    const storeColumns = columnsProvider<EvaluationSummaryExtended>(columnsConfig);

    return getBaseExecutionColumns(
      currencyId,
      clusterType === CalculationStatisticClusterType.StoreDepartment,
      storeColumns,
      false,
    );
  }, [columnsProvider, currencyId, clusterType]);

  return (
    <>
      {isFetchingStatistics && <LoadingIndicator />}
      {data && (
        <>
          <Header>Target stores:</Header>
          <TableWrapper>
            <SimpleTable<EvaluationSummaryExtended>
              id={`target-store-evaluations-${sourceStoreId}`}
              dataSource={data}
              columns={columns}
              loading={isFetchingStatistics}
              showHeader={true}
              rowClassName={evaluationsRowClassName}
            />
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default TargetStoreDetailTable;
