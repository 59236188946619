import EligibleSource from './EligibleSource';
import PortfolioStructureBar from './PortfolioStructureBar';
import { Column, COLORS, IconButton_IconSizes, Title, Button } from '@ydistri/ds';
import ExcessiveStockBar from './ExcessiveStockBar/ExcessiveStockBar';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ALIGN_SELF_CENTER,
  parseCategorySummaryIntoSourceInsightsData,
} from '../categoryInsightsLib';
import { MdAdd, MdRemove } from 'react-icons/md';
import useCategoryId from '../../../../hooks/useCategoryId';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { GetCategoryInsightsParams, useGetCategoryInsightsQuery } from '../apiCategoryInsights';
import { Spin } from 'antd';

const showMoreIcon = <MdAdd size={IconButton_IconSizes.small} />;
const showLessIcon = <MdRemove size={IconButton_IconSizes.small} />;

export const PotentialSourceAnalysis: React.FC = () => {
  const categoryId = useCategoryId();
  const templateOrCalculation = useTemplateOrCalculation();
  const categoryInsightsParam: GetCategoryInsightsParams = {
    categoryId,
    templateOrCalculation,
  };

  const { data: categoryData, isLoading: isCategoryInsightsLoading } =
    useGetCategoryInsightsQuery(categoryInsightsParam);
  const [showMore, setShowMore] = useState(false);

  const sourceData = useMemo(() => {
    if (categoryData?.summaries) {
      return parseCategorySummaryIntoSourceInsightsData(
        categoryData.summaries,
        categoryData.attributes,
      );
    }
    return undefined;
  }, [categoryData?.attributes, categoryData?.summaries]);

  const toggleShowMore = useCallback(() => setShowMore(p => !p), []);

  if (isCategoryInsightsLoading) {
    return <Spin spinning={true}> Loading data...</Spin>;
  }

  if (!sourceData) {
    return (
      <Title $size="small">
        No data available for this {templateOrCalculation.type.toLowerCase()}
      </Title>
    );
  }

  return (
    <>
      <EligibleSource data={sourceData} />
      <PortfolioStructureBar data={sourceData} />
      <Column $gap="2rem">
        <ExcessiveStockBar
          data={sourceData.excessiveStock.total}
          title="Total Dead stock and overstock"
        />
        {showMore && (
          <>
            <ExcessiveStockBar
              data={sourceData.excessiveStock.deadstock}
              title="Dead stock"
              baseColor={COLORS.BAR_DEADSTOCK}
              highlightedColor={COLORS.BAR_DEADSTOCK}
            />
            <ExcessiveStockBar
              data={sourceData.excessiveStock.slowmovers}
              title="Overstock of slow moving items"
              baseColor={COLORS.BAR_SLOWMOVERS}
              highlightedColor={COLORS.BAR_SLOWMOVERS}
            />
            <ExcessiveStockBar
              data={sourceData.excessiveStock.fastmovers}
              title="Overstock of fast moving items"
              baseColor={COLORS.BAR_FASTMOVERS}
              highlightedColor={COLORS.BAR_FASTMOVERS}
            />
          </>
        )}
        <div style={ALIGN_SELF_CENTER}>
          <Button
            $textVariant="semibold"
            icon={showMore ? showLessIcon : showMoreIcon}
            type="primary"
            onClick={toggleShowMore}
          >
            {showMore ? 'Hide' : 'Show'} by inventory types
          </Button>
        </div>
      </Column>
    </>
  );
};
