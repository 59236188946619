import React, { useCallback } from 'react';
import { SubmenuOption } from '../../screens/Configuration/ConfigurationHeader';
import { Link } from 'react-router-dom';
import { Button } from '@ydistri/ds';

interface MenuSubpageButtonProps {
  submenuOption: SubmenuOption;
  selected?: boolean;
  partialPath: string;
  disabled?: boolean;
  onClick?: () => void;
}

const MenuSubpageButton: React.FC<MenuSubpageButtonProps> = ({
  submenuOption,
  selected,
  partialPath,
  onClick,
  ...rest
}) => {
  const onClickHandler = useCallback(() => {
    if (onClick && !partialPath) onClick();
  }, [onClick, partialPath]);

  return (
    <Link to={partialPath}>
      <Button
        $colorVariant={selected ? 'selected' : 'ghost'}
        $textVariant="semibold"
        onClick={onClickHandler}
        data-type="menu-subpage-button"
        data-selected={selected}
        {...rest}
      >
        {submenuOption.label}
      </Button>
    </Link>
  );
};

export default MenuSubpageButton;
