import React from 'react';
import {
  BorderOutlined,
  HomeFilled,
  HomeOutlined,
  HomeTwoTone,
  LogoutOutlined,
  RightCircleOutlined,
  SettingOutlined,
  EditOutlined,
  PlusOutlined,
  RightOutlined,
  DragOutlined,
  RestOutlined,
  LineChartOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  ControlOutlined,
  MergeCellsOutlined,
  ProjectOutlined,
  ShopOutlined,
  SisternodeOutlined,
  UserOutlined,
  ShareAltOutlined,
  NodeIndexOutlined,
  PieChartOutlined,
  HolderOutlined,
  SkinOutlined,
  TagOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  ApartmentOutlined,
  FlagOutlined,
  CarOutlined,
  BulbOutlined,
  SafetyOutlined,
  SolutionOutlined,
  TeamOutlined,
  ToolOutlined,
  LockOutlined,
  UnlockOutlined,
  ProfileOutlined,
  LayoutOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

export enum IconType {
  HOME_TWO_TONE = 1,
  HOME_FILLED,
  HOME_OUTLINED,
  BORDER_OUTLINED,
  RIGHT_CIRCLE_OUTLINED,
  SETTING_OUTLINED,
  LOGOUT_OUTLINED,
  EDIT_OUTLINED,
  PLUS_OUTLINED,
  RIGHT_OUTLINED,
  DRAG_OUTLINED,
  REST_OUTLINED,
  LINE_CHART_OUTLINED,
  CALENDAR_OUTLINED,
  CARRY_OUT_OUTLINED,
  CONTROL_OUTLINED,
  MERGE_CELLS_OUTLINED,
  PROJECT_OUTLINED,
  SHOP_OUTLINED,
  SISTERNODE_OUTLINED,
  USER_OUTLINED,
  SHARE_ALT_OUTLINED,
  NODE_INDEX_OUTLINED,
  TAG_OUTLINED,
  SKIN_OUTLINED,
  HOLDER_OUTLINED,
  PIE_CHART_OUTLINED,
  BAR_CHART_OUTLINED,
  AREA_CHART_OUTLINED,
  APARTMENT_OUTLINED,
  FLAG_OUTLINED,
  CAR_OUTLINED,
  BULB_OUTLINED,
  SAFETY_OUTLINED,
  SOLUTION_OUTLINED,
  TEAM_OUTLINED,
  TOOL_OUTLINED,
  LOCK_OUTLINED,
  UNLOCK_OUTLINED,
  PROFILE_OUTLINED,
  LAYOUT_OUTLINED,
  DELETE_OUTLINED,
  MORE_OUTLINED,
  PLAY_CIRCLE_OUTLINED,
  RELOAD_OUTLINED,
}

export const icons: Record<IconType, React.ReactNode> = {
  [IconType.HOME_TWO_TONE]: React.createElement(HomeTwoTone),
  [IconType.HOME_FILLED]: React.createElement(HomeFilled),
  [IconType.HOME_OUTLINED]: React.createElement(HomeOutlined),
  [IconType.BORDER_OUTLINED]: React.createElement(BorderOutlined),
  [IconType.RIGHT_CIRCLE_OUTLINED]: React.createElement(RightCircleOutlined),
  [IconType.SETTING_OUTLINED]: React.createElement(SettingOutlined),
  [IconType.LOGOUT_OUTLINED]: React.createElement(LogoutOutlined),
  [IconType.EDIT_OUTLINED]: React.createElement(EditOutlined),
  [IconType.PLUS_OUTLINED]: React.createElement(PlusOutlined),
  [IconType.RIGHT_OUTLINED]: React.createElement(RightOutlined),
  [IconType.DRAG_OUTLINED]: React.createElement(DragOutlined),
  [IconType.REST_OUTLINED]: React.createElement(RestOutlined),
  [IconType.LINE_CHART_OUTLINED]: React.createElement(LineChartOutlined),
  [IconType.CALENDAR_OUTLINED]: React.createElement(CalendarOutlined),
  [IconType.CARRY_OUT_OUTLINED]: React.createElement(CarryOutOutlined),
  [IconType.CONTROL_OUTLINED]: React.createElement(ControlOutlined),
  [IconType.MERGE_CELLS_OUTLINED]: React.createElement(MergeCellsOutlined),
  [IconType.PROJECT_OUTLINED]: React.createElement(ProjectOutlined),
  [IconType.SHOP_OUTLINED]: React.createElement(ShopOutlined),
  [IconType.SISTERNODE_OUTLINED]: React.createElement(SisternodeOutlined),
  [IconType.USER_OUTLINED]: React.createElement(UserOutlined),
  [IconType.SHARE_ALT_OUTLINED]: React.createElement(ShareAltOutlined),
  [IconType.NODE_INDEX_OUTLINED]: React.createElement(NodeIndexOutlined),
  [IconType.TAG_OUTLINED]: React.createElement(TagOutlined),
  [IconType.SKIN_OUTLINED]: React.createElement(SkinOutlined),
  [IconType.HOLDER_OUTLINED]: React.createElement(HolderOutlined),
  [IconType.PIE_CHART_OUTLINED]: React.createElement(PieChartOutlined),
  [IconType.BAR_CHART_OUTLINED]: React.createElement(BarChartOutlined),
  [IconType.AREA_CHART_OUTLINED]: React.createElement(AreaChartOutlined),
  [IconType.APARTMENT_OUTLINED]: React.createElement(ApartmentOutlined),
  [IconType.FLAG_OUTLINED]: React.createElement(FlagOutlined),
  [IconType.CAR_OUTLINED]: React.createElement(CarOutlined),
  [IconType.BULB_OUTLINED]: React.createElement(BulbOutlined),
  [IconType.SAFETY_OUTLINED]: React.createElement(SafetyOutlined),
  [IconType.SOLUTION_OUTLINED]: React.createElement(SolutionOutlined),
  [IconType.TEAM_OUTLINED]: React.createElement(TeamOutlined),
  [IconType.TOOL_OUTLINED]: React.createElement(ToolOutlined),
  [IconType.LOCK_OUTLINED]: React.createElement(LockOutlined),
  [IconType.UNLOCK_OUTLINED]: React.createElement(UnlockOutlined),
  [IconType.PROFILE_OUTLINED]: React.createElement(ProfileOutlined),
  [IconType.LAYOUT_OUTLINED]: React.createElement(LayoutOutlined),
  [IconType.DELETE_OUTLINED]: React.createElement(DeleteOutlined),
  [IconType.MORE_OUTLINED]: React.createElement(MoreOutlined),
  [IconType.PLAY_CIRCLE_OUTLINED]: React.createElement(PlayCircleOutlined),
  [IconType.RELOAD_OUTLINED]: React.createElement(ReloadOutlined),
};
