import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { Label, Row } from '@ydistri/ds';
import { styled } from 'styled-components';
import {
  getColorBasedOnCalculationActionStatus,
  getIconBasedOnCalculationActionStatus,
  getLabelBasedOnCalculationActionStatus,
} from './calculationsActionLib';
import { AvailableModals } from '../modalSlice';

export type CalculationActionStatus = 'ready' | 'progress' | 'success' | 'error';
export type CalculationActionStatuses = Record<number, CalculationActionStatus | undefined>;

const ColoredRow = styled(Row)<{ $color: CSSProperties['color'] }>`
  color: ${p => p.$color};
`;

interface CalculationActionStatusComponentProps {
  calculationId: number;
}

const CalculationActionStatusComponent: React.FC<CalculationActionStatusComponentProps> = ({
  calculationId,
}) => {
  const status = useSelector(
    (state: ReduxState) => state.calculationsAction.calculationStatuses[calculationId] ?? 'ready',
  );
  const calculationActionType = useSelector(
    (state: ReduxState) =>
      state.modal.modalData[AvailableModals.MULTI_CALCULCATION_ACTIONS]?.calculationActionType,
  );

  const icon = getIconBasedOnCalculationActionStatus(status);
  const color = getColorBasedOnCalculationActionStatus(status);
  const label = getLabelBasedOnCalculationActionStatus(status, calculationActionType);

  return (
    <ColoredRow $gap="0.5rem" $color={color} $alignItems="center">
      {icon}
      <Label>{label}</Label>
    </ColoredRow>
  );
};

export default CalculationActionStatusComponent;
