import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationSkusQuery } from '../../../../../../Calculations/apiCalculationsSkus';
import LoadingIndicator from '../../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { useGetCalculationQuery } from '../../../../../apiCalculationDetail';
import CalculationsTable from '../../../../../../Calculations/components/table/CalculationsTable';
import {
  mergeCalculationWithSubordinateCalculations,
  SUBCALCULATION_SELECTOR_TABLE_ID,
  SubcalculationExtension,
  SubcalculationSelectorCalculationData,
  getSubordinateCalculationsTableColumnExtension,
} from './subordinateCalculationSelectorLib';
import { CalculationCheckboxMode } from '../../../../../../Calculations/components/table/CalculationCheckbox/calculationCheckboxLib';

interface SubordinateCalculationSelectorProps {
  sourceSkuId: number;
  targetSkuId: number;
}

const SubordinateCalculationSelector: React.FC<SubordinateCalculationSelectorProps> = ({
  sourceSkuId,
  targetSkuId,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculation, isFetching: calculationFetching } = useGetCalculationQuery(
    templateOrCalculation.id,
  );

  const { data: sourceSkuConfiguration, isFetching: sourceSkuConfigurationFetching } =
    useGetCalculationSkusQuery({
      skuId: sourceSkuId,
      calculationId: templateOrCalculation.id,
    });

  const { data: targetSkuConfiguration, isFetching: targetSkuConfigurationFetching } =
    useGetCalculationSkusQuery({
      skuId: targetSkuId,
      calculationId: templateOrCalculation.id,
    });

  const data: SubcalculationSelectorCalculationData[] = useMemo(
    () =>
      mergeCalculationWithSubordinateCalculations(
        calculation,
        sourceSkuConfiguration,
        targetSkuConfiguration,
      ),
    [calculation, sourceSkuConfiguration, targetSkuConfiguration],
  );

  const selectableRules = useCallback(
    (c: SubcalculationSelectorCalculationData) => !(c.sourceAvailable || c.targetAvailable),
    [],
  );

  const columnsExtension = useMemo(
    () => getSubordinateCalculationsTableColumnExtension(sourceSkuId, targetSkuId),
    [sourceSkuId, targetSkuId],
  );

  const isFetching =
    calculationFetching || sourceSkuConfigurationFetching || targetSkuConfigurationFetching;

  if (isFetching) return <LoadingIndicator />;

  return (
    <CalculationsTable<SubcalculationExtension>
      calculationsTableId={SUBCALCULATION_SELECTOR_TABLE_ID}
      predefinedCalculations={data}
      displayCreationTime={false}
      variant="compact"
      showActions={false}
      linksEnabled={false}
      selectableMode={CalculationCheckboxMode.SINGLE}
      selectableRules={selectableRules}
      expandableSubmerged={false}
      columnsExtension={columnsExtension}
      autoHeight={true}
    />
  );
};

export default SubordinateCalculationSelector;
