import React, { Fragment, useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { Column, Row, Text } from '@ydistri/ds';
import TextArea from 'antd/es/input/TextArea';
import {
  MultiselectKeysToParse,
  MultiselectParseResponse,
} from '../Configuration/ConfigurationStoresAndDepartments/ConfigurationMultiselect';

const titles = {
  customerId: 'ID',
  code: 'Code',
};

interface MultiselectValidationModalProps {
  parsedData?: MultiselectParseResponse;
  onClose?: () => void;
  onSubmit?: (data: number[]) => void;
}

const MultiselectValidationModal: React.FC<MultiselectValidationModalProps> = ({
  parsedData,
  onClose,
  onSubmit,
}) => {
  const onCloseHandler = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const onSubmitHandler = useCallback(() => {
    if (parsedData && onSubmit) onSubmit(parsedData.ids);
  }, [parsedData, onSubmit]);

  const availableTypes: MultiselectKeysToParse[] = useMemo(() => {
    if (parsedData) {
      return (
        Object.entries(parsedData.availability)
          .filter(entry => entry[1])
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          .map(entry => entry[0] as MultiselectKeysToParse)
      );
    }
    return [];
  }, [parsedData]);
  console.log('Inside modal...');

  if (!parsedData) {
    console.log('We dont have parsed data!');
    return null;
  }

  return (
    <Modal
      open={true}
      title="Pasted data inconsistency"
      onCancel={onCloseHandler}
      okText="It is okay, insert identified values"
      cancelText="Do not insert anything"
      onOk={onSubmitHandler}
    >
      <Column $gap="1rem">
        <Text>
          We couldn&apos;t recognize some of the pasted data
          {availableTypes.length > 1 && (
            <Text>
              {' '}
              (we assumed <Text $bold>{titles[parsedData.parsedBy]}</Text> was pasted)
            </Text>
          )}
        </Text>
        <Row $gap="1rem">
          {availableTypes.map(type => {
            return (
              <Fragment key={type}>
                <Text $bold>{titles[type]}:</Text>
                <Text>
                  {parsedData.counter[type]} of {parsedData.counter.total} identified{' '}
                </Text>
              </Fragment>
            );
          })}
        </Row>

        <Text $bold>These {titles[parsedData.parsedBy].toLowerCase()}s were not identified:</Text>
        <TextArea
          rows={Math.min(10, parsedData.notRecognized.length + 3)}
          value={parsedData.notRecognized.join('\n')}
        />
      </Column>
    </Modal>
  );
};

export default MultiselectValidationModal;
