import React from 'react';
import { Button } from 'antd';
import TableExport from '../../../../../components/global/TableExport/TableExport';
import { ColumnsType } from 'antd/es/table';

interface TableStorePickingResultsExportProps<T> {
  title?: string;
  dataSource: T[];
  columns: ColumnsType<T>;
}

const TableStorePickingResultsExport = <T extends object>({
  title,
  dataSource,
  columns,
}: TableStorePickingResultsExportProps<T>): React.ReactElement => {
  return (
    <TableExport
      columns={columns}
      dataSource={dataSource}
      fileName={`${title}.xlsx`}
      sheetName="Store picking results"
    >
      <Button>Export</Button>
    </TableExport>
  );
};

export default TableStorePickingResultsExport;
