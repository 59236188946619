import React, { useMemo } from 'react';
import {
  Column,
  Title,
  BarPartitionContent,
  BarLineConnection,
  BarLineProps,
  BarLineSizes,
  BarLineTypes,
  BarLine,
  COLORS,
  ArrayTooltipContent,
} from '@ydistri/ds';
import { InsightsTargetData } from '../categoryInsightsLib';
import { computePercentage, formatNumber, formatPercentage, shortenNumber } from '@ydistri/utils';
import { useCurrency } from '../../../../hooks/useCurrency';
import { Popover } from 'antd';

interface TargetStructureBarProps {
  data: InsightsTargetData;
}

const TargetStructureBar: React.FC<TargetStructureBarProps> = ({ data }) => {
  const currency = useCurrency();
  const targetPotential = data.targetPotential.total;

  const tooltipData = useMemo(() => {
    return {
      theoretical: [
        {
          title: 'Theoretical potential',
          value: data.theoreticalPotential,
          postfix: currency,
          isTitle: true,
        },
      ],
      targetPotentialForSourcesWithPotential: [
        {
          title: 'Target potential for sources with potential',
          value: data.targetPotentialForSourcesWithPotential,
          postfix: currency,
          isTitle: true,
        },
      ],
      targetPotentialWithNoSourcePotential: [
        {
          title: 'Target potential with no source potential',
          value: data.targetPotentialWithNoSourcePotential,
          postfix: currency,
          isTitle: true,
        },
      ],
    };
  }, [
    currency,
    data.targetPotentialForSourcesWithPotential,
    data.targetPotentialWithNoSourcePotential,
    data.theoreticalPotential,
  ]);

  const barLineData: BarLineProps['data'] = useMemo(() => {
    const totalValues =
      data.theoreticalPotential +
      data.targetPotentialWithNoSourcePotential +
      data.targetPotentialForSourcesWithPotential;
    const percentageTheoretical = computePercentage(data.theoreticalPotential, totalValues);
    const percentageTargetPotentialForSourcesWithPotential = computePercentage(
      data.targetPotentialForSourcesWithPotential,
      totalValues,
    );
    const percentageTargetPotentialWithNoSourcePotential =
      100 - percentageTheoretical - percentageTargetPotentialForSourcesWithPotential;

    return [
      {
        percentage: percentageTheoretical,
        color: COLORS.BAR_THEORETICAL,
        tooltip: <ArrayTooltipContent data={tooltipData.theoretical} />,
        content: (
          <BarPartitionContent title="Theoretical potential" value={data.theoreticalPotential} />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 45,
        highlightedPercentage: 100,
      },
      {
        percentage: percentageTargetPotentialForSourcesWithPotential,
        color: COLORS.BAR_TARGET_PARTIAL_SOURCE,
        tooltip: <ArrayTooltipContent data={tooltipData.targetPotentialForSourcesWithPotential} />,
        content: (
          <BarPartitionContent
            title="Target potential for sources with potential"
            value={data.targetPotentialForSourcesWithPotential}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 95,
      },
      {
        percentage: percentageTargetPotentialWithNoSourcePotential,
        color: COLORS.BAR_TARGET_NO_SOURCE,
        tooltip: <ArrayTooltipContent data={tooltipData.targetPotentialWithNoSourcePotential} />,
        content: (
          <BarPartitionContent
            title="Target potential with no source potential"
            value={data.targetPotentialWithNoSourcePotential}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 85,
      },
    ];
  }, [data, tooltipData]);

  const potentialPercentage = useMemo(() => {
    const subSum =
      data.targetPotential.byTargetList +
      data.targetPotential.byForecast +
      data.targetPotential.byMinLayers;
    const sum = subSum + data.targetPotential.inventory + data.targetPotential.openPurchaseOrders;
    return computePercentage(subSum, sum);
  }, [data]);

  return (
    <Column $gap="0.5rem">
      <Title>
        <Popover content={`${formatNumber(targetPotential)} ${currency}`}>
          Target potential ({shortenNumber(targetPotential)} {currency})
        </Popover>
      </Title>
      <BarLine type={BarLineTypes.HORIZONTAL} size={BarLineSizes.LARGE} data={barLineData} />
      <BarLineConnection
        $widthTop={formatPercentage(barLineData[0].percentage)}
        $widthBottom={formatPercentage(potentialPercentage)}
      />
    </Column>
  );
};

export default TargetStructureBar;
