import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { rtkQueryPatchStatusToConfigurationFieldStatus, SkuTypeColumn } from '@ydistri/utils';
import {
  ConfigurationDataType,
  ConfigurationFieldType,
  EntityListResponse,
  FieldConfigurationDeleteRequest,
  FieldConfigurationRequest,
  SkuType,
} from '@ydistri/api-sdk';
import { Popover } from 'antd';
import { ConfigurationFieldWrapper } from '../../../../../components/global/ConfigurationField/ConfigurationField';
import { computeRemSize, ConfigurationSwitch } from '@ydistri/ds';
import useCategoryId from '../../../../../hooks/useCategoryId';
import {
  ExceptionInfo,
  useCategoryConfiguration,
} from '../../../../../hooks/useCategoryConfiguration';
import { usePatchCategoryConfigurationMutation } from '../../../apiTemplate';
import { useDeleteCategoryConfigurationMutation } from '../../../../../components/global/CategoryTree/apiCategoryTree';
import {
  createEntityListConfig,
  EntityListConfiguration,
  isEntityListTarget,
  parseEntityListConfigs,
} from './entityListLib';
import { createPayload } from '../../../../../components/global/ConfigurationField/createPayload';
import { getConfigurationFieldHoverData } from '../../../../../components/global/ConfigurationField/getConfigurationFieldHoverData';

interface ConfigurationEntityListFieldProps {
  disabled?: boolean;
  skuTypeColumn: SkuTypeColumn;
  fieldType: ConfigurationFieldType;
  dataType: ConfigurationDataType;
  entityList: EntityListResponse;
  forcedCategoryId?: number;
}
const popoverWidth = { width: computeRemSize(500), minWidth: computeRemSize(500) };

const ConfigurationEntityListField: React.FC<ConfigurationEntityListFieldProps> = ({
  disabled = false,
  skuTypeColumn,
  fieldType,
  dataType,
  entityList,
  forcedCategoryId,
}) => {
  const selectedCategoryId = useCategoryId();
  const categoryId = forcedCategoryId ?? selectedCategoryId;
  const [hovered, setHovered] = useState(false);
  const { rootCategoryConfiguration, categoryConfigurationData: configurations } =
    useCategoryConfiguration(categoryId);

  const validSkuType: SkuType = useMemo(
    () =>
      skuTypeColumn === null || skuTypeColumn === 'Target' ? SkuType.FastMover : skuTypeColumn,
    [skuTypeColumn],
  );

  const data = useMemo(() => {
    const parsedRootConfigs = parseEntityListConfigs(
      rootCategoryConfiguration?.[fieldType][validSkuType] ?? '[]',
    );
    let finalConfigsForAllEntityLists = [...parsedRootConfigs];
    const reversedExceptions = (
      configurations?.[fieldType][validSkuType]?.exceptions ?? []
    ).toReversed();

    const exceptionsForThisEntityList: ExceptionInfo[] = [];
    reversedExceptions.forEach(e => {
      const parsedException = parseEntityListConfigs(e.value);
      const exceptionForThisEntityList = parsedException.find(
        e => e.EntityListId === entityList.entityListId,
      );
      if (exceptionForThisEntityList) {
        exceptionsForThisEntityList.push(e);
      }
      finalConfigsForAllEntityLists = finalConfigsForAllEntityLists.map(
        c => parsedException.find(ele => ele.EntityListId === c.EntityListId) ?? c,
      );
    });

    const currentExceptionForThisCategory = parseEntityListConfigs(
      reversedExceptions.find(e => e.categoryId === categoryId)?.value ?? '[]',
    );
    const hasExceptionOnCategory = currentExceptionForThisCategory.length > 0;
    const hasParentException =
      exceptionsForThisEntityList.find(e => e.categoryId !== categoryId) !== undefined;
    const hasExceptionOnCategoryEntityList = currentExceptionForThisCategory.find(
      e => e.EntityListId === entityList.entityListId,
    );
    const finalConfig =
      finalConfigsForAllEntityLists.find(e => e.EntityListId === entityList.entityListId)
        ?.IsEnabled === 1;

    const data = {
      finalConfigsForAllEntityLists,
      finalConfig,
      currentExceptionForThisCategory,
      exceptionsForThisEntityList,
      hasParentException,
      hasExceptionOnCategory,
      hasExceptionOnCategoryEntityList,
    };

    return data;
  }, [
    categoryId,
    entityList.entityListId,
    rootCategoryConfiguration,
    configurations,
    fieldType,
    validSkuType,
  ]);

  const handleHoverChange = useCallback((open: boolean) => {
    setHovered(open);
  }, []);

  const [patchConfiguration, patchConfigurationStatus] = usePatchCategoryConfigurationMutation();
  const [deleteConfiguration, { isLoading: clearing }] = useDeleteCategoryConfigurationMutation();

  const status = useMemo(
    () => rtkQueryPatchStatusToConfigurationFieldStatus(patchConfigurationStatus),
    [patchConfigurationStatus],
  );

  useEffect(() => {
    patchConfigurationStatus.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const updateConfigHandler = useCallback(
    (value: string) => {
      const newValues: EntityListConfiguration[] = (
        categoryId === 1 ? data.finalConfigsForAllEntityLists : data.currentExceptionForThisCategory
      ).filter(v => v.EntityListId !== entityList.entityListId);

      newValues.push(createEntityListConfig(entityList.entityListId, parseInt(value)));

      const payload: FieldConfigurationRequest[] = createPayload(
        {
          configurationFieldType: fieldType,
          value: JSON.stringify(newValues),
          skuType: undefined,
        },
        isEntityListTarget(fieldType) ? 'Target' : skuTypeColumn,
      );

      patchConfiguration({
        categoryId,
        payload,
      });
    },
    [
      categoryId,
      data.finalConfigsForAllEntityLists,
      data.currentExceptionForThisCategory,
      fieldType,
      skuTypeColumn,
      patchConfiguration,
      entityList.entityListId,
    ],
  );

  const clearExceptionHandler = useCallback(() => {
    const newValues: EntityListConfiguration[] = data.currentExceptionForThisCategory?.filter(
      v => v.EntityListId !== entityList.entityListId,
    );

    if (newValues.length > 0 || categoryId === 1) {
      const basePayload: FieldConfigurationRequest = {
        configurationFieldType: fieldType,
        value: JSON.stringify(newValues),
        skuType: undefined,
      };

      const payload: FieldConfigurationRequest[] = createPayload(basePayload, skuTypeColumn);
      patchConfiguration({
        categoryId,
        payload,
      });
    } else {
      const basePayload: FieldConfigurationDeleteRequest = {
        configurationFieldType: fieldType,
        skuType: null,
      };
      const payload: FieldConfigurationDeleteRequest[] = createPayload(basePayload, skuTypeColumn);
      deleteConfiguration({
        categoryId,
        fields: payload,
      });
    }

    setHovered(false);
  }, [
    data.currentExceptionForThisCategory,
    categoryId,
    entityList.entityListId,
    fieldType,
    skuTypeColumn,
    patchConfiguration,
    deleteConfiguration,
  ]);

  const hoverData = useMemo(
    () =>
      getConfigurationFieldHoverData({
        selectedCategoryId: categoryId,
        exceptions: data.exceptionsForThisEntityList,
        rootCategoryConfiguration,
        fieldType,
        dataType,
        validSkuType,
        clearing,
        disabled,
        clearExceptionHandler,
        entityListId: entityList.entityListId,
      }),
    [
      categoryId,
      data.exceptionsForThisEntityList,
      rootCategoryConfiguration,
      fieldType,
      dataType,
      validSkuType,
      clearing,
      disabled,
      clearExceptionHandler,
      entityList.entityListId,
    ],
  );

  return (
    <Popover
      style={popoverWidth}
      content={hoverData}
      trigger="hover"
      placement={skuTypeColumn === 'Target' ? 'topRight' : undefined}
      open={hovered && !!hoverData}
      onOpenChange={handleHoverChange}
    >
      <ConfigurationFieldWrapper $skuTypeColumn={skuTypeColumn}>
        <ConfigurationSwitch
          status={status}
          overwritten={!!data.hasExceptionOnCategoryEntityList}
          parentException={data.hasParentException}
          value={data.finalConfig}
          disabled={disabled}
          onChange={updateConfigHandler}
        />
      </ConfigurationFieldWrapper>
    </Popover>
  );
};

export default ConfigurationEntityListField;
