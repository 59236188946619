import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

const useIsRootCategory = (): boolean => {
  const { slug } = useParams();

  return useMemo(() => slug === '1', [slug]);
};

export default useIsRootCategory;
