import React, { ReactNode, useMemo } from 'react';
import { RoutingPage } from '../../routes/routerSlice';
import { MenuItemIcon, MenuNavLink, Text } from '@ydistri/ds';
import { Tooltip } from 'antd';
import { CountBadge } from '../global/CountBadge';
import { useValidation } from '../../hooks/useValidation';
import { useSider } from '../../hooks/useSider';
import { LeftMenuItem } from '@ydistri/utils';

interface MenuItemProps {
  item: LeftMenuItem;
}

const childItemBadgeOffset: [string | number, string | number] = [-24, 0];
const validationsStyle = { fontSize: '.75rem' };

/**
 * Item in menu displayed on the left either collapsed or expanded
 * @param item
 * @constructor
 */
const MenuItem: React.FC<MenuItemProps> = ({ item }: MenuItemProps) => {
  const validations = useValidation();
  const { isLeftMenuCollapsed } = useSider();

  let badgeCount = 0;
  let badgeColor = 'blue';
  let badgeText: ReactNode | undefined = undefined;

  if (item.subpageId && item.parentId && item.parentId === RoutingPage.CONFIGURATION.toString()) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const subpage = item.subpageId as keyof typeof validations;
    badgeCount = validations[subpage].length;
    let maxValidationLevel = 'info';
    validations[subpage].forEach(validation => {
      if (validation.type === 'error') {
        maxValidationLevel = 'error';
        badgeColor = 'red';
      } else if (validation.type === 'warning' && maxValidationLevel === 'info') {
        maxValidationLevel = 'warning';
        badgeColor = 'orange';
      }
    });

    badgeCount = 0;

    if (badgeCount > 0) {
      badgeText = (
        <>
          {item.label}
          <ul style={validationsStyle}>
            {validations[subpage].map(validation => (
              <li key={validation.key}>{validation.msg}</li>
            ))}
          </ul>
        </>
      );
    }
  }

  const tooltipTitle = useMemo(() => {
    if (badgeText) {
      return badgeText;
    } else {
      if (isLeftMenuCollapsed) {
        return item.label;
      }
    }
  }, [badgeText, item, isLeftMenuCollapsed]);

  return (
    <MenuNavLink
      to={item.path}
      key={item.key}
      $isCollapsed={isLeftMenuCollapsed}
      $isSelected={item.isSelected}
      $isChild={item.isChild}
      $hasChild={item.hasChild}
      $isOpened={item.isOpened}
      data-type="MenuItem"
      data-testid={item.label}
      role="menuitem"
      data-selected={item.isSelected}
    >
      <Tooltip title={tooltipTitle} placement="right">
        <CountBadge
          dot={false}
          size="small"
          count={badgeCount}
          $color={badgeColor}
          offset={childItemBadgeOffset}
        >
          <MenuItemIcon $childItem={item.isChild}>{item.icon}</MenuItemIcon>
        </CountBadge>
      </Tooltip>
      {!isLeftMenuCollapsed && (
        <Text $size="large" $semiBold>
          {item.label}
        </Text>
      )}
      {isLeftMenuCollapsed && item.isChild && (
        <Text $size="large" $semiBold>
          {item.shortcut}
        </Text>
      )}
    </MenuNavLink>
  );
};

export default MenuItem;
