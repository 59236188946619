import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  emptyValidatorSectionMessageInfo,
  emptyValidatorSectionMessages,
  ValidatorMessage,
  ValidatorSection,
  ValidatorSectionMessageInfo,
  ValidatorSectionMessages,
  ValidatorStatus,
} from './validatorLib';
import {
  getInitialValidatorInfo,
  initialValidatorOverviewMessage,
  ValidatorInfo,
  ValidatorOverviewMessage,
} from './validatorInfoLib';

interface SetPartialValidatorSectionMessagesPayload {
  section: ValidatorSection;
  messages: ValidatorMessage[];
}

interface SetPartialValidatorInfoPayload<T extends ValidatorSection> {
  section: T;
  data: ValidatorInfo[T];
}

interface SetPartialValidatorOverviewMessagePayload {
  section: ValidatorSection;
  data: string;
}

interface ValidatorState {
  modal: boolean;
  modalContent: boolean;
  status: ValidatorStatus;
  sectionMessages: ValidatorSectionMessages;
  sectionMessageInfo: ValidatorSectionMessageInfo;
  info: ValidatorInfo;
  overviewMessages: ValidatorOverviewMessage;
}

const initialState: ValidatorState = {
  modal: false,
  modalContent: false,
  status: ValidatorStatus.RUNNING,
  sectionMessages: { ...emptyValidatorSectionMessages },
  sectionMessageInfo: { ...emptyValidatorSectionMessageInfo },
  info: getInitialValidatorInfo(),
  overviewMessages: { ...initialValidatorOverviewMessage },
};

export const validatorSlice = createSlice({
  name: 'validator',
  initialState,
  reducers: {
    toggleValidatorModal: state => {
      const willOpen = !state.modal;
      if (willOpen) {
        state.modal = true;
        state.modalContent = true;
      } else {
        state.modal = false;
      }
    },
    toggleValidatorModalContent: state => {
      state.modalContent = !state.modalContent;
    },
    setValidatorStatus: (state, action: PayloadAction<ValidatorStatus>) => {
      state.status = action.payload;
    },
    setPartialValidatorSectionMessages: (
      state,
      action: PayloadAction<SetPartialValidatorSectionMessagesPayload>,
    ) => {
      state.sectionMessages[action.payload.section] = action.payload.messages;
    },
    setValidatorSectionMessageInfo: (state, action: PayloadAction<ValidatorSectionMessageInfo>) => {
      state.sectionMessageInfo = action.payload;
    },
    setPartialValidatorInfo: <T extends ValidatorSection>(
      state: ValidatorState,
      action: PayloadAction<SetPartialValidatorInfoPayload<T>>,
    ) => {
      state.info[action.payload.section] = action.payload.data;
    },
    setPartialValidatorOverviewMessage: (
      state,
      action: PayloadAction<SetPartialValidatorOverviewMessagePayload>,
    ) => {
      state.overviewMessages[action.payload.section] = action.payload.data;
    },
    resetState: () => ({
      ...initialState,
    }),
  },
});

export const {
  toggleValidatorModal,
  toggleValidatorModalContent,
  setValidatorStatus,
  setPartialValidatorSectionMessages,
  setValidatorSectionMessageInfo,
  setPartialValidatorInfo,
  setPartialValidatorOverviewMessage,
} = validatorSlice.actions;

export const validatorReducer = validatorSlice.reducer;
