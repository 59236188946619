import { MultiLevelDataSource } from './tablesTypes';

/**
 * Extract data of given level from Data source that contains multiple levels.
 * Ant Design displays such data source as a tree table.
 * Export may need just a part of the data source, so this function extracts the data of given level.
 * @param dataSource
 * @param level
 */
export const extractData = <T extends MultiLevelDataSource<T>>(dataSource: T[], level = 1): T[] => {
  if (level <= 1) {
    return dataSource;
  }

  const result: T[] = [];
  dataSource.forEach(row => {
    if (row.children) {
      result.push(...extractData(row.children, level - 1));
    }
  });

  return result;
};
