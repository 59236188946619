import React, { useCallback, useMemo, useState } from 'react';
import { Column, IconButton, IconButton_IconSizes, Row } from '@ydistri/ds';
import { CalculationResponse, CalculationType } from '@ydistri/api-sdk';
import CountableText from '../../../../components/global/CountableText/CountableText';
import ListCalculationsModal from './ListCalculationsModal';
import { MdViewHeadline } from 'react-icons/md';

interface ListCalculationsProps {
  calculations: CalculationResponse[];
}

/** Usage statistics of the TargetList/ProductList */
interface ListStats {
  /** Number of configurations using the TargetList/ProductList */
  configurationCount: number;
  /** Number of calculations using the TargetList/ProductList */
  calculationsCount: number;
}

const listIcon = <MdViewHeadline size={IconButton_IconSizes.small} />;

/**
 * Compute in how many calculations/configurations the TargetList is used.
 * @param calculations
 */
const computeUsageStatistics = (calculations: CalculationResponse[]): ListStats => {
  let configurationCount = 0;
  let calculationsCount = 0;

  calculations.forEach(calculation => {
    if (calculation.type === CalculationType.Template) {
      configurationCount++;
    } else {
      calculationsCount++;
    }
  });

  return {
    configurationCount,
    calculationsCount,
  };
};

/**
 * Displays usage statistics of the TargetList/ProductList.
 * It will say in how many calculations/configurations the TargetList/ProductList is used
 * with the option to display the full list of calculations/configurations
 * in a modal.
 * @param calculations
 * @constructor
 */
const ListCalculations: React.FC<ListCalculationsProps> = ({ calculations }) => {
  const [showFullList, setShowFullList] = useState(false);

  const statsComponents = useMemo(() => {
    const usageStatistics = computeUsageStatistics(calculations);

    const result: React.JSX.Element[] = [];
    if (usageStatistics.configurationCount > 0) {
      result.push(
        <CountableText value={usageStatistics.configurationCount} word="Configuration" />,
      );
    }

    if (usageStatistics.calculationsCount > 0) {
      result.push(<CountableText value={usageStatistics.calculationsCount} word="Calculation" />);
    }

    return result;
  }, [calculations]);

  const showClickHandler = useCallback(() => {
    setShowFullList(true);
  }, []);

  const fullListClosedHandler = useCallback(() => {
    setShowFullList(false);
  }, []);

  return (
    <>
      <Row $gap="2rem">
        <Column $gap="0.35rem">{statsComponents}</Column>
        <IconButton
          icon={listIcon}
          onClick={showClickHandler}
          size="small"
          tooltip="Show list of calculations/configurations"
        />
      </Row>
      {showFullList && (
        <ListCalculationsModal calculations={calculations} onDialogClosed={fullListClosedHandler} />
      )}
    </>
  );
};

export default ListCalculations;
