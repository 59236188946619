import { styled } from 'styled-components';
import { Text } from '@ydistri/ds';
import React, { PropsWithChildren } from 'react';

export const ListItemPrimaryWrapper = styled(Text)`
  font-weight: 500;
`;

const ListItemPrimary: React.FC<PropsWithChildren> = ({ children }) => {
  return <ListItemPrimaryWrapper $size="large">{children}</ListItemPrimaryWrapper>;
};

export default ListItemPrimary;
