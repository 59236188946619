import React, { useMemo } from 'react';
import { Popover } from 'antd';
import { Column, Text } from '@ydistri/ds';
import { MdWarning } from 'react-icons/md';
import { ScopeErrorType } from './scopeLib2';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';

export type ScopeConfigurationFieldRowWarningType =
  | 'closingAndSuperTargetAtOnce'
  | 'departmentClosingSuperTargetWarning'
  | ScopeErrorType;

interface ScopeConfigurationFieldRowWarningProps {
  scopeId: number;
  warningType: ScopeConfigurationFieldRowWarningType;
  value: number | boolean | null | undefined;
  title: string;
}

const ScopeConfigurationFieldRowWarning: React.FC<ScopeConfigurationFieldRowWarningProps> = ({
  scopeId,
  warningType,
  value,
  title,
}) => {
  const scopeOverlapErrors = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.scopesWithErrors[scopeId],
  );
  const scopeList = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.scopeList.object,
  );

  const popoverContent: React.ReactNode = useMemo(() => {
    if (warningType === 'closingAndSuperTargetAtOnce')
      return 'Can not be Closing and Super target at once';
    if (warningType === 'departmentClosingSuperTargetWarning')
      return 'Department-only scopes can not be used as closing or super target';
    if (warningType === ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET) {
      return (
        <Column $gap="1rem">
          <Column>
            <Text>
              Exception with higher priority is set as &apos;Closing&apos;, but its scope overlaps
              with this exception.
            </Text>
            <Text>
              That causes some stores to be{' '}
              <b>&apos;Super Target&apos; and &apos;Closing&apos; at once</b>.
            </Text>
          </Column>
          <Column>
            <Text>These exceptions cause this warning:</Text>
            <ul>
              {scopeOverlapErrors?.isClosingOverlapsSuperTarget?.map(e => (
                <li key={e}>{scopeList[e]?.name ?? `Exception ${e}`}</li>
              ))}
            </ul>
          </Column>
        </Column>
      );
    }
    if (warningType === ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING) {
      return (
        <Column $gap="1rem">
          <Column>
            <Text>
              Exception with higher priority is set as &apos;Super target&apos;, but its scope
              overlaps with this exception.
            </Text>
            <Text>
              That causes some stores to be{' '}
              <b>&apos;Super Target&apos; and &apos;Closing&apos; at once</b>.
            </Text>
          </Column>
          <Column>
            <Text>These exceptions cause this warning:</Text>
            <ul>
              {scopeOverlapErrors?.isSuperTargetOverlapsClosing?.map(e => (
                <li key={e}>{scopeList[e]?.name ?? `Exception ${e}`}</li>
              ))}
            </ul>
          </Column>
        </Column>
      );
    }
  }, [
    scopeList,
    scopeOverlapErrors?.isClosingOverlapsSuperTarget,
    scopeOverlapErrors?.isSuperTargetOverlapsClosing,
    warningType,
  ]);

  return (
    <Popover content={popoverContent}>
      <Text $type={value !== null ? 'danger' : 'light'} $size="large" $semiBold>
        <MdWarning size={16} /> {title}
      </Text>
    </Popover>
  );
};

export default ScopeConfigurationFieldRowWarning;
