import React, { useCallback, useMemo } from 'react';
import { ReadOnlyItem, ReadOnlyItemProps, Text } from '@ydistri/ds';
import NoData from '../../../../components/global/NoData/NoData';
import CalculationsList from '../../../Calculations/common/CalculationsList';
import ListCalculations from './ListCalculations';
import { CalculationResponse, CalculationType } from '@ydistri/api-sdk';
import { useUser } from '../../../../hooks/useUser';
import { createDebugLog } from '../../../../lib/utils/logging';

const debugLog = createDebugLog('ListCalculationsWrapper');

/**
 * Filter out private calculations where the user is not the owner.
 * @param calculations
 * @param userId
 */
const getVisibleCalculations = (
  calculations: CalculationResponse[],
  userId: string,
): CalculationResponse[] => {
  return calculations.filter(calculation => {
    //if calculation is private, the user must be the owner to see it.
    return calculation.type !== CalculationType.Private || calculation.identityUserId === userId;
  });
};

interface ListCalculationsWrapperProps {
  calculations: CalculationResponse[];
  fetching: boolean;
  labelWidth: ReadOnlyItemProps['labelWidth'];
}

const ListCalculationsWrapper: React.FC<ListCalculationsWrapperProps> = ({
  calculations,
  fetching,
  labelWidth,
}) => {
  const user = useUser();

  /**
   * Filter out calculations not available to the user.
   * If the calculation is Private, the user must be the owner to see it or be a superuser.
   */
  const filterCalculations = useCallback(
    (calculations: CalculationResponse[]): CalculationResponse[] => {
      debugLog('filtering calculations');
      if (user) {
        if (user.isSuperUser) {
          //superuser can see all calculations
          debugLog('user is super user, returning all calculations');
          return calculations;
        } else {
          const result = getVisibleCalculations(calculations, user.id);
          debugLog('returning %d calculations out of %d', result.length, calculations.length);
          return result;
        }
      } else {
        debugLog('user is not set, returning all calculations');
        return calculations;
      }
    },
    [user],
  );

  /**
   * Display different output based on conditions:
   * - if fetching, display no data component
   * - if no calculations, display "Not used"
   * - if no visible calculations, display "Private calculations" as the user cannot see them
   * - if only one or two calculations, display them in a list as they fit the ui and we can get rid of one click
   * - if more than two calculations, display a link to a modal with the full list of calculations
   */
  const calculationsComponent = useMemo(() => {
    if (fetching) {
      return <NoData />;
    }

    if (calculations.length === 0) {
      return <Text>Not used</Text>;
    }

    const visibleCalculations = filterCalculations(calculations);
    if (visibleCalculations.length === 0) {
      return <Text>Private calculations</Text>;
    }

    if (visibleCalculations.length < 3) {
      return <CalculationsList calculations={calculations} showType={true} />;
    }

    return <ListCalculations calculations={visibleCalculations} />;
  }, [fetching, calculations, filterCalculations]);

  return (
    <ReadOnlyItem label="Used in" labelWidth={labelWidth} alignItems="flex-start">
      {calculationsComponent}
    </ReadOnlyItem>
  );
};

export default ListCalculationsWrapper;
