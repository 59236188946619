import React, { useCallback, useEffect } from 'react';
import { computeRemSize, Section, MainContent } from '@ydistri/ds';
import { useDispatch } from 'react-redux';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetSkuClassConfigurationQuery } from './apiSkuClasses';
import { SkuClassConfigurationResponse, SkuClassResponse } from '@ydistri/api-sdk';
import { ColumnsType } from 'antd/es/table';
import SkuClassConfigurationSwitch from './SkuClassConfigurationSwitch';
import { Table } from 'antd';
import { booleanSorter } from '@ydistri/utils';

const skuClassConfigurationColumns: ColumnsType<SkuClassConfigurationResponse> = [
  {
    title: 'Name',
    dataIndex: 'skuClass',
    key: 'name',
    width: computeRemSize(300),
    render: (skuClass: SkuClassResponse) => skuClass.name,
    sorter: (a, b) => (a.skuClass?.name ?? '').localeCompare(b.skuClass?.name ?? ''),
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Use as source',
    dataIndex: 'useAsSource',
    key: 'useAsSource',
    width: computeRemSize(150),
    sorter: (a, b) => booleanSorter(a.useAsSource, b.useAsSource),
    render: (useAsSource: boolean, record: SkuClassConfigurationResponse) => {
      return (
        <SkuClassConfigurationSwitch
          value={useAsSource}
          skuClassId={record.skuClass?.id ?? 0}
          field="useAsSource"
        />
      );
    },
  },
  {
    title: 'Use as target',
    dataIndex: 'useAsTarget',
    key: 'useAsTarget',
    width: computeRemSize(150),
    sorter: (a, b) => booleanSorter(a.useAsTarget, b.useAsTarget),
    render: (useAsTarget: boolean, record: SkuClassConfigurationResponse) => {
      return (
        <SkuClassConfigurationSwitch
          value={useAsTarget}
          skuClassId={record.skuClass?.id ?? 0}
          field="useAsTarget"
        />
      );
    },
  },
  {
    title: 'Enabled',
    dataIndex: 'isEnabled',
    key: 'isEnabled',
    sorter: (a, b) => booleanSorter(a.isEnabled, b.isEnabled),
    render: (isEnabled: boolean, record: SkuClassConfigurationResponse) => {
      return (
        <SkuClassConfigurationSwitch
          value={isEnabled}
          skuClassId={record.skuClass?.id ?? 0}
          field="isEnabled"
        />
      );
    },
  },
];

const ConfigurationSkuClasses: React.FC = () => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: skuClassConfigurations, isFetching } =
    useGetSkuClassConfigurationQuery(templateOrCalculation);

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.SKUCLASSES));
  });

  const rowKey = useCallback((r: SkuClassConfigurationResponse) => r.skuClass?.id ?? 0, []);

  return (
    <MainContent>
      <Section header="SKU classes">
        <Table
          loading={isFetching}
          columns={skuClassConfigurationColumns}
          dataSource={skuClassConfigurations}
          rowKey={rowKey}
          size="small"
          pagination={false}
        />
      </Section>
    </MainContent>
  );
};

export default ConfigurationSkuClasses;
