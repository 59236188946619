import {
  ApiOperationType,
  Operation,
  ProductListConfigurationRequest,
  ProductListConfigurationResponse,
  ProductResponse,
  SortDirection,
} from '@ydistri/api-sdk';
import { apiSlice, TemplateOrCalculation, wrongTemplateInHeaders } from '../../../apis/api';
import {
  CalculationsCollection,
  CurrentSetupCollection,
  ProductListsCollection,
} from '../../../swagger/collections';
import {
  SignalConfigurationChanged,
  SignalConfigurationsChanged,
} from '../../../signalr/signalrInterfaces';
import { signalrClient } from '../../../signalr/client';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
  serializeFullApiBasedQueryArgs,
  serializeQueryArgsForInfiniteScroll,
} from '@ydistri/ds';
import { getTags } from '../../../apis/apiLib';
import { SignalProjectConfigurationChanged } from '../../ProjectAdministration/common/administrationItemsTypes';
import { addToast } from '@ydistri/utils';
import { ReduxState } from '../../../store';

const { TAGS, TAGS_ARRAY } = getTags('productListConfiguration');

export interface GetProductListConfigurationRequest extends InfiniteScrollParams {
  templateOrCalculation: TemplateOrCalculation;
}

export interface UpdateProductListConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  productListId: number;
  request: ProductListConfigurationRequest;
}

export interface GetProductListProductsPayload {
  productListId: number;
  top?: number;
  skip?: number;
  search?: string;
}

export interface DeleteProductListConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  productListId: number;
}

/**
 * Sorter for product list configuration object. Objects are sorted by isConfigured first
 * than by name.
 * @param left
 * @param right
 */
const productListConfigSort = (
  left: ProductListConfigurationResponse,
  right: ProductListConfigurationResponse,
): number => {
  if (left.isConfigured && !right.isConfigured) {
    return -1;
  } else if (!left.isConfigured && right.isConfigured) {
    return 1;
  } else {
    if (left.name !== null) {
      return (left.name ?? '').localeCompare(right.name ?? '');
    } else {
      return 1;
    }
  }
};

export const apiProductLists = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getProductListConfiguration: builder.query<
        ProductListConfigurationResponse[], //Expanded
        GetProductListConfigurationRequest
      >({
        queryFn: async arg => {
          if (wrongTemplateInHeaders(arg.templateOrCalculation)) return { data: [] };

          const query = {
            top: arg.top,
            skip: arg.skip,
            inlineCount: true,
            sortings: [
              { fieldName: 'IsConfigured', direction: SortDirection.Desc },
              ...(arg.sortings ?? []),
            ],
            conditions: arg.conditions,
            search: arg.search,
          };

          const {
            data: { data: productListConfiguration },
          } = await (arg.templateOrCalculation.type === 'Template'
            ? CurrentSetupCollection.getCurrentProductListConfigurationsV2(query)
            : CalculationsCollection.getCalculationProductListsV2(
                arg.templateOrCalculation.id,
                query,
              ));

          return { data: productListConfiguration.filter(pl => pl.isConfigured || !pl.isArchived) };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.productListConfiguration, id: JSON.stringify(arg) },
        ],
        serializeQueryArgs: serializeFullApiBasedQueryArgs(),
        merge: mergeForInfiniteScroll<
          ProductListConfigurationResponse,
          GetProductListConfigurationRequest
        >('productListId'),
        forceRefetch: forceRefetchForInfiniteScroll<
          GetProductListConfigurationRequest | undefined
        >(),
        async onCacheEntryAdded(
          // eslint-disable-next-line no-unused-vars
          { templateOrCalculation: { type: objectType, id: selectedTemplateId } },
          api,
        ) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              console.log('CONFIGURATION CHANGED', signal);
              if (selectedTemplateId !== signal.templateId) return;
              if (
                signal.entityType === 'ProductListConfiguration' &&
                signal.operationType === ApiOperationType.Patch
              ) {
                const productListId = signal.entityId;

                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const state = api.getState() as ReduxState;
                const queryKeys = apiProductLists.util.selectCachedArgsForQuery(
                  state,
                  'getProductListConfiguration',
                );

                queryKeys.forEach(qk => {
                  apiProductLists.util.updateQueryData('getProductListConfiguration', qk, draft => {
                    signal.updates.forEach(update => {
                      const productList = draft.find(r => r.productListId === productListId);
                      if (productList && update.value !== undefined) {
                        productList.isEnabled = update.value === '1';
                        productList.isConfigured = true;
                      }
                    });
                  });
                });
              }
            };

            const listenerForDeletes = (signals: SignalConfigurationsChanged[]) => {
              console.log('CONFIGURATIONS CHANGED', signals);
              signals.forEach(signal => {
                if (selectedTemplateId !== signal.templateId) return;
                const productListId = signal.entityId;
                if (signal.entityType === 'ProductListConfiguration') {
                  if (signal.operationType === ApiOperationType.Delete) {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const state = api.getState() as ReduxState;
                    const queryKeys = apiProductLists.util.selectCachedArgsForQuery(
                      state,
                      'getProductListConfiguration',
                    );

                    queryKeys.forEach(qk => {
                      apiProductLists.util.updateQueryData(
                        'getProductListConfiguration',
                        qk,
                        draft => {
                          const pl = draft.find(r => r.productListId === productListId);
                          if (pl) {
                            pl.isEnabled = null;
                            pl.isConfigured = false;
                          }
                        },
                      );
                    });
                  }
                }
              });
            };

            const listenerForProjectConfiguration = (signal: SignalProjectConfigurationChanged) => {
              console.log('Project configuration CHANGED', signal);

              if (signal.entityType !== 'ProductList') {
                return;
              }

              switch (signal.operationType) {
                case ApiOperationType.Create:
                case ApiOperationType.Replace:
                case ApiOperationType.Patch:
                  {
                    //when entry changes or is created, we need to update the cache

                    CurrentSetupCollection.getCurrentProductListConfigurationsV2({
                      conditions: [
                        {
                          fieldName: 'ProductListId',
                          operation: Operation.Eq,
                          value: signal.entityId,
                        },
                      ],
                    }).then(response => {
                      api.updateCachedData(draft => {
                        response.data.data.forEach(productListConfiguration => {
                          const plIndex = draft.findIndex(
                            r => r.productListId === productListConfiguration.productListId,
                          );
                          if (plIndex > -1) {
                            //if the product list was archived by the patch, we need to remove it from the list (but only
                            //if it was not configured)
                            if (
                              productListConfiguration.isArchived &&
                              !draft[plIndex].isArchived &&
                              !draft[plIndex].isConfigured
                            ) {
                              draft.splice(plIndex, 1);
                              api.dispatch(
                                addToast({
                                  message: `Product List '${productListConfiguration.name}' has been archived and cannot be used.`,
                                }),
                              );
                            } else {
                              draft[plIndex] = productListConfiguration;
                            }
                          } else {
                            //insert the item where it belongs in the sorted data
                            //the sorted items may have sorting values like this [-1, -1, -1, 1, 1, 1]
                            //we need to find the first index of 1, that is where the new item belongs
                            const insertionIndex = draft.findIndex(
                              existingItem =>
                                productListConfigSort(existingItem, productListConfiguration) === 1,
                            );

                            //either append the item to the end or insert it at the sorted position
                            if (insertionIndex === -1) {
                              draft.push(productListConfiguration);
                            } else {
                              draft.splice(insertionIndex, 0, productListConfiguration);
                            }

                            api.dispatch(
                              addToast({
                                message: `New Product List '${productListConfiguration.name}' has been added to the list.`,
                              }),
                            );
                          }
                        });
                      });
                    });
                  }
                  break;
                case ApiOperationType.Delete:
                  {
                    api.updateCachedData(draft => {
                      const indexToDelete = draft.findIndex(
                        pl => pl.productListId === signal.entityId,
                      );
                      if (indexToDelete > -1) {
                        const plName = draft[indexToDelete].name;
                        draft.splice(indexToDelete, 1);
                        api.dispatch(
                          addToast({
                            message: `Product List '${plName}' has been deleted.`,
                          }),
                        );
                      }
                    });
                  }
                  break;
                case ApiOperationType.Read: {
                  //we are not interested in reads
                }
              }
            };

            signalrClient.on('TemplateConfigurationChanged', listener);
            signalrClient.on('TemplateConfigurationsChanged', listenerForDeletes);

            //we listen for changes only when a template is open, not calculation
            if (objectType === 'Template') {
              signalrClient.on('ProjectConfigurationChanged', listenerForProjectConfiguration);
            }

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener);
              signalrClient.off('TemplateConfigurationsChanged', listenerForDeletes);
              if (objectType === 'Template') {
                signalrClient.off('ProjectConfigurationChanged', listenerForProjectConfiguration);
              }
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),

      updateProductListConfiguration: builder.mutation<
        ProductListConfigurationResponse,
        UpdateProductListConfigurationPayload
      >({
        queryFn: async payload => {
          const {
            data: { data: productListConfiguration },
          } = await CurrentSetupCollection.postCurrentProductListConfiguration(
            payload.productListId,
            payload.request,
          );

          return { data: productListConfiguration };
        },
        onQueryStarted(payload, api) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const state = api.getState() as ReduxState;
          const queryKeys = apiProductLists.util.selectCachedArgsForQuery(
            state,
            'getProductListConfiguration',
          );

          queryKeys.forEach(qk => {
            const patchResult = api.dispatch(
              apiProductLists.util.updateQueryData('getProductListConfiguration', qk, draft => {
                const productList = draft.find(r => r.productListId === payload.productListId);
                if (productList)
                  Object.assign(productList, {
                    isEnabled: payload.request.isEnabled,
                    isConfigured: true,
                  });
              }),
            );
            api.queryFulfilled.catch(patchResult.undo);
          });
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      deleteProductListConfiguration: builder.mutation<void, DeleteProductListConfigurationPayload>(
        {
          queryFn: async payload => {
            await CurrentSetupCollection.deleteCurrentProductListConfiguration(
              payload.productListId,
            );
            return { data: undefined };
          },
          onQueryStarted(payload, api) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const state = api.getState() as ReduxState;
            const queryKeys = apiProductLists.util.selectCachedArgsForQuery(
              state,
              'getProductListConfiguration',
            );

            queryKeys.forEach(qk => {
              const patchResult = api.dispatch(
                apiProductLists.util.updateQueryData('getProductListConfiguration', qk, draft => {
                  const productList = draft.find(r => r.productListId === payload.productListId);
                  if (productList)
                    Object.assign(productList, {
                      isEnabled: null,
                      isConfigured: false,
                    });
                }),
              );
              api.queryFulfilled.catch(patchResult.undo);
            });
          },
        },
      ),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      resetProductListConfiguration: builder.mutation<void, TemplateOrCalculation>({
        queryFn: async () => {
          await CurrentSetupCollection.deleteCurrentProductListConfigurations();
          return { data: undefined };
        },
        onQueryStarted(payload, api) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const state = api.getState() as ReduxState;
          const queryKeys = apiProductLists.util.selectCachedArgsForQuery(
            state,
            'getProductListConfiguration',
          );

          queryKeys.forEach(qk => {
            const patchResult = api.dispatch(
              apiProductLists.util.updateQueryData('getProductListConfiguration', qk, draft => {
                draft.forEach(b => {
                  if (b.isConfigured) b.isConfigured = false;
                });
              }),
            );
            api.queryFulfilled.catch(patchResult.undo);
          });
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getProductListProducts: builder.query<
        ProductResponse[], //Expanded
        GetProductListProductsPayload
      >({
        queryFn: async payload => {
          console.log('Inside query function', payload);

          const {
            data: { data: productListProducts },
          } = await ProductListsCollection.getProducts(payload.productListId, {
            top: payload.top,
            skip: payload.skip ?? 0,
            inlineCount: true,
            search: payload.search,
            sortings: [{ fieldName: 'Name', direction: SortDirection.Asc }],
          });

          return { data: productListProducts };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.productListConfigurationProducts, id: `plp-${arg.productListId}` },
        ],
        serializeQueryArgs: serializeQueryArgsForInfiniteScroll<GetProductListProductsPayload>(),
        merge: mergeForInfiniteScroll<ProductResponse, GetProductListProductsPayload>(),
        forceRefetch: forceRefetchForInfiniteScroll<GetProductListProductsPayload | undefined>(),
      }),
    }),
  });

export const {
  useGetProductListConfigurationQuery,
  useUpdateProductListConfigurationMutation,
  useDeleteProductListConfigurationMutation,
  useResetProductListConfigurationMutation,
  useGetProductListProductsQuery,
} = apiProductLists;
