import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import ChangePassword from './ChangePassword/ChangePassword';

const UserSettings: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(RoutingPage.USER_SETTINGS));
  }, [dispatch]);

  return <ChangePassword />;
};

export default UserSettings;
