import { SkuAttrs } from '../../../lib/sku/skuTypes';
import { Column, Row, Label, Text } from '@ydistri/ds';
import React, { PropsWithChildren, useCallback } from 'react';
import CountableText from '../../global/CountableText/CountableText';
import { formatDate, fullMonthDiff } from '@ydistri/utils';
import { useCurrency } from '../../../hooks/useCurrency';
import Price from './Price';

interface SkuAttributesProps {
  sku: SkuAttrs;
}

interface SKUAttributeProps extends PropsWithChildren {
  label: string;
}

const SKUAttribute: React.FC<SKUAttributeProps> = ({ label, children }) => {
  return (
    <Column>
      <Text $size="small">{label}</Text>
      <Text $size="large">{children}</Text>
    </Column>
  );
};

const applicationDate = new Date();
const SKUAttributes: React.FC<SkuAttributesProps> = ({ sku }) => {
  const currency = useCurrency();

  const renderSales = useCallback(() => {
    const saleValues = [];
    if (sku.dates.dateOfFirstSale) {
      saleValues.push(
        <CountableText
          value={fullMonthDiff(sku.dates.dateOfFirstSale, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from first sale (${formatDate(sku.dates.dateOfFirstSale)})`}
        />,
      );
    }

    if (sku.dates.dateOfLastSale) {
      saleValues.push(
        <CountableText
          value={fullMonthDiff(sku.dates.dateOfLastSale, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from last sale (${formatDate(sku.dates.dateOfLastSale)})`}
        />,
      );
    }

    return (
      <SKUAttribute label="Sales">
        <Column>{saleValues.length > 0 ? <Column>{saleValues}</Column> : 'No sales info'}</Column>
      </SKUAttribute>
    );
  }, [sku]);

  const renderPurchases = useCallback(() => {
    const purchaseValues = [];
    if (sku.dates.dateOfFirstPurchase) {
      purchaseValues.push(
        <CountableText
          value={fullMonthDiff(sku.dates.dateOfFirstPurchase, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from first purchase (${formatDate(sku.dates.dateOfFirstPurchase)})`}
        />,
      );
    }

    if (sku.dates.dateOfLastPurchase) {
      purchaseValues.push(
        <CountableText
          value={fullMonthDiff(sku.dates.dateOfLastPurchase, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from last purchase (${formatDate(sku.dates.dateOfLastPurchase)})`}
        />,
      );
    }

    return (
      <SKUAttribute label="Purchases">
        <Column>
          {purchaseValues.length > 0 ? <Column>{purchaseValues}</Column> : 'No purchase info'}
        </Column>
      </SKUAttribute>
    );
  }, [sku]);

  return (
    <Row $gap="3rem" $padding={1.5}>
      <SKUAttribute label="Available supply">
        <Label>{`${sku.values.AvailableSupply ?? '-'} MU`}</Label>
      </SKUAttribute>
      <SKUAttribute label="Open purchase order">
        <Label>{`${sku.values.OpenPurchaseOrdersQuantity ?? '-'} MU`}</Label>
      </SKUAttribute>

      <SKUAttribute label="Sale frequency">
        <Column>
          <CountableText
            value={sku.values.Frequency6Month}
            highlighting="both"
            word="sale"
            suffix="in last 6 months"
            key="frequency6"
          />
          <CountableText
            value={sku.values.Frequency12Month}
            highlighting="both"
            word="sale"
            suffix="in last 12 months"
            key="frequency12"
          />
        </Column>
      </SKUAttribute>
      {renderSales()}

      {renderPurchases()}
      {/* TODOXX */}
      {/*<SKUAttribute label="Minimum layers">
        <Column>
          <Text></Text>
        </Column>
      </SKUAttribute>*/}
      <SKUAttribute label="Prices (without VAT) ">
        <Column>
          <Price label="Cost price" currency={currency} price={sku.values.StockPrice} />
          <Price label="Sale price" currency={currency} price={sku.values.SalePrice} />
          <Price label="VAT" currency="%" price={sku.values.Vat} />
        </Column>
      </SKUAttribute>
    </Row>
  );
};

export default SKUAttributes;
