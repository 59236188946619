import React, { useCallback, useEffect, useState } from 'react';
import { ActionsBar, Label, Row } from '@ydistri/ds';
import ForecastConfidenceButton from './ForecastConfidenceButton';

interface ForecastConfidenceProps {
  values: number[];
  options: number[];
  onValuesChanged: (values: number[]) => void;
}

const isSelected = (value: number, values: number[]) => {
  return values.includes(value);
};

const ForecastConfidence: React.FC<ForecastConfidenceProps> = ({
  values,
  options,
  onValuesChanged,
}: ForecastConfidenceProps) => {
  const [isCtrlHold, setIsCtrlHold] = useState(false);

  const controlHoldStart = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Control' && !event.repeat) {
      setIsCtrlHold(true);
    }
  }, []);

  const controlHoldEnd = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Control') {
      setIsCtrlHold(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', controlHoldStart);
    window.addEventListener('keyup', controlHoldEnd);

    return () => {
      window.removeEventListener('keydown', controlHoldStart);
      window.removeEventListener('keyup', controlHoldEnd);
    };
  }, [controlHoldEnd, controlHoldStart]);

  const handleOptionClick = useCallback(
    (option: number) => {
      //if the option is already selected and it is the only one selected, do nothing
      if (isSelected(option, values) && values.length === 1) {
        return;
      }

      //if the option is already selected and there are other options selected, remove it
      if (isSelected(option, values) && values.length > 1) {
        onValuesChanged(values.filter(value => value !== option));
        return;
      }

      //if the option is not selected and ctrl is not hold, remove all other options and add this one
      if (!isSelected(option, values) && !isCtrlHold) {
        onValuesChanged([option]);
        return;
      }

      //if the option is not selected and ctrl is hold, add it to the list
      if (!isSelected(option, values) && isCtrlHold) {
        onValuesChanged([...values, option]);
        return;
      }
    },
    [isCtrlHold, onValuesChanged, values],
  );

  return (
    <Row $alignItems="center" $gap="1rem">
      <Label>Forecasting approach:</Label>
      <ActionsBar>
        {options.map(option => (
          <ForecastConfidenceButton
            selected={isSelected(option, values)}
            key={option}
            option={option}
            onClicked={handleOptionClick}
          />
        ))}
      </ActionsBar>
    </Row>
  );
};

export default ForecastConfidence;
