import { useBlocker } from 'react-router-dom';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { GenericConfirmationModal } from '@ydistri/ds';

interface PromptProps {
  message: string;
  blocked: boolean;
  cleanup?: () => void;
}

const Prompt: React.FC<PromptProps> = ({ message, blocked, cleanup }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const blocker = useBlocker(isBlocked); //useBlocker(isFormDirty)

  //workaround according to issue https://github.com/remix-run/react-router/issues/10144
  useLayoutEffect(() => {
    setIsBlocked(true);
  }, []);

  React.useEffect(() => {
    if (blocker.state === 'blocked' && !blocked) {
      blocker.reset();
    }
  }, [blocker, blocked]);

  const handleClose = useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  const handleConfirm = useCallback(() => {
    cleanup?.();
    blocker.proceed?.();
  }, [blocker, cleanup]);

  if (blocker.state === 'blocked') {
    return (
      <GenericConfirmationModal
        title={message}
        onConfirmed={handleConfirm}
        onCanceled={handleClose}
        data-testid="NavigateAwayModal"
      />
    );
  } else {
    return null;
  }
};

export default Prompt;
