import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrandConfigurationResponse } from '@ydistri/api-sdk';

interface BrandConfigurationState {
  selectedBrandConfiguration?: BrandConfigurationResponse | undefined;
}

const initialState: BrandConfigurationState = {
  selectedBrandConfiguration: undefined,
};

export const brandConfigurationSlice = createSlice({
  name: 'brandConfiguration',
  initialState,
  reducers: {
    setSelectedBrandConfiguration: (
      state,
      action: PayloadAction<BrandConfigurationState['selectedBrandConfiguration']>,
    ) => {
      state.selectedBrandConfiguration = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setSelectedBrandConfiguration } = brandConfigurationSlice.actions;

export const brandConfigurationReducer = brandConfigurationSlice.reducer;
