import React, { useEffect } from 'react';
import { Section } from '@ydistri/ds';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import { EntityListType } from '@ydistri/api-sdk';
import EntityListSteps from '../steps/EntityListSteps';
import { setVerificationTimeStamp } from '../../entityListAdministrationSlice';

interface EntityListEditModeSectionProps {
  entityListType: EntityListType;
}

const EntityListEditModeSection: React.FC<EntityListEditModeSectionProps> = ({
  entityListType,
}) => {
  const dispatch = useDispatch();

  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  useEffect(() => {
    dispatch(
      setVerificationTimeStamp({
        entityListType,
        data: selectedEntityList?.entityListImport?.verificationTimeStamp,
      }),
    );
  }, [dispatch, entityListType, selectedEntityList?.entityListImport?.verificationTimeStamp]);

  if (!selectedEntityList) {
    return null;
  } else {
    return (
      <Section header={selectedEntityList?.name}>
        <EntityListSteps entityListType={entityListType} />
      </Section>
    );
  }
};

export default EntityListEditModeSection;
