import {
  CalculationConfigurationResponse,
  ProductListConfigurationResponse,
} from '@ydistri/api-sdk';
import {
  ValidatorMessage,
  ValidatorMessageSeverity,
  ValidatorResponse,
  ValidatorSection,
} from './validatorLib';
import { createDebugLog } from '../../../lib/utils/logging';
import { initialValidatorInfoProductLists } from './validatorInfoLib';
import { plural } from '@ydistri/utils';

const debugLog = createDebugLog('Validator', 'validateProductLists');

enum Messages {
  NO_ENABLED_PRODUCT_LISTS,
}

const section = ValidatorSection.PRODUCT_LISTS;

type PartialMessage = Omit<ValidatorMessage, 'section'>;

const addMessage = (condition: boolean, messages: ValidatorMessage[], message: PartialMessage) => {
  if (!condition) return;
  messages.push({
    section,
    ...message,
  });
};

const MESSAGES = {
  noEnabledStores: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'General setting is "All disabled", but no product list is enabled.',
    key: Messages.NO_ENABLED_PRODUCT_LISTS,
  },
} as const;

export const validateProductLists = (
  productListConfig: ProductListConfigurationResponse[],
  templateConfig: CalculationConfigurationResponse,
): ValidatorResponse<typeof section> => {
  debugLog('productListConfig', productListConfig);

  const rsp: ValidatorResponse<typeof section> = {
    section,
    messages: [],
    info: { ...initialValidatorInfoProductLists },
    overviewMessage: '',
  };

  const allEnabled = templateConfig.productListsAllEnabled;
  let exceptionCount = 0;

  productListConfig.forEach(pl => {
    if (pl.isConfigured && !pl.isArchived && pl.productCount > 0) {
      exceptionCount++;
    }
  });

  addMessage(exceptionCount === 0 && !allEnabled, rsp.messages, MESSAGES.noEnabledStores);

  const infoMessages: string[] = [];
  if (allEnabled) {
    if (exceptionCount > 0) {
      infoMessages.push(
        `${exceptionCount} product list${plural(exceptionCount, ' is', 's are')} disabled`,
      );
    } else {
      infoMessages.push(`All product lists are enabled`);
    }
  } else {
    if (exceptionCount > 0) {
      infoMessages.push(
        `${exceptionCount} product list${plural(exceptionCount, ' is', 's are')} enabled`,
      );
    } else {
      //this is an error, no need to add another message
    }
  }

  rsp.info.allEnabled = allEnabled;
  rsp.info.exceptionCount = exceptionCount;
  rsp.overviewMessage = infoMessages.join(' ');

  return rsp;
};
