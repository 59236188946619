import { apiSlice } from '../../../apis/api';
import { CalculationsCollection } from '../../../swagger/collections';
import {
  CalculationEvaluationRatesSummaryResponse,
  CalculationStatisticClusterType,
  Operation,
} from '@ydistri/api-sdk';
import { getTags } from '../../../apis/apiLib';
import { EvaluationSummaryExtended, extendEvaluationSummary } from './evaluationsLib';

const { TAGS, TAGS_ARRAY } = getTags('calculationEvaluations');

export interface GetEvaluationsRequest {
  calculationId: number;
  departmentId?: number;
  clusterType?: CalculationStatisticClusterType;
}
export interface GetEvaluationsSummaryRequest {
  calculationId: number;
}

export interface GetSourceStoreTargetStoresExecutionRatesRequest extends GetEvaluationsRequest {
  sourceStoreId: number;
}

const evaluationsRequestKey = (a: GetEvaluationsRequest) =>
  `${a.calculationId}-${a.departmentId ?? 'x'}`;

export const apiCalculationEvaluations = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getEvaluationSummary: builder.query<
        EvaluationSummaryExtended & CalculationEvaluationRatesSummaryResponse,
        GetEvaluationsSummaryRequest
      >({
        queryFn: async ({ calculationId }) => {
          const { data } =
            await CalculationsCollection.getCalculationStatisticsEvaluationRates(calculationId);

          return {
            data: {
              executionRateLastEstimatedOn: data.data.executionRateLastEstimatedOn,
              finishExecutionUntil: data.data.finishExecutionUntil,
              calculationCreatedAt: data.data.calculationCreatedAt,
              evaluationRatesSummary: data.data.evaluationRatesSummary,
              ...extendEvaluationSummary(data.data, 'evaluationRatesSummary'),
            },
          };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationEvaluationSummary,
            id: `evalSummary-${arg.calculationId}`,
          },
        ],
      }),

      getStoreEvaluationRates: builder.query<EvaluationSummaryExtended[], GetEvaluationsRequest>({
        queryFn: async ({ calculationId, departmentId, clusterType }) => {
          const conditions = [];

          if (departmentId) {
            conditions.push({
              fieldName: 'departmentId',
              operation: Operation.Eq,
              value: departmentId,
            });
          }

          const { data } =
            await CalculationsCollection.getCalculationStatisticsSourceStoresEvaluationRates(
              calculationId,
              {
                clusterType,
                conditions,
              },
            );

          console.log(
            'RESULT: ',
            data.data.map(d => extendEvaluationSummary(d, 'evaluationSummary')),
          );

          return {
            data: data.data.map(d => extendEvaluationSummary(d, 'evaluationSummary')),
          };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.calculationStoreExecutionRates, id: evaluationsRequestKey(arg) },
        ],
      }),

      getSourceStoreTargetStoresCategoriesEvaluationRates: builder.query<
        EvaluationSummaryExtended[],
        GetSourceStoreTargetStoresExecutionRatesRequest
      >({
        queryFn: async ({ calculationId, sourceStoreId, departmentId }) => {
          const conditions = [];

          if (departmentId) {
            conditions.push({
              fieldName: 'departmentId',
              operation: Operation.Eq,
              value: departmentId,
            });
          }

          const { data } =
            await CalculationsCollection.getCalculationStatisticsSourceStoreTargetStoresExecutionRates(
              calculationId,
              sourceStoreId,
              {
                conditions,
              },
            );

          return {
            data: data.data.map(d => extendEvaluationSummary(d, 'evaluationSummary')),
          };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationSourceStoreTargetStoresExecutionRates,
            id: evaluationsRequestKey(arg),
          },
        ],
      }),
    }),
  });

export const {
  useGetEvaluationSummaryQuery,
  useGetStoreEvaluationRatesQuery,
  useGetSourceStoreTargetStoresCategoriesEvaluationRatesQuery,
} = apiCalculationEvaluations;
