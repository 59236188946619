import React from 'react';
import { MdSignalWifiStatusbarConnectedNoInternet1 } from 'react-icons/md';
import { styled } from 'styled-components';
import { COLORS } from '../../styles/style';
import { Popover } from 'antd';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store';

const IconWrapper = styled.div`
  color: ${COLORS.PRIMARY};
`;

const SignalrConnectionStatus: React.FC = () => {
  const isConnected = useSelector((state: ReduxState) => state.appReducer.signalrConnected);

  if (isConnected === false) {
    return (
      <IconWrapper>
        {isConnected}
        <Popover content="Real-time updates disconnected, trying to reconnect...">
          <MdSignalWifiStatusbarConnectedNoInternet1 size={24} />
        </Popover>
      </IconWrapper>
    );
  }

  return isConnected;
};

export default SignalrConnectionStatus;
