import { Reorder } from 'framer-motion';
import { useGetTemplatesQuery } from '../../../../apis/apiApplication';
import { getApiHeader } from '../../../../apis/getApiHeader';
import { API } from '../../../../swagger/collections';
import { skipToken } from '@reduxjs/toolkit/query';
import { TemplateSelect } from '../../../Configuration/TemplateSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { Column, Row } from '@ydistri/ds';
import TemplateRow from './TemplateRow';
import { TbPlus } from 'react-icons/tb';
import { TemplateResponse } from '@ydistri/api-sdk';
import { DragHandleBase } from '../../../../components/global/DragHandleBase/DragHandleBase';

interface TemplateMultiselectAndSortProps {
  defaultValues?: number[];
  onChangeSelectedTemplates: (v: number[]) => void;
  onRemoveTemplate?: (v: number) => void;
  onCustomTemplateEvent?: (v: TemplateResponse) => void;
  customElements: (templateId: number, index: number) => React.ReactNode;
}

const TemplateMultiselectAndSort: React.FC<TemplateMultiselectAndSortProps> = ({
  defaultValues,
  onChangeSelectedTemplates,
  onRemoveTemplate: onRemoveTemplateParent,
  onCustomTemplateEvent,
  customElements,
}) => {
  const { data: templates } = useGetTemplatesQuery(getApiHeader(API, 'Project-Code') ?? skipToken);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>(defaultValues ?? []);

  const onChangeTemplateSelected = useCallback(
    (selected: number) => {
      setSelectedTemplates(p => [...p, selected]);
      if (templates) {
        const tpl = templates.find(t => t.id === selected);
        if (tpl && onCustomTemplateEvent) onCustomTemplateEvent(tpl);
      }
    },
    [onCustomTemplateEvent, templates],
  );

  const onRemoveTemplate = useCallback(
    (templateId: number) => {
      if (onRemoveTemplateParent) {
        onRemoveTemplateParent(templateId);
      }
      setSelectedTemplates(p => p.filter(x => x !== templateId));
    },
    [onRemoveTemplateParent],
  );

  const onReorder = useCallback((x: number[]) => {
    setSelectedTemplates(x);
  }, []);

  useEffect(() => {
    onChangeSelectedTemplates(selectedTemplates);
  }, [onChangeSelectedTemplates, selectedTemplates]);

  return (
    <Column $gap="1rem">
      <Reorder.Group as="div" axis="y" values={selectedTemplates} onReorder={onReorder}>
        {selectedTemplates.map((templateId, i) => (
          <TemplateRow key={templateId} templateId={templateId} onRemoveTemplate={onRemoveTemplate}>
            {customElements(templateId, i)}
          </TemplateRow>
        ))}
      </Reorder.Group>
      <Row $flexWrap="nowrap" $gap="0.5rem">
        <DragHandleBase>
          <TbPlus size={20} />
        </DragHandleBase>
        <TemplateSelect
          templateId={null}
          templateIdsToRemove={selectedTemplates}
          onChange={onChangeTemplateSelected}
          placeholder="Select template to add"
        />
      </Row>
    </Column>
  );
};

export default TemplateMultiselectAndSort;
