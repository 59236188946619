import React, { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { MeCollection } from '../swagger/collections-identity';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice';
import { auth } from '../app';
import { Unauthorized } from '@ydistri/ds';

enum ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
}

interface PrivateRouteProps {
  component: React.ComponentType;
  path?: string;
  roles?: ROLE[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, roles = [] }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const isAuthenticated = useMemo(() => auth.isAuthenticated(), []);

  useEffect(() => {
    MeCollection.getMe()
      .then(({ data }) => {
        dispatch(setUser(data.data));
      })
      .catch((error: Error) => {
        console.log(`Error getting information about current user: ${error.message}`);
      });
  }, [dispatch]);

  const userHasRequiredRole = useMemo(
    () =>
      roles.length === 0 ||
      roles.filter(r => {
        switch (r) {
          case ROLE.SUPER_ADMIN:
            return user?.isSuperUser;
        }
      }).length > 0,
    [roles, user?.isSuperUser],
  );

  if (isAuthenticated && userHasRequiredRole) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const RouteComponent = component;
    return <RouteComponent />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Unauthorized />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
