import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { RoutingPage, setPage } from '../../../../routes/routerSlice';
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';
import {
  adjustPointSizeInLines,
  changeFillInLineTypesBasedOnHiddenLines,
  createChartDataFromDatasetsAndParsedMonthlyTransactions,
  createDatasetsFromLineTypesAndGraphLines,
  getChartPointSizeBasedOnDisplayedDays,
  hideNullLineTypes,
  inboundOutboundTypesDefault,
  mergeDailyInventoryMovements,
  parseTransactions,
  saleTypesDefault,
  setupChartLines,
  supplyLineDefault,
} from '../../../../lib/charts/saleChartsLib';
import {
  DateRangeData,
  createInitialDateRangeData,
  scaleTicksCallback,
} from './dailyTransactionsChartLib';

import { Chart as ChartJS, ChartDataset, ChartOptions, TimeUnit } from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import {
  useGetDailyInboundsQuery,
  useGetDailyOutboundsQuery,
  useGetDailySalesQuery,
} from '../../apiDetail';
import SkuDailyTransactionChartSlider from './SkuDailyTransactionChartSlider';
import { useCurrency } from '../../../../hooks/useCurrency';
import saleChartTooltip from '../../../../components/global/SaleChart/saleChartTooltip';
import { chartScrollZoomHandler } from './chartScrollZoomHandler';
import { isObject } from 'chart.js/helpers';
import { ChartDatasetMetaExtension } from '@shared-types/chartTypes';

const currentDate = new Date();

const ChartAndSliderWrapper = styled.div<{
  width?: CSSProperties['width'];
}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  ${({ width }) => width && `width: ${width};`}
`;

const ChartWrapper = styled.div<{
  height?: CSSProperties['height'];
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ height }) => height && `height: ${height};`}
`;

interface SkuDailyTransactionsChartProps {
  skuId: number;
}

const SkuDailyTransactionsChart: React.FC<SkuDailyTransactionsChartProps> = ({ skuId }) => {
  const apiParams = useMemo(
    () => ({
      skuId,
      monthsCount: 36,
    }),
    [skuId],
  );

  const { data: dailySales, isFetching: isFetchingDailySales } = useGetDailySalesQuery(apiParams);
  const { data: dailyInbounds, isFetching: isFetchingDailyInbounds } =
    useGetDailyInboundsQuery(apiParams);
  const { data: dailyOutbounds, isFetching: isFetchingDailyOutbounds } =
    useGetDailyOutboundsQuery(apiParams);

  const fetching = isFetchingDailySales || isFetchingDailyInbounds || isFetchingDailyOutbounds;

  const dispatch = useDispatch();
  const currency = useCurrency();
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>(createInitialDateRangeData());

  useEffect(() => {
    dispatch(setPage(RoutingPage.CHART));
  }, [dispatch]);

  const chartDateMin = dateRangeData.chart.min;
  const chartDateMax = dateRangeData.chart.max;
  const chartPointRadius = getChartPointSizeBasedOnDisplayedDays(
    chartDateMax.diff(chartDateMin, 'day'),
  );

  const data = useMemo(() => {
    if (fetching || !dailySales || !dailyInbounds || !dailyOutbounds)
      return {
        lineTypes: [],
        p: [],
        chartLines: {},
        lineTypesAfterHiding: [],
        datasets: [],
        nonSaleDataByDay: {},
        chartdata: { datasets: [] },
      };
    let lineTypes = [...saleTypesDefault].concat([...inboundOutboundTypesDefault]);

    const { summedData: mergedTransactions, nonSaleDataByDay } = mergeDailyInventoryMovements(
      dailySales,
      dailyInbounds,
      dailyOutbounds,
    );

    const p = parseTransactions({
      type: 'daily',
      transactions: mergedTransactions,
      lineTypes,
      dateToStartCalculatingCumulativeValues: new Date(),
    });

    if (p.length > 0) {
      setDateRangeData(prev => ({
        chart: prev.chart.min.isSame(dayjs(), 'day')
          ? {
              min: dayjs(p[p.length > 365 ? 365 : p.length - 1].dates.dateFrom),
              max: dayjs(p[0].dates.dateFrom),
            }
          : prev.chart,
        data: {
          min: dayjs(p[p.length - 1].dates.dateFrom),
          max: dayjs(p[0].dates.dateFrom),
        },
      }));
    }

    lineTypes.push(supplyLineDefault);
    lineTypes = adjustPointSizeInLines(lineTypes, chartPointRadius);

    const chartLines = setupChartLines(lineTypes, p, false, new Date(), 'Quantity');

    let lineTypesAfterHiding = hideNullLineTypes(lineTypes, p); //sets "hidden: true" to LineTypes in case of only 0 or null in data
    lineTypesAfterHiding = changeFillInLineTypesBasedOnHiddenLines(lineTypesAfterHiding); //sets fill property based on missing data

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- mixing of line and bar types
    const datasets = createDatasetsFromLineTypesAndGraphLines(
      lineTypesAfterHiding,
      chartLines,
    ) as unknown as ChartDataset<'line'>[];

    const chartdata = createChartDataFromDatasetsAndParsedMonthlyTransactions(datasets, p);

    return {
      lineTypes,
      p,
      chartLines,
      lineTypesAfterHiding,
      datasets,
      chartdata,
      nonSaleDataByDay,
    };
  }, [dailySales, dailyInbounds, dailyOutbounds, fetching, chartPointRadius]);

  const chartOptions: ChartOptions<'line'> = useMemo(() => {
    const dayDiff = chartDateMax.diff(chartDateMin, 'day');

    let xScaleTimeUnit: TimeUnit = 'day';
    if (dayDiff > 180) {
      xScaleTimeUnit = 'month';
    } else if (dayDiff > 90) {
      xScaleTimeUnit = 'week';
    }

    return {
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: (legendItem, chartData) => {
              const { datasetIndex } = legendItem;
              if (datasetIndex !== undefined) {
                const dataset = chartData.datasets[datasetIndex];
                if ('metaExtensions' in dataset) {
                  return (
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- problems with module typing
                    (dataset.metaExtensions as ChartDatasetMetaExtension).displayLegend ?? false
                  );
                }
              }
              return false;
            },
          },
        },
        annotation: {},
        tooltip: {
          enabled: false,
          external: ({ tooltip }) => {
            if (chartRef.current) {
              saleChartTooltip(
                tooltip,
                data.lineTypesAfterHiding,
                data.datasets,
                data.p,
                currentDate,
                chartRef,
                currency,
                0,
                'none',
                'daily',
                data.nonSaleDataByDay,
              );
            }
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: false,
          beginAtZero: false,
          ticks: {
            stepSize: 1,
          },
        },
        x: {
          type: 'time',
          min: chartDateMin.format('YYYY-MM-DD'),
          max: chartDateMax.format('YYYY-MM-DD'),
          time: {
            unit: xScaleTimeUnit,
            displayFormats: {
              day: 'dd MMM YYYY',
            },
          },
          ticks: {
            source: 'data',
            autoSkip: false,
            callback: scaleTicksCallback(xScaleTimeUnit),
          },
        },
      },
    };
  }, [
    chartDateMax,
    chartDateMin,
    currency,
    data.datasets,
    data.lineTypesAfterHiding,
    data.nonSaleDataByDay,
    data.p,
  ]);

  const scrollHandler: React.WheelEventHandler<HTMLCanvasElement> = useMemo(
    () => chartScrollZoomHandler(setDateRangeData, chartRef),
    [],
  );

  return (
    <div>
      <ChartAndSliderWrapper width="90%">
        <ChartWrapper className="chart-container" height="500px" data-type="chart-container">
          <Line
            id="yd-chart"
            onWheel={scrollHandler}
            data={data.chartdata}
            options={chartOptions}
            ref={chartRef}
          />
        </ChartWrapper>
        <SkuDailyTransactionChartSlider
          dateRangeData={dateRangeData}
          setDateRangeData={setDateRangeData}
        />
      </ChartAndSliderWrapper>
    </div>
  );
};

export default SkuDailyTransactionsChart;
