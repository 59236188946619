import React from 'react';
import { styled } from 'styled-components';
import {
  Column,
  Title,
  BarLineSizes,
  BarLineTypes,
  BarLine,
  BarLinePartitionProps,
} from '@ydistri/ds';
import { useCurrency } from '../../../../hooks/useCurrency';

const TitleAboveBar = styled(Title).attrs({
  $marginTop: 'none',
  $marginBottom: 'none',
})`
  align-self: center;
`;

const RedistributionBarWrapper = styled(Column)`
  height: 225px;
`;

interface BarRedistributionProps {
  barLineData: BarLinePartitionProps[];
  title: string[];
  dividers?: boolean;
}

const BarRedistribution: React.FC<BarRedistributionProps> = ({
  barLineData,
  title = [],
  dividers = false,
}) => {
  const currency = useCurrency();
  return (
    <Column $gap="0.25rem">
      {title.map(x => (
        <TitleAboveBar key={x}>{x}</TitleAboveBar>
      ))}
      <RedistributionBarWrapper>
        <BarLine
          type={BarLineTypes.VERTICAL}
          size={BarLineSizes.XLARGE}
          data={barLineData}
          dividers={dividers}
        />
      </RedistributionBarWrapper>
      <TitleAboveBar>({currency})</TitleAboveBar>
    </Column>
  );
};

export default BarRedistribution;
