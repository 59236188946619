import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableModals, openModal } from '../../Modals/modalSlice';
import { resetSelectedRowKeys } from '../calculationsSlice';
import { Button, IconButton, IconButton_IconSizes, Row, Text } from '@ydistri/ds';
import { CalculationActionType } from '../calculationsTypes';
import { MdDelete } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Divider } from 'antd';
import { plural } from '@ydistri/utils';

interface SelectedCalculationsActionButtonsProps {
  calculationsTableId: string;
}

const SelectedCalculationsActionButtons: React.FC<SelectedCalculationsActionButtonsProps> = ({
  calculationsTableId,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedCalculations = useSelector(
    state => state.calculations.selectedRowKeys[calculationsTableId]?.arr ?? [],
  );
  const hasSelectedCalculations = useSelector(
    state => state.calculations.hasSelectedRowKeys[calculationsTableId],
  );
  const calculationTableParams = useSelector(state => state.calculations.calculationsTableParams);

  const setModalData = useCallback(
    (calculationActionType: CalculationActionType) => {
      dispatch(
        openModal({
          type: AvailableModals.MULTI_CALCULCATION_ACTIONS,
          data: {
            calculationIds: [...selectedCalculations],
            calculationTableParams,
            calculationActionType,
          },
        }),
      );
    },
    [dispatch, selectedCalculations, calculationTableParams],
  );

  const onPrivateHandler = useCallback(
    () => setModalData(CalculationActionType.PRIVATE),
    [setModalData],
  );

  const onUnsetPrivateHandler = useCallback(
    () => setModalData(CalculationActionType.UNSET_PRIVATE),
    [setModalData],
  );

  const onDeleteHandler = useCallback(
    () => setModalData(CalculationActionType.DELETE),
    [setModalData],
  );

  const onClearHandler = useCallback(() => {
    dispatch(resetSelectedRowKeys(calculationsTableId));
  }, [calculationsTableId, dispatch]);

  if (!hasSelectedCalculations) return null;
  return (
    <Row $gap="0.5rem" $alignItems="center">
      <Text>
        {selectedCalculations.length} selected
        {plural(selectedCalculations.length, ' calculation')}
      </Text>
      <Button $colorVariant="ghostPrimary" onClick={onClearHandler}>
        Clear selection
      </Button>
      <IconButton
        size="small"
        tooltip="Set private"
        tooltipPosition="top"
        colorScheme={theme.iconColorSchemes.default}
        icon={<AiFillEye size={IconButton_IconSizes['small']} />}
        onClick={onPrivateHandler}
      />
      <IconButton
        size="small"
        tooltip="Unset private"
        tooltipPosition="top"
        colorScheme={theme.iconColorSchemes.default}
        icon={<AiOutlineEyeInvisible size={IconButton_IconSizes['small']} />}
        onClick={onUnsetPrivateHandler}
      />
      <IconButton
        size="small"
        tooltip="Delete selected calculations"
        tooltipPosition="top"
        colorScheme={theme.iconColorSchemes.danger}
        icon={<MdDelete size={IconButton_IconSizes['small']} />}
        onClick={onDeleteHandler}
      />
      <Divider type="vertical" />
    </Row>
  );
};

export default SelectedCalculationsActionButtons;
