import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { Steps } from 'antd';
import { StepProps } from 'antd/es/steps';
import { LoadingOutlined } from '@ant-design/icons';
import { ProcessingStep } from '../redistributionImportTypes';

const StepsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StepsWrapper = styled.div`
  display: flex;
  min-width: ${computeRemSize(600)};
  max-width: ${computeRemSize(800)};
  align-items: center;
  align-self: center;
  gap: 4rem;
`;

interface ProcessingStepsProps {
  steps: string[];
  currentStepIndex: ProcessingStep;
  currentStepRunning: boolean;
}

const ProcessingSteps: React.FC<ProcessingStepsProps> = ({
  steps,
  currentStepIndex,
  currentStepRunning,
}) => {
  const stepItems = useMemo(() => {
    return steps.map((step, index) => {
      const result: StepProps = {
        title: step,
      };

      if (index === currentStepIndex.valueOf() && currentStepRunning) {
        result.status = 'process';
        result.icon = <LoadingOutlined />;
      }

      return result;
    });
  }, [currentStepIndex, currentStepRunning, steps]);

  return (
    <StepsRow>
      <StepsWrapper>
        <Steps current={currentStepIndex} items={stepItems}></Steps>
      </StepsWrapper>
    </StepsRow>
  );
};

export default ProcessingSteps;
