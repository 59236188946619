import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Segmented, SegmentedProps, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeStatisticsModal } from '../statisticsSlice';
import { SegmentedValue } from 'antd/es/segmented';
import { StatisticsModalType } from '../statisticsLib';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import StatisticsModalTableRoute from './StatisticsModalTableRoute';
import { styled } from 'styled-components';
import { ModalDataInfiniteScrollParams } from '../apiCalculationStatistics';
import StatisticsModalTableSourceStores from './StatisticsModalTableSourceStores';
import StatisticsModalTableTargetStores from './StatisticsModalTableTargetStores';
import { statisticsModalChartLib, ChartPropertyType } from './statisticsModalChartLib';
import { camelCaseToNormal } from '@ydistri/utils';
import StatisticsModalChartRoute from './StatisticsModalChartRoute';
import StatisticsModalChartSourceStore from './StatisticsModalChartSourceStore';
import StatisticsModalChartTargetStore from './StatisticsModalChartTargetStore';
import { Label } from '@ydistri/ds';
import { AiOutlineLineChart, AiOutlineTable } from 'react-icons/ai';
import { ReduxState } from '../../../../store';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import { useApplicationData } from '../../../../hooks/useApplicationData';

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface StatisticsModalProps<T extends StatisticsModalType> {
  title?: string;
  statisticsModalType: T;
}

enum StatisticsModalDisplayType {
  TABLE = 'table',
  CHART = 'graph',
}

const displayTypeOptions: SegmentedProps['options'] = [
  {
    label: 'Table',
    value: StatisticsModalDisplayType.TABLE,
    icon: <AiOutlineTable />,
  },
  {
    label: 'Chart',
    value: StatisticsModalDisplayType.CHART,
    icon: <AiOutlineLineChart />,
  },
];

const StatisticsModal = <T extends StatisticsModalType>({
  title = '',
  statisticsModalType,
}: StatisticsModalProps<T>): React.ReactElement => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const handleClose = useCallback(() => dispatch(closeStatisticsModal()), [dispatch]);

  const { departments: hasDepartments } = useApplicationData();
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);
  const [displayType, setDisplayType] = React.useState<StatisticsModalDisplayType>(
    StatisticsModalDisplayType.TABLE,
  );
  const [chartValueType, setChartValueType] =
    React.useState<(typeof statisticsModalChartLib)[T][number]>('value');

  const displayDepartmentText =
    hasDepartments && clusterType === CalculationStatisticClusterType.StoreDepartment;

  const [params, setParams] = useState<ModalDataInfiniteScrollParams>({
    calculationId: templateOrCalculation.id,
    departmentId,
    tableOrChart: displayType === StatisticsModalDisplayType.TABLE ? 'table' : 'chart',
    skip: 0,
    top: 50,
    clusterType,
  });

  const chartValueTypeOptions: SegmentedProps['options'] = useMemo(
    () =>
      statisticsModalChartLib[statisticsModalType].map(o => ({
        label: camelCaseToNormal(o),
        value: o,
      })),
    [statisticsModalType],
  );

  const handleDisplayTypeChange = useCallback((value: SegmentedValue) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setDisplayType(value as StatisticsModalDisplayType);
  }, []);

  const handleChartValueTypeChange = useCallback((value: SegmentedValue) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setChartValueType(value as (typeof statisticsModalChartLib)[T][number]);
  }, []);

  useEffect(() => {
    setParams(p => ({
      ...p,
      calculationId: templateOrCalculation.id,
      departmentId,
      tableOrChart: displayType === StatisticsModalDisplayType.TABLE ? 'table' : 'chart',
      skip: 0,
      top: 50,
    }));
  }, [displayType, templateOrCalculation.id, departmentId]);

  const footer = useMemo(() => <Button onClick={handleClose}>Close</Button>, [handleClose]);

  /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
  return (
    <Modal title={title} centered open={true} width="80%" onCancel={handleClose} footer={footer}>
      <ModalContent>
        <Space>
          <Label>Display type: </Label>{' '}
          <Segmented
            options={displayTypeOptions}
            value={displayType}
            onChange={handleDisplayTypeChange}
          />
          {displayType === StatisticsModalDisplayType.CHART && (
            <Segmented
              options={chartValueTypeOptions}
              value={String(chartValueType)}
              onChange={handleChartValueTypeChange}
            />
          )}
        </Space>

        {displayType === StatisticsModalDisplayType.TABLE && (
          <>
            {statisticsModalType === StatisticsModalType.ROUTE && (
              <StatisticsModalTableRoute
                params={params}
                setParams={setParams}
                displayDepartment={displayDepartmentText}
              />
            )}
            {statisticsModalType === StatisticsModalType.SOURCE_STORE && (
              <StatisticsModalTableSourceStores
                params={params}
                setParams={setParams}
                displayDepartment={displayDepartmentText}
              />
            )}
            {statisticsModalType === StatisticsModalType.TARGET_STORE && (
              <StatisticsModalTableTargetStores
                params={params}
                setParams={setParams}
                displayDepartment={displayDepartmentText}
              />
            )}
          </>
        )}

        {displayType === StatisticsModalDisplayType.CHART && (
          <>
            {statisticsModalType === StatisticsModalType.ROUTE && (
              <StatisticsModalChartRoute
                chartValueType={
                  //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  chartValueType as ChartPropertyType<StatisticsModalType.ROUTE>
                }
                calculationId={templateOrCalculation.id}
              />
            )}
            {statisticsModalType === StatisticsModalType.SOURCE_STORE && (
              <StatisticsModalChartSourceStore
                chartValueType={
                  //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  chartValueType as ChartPropertyType<StatisticsModalType.SOURCE_STORE>
                }
                calculationId={templateOrCalculation.id}
              />
            )}
            {statisticsModalType === StatisticsModalType.TARGET_STORE && (
              <StatisticsModalChartTargetStore
                chartValueType={
                  //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  chartValueType as ChartPropertyType<StatisticsModalType.TARGET_STORE>
                }
                calculationId={templateOrCalculation.id}
              />
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default StatisticsModal;
