import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState } from '../../store';
import { styled } from 'styled-components';
import { Link, useParams } from 'react-router-dom';

const TableWrapper = styled.div`
  height: 70vh;
`;

const modalId = AvailableModals.ENTITY_LIST_CONTENTS;

const EntityListContentsModal: React.FC = () => {
  const { projectShortName } = useParams();
  const dispatch = useDispatch();
  const entityList = useSelector((state: ReduxState) => state.modal.modalData[modalId]?.entityList);

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const footer = useMemo(
    () => (
      <>
        {projectShortName && (
          <Button onClick={handleClose}>
            <Link
              to={`/${projectShortName}/project-administration/entitylists/${entityList?.entityListId}`}
            >
              Open in administration
            </Link>
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </>
    ),
    [projectShortName, entityList?.entityListId, handleClose],
  );

  if (!entityList) return null;

  return (
    <Modal
      title={`Entity list - ${entityList?.name ?? 'undefined'}`}
      centered
      open={true}
      width="80%"
      onCancel={handleClose}
      footer={footer}
    >
      <TableWrapper>TBD</TableWrapper>
    </Modal>
  );
};

export default EntityListContentsModal;
