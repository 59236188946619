import { ColumnsType } from 'antd/es/table';
import {
  CalculationStatisticRouteResponse,
  CalculationStatisticStoreSourceResponse,
  CalculationStatisticStoreTargetResponse,
  DepartmentResponse,
} from '@ydistri/api-sdk';
import { formatNumber } from '@ydistri/utils';
import { computeRemSize, YColumnsType } from '@ydistri/ds';

type ModalData =
  | CalculationStatisticRouteResponse
  | CalculationStatisticStoreSourceResponse
  | CalculationStatisticStoreTargetResponse;

const columnValue = <T>(currencyId: string): YColumnsType<T> => ({
  title: `Value [${currencyId}]`,
  dataIndex: 'value',
  key: 'value',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  defaultSortOrder: 'descend',
  render: (value: number) => formatNumber(value),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'Value',
});

const columnQuantity = <T>(): YColumnsType<T> => ({
  title: `Quantity [MU]`,
  dataIndex: 'quantity',
  key: 'quantity',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (value: number) => formatNumber(value),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'Quantity',
});

const columnProductCount = <T>(): YColumnsType<T> => ({
  title: `Product count`,
  dataIndex: 'productCount',
  key: 'productCount',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (productCount: number) => formatNumber(productCount),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'ProductCount',
});

const columnPickingPositions = <T>(): YColumnsType<T> => ({
  title: `Picking positions`,
  dataIndex: 'pickingPositionCount',
  key: 'pickingPositionCount',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (productCount: number) => formatNumber(productCount),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'PickingPositionCount',
});

const columnFilledStockoutValue = <T>(currencyId: string): YColumnsType<T> => ({
  title: `Replenished out-of-stock value [${currencyId}]`,
  dataIndex: 'filledStockoutValue',
  key: 'filledStockoutValue',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (filledStockoutValue: number) => formatNumber(filledStockoutValue),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'FilledStockoutValue',
});

const columnFilledStockoutPercentage = <T extends ModalData>(): YColumnsType<T> => ({
  title: `Replenished out-of-stock [%]`,
  dataIndex: 'filledStockoutValue',
  key: 'filledStockoutPercentage',
  width: computeRemSize(150),
  render: (filledStockoutValue: number, record: T) =>
    'value' in record
      ? `${record.value > 0 ? Math.floor((filledStockoutValue / record.value) * 100) : 0}%`
      : '',
});

const columnTargetStoreCount = <T>(): YColumnsType<T> => ({
  title: `Target store count`,
  dataIndex: 'targetStoreCount',
  key: 'targetStoreCount',
  align: 'right',
  sorter: true,
  width: computeRemSize(150),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'TargetStoreCount',
});

const columnSourceStoreCount = <T>(): YColumnsType<T> => ({
  title: `Source store count`,
  dataIndex: 'sourceStoreCount',
  key: 'sourceStoreCount',
  align: 'right',
  sorter: true,
  width: computeRemSize(150),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'SourceStoreCount',
});

const columnDepartmentName = <T>(displayDepartment: boolean): YColumnsType<T>[] =>
  displayDepartment
    ? [
        {
          title: `Department`,
          dataIndex: 'department',
          key: 'department',
          sorter: true,
          render: (department: DepartmentResponse) => department?.name ?? '',
          apiFilterable: true,
          apiFilterType: 'text',
          apiColumnName: 'Department/Name',
        },
      ]
    : [];

/**
 * Return columns used in Statistics / Source Stores / Show more table
 * @param currencyId
 * @param displayDepartment
 * @param storeColumns
 */
export const getStatisticsSourceStoresTableColumns = <T>(
  currencyId: string,
  displayDepartment: boolean,
  storeColumns: YColumnsType<T>[],
): ColumnsType<T> => {
  return [
    ...storeColumns,
    ...columnDepartmentName<T>(displayDepartment),
    columnValue(currencyId),
    columnQuantity(),
    columnPickingPositions(),
    columnProductCount(),
    columnTargetStoreCount(),
  ];
};

/**
 * Return columns used in Statistics / Target Stores / Show more table
 * @param currencyId
 * @param displayDepartment
 * @param storeColumns
 */
export const getStatisticsTargetStoresTableColumns = <T extends ModalData>(
  currencyId: string,
  displayDepartment: boolean,
  storeColumns: YColumnsType<T>[],
): ColumnsType<T> => {
  return [
    ...storeColumns,
    ...columnDepartmentName<T>(displayDepartment),
    columnValue(currencyId),
    columnQuantity(),
    columnPickingPositions(),
    columnProductCount(),
    columnSourceStoreCount(),
    columnFilledStockoutValue(currencyId),
    columnFilledStockoutPercentage<T>(),
  ];
};

/**
 * Return columns used in Statistics / Routes / Show more table
 * @param currencyId
 * @param displayDepartment
 * @param sourceStoreColumns
 * @param targetStoreColumns
 */
export const getStatisticsRouteTableColumns = <T>(
  currencyId: string,
  displayDepartment: boolean,
  sourceStoreColumns: YColumnsType<T>[],
  targetStoreColumns: YColumnsType<T>[],
): ColumnsType<T> => {
  return [
    ...sourceStoreColumns,
    ...targetStoreColumns,
    ...columnDepartmentName<T>(displayDepartment),
    columnValue(currencyId),
    columnQuantity(),
    columnPickingPositions(),
    columnFilledStockoutValue(currencyId),
  ];
};
