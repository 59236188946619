import { SkuTypeForDrilldown } from './drillDownLib';
import React, { useCallback } from 'react';
import { Button } from '@ydistri/ds';

interface SkuTypeButtonProps {
  skuType: SkuTypeForDrilldown;
  selectedSkuType: SkuTypeForDrilldown;
  setSelectedSkuType: React.Dispatch<React.SetStateAction<SkuTypeForDrilldown>>;
}

export const SkuTypeButton: React.FC<SkuTypeButtonProps> = ({
  skuType,
  selectedSkuType,
  setSelectedSkuType,
}) => {
  const setType = useCallback(() => {
    setSelectedSkuType(skuType);
  }, [skuType, setSelectedSkuType]);

  return (
    <Button
      $colorVariant={selectedSkuType === skuType ? 'selected' : 'ghost'}
      $textVariant="semibold"
      onClick={setType}
      data-type="drill-down-sku-type-button"
    >
      {skuType === 'total' ? 'All' : skuType}
    </Button>
  );
};
