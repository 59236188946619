import useCategoryId from '../../../../hooks/useCategoryId';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { GetCategoryInsightsParams, useGetCategoryInsightsQuery } from '../apiCategoryInsights';
import React, { useMemo } from 'react';
import {
  InsightsTargetData,
  parseCategorySummaryIntoTargetInsightsData,
} from '../categoryInsightsLib';
import EligibleTarget from './EligibleTarget';
import TargetStructureBar from './TargetStructureBar';
import { Column, Title } from '@ydistri/ds';
import TargetPotentialBar from './TargetPotentialBar/TargetPotentialBar';
import { Spin } from 'antd';

export const PotentialTargetAnalysis: React.FC = () => {
  const categoryId = useCategoryId();
  const templateOrCalculation = useTemplateOrCalculation();
  const categoryInsightsParam: GetCategoryInsightsParams = {
    categoryId,
    templateOrCalculation,
  };

  const { data: categoryData, isLoading: isCategoryInsightsLoading } =
    useGetCategoryInsightsQuery(categoryInsightsParam);

  const targetData: InsightsTargetData | undefined = useMemo(() => {
    if (categoryData?.summaries) {
      return parseCategorySummaryIntoTargetInsightsData(
        categoryData.summaries,
        categoryData.attributes,
      );
    }
    return undefined;
  }, [categoryData?.attributes, categoryData?.summaries]);

  if (isCategoryInsightsLoading) {
    return <Spin spinning={true}> Loading data...</Spin>;
  }

  if (!targetData) {
    return (
      <Title $size="small">
        No data available for this {templateOrCalculation.type.toLowerCase()}
      </Title>
    );
  }

  return (
    <>
      <EligibleTarget data={targetData} />
      <TargetStructureBar data={targetData} />
      <Column $gap="2rem">
        <TargetPotentialBar data={targetData.targetPotential} title="Theoretical potential" />
      </Column>
    </>
  );
};
