import { TargetListResponse } from '@ydistri/api-sdk';
import { styled } from 'styled-components';
import { Form, Input, InputRef, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  defaultTargetListParams,
  TargetListUpdateRequest,
  useCreateTargetListMutation,
  useGetTargetListsQuery,
  useUpdateTargetListMutation,
} from '../apiTargetLists';
import { useDispatch } from 'react-redux';
import { setSelectedTargetList } from '../targetListAdministrationSlice';
import { addToast } from '@ydistri/utils';
import { ModalMode } from '../../common/administrationItemsTypes';
import { computeRemSize } from '@ydistri/ds';
import { Rule } from 'rc-field-form/lib/interface';
import { ErrorType } from '../../../../apis/api';

interface TargetListModalProps {
  targetList?: TargetListResponse;
  onClose: () => void;
  confirmButtonLabel?: string;
}

interface FormType {
  name: string;
}

/**
 * Needed to override default height of select component to be the same
 * as the Input field on the modal.
 */
const StyledModal = styled(Modal)`
  #CreateTargetListForm .ant-select-selector,
  #EditTargetListForm .ant-select-selector {
    height: ${computeRemSize(28)};
    align-items: center;
  }

  #TargetListTypeSelect {
    height: ${computeRemSize(24)};
  }
`;

const TargetListModal: React.FC<TargetListModalProps> = ({
  onClose,
  targetList,
  confirmButtonLabel,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [addTargetList] = useCreateTargetListMutation();
  const [updateTargetList] = useUpdateTargetListMutation();
  const [form] = Form.useForm<FormType>();
  const { data: targetLists } = useGetTargetListsQuery(defaultTargetListParams);
  const inputRef = useRef<InputRef>(null);

  const modalMode = useMemo(() => {
    if (targetList) {
      return ModalMode.EDIT;
    } else {
      return ModalMode.CREATE;
    }
  }, [targetList]);

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const createTargetList = useCallback(
    (values: FormType) => {
      setIsBusy(true);

      addTargetList({ name: values.name })
        .unwrap()
        .then(result => {
          dispatch(setSelectedTargetList(result));
        })
        .catch((error: ErrorType) => {
          dispatch(
            addToast({
              isError: true,
              message: `Target List was not created: ${error.response.data.Messages.join(', ')}`,
            }),
          );
        })
        .finally(() => {
          setIsBusy(false);
          closeModal();
        });
    },
    [addTargetList, closeModal, dispatch],
  );

  const editTargetList = useCallback(
    (values: FormType) => {
      if (targetList) {
        setIsBusy(true);
        const request: TargetListUpdateRequest = {
          targetListId: targetList.targetListId,
        };

        //only include values in the request that have changed
        if (values.name !== targetList.name) {
          request.name = values.name;
        }

        updateTargetList(request)
          .unwrap()
          .then(result => {
            if (result) {
              dispatch(setSelectedTargetList(result));
            }
          })
          .catch(() => {
            dispatch(
              addToast({ message: 'Error occurred when updating a Target List', isError: true }),
            );
          })
          .finally(() => {
            setIsBusy(false);
            closeModal();
          });
      }
    },
    [closeModal, dispatch, targetList, updateTargetList],
  );

  const onSubmit = useCallback(
    (values: FormType) => {
      if (modalMode === ModalMode.CREATE) {
        createTargetList(values);
      } else {
        editTargetList(values);
      }
    },
    [modalMode, createTargetList, editTargetList],
  );

  const processForm = useCallback(() => {
    form
      .validateFields()
      .then(values => {
        onSubmit(values);
      })
      .catch(error => {
        console.log('Validation Error:', error);
      });
  }, [form, onSubmit]);

  const okHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      processForm();
    },
    [processForm],
  );

  /**
   * Autofocus the input element when mounted for better user experience
   * as there is only one input element where the user needs to write something.
   */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const onEnterPressed = useCallback(() => {
    processForm();
  }, [processForm]);

  const nameItemRules = useMemo(
    () => [
      { required: true, message: 'Enter the name of the target list' },
      {
        validator: async (rule: Rule, value: string) => {
          const otherTargetLists = targetList
            ? targetLists?.filter(item => item.targetListId !== targetList.targetListId)
            : targetLists;

          const sameNameTargetListExists = otherTargetLists?.find(item => item.name === value);
          if (sameNameTargetListExists) {
            return Promise.reject(
              new Error('Target List with this name already exists, choose different name'),
            );
          }
        },
      },
    ],
    [targetList, targetLists],
  );

  const buttonProps = useMemo(() => {
    return {
      disabled: isBusy,
    };
  }, [isBusy]);

  const initialValues = useMemo(() => {
    if (targetList) {
      return {
        name: targetList.name,
      };
    }
  }, [targetList]);

  return (
    <StyledModal
      data-testid={`${modalMode === ModalMode.CREATE ? 'Create' : 'Edit'}TargetListModal`}
      onOk={okHandler}
      open={true}
      onCancel={closeModal}
      title={
        modalMode === ModalMode.CREATE
          ? 'Create Target List'
          : `Edit Target List "${targetList?.name ?? ''}"`
      }
      okButtonProps={buttonProps}
      cancelButtonProps={buttonProps}
      okText={confirmButtonLabel}
    >
      <Form
        name={`${modalMode === ModalMode.CREATE ? 'Create' : 'Edit'}TargetListForm`}
        form={form}
        layout="vertical"
        autoComplete="off"
        disabled={isBusy}
        initialValues={initialValues}
      >
        <Form.Item name="name" label="Name" rules={nameItemRules}>
          <Input
            placeholder="Name of Target List"
            ref={inputRef}
            onPressEnter={onEnterPressed}
            disabled={isBusy}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default TargetListModal;
