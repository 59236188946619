import {
  MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
  ParsedCategoryRedistribution,
} from '../categoryInsightsLib';
import React, { useMemo } from 'react';
import { BarPartitionContent, BarLineProps, COLORS, SimpleTooltipContent } from '@ydistri/ds';
import BarRedistribution from './BarRedistribution';
import { computePercentage } from '@ydistri/utils';
import { useCurrency } from '../../../../hooks/useCurrency';

const TITLE_ROWS = ['Source', 'by inventory type'];

interface BarSourceByRedistributionTypeProps {
  data: ParsedCategoryRedistribution;
}

const BarSourceByRedistributionType: React.FC<BarSourceByRedistributionTypeProps> = ({ data }) => {
  const currency = useCurrency();

  const barLineData: BarLineProps['data'] = useMemo(() => {
    const percentageDeadstock = computePercentage(data.bySkuType.DeadStock.totalSource, data.total);
    const percentageSlowmover = computePercentage(data.bySkuType.SlowMover.totalSource, data.total);
    const percentageFastmover = 100 - percentageDeadstock - percentageSlowmover;

    return [
      {
        percentage: percentageFastmover,
        color: COLORS.BAR_FASTMOVERS,
        tooltip: (
          <SimpleTooltipContent
            title="Fast movers"
            value={data.bySkuType.FastMover.totalSource}
            postfix={currency}
          />
        ),
        content: (
          <BarPartitionContent
            title="Fast movers"
            percentage={percentageFastmover}
            value={data.bySkuType.FastMover.totalSource}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageSlowmover,
        color: COLORS.BAR_SLOWMOVERS,
        tooltip: (
          <SimpleTooltipContent
            title="Slow movers"
            value={data.bySkuType.SlowMover.totalSource}
            postfix={currency}
          />
        ),
        content: (
          <BarPartitionContent
            title="Slow movers"
            percentage={percentageSlowmover}
            value={data.bySkuType.SlowMover.totalSource}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageDeadstock,
        color: COLORS.BAR_DEADSTOCK,
        tooltip: (
          <SimpleTooltipContent
            title="Dead stock"
            value={data.bySkuType.DeadStock.totalSource}
            postfix={currency}
          />
        ),
        content: (
          <BarPartitionContent
            title="Dead stock"
            percentage={percentageDeadstock}
            value={data.bySkuType.DeadStock.totalSource}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
    ];
  }, [
    data.bySkuType.DeadStock.totalSource,
    data.bySkuType.FastMover.totalSource,
    data.bySkuType.SlowMover.totalSource,
    data.total,
    currency,
  ]);

  return <BarRedistribution barLineData={barLineData} title={TITLE_ROWS} />;
};

export default BarSourceByRedistributionType;
