export interface RedistributionImportInputData {
  sourceStoreIdentifier: string;
  targetStoreIdentifier: string;
  productIdentifier: string;
  count?: number;
  description?: string;
}

export type RedistributionImportInputDataKeys = (keyof RedistributionImportInputData)[];

export enum InstructionType {
  QUANTITY = 'Quantity',
  DESCRIPTION = 'Description',
  QUANTITY_DESCRIPTION = 'QuantityWithDescription',
}

export enum ProcessingStep {
  IMPORT = 0,
  VALIDATION = 1,
  FINALIZED = 2,
}

export interface ImportFormatItem<T> {
  id: keyof T;
  label: string;
}
