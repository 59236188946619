import React from 'react';
import { Row, Text } from '@ydistri/ds';
import NoData from '../NoData/NoData';

interface CountableTextProps {
  value: number | string | undefined | null;
  word: string;
  highlighting?: 'none' | 'number' | 'both' | 'text-only';
  suffix?: string;
  parser?: (value: string) => number;
}

const CountableText: React.FC<CountableTextProps> = ({
  value,
  word,
  highlighting = 'number',
  suffix,
  parser = parseInt,
}) => {
  if (value === null || typeof value === 'undefined') {
    return <NoData />;
  }

  let numericValue = typeof value === 'string' ? parser(value) : value;

  let strVar = ' ' + word;

  if (numericValue !== 1 && word !== 'MU') {
    strVar += 's';
  }

  if (word === 'sale') {
    numericValue = Math.round(numericValue);
  }

  switch (highlighting) {
    case 'number':
      if (suffix) {
        strVar += ' ' + suffix;
      }
      return (
        <Row $gap="0.5rem">
          <Text $semiBold>{numericValue.toString()}</Text>
          <Text>{strVar}</Text>
        </Row>
      );
    case 'both':
      return (
        <Row $gap="0.5rem">
          <Text $semiBold>
            {numericValue.toString()} {strVar}
          </Text>
          {suffix && <Text>{` ${suffix}`}</Text>}
        </Row>
      );
    case 'text-only':
      return (
        <Row $gap="0.5rem">
          <Text>{numericValue.toString()}</Text>
          <Text $semiBold>{strVar}</Text>
          {suffix && <Text>{suffix}</Text>}
        </Row>
      );
    default:
      if (suffix) {
        strVar += ' ' + suffix;
      }
      return <Text>{`${numericValue.toString()} ${strVar}`}</Text>;
  }
};

export default CountableText;
