import { CalculationMode, CalculationStatus } from '@ydistri/api-sdk';
import { COLORS, Label, Spinner } from '@ydistri/ds';
import React from 'react';
import { styled, useTheme } from 'styled-components';

const CalculationStatusLabel = styled(Label)`
  color: ${() => COLORS.GREY_DARK};
`;

interface CalculationStatusProps {
  status: CalculationStatus;
  mode: CalculationMode;
}

const CalculationStatusComponent: React.FC<CalculationStatusProps> = ({ status, mode }) => {
  const theme = useTheme();
  switch (status) {
    case CalculationStatus.Pending: {
      return (
        <>
          <Spinner color="blue" />
          {mode === CalculationMode.Manual ? (
            <CalculationStatusLabel>Waiting for manual import</CalculationStatusLabel>
          ) : (
            <CalculationStatusLabel>Calculation pending</CalculationStatusLabel>
          )}
        </>
      );
    }
    case CalculationStatus.Queued: {
      return (
        <>
          <Spinner color="blue" />
          <CalculationStatusLabel>Calculation queued</CalculationStatusLabel>
        </>
      );
    }
    case CalculationStatus.Running: {
      return (
        <>
          <Spinner color="blue" />
          <CalculationStatusLabel>Calculation running</CalculationStatusLabel>
        </>
      );
    }
    case CalculationStatus.Crashed: {
      return <CalculationStatusLabel>Calculation failed</CalculationStatusLabel>;
    }
    case CalculationStatus.SoftDeleted: {
      return (
        <>
          <Spinner color={theme.colors.danger} />
          <CalculationStatusLabel>Calculation to be deleted</CalculationStatusLabel>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export default CalculationStatusComponent;
