import { MinMaxForecast, SkuData } from '../../../../../redistributionTypes';
import React, { useCallback, useMemo } from 'react';
import SkuInfo, { SkuInfoData } from './SkuInfo';
import { Row, Text } from '@ydistri/ds';
import { fullMonthDiff, getForecastApproachLabel } from '@ydistri/utils';
import { format } from 'date-fns';
import CountableText from '../../../../../../../../components/global/CountableText/CountableText';
import NoData, {
  NO_DATA_PLACEHOLDER,
} from '../../../../../../../../components/global/NoData/NoData';
import SkuInfoTypeConfiguration from '../SkuInfo/SkuInfoDetails/SkuInfoTypeConfiguration';
import SkuInfoTypeValue from '../SkuInfo/SkuInfoDetails/SkuInfoTypeValue';
import ChartLineLegendGraphic from '../../../../../../../Detail/sections/components/DetailSaleChartTooltip/ChartLineLegendGraphic';
import { forecastTypesDefault } from '../../../../../../../../lib/charts/saleChartsLib';

interface SkuInfoSourceProps {
  skuData?: SkuData;
  applicationDate: Date;
  usedForecast?: MinMaxForecast;
  showActualData?: boolean;
}

const getMonthsSinceFirstShipmentValue = (sku: SkuData, applicationDate: Date) => {
  if (!sku.attributes.dates.dateOfFirstPurchase) {
    return 'No shipment info';
  }

  return (
    <Row $gap="0.5rem">
      <CountableText
        value={fullMonthDiff(sku.attributes.dates.dateOfFirstPurchase, applicationDate)}
        word="month"
        highlighting="both"
        suffix="from first shipment"
      />
      <Text>
        (First shipment: {format(sku.attributes.dates.dateOfFirstPurchase, 'dd. MM. yyyy')})
      </Text>
    </Row>
  );
};

const getMonthsSinceLastShipmentValue = (sku: SkuData, applicationDate: Date) => {
  if (!sku.attributes.dates.dateOfLastPurchase) {
    return 'No shipment info';
  }

  return (
    <Row $gap="0.5rem">
      <CountableText
        value={fullMonthDiff(sku.attributes.dates.dateOfLastPurchase, applicationDate)}
        word="month"
        highlighting="both"
        suffix="from last shipment to store"
      />
      <Text>
        (Last shipment: {format(sku.attributes.dates.dateOfLastPurchase, 'dd. MM. yyyy')})
      </Text>
    </Row>
  );
};

const getMuToRedistribute = (skuData: SkuData, usedForecastMax?: number) => {
  if (skuData.attributes.values.AvailableSupply && usedForecastMax) {
    const availableSupply = skuData.attributes.values.AvailableSupply
      ? parseFloat(skuData.attributes.values.AvailableSupply)
      : 0;

    const toRedistribute = availableSupply - usedForecastMax;

    return `(${toRedistribute < 0 ? 0 : toRedistribute} MU to redistribute)`;
  }
};

const SkuInfoSource: React.FC<SkuInfoSourceProps> = ({
  skuData,
  applicationDate,
  usedForecast,
  showActualData = false,
}) => {
  const shouldRenderForcedQuantityRedistributionWarning = skuData
    ? skuData.config.isForced || skuData.config.isClosing
    : false;

  const getForcedRedistributionValue = useCallback(
    (sku: SkuData) => {
      if (sku.config.PsUseForcedRedistribution && sku.attributes.values.IsForced) {
        const useForcedRedistribution = parseInt(sku.config.PsUseForcedRedistribution);
        const isForced = parseInt(sku.attributes.values.IsForced);

        const prefix: string = useForcedRedistribution === 0 && isForced === 1 ? 'NOT USED - ' : '';
        let value = '0';
        if (
          shouldRenderForcedQuantityRedistributionWarning &&
          sku.attributes.values.AvailableSupply
        ) {
          value = parseFloat(sku.attributes.values.AvailableSupply).toFixed(2).toString();
        }

        return `${prefix}${value} MU`;
      }
    },
    [shouldRenderForcedQuantityRedistributionWarning],
  );

  const tableData = useMemo(() => {
    const result: SkuInfoData[] = [];

    result.push({
      label: skuData?.type ?? 'Type',
      configuration: <SkuInfoTypeConfiguration sku={skuData} />,
      value: skuData ? (
        <SkuInfoTypeValue sku={skuData} applicationDate={applicationDate} />
      ) : (
        <NoData />
      ),
    });

    result.push({
      label: 'Months of supply after redistribution',
      configuration: skuData ? (
        `${skuData.config.PsMonthsOfSupplyToKeep ?? NO_DATA_PLACEHOLDER} months`
      ) : (
        <NoData />
      ),
      value: (
        <Row $gap="0.5rem">
          <Text $semiBold>{`${usedForecast?.max ?? NO_DATA_PLACEHOLDER} MU to keep`}</Text>{' '}
          {skuData && <Text>{getMuToRedistribute(skuData, usedForecast?.max)}</Text>}
        </Row>
      ),
    });

    result.push({
      label: 'Months since 1st shipment to store',
      configuration: skuData?.config.PsMonthsFromFirstPurchase ? (
        <CountableText
          value={parseInt(skuData.config.PsMonthsFromFirstPurchase)}
          word="month"
          highlighting="none"
        />
      ) : (
        NO_DATA_PLACEHOLDER
      ),
      value: skuData ? getMonthsSinceFirstShipmentValue(skuData, applicationDate) : <NoData />,
    });

    result.push({
      label: 'Months since last shipment to store',
      configuration: skuData?.config.PsMonthsFromLastPurchase ? (
        <CountableText
          value={parseInt(skuData.config.PsMonthsFromLastPurchase)}
          word="month"
          highlighting="none"
        />
      ) : (
        NO_DATA_PLACEHOLDER
      ),
      value: skuData ? getMonthsSinceLastShipmentValue(skuData, applicationDate) : <NoData />,
    });

    const minLayers = skuData?.sourceMinLayerLists ?? [];

    result.push({
      label: 'Minimum layers',
      configuration: minLayers.length > 0 ? 'Yes' : 'No',
      value:
        minLayers.length > 0 ? (
          <table>
            {minLayers.map(ml => (
              <tr key={ml.entityListVersionId}>
                <td>
                  <Text $semiBold>{ml.name}</Text>
                </td>
                <td>{ml.quantity} MU</td>
              </tr>
            ))}
          </table>
        ) : (
          ' - '
        ),
    });

    result.push({
      label: 'Forced redistribution',
      configuration: shouldRenderForcedQuantityRedistributionWarning ? 'Yes' : 'No',
      value: skuData ? getForcedRedistributionValue(skuData) : <NoData />,
    });

    const fctMin = forecastTypesDefault.find(ft => ft.priority === 1);
    const fctMax = forecastTypesDefault.find(ft => ft.priority === 2);
    const fctMinLine = fctMin && 'lineInfo' in fctMin ? fctMin.lineInfo : undefined;
    const fctMaxLine = fctMax && 'lineInfo' in fctMax ? fctMax.lineInfo : undefined;

    result.push({
      label: 'Forecasting approach',
      configuration: (
        <>
          <Row $alignItems="center" $gap="0.5rem">
            {fctMaxLine ? <ChartLineLegendGraphic $lineInfo={fctMaxLine} /> : null}
            <Text $bold>Source</Text>
          </Row>
          <Row $alignItems="center" $gap="0.5rem">
            {fctMinLine ? <ChartLineLegendGraphic $lineInfo={fctMinLine} /> : null}
            <Text>Target</Text>
          </Row>
        </>
      ),
      value: (
        <>
          <Text $bold>{getForecastApproachLabel(skuData?.config.PsMaxForecastConfidence)}</Text>
          <br />
          <Text>{getForecastApproachLabel(skuData?.config.PtMinForecastConfidence)}</Text>
        </>
      ),
    });

    return result;
  }, [
    applicationDate,
    getForcedRedistributionValue,
    shouldRenderForcedQuantityRedistributionWarning,
    skuData,
    usedForecast?.max,
  ]);

  return <SkuInfo data={tableData} showActualData={showActualData} skuClass={skuData?.skuClass} />;
};

export default SkuInfoSource;
