import {
  CategoryAttributeValueResponse,
  CategoryAttributeValueType,
  CategoryRedistributionResponse,
  CategoryRedistributionType,
  CategorySummaryResponse,
  CategorySummaryType,
  SkuType,
} from '@ydistri/api-sdk';
import { SkuTypeForDrilldown } from '../../../screens/Configuration/ConfigurationDrillDown/drillDownLib';

export const MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT = 45;

interface ExcessiveStock {
  total: ExcessiveStockPotentialInfo;
  deadstock: ExcessiveStockPotentialInfo;
  slowmovers: ExcessiveStockPotentialInfo;
  fastmovers: ExcessiveStockPotentialInfo;
}

interface ExcessiveStockTotals {
  total: number;
  deadstock: number;
  slowmovers: number;
  fastmovers: number;
}

export const LOCKED_BY = [
  CategorySummaryType.ExcessivePotentialTotalValue,
  CategorySummaryType.ExcessiveBlockedByFirstPurchaseDateValue,
  CategorySummaryType.ExcessiveBlockedByLastPurchaseDateValue,
  CategorySummaryType.ExcessiveBlockedByMinimalQuantityValue,
  CategorySummaryType.ExcessiveBlockedByMinValue,
  CategorySummaryType.ExcessiveBlockedByMinLayersValue,
  CategorySummaryType.ExcessiveBlockedByMinimalSetValue,
  CategorySummaryType.ExcessiveBlockedByPromoValue,
] as const;

type LockedByKeys = (typeof LOCKED_BY)[number];
export type ExcessiveStockPotentialInfo = Record<LockedByKeys, number>;
export type ExcessiveStockPotentialTitles = Record<LockedByKeys, string>;

export const LOCKED_BY_TITLES: ExcessiveStockPotentialTitles = {
  [CategorySummaryType.ExcessiveBlockedByMinLayersValue]: 'Blocked by Minimal layers',
  [CategorySummaryType.ExcessiveBlockedByFirstPurchaseDateValue]:
    'Blocked by "Months since 1st shipment to store"',
  [CategorySummaryType.ExcessiveBlockedByLastPurchaseDateValue]:
    'Blocked by "Months since last shipment to store"',
  [CategorySummaryType.ExcessiveBlockedByMinValue]: 'Blocked by "Min product value"',
  [CategorySummaryType.ExcessiveBlockedByPromoValue]: 'Blocked by "Products with future promo"',
  [CategorySummaryType.ExcessiveBlockedByMinimalQuantityValue]: 'Blocked by "Minimal quantity"',
  [CategorySummaryType.ExcessiveBlockedByMinimalSetValue]: 'Blocked by "Minimal set"',
  [CategorySummaryType.ExcessivePotentialTotalValue]: 'Potential for redeployment',
};

export const sumLockedByOptions = (data: ExcessiveStockPotentialInfo): number =>
  LOCKED_BY.reduce((p, c) => p + data[c], 0);

interface EligibleSupply {
  excessive: number;
  forced: number;
  isClosing: number;
  healthy: number;
  outgoing: number;
}

export interface InsightsSourceData {
  sourceTotalSupply: number;
  sourceEligibleSupply: number;

  totalSkuCount: number;
  sourceEligibleSkuCount: number;

  eligibleSupply: EligibleSupply;
  excessiveStock: ExcessiveStock;
  excessiveStockTotals: ExcessiveStockTotals;
}

export interface TargetPotentialInfo {
  total: number;
  byForecast: number;
  byMinLayers: number;
  byTargetList: number;
  inventory: number;
  openPurchaseOrders: number;
  incoming: number;
}

export interface InsightsTargetData {
  totalSkuCount: number;
  targetEligibleSkuCount: number;

  theoreticalPotential: number;
  targetPotentialForSourcesWithPotential: number;
  targetPotentialWithNoSourcePotential: number;

  targetPotential: TargetPotentialInfo;
}

const createStockPotentialInfo = (
  summaries: ParsedCategorySummaries,
  type: SkuType | 'total',
): ExcessiveStockPotentialInfo => {
  const excessiveStock: Partial<ExcessiveStockPotentialInfo> = {};

  LOCKED_BY.forEach(o => {
    excessiveStock[o] = summaries[o][type];
  });

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return excessiveStock as ExcessiveStockPotentialInfo;
};

export const parseCategorySummaryIntoSourceInsightsData = (
  summary: ParsedCategorySummaries,
  attributes: ParsedCategoryAttributeValues,
): InsightsSourceData => {
  const excessiveStock = {
    total: createStockPotentialInfo(summary, 'total'),
    deadstock: createStockPotentialInfo(summary, SkuType.DeadStock),
    slowmovers: createStockPotentialInfo(summary, SkuType.SlowMover),
    fastmovers: createStockPotentialInfo(summary, SkuType.FastMover),
  };

  return {
    sourceTotalSupply: attributes.AvailableSupplyValue,
    sourceEligibleSupply: summary.EligibleSourceSupplyValue.total,

    totalSkuCount: attributes.TotalSkuCount,
    sourceEligibleSkuCount: summary.EligibleSourceSkuCount.total,

    eligibleSupply: {
      excessive: summary.ExcessivePotentialTotalValue.total,
      forced: summary.ForcedValue.total,
      isClosing: summary.IsClosingValue.total,
      healthy: summary.HealthyValue.total,
      outgoing: summary.OutgoingValue.total,
    },
    excessiveStock,
    excessiveStockTotals: {
      total:
        summary.EligibleSourceSupplyValue.total -
        summary.HealthyValue.total -
        summary.ForcedValue.total -
        summary.IsClosingValue.total,
      deadstock:
        summary.EligibleSourceSupplyValue.DeadStock -
        summary.HealthyValue.DeadStock -
        summary.ForcedValue.DeadStock -
        summary.IsClosingValue.DeadStock,
      slowmovers:
        summary.EligibleSourceSupplyValue.SlowMover -
        summary.HealthyValue.SlowMover -
        summary.ForcedValue.SlowMover -
        summary.IsClosingValue.SlowMover,
      fastmovers:
        summary.EligibleSourceSupplyValue.FastMover -
        summary.HealthyValue.FastMover -
        summary.ForcedValue.FastMover -
        summary.IsClosingValue.FastMover,
    },
  };
};

export const parseCategorySummaryIntoTargetInsightsData = (
  summary: ParsedCategorySummaries,
  attributes: ParsedCategoryAttributeValues,
): InsightsTargetData => {
  return {
    totalSkuCount: attributes.TotalSkuCount,
    targetEligibleSkuCount: summary.EligibleTargetSkuCount.total, //TODO change for target

    theoreticalPotential: summary.TheoreticalPotentialValue.total,
    targetPotentialForSourcesWithPotential: summary.TargetPotentialOverreachingSourceValue.total,
    targetPotentialWithNoSourcePotential:
      summary.TargetPotentialUnfulfilledWithAnySourceValue.total,

    targetPotential: {
      total: getTargetPotentialFromSummary(summary, 'total'),
      byForecast: summary.TargetPotentialEndorsedByForecastValue.total,
      byMinLayers: summary.TargetPotentialEndorsedByMinimalLayersValue.total,
      byTargetList: summary.TargetPotentialEndorsedByTargetListsValue.total,
      inventory: summary.TargetSupplyForTheoreticalPotentialValue.total,
      openPurchaseOrders: summary.OpenPurchaseOrdersForTheoreticalPotentialValue.total,
      incoming: summary.IncomingForTheoreticalPotentialValue.total,
    },
  };
};

export type ParsedCategorySummaries = Record<
  CategorySummaryType,
  Record<SkuType | 'total', number>
>;

type CategoryRedistributionBySkuType = Record<
  SkuType,
  Record<CategoryRedistributionType | 'totalSource' | 'totalTarget', number>
>;

type CategoryRedistributionByRedistributionType = Record<
  CategoryRedistributionType,
  Record<SkuType | 'total', number>
>;

export interface ParsedCategoryRedistribution {
  byRedistributionType: CategoryRedistributionByRedistributionType;
  bySkuType: CategoryRedistributionBySkuType;
  total: number;
}

export const parseCategorySummaries = (
  summaries: CategorySummaryResponse[],
): ParsedCategorySummaries | undefined => {
  if (summaries.length === 0) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const result = summaries.reduce<Partial<ParsedCategorySummaries>>((acc, summary) => {
    const { skuTypeId, categorySummaryTypeId, value } = summary;

    if (!acc[categorySummaryTypeId]) {
      acc[categorySummaryTypeId] = {
        [SkuType.DeadStock]: 0,
        [SkuType.SlowMover]: 0,
        [SkuType.FastMover]: 0,
        total: 0,
      };
    }

    const insight = acc[categorySummaryTypeId];
    if (insight) {
      insight[skuTypeId] += value;
      insight.total += value;
    }
    return acc;
  }, {}) as ParsedCategorySummaries;

  //we need to compute total margin, because we can't sum the margin of each sku type in this case
  if (result.EligibleMonthlyRevenue.total > 0) {
    result.EligibleMargin.total =
      (result.EligibleMonthlyRevenue.DeadStock / result.EligibleMonthlyRevenue.total) *
        result.EligibleMargin.DeadStock +
      (result.EligibleMonthlyRevenue.SlowMover / result.EligibleMonthlyRevenue.total) *
        result.EligibleMargin.SlowMover +
      (result.EligibleMonthlyRevenue.FastMover / result.EligibleMonthlyRevenue.total) *
        result.EligibleMargin.FastMover;
  } else {
    result.EligibleMargin.total = 0;
  }

  return result;
};

export const parseCategoryRedistributionByRedistributionType = (
  redistributionData: CategoryRedistributionResponse[],
): CategoryRedistributionByRedistributionType => {
  const emptyData = () => ({
    [SkuType.DeadStock]: 0,
    [SkuType.SlowMover]: 0,
    [SkuType.FastMover]: 0,
    total: 0,
  });

  const emptyResult: CategoryRedistributionByRedistributionType = {
    [CategoryRedistributionType.SourceForcedValue]: emptyData(),
    [CategoryRedistributionType.SourceStandardValue]: emptyData(),
    [CategoryRedistributionType.SourceIsClosingValue]: emptyData(),
    [CategoryRedistributionType.TargetForecastValue]: emptyData(),
    [CategoryRedistributionType.TargetMinLayersValue]: emptyData(),
    [CategoryRedistributionType.TargetTargetListValue]: emptyData(),
    [CategoryRedistributionType.TargetOthersValue]: emptyData(),
    [CategoryRedistributionType.SendToStockoutValue]: emptyData(),
  };

  if (redistributionData.length === 0) return emptyResult;

  return redistributionData.reduce<CategoryRedistributionByRedistributionType>((acc, data) => {
    const { skuTypeId, categoryRedistributionTypeId, value } = data;

    const insight = acc[categoryRedistributionTypeId];

    insight[skuTypeId] += value;
    insight.total += value;

    return acc;
  }, emptyResult);
};

export const parseCategoryRedistributionBySkuType = (
  redistributionData: CategoryRedistributionResponse[],
): CategoryRedistributionBySkuType => {
  const emptyData = () => ({
    [CategoryRedistributionType.SourceForcedValue]: 0,
    [CategoryRedistributionType.SourceStandardValue]: 0,
    [CategoryRedistributionType.SourceIsClosingValue]: 0,
    [CategoryRedistributionType.TargetForecastValue]: 0,
    [CategoryRedistributionType.TargetMinLayersValue]: 0,
    [CategoryRedistributionType.TargetTargetListValue]: 0,
    [CategoryRedistributionType.TargetOthersValue]: 0,
    [CategoryRedistributionType.SendToStockoutValue]: 0,
    totalSource: 0,
    totalTarget: 0,
  });

  const emptyResult = {
    [SkuType.DeadStock]: emptyData(),
    [SkuType.SlowMover]: emptyData(),
    [SkuType.FastMover]: emptyData(),
  };

  if (redistributionData.length === 0) return emptyResult;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return redistributionData.reduce<CategoryRedistributionBySkuType>((acc, data) => {
    const { skuTypeId, categoryRedistributionTypeId, value } = data;

    const insight = acc[skuTypeId];

    insight[categoryRedistributionTypeId] += value;

    switch (categoryRedistributionTypeId) {
      case CategoryRedistributionType.SourceStandardValue:
      case CategoryRedistributionType.SourceForcedValue:
      case CategoryRedistributionType.SourceIsClosingValue:
        insight.totalSource += value;
        break;
      case CategoryRedistributionType.TargetForecastValue:
      case CategoryRedistributionType.TargetMinLayersValue:
      case CategoryRedistributionType.TargetTargetListValue:
      case CategoryRedistributionType.TargetOthersValue:
        insight.totalTarget += value;
        break;
      case CategoryRedistributionType.SendToStockoutValue:
        break;
    }

    return acc;
  }, emptyResult);
};

export const parseCategoryRedistribution = (
  redistributionData: CategoryRedistributionResponse[],
): ParsedCategoryRedistribution => {
  const result = {
    byRedistributionType: parseCategoryRedistributionByRedistributionType(redistributionData),
    bySkuType: parseCategoryRedistributionBySkuType(redistributionData),
    total: 0,
  };

  result.total =
    result.bySkuType[SkuType.DeadStock].totalSource +
    result.bySkuType[SkuType.SlowMover].totalSource +
    result.bySkuType[SkuType.FastMover].totalSource;

  return result;
};

export type ParsedCategoryAttributeValues = Record<CategoryAttributeValueType, number>;

export const parseCategoryAttributeValues = (
  attributes: CategoryAttributeValueResponse[],
): ParsedCategoryAttributeValues => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return attributes.reduce<Partial<ParsedCategoryAttributeValues>>((acc, summary) => {
    const { categoryAttributeValueTypeId, value } = summary;

    acc[categoryAttributeValueTypeId] = value;
    return acc;
  }, {}) as ParsedCategoryAttributeValues;
};

export interface ParsedCategoryInsights {
  summaries: ParsedCategorySummaries | undefined;
  attributes: ParsedCategoryAttributeValues;
  redistributions: ParsedCategoryRedistribution | undefined;
}

export const ALIGN_SELF_CENTER = { alignSelf: 'center' };

export const getSourcePotentialFromSummary = (
  summaries: ParsedCategorySummaries | undefined,
  skuType: SkuTypeForDrilldown,
): number => {
  if (!summaries) {
    return 0;
  }
  return (
    summaries.ExcessivePotentialTotalValue[skuType] +
    summaries.ForcedValue[skuType] +
    summaries.IsClosingValue[skuType]
  );
};

export const getTargetPotentialFromSummary = (
  summaries: ParsedCategorySummaries | undefined,
  skuType: SkuTypeForDrilldown,
): number => {
  if (!summaries) {
    return 0;
  }
  return (
    summaries.TargetPotentialEndorsedByForecastValue[skuType] +
    summaries.TargetPotentialEndorsedByMinimalLayersValue[skuType] +
    summaries.TargetPotentialEndorsedByTargetListsValue[skuType] +
    summaries.TargetPotentialOverreachingSourceValue[skuType] +
    summaries.TargetPotentialUnfulfilledWithAnySourceValue[skuType]
  );
};
