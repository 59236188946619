import { apiSlice, ErrorType } from './api';
import { MeCollection } from '../swagger/collections-identity';
import { UserChangePasswordRequest } from '@ydistri/identity-sdk';
import { getTags } from './apiLib';

const { TAGS_ARRAY } = getTags('identity');

export const apiIdentity = apiSlice.enhanceEndpoints({ addTagTypes: TAGS_ARRAY }).injectEndpoints({
  endpoints: builder => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    updateUserPassword: builder.mutation<void, UserChangePasswordRequest>({
      queryFn: async payload => {
        const { data } = await MeCollection.changePassword(payload).catch((err: ErrorType) => {
          // eslint-disable-next-line @typescript-eslint/only-throw-error
          throw { ...err, message: err.response.data.Messages[0] };
        });
        return { data };
      },
    }),
  }),
});

export const { useUpdateUserPasswordMutation } = apiIdentity;
