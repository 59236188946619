export function getOAuthSettings(): {
  oauthUrl: string;
  clientId: string;
  clientSecret: string;
} {
  return {
    oauthUrl: import.meta.env.VITE_REACT_APP_OAUTH_URL ?? '',
    clientId: import.meta.env.VITE_REACT_APP_OAUTH_CLIENT_ID ?? '',
    clientSecret: import.meta.env.VITE_REACT_APP_OAUTH_CLIENT_SECRET ?? '',
  };
}
