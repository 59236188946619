import { PickingResultsPart } from './pickingResultsLib';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PickingResultsSliceState {
  currentPart: PickingResultsPart;
}

const initialState: PickingResultsSliceState = {
  currentPart: PickingResultsPart.EXECUTION,
};

export const pickingResultsSlice = createSlice({
  name: 'pickingResults',
  initialState,
  reducers: {
    setCurrentPart: (state, action: PayloadAction<PickingResultsPart>) => {
      state.currentPart = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setCurrentPart } = pickingResultsSlice.actions;

export const pickingResultsReducer = pickingResultsSlice.reducer;
