import { ContentData, ContentDataType } from './detailSaleChartTooltipTypes';
import React, { useMemo } from 'react';
import { Text } from '@ydistri/ds';
import { css, styled } from 'styled-components';
import ChartLineLegendGraphic from './ChartLineLegendGraphic';
import { TChartBarInfo, TChartLineInfo } from '../../../../../lib/charts/saleChartsTypes';

/** Left aligned header cell */
const LineLabelHeaderCell = styled.th`
  text-align: left;
`;

/** Right aligned header cell */
const ValueHeaderCell = styled.th`
  text-align: center;
`;

/** Left aligned table cell */
const LineLabelCell = styled.td`
  text-align: left;
`;

/** Right aligned table cell */
const ValueCell = styled.td`
  text-align: right;
`;

const ContentTable = styled.table`
  border-collapse: collapse; //so the lines are not interrupted by a small gap
`;

const ContentHeader = styled.tr`
  & th {
    font-weight: 700;
    border-bottom: calc(2rem / ${({ theme }) => theme.rootFontSize}) solid #eee;
  }
`;

/** Row in Tooltip table for lines or total.
 * Total row draws a line above it
 */
const ContentTableRow = styled.tr<{ $isTotal?: boolean }>`
  ${p =>
    p.$isTotal
      ? css`
          border-top: calc(2rem / ${({ theme }) => theme.rootFontSize}) solid #eee;
        `
      : ''};
`;

interface TooltipContentProps {
  data: ContentData[];
  columnNames: string[];
}

/**
 * TooltipContent renders the content of the tooltip which is the table.
 * Table contains headers and rows.
 * @param data will be rendered in the body of the table
 * @param columnNames headers of the table
 * @constructor
 */
const TooltipContent: React.FC<TooltipContentProps> = ({ data, columnNames }) => {
  const header = useMemo(() => {
    return (
      <thead>
        <ContentHeader>
          {columnNames.map((columnHead, index) => {
            if (index === 0) {
              return (
                <LineLabelHeaderCell colSpan={2} key={columnHead}>
                  {columnHead}
                </LineLabelHeaderCell>
              );
            } else {
              return <ValueHeaderCell key={columnHead}>{columnHead}</ValueHeaderCell>;
            }
          })}
        </ContentHeader>
      </thead>
    );
  }, [columnNames]);

  const body = useMemo(() => {
    return (
      <tbody>
        {data.map(row => {
          const { line } = row;

          let info: TChartLineInfo | TChartBarInfo | undefined = undefined;
          if (line) {
            if ('lineInfo' in line) {
              info = line.lineInfo;
            } else if ('barInfo' in line) {
              info = line.barInfo;
            }
          }

          return (
            <ContentTableRow key={row.label} $isTotal={row.type === ContentDataType.TOTAL}>
              <td>{info && <ChartLineLegendGraphic $lineInfo={info} />}</td>
              <LineLabelCell>
                <Text $semiBold={true}>{row.label}</Text>
              </LineLabelCell>
              <ValueCell>{row.primaryColumnValue}</ValueCell>
              {row.secondaryColumnValue && <ValueCell>{row.secondaryColumnValue}</ValueCell>}
            </ContentTableRow>
          );
        })}
      </tbody>
    );
  }, [data]);

  return (
    <ContentTable>
      {header}
      {body}
    </ContentTable>
  );
};

export default TooltipContent;
