import { SkuType } from '@ydistri/api-sdk';
import CountableText from '../../../../../../../../../components/global/CountableText/CountableText';
import { Row, Text } from '@ydistri/ds';
import NoData from '../../../../../../../../../components/global/NoData/NoData';
import { SkuInfoComponent } from './SkuInfoTypes';

const SkuInfoTypeConfiguration: SkuInfoComponent = ({ sku }) => {
  if (sku) {
    switch (sku.type) {
      case SkuType.DeadStock:
        if (sku.config.CatDeadStock) {
          return (
            <CountableText
              value={parseInt(sku.config.CatDeadStock)}
              word="month"
              suffix="without sales"
            />
          );
        } else {
          return <NoData />;
        }
      case SkuType.SlowMover:
      case SkuType.FastMover: {
        if (sku.config.CatSlowMoverMonths && sku.config.CatSlowMoverSales) {
          return (
            <>
              <CountableText value={parseInt(sku.config.CatSlowMoverSales)} word="sale" />
              <Row $gap="0.5rem">
                <Text>{' in '}</Text>
                <CountableText value={parseInt(sku.config.CatSlowMoverMonths)} word="month" />
              </Row>
            </>
          );
        } else {
          return <NoData />;
        }
      }
      default:
        return <NoData />;
    }
  } else {
    return (
      <>
        <CountableText value={null} word="sale" />
        <Row $gap="0.5rem">
          <Text>{' in '}</Text>
          <CountableText value={null} word="month" />
        </Row>
      </>
    );
  }
};

export default SkuInfoTypeConfiguration;
