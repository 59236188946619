import { apiSlice } from '../../../apis/api';
import { CalculationsCollection } from '../../../swagger/collections';
import {
  CalculationStatisticTargetListItemResponse,
  CalculationStatisticTargetListItemSourceResponse,
  CalculationStatisticTargetListSummaryResponse,
} from '@ydistri/api-sdk';
import { getTags } from '../../../apis/apiLib';
import { computePercentage } from '@ydistri/utils';

const { TAGS, TAGS_ARRAY } = getTags('calculationStatistics');

interface GetSourceItemsForTargetSkuRequest {
  calculationId: number;
  targetSkuId: number;
}

export interface CalculationStatisticTargetListResponseWithKey
  extends CalculationStatisticTargetListItemResponse {
  key: number;
  receivedQuantityPercentage: number;
}

const countPercentage = (data: CalculationStatisticTargetListItemResponse): number =>
  Math.floor(computePercentage(data.receivedQuantity, data.requestedQuantity));

export const apiCalculationTargetListStatistics = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getTargetListOverallData: builder.query<
        CalculationStatisticTargetListSummaryResponse,
        number
      >({
        queryFn: async calculationId => {
          const { data } =
            await CalculationsCollection.getCalculationStatisticTargetListSummary(calculationId);
          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.calculationStats, id: `overall-${arg}` },
        ],
      }),
      getTargetListItems: builder.query<CalculationStatisticTargetListResponseWithKey[], number>({
        queryFn: async calculationId => {
          const { data } =
            await CalculationsCollection.getCalculationStatisticTargetListItems(calculationId);
          const mappedData = data.data
            .map(
              (item): CalculationStatisticTargetListResponseWithKey => ({
                ...item,
                key: item.skuId,
                receivedQuantityPercentage: countPercentage(item),
              }),
            )
            .sort((left, right) => {
              if (left.receivedQuantityPercentage === right.receivedQuantityPercentage) {
                const leftDiff = left.requestedQuantity - left.receivedQuantity;
                const rightDiff = right.requestedQuantity - right.receivedQuantity;
                return rightDiff - leftDiff;
              } else {
                return left.receivedQuantityPercentage > right.receivedQuantityPercentage ? -1 : 1;
              }
            });

          return { data: mappedData };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.calculationStats, id: `target-${arg}` },
        ],
      }),
      getSourceItemsForTargetSku: builder.query<
        CalculationStatisticTargetListItemSourceResponse[],
        GetSourceItemsForTargetSkuRequest
      >({
        queryFn: async ({ calculationId, targetSkuId }) => {
          const { data } =
            await CalculationsCollection.getCalculationStatisticTargetListItemSources(
              calculationId,
              targetSkuId,
            );

          return data;
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.calculationStats, id: `sources-${arg.targetSkuId}` },
        ],
      }),
    }),
  });

export const {
  useGetSourceItemsForTargetSkuQuery,
  useGetTargetListOverallDataQuery,
  useGetTargetListItemsQuery,
} = apiCalculationTargetListStatistics;
