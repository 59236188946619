import { BadgeProps } from 'antd';
import { ReactNode } from 'react';
import { ValidatorInfo } from './validatorInfoLib';
import { TextProps } from '@ydistri/ds';

export enum ValidatorStatus {
  RUNNING = 'RUNNING',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const validatorStatusBadgeColors: Record<ValidatorStatus, BadgeProps['color']> = {
  [ValidatorStatus.RUNNING]: 'blue',
  [ValidatorStatus.INFO]: 'blue',
  [ValidatorStatus.WARNING]: 'orange',
  [ValidatorStatus.ERROR]: 'red',
};

export const validatorStatusTextColors: Record<ValidatorStatus, TextProps['$type']> = {
  [ValidatorStatus.RUNNING]: 'default',
  [ValidatorStatus.INFO]: 'success',
  [ValidatorStatus.WARNING]: 'warning',
  [ValidatorStatus.ERROR]: 'danger',
};

export enum ValidatorSection {
  CATEGORIES = 'CATEGORIES',
  REGIONS = 'REGIONS',
  STORES_AND_DEPARTMENTS = 'STORES_AND_DEPARTMENTS',
  SKU_CLASSES = 'SKU_CLASSES',
  PRODUCT_LISTS = 'PRODUCT_LISTS',
  BRANDS = 'BRANDS',
}

export enum ValidatorMessageSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface ValidatorMessage {
  section: ValidatorSection;
  status: ValidatorMessageSeverity;
  key: number;
  message: ReactNode;
}

export interface ValidatorResponse<T extends ValidatorSection> {
  section: T;
  messages: ValidatorMessage[];
  info: ValidatorInfo[T];
  overviewMessage: string;
}

export type ValidatorSectionMessages = Record<ValidatorSection, ValidatorMessage[]>;

export const emptyValidatorSectionMessages: ValidatorSectionMessages = {
  [ValidatorSection.CATEGORIES]: [],
  [ValidatorSection.REGIONS]: [],
  [ValidatorSection.STORES_AND_DEPARTMENTS]: [],
  [ValidatorSection.SKU_CLASSES]: [],
  [ValidatorSection.PRODUCT_LISTS]: [],
  [ValidatorSection.BRANDS]: [],
};

export const validatorSectionTitles = (
  hasDepartments: boolean,
): Record<ValidatorSection, string> => ({
  [ValidatorSection.CATEGORIES]: 'Categories',
  [ValidatorSection.REGIONS]: 'Regions',
  [ValidatorSection.STORES_AND_DEPARTMENTS]: hasDepartments ? 'Stores and departments' : 'Stores',
  [ValidatorSection.SKU_CLASSES]: 'SKU Classes',
  [ValidatorSection.PRODUCT_LISTS]: 'Product Lists',
  [ValidatorSection.BRANDS]: 'Brands',
});

export interface ValidatorSectionMessageInfo {
  [ValidatorMessageSeverity.INFO]: number;
  [ValidatorMessageSeverity.WARNING]: number;
  [ValidatorMessageSeverity.ERROR]: number;
  total: number;
}

export const emptyValidatorSectionMessageInfo: ValidatorSectionMessageInfo = {
  [ValidatorMessageSeverity.INFO]: 0,
  [ValidatorMessageSeverity.WARNING]: 0,
  [ValidatorMessageSeverity.ERROR]: 0,
  total: 0,
};

export const getMessageInfoFromValidatorSectionMessages = (
  messages: ValidatorSectionMessages,
): ValidatorSectionMessageInfo => {
  const info: ValidatorSectionMessageInfo = {
    ...emptyValidatorSectionMessageInfo,
  };

  Object.values(messages).forEach(sectionMessages => {
    sectionMessages.forEach(message => {
      info[message.status] += 1;
      info.total += 1;
    });
  });

  return info;
};

export const getMessageInfoFromValidatorMessages = (
  messages: ValidatorMessage[],
): ValidatorSectionMessageInfo => {
  const info: ValidatorSectionMessageInfo = {
    ...emptyValidatorSectionMessageInfo,
  };

  messages.forEach(message => {
    info[message.status] += 1;
    info.total += 1;
  });

  return info;
};
