import { useGetTemplateQuery } from '../screens/Configuration/apiTemplate';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetCalculationQuery } from '../screens/CalculationDetail/apiCalculationDetail';
import { useTemplateOrCalculation } from './useTemplateOrCalculation';
import { useMemo } from 'react';
import { TemplateOrCalculationType } from '../apis/api';

export const useApplicationDate = (
  forcedType: TemplateOrCalculationType | undefined = undefined,
): Date | undefined => {
  const templateOrCalculation = useTemplateOrCalculation(forcedType);
  const { data: templateData } = useGetTemplateQuery(
    templateOrCalculation.type === 'Template' ? templateOrCalculation.id : skipToken,
  );
  const { data: calculationData } = useGetCalculationQuery(
    templateOrCalculation.type === 'Calculation' ? templateOrCalculation.id : skipToken,
  );

  return useMemo(() => {
    let date: Date | undefined = undefined;
    if (templateOrCalculation.type === 'Template') {
      if (templateData) {
        date = new Date(templateData.applicationDate);
      }
    } else {
      if (calculationData) {
        date = new Date(calculationData.applicationDate);
      }
    }

    return date;
  }, [calculationData, templateData, templateOrCalculation.type]);
};
