import {
  ApiOperationType,
  DeleteRegionFlowGroupRequest,
  Operation,
  RegionFlowGroupResponse,
} from '@ydistri/api-sdk';
import { apiSlice, TemplateOrCalculation, wrongTemplateInHeaders } from '../../../apis/api';
import {
  CalculationsCollection,
  CurrentSetupCollection,
  RegionsCollection,
} from '../../../swagger/collections';
import {
  SignalConfigurationChanged,
  SignalConfigurationsChanged,
} from '../../../signalr/signalrInterfaces';
import { signalrClient } from '../../../signalr/client';
import { getTags } from '../../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('regionFlowGroups');

interface ModifyRegionToGroupPayload {
  regionId: number;
  regionGroupId: number;
}

export const apiRegionFlowGroups = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      postRegionToGroup: builder.mutation<RegionFlowGroupResponse[], ModifyRegionToGroupPayload>({
        queryFn: async payload => {
          const { data } = await CurrentSetupCollection.postRegionFlowGroupConfiguration(
            payload.regionGroupId,
            {
              regionIds: [payload.regionId],
            },
          );

          return data;
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      removeRegionFromGroup: builder.mutation<void, ModifyRegionToGroupPayload>({
        queryFn: async payload => {
          const { data } = await CurrentSetupCollection.deleteRegionFlowConfiguration(
            payload.regionGroupId,
            {
              regionIds: [payload.regionId],
            },
          );
          return { data };
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      removeRegionGroup: builder.mutation<void, DeleteRegionFlowGroupRequest>({
        queryFn: async payload => {
          if (payload.regionFlowGroupIds) {
            await CurrentSetupCollection.deleteRegionFlowGroup({
              regionFlowGroupIds: [...payload.regionFlowGroupIds],
            });
          }
          return { data: undefined };
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      createNewRegionGroup: builder.mutation<void, void>({
        queryFn: async () => {
          await CurrentSetupCollection.postCurrentRegionFlowGroup();
          return { data: undefined };
        },
      }),
      getRegionFlowGroups: builder.query<RegionFlowGroupResponse[], TemplateOrCalculation>({
        queryFn: async arg => {
          if (wrongTemplateInHeaders(arg)) return { data: [] };
          const {
            data: { data: regionConfiguration },
          } = await (arg.type === 'Template'
            ? CurrentSetupCollection.getCurrentRegionFlowGroups()
            : CalculationsCollection.getRegionFlowGroups(arg.id));
          return { data: regionConfiguration };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.regionFlowGroups, id: `region-flow-groups-${arg.id}` },
        ],
        async onCacheEntryAdded(
          // eslint-disable-next-line no-unused-vars
          { type: _, id: selectedTemplateId },
          api,
        ) {
          try {
            await api.cacheDataLoaded;

            const listener1 = (signal: SignalConfigurationChanged) => {
              console.log('CONFIGURATION CHANGED', signal);
              if (selectedTemplateId !== signal.templateId) return;
              const groupId = signal.entityId;
              if (signal.entityType === 'RegionFlowGroup') {
                if (signal.operationType === ApiOperationType.Create) {
                  api.updateCachedData(draft => {
                    console.log('INSIDE SIGNAL: ', signal, groupId, []);
                    draft.push({
                      id: groupId,
                      regions: [],
                    });
                  });
                }
              } else if (signal.entityType === 'RegionFlowGroupConfiguration') {
                if (signal.operationType === ApiOperationType.Create) {
                  signal.updates.forEach(update => {
                    const regionId = update.value;
                    RegionsCollection.regionsList({
                      conditions: [{ fieldName: 'Id', operation: Operation.Eq, value: regionId }],
                    }).then(r => {
                      api.updateCachedData(draft => {
                        const group = draft.find(g => g.id === groupId);
                        if (group) group.regions.push(r.data.data[0]);
                      });
                    });
                  });
                } else if (signal.operationType === ApiOperationType.Delete) {
                  signal.updates.forEach(update => {
                    api.updateCachedData(draft => {
                      const group = draft.find(g => g.id === groupId);
                      if (group)
                        group.regions = group.regions.filter(
                          r => r.id !== parseInt(update.value ?? '0'),
                        );
                    });
                  });
                }
              }
            };

            //TODO: This is a hack to get around the fact that the signalr client does not support multiple listeners
            const listener2 = (signal2: SignalConfigurationsChanged[]) => {
              const signal = signal2[0];
              if (selectedTemplateId !== signal.templateId) return;
              const groupId = signal.entityId;
              if (signal.entityType === 'RegionFlowGroup') {
                if (signal.operationType === ApiOperationType.Delete) {
                  api.updateCachedData(draft => {
                    console.log('---------------------------------');
                    draft = draft.filter(g => g.id !== groupId);
                    return draft;
                  });
                }
              }
            };

            signalrClient.on('TemplateConfigurationChanged', listener1);
            signalrClient.on('TemplateConfigurationsChanged', listener2);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener1);
              signalrClient.off('TemplateConfigurationsChanged', listener2);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),
    }),
  });

export const {
  useGetRegionFlowGroupsQuery,
  useCreateNewRegionGroupMutation,
  usePostRegionToGroupMutation,
  useRemoveRegionFromGroupMutation,
  useRemoveRegionGroupMutation,
} = apiRegionFlowGroups;
