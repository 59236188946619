import {
  CalculationDetailResponse,
  CalculationMode,
  SkuCalculationConfigurationResponse,
} from '@ydistri/api-sdk';
import SubordinateCalculationSkuAvailabilityRenderer from './SubordinateCalculationSkuAvailabilityRenderer';
import React from 'react';
import {
  CalculationExtended,
  ColumnsExtension,
} from '../../../../../../Calculations/components/table/CalculationsTable';
import { CalculationDataType } from '../../../../../../Calculations/calculationsTypes';

export interface SubcalculationExtension {
  sourceAvailable: boolean;
  targetAvailable: boolean;
}

export type SubcalculationSelectorCalculationData = CalculationDataType<SubcalculationExtension>;

export const SUBCALCULATION_SELECTOR_TABLE_ID = 'subcalculation-selector';

export const mergeCalculationWithSubordinateCalculations = (
  calculation: CalculationDetailResponse | undefined,
  sourceSkuConfiguration: SkuCalculationConfigurationResponse | undefined = undefined,
  targetSkuConfiguration: SkuCalculationConfigurationResponse | undefined = undefined,
): SubcalculationSelectorCalculationData[] =>
  calculation
    ? [
        {
          ...calculation,
          title: `Resulting merged calculation - ${calculation.title}`,
          sourceAvailable: true,
          targetAvailable: true,
        },
        ...(calculation.subordinateCalculations ?? []).map(c => ({
          ...c,
          sourceAvailable: !!sourceSkuConfiguration?.subordinateCalculationSkus?.find(
            s => s.calculation.id === c.id,
          ),
          targetAvailable: !!targetSkuConfiguration?.subordinateCalculationSkus?.find(
            s => s.calculation.id === c.id,
          ),
        })),
      ]
    : [];

export const getSubordinateCalculationsTableColumnExtension = (
  sourceSkuId: number,
  targetSkuId: number,
): ColumnsExtension<SubcalculationExtension>[] => [
  {
    afterKey: 'title',
    columns: [
      {
        title: 'Source available',
        key: 'sourceAvailable',
        dataIndex: ['data', 'sourceAvailable'],
        width: '14rem',
        render: (
          sourceAvailable: boolean,
          record: CalculationExtended<SubcalculationExtension>,
        ): React.ReactNode => {
          return (
            <SubordinateCalculationSkuAvailabilityRenderer
              available={sourceAvailable}
              sourceOrTarget="Source"
              manual={record.data.mode === CalculationMode.Manual}
              calculationId={record.data.id}
              skuId={sourceSkuId}
            />
          );
        },
      },
      {
        title: 'Target available',
        key: 'targetAvailable',
        dataIndex: ['data', 'targetAvailable'],
        width: '14rem',
        render: (
          targetAvailable: boolean,
          record: CalculationExtended<SubcalculationExtension>,
        ): React.ReactNode => {
          return (
            <SubordinateCalculationSkuAvailabilityRenderer
              available={targetAvailable}
              sourceOrTarget="Target"
              manual={record.data.mode === CalculationMode.Manual}
              calculationId={record.data.id}
              skuId={targetSkuId}
            />
          );
        },
      },
    ],
  },
];
