import { TemplateOrCalculationType, TemplateOrCalculation } from '../apis/api';
import { useSelectedTemplateId } from './useSelectedTemplateId';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { createDebugLog } from '../lib/utils/logging';

const debugLog = createDebugLog('TemplateOrCalculation');

const templateOrCalculationMap = new Map<string, TemplateOrCalculation>();
const createMapKey = (obj: TemplateOrCalculation): string => `${obj.type}-${obj.id}`;
const defaultObject: TemplateOrCalculation = { type: 'Template', id: 0 };
templateOrCalculationMap.set(createMapKey(defaultObject), defaultObject);

const createObject = (
  calculationIdParam?: string,
  selectedTemplateId?: number,
  forcedType: TemplateOrCalculationType | undefined = undefined,
): TemplateOrCalculation => {
  if (forcedType === 'Calculation') {
    if (calculationIdParam) {
      return createFinalObject('Calculation', Number(calculationIdParam));
    } else {
      debugLog('TemplateOrCalculation forced to Calculation, but no calculationIdParam');
    }
  } else if (forcedType === 'Template') {
    if (selectedTemplateId) {
      return createFinalObject('Template', selectedTemplateId);
    } else {
      debugLog('TemplateOrCalculation forced to Template, but no selectedTemplateId');
    }
  }

  if (calculationIdParam) {
    return createFinalObject('Calculation', Number(calculationIdParam));
  } else if (selectedTemplateId) {
    return createFinalObject('Template', selectedTemplateId);
  }
  return createFinalObject('Template', 0);
};

const createFinalObject = (
  type: TemplateOrCalculation['type'],
  id: number,
): TemplateOrCalculation => {
  const obj = { type, id };
  const mapKey = createMapKey(obj);
  const existingObj = templateOrCalculationMap.get(mapKey);

  if (existingObj) {
    return existingObj;
  }

  templateOrCalculationMap.set(mapKey, obj);
  return obj;
};

/**
 * Returns TemplateOrCalculation object, which is used in endpoints for configurations
 * Based on values inside this object, correct endpoint will be used (rtk queries decide, if calculation or template endpoint should be used)
 * We use map, because we want to keep object reference, so that we don't need to re-render components, which use this hook
 */
export function useTemplateOrCalculation(
  forcedType: TemplateOrCalculationType | undefined = undefined,
): TemplateOrCalculation {
  const { calculationId } = useParams();
  const selectedTemplateId = useSelectedTemplateId();

  return useMemo(
    () => createObject(calculationId, selectedTemplateId, forcedType),
    [calculationId, forcedType, selectedTemplateId],
  );
}
