import { useGetBrandsQuery } from '../../../../../../../apis/apiLists';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterByBrandId } from '../../../../../../Calculations/calculationsSlice';
import GenericSelector from '../../../../../../../components/global/GenericSelector/GenericSelector';

const NO_BRAND = '- No brand specified -';

/**
 * Allows to select a brand to filter the redistribution table by.
 * <p>Brands are retrieved from the backend. When selected it sets the calculations.redistributionFilterBrandId in the Redux Store</p>
 * @constructor
 */
const BrandRedistributionFilter: React.FC = () => {
  const { data, isFetching, isError } = useGetBrandsQuery(undefined);
  const redistributionFilterBrandId = useSelector(
    state => state.calculations.redistributionFilterBrandId,
  );

  const dispatch = useDispatch();

  const onBrandSelected = useCallback(
    (brandId: number) => {
      dispatch(filterByBrandId(brandId));
    },
    [dispatch],
  );

  const sortedBrands = useMemo(() => {
    if (data) {
      const newData = [...data];
      newData.sort((left, right) => {
        if (left.name === NO_BRAND) {
          return 1;
        }

        if (right.name === NO_BRAND) {
          return -1;
        }

        return left.name.localeCompare(right.name);
      });
      return newData;
    }
  }, [data]);

  const selectorOptions = useMemo(() => {
    return sortedBrands?.map(brand => ({
      id: brand.id,
      label: brand.name,
    }));
  }, [sortedBrands]);

  if (!isError && !isFetching && selectorOptions) {
    return (
      <GenericSelector
        options={selectorOptions}
        label="Brand"
        value={redistributionFilterBrandId}
        onChange={onBrandSelected}
        showSearch={true}
      />
    );
  }
};

export default BrandRedistributionFilter;
