import InventoryTypeDefinitionPart from './InventoryTypeDefinitionPart';
import { ConfigurationDataType, ConfigurationFieldType, SkuType } from '@ydistri/api-sdk';
import ConfigurationField from '../../../../../components/global/ConfigurationField/ConfigurationField';
import { Row, Text } from '@ydistri/ds';
import React from 'react';

const FastMoversTypeDefinition: React.FC = () => {
  return (
    <InventoryTypeDefinitionPart type={SkuType.FastMover} title="Fast movers">
      <Row $flexWrap="wrap" $alignItems="center" $gap="0.5rem">
        <Text>More than</Text>
        <Text $bold>
          <ConfigurationField
            disabled={true}
            skuTypeColumn={null}
            fieldType={ConfigurationFieldType.CatSlowMoverSales}
            dataType={ConfigurationDataType.InputNumber}
            showValueAsText={true}
            inputWidth="0"
            padding="0"
          />
        </Text>
        <Text>sales within</Text>
        <Text $bold>
          <ConfigurationField
            disabled={true}
            skuTypeColumn={null}
            fieldType={ConfigurationFieldType.CatSlowMoverMonths}
            dataType={ConfigurationDataType.SelectBoxMonthIdentifySkuType}
            showValueAsText={true}
            inputWidth="0"
            padding="0"
          />
        </Text>
        <Text>months</Text>
      </Row>
    </InventoryTypeDefinitionPart>
  );
};

export default FastMoversTypeDefinition;
