import React, { useCallback } from 'react';
import { MdShowChart } from 'react-icons/md';
import { IconButton_IconSizes } from '@ydistri/ds';
import ToggleIconButton from '../../../../../../components/buttons/ToggleIconButton';
import { useTheme } from 'styled-components';

interface CumulativeForecastActionProps {
  checked?: boolean;
  onChanged?: (pushed: boolean) => void;
}

const cumulativeChartIcon = <MdShowChart size={IconButton_IconSizes.small} />;

const CumulativeForecastAction: React.FC<CumulativeForecastActionProps> = ({
  checked = false,
  onChanged,
}) => {
  const clickHandler = useCallback(() => {
    if (onChanged) {
      onChanged(!checked);
    }
  }, [onChanged, checked]);

  const theme = useTheme();

  return (
    <ToggleIconButton
      checked={checked}
      onClick={clickHandler}
      icon={cumulativeChartIcon}
      tooltip="Cumulative forecast"
      size="small"
      colorScheme={theme.iconColorSchemes.none}
    />
  );
};

export default CumulativeForecastAction;
