import { Row, Spinner, Text, TextSizeType } from '@ydistri/ds';
import React from 'react';

export interface LoadingIndicatorProps {
  text?: string;
  size?: TextSizeType;
  bold?: boolean;
}
const LoadingIndicator: React.FC<LoadingIndicatorProps> = React.memo(
  ({ text = 'Loading...', size = 'large', bold = true }) => {
    return (
      <Row $justifyContent="center" $alignItems="center" $gap="1rem">
        <Spinner />
        <Text $size={size} $bold={bold}>
          {text}
        </Text>
      </Row>
    );
  },
);

LoadingIndicator.displayName = 'LoadingIndicator';
export default LoadingIndicator;
