import { useSelector } from 'react-redux';
import { ReduxState } from '../store';
import { useGetUserProjectsQuery } from '../apis/apiApplication';
import { useMemo } from 'react';

export function useCurrency(): string {
  const projectCode = useSelector((state: ReduxState) => state.userReducer.selectedProjectCode);
  const { data: projects } = useGetUserProjectsQuery();

  return useMemo(() => {
    return projects?.find(p => p.id === projectCode)?.currency ?? '';
  }, [projectCode, projects]);
}
