import { CalculationMode, CalculationStatus, CalculationType } from '@ydistri/api-sdk';
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, Popover } from 'antd';
import { icons, IconType } from '../../../components/icons/icons';
import { GenericConfirmationModal, IconButton, IconButton_IconSizes } from '@ydistri/ds';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineMore } from 'react-icons/ai';
import { MdAppShortcut, MdAutorenew, MdCopyAll } from 'react-icons/md';
import LoadIntoTemplateModal from '../modals/LoadIntoTemplateModal';
import EditCalculationModal from '../../CalculationDetail/EditCalculationModal';
import { PatchCalculationPayload, usePatchCalculationMutation } from '../apiCalculations';
import { useUser } from '../../../hooks/useUser';
import { ImTruck } from 'react-icons/im';
import { useSelectedProjectCode } from '../../../hooks/useSelectedProjectCode';
import { AvailableModals, openModal } from '../../Modals/modalSlice';
import { useDispatch } from 'react-redux';
import { useApplicationConfiguration } from '../../../hooks/useApplicationConfiguration';
import { ItemType } from 'antd/es/menu/interface';
import { CalculationDataType } from '../calculationsTypes';
import CreateCalculationWithDefaultsModal from '../components/CreateCalculationWithDefaultsModal/CreateCalculationWithDefaultsModal';

enum ActionModal {
  DELETE = 'DELETE',
  LOAD_INTO_TEMPLATE = 'LOAD_INTO_TEMPLATE',
  EDIT = 'EDIT',
  SET_PRODUCTION = 'SET_PRODUCTION',
  CREATE_MERGED = 'CREATE_MERGED',
}

const iconMore = <AiOutlineMore size={IconButton_IconSizes.small} />;
const iconLoadIntoTemplate = <MdAutorenew size={IconButton_IconSizes.xSmall} />;
const iconPrivate = <AiOutlineEyeInvisible size={IconButton_IconSizes.xSmall} />;
const iconPublic = <AiOutlineEye size={IconButton_IconSizes.xSmall} />;
const iconProduction = <ImTruck size={IconButton_IconSizes.xSmall} />;
const iconPapApp = <MdAppShortcut size={IconButton_IconSizes.xSmall} />;
const iconCreateMerged = <MdCopyAll size={IconButton_IconSizes.xSmall} />;

interface CalculationContextMenuProps {
  calculation: CalculationDataType;
  onDelete?: (calculation: CalculationDataType) => void;
}

const CalculationContextMenu: React.FC<CalculationContextMenuProps> = ({
  calculation,
  onDelete,
}) => {
  const user = useUser();
  const projectCode = useSelectedProjectCode();
  const dispatch = useDispatch();

  const { applicationDate, isPickAndPackEnabled } = useApplicationConfiguration();

  const [editCalculation] = usePatchCalculationMutation();
  const [openedActionModal, setOpenedActionModal] = useState<ActionModal | undefined>();

  const closeModal = useCallback(() => {
    setOpenedActionModal(undefined);
  }, []);

  const deleteConfirmed = useCallback(() => {
    closeModal();
    if (onDelete) {
      onDelete(calculation);
    }
  }, [closeModal, onDelete, calculation]);

  /**
   * Changes the calculation type to private/public.
   * Called from the dropdown menu.
   */
  const setCalculationType = useCallback(
    (calculationId: number, newType: CalculationType) => {
      const payload: PatchCalculationPayload = {
        projectCode,
        calculationId,
        data: {
          calculationType: newType,
        },
      };
      editCalculation(payload);
    },
    [editCalculation, projectCode],
  );

  const waitingForPostprocess = [
    CalculationStatus.Queued,
    CalculationStatus.Running,
    CalculationStatus.Pending,
  ].includes(calculation.status);

  const setPap = useCallback(
    (calculationId: number, newIsPap: boolean) => {
      const payload: PatchCalculationPayload = {
        projectCode,
        calculationId,
        data: {
          isPap: newIsPap,
        },
      };
      editCalculation(payload);
    },
    [editCalculation, projectCode],
  );

  const menuItemClickHandler = useCallback(
    (menuInfo: {
      key: string;
      domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
    }) => {
      const { key, domEvent } = menuInfo;

      domEvent.preventDefault();

      switch (key) {
        case 'loadIntoTemplate':
          setOpenedActionModal(ActionModal.LOAD_INTO_TEMPLATE);
          break;
        case 'edit':
          setOpenedActionModal(ActionModal.EDIT);
          break;
        case 'delete':
          setOpenedActionModal(ActionModal.DELETE);
          break;
        case 'makePrivate':
          setCalculationType(calculation.id, CalculationType.Private);
          break;
        case 'makePublic':
          setCalculationType(calculation.id, CalculationType.Simulation);
          break;
        case 'makeProduction':
          dispatch(
            openModal({
              type: AvailableModals.SET_PRODUCTION,
              data: {
                calculationId: calculation.id,
                isErpDownload: false,
              },
            }),
          );
          break;
        case 'makePap':
          setPap(calculation.id, true);
          break;
        case 'removePap':
          setPap(calculation.id, false);
          break;
        case 'removeProduction':
          dispatch(
            openModal({
              type: AvailableModals.UNSET_PRODUCTION,
              data: {
                calculationId: calculation.id,
              },
            }),
          );
          break;
        case 'createNewMergedBasedOnThis':
          setOpenedActionModal(ActionModal.CREATE_MERGED);
          break;
      }
    },
    [setCalculationType, calculation.id, dispatch, setPap],
  );

  const menuItems = useMemo(() => {
    const items: ItemType[] = [];

    if (calculation.status === CalculationStatus.Completed) {
      if (
        calculation.mode !== CalculationMode.Manual &&
        calculation.mode !== CalculationMode.Merged
      ) {
        items.push({
          label: 'Load into template...',
          key: 'loadIntoTemplate',
          icon: iconLoadIntoTemplate,
          onClick: menuItemClickHandler,
        });
      }

      items.push({
        label: 'Edit calculation...',
        key: 'edit',
        icon: icons[IconType.EDIT_OUTLINED],
        onClick: menuItemClickHandler,
      });

      if (calculation.mode === CalculationMode.Merged) {
        items.push({
          label: 'Create using this merged calculation...',
          key: 'createNewMergedBasedOnThis',
          icon: iconCreateMerged,
          onClick: menuItemClickHandler,
        });
      }

      //only superuser can mark calculation as private/public
      //in view mode, the user can only mark his own calculations as private/public
      if (user?.isSuperUser && calculation.identityUserId === user.id) {
        if (calculation.type === CalculationType.Private) {
          items.push({
            label: 'Make public',
            key: 'makePublic',
            icon: iconPublic,
            onClick: menuItemClickHandler,
          });
        } else if (calculation.type === CalculationType.Simulation) {
          items.push({
            label: 'Make private',
            key: 'makePrivate',
            icon: iconPrivate,
            onClick: menuItemClickHandler,
          });
        }
      }

      if (calculation.type === CalculationType.Production) {
        items.push({
          label: 'Remove production flag',
          key: 'removeProduction',
          icon: iconProduction,
          onClick: menuItemClickHandler,
          disabled: calculation.isPap,
          title: calculation.isPap
            ? 'Calculations marked to PaP can not be unmarked as Production'
            : undefined,
        });
        if (isPickAndPackEnabled) {
          let allowedPapSync = false;
          if (calculation.finishExecutionUntil && applicationDate) {
            if (new Date(calculation.finishExecutionUntil) > new Date(applicationDate)) {
              allowedPapSync = true;
            }
          }

          items.push({
            label: calculation.isPap ? 'Re-sync to PaP' : 'Sync to PaP',
            key: 'makePap',
            icon: iconPapApp,
            onClick: menuItemClickHandler,
            disabled: !allowedPapSync,
          });

          if (calculation.isPap) {
            items.push({
              label: 'Remove from PaP',
              key: 'removePap',
              icon: iconPapApp,
              onClick: menuItemClickHandler,
            });
          }
        }
      } else {
        if (calculation.type !== CalculationType.Submerged) {
          items.push({
            label: 'Mark as production...',
            key: 'makeProduction',
            icon: iconProduction,
            onClick: menuItemClickHandler,
          });
        }
      }
    }

    if (calculation.isDeletable) {
      //do not add a divider if there is no option in the menu to make it look better
      if (items.length > 0) {
        items.push({
          type: 'divider',
        });
      }

      items.push({
        label: `Delete... ${waitingForPostprocess ? '(waiting for postprocess)' : ''}`,
        key: 'delete',
        icon: icons[IconType.DELETE_OUTLINED],
        onClick: menuItemClickHandler,
        danger: true,
        disabled: waitingForPostprocess,
      });
    }

    return { items };
  }, [
    applicationDate,
    calculation.finishExecutionUntil,
    calculation.identityUserId,
    calculation.isDeletable,
    calculation.isPap,
    calculation.mode,
    calculation.status,
    calculation.type,
    isPickAndPackEnabled,
    menuItemClickHandler,
    user?.id,
    user?.isSuperUser,
    waitingForPostprocess,
  ]);

  const voidClickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  }, []);

  return (
    <>
      {waitingForPostprocess ? (
        <Popover content="Waiting for postprocess...">
          <IconButton icon={iconMore} size="small" disabled />
        </Popover>
      ) : (
        <Dropdown menu={menuItems}>
          <IconButton icon={iconMore} size="small" onClick={voidClickHandler} />
        </Dropdown>
      )}

      {openedActionModal === ActionModal.EDIT && (
        <EditCalculationModal
          open={true}
          onCancel={closeModal}
          calculationId={calculation.id}
          onCalculationChanged={closeModal}
        />
      )}
      {openedActionModal === ActionModal.DELETE && (
        <GenericConfirmationModal
          title="Delete calculation"
          message={`Calculation '${calculation.title}' will be deleted. Continue? `}
          onConfirmed={deleteConfirmed}
          onCanceled={closeModal}
        />
      )}
      {openedActionModal === ActionModal.LOAD_INTO_TEMPLATE && (
        <LoadIntoTemplateModal
          calculationId={calculation.id}
          calculationTitle={calculation.title}
          onCanceled={closeModal}
          onConfirmed={closeModal}
        />
      )}
      {openedActionModal === ActionModal.CREATE_MERGED && (
        <CreateCalculationWithDefaultsModal calculation={calculation} onClose={closeModal} />
      )}
    </>
  );
};

export default CalculationContextMenu;
