import { AiOutlineMore } from 'react-icons/ai';
import { Button } from 'antd';
import DropdownButton from 'antd/es/dropdown/dropdown-button';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';

const iconMore = <AiOutlineMore size={computeRemSize(24)} />;

const StyledButton = styled(Button)`
  font-size: ${computeRemSize(30)} !important;
  border-color: transparent;
  background-color: transparent;

  &:hover {
    border-color: transparent !important;
    background-color: transparent !important;
  }

  svg {
    width: ${computeRemSize(30)};
    height: ${computeRemSize(30)};
  }
`;

const MoreIconButton: typeof DropdownButton = () => {
  return <StyledButton icon={iconMore} />;
};

export default MoreIconButton;
