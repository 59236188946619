import { ColumnsType } from 'antd/es/table';
import { CategoryDrillDownData } from '../drillDownLib';
import { formatNumber } from '@ydistri/utils';
import { styled } from 'styled-components';
import { TreeLeafTitle } from '../../../../components/global/CategoryTree/categoryTreeStyledComponents';
import { computeRemSize, marginPopoverContent } from '@ydistri/ds';
import { TemplateOrCalculation } from '../../../../apis/api';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom';

interface LeftPaddedNameProps {
  $level: number;
  $isSelected?: boolean;
}

const LeftPaddedName = styled(NavLink)<LeftPaddedNameProps>`
  padding-left: ${({ $level }) => 1 + $level * 1.5}rem;
  font-size: ${({ $level }) => ($level <= 1 ? computeRemSize(14) : '1rem')};
  font-weight: ${({ $level }) => ($level <= 1 ? '600' : 'regular')};
  height: ${({ $level }) => ($level === 0 ? computeRemSize(32) : computeRemSize(24))};
  display: flex;
  align-items: center;
`;

export const getCategoryDrillDownColumns = (
  templateOrCalculation: TemplateOrCalculation['type'],
  currency: string,
  omitPotentialColumns?: boolean,
): ColumnsType<CategoryDrillDownData> => {
  const finalColumns: ColumnsType<CategoryDrillDownData> = [
    {
      title: '',
      dataIndex: ['categoryName'],
      width: '40rem',
      render: (value: string, record) => (
        <LeftPaddedName
          to={`../${record.categorySlug}`}
          relative="path"
          $level={record.categoryLevel}
        >
          <TreeLeafTitle>{record.categoryLevel > 0 ? value : 'Company'} </TreeLeafTitle>
        </LeftPaddedName>
      ),
    },
    {
      title: (
        <Popover
          title={`Revenue [${currency}]`}
          content="Average 30 day sales (in sale price, without VAT, 30 day average is computed from last 90 days)"
        >
          Revenue [{currency}]
        </Popover>
      ),
      dataIndex: ['revenue'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    },
    {
      title: (
        <Popover
          title={`Available supply [${currency}]`}
          content="Current inventory value of all enabled product-store combinations (in cost price)"
        >
          Available supply [{currency}]
        </Popover>
      ),
      dataIndex: ['availableSupply'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    },
  ];

  if (!omitPotentialColumns) {
    finalColumns.push(
      {
        title: (
          <Popover
            title={`Source potential [${currency}]`}
            content="Affected by current settings (in cost price)"
          >
            Source potential [{currency}]
          </Popover>
        ),
        dataIndex: ['potentialSource'],
        render: (value: number) => formatNumber(value),
        align: 'right',
      },
      {
        title: (
          <Popover
            title={`Target potential [${currency}]`}
            content="Affected by current settings (in cost price)"
          >
            Target potential [{currency}]
          </Popover>
        ),
        dataIndex: ['potentialTarget'],
        render: (value: number) => formatNumber(value),
        align: 'right',
      },
    );
  }

  if (templateOrCalculation === 'Template') {
    finalColumns.push({
      title: (
        <Popover
          title={`Theoretical redistribution [${currency}]`}
          content="Maximum overlap of source and target potential, affected by current settings, before applying redistribution limitation settings (in cost price)"
        >
          Theoretical redistribution [{currency}]
        </Popover>
      ),
      dataIndex: ['addressableRedistribution'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    });
    finalColumns.push({
      title: (
        <Popover
          title={`Theoretical redistribution for out-of-stocks [${currency}]`}
          content="Value of theoretical redistribution, that can be sent to product-store combinations with zero inventory (in cost price)"
        >
          Theoretical redistribution for out-of-stocks [{currency}]
        </Popover>
      ),
      dataIndex: ['addressableRedistributionForStockout'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    });
  } else {
    finalColumns.push({
      title: `Final redistribution [${currency}]`,
      dataIndex: ['pairingFinal'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    });
    finalColumns.push({
      title: `Final redistribution for out-of-stocks [${currency}]`,
      dataIndex: ['pairingFilledStockout'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    });
  }

  finalColumns.push({
    title: (
      <Popover title="Margin" content={marginPopoverContent}>
        Margin
      </Popover>
    ),
    dataIndex: ['margin'],
    render: (value: number) => (value ? `${Math.floor(value * 100)}%` : '-'),
    align: 'right',
    width: '10rem',
  });

  return finalColumns;
};
