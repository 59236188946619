import React, { useEffect, useMemo, useState } from 'react';
import { INFINITE_PAGE_SIZE } from '@ydistri/ds';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import { skipToken } from '@reduxjs/toolkit/query';
import ProductCatalog from '../../../../components/global/ProductCatalog/ProductCatalog';
import { GetBrandProductsPayload, useGetBrandProductsQuery } from '../apiBrands';

export type BrandDetailParams = GetBrandProductsPayload;

const BrandDetail: React.FC = () => {
  const selectedBrandConfiguration = useSelector(
    (state: ReduxState) => state.brandConfiguration.selectedBrandConfiguration,
  );

  const [params, setParams] = useState<BrandDetailParams>({
    brandId: selectedBrandConfiguration?.id ?? 0,
    skip: 0,
    top: INFINITE_PAGE_SIZE,
  });

  const { data: brandProducts, isFetching } = useGetBrandProductsQuery(
    params.brandId > 0 ? params : skipToken,
  );

  useEffect(() => {
    if (selectedBrandConfiguration?.id) {
      setParams(p => ({
        ...p,
        brandId: selectedBrandConfiguration.id,
        skip: 0,
      }));
    }
  }, [selectedBrandConfiguration?.id]);

  /* ========================= Header ========================= */

  const header = useMemo(
    () => selectedBrandConfiguration?.name ?? 'Brand detail',
    [selectedBrandConfiguration],
  );

  /* ========================= Scrolling ========================= */

  return (
    <ProductCatalog
      header={header}
      id="brand-detail"
      data={brandProducts}
      loading={isFetching}
      setParams={setParams}
      alertMessage={!selectedBrandConfiguration ? 'No selected brand' : undefined}
      alertDescription={
        !selectedBrandConfiguration ? 'Select a brand to view its products' : undefined
      }
    />
  );
};

export default BrandDetail;
