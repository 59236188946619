import React, { useMemo } from 'react';
import useCategoryId from '../../../hooks/useCategoryId';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import {
  GetCategoryInsightsParams,
  useGetCategoryInsightsQuery,
} from '../CategoryInsights/apiCategoryInsights';
import { Divider, Statistic } from 'antd';
import { styled } from 'styled-components';
import {
  getSourcePotentialFromSummary,
  getTargetPotentialFromSummary,
} from '../CategoryInsights/categoryInsightsLib';
import { useCurrency } from '../../../hooks/useCurrency';
import { currencyFormatter } from '@ydistri/utils';
import { CalculationMode } from '@ydistri/api-sdk';

const PotentialStatistic = styled(Statistic)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //flex-basis: 5rem;
  width: 7.5rem;

  .ant-statistic-content-value {
    font-weight: 500;
  }
`;

interface PotentialValuesProps {
  calculationMode: CalculationMode;
}

const PotentialValues: React.FC<PotentialValuesProps> = ({ calculationMode }) => {
  const categoryId = useCategoryId();
  const currencyId = useCurrency();
  const templateOrCalculation = useTemplateOrCalculation();
  const categoryInsightsParam: GetCategoryInsightsParams = useMemo(
    () => ({
      categoryId,
      templateOrCalculation,
    }),
    [categoryId, templateOrCalculation],
  );

  const { data: categoryData } = useGetCategoryInsightsQuery(categoryInsightsParam);

  const { sourceFormatted, targetFormatted, finalFormatted, theoreticalFormatted } = useMemo(() => {
    const sourcePotential = getSourcePotentialFromSummary(categoryData?.summaries, 'total');
    const targetPotential = getTargetPotentialFromSummary(categoryData?.summaries, 'total');
    const finalRedistribution = categoryData?.redistributions?.total ?? 0;
    const theoreticalRedistribution = categoryData?.summaries?.TheoreticalPotentialValue.total ?? 0;

    return {
      sourceFormatted: currencyFormatter(currencyId, sourcePotential, sourcePotential >= 1000000),
      targetFormatted: currencyFormatter(currencyId, targetPotential, targetPotential >= 1000000),
      finalFormatted: currencyFormatter(
        currencyId,
        finalRedistribution,
        finalRedistribution >= 1000000,
      ),
      theoreticalFormatted: currencyFormatter(
        currencyId,
        theoreticalRedistribution,
        theoreticalRedistribution >= 1000000,
      ),
    };
  }, [categoryData?.summaries, categoryData?.redistributions?.total, currencyId]);

  const displaySourceAndTarget = calculationMode === CalculationMode.Computed;

  return (
    <>
      {displaySourceAndTarget && (
        <>
          <PotentialStatistic title="Source" value={sourceFormatted} />
          <Divider type="vertical" />
          <PotentialStatistic title="Target" value={targetFormatted} />
          <Divider type="vertical" />
        </>
      )}
      {templateOrCalculation.type === 'Calculation' && (
        <PotentialStatistic title="Final" value={finalFormatted} />
      )}
      {templateOrCalculation.type === 'Template' && (
        <PotentialStatistic title="Theoretical" value={theoreticalFormatted} />
      )}
    </>
  );
};

export default PotentialValues;
