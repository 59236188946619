import { AdministrationItemListHandlingMethod } from '../administrationItemsTypes';
import React, { useMemo } from 'react';
import { Space } from 'antd';
import StatisticsItemComponent from './StatisticsItemComponent';
import { extractValidationStatistics } from '../administrationItemsLib';
import { ProductListValidatedItem } from '../../ProductLists/productListTypes';
import { TargetListValidatedItem } from '../../TargetLists/targetListsTypes';

interface AdministrationItemValidationStatsProps<T> {
  data: T[];
  handlingMethod: AdministrationItemListHandlingMethod | undefined;
}

/**
 * Number of items to be added/updated, replaced, unchanged and number of errors to be
 * displayed after validation.
 * @param data
 * @param handlingMethod
 * @constructor
 */
const AdministrationItemValidationStats: React.FC<
  AdministrationItemValidationStatsProps<ProductListValidatedItem | TargetListValidatedItem>
> = ({ data, handlingMethod }) => {
  const stats = useMemo(
    () => extractValidationStatistics(data, handlingMethod),
    [handlingMethod, data],
  );

  return (
    <Space>
      {stats.map(stat => (
        <StatisticsItemComponent
          item={stat}
          key={stat.label}
          data-testid={`StatisticsItemComponent-${stat.label}`}
        />
      ))}
    </Space>
  );
};

export default AdministrationItemValidationStats;
