import { EntityListResponse, ProductListResponse, TargetListResponse } from '@ydistri/api-sdk';
import React, { useCallback } from 'react';
import { List } from 'antd';
import YListItem from '../../../../components/global/data-display/YListItem';
import { COLORS } from '@ydistri/ds';
import AdministrationItemLink from '../AdministrationItemLink';
import AdministrationItemCard from '../AdministrationItemCard';

interface AdministrationItemsListProps<T> {
  data?: T[];
  onItemClick?: (targetList: T) => void;
  loading?: boolean;
  countableDataProvider: (item: T) => { count: number; description: string }[];
  iconProvider?: (item: T) => React.JSX.Element;
  itemSelected?: (item: T) => boolean;
  idProvider: (item: T) => string | number;
  linkProvider: (item: T) => string;
}

const AdministrationItemsList = <
  T extends TargetListResponse | ProductListResponse | EntityListResponse,
>({
  data,
  loading,
  onItemClick,
  countableDataProvider,
  iconProvider,
  itemSelected,
  idProvider,
  linkProvider,
  ...rest
}: AdministrationItemsListProps<T>): React.ReactElement => {
  const yListItemClickHandler = useCallback((item: T) => () => onItemClick?.(item), [onItemClick]);

  const renderItem = useCallback(
    (item: T) => {
      return (
        <YListItem
          onClick={yListItemClickHandler(item)}
          selected={itemSelected ? itemSelected(item) : false}
          $backgroundColor={item.isArchived ? COLORS.GREY_LIGHT : undefined}
          data-recordid={idProvider(item)}
        >
          <AdministrationItemLink to={linkProvider(item)}>
            <AdministrationItemCard
              item={item}
              countable={countableDataProvider(item)}
              icon={iconProvider ? iconProvider(item) : undefined}
              $nameWidth="17rem"
            />
          </AdministrationItemLink>
        </YListItem>
      );
    },
    [
      yListItemClickHandler,
      itemSelected,
      idProvider,
      linkProvider,
      countableDataProvider,
      iconProvider,
    ],
  );

  return (
    <List dataSource={data} loading={loading} size="small" renderItem={renderItem} {...rest} />
  );
};

export default AdministrationItemsList;
