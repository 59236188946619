import { Select, SelectProps } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiApplication, useGetUserProjectsQuery } from '../../apis/apiApplication';
import { ProjectResponse, TenantResponse } from '@ydistri/identity-sdk';
import { API } from '../../swagger/collections';
import { RoutingPage } from '../../routes/routerSlice';
import { styled } from 'styled-components';
import { COLORS, computeRemSize } from '@ydistri/ds';
import { useDispatch } from 'react-redux';
import { ReduxDispatch } from '../../store';
import { createDebugLog } from '../../lib/utils/logging';
import { apiSlice, APITAGS, getAllTagsExcept } from '../../apis/api';
import { SearchableSelectOptionType, selectFilterHandler } from '@ydistri/utils';
import type { BaseSelectRef } from 'rc-select';

const debugLog = createDebugLog('ProjectSelect');

interface TTenantWithProjects {
  tenant: TenantResponse;
  projectsForTenant: ProjectResponse[];
}

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${computeRemSize(22)};
  font-size: ${computeRemSize(14)};
`;

const Divider = styled.div`
  height: ${computeRemSize(1)};
  margin: ${computeRemSize(6)} 0;
`;

const DropdownRenderer = styled.div`
  .rc-virtual-list-holder {
    max-height: 80vh !important;
  }
`;

const dropdownStyle = {
  fontSize: `${computeRemSize(32)} !important`,
  minWidth: computeRemSize(300),
};

const dropdownRender: SelectProps['dropdownRender'] = (origin: React.ReactElement) => {
  return <DropdownRenderer>{origin}</DropdownRenderer>;
};

function getTenantsWithProjectsFromProjects(projects: ProjectResponse[]) {
  const tenants: TTenantWithProjects[] = [];

  projects.forEach(p => {
    const relevantTenant = tenants.find(t => t.tenant.id === p.tenant?.id);
    if (!relevantTenant) {
      const newTenantProjects = [];
      newTenantProjects.push(p);
      if (p.tenant) {
        tenants.push({
          tenant: p.tenant,
          projectsForTenant: [p],
        });
      }
    } else {
      relevantTenant.projectsForTenant.push(p);
    }
  });

  return tenants;
}

const ProjectSelectStyled = styled(Select<string>)`
  width: 100%;
  padding: 0;

  .ant-select-selector {
    font-size: ${computeRemSize(22)} !important;

    .ant-select-selection-search {
      margin-top: ${computeRemSize(-7)} !important;
      input {
        font-family: Roboto, Helvetica, Arial, sans-serif !important;
        height: ${computeRemSize(22)} !important;
        font-size: ${computeRemSize(22)} !important;
      }
    }

    .ant-select-selection-item {
      overflow: visible;
    }
    .ant-select-selection-item div {
      color: ${COLORS.COLOR_DARK};
      font-size: ${computeRemSize(22)} !important;
      height: ${computeRemSize(22)};
    }
  }

  .ant-select-item {
    font-weight: 500;
  }
`;

export const ProjectSelect: React.FC = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const navigate = useNavigate();
  const ref = useRef<BaseSelectRef>(null);
  const { projectShortName } = useParams();
  const [localProjectShortName, setLocalProjectShortName] = useState(projectShortName);
  const { data: projects } = useGetUserProjectsQuery();

  const options: SearchableSelectOptionType[] = useMemo(() => {
    if (projects && projects.length > 0) {
      const tenants = getTenantsWithProjectsFromProjects(projects).sort((a, b) =>
        a.tenant.name.localeCompare(b.tenant.name),
      );

      return tenants.map((t, i) => ({
        searchableString: t.tenant.name,
        label: (
          <>
            {i > 0 && <Divider />}
            {t.tenant.name}
          </>
        ),
        options: t.projectsForTenant.map(p => ({
          value: p.shortName,
          label: <OptionRow>{p.name}</OptionRow>,
          searchableString: p.name,
        })),
      }));
    }

    return [];
  }, [projects]);

  /**
   * Handles the onChange event for selecting a project.
   *
   * 1. set header 'Project-Code' to new project code
   * 2. we reset calculations to empty array, so when project is changed, there won't be old ones
   * 3. we navigate to calculations page of the OLD project (useful later - this will ensure that after api reset, no unnecessary queries will be run, because the page is already "calculations")
   * 4. we get templates of the NEW project and then, set template id to the first template of new project (necessary to not query new project endpoints with template id from old project)
   * 5. completely reset api state (so we don't have any old data from the old project)
   * 6. set data of getUserProjects to the original projects (so the project select is not in the loading state)  TODO: Does not work, project short name still flashes inside of select
   * 7. redirect to new project calculations
   *
   * @param {string} selectedProjectShortName - The short name of the selected project.
   * @returns {void}
   */
  const onChangeHandler = useCallback(
    (selectedProjectShortName: string) => {
      debugLog('XXXXXXXXXXXXXXX changing project XXXXXXXXXXXXXXX');
      if (projects) {
        const project = projects.find(i => i.shortName === selectedProjectShortName);
        const oldProject = projects.find(
          i => i.id === API.instance.defaults.headers.common['Project-Code'],
        );
        if (project && oldProject) {
          setLocalProjectShortName(selectedProjectShortName);
          debugLog('OLD API project code: ', API.instance.defaults.headers.common['Project-Code']);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          API.instance.defaults.headers.common['Project-Code'] = project.id;
          debugLog('NEW API project code: ', API.instance.defaults.headers.common['Project-Code']);

          debugLog(`Navigating - /${oldProject.shortName}/${RoutingPage.CALCULATIONS}`);
          navigate(`/${oldProject.shortName}/${RoutingPage.CALCULATIONS}`);

          dispatch(apiApplication.endpoints.getTemplates.initiate(project.id)).then(
            ({ data: templates }) => {
              debugLog('templates', templates);
              if (templates && templates.length > 0) {
                API.instance.defaults.headers.common['Template-Id'] = templates[0].id;

                debugLog('resetting api state and setting projects after reset');

                dispatch(
                  apiSlice.util.invalidateTags(
                    getAllTagsExcept([
                      APITAGS.application.projects,
                      APITAGS.calculations.calculations,
                      APITAGS.application.templates,
                    ]),
                  ),
                );

                dispatch(
                  apiSlice.util.invalidateTags([
                    { type: APITAGS.calculations.calculations, id: project.id },
                  ]),
                );

                debugLog(`Navigating - /${project.shortName}/${RoutingPage.CALCULATIONS}\``);
                navigate(`/${project.shortName}/${RoutingPage.CALCULATIONS}`);
              } else {
                debugLog("No templates found - this shouldn't happen!");
              }
            },
          );
        }
      }
      debugLog('XXXXXXXXXXXXXXX changing project end XXXXXXXXXXXXXXX');
    },
    [projects, dispatch, navigate],
  );

  const hasOptions = options.length > 0;

  const value = useMemo(
    () => (hasOptions ? (localProjectShortName ?? projectShortName) : undefined),
    [hasOptions, localProjectShortName, projectShortName],
  );

  const focusOnDropdownOpen = useCallback((open: boolean) => {
    if (open && ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <ProjectSelectStyled
      ref={ref}
      value={value}
      onChange={onChangeHandler}
      onDropdownVisibleChange={focusOnDropdownOpen}
      options={options}
      showSearch={true}
      variant="borderless"
      popupMatchSelectWidth={false}
      dropdownStyle={dropdownStyle}
      dropdownRender={dropdownRender}
      id="ProjectSelector"
      suffixIcon={null}
      filterOption={selectFilterHandler}
    />
  );
};
