import {
  ApiOperationType,
  BrandConfigurationResponse,
  Condition,
  LogicalOperator,
  Operation,
  ProductResponse,
  SortDirection,
  Sorting,
} from '@ydistri/api-sdk';
import { apiSlice, TemplateOrCalculation, wrongTemplateInHeaders } from '../../../apis/api';
import {
  CalculationsCollection,
  CurrentSetupCollection,
  ProductsCollection,
} from '../../../swagger/collections';
import { SignalConfigurationsChanged } from '../../../signalr/signalrInterfaces';
import { signalrClient } from '../../../signalr/client';
import {
  forceRefetchForInfiniteScroll,
  mergeForInfiniteScroll,
  serializeQueryArgsForInfiniteScroll,
} from '@ydistri/ds';
import { getTags } from '../../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('brandsConfiguration');

export interface UpdateBrandConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  brandId: number;
}

export interface GetBrandConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  top?: number;
  skip?: number;
  search?: string;
  pastedBrands?: string[];
}

export interface GetBrandProductsPayload {
  brandId: number;
  top?: number;
  skip?: number;
  search?: string;
}

export interface DeleteBrandConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  brandId: number;
}

export const apiBrands = apiSlice.enhanceEndpoints({ addTagTypes: TAGS_ARRAY }).injectEndpoints({
  endpoints: builder => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getBrandConfiguration: builder.query<
      BrandConfigurationResponse[], //Expanded
      GetBrandConfigurationPayload
    >({
      queryFn: async payload => {
        if (wrongTemplateInHeaders(payload.templateOrCalculation)) return { data: [] };
        const sortings: Sorting[] = [
          { fieldName: 'IsConfigured', direction: SortDirection.Desc },
          { fieldName: 'Name', direction: SortDirection.Asc },
        ];

        const conditions: Condition[] = [];

        if ((payload.pastedBrands ?? []).length > 0) {
          payload.pastedBrands?.forEach(pb => {
            conditions.push({
              fieldName: 'Name',
              value: pb,
              operation: Operation.Eq,
              logicalOperator: LogicalOperator.Or,
            });
          });
        } else {
          if (payload.search) {
            conditions.push({
              rawQuery: `Name contains ${payload.search}`,
            });
          } else {
            conditions.push({
              rawQuery: `IsConfigured eq true or ProductCount gt 0`,
            });
          }
        }

        const {
          data: { data: brandConfiguration },
        } = await (payload.templateOrCalculation.type === 'Template'
          ? CurrentSetupCollection.getCurrentBrands({
              sortings,
              top: payload.top,
              skip: payload.skip ?? 0,
              conditions,
              inlineCount: true,
            })
          : CalculationsCollection.getCalculationBrands(payload.templateOrCalculation.id, {
              sortings,
              top: payload.top,
              skip: payload.skip ?? 0,
              conditions,
              inlineCount: true,
            }));

        return { data: brandConfiguration };
      },
      providesTags: [TAGS.brandsConfiguration],
      async onCacheEntryAdded(
        // eslint-disable-next-line no-unused-vars
        { templateOrCalculation: { type: _, id: selectedTemplateId } },
        api,
      ) {
        try {
          await api.cacheDataLoaded;

          const listener = (signals: SignalConfigurationsChanged[]) => {
            console.log('CONFIGURATION CHANGED', signals);
            const brandIdsToDelete: number[] = [];
            signals.forEach(signal => {
              if (selectedTemplateId !== signal.templateId) return;
              const brandId = signal.entityId;
              if (signal.entityType === 'BrandConfiguration') {
                if (signal.operationType === ApiOperationType.Create) {
                  api.updateCachedData(draft => {
                    const brand = draft.find(r => r.id === brandId);
                    if (brand) brand.isConfigured = true;
                  });
                } else if (signal.operationType === ApiOperationType.Delete) {
                  brandIdsToDelete.push(brandId);
                }
              }
            });

            if (brandIdsToDelete.length > 0) {
              api.updateCachedData(draft => {
                const result: BrandConfigurationResponse[] = [];
                draft.forEach(b => {
                  const deleteBrand = brandIdsToDelete.includes(b.id);
                  if (deleteBrand) {
                    if (b.productCount > 0) {
                      b.isConfigured = false;
                      result.push(b);
                    }
                  } else {
                    result.push(b);
                  }
                });
                return result;
              });
            }
          };

          signalrClient.on('TemplateConfigurationsChanged', listener);

          api.cacheEntryRemoved.then(() => {
            signalrClient.off('TemplateConfigurationsChanged', listener);
          });
        } catch {
          // no-op in case cache entry was removed before data was loaded
        }
        await api.cacheEntryRemoved;
      },
      serializeQueryArgs: serializeQueryArgsForInfiniteScroll<GetBrandConfigurationPayload>(),
      merge: (currentCache, newItems, otherArgs): void => {
        if (otherArgs.arg.skip === 0) {
          currentCache.splice(0, currentCache.length);
        }
        const itemsToPush = newItems.filter(i => !currentCache.find(c => c.id === i.id));
        currentCache.push(...itemsToPush);
      },
      forceRefetch: forceRefetchForInfiniteScroll<GetBrandConfigurationPayload | undefined>(),
    }),

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    updateBrandConfiguration: builder.mutation<void, UpdateBrandConfigurationPayload>({
      queryFn: async payload => {
        const { data } = await CurrentSetupCollection.postCurrentBrandConfiguration(
          payload.brandId,
        );

        return { data };
      },
      onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiBrands.util.updateQueryData(
            'getBrandConfiguration',
            {
              templateOrCalculation: payload.templateOrCalculation,
            },
            draft => {
              const brand = draft.find(r => r.id === payload.brandId);
              if (brand)
                Object.assign(brand, {
                  isConfigured: true,
                });
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    deleteBrandConfiguration: builder.mutation<void, DeleteBrandConfigurationPayload>({
      queryFn: async payload => {
        await CurrentSetupCollection.deleteCurrentBrandConfiguration(payload.brandId);
        return { data: undefined };
      },
      onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiBrands.util.updateQueryData(
            'getBrandConfiguration',
            {
              templateOrCalculation: payload.templateOrCalculation,
            },
            draft => {
              const brand = draft.find(r => r.id === payload.brandId);
              if (brand)
                Object.assign(brand, {
                  isConfigured: false,
                });
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    resetBrandConfiguration: builder.mutation<void, TemplateOrCalculation>({
      queryFn: async () => {
        await CurrentSetupCollection.deleteCurrentBrandConfigurations();
        return { data: undefined };
      },
      onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiBrands.util.updateQueryData(
            'getBrandConfiguration',
            {
              templateOrCalculation: payload,
            },
            draft => {
              draft.forEach(b => {
                if (b.isConfigured) b.isConfigured = false;
              });
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getBrandProducts: builder.query<
      ProductResponse[], //Expanded
      GetBrandProductsPayload
    >({
      queryFn: async payload => {
        console.log('Inside query function', payload);
        const conditions = [
          {
            rawQuery: `Brand/Id eq ${payload.brandId}`,
          },
        ];

        const {
          data: { data: brandProducts },
        } = await ProductsCollection.productsList({
          top: payload.top,
          skip: payload.skip ?? 0,
          inlineCount: true,
          conditions: conditions,
          search: payload.search,
          sortings: [{ fieldName: 'Name', direction: SortDirection.Asc }],
        });

        return { data: brandProducts };
      },
      providesTags: (result, error, arg) => [{ type: TAGS.brandProducts, id: `bp-${arg.brandId}` }],
      serializeQueryArgs: serializeQueryArgsForInfiniteScroll<GetBrandProductsPayload>(),
      merge: mergeForInfiniteScroll<ProductResponse, GetBrandProductsPayload>(),
      forceRefetch: forceRefetchForInfiniteScroll<GetBrandProductsPayload | undefined>(),
    }),
  }),
});

export const {
  useGetBrandConfigurationQuery,
  useUpdateBrandConfigurationMutation,
  useDeleteBrandConfigurationMutation,
  useGetBrandProductsQuery,
  useResetBrandConfigurationMutation,
} = apiBrands;
