import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  computeRemSize,
  getDefaultSorting,
  InfiniteScrollTable,
  Panel,
  YColumnsType,
} from '@ydistri/ds';
import { ProductResponse } from '@ydistri/api-sdk';
import { ProductListItemsRequest, useGetProductListItemsQuery } from '../../apiProductLists';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

interface ProductListSkuGridProps {
  selectedProductListId: number;
}

const BLOCK_SIZE: number = 45;

const ProductListProductsGrid: React.FC<ProductListSkuGridProps> = ({ selectedProductListId }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { productIdColumnWidth } = useColumnWidth();
  const [tableHeight, setTableHeight] = useState(480);

  const productColumnsProvider = useProductTableColumnsProvider();

  const columns: YColumnsType<ProductResponse>[] = useMemo(() => {
    const productColumnsConfig: EntityColumnsConfig<ProductResponse> = {
      [EntityColumns.ID]: {
        width: productIdColumnWidth,
        apiColumnName: 'Id',
        apiFilterable: true,
      },
      [EntityColumns.NAME]: {
        width: '29rem',
        apiColumnName: 'Name',
        apiFilterable: true,
      },
      [EntityColumns.CODE]: {
        width: '6rem',
        apiColumnName: 'Code',
        apiFilterable: true,
      },
    };

    return productColumnsProvider(productColumnsConfig, []);
  }, [productColumnsProvider, productIdColumnWidth]);

  const defaultParams = useMemo(() => {
    return {
      productListId: selectedProductListId,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [columns, selectedProductListId]);

  const [params, setParams] = useState<ProductListItemsRequest>(defaultParams);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  const { data, isFetching, refetch } = useGetProductListItemsQuery(params);

  const resizeTable = useCallback(() => {
    const TABLE_SHIFT: number = 96;
    if (ref.current) {
      setTableHeight(ref.current.clientHeight - TABLE_SHIFT);
    }
  }, []);

  useEffect(() => {
    resizeTable();

    //compute height on window resize
    window.addEventListener('resize', () => resizeTable);

    return () => {
      window.removeEventListener('resize', () => resizeTable);
    };
  }, [refetch, resizeTable]);

  const rowKeyProvider = useCallback(
    (row: ProductResponse) => (row.customerId ? row.customerId.toString() : row.id.toString()),
    [],
  );

  return (
    <Panel $height="100%" ref={ref}>
      <InfiniteScrollTable<ProductResponse, ProductListItemsRequest>
        id="targetListContent"
        height={computeRemSize(tableHeight)}
        setParams={setParams}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
      />
    </Panel>
  );
};

export default ProductListProductsGrid;
