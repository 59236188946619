import React from 'react';
import { EntityListType } from '@ydistri/api-sdk';
import EntityListEditor from '../edit/EntityListEditor';

interface EntityListStepImportProps {
  entityListType: EntityListType;
}

const EntityListStepImport: React.FC<EntityListStepImportProps> = ({ entityListType }) => {
  return <EntityListEditor entityListType={entityListType} />;
};

export default EntityListStepImport;
