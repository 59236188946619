import React, { useCallback, useMemo } from 'react';
import GenericSelector from '../../../../../../components/global/GenericSelector/GenericSelector';

export interface DepartmentOption {
  id: number;
  name: string;
  customerDepartmentId: string | null;
}

interface DepartmentSelectorProps {
  options: DepartmentOption[];
  onChange?: (departmentId: number | undefined) => void;
  value?: number;
  noSelectionLabel?: string;
}

const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({
  options,
  onChange,
  value,
  noSelectionLabel,
}) => {
  const departmentOptions = useMemo(() => {
    return options.map(option => ({
      id: option.id,
      label: option.customerDepartmentId
        ? `${option.customerDepartmentId} - ${option.name}`
        : option.name,
    }));
  }, [options]);

  const changeHandler = useCallback(
    (value: number) => {
      if (onChange) {
        onChange(value === -1 ? undefined : value);
      }
    },
    [onChange],
  );

  return (
    <GenericSelector
      options={departmentOptions}
      label="Department"
      onChange={changeHandler}
      value={value}
      noSelectionLabel={noSelectionLabel}
      showSearch={true}
    />
  );
};

export default DepartmentSelector;
