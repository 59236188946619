import React, { useMemo } from 'react';
import RedistributionImportEditor from './RedistributionImportEditor';
import { useGetCalculationRedistributionImportQuery } from '../../../apiCalculationRedistributionImport';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import LoadingIndicator from '../../../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import RedistributionPendingImport from './RedistributionPendingImport';

const RedistributionImportStepImport: React.FC = () => {
  const calculation = useTemplateOrCalculation();
  const { data, isFetching, isError } = useGetCalculationRedistributionImportQuery(calculation.id);

  const showEditor = useMemo(() => {
    if (!isFetching) {
      //error is thrown if the import does not exist and we can continue
      if (isError) {
        return true;
      }
    }
  }, [isError, isFetching]);

  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (showEditor) {
    return <RedistributionImportEditor />;
  } else {
    return data && <RedistributionPendingImport calculationImport={data} />;
  }
};

export default RedistributionImportStepImport;
