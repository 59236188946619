import { apiSlice, TemplateOrCalculation } from './api';
import {
  BrandDetailResponse,
  CalculationDepartmentResponse,
  DepartmentResponse,
  RegionResponse,
  StoreResponse,
  StoreTypeResponse,
} from '@ydistri/api-sdk';
import {
  BrandsCollection,
  CalculationsCollection,
  TemplatesCollection,
} from '../swagger/collections';
import { getTags } from './apiLib';

const { TAGS, TAGS_ARRAY } = getTags('lists');

export interface ListType<T> {
  array: T[];
  object: Record<number, T | undefined>;
}

const reduceDataToListTypeObject = <T extends { id: number }>(
  data: T[],
  sorter?: (a: T, b: T) => number,
): ListType<T> => {
  const obj: ListType<T>['object'] = {};
  data.reduce((acc, slice) => {
    acc[slice.id] = slice;
    return acc;
  }, obj);

  return { array: sorter ? data.toSorted(sorter) : data, object: obj };
};

export const apiLists = apiSlice.enhanceEndpoints({ addTagTypes: TAGS_ARRAY }).injectEndpoints({
  endpoints: builder => ({
    getBrands: builder.query<BrandDetailResponse[], undefined>({
      queryFn: async () => {
        const response = await BrandsCollection.brandsList();
        return { data: response.data.data };
      },
      providesTags: [TAGS.brandsList],
    }),

    getStores: builder.query<ListType<StoreResponse>, TemplateOrCalculation>({
      queryFn: async arg => {
        const response = await (arg.type === 'Template'
          ? TemplatesCollection.getTemplateStores(arg.id)
          : CalculationsCollection.getCalculationStores(arg.id));

        return {
          data: reduceDataToListTypeObject(response.data.data),
        };
      },
      providesTags: [TAGS.storesList],
    }),

    getRegions: builder.query<ListType<RegionResponse>, TemplateOrCalculation>({
      queryFn: async arg => {
        const response = await (arg.type === 'Template'
          ? TemplatesCollection.getTemplateRegions(arg.id)
          : CalculationsCollection.getCalculationRegions(arg.id));

        return {
          data: reduceDataToListTypeObject(response.data.data, (left, right) =>
            left.name.localeCompare(right.name),
          ),
        };
      },
      providesTags: [TAGS.regionsList],
    }),

    getStoreTypes: builder.query<ListType<StoreTypeResponse>, TemplateOrCalculation>({
      queryFn: async arg => {
        const response = await (arg.type === 'Template'
          ? TemplatesCollection.getTemplateStoreTypes(arg.id)
          : CalculationsCollection.getCalculationStoreTypes(arg.id));

        return { data: reduceDataToListTypeObject(response.data.data) };
      },
      providesTags: [TAGS.storeTypesList],
    }),

    getDepartments: builder.query<
      ListType<DepartmentResponse | CalculationDepartmentResponse>,
      TemplateOrCalculation
    >({
      queryFn: async arg => {
        const response = await (arg.type === 'Template'
          ? TemplatesCollection.getTemplateDepartments(arg.id)
          : CalculationsCollection.getCalculationDepartments(arg.id));

        return { data: reduceDataToListTypeObject(response.data.data) };
      },
      providesTags: [TAGS.departmentsList],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetRegionsQuery,
  useGetStoresQuery,
  useGetStoreTypesQuery,
  useGetDepartmentsQuery,
} = apiLists;
