import { ColumnType } from 'antd/lib/table';
import React from 'react';
import TableExport from '../../../../../../components/global/TableExport/TableExport';
import { Button } from 'antd';

interface DetailSalesExportProps<T> {
  title?: string;
  dataSource: T[];
  columns: ColumnType<T>[];
}

const DetailSalesExport = <T extends object>({
  title,
  dataSource,
  columns,
}: DetailSalesExportProps<T>): React.ReactElement => {
  return (
    <TableExport
      columns={columns}
      dataSource={dataSource}
      fileName={title ?? 'Detail Sales.xlsx'}
      sheetName="Detail Sales"
      level={2}
    >
      <Button>Export</Button>
    </TableExport>
  );
};

export default DetailSalesExport;
