import React, { useCallback, useMemo } from 'react';
import { DatePicker, Form, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState } from '../../store';
import { useGetApplicationConfigurationQuery } from '../../apis/apiApplication';
import dayjs from 'dayjs';
import { useGetCalculationQuery } from '../CalculationDetail/apiCalculationDetail';
import {
  PatchCalculationPayload,
  usePatchCalculationMutation,
} from '../Calculations/apiCalculations';
import { CalculationType } from '@ydistri/api-sdk';
import LoadingIndicator from '../../components/global/LoadingIndicator/LoadingIndicator';
import { Column, Text } from '@ydistri/ds';

interface FormFields {
  finishExecutionUntil: dayjs.Dayjs;
}

const modalId = AvailableModals.SET_PRODUCTION;

const SetProductionModal: React.FC = () => {
  const [form] = Form.useForm<FormFields>();
  const dispatch = useDispatch();
  const modalData = useSelector((state: ReduxState) => state.modal.modalData[modalId]);

  const calculationId = modalData?.calculationId ?? 0;
  const isErpDownload = modalData?.isErpDownload ?? false;

  const { data: applicationConfiguration } = useGetApplicationConfigurationQuery();
  const { data: calculationData, isFetching } = useGetCalculationQuery(calculationId);
  const [editCalculation, { isLoading }] = usePatchCalculationMutation();

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const date = useMemo(
    () =>
      dayjs(calculationData?.applicationDate).add(
        applicationConfiguration?.finishExecutionWithinDays ?? 14,
        'day',
      ),
    [calculationData?.applicationDate, applicationConfiguration?.finishExecutionWithinDays],
  );

  const buttonProps = useMemo(() => {
    return {
      disabled: isLoading,
    };
  }, [isLoading]);

  const okHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      form
        .validateFields()
        .then(values => {
          const payload: PatchCalculationPayload = {
            calculationId: calculationId,
            data: {
              calculationType: CalculationType.Production,
              finishExecutionUntil: values.finishExecutionUntil.format('YYYY-MM-DD'),
            },
          };
          editCalculation(payload).then(() => {
            handleClose();
          });
        })
        .catch(error => {
          console.log('Validation Error:', error);
        });
    },
    [calculationId, editCalculation, form, handleClose],
  );

  const initialValues = useMemo(() => {
    if (date) {
      return {
        finishExecutionUntil: date,
      };
    }
  }, [date]);

  return (
    <Modal
      title="Set as production"
      open={true}
      onCancel={handleClose}
      onOk={okHandler}
      okButtonProps={buttonProps}
      cancelButtonProps={buttonProps}
      width={640}
    >
      {isFetching ? (
        <LoadingIndicator />
      ) : (
        <Form
          form={form}
          initialValues={initialValues}
          data-type="production-calculation-form"
          name="production_calculation_modal"
          layout="horizontal"
        >
          <Column $gap="0.5rem">
            {isErpDownload ? (
              <Text>
                You are downloading file for an ERP system. Mark this calculation as Production?
              </Text>
            ) : null}
            <Form.Item name="finishExecutionUntil" label="Final execution date: ">
              <DatePicker />
            </Form.Item>
          </Column>
        </Form>
      )}
    </Modal>
  );
};

export default SetProductionModal;
