import {
  EntityIdentificationType,
  EntityListResponse,
  EntityListType,
  IdentificationType,
  ProductListResponse,
  TargetListResponse,
} from '@ydistri/api-sdk';
import {
  AdministrationItemAction,
  AdministrationItemListHandlingMethod,
  AdministrationItemOrigin,
  AdministrationItemParser,
  AdministrationItemRow,
  AdministrationItemValidationStatisticsData,
  StatisticsItem,
  StatisticsItemType,
} from './administrationItemsTypes';
import { TargetListInputData, TargetListValidatedItem } from '../TargetLists/targetListsTypes';
import { ProductListInputData, ProductListValidatedItem } from '../ProductLists/productListTypes';

export interface ArchivableAdministrationItem {
  isArchived: boolean;
}

export type SelectionType = IdentificationType | EntityIdentificationType | EntityListType;

export interface ReactSelectType<T> {
  label: string;
  value: T;
}

export enum ActiveScreenType {
  NO_SELECTION = 'no-selection',
  EDIT = 'edit',
  DETAIL = 'detail',
  VALIDATION = 'validation',
  DONE = 'done',
}

export const onlyActive = <T extends ArchivableAdministrationItem>(targetLists: T[]): T[] => {
  return targetLists.filter(item => !item.isArchived);
};

export const getArchivedItemsCount = <T extends ArchivableAdministrationItem>(
  items: T[],
): number => {
  return items.reduce((previousValue, item) => {
    if (item.isArchived) {
      return previousValue + 1; //do not use post increment here as it first returns a value and then increments it
    } else {
      return previousValue;
    }
  }, 0);
};

export const getArchivedStateSorter =
  <T extends TargetListResponse | ProductListResponse | EntityListResponse>(
    defaultSorter: (l: T, r: T) => number,
  ) =>
  (left: T, right: T): number => {
    if ((left.isArchived && right.isArchived) || (!left.isArchived && !right.isArchived)) {
      return defaultSorter(left, right);
    } else {
      return left.isArchived ? 1 : -1;
    }
  };

export const nameSorter = <T extends TargetListResponse | ProductListResponse | EntityListResponse>(
  left: T,
  right: T,
): number => {
  if (left.name && right.name) {
    return left.name.localeCompare(right.name);
  } else {
    if (left.name) {
      return -1;
    } else {
      return 1;
    }
  }
};

export const createdATSorter = <
  T extends TargetListResponse | ProductListResponse | EntityListResponse,
>(
  left: T,
  right: T,
): number => {
  if (left.createdAt && right.createdAt) {
    return left.createdAt.localeCompare(right.createdAt);
  } else {
    if (left.createdAt) {
      return 1;
    } else {
      return -1;
    }
  }
};

/**
 * Processes the rows and marks the duplicates according to the compare function.
 * Changes data in place.
 * @param rows data to be processed
 * @param compare function to compare two rows for equality
 */
export const markDuplicates = <
  T extends AdministrationItemRow<TargetListInputData | ProductListInputData>,
>(
  rows: T[],
  compare: (a: T, b: T) => boolean,
): void => {
  rows.forEach((row, index, allRows) => {
    const sameIndex = allRows.findIndex(tmpRow => compare(tmpRow, row));
    if (sameIndex !== index) {
      row.isError = true;
      row.isDuplicate = true;

      const duplicateMessage = `Is duplicate to row ${allRows[sameIndex].rowNumber} from original data`;
      if (!row.errors) {
        row.errors = [duplicateMessage];
      } else {
        row.errors.push(duplicateMessage);
      }
    }
  });
};

export const selectParser = <T>(
  data: DataTransfer,
  parsers: AdministrationItemParser<T>[],
): AdministrationItemParser<T> | null => {
  const dataTypes = data.types;

  const suitableParser = parsers.find(parser => dataTypes.includes(parser.contentType));
  if (suitableParser) {
    return suitableParser;
  } else {
    return null;
  }
};

const processValidationItem = (
  item: ProductListValidatedItem | TargetListValidatedItem,
  stats: AdministrationItemValidationStatisticsData,
  handlingMethod: AdministrationItemListHandlingMethod | undefined,
) => {
  if (item.isError) {
    stats.errors++;
    return;
  }

  if (handlingMethod) {
    const action = item.actions[handlingMethod];
    switch (action) {
      case AdministrationItemAction.NONE:
        if (item.origin === AdministrationItemOrigin.USER) {
          stats.unchanged++;
        }
        break;
      case AdministrationItemAction.INSERT:
        stats.updates++;
        break;
      case AdministrationItemAction.UPDATE:
        stats.updates++;
        break;
      case AdministrationItemAction.REMOVE:
        stats.removed++;
        break;
    }
  }
};

export const extractValidationStatistics = (
  data: (ProductListValidatedItem | TargetListValidatedItem)[],
  handlingMethod?: AdministrationItemListHandlingMethod,
): StatisticsItem[] => {
  const stats: AdministrationItemValidationStatisticsData = {
    total: 0,
    updates: 0,
    errors: 0,
    removed: 0,
    unchanged: 0,
  };

  data.forEach(item => processValidationItem(item, stats, handlingMethod));

  const result: StatisticsItem[] = [
    { label: 'Updates', observation: stats.updates, type: StatisticsItemType.SUCCESS },
    { label: 'Errors', observation: stats.errors, type: StatisticsItemType.ERROR },
  ];

  if (handlingMethod === AdministrationItemListHandlingMethod.REPLACE) {
    result.push({ label: 'Removed', observation: stats.removed, type: StatisticsItemType.REMOVED });
  }

  result.push({
    label: 'Unchanged',
    observation: stats.unchanged,
    type: StatisticsItemType.UNCHANGED,
  });

  return result;
};

export const extractValidationStatisticsAsMap = (
  data: ProductListValidatedItem[] | TargetListValidatedItem[],
  handlingMethod?: AdministrationItemListHandlingMethod,
): Map<StatisticsItemType, StatisticsItem> => {
  const stats = extractValidationStatistics(data, handlingMethod);

  return stats.reduce((prevValue, currentValue) => {
    prevValue.set(currentValue.type, currentValue);
    return prevValue;
  }, new Map<StatisticsItemType, StatisticsItem>());
};

/**
 * Check if the list contains any errors
 * @param items List of validated items
 */
export const containsErrors = (
  items?: ProductListValidatedItem[] | TargetListValidatedItem[],
): boolean | undefined => {
  return items?.some(item => item.isError);
};

export const getAdministrationItemActionSortIndex = (action: AdministrationItemAction): number => {
  switch (action) {
    case AdministrationItemAction.INSERT:
      return 0;
    case AdministrationItemAction.UPDATE:
      return 1;
    case AdministrationItemAction.REMOVE:
      return 2;
    case AdministrationItemAction.NONE:
      return 3;
  }
};

/**
 * Based on the handling method and showOnlyErrors flag, return a list of items that should be displayed to the user.
 * When showOnlyErrors is false, the list will contain all items that were entered by the user, plus all errors sorted by action -
 * inserts first, then updates, deletes and no changes will be last.
 * @param items List of validated items
 * @param handlingMethod Are we adding or replacing items in the target list
 * @param showOnlyErrors Should we show only errors or all items
 */
export const prepareValidatedItemsForPresentation = <
  T extends TargetListValidatedItem | ProductListValidatedItem,
>(
  items: T[],
  handlingMethod: AdministrationItemListHandlingMethod,
  showOnlyErrors: boolean,
): T[] => {
  return items
    .filter(validatedItem => {
      //we always show errors
      if (validatedItem.isError) return true;

      //when showOnlyErrors is false, we show all items that were entered by the user
      if (!showOnlyErrors) {
        const isUserEntered = validatedItem.origin === AdministrationItemOrigin.USER;
        if (handlingMethod === AdministrationItemListHandlingMethod.REPLACE) {
          const action = validatedItem.actions[handlingMethod];
          if (action === AdministrationItemAction.NONE) {
            return isUserEntered;
          } else {
            return true;
          }
        }
        return isUserEntered;
      }
    })
    .sort((left, right) => {
      //sort  errors first, then by action - inserts, updates, deletes, no changes last
      if (left.isError && !right.isError) {
        return -1;
      }
      if (!left.isError && right.isError) {
        return 1;
      }

      const leftAction = left.actions[handlingMethod];
      const leftActionSortIndex = getAdministrationItemActionSortIndex(leftAction);

      const rightAction = right.actions[handlingMethod];
      const rightActionSortIndex = getAdministrationItemActionSortIndex(rightAction);

      return leftActionSortIndex - rightActionSortIndex;
    });
};
