import { IconButton, IconButton_IconSizes } from '@ydistri/ds';
import { MdFilterAlt } from 'react-icons/md';
import React from 'react';
import { Popover } from 'antd';
import CalculationRedistributionFilters from './CalculationRedistributionFilters';
import { useIsRedistributionFilterSet } from './useRedistributionFilters';
import { useTheme } from 'styled-components';

const icon = <MdFilterAlt size={IconButton_IconSizes.small} />;

const filtersContent = <CalculationRedistributionFilters />;

/**
 * Icon button to show the filters for the calculation's redistribution in a popover.
 * <p>When any filter is set, the icon is changes color to reflect that and give the
 * user a feedback that some filters are in effect.</p>
 */
const CalculationRedistributionFiltersAction: React.FC = () => {
  const isFilterSet = useIsRedistributionFilterSet();
  const theme = useTheme();

  return (
    <Popover
      trigger="click"
      content={filtersContent}
      placement="bottom"
      title="Filter redistribution by:"
    >
      <IconButton
        size="small"
        tooltip="Show additional filters"
        icon={icon}
        colorScheme={isFilterSet ? theme.iconColorSchemes.default : undefined}
      />
    </Popover>
  );
};

export default CalculationRedistributionFiltersAction;
