import React from 'react';
import { Title, Row } from '@ydistri/ds';
import { InsightsSourceData } from '../categoryInsightsLib';
import { computePercentage, shortenNumber } from '@ydistri/utils';
import TitleWithNumericPopover from '../common/TitleWithNumericPopover';

interface EligibleSourceProps {
  data: InsightsSourceData;
}

const EligibleSource: React.FC<EligibleSourceProps> = ({ data }) => {
  const sourceEligibleSkuCount = data.sourceEligibleSkuCount;
  const totalSkuCount = data.totalSkuCount;
  const skuCountPercentage = computePercentage(sourceEligibleSkuCount, totalSkuCount);

  const sourceEligibleSupply = data.sourceEligibleSupply;
  const sourceTotalSupply = data.sourceTotalSupply;
  const supplyPercentage = computePercentage(sourceEligibleSupply, sourceTotalSupply);

  return (
    <Row $justifyContent="space-between" $gap="1rem">
      <Title>Eligible to use as a source inventory</Title>
      <Row $justifyContent="space-between" $gap="1rem">
        <TitleWithNumericPopover
          value={sourceEligibleSkuCount}
          text={`store-product combinations (${skuCountPercentage}% of total)`}
        />
        <TitleWithNumericPopover
          value={sourceEligibleSupply}
          appendCurrency={true}
          text={`(${supplyPercentage}% of total supply ${shortenNumber(sourceTotalSupply)})`}
        />
      </Row>
    </Row>
  );
};

export default EligibleSource;
