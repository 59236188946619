import React from 'react';
import { Title, Row } from '@ydistri/ds';
import { InsightsTargetData } from '../categoryInsightsLib';
import { computePercentage } from '@ydistri/utils';
import TitleWithNumericPopover from '../common/TitleWithNumericPopover';

interface EligibleTargetProps {
  data: InsightsTargetData;
}

const EligibleTarget: React.FC<EligibleTargetProps> = ({ data }) => {
  const targetEligibleSkuCount = data.targetEligibleSkuCount;
  const totalSkuCount = data.totalSkuCount;
  const skuCountPercentage = computePercentage(targetEligibleSkuCount, totalSkuCount);

  return (
    <Row $justifyContent="space-between" $gap="1rem">
      <Title>Eligible to use as a target inventory</Title>
      <Row $justifyContent="space-between" $gap="1rem">
        <TitleWithNumericPopover
          value={targetEligibleSkuCount}
          text={`store-product combinations (${skuCountPercentage}% of total)`}
        />
      </Row>
    </Row>
  );
};

export default EligibleTarget;
