/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import * as Sentry from '@sentry/react';
import { HttpClient, Me } from '@ydistri/identity-sdk';
import { getOAuthUrl } from '../helpers';
import { runInterceptor } from '@ydistri/utils';

export const IdentityAPI = new HttpClient({
  baseURL: getOAuthUrl(),
});

IdentityAPI.instance.interceptors.request.use(
  req => runInterceptor(req),
  error => {
    Sentry.captureException(error);
    const errorMessage = error instanceof Error ? error.message : String(error);
    return Promise.reject(new Error(errorMessage));
  },
);

IdentityAPI.instance.interceptors.response.use(
  req => req,
  error => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error?.response && error.response.status === 401) {
      //TODO - logout
      console.log('LOGOUT - INTERCEPTOR 1');
      // window.location.reload();
    }
    const errorMessage = error instanceof Error ? error.message : String(error);
    return Promise.reject(new Error(errorMessage));
  },
);

export const MeCollection = new Me(IdentityAPI);
