import { CalculationResponse, CalculationType } from '@ydistri/api-sdk';
import { Column, computeRemSize, Row, Text, Title } from '@ydistri/ds';
import { Divider, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { styled } from 'styled-components';
import { generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '../../../components/menu/menuLeftItemTemplate';
import { CalculationDetailSubpages } from '../calculationsLib';
import LinkIcon from '../../../components/global/Link/LinkIcon';
import YLink from '../../../components/global/Link/YLink';

interface CalculationsListProps {
  calculations: CalculationResponse[];
  categorized?: boolean;
  showType?: boolean;
}

const splitter = <Divider type="vertical" />;

const CategorizedList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 0.5rem;
  }

  li ul {
    margin-top: 1rem;
  }
`;

const CalculationListItem = styled.li`
  list-style-type: none;
  padding-bottom: 4px;
`;

const CategoryTitle = styled(Title)`
  border-left: ${computeRemSize(3)} solid ${({ theme }) => theme.colors.primary};
  padding: 0.4rem 1rem;
`;

const Category: React.FC<{
  title: string;
  calculations: CalculationResponse[];
  itemRenderer: (calculation: CalculationResponse) => React.JSX.Element;
}> = ({ title, calculations, itemRenderer }) => {
  return (
    <CalculationListItem>
      <CategoryTitle $size="large">{title}</CategoryTitle>
      <ul>
        {calculations.map(calculation => (
          <CalculationListItem key={calculation.id}>
            {itemRenderer(calculation)}
          </CalculationListItem>
        ))}
      </ul>
    </CalculationListItem>
  );
};

/**
 * Simple list of calculations.
 * <p>Displays two rows per calculation: title on first and type and description on second.</p>
 * <p>Each calculation's title is a link to its detail page.</p>
 * @param calculations Array of CalculationResponse objects
 * @param categorized true to categorize the calculations by type Templates and Calculations
 * @param showType Show the calculation type in the list
 * @constructor
 */
const CalculationsList: React.FC<CalculationsListProps> = ({
  calculations,
  categorized,
  showType = false,
}) => {
  const { projectShortName } = useParams();

  const descriptionRow = useCallback((calculation: CalculationResponse, showType: boolean) => {
    if (showType) {
      return (
        <Space split={splitter} align="start">
          <Text $type="light">
            {calculation.type === CalculationType.Template ? 'Template' : 'Calculation'}
          </Text>
          {calculation.description && <Text $type="light">{calculation.description}</Text>}
        </Space>
      );
    } else {
      return calculation.description ? <Text $type="light">{calculation.description}</Text> : null;
    }
  }, []);

  const itemRenderer = useCallback(
    (calculation: CalculationResponse) => {
      let path = '';
      if (calculation.type === CalculationType.Template) {
        path = generatePath(ROUTES.configuration, {
          projectShortName: projectShortName ?? '',
          templateId: `${calculation.id}`,
          slug: '1',
        });
      } else {
        path = generatePath(ROUTES.calculationDetail, {
          projectShortName: projectShortName ?? '',
          calculationId: `${calculation.id}`,
          subpage: CalculationDetailSubpages.REDISTRIBUTION,
          slug: '1',
        });
      }

      return (
        <YLink to={path}>
          <Row $gap="1rem" $flexWrap="nowrap">
            <Title $size="small">{`#${calculation.id}`}</Title>
            <Column $gap="2px">
              <Row $gap="0.5rem" $alignItems="center">
                <Title $size="small">{calculation.title}</Title>
                <LinkIcon url={path} />
              </Row>
              <Row>{descriptionRow(calculation, showType)}</Row>
            </Column>
          </Row>
        </YLink>
      );
    },
    [descriptionRow, projectShortName, showType],
  );

  const categorizedCalculations = useMemo(() => {
    if (categorized) {
      const templates = calculations.filter(
        calculation => calculation.type === CalculationType.Template,
      );
      const tmpCalculations = calculations.filter(
        calculation => calculation.type !== CalculationType.Template,
      );
      return { templates, calculations: tmpCalculations };
    }
    return { templates: calculations, calculations: [] };
  }, [calculations, categorized]);

  if (categorized) {
    return (
      <Column $gap="1.2rem">
        <CategorizedList>
          {categorizedCalculations.templates.length > 0 && (
            <Category
              title="Templates"
              calculations={categorizedCalculations.templates}
              itemRenderer={itemRenderer}
            />
          )}
          {categorizedCalculations.calculations.length > 0 && (
            <Category
              title="Calculations"
              calculations={categorizedCalculations.calculations}
              itemRenderer={itemRenderer}
            />
          )}
        </CategorizedList>
      </Column>
    );
  } else {
    return (
      <Column $gap="1.2rem">{calculations.map(calculation => itemRenderer(calculation))}</Column>
    );
  }
};

export default CalculationsList;
