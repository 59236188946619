import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdentificationType, TargetListResponse } from '@ydistri/api-sdk';
import { TargetListItemsCompared, TargetListRow } from './targetListsTypes';
import { ActiveScreenType } from '../common/administrationItemsLib';
import { AdministrationItemListHandlingMethod } from '../common/administrationItemsTypes';

export interface TargetListAdministrationState {
  selectedTargetList?: TargetListResponse;
  handlingMethod?: AdministrationItemListHandlingMethod;
  productItemsContent: string;
  productItemsErrors: string;
  productIdentificationType: IdentificationType;
  warehouseIdentificationType: IdentificationType;
  showArchivedItems: boolean;
  sortField?: string;
  activeScreenType: ActiveScreenType;
  parsedTargetListItems: TargetListRow[];
  isLoading: boolean;
  isOperationFinishedWithSuccess?: boolean;
  validation: TargetListItemsCompared | undefined;
  refreshTargetListContentFlip: boolean;
}

export interface ValidationFinishedPayload {
  validation: TargetListItemsCompared | undefined;
  errors?: string;
}

const initialState: TargetListAdministrationState = {
  selectedTargetList: undefined,
  handlingMethod: undefined,
  productItemsContent: '',
  productItemsErrors: '',
  productIdentificationType: IdentificationType.CustomerId,
  warehouseIdentificationType: IdentificationType.CustomerId,
  showArchivedItems: false,
  sortField: 'CreatedAt',
  activeScreenType: ActiveScreenType.NO_SELECTION,
  parsedTargetListItems: [],
  isLoading: false,
  isOperationFinishedWithSuccess: undefined,
  validation: undefined,
  refreshTargetListContentFlip: false,
};

const appendValidationHelper = (
  data: TargetListItemsCompared | undefined,
  currentValidation: TargetListItemsCompared | undefined,
) => {
  if (currentValidation) {
    return {
      ...currentValidation,
      items: currentValidation.items
        .filter(validatedItem => !validatedItem.isError)
        .filter(validatedItem => {
          const foundIndex = data?.items.findIndex(item => {
            return (
              validatedItem.inputData.productId === item.inputData.productId &&
              validatedItem.inputData.storeId === item.inputData.storeId
            );
          });
          return foundIndex === -1;
        })
        .concat(data?.items ?? []),
    };
  } else {
    return data;
  }
};

export const targetListAdministrationSlice = createSlice({
  name: 'targetListAdministration',
  initialState,
  reducers: {
    setSelectedTargetList: (state, action: PayloadAction<TargetListResponse | undefined>) => {
      const previousSelectedTargetListId = state.selectedTargetList?.targetListId;
      state.selectedTargetList = action.payload;

      //reset previous state if any
      state.validation = undefined;
      state.parsedTargetListItems = [];
      state.productItemsContent = '';
      state.productItemsErrors = '';

      //if target list is selected, we are in detail screen
      //if no selection is made, we are in no selection screen
      if (action.payload) {
        //we may be updating a currently selected target list
        //if so, we do not want to change the screen type
        if (action.payload.targetListId !== previousSelectedTargetListId) {
          state.activeScreenType = ActiveScreenType.DETAIL;
        }
      } else {
        state.activeScreenType = ActiveScreenType.NO_SELECTION;
      }
    },

    setHandlingMethod: (
      state,
      action: PayloadAction<AdministrationItemListHandlingMethod | undefined>,
    ) => {
      state.handlingMethod = action.payload;
    },
    setProductItemsError: (state, action: PayloadAction<string>) => {
      state.productItemsErrors = action.payload;
    },
    setProductItemsContent: (state, action: PayloadAction<string>) => {
      state.productItemsContent = action.payload;
    },
    addProductItemsContent: (state, action: PayloadAction<string>) => {
      state.productItemsContent += action.payload;
    },
    setIdentificationType: (state, action: PayloadAction<IdentificationType>) => {
      state.productIdentificationType = action.payload;
    },
    setWarehouseIdentificationType: (state, action: PayloadAction<IdentificationType>) => {
      state.warehouseIdentificationType = action.payload;
    },
    setShowArchivedItems: (state, action: PayloadAction<boolean>) => {
      state.showArchivedItems = action.payload;
    },
    setSortField: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
    },
    setActiveScreenType: (state, action: PayloadAction<ActiveScreenType>) => {
      state.activeScreenType = action.payload;
    },
    setTargetListItems: (state, action: PayloadAction<TargetListRow[]>) => {
      state.parsedTargetListItems = action.payload;
    },

    resetTargetListItems: state => {
      state.parsedTargetListItems = [];
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsOperationFinishedWithSuccess: (state, action: PayloadAction<boolean | undefined>) => {
      state.isOperationFinishedWithSuccess = action.payload;
    },
    setValidation: (state, action: PayloadAction<TargetListItemsCompared | undefined>) => {
      state.validation = action.payload;
    },
    appendValidation: (state, action: PayloadAction<TargetListItemsCompared | undefined>) => {
      state.validation = appendValidationHelper(action.payload, state.validation);
    },
    clearValidation: state => {
      state.validation = undefined;
      state.parsedTargetListItems = [];
      state.productItemsContent = '';
      state.productItemsErrors = '';
    },
    refreshTargetListContent: state => {
      state.refreshTargetListContentFlip = !state.refreshTargetListContentFlip;
    },
    validationFinished: (state, action: PayloadAction<ValidationFinishedPayload>) => {
      state.validation = appendValidationHelper(action.payload.validation, state.validation);
      state.activeScreenType = ActiveScreenType.VALIDATION;
      state.productItemsErrors = action.payload.errors ?? '';
      state.productItemsContent = '';
    },
    resetState: () => initialState,
  },
});

export const {
  setSelectedTargetList,
  setHandlingMethod,
  setProductItemsContent,
  setIdentificationType,
  setWarehouseIdentificationType,
  setProductItemsError,
  setShowArchivedItems,
  setSortField,
  setActiveScreenType,
  setTargetListItems,
  resetTargetListItems,
  setIsOperationFinishedWithSuccess,
  setIsLoading,
  setValidation,
  clearValidation,
  refreshTargetListContent,
  resetState,
  addProductItemsContent,
  appendValidation,
  validationFinished,
} = targetListAdministrationSlice.actions;

export const targetListAdministrationReducer = targetListAdministrationSlice.reducer;
