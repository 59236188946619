import React, { useMemo } from 'react';
import {
  CalculationStatisticTargetListResponseWithKey,
  useGetSourceItemsForTargetSkuQuery,
} from './apiCalculationTargetListStatistics';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { ColumnsType } from 'antd/es/table';
import { CalculationStatisticTargetListItemSourceResponse } from '@ydistri/api-sdk';
import { SimpleTable } from './components/SimpleTable';
import { computeRemSize, injectLocalFilters, tableScroll, YColumnsType } from '@ydistri/ds';
import { formatNumber } from '@ydistri/utils';
import { Popover } from 'antd';
import { useGetCalculationQuery } from '../apiCalculationDetail';
import {
  EntityColumns,
  EntityColumnsConfig,
  useStoreTableColumnsProvider,
} from '../../../hooks/useEntityTableColumnsProvider';

const returnRenderResult = (value: number | string, className: string) => ({
  props: {
    className,
  },
  children: typeof value === 'number' ? formatNumber(value) : value,
});

const renderFuncSent = (value: number) => returnRenderResult(value, value > 0 ? 'green' : '');

const renderFuncAvailableSupply = (value: number) =>
  returnRenderResult(value, value === 0 ? 'red' : '');

const renderFuncGrey = (value: number) => returnRenderResult(value, 'grey');

const renderFuncBoolean = (value: boolean) =>
  returnRenderResult(value ? 'Yes' : 'No', value ? '' : 'red');

const renderFuncBlocked = (
  value: number,
  record: CalculationStatisticTargetListItemSourceResponse,
) => {
  let className = '';
  if (value === record.availableSupply && value > 0) {
    className = 'red';
  } else if (value > 0) {
    className = 'orange';
  }
  return returnRenderResult(value, className);
};

const overlappingColumns: ColumnsType<CalculationStatisticTargetListItemSourceResponse> = [
  {
    title: (
      <Popover title="Incoming quantity" content="Incoming quantity from overlapping calculations">
        (IQ)
      </Popover>
    ),
    key: 'incomingQuantity',
    dataIndex: ['incomingQuantity'],
    width: computeRemSize(75),
    align: 'right',
    sorter: (a, b) => a.incomingQuantity - b.incomingQuantity,
    render: renderFuncBlocked,
  },
  {
    title: (
      <Popover title="Outgoing quantity" content="Outgoing quantity from overlapping calculations">
        (OQ)
      </Popover>
    ),
    key: 'outgoingQuantity',
    dataIndex: ['outgoingQuantity'],
    width: computeRemSize(75),
    align: 'right',
    sorter: (a, b) => a.outgoingQuantity - b.outgoingQuantity,
    render: renderFuncBlocked,
  },
];

const getTargetListItemSourcesColumns = (
  hasOverlappingCalculations: boolean,
  storeColumns: YColumnsType<CalculationStatisticTargetListItemSourceResponse>[],
): ColumnsType<CalculationStatisticTargetListItemSourceResponse> =>
  injectLocalFilters([
    ...storeColumns,
    {
      title: 'Sent Quantity',
      key: 'sentQuantity',
      dataIndex: ['sentQuantity'],
      width: computeRemSize(100),
      align: 'right',
      sorter: (a, b) => a.sentQuantity - b.sentQuantity,
      defaultSortOrder: 'descend',
      render: renderFuncSent,
    },
    {
      title: 'Available supply',
      key: 'availableSupply',
      dataIndex: ['availableSupply'],
      width: computeRemSize(100),
      align: 'right',
      sorter: (a, b) => a.availableSupply - b.availableSupply,
      render: renderFuncAvailableSupply,
    },
    {
      title: 'Available to send',
      key: 'availableToSend',
      dataIndex: ['availableToSend'],
      width: computeRemSize(100),
      align: 'right',
      sorter: (a, b) => a.availableToSend - b.availableToSend,
      render: renderFuncSent,
    },
    ...(hasOverlappingCalculations ? overlappingColumns : []),
    {
      title: (
        <Popover
          title="Final blocked amount"
          content="Maximum amount of MU that is blocked by any setting"
        >
          Blocked
        </Popover>
      ),
      key: 'blockedByAllSettings',
      dataIndex: ['blockedByAllSettings'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByAllSettings - b.blockedByAllSettings,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover
          title="Standard redistribution"
          content="Blocked by 'Standard redistribution' setting "
        >
          (Cat)
        </Popover>
      ),
      key: 'blockedByCategory',
      dataIndex: ['blockedByCategory'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByCategory - b.blockedByCategory,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover
          title="New products protection"
          content="Blocked by 'Months since 1st shipment to store' setting "
        >
          (FPD)
        </Popover>
      ),
      key: 'blockedByFirstPurchaseDate',
      dataIndex: ['blockedByFirstPurchaseDate'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByFirstPurchaseDate - b.blockedByFirstPurchaseDate,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover
          title="Recent purchase protection"
          content="Blocked by 'Months since last shipment to store' setting "
        >
          (LPD)
        </Popover>
      ),
      key: 'blockedByLastPurchaseDate',
      dataIndex: ['blockedByLastPurchaseDate'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByLastPurchaseDate - b.blockedByLastPurchaseDate,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover title="Product display protection" content="Blocked by Minimum Layers ">
          (ML)
        </Popover>
      ),
      key: 'blockedByMinLayers',
      dataIndex: ['blockedByMinLayers'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByMinLayers - b.blockedByMinLayers,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover
          title="Small value protection"
          content="Blocked by 'Min product value [unit cost x qty] to allow a transfer' setting "
        >
          (MinV)
        </Popover>
      ),
      key: 'blockedByMinValue',
      dataIndex: ['blockedByMinValue'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByMinValue - b.blockedByMinValue,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover
          title="Future promotion stock protection"
          content="Blocked by 'Products with future promo' setting "
        >
          (Promo)
        </Popover>
      ),
      key: 'blockedByPromo',
      dataIndex: ['blockedByPromo'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByPromo - b.blockedByPromo,
      render: renderFuncBlocked,
    },
    {
      title: (
        <Popover
          title="Forecasted stock protection "
          content="Blocked by 'Months of supply after redistribution' + 'Required min. confidence of forecast' settings"
        >
          (Fct)
        </Popover>
      ),
      key: 'blockedByForecast',
      dataIndex: ['blockedByForecast'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => a.blockedByForecast - b.blockedByForecast,
      render: renderFuncBlocked,
    },
    {
      title: <Popover title="Use as source ">UaS</Popover>,
      key: 'useAsSource',
      dataIndex: ['useAsSource'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => (a.useAsSource ? 1 : 0) - (b.useAsSource ? 1 : 0),
      render: renderFuncBoolean,
    },
    {
      title: <Popover title="Is forced ">Forced</Popover>,
      key: 'isForcedSkuAttribute',
      dataIndex: ['isForcedSkuAttribute'],
      width: computeRemSize(75),
      align: 'right',
      sorter: (a, b) => (a.useAsSource ? 1 : 0) - (b.useAsSource ? 1 : 0),
      render: renderFuncBoolean,
    },
  ]);

interface TableTargetListItemSourcesProps {
  selectedTargetListSku?: CalculationStatisticTargetListResponseWithKey;
}

const TableTargetListItemSources: React.FC<TableTargetListItemSourcesProps> = ({
  selectedTargetListSku,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);

  const { data: sourceSkuData, isFetching } = useGetSourceItemsForTargetSkuQuery(
    selectedTargetListSku
      ? {
          calculationId: templateOrCalculation.id,
          targetSkuId: selectedTargetListSku.skuId,
        }
      : skipToken,
  );

  const storeColumnsProvider = useStoreTableColumnsProvider();

  const hasOverlappingCalculations = (calculationData?.overlappingCalculations?.length ?? 0) > 0;

  const cols: ColumnsType<CalculationStatisticTargetListItemSourceResponse> = useMemo(() => {
    const columnsConfig: EntityColumnsConfig<CalculationStatisticTargetListItemSourceResponse> = {
      [EntityColumns.ID]: {
        width: computeRemSize(100),
      },
    };
    const storeColumns =
      storeColumnsProvider<CalculationStatisticTargetListItemSourceResponse>(columnsConfig);

    if (!sourceSkuData) {
      return getTargetListItemSourcesColumns(hasOverlappingCalculations, storeColumns);
    } else {
      const removableColumns: (keyof CalculationStatisticTargetListItemSourceResponse)[] = [
        'blockedByForecast',
        'blockedByPromo',
        'blockedByMinValue',
        'blockedByMinLayers',
        'blockedByLastPurchaseDate',
        'blockedByFirstPurchaseDate',
        'blockedByCategory',
      ];

      sourceSkuData.forEach(sourceSku => {
        removableColumns.forEach(column => {
          const value = sourceSku[column];
          if (typeof value === 'number' && value > 0) {
            removableColumns.splice(removableColumns.indexOf(column), 1);
          }
        });
      });

      return getTargetListItemSourcesColumns(hasOverlappingCalculations, storeColumns).map(
        column => {
          if (
            removableColumns.includes(
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              column.key as keyof CalculationStatisticTargetListItemSourceResponse,
            )
          ) {
            return {
              ...column,
              render: renderFuncGrey,
            };
          }
          return column;
        },
      );
    }
  }, [hasOverlappingCalculations, sourceSkuData, storeColumnsProvider]);

  return (
    <SimpleTable<CalculationStatisticTargetListItemSourceResponse>
      id="target-list-item-sources"
      height="310px"
      scroll={tableScroll}
      dataSource={sourceSkuData}
      columns={cols}
      loading={isFetching}
      showHeader={true}
      showSorterTooltip={false}
    />
  );
};

export default TableTargetListItemSources;
