import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NotLoggedInPage, GreenInfo, InputFieldType, OrangeInfo } from '@ydistri/ds';
import { MeCollection } from '../../swagger/collections-identity';
import Auth from '../../auth/Auth';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';

const signInInputs: InputFieldType[] = [
  {
    title: 'Email',
    type: 'text',
    name: 'login',
  },
  {
    title: 'Password',
    type: 'password',
    name: 'password',
  },
];

const auth = new Auth();

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const activation = parseInt(new URLSearchParams(search).get('activation') ?? '0');
  const passReset = parseInt(new URLSearchParams(search).get('passReset') ?? '0');
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const infoTop = useMemo(
    () =>
      Date.now() - (activation + passReset) <= 5000 ? (
        <GreenInfo>Action successful! Please sign in.</GreenInfo>
      ) : null,
    [activation, passReset],
  );

  const infoBottom = useMemo(() => {
    return (
      <>
        <Link to="/account/forgot-pass/">Forgot password?</Link>
        {error ? <OrangeInfo>{error}</OrangeInfo> : null}
      </>
    );
  }, [error]);

  const onSubmit = useCallback(
    (values: Record<string, string>) => {
      setError(undefined);
      setSubmitting(true);
      auth
        .signin(values.login, values.password)
        .then(() => {
          return MeCollection.getMe().then(({ data }) => {
            dispatch(setUser(data.data));
            setSubmitting(false);
            navigate('/');
          });
        })
        .catch(e => {
          setSubmitting(false);
          setError(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/consistent-type-assertions
            (e.response?.data?.error_description === 'invalid_username_or_password'
              ? 'Wrong email or password'
              : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
                (e.response.data.error_description ?? e.message)) as string,
          );
        });
    },
    [navigate, dispatch],
  );

  return (
    <NotLoggedInPage
      title="Sign in to Y′app"
      inputs={signInInputs}
      button="Sign in"
      onSubmit={onSubmit}
      submitting={submitting}
      infoTop={infoTop}
      infoBottom={infoBottom}
    >
      {null}
    </NotLoggedInPage>
  );
};

export default SignIn;
