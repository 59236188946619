import React, { useMemo } from 'react';
import { CategoryConfigurationDefinitionResponse, ConfigurationFieldType } from '@ydistri/api-sdk';
import { styled } from 'styled-components';
import { isEntityListConfiguration } from './segmentPotentialDefinitionLib';
import TargetListConfigurationInfo from './TargetListConfigurationInfo';
import { InfoIconTooltip } from '../../../../components/icons/InfoIconTooltip';
import { COLORS, computeRemSize } from '@ydistri/ds';
import { isSectionTypeTarget } from '../configurationUtils';
import EntityListConfigurationInfo from './EntityListConfigurationInfo';
import { EntityListInfo } from '../../../ProjectAdministration/EntityLists/entityListsTypes';
import EntityListDeleteButton from './ConfigurationEntityLists/EntityListDeleteButton';

interface ConfigurationTitleWrapperProps {
  $isTarget: boolean;
}

export const ConfigurationTitleWrapper = styled.div<ConfigurationTitleWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: ${p => computeRemSize(p.$isTarget ? 200 : 300)};
  min-width: ${p => computeRemSize(p.$isTarget ? 200 : 300)};
`;

export const ConfigurationTitle = styled.div<{ $isArchived?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${p => (p.$isArchived ? COLORS.GREY : 'inherit')};
`;

interface ConfigurationRowTitleProps {
  definition: CategoryConfigurationDefinitionResponse;
  forcedTitle?: string;
  entityListInfo?: EntityListInfo;
  fullyFeatured?: boolean;
}

const ConfigurationRowTitle: React.FC<ConfigurationRowTitleProps> = ({
  definition,
  forcedTitle,
  entityListInfo,
  fullyFeatured = true,
}) => {
  const { dataType, fieldType, title, description, sectionType } = definition;

  const displayTitle = forcedTitle ?? title;

  const additionalInfo: React.ReactNode = useMemo(() => {
    if (fieldType === ConfigurationFieldType.PtTargetList) return <TargetListConfigurationInfo />;
    if (
      entityListInfo?.entityList &&
      isEntityListConfiguration(fieldType, dataType) &&
      fullyFeatured
    )
      return <EntityListConfigurationInfo entityList={entityListInfo.entityList} />;
    return null;
  }, [dataType, entityListInfo?.entityList, fieldType, fullyFeatured]);

  const isTarget = isSectionTypeTarget(sectionType);

  return (
    <ConfigurationTitleWrapper $isTarget={isTarget}>
      <ConfigurationTitle $isArchived={entityListInfo?.entityList.isArchived}>
        {entityListInfo && fullyFeatured && (
          <EntityListDeleteButton
            entityListInfo={entityListInfo}
            configurationDefinition={definition}
          />
        )}
        {entityListInfo?.entityList.isArchived ? '(archived) ' : null}
        {displayTitle}
        <InfoIconTooltip htmlContent={forcedTitle ? undefined : (description ?? '')} />
      </ConfigurationTitle>
      {additionalInfo}
    </ConfigurationTitleWrapper>
  );
};

export default ConfigurationRowTitle;
