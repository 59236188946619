import { getSectionTitle } from '../../targetListsLib';
import React, { useCallback, useEffect } from 'react';
import {
  clearValidation,
  setActiveScreenType,
  setProductItemsContent,
  setProductItemsError,
} from '../../targetListAdministrationSlice';
import { batch, useDispatch, useSelector } from 'react-redux';
import TargetListValidationResults from './TargetListValidationResults';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import ValidationSection from '../../../common/ValidationSection';
import { ReduxState } from '../../../../../store';

const TargetListValidationSection: React.FC = () => {
  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );
  const productItemsErrors = useSelector(
    (state: ReduxState) => state.targetListAdministration.productItemsErrors,
  );

  const dispatch = useDispatch();

  /**
   * Content of the text area is set to error data if any
   */
  useEffect(() => {
    batch(() => {
      if (productItemsErrors.length > 0) {
        dispatch(setProductItemsContent(productItemsErrors));
        dispatch(setProductItemsError(''));
      }
    });
  }, [dispatch, productItemsErrors]);

  const onExitEditMode = useCallback(() => {
    dispatch(clearValidation());
    dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
  }, [dispatch]);

  if (selectedTargetList) {
    return (
      <ValidationSection title={getSectionTitle(selectedTargetList)} onClose={onExitEditMode}>
        <TargetListValidationResults />
      </ValidationSection>
    );
  } else {
    return null;
  }
};

export default TargetListValidationSection;
