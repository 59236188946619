import React, { useEffect } from 'react';
import { EntityListResponse } from '@ydistri/api-sdk';
import { Column, ReadOnlyItem } from '@ydistri/ds';
import ListCalculationsWrapper from '../../common/usage/ListCalculationsWrapper';
import { useGetEntityListCalculationsQuery } from '../apiEntityLists';

interface EntityListDetailsProps {
  entityList: EntityListResponse;
}

/** Common width for the labels to have the data aligned */
const LABEL_WIDTH: string = '6rem';

/**
 * Details of a list like title, id etc.
 * @param targetList
 * @constructor
 */
const EntityListDetails: React.FC<EntityListDetailsProps> = ({ entityList }) => {
  const {
    data: entityListCalculations = [],
    isFetching,
    refetch: refetchEntityListCalculations,
  } = useGetEntityListCalculationsQuery(entityList.entityListId);

  useEffect(() => {
    refetchEntityListCalculations();
  }, [refetchEntityListCalculations, entityList]);

  return (
    <Column $gap="1rem" data-type="EntityListDetails">
      {entityList.customerEntityListId && entityList.customerEntityListId !== '' ? (
        <ReadOnlyItem
          label="System Id"
          value={entityList.customerEntityListId}
          labelWidth={LABEL_WIDTH}
        />
      ) : null}
      <ListCalculationsWrapper
        calculations={entityListCalculations}
        fetching={isFetching}
        labelWidth={LABEL_WIDTH}
      />
    </Column>
  );
};

export default EntityListDetails;
