import React, { useCallback, useMemo } from 'react';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
  PatchCalculationPayload,
  usePatchCalculationMutation,
} from '../Calculations/apiCalculations';
import { useGetCalculationQuery } from './apiCalculationDetail';
import LoadingDataOverlay from '../../components/global/LoadingDataOverlay/LoadingDataOverlay';
import { styled } from 'styled-components';
import { CalculationDetailResponse } from '@ydistri/api-sdk';
import { useSelectedProjectCode } from '../../hooks/useSelectedProjectCode';

interface EditCalculationModalProps {
  open: boolean;
  calculationId: number;
  onCancel: () => void;
  onCalculationChanged?: (calculation: CalculationDetailResponse) => void;
}

interface FormFields {
  title: string;
  description: string;
}

const titleInputRules = [{ required: true, message: 'Please enter a title for your template' }];
const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

//to prevent the modal to change size too much when the data loads
const BusyContent = styled.div`
  min-height: 145px;
`;

const EditCalculationModal: React.FC<EditCalculationModalProps> = ({
  open,
  calculationId,
  onCancel,
  onCalculationChanged,
}) => {
  const { data: calculation, isLoading: isCalculationLoading } =
    useGetCalculationQuery(calculationId);
  const [form] = Form.useForm<FormFields>();
  const [editCalculation, { isLoading }] = usePatchCalculationMutation();
  const projectCode = useSelectedProjectCode();

  const initialValues: FormFields = useMemo(
    () => ({
      title: calculation?.title ?? '',
      description: calculation?.description ?? '',
    }),
    [calculation?.description, calculation?.title],
  );

  const handleSubmit = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      form.validateFields().then(values => {
        const payload: PatchCalculationPayload = {
          projectCode,
          calculationId,
          data: values,
        };

        editCalculation(payload)
          .unwrap()
          .then(result => {
            form.resetFields();
            if (onCalculationChanged) {
              onCalculationChanged(result);
            } else {
              onCancel();
            }
          });
      });
    },
    [form, projectCode, calculationId, editCalculation, onCalculationChanged, onCancel],
  );

  const modalTitle = useMemo(
    () => `Edit calculation #${calculation?.id ?? ''} ${calculation?.title ?? ''}`,
    [calculation?.id, calculation?.title],
  );

  const onCancelHandler = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onCancel();
    },
    [onCancel],
  );

  const voidClickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  }, []);

  //the form will not reinitialize when initialValues change, so we need to render
  //only when we have the data
  return (
    <div onClick={voidClickHandler}>
      <Modal
        data-type="modal"
        title={modalTitle}
        open={open}
        width={1000}
        okText="Save"
        onOk={handleSubmit}
        onCancel={onCancelHandler}
        confirmLoading={isLoading}
      >
        {isLoading || isCalculationLoading ? (
          <LoadingDataOverlay active>
            <BusyContent />
          </LoadingDataOverlay>
        ) : (
          <Form
            data-type="edit-calculation-form"
            form={form}
            labelCol={formLayout.labelCol}
            wrapperCol={formLayout.wrapperCol}
            name="edit_calculation_modal"
            layout="horizontal"
            initialValues={initialValues}
          >
            <Form.Item name="title" label="Title" rules={titleInputRules}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default EditCalculationModal;
