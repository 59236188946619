import React from 'react';
import { LabeledToggle, LabelPlacement } from '@ydistri/ds';

interface ShowOnlyErrorsToggleProps {
  showOnlyErrors: boolean;
  onClick: () => void;
}

const ShowOnlyErrorsToggle: React.FC<ShowOnlyErrorsToggleProps> = ({ showOnlyErrors, onClick }) => {
  return (
    <LabeledToggle
      label="Show only errors"
      checked={showOnlyErrors}
      onClick={onClick}
      labelPlacement={LabelPlacement.LEFT}
      data-testid="ShowOnlyErrorsToggle"
    />
  );
};

export default ShowOnlyErrorsToggle;
