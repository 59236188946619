import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { RedistributionDisplayMode } from '../../Calculations/calculationsSlice';
import CalculationRedistributionListing from './parts/listing/CalculationRedistributionListing';
import CalculationRedistributionImport from './parts/import/CalculationRedistributionImport';

const CalculationRedistributionSubpage: React.FC = () => {
  const redistributionDisplayMode = useSelector(
    (state: ReduxState) => state.calculations.redistributionDisplayMode,
  );

  switch (redistributionDisplayMode) {
    case RedistributionDisplayMode.LIST: {
      return <CalculationRedistributionListing />;
    }
    case RedistributionDisplayMode.IMPORT: {
      return <CalculationRedistributionImport />;
    }
  }
};

export default CalculationRedistributionSubpage;
