import React from 'react';
import { SkuResponse } from '@ydistri/api-sdk';

export type SaleType = 'saleRegular' | 'salePromo' | 'saleSellout';

export const saleTypeTuple: SaleType[] = ['saleRegular', 'salePromo', 'saleSellout'];

export type SaleTypeMapped = {
  [saleType in SaleType]: number;
};

export const saleTypeDefault: SaleTypeMapped = {
  salePromo: 0,
  saleRegular: 0,
  saleSellout: 0,
};

export interface DetailSaleRowData extends SaleTypeMapped {
  key: React.ReactNode;
  isFullYear: boolean;
  month: Date;
  monthString: string;
  saleTotal: number;
  children?: DetailSaleRowData[];
}

export type FullYearData = Record<number, SaleTypeMapped>;

export type FullYearTotalSales = Record<number, number>;

export interface SkuResponseExt extends SkuResponse {
  frequency6Months?: number;
  saleQuantityTotal?: number;
  saleValueTotal?: number;
}
