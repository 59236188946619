import React, { useCallback, useMemo } from 'react';
import { CalculationType, PapProgressStatus, PapSyncStatus } from '@ydistri/api-sdk';
import { useGetCalculationQuery, useUpdateCalculationMutation } from './apiCalculationDetail';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { Button, Column, Row, Text } from '@ydistri/ds';
import { MdAppShortcut, MdDelete } from 'react-icons/md';
import CalculationAction from '../Calculations/actions/CalculationAction';
import { Popover } from 'antd';
import { useApplicationConfiguration } from '../../hooks/useApplicationConfiguration';
import { format } from 'date-fns';

const appIcon = <MdAppShortcut size={20} />;
const trashIcon = <MdDelete />;

const PapSyncButton: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);
  const [updateCalculation, { isLoading }] = useUpdateCalculationMutation();
  const { isPickAndPackEnabled } = useApplicationConfiguration();

  const isProduction = useMemo(
    () => calculationData?.type === CalculationType.Production,
    [calculationData?.type],
  );

  const papSyncStatus = calculationData?.papDbSyncStatus || PapSyncStatus.NotSynced;
  const papProgressStatus =
    calculationData?.papResultCalculationProgressStatus || PapProgressStatus.NotStarted;

  const isPapSynced = calculationData?.isPap && calculationData?.papDbSyncStatus !== undefined;
  const hasPickingStarted = papProgressStatus !== PapProgressStatus.NotStarted;
  const hasPickingFinished =
    papProgressStatus === PapProgressStatus.PickingDone ||
    papProgressStatus === PapProgressStatus.Finalized;

  const syncPapHandler = useCallback(() => {
    updateCalculation({
      calculationId: templateOrCalculation.id,
      updateRequest: {
        isPap: true,
      },
    });
  }, [templateOrCalculation.id, updateCalculation]);

  const unsyncPapHandler = useCallback(() => {
    updateCalculation({
      calculationId: templateOrCalculation.id,
      updateRequest: {
        isPap: false,
      },
    });
  }, [templateOrCalculation.id, updateCalculation]);

  const loading = isLoading || papSyncStatus === PapSyncStatus.Syncing;
  const lastSyncedAt = useMemo(
    () =>
      calculationData?.lastSyncedIntoPapAppAt
        ? format(new Date(calculationData.lastSyncedIntoPapAppAt), 'd. M. yyyy HH:mm')
        : ' - ',
    [calculationData?.lastSyncedIntoPapAppAt],
  );

  const content = useMemo(() => {
    return (
      <Column $gap="0.5rem">
        <Column $gap="0.25rem">
          {hasPickingFinished ? <Text $semiBold>Picking finished.</Text> : null}
          {hasPickingStarted && !hasPickingFinished ? (
            <Text $semiBold>Picking started.</Text>
          ) : null}
          {!hasPickingStarted && !hasPickingFinished ? <Text>Picking not started yet.</Text> : null}
          <span>&nbsp;</span>
          <Row $gap="0.25rem">
            <Text $semiBold>Last synced to App: </Text>
            <Text>{lastSyncedAt}</Text>
          </Row>
        </Column>
        <Button
          icon={loading ? undefined : appIcon}
          loading={loading}
          onClick={syncPapHandler}
          disabled={papSyncStatus === PapSyncStatus.Unsyncing}
          $colorVariant={papSyncStatus === PapSyncStatus.SyncFailed ? 'ghostDanger' : 'primary'}
        >
          {isPapSynced ? 'Re-sync to PaP' : 'Sync to PaP'}
        </Button>
        {isPapSynced && (
          <Button
            icon={loading ? undefined : trashIcon}
            loading={papSyncStatus === PapSyncStatus.Unsyncing}
            disabled={loading || hasPickingStarted}
            onClick={unsyncPapHandler}
            $colorVariant="ghostDanger"
          >
            Remove from PaP
          </Button>
        )}
      </Column>
    );
  }, [
    hasPickingFinished,
    hasPickingStarted,
    isPapSynced,
    lastSyncedAt,
    loading,
    papSyncStatus,
    syncPapHandler,
    unsyncPapHandler,
  ]);

  if (!isPickAndPackEnabled) return null;
  if (!isProduction) return null;

  return (
    <Popover content={content} placement="bottomLeft">
      <CalculationAction
        data-type="ProductionCalculationIcon"
        data-checked={isPapSynced ?? false}
        icon={appIcon}
        tooltipPosition="left"
        checked={isPapSynced ?? false}
        loading={
          papSyncStatus === PapSyncStatus.Syncing || papSyncStatus === PapSyncStatus.Unsyncing
        }
      />
    </Popover>
  );
};

export default PapSyncButton;
