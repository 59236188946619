import NoAdministrationItemSelected from '../../common/NoAdministrationItemSelected';
import React from 'react';
import { useGetProductListsQuery } from '../apiProductLists';

/**
 * Component for main part of the screen when no Product List is selected.
 * Displays a message based on whether there are any Product Lists or not.
 * @constructor
 */
const NoProductListSelected: React.FC = React.memo(() => {
  const { data: productLists } = useGetProductListsQuery(undefined);
  return (
    <NoAdministrationItemSelected
      message="No Product List selected"
      secondaryMessage={
        productLists && productLists.length > 0
          ? 'Click on a Product List from the list'
          : "Click on the 'Create new product list' button on the left to create a new Product List"
      }
    />
  );
});

NoProductListSelected.displayName = 'NoProductListSelected';
export default NoProductListSelected;
