import React, { useCallback, useMemo } from 'react';
import { IdentificationType } from '@ydistri/api-sdk';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setProductItemsContent } from '../../targetListAdministrationSlice';
import { getParseableStringFromPastedData, selectTargetListParser } from '../../targetListsLib';
import ListItemsTextArea from '../../../common/edit/ListItemsTextArea';
import { TargetListInputData } from '../../targetListsTypes';
import { AdministrationItemRow } from '../../../common/administrationItemsTypes';
import { ReduxState } from '../../../../../store';

interface TargetListItemsTextAreaProps {
  id?: string;
  label?: string;
}

const TargetListItemsTextArea: React.FC<TargetListItemsTextAreaProps> = ({ label, id }) => {
  const warehouseIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.warehouseIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.productIdentificationType,
  );
  const productItemsContent = useSelector(
    (state: ReduxState) => state.targetListAdministration.productItemsContent,
  );

  const dispatch = useDispatch();

  const itemsPlaceholder = useMemo(() => {
    return `Paste store ${
      warehouseIdentificationType === IdentificationType.Code ? 'code' : 'ID'
    }, product ${
      productIdentificationType === IdentificationType.Code ? 'code' : 'ID'
    } and quantity from Excel (or separated by semicolons)`;
  }, [productIdentificationType, warehouseIdentificationType]);

  /**
   * Saves the content of the text area to state
   * Called on text area's change event
   */
  const targetListItemsHandler = useCallback(
    (newValue: string) => {
      batch(() => {
        dispatch(setProductItemsContent(newValue));
      });
    },
    [dispatch],
  );

  const skuSerializer = useCallback((data: AdministrationItemRow<TargetListInputData>[]) => {
    return getParseableStringFromPastedData(data, ';');
  }, []);

  return (
    <ListItemsTextArea<TargetListInputData>
      label={label}
      id={id ?? 'TargetListItemsInput'}
      value={productItemsContent}
      placeholder={itemsPlaceholder}
      onValueChanged={targetListItemsHandler}
      parserProvider={selectTargetListParser}
      serializer={skuSerializer}
    />
  );
};

export default TargetListItemsTextArea;
