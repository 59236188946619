import { useSelectedTemplateId } from './useSelectedTemplateId';
import { useGetTemplatesQuery } from '../apis/apiApplication';
import { getApiHeader } from '../apis/getApiHeader';
import { API } from '../swagger/collections';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { TemplateResponse } from '@ydistri/api-sdk';

export function useSelectedTemplate(): TemplateResponse | undefined {
  const selectedTemplateId = useSelectedTemplateId();
  const { data: templates } = useGetTemplatesQuery(getApiHeader(API, 'Project-Code') ?? skipToken);

  return useMemo(() => {
    if (selectedTemplateId && templates)
      return templates.find(template => template.id === selectedTemplateId);
  }, [templates, selectedTemplateId]);
}
