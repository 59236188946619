import React, { PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { computeRemSize, Title } from '@ydistri/ds';
import { COLORS } from '../../../../styles/style';

const Header = styled(Title)`
  margin-top: ${() => computeRemSize(8)};
`;
const TableWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 2px solid ${COLORS.BORDER};
`;

interface EmbeddedTableWrapperProps extends PropsWithChildren {
  title?: string;
}

/**
 * Table to be used inside another table when a row is expanded.
 * @param title
 * @param children
 * @constructor
 */
const EmbeddedTableWrapper: React.FC<EmbeddedTableWrapperProps> = ({ title, children }) => {
  return (
    <>
      {title && <Header $size="xSmall">{title}</Header>}
      <TableWrapper>{children}</TableWrapper>
    </>
  );
};

export default EmbeddedTableWrapper;
