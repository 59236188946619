import { styled } from 'styled-components';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { CalculationStatisticIcon } from './CalculationStatisticIcon';
import { Column } from '@ydistri/ds';

interface StatisticsSectionDivProps {
  $width?: CSSProperties['width'];
  $minWidth?: CSSProperties['minWidth'];
}

const StatisticsSectionDiv = styled.div<StatisticsSectionDivProps>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 2rem;
  align-items: start;
  padding: 1rem;
  width: ${props => props.$width};
  min-width: ${props => props.$minWidth};
`;

interface StatisticsSectionProps extends PropsWithChildren, StatisticsSectionDivProps {
  icon?: string;
}

const StatisticsSection: React.FC<StatisticsSectionProps> = ({
  children,
  icon,
  $width = '50%',
  $minWidth = '50rem',
}) => {
  return (
    <StatisticsSectionDiv $width={$width} $minWidth={$minWidth}>
      {icon && <CalculationStatisticIcon src={icon} alt="icon" />}
      <Column $gap="1rem" $flexGrow={1}>
        {children}
      </Column>
    </StatisticsSectionDiv>
  );
};

export default StatisticsSection;
