import {
  EntityIdentificationType,
  EntityListImportAction,
  EntityListResponse,
  EntityListType,
} from '@ydistri/api-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveScreenType } from '../common/administrationItemsLib';
import { EntityListRow, IMPORT_STEPS } from './entityListsLib';
import { WritableDraft } from 'immer';

export type EntityListImportStep = (typeof IMPORT_STEPS)[keyof typeof IMPORT_STEPS];
export type EntityListImportStepRunning = Record<EntityListImportStep, boolean>;
export const getNumericStep = (x: EntityListImportStep): number => parseInt(x[4] ?? '0');

export const getStepId = (x: number): EntityListImportStep => {
  const step = `step${x}`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (Object.values(IMPORT_STEPS).includes(step)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return step as EntityListImportStep;
  }
  throw new Error('Unknown step!');
};

export interface EntityListTypeAdministrationState<T extends EntityListType> {
  selectedEntityList?: EntityListResponse;
  activeScreenType: ActiveScreenType;
  activeScreenTypeForced: boolean;
  showArchivedItems: boolean;
  sortField?: string;
  productItemsContent: string;
  productItemsErrors: string;
  productIdentificationType: EntityIdentificationType;
  storeIdentificationType: EntityIdentificationType;
  isLoading: boolean;
  parsedEntityListItems: EntityListRow<T>[];
  importStep: EntityListImportStep;
  importStepRunning: EntityListImportStepRunning;
  verificationTimeStamp?: string;
  selectedValidationImportAction?: EntityListImportAction;
  //validation: EntityListItemsCompared | undefined;
}

const getInitialEntityListTypeState = <
  T extends EntityListType,
>(): EntityListTypeAdministrationState<T> => ({
  selectedEntityList: undefined,
  activeScreenType: ActiveScreenType.NO_SELECTION,
  activeScreenTypeForced: false,
  showArchivedItems: false,
  sortField: 'CreatedAt',
  productItemsContent: '',
  productItemsErrors: '',
  productIdentificationType: EntityIdentificationType.CustomerId,
  storeIdentificationType: EntityIdentificationType.CustomerId,
  isLoading: false,
  parsedEntityListItems: [],
  importStep: IMPORT_STEPS.ITEM_IMPORT,
  importStepRunning: {
    // step0: false,
    step1: false,
    step2: false,
    step3: false,
  },
  verificationTimeStamp: undefined,
  selectedValidationImportAction: undefined,
  // validation: undefined,
});

export type EntityListAdministrationState = {
  [key in EntityListType]: EntityListTypeAdministrationState<key>;
};

const initialState: EntityListAdministrationState = {
  [EntityListType.ProductList]: getInitialEntityListTypeState<EntityListType.ProductList>(),
  [EntityListType.TargetList]: getInitialEntityListTypeState<EntityListType.TargetList>(),
  [EntityListType.MinLayerList]: getInitialEntityListTypeState<EntityListType.MinLayerList>(),
};

export const entityListAdministrationSlice = createSlice({
  name: 'entityListsAdministration',
  initialState,
  reducers: {
    setSelectedEntityList: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: EntityListResponse | undefined;
        openDetailPage?: boolean;
      }>,
    ) => {
      const elType = action.payload.entityListType;
      const previousSelectedEntityListId = state[elType].selectedEntityList?.entityListId;
      state[elType].selectedEntityList = action.payload.data;

      if (action.payload) {
        if (action.payload.data?.entityListId !== previousSelectedEntityListId) {
          state[elType].activeScreenType = ActiveScreenType.DETAIL;
        }
      } else {
        state[elType].activeScreenType = ActiveScreenType.NO_SELECTION;
      }
    },

    setActiveScreenType: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: ActiveScreenType;
        forced?: boolean;
      }>,
    ) => {
      state[action.payload.entityListType].activeScreenType = action.payload.data;
      state[action.payload.entityListType].activeScreenTypeForced = action.payload.forced ?? false;
    },

    setShowArchivedItems: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: boolean;
      }>,
    ) => {
      state[action.payload.entityListType].showArchivedItems = action.payload.data;
    },

    setSortField: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: string;
      }>,
    ) => {
      state[action.payload.entityListType].sortField = action.payload.data;
    },

    setIsLoading: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: boolean;
      }>,
    ) => {
      state[action.payload.entityListType].isLoading = action.payload.data;
    },

    setImportStep: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: EntityListImportStep;
      }>,
    ) => {
      state[action.payload.entityListType].importStep = action.payload.data;
    },

    setImportStepRunning: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: {
          step: EntityListImportStep;
          value: boolean;
        };
      }>,
    ) => {
      state[action.payload.entityListType].importStepRunning[action.payload.data.step] =
        action.payload.data.value;
    },

    setVerificationTimeStamp: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data?: string;
      }>,
    ) => {
      state[action.payload.entityListType].verificationTimeStamp = action.payload.data;
    },

    setSelectedValidationImportAction: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: EntityListImportAction;
      }>,
    ) => {
      state[action.payload.entityListType].selectedValidationImportAction = action.payload.data;
    },

    setProductIdentificationType: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: EntityIdentificationType;
      }>,
    ) => {
      state[action.payload.entityListType].productIdentificationType = action.payload.data;
    },

    setStoreIdentificationType: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: EntityIdentificationType;
      }>,
    ) => {
      state[action.payload.entityListType].storeIdentificationType = action.payload.data;
    },
    setProductItemsError: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: string;
      }>,
    ) => {
      state[action.payload.entityListType].productItemsErrors = action.payload.data;
    },
    setProductItemsContent: (
      state,
      action: PayloadAction<{
        entityListType: EntityListType;
        data: string;
      }>,
    ) => {
      state[action.payload.entityListType].productItemsContent = action.payload.data;
    },

    resetEntityListItems: (state, action: PayloadAction<{ entityListType: EntityListType }>) => {
      state[action.payload.entityListType].parsedEntityListItems = [];
    },

    setEntityListItems: <T extends EntityListType>(
      state: WritableDraft<EntityListAdministrationState>,
      action: PayloadAction<{
        entityListType: T;
        data: EntityListRow<T>[];
      }>,
    ) => {
      const { entityListType, data } = action.payload;
      if (entityListType in state) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        state[entityListType].parsedEntityListItems = data;
      }
    },

    resetState: () => initialState,
  },
});

export const {
  setSelectedEntityList,
  setActiveScreenType,
  setShowArchivedItems,
  setSortField,
  setProductIdentificationType,
  setStoreIdentificationType,
  setIsLoading,
  setImportStep,
  setImportStepRunning,
  setVerificationTimeStamp,
  setSelectedValidationImportAction,
  setProductItemsContent,
  setProductItemsError,
  setEntityListItems,
  resetEntityListItems,
} = entityListAdministrationSlice.actions;
