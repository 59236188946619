import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { closeModal } from './modalSlice';
import StoreDepartmentTable from '../Configuration/ConfigurationStoresAndDepartments/StoreDepartmentTable';

const StoreOverviewModal: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const footer = useMemo(() => <Button onClick={handleClose}>Close</Button>, [handleClose]);

  return (
    <Modal
      width="90%"
      title="Store department overview"
      onCancel={handleClose}
      open={true}
      footer={footer}
    >
      <StoreDepartmentTable />
    </Modal>
  );
};

export default StoreOverviewModal;
