import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { SkuAttrs } from '../../../lib/sku/skuTypes';
import { restoreSKUAttributes } from '../detailLib';
import { pick, type Many } from 'lodash';

export function useDetailSkuEntity(): SkuAttrs | undefined {
  const skuEntity = useSelector((state: ReduxState) => state.detailReducer.skuEntity);

  if (skuEntity) {
    return restoreSKUAttributes(skuEntity);
  } else {
    return undefined;
  }
}

// export function useDetail(): ReduxState['detailReducer'] {
//   return useSelector((state: ReduxState) => state.detailReducer);
// }

type Detail = ReduxState['detailReducer'];
export function useDetail<K extends keyof Detail = keyof Detail>(
  ...keys: Many<K>[]
): Pick<Detail, K> {
  return useSelector(
    (state: ReduxState) => (keys.length ? pick(state.detailReducer, ...keys) : state.detailReducer),
    shallowEqual,
  );
}
