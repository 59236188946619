import React from 'react';
import { ViewMode } from './scopeLib';
import { ConfigurationRuleSetupType } from '@ydistri/api-sdk';
import { ConfigurationInput, ConfigurationSwitch, OverwrittenIcon } from '@ydistri/ds';
import { ConfigurationFieldStatus } from '@ydistri/utils';

interface ScopeConfigurationFieldProps {
  viewMode?: ViewMode;
  value: number | boolean | null | undefined;
  field: ConfigurationRuleSetupType;
  disabledFinal?: boolean;
  onChangeSwitchHandler?: () => void;
  onChangeInputHandler?: (newValue: string) => void;
  status?: ConfigurationFieldStatus;
  fieldIsOverwritten?: boolean;
}

const emptyChangeHandler = () => {};

const ScopeConfigurationField: React.FC<ScopeConfigurationFieldProps> = ({
  viewMode = ViewMode.GENERAL_CONFIGURATION,
  value,
  field,
  disabledFinal = false,
  onChangeSwitchHandler = emptyChangeHandler,
  onChangeInputHandler = emptyChangeHandler,
  status = ConfigurationFieldStatus.DEFAULT,
  fieldIsOverwritten = false,
}) => {
  if (
    (viewMode === ViewMode.EXCEPTION || viewMode === ViewMode.NEW_EXCEPTION_MODAL) &&
    value === null
  ) {
    return null;
  }

  switch (field) {
    case ConfigurationRuleSetupType.UseAsSource:
    case ConfigurationRuleSetupType.UseAsTarget:
    case ConfigurationRuleSetupType.IsEnabled:
    case ConfigurationRuleSetupType.IsClosing:
    case ConfigurationRuleSetupType.IsSuperTarget:
      return (
        <ConfigurationSwitch
          disabled={disabledFinal}
          status={status}
          overwritten={fieldIsOverwritten}
          overwrittenIcon={OverwrittenIcon.WARNING}
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          value={value as boolean}
          onChange={onChangeSwitchHandler}
          includeEmptySpace={false}
        />
      );
    case ConfigurationRuleSetupType.MaxNumberOfSourceStores:
    case ConfigurationRuleSetupType.MaxNumberOfTargetStores:
    case ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions:
    case ConfigurationRuleSetupType.MinimumRouteValue:
      return (
        <ConfigurationInput
          disabled={disabledFinal}
          status={status}
          overwritten={fieldIsOverwritten}
          overwrittenIcon={OverwrittenIcon.WARNING}
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          value={(value ?? 0) as number}
          onChange={onChangeInputHandler}
          placeholder="Unlimited"
        />
      );
  }
};

export default ScopeConfigurationField;
