import React, { useCallback } from 'react';
import { ActionsBar, Column, Row } from '@ydistri/ds';
import { batch, useDispatch, useSelector } from 'react-redux';
import { EntityIdentificationType, EntityListType } from '@ydistri/api-sdk';

import { Button } from 'antd';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { ReduxState } from '../../../../../store';
import {
  resetEntityListItems,
  setProductIdentificationType,
  setStoreIdentificationType,
} from '../../entityListAdministrationSlice';
import useImportItems from '../../useImportItems';
import EntityListItemsArea from './EntityListItemsArea';
import { titleByEntityListType } from '../../entityListsLib';
import EntityIdentificationTypeComponent from '../../../common/EntityIdentificationTypeComponent';

interface EntityListEditorProps {
  entityListType: EntityListType;
}

const EntityListEditor: React.FC<EntityListEditorProps> = ({ entityListType }) => {
  const storeIdentificationType = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].storeIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) =>
      state.entityListsAdministration[entityListType].productIdentificationType,
  );
  const isLoading = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].isLoading,
  );

  const productItemsContent = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].productItemsContent,
  );

  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  const dispatch = useDispatch();

  const { callback: importItems, isPending } = useImportItems(entityListType);

  const selectWarehouseIdentificationHandler = useCallback(
    (newValue: EntityIdentificationType) => {
      batch(() => {
        dispatch(setStoreIdentificationType({ entityListType, data: newValue }));
        dispatch(resetEntityListItems({ entityListType }));
      });
    },
    [dispatch, entityListType],
  );

  const selectProductIdentificationHandler = useCallback(
    (newValue: EntityIdentificationType) => {
      batch(() => {
        dispatch(setProductIdentificationType({ entityListType, data: newValue }));
        dispatch(resetEntityListItems({ entityListType }));
      });
    },
    [dispatch, entityListType],
  );

  const replaceItems = useCallback(() => {
    importItems();
  }, [importItems]);

  if (!selectedEntityList) {
    return null;
  }

  if (isLoading) {
    return (
      <Column $gap="0.5rem" $minWidth="45rem">
        <LoadingIndicator text="Validating..." />
      </Column>
    );
  } else {
    return (
      <Column $gap="1rem">
        <EntityIdentificationTypeComponent
          type="Store"
          value={storeIdentificationType}
          onChange={selectWarehouseIdentificationHandler}
          id="StoreIdentificationTypeSelect"
        />
        <EntityIdentificationTypeComponent
          type="Product"
          value={productIdentificationType}
          onChange={selectProductIdentificationHandler}
          id="ProductIdentificationTypeSelect"
        />
        <Row $gap="1rem">
          <Column $gap="0.5rem" $minWidth="45rem">
            <EntityListItemsArea label="Items" entityListType={entityListType} />
            <ActionsBar data-testid="EntityListEditorActions">
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="replace-entity-list"
                type="primary"
                onClick={replaceItems}
                loading={isPending}
              >
                Import {titleByEntityListType[entityListType]}
              </Button>
            </ActionsBar>
          </Column>
        </Row>
      </Column>
    );
  }
};

export default EntityListEditor;
