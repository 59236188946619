import {
  CalculationActionStatus,
  CalculationActionStatuses,
} from './CalculationActionStatusComponent';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CalculationsActionState {
  calculationStatuses: CalculationActionStatuses;
}

const initialState: CalculationsActionState = {
  calculationStatuses: {},
};

type UpdateStatusData = {
  calculationId: number;
  status: CalculationActionStatus | undefined;
};

export const calculationsActionSlice = createSlice({
  name: 'calculationsAction',
  initialState,
  reducers: {
    setCalculationsActionStatuses: (
      state: CalculationsActionState,
      action: PayloadAction<CalculationActionStatuses>,
    ) => {
      state.calculationStatuses = action.payload;
    },
    updateCalculationActionStatus: (
      state: CalculationsActionState,
      action: PayloadAction<UpdateStatusData>,
    ) => {
      state.calculationStatuses[action.payload.calculationId] = action.payload.status;
    },
    resetState: () => initialState,
  },
});

export const { setCalculationsActionStatuses, updateCalculationActionStatus } =
  calculationsActionSlice.actions;

export const calculationsActionReducer = calculationsActionSlice.reducer;
