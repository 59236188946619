import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterByForced } from '../../../../../../Calculations/calculationsSlice';
import { LabeledToggle, LabelPlacement } from '@ydistri/ds';

/**
 * Controls the Out-Of-Stock SKU filter setting in the calculation slice.
 * <p>When the filter is set, only out of stock skus are shown.</p>
 */
const ForcedRedistributionFilter: React.FC = () => {
  const redistributionFilterForced = useSelector(
    state => state.calculations.redistributionFilterForced,
  );
  const dispatch = useDispatch();

  const clickHandler = useCallback(() => {
    dispatch(filterByForced(!redistributionFilterForced));
  }, [dispatch, redistributionFilterForced]);

  return (
    <LabeledToggle
      label="Show only forced redistribution SKUs"
      checked={redistributionFilterForced}
      labelPlacement={LabelPlacement.RIGHT}
      onClick={clickHandler}
    />
  );
};

export default ForcedRedistributionFilter;
