import React, { useEffect } from 'react';
import { setSubpage } from '../../../routes/routerSlice';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import { useDispatch } from 'react-redux';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../apiCalculationDetail';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { CalculationType } from '@ydistri/api-sdk';
import { Helmet } from 'react-helmet';
import SectionStoreEvaluations from './SectionStoreEvaluations';
import { MainContent, MainContentWrapper } from '@ydistri/ds';

const CalculationEvaluation: React.FC = () => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData, isFetching } = useGetCalculationQuery(templateOrCalculation.id);

  useEffect(() => {
    dispatch(setSubpage(CalculationDetailSubpages.EVALUATION));
  }, [dispatch]);

  if (isFetching) return <LoadingIndicator />;
  if (calculationData?.type !== CalculationType.Production)
    return <div>Calculation is not set as production, so it is not evaluated.</div>;

  return (
    <>
      <Helmet title={`Calculation ${templateOrCalculation.id} - Evaluation`} />
      <MainContentWrapper $flexDirection="column">
        <MainContent>
          <SectionStoreEvaluations />
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default CalculationEvaluation;
