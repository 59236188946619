import {
  MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
  ParsedCategoryRedistribution,
} from '../categoryInsightsLib';
import React, { useMemo } from 'react';
import { BarPartitionContent, BarLineProps, SimpleTooltipContent } from '@ydistri/ds';
import BarRedistribution from './BarRedistribution';
import { computePercentage } from '@ydistri/utils';
import { useCurrency } from '../../../../hooks/useCurrency';

const TITLE_ROWS = ['Source', 'by reason'];

interface BarSourceByReasonProps {
  data: ParsedCategoryRedistribution;
}

const BarSourceByReason: React.FC<BarSourceByReasonProps> = ({ data }) => {
  const currency = useCurrency();

  const barLineData: BarLineProps['data'] = useMemo(() => {
    const percentageForced = computePercentage(
      data.byRedistributionType.SourceForcedValue.total,
      data.total,
    );
    const percentageClosing = computePercentage(
      data.byRedistributionType.SourceIsClosingValue.total,
      data.total,
    );
    const percentageStandard = 100 - percentageForced - percentageClosing;

    return [
      {
        percentage: percentageClosing,
        tooltip: (
          <SimpleTooltipContent
            title="Is closing"
            value={data.byRedistributionType.SourceIsClosingValue.total}
            postfix={currency}
          />
        ),
        content: (
          <BarPartitionContent
            title="Is closing"
            percentage={percentageClosing}
            value={data.byRedistributionType.SourceIsClosingValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageForced,
        tooltip: (
          <SimpleTooltipContent
            title="Forced redistribution"
            value={data.byRedistributionType.SourceForcedValue.total}
            postfix={currency}
          />
        ),
        content: (
          <BarPartitionContent
            title="Forced redistribution"
            percentage={percentageForced}
            value={data.byRedistributionType.SourceForcedValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageStandard,
        tooltip: (
          <SimpleTooltipContent
            title="Standard redistribution"
            value={data.byRedistributionType.SourceStandardValue.total}
            postfix={currency}
          />
        ),
        content: (
          <BarPartitionContent
            title="Standard redistribution"
            percentage={percentageStandard}
            value={data.byRedistributionType.SourceStandardValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
    ];
  }, [
    data.byRedistributionType.SourceForcedValue.total,
    data.byRedistributionType.SourceIsClosingValue.total,
    data.byRedistributionType.SourceStandardValue.total,
    data.total,
    currency,
  ]);

  return <BarRedistribution barLineData={barLineData} title={TITLE_ROWS} dividers={true} />;
};

export default BarSourceByReason;
