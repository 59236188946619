import { useMemo } from 'react';
import { RedistributionImportInputDataKeys } from './redistributionImportTypes';
import useRedistributionImportFormat from './useRedistributionImportFormat';

/**
 * Returns a list of keys that form the format of importContent. The content may changed based on user's choice of instruction.
 */
const useRedistributionImportKeys = (): RedistributionImportInputDataKeys => {
  const importFormat = useRedistributionImportFormat();

  return useMemo((): RedistributionImportInputDataKeys => {
    return importFormat.map(identifier => identifier.id);
  }, [importFormat]);
};

export default useRedistributionImportKeys;
