import { apiSlice } from '../../apis/api';
import { SkuRedistributionResponseCollectionResponse } from '@ydistri/api-sdk';
import { CalculationsCollection } from '../../swagger/collections';
import { forceRefetchForInfiniteScroll, InfiniteScrollParams, OtherArgs } from '@ydistri/ds';
import { getTags } from '../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('calculationCategories');

export interface CalculationCategoryPairingRequest extends InfiniteScrollParams {
  calculationId: number;
  categoryId: number;
}

export const apiCalculationsCategories = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getCalculationCategoryPairings: builder.query<
        SkuRedistributionResponseCollectionResponse,
        CalculationCategoryPairingRequest
      >({
        queryFn: async args => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
          };

          const response = await CalculationsCollection.getCalculationCategoryPairings(
            args.calculationId,
            args.categoryId,
            query,
          );
          return { data: response.data };
        },
        providesTags: result => {
          if (result) {
            return [
              ...result.data.map(
                ({ sourceSkuId, targetSkuId }) =>
                  ({
                    type: TAGS.calculationCategoryPairings,
                    id: `${sourceSkuId}#${targetSkuId}`,
                  }) as const,
              ),
              { type: TAGS.calculationCategoryPairings, id: 'LIST' },
            ];
          } else {
            return [{ type: TAGS.calculationCategoryPairings, id: 'LIST' }];
          }
        },
        serializeQueryArgs: args => {
          const conditions = args.queryArgs.conditions;
          let txtConditions = '';
          if (conditions && conditions.length > 0) {
            txtConditions = conditions
              .map(c => `${c.fieldName} ${c.operation} ${c.value}`)
              .join('; ');
          }

          let txtSortings = '';
          if (args.queryArgs.sortings && args.queryArgs.sortings.length > 0) {
            txtSortings = args.queryArgs.sortings
              .map(s => `${s.fieldName} ${s.direction}`)
              .join('; ');
          }
          return `${args.endpointName}(calculationId=${args.queryArgs.calculationId}, categoryId=${
            args.queryArgs.categoryId
          }, conditions=${txtConditions}, search=${
            args.queryArgs.search ?? ''
          }, sortings=${txtSortings})`;
        },
        merge: (
          currentCache: SkuRedistributionResponseCollectionResponse,
          newItems: SkuRedistributionResponseCollectionResponse,
          otherArgs: OtherArgs<CalculationCategoryPairingRequest>,
        ) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.data.splice(0, currentCache.data.length);
          }
          currentCache.data.push(...newItems.data);
        },
        forceRefetch: forceRefetchForInfiniteScroll<InfiniteScrollParams | undefined>(),
      }),
    }),
  });

export const { useGetCalculationCategoryPairingsQuery } = apiCalculationsCategories;
