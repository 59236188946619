/**
 * Return true if the value is an outlier in the observations.
 * An outlier is a value that is more than 3 times the MAD (Median Absolute Deviation) away from the median.
 * @param observations
 * @param value
 */
export const isOutlier = (observations: number[], value: number): boolean => {
  // Helper function to calculate median
  const median = (nums: number[]): number => {
    const sorted = [...nums].sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
  };

  // Helper function to calculate MAD (Median Absolute Deviation)
  const mad = (nums: number[], med: number): number => {
    const absDeviations = nums.map(num => Math.abs(num - med));
    return median(absDeviations);
  };

  const med = median(observations);
  const madValue = mad(observations, med);
  const threshold = 3 * madValue;

  const deviation = value - med; //not computing absolute value, because we are interested in positive deviations only
  return deviation > threshold;
};
