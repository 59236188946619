import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { useGetTargetStoresPickingInsightsQuery } from '../../../apiCalculationPickingResults';
import EmbeddedTableWrapper from '../../../components/EmbeddedTableWrapper';
import LoadingIndicator from '../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import React, { useEffect, useMemo } from 'react';
import { getPickInsightsColumns } from '../../../pickingResultsLib';
import { SimpleTable } from '../../../../Statistics/components/SimpleTable';
import { StorePickingInsightsResponse } from '@ydistri/api-sdk';
import { computeRemSize } from '@ydistri/ds';
import {
  EntityColumns,
  useStoreTableColumnsProvider,
} from '../../../../../../hooks/useEntityTableColumnsProvider';

const AdjustExpandedRowPadding: React.FC = () => {
  useEffect(() => {
    const adjustPadding = () => {
      const expandableCell = document.querySelector<HTMLTableCellElement>(
        'td.ant-table-row-expand-icon-cell',
      );
      if (expandableCell) {
        const expandableCellWidth = expandableCell.offsetWidth;

        const expandedRows = document.querySelectorAll('tr.ant-table-expanded-row');
        expandedRows.forEach(expandedRow => {
          const expandedRowCell = expandedRow.querySelector('td');
          if (expandedRowCell) {
            expandedRowCell.style.setProperty(
              'padding-left',
              `${expandableCellWidth}px`,
              'important',
            );
          }
        });
      }
    };

    // Adjust padding on component mount
    adjustPadding();

    // adjust padding on window resize
    window.addEventListener('resize', adjustPadding);
    return () => {
      window.removeEventListener('resize', adjustPadding);
    };
  }, []);

  return null;
};

interface TargetStoreDetailTableProps {
  sourceStoreId: number;
}

const TargetStorePickingResultsDetailTable: React.FC<TargetStoreDetailTableProps> = ({
  sourceStoreId,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const storeTableColumnsProvider = useStoreTableColumnsProvider();

  const { data, isFetching } = useGetTargetStoresPickingInsightsQuery({
    calculationId: templateOrCalculation.id,
    sourceStoreId,
  });

  const tableColumns = useMemo(() => {
    const storeColumns = storeTableColumnsProvider<StorePickingInsightsResponse>({
      [EntityColumns.NAME]: {
        width: computeRemSize(260),
      },
      [EntityColumns.ID]: {
        width: computeRemSize(100),
        align: 'center',
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(100),
      },
    });
    return getPickInsightsColumns(storeColumns);
  }, [storeTableColumnsProvider]);

  if (isFetching) {
    return <LoadingIndicator />;
  } else {
    if (data) {
      return (
        <EmbeddedTableWrapper title="Target stores">
          <AdjustExpandedRowPadding />
          <SimpleTable<StorePickingInsightsResponse>
            id={`target-store-picking-insights-${sourceStoreId}`}
            dataSource={data}
            columns={tableColumns}
            loading={isFetching}
            showHeader={true}
            showBorder={true}
          />
        </EmbeddedTableWrapper>
      );
    }
  }
};

export default TargetStorePickingResultsDetailTable;
