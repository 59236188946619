import React from 'react';
import { Button, Tooltip } from 'antd';
import { BaseRootConfiguration, ExceptionInfo } from '../../../hooks/useCategoryConfiguration';
import { ConfigurationDataType, ConfigurationFieldType, SkuType } from '@ydistri/api-sdk';
import { styled, css } from 'styled-components';
import { COLORS } from '@ydistri/ds';
import { configurationValueToString } from '@ydistri/utils';
import { AiOutlineDelete, AiOutlineGlobal } from 'react-icons/ai';

const ExceptionsTable = styled.table.attrs({
  cellSpacing: 0,
  cellPadding: 0,
  borderSpacing: 10,
})`
  td {
    vertical-align: middle;
  }
`;

enum ExceptionRowType {
  GLOBAL,
  SELECTED,
  DEFAULT,
}

interface ExceptionRowProps {
  exceptionRowType?: ExceptionRowType;
}

const ClearButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

const ExceptionTbody = styled.tbody``;

const ExceptionTr = styled.tr<ExceptionRowProps>`
  height: 1.75rem;

  td:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  td:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  ${({ exceptionRowType }) =>
    exceptionRowType === ExceptionRowType.GLOBAL &&
    css`
      font-weight: 500;
      font-size: 1.1rem;
      background-color: ${COLORS.GREY_LIGHT};
    `}

  ${({ exceptionRowType }) =>
    exceptionRowType === ExceptionRowType.SELECTED &&
    css`
      font-weight: 500;
      font-size: 1.1rem;
      background-color: ${COLORS.PRIMARY_LIGHT};
      color: ${COLORS.PRIMARY};
    `}
`;

const SpacerTr = styled.tr`
  height: 0.25rem;
`;

const ExceptionTd = styled.td`
  padding: 0.25rem 1rem;
  text-align: center;
`;

const ExceptionTdCategoryName = styled(ExceptionTd)`
  text-align: left;
`;

interface GetHoverDataProps {
  selectedCategoryId: number;
  exceptions?: ExceptionInfo[];
  rootCategoryConfiguration?: BaseRootConfiguration;
  fieldType: ConfigurationFieldType;
  dataType: ConfigurationDataType;
  validSkuType: SkuType;
  clearing: boolean;
  disabled: boolean;
  clearExceptionHandler: () => void;
  entityListId?: number;
}

const deleteIcon = <AiOutlineDelete />;

export const getConfigurationFieldHoverData = ({
  selectedCategoryId,
  exceptions,
  rootCategoryConfiguration,
  fieldType,
  dataType,
  validSkuType,
  clearing,
  disabled,
  clearExceptionHandler,
  entityListId,
}: GetHoverDataProps): React.ReactNode => {
  if (selectedCategoryId === 1) return null;

  const es = exceptions ?? [];

  if (es.length === 0) return null;

  const e = es.toReversed();

  let globalSetting: string = '0';

  if (rootCategoryConfiguration) {
    globalSetting = rootCategoryConfiguration[fieldType][validSkuType];
  }

  return (
    <ExceptionsTable>
      <ExceptionTbody>
        <ExceptionTr exceptionRowType={ExceptionRowType.GLOBAL}>
          <ExceptionTdCategoryName>Global settings</ExceptionTdCategoryName>
          <ExceptionTd>
            <span>{configurationValueToString(dataType, globalSetting, entityListId)}</span>
          </ExceptionTd>
          <ExceptionTd>
            <AiOutlineGlobal />
          </ExceptionTd>
        </ExceptionTr>
        <SpacerTr />
        {e.map(exception => (
          <React.Fragment key={exception.categoryId}>
            <ExceptionTr
              exceptionRowType={
                exception.categoryId === selectedCategoryId
                  ? ExceptionRowType.SELECTED
                  : ExceptionRowType.DEFAULT
              }
            >
              <ExceptionTdCategoryName>{exception.categoryName}</ExceptionTdCategoryName>
              <ExceptionTd>
                {configurationValueToString(dataType, exception.value, entityListId)}
              </ExceptionTd>
              <ExceptionTd>
                {exception.categoryId === selectedCategoryId && !disabled && (
                  <Tooltip title="Clear exception">
                    <ClearButton
                      type="primary"
                      danger
                      icon={deleteIcon}
                      onClick={clearExceptionHandler}
                      loading={clearing}
                    />
                  </Tooltip>
                )}
              </ExceptionTd>
            </ExceptionTr>
            <SpacerTr />
          </React.Fragment>
        ))}
      </ExceptionTbody>
    </ExceptionsTable>
  );
};
