import { getOAuthSettings } from './getOAuthSettings';
import { API } from '../swagger/collections';
import { IdentityAPI } from '../swagger/collections-identity';
import { getItem, LSKey, removeItem, setItem } from '../store/localStore';
// eslint-disable-next-line import/no-named-as-default
import Axios from 'axios';

const { oauthUrl, clientId, clientSecret } = getOAuthSettings();

export default class Auth {
  oAuth = {
    oAuthUrl: oauthUrl,
    clientId: clientId,
    clientSecret: clientSecret,
    scope: 'openid email',
    responseType: 'token id_token',
  };

  constructor() {
    this.signin = this.signin.bind(this);
    this.signOut = this.signOut.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getBearer = this.getBearer.bind(this);
  }

  getUser(): string | undefined {
    return getItem(LSKey.TOKEN);
  }

  getBearer(): string | undefined {
    return `Bearer ${getItem(LSKey.TOKEN) ?? ''}`;
  }

  signin(username: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const params = new URLSearchParams();
      params.append('client_id', this.oAuth.clientId);
      params.append('client_secret', this.oAuth.clientSecret);
      params.append('grant_type', 'password');
      params.append('username', username);
      params.append('password', password);

      Axios({
        method: 'POST',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: oauthUrl + '/connect/token',
        data: params,
      })
        .then(
          (res: {
            data: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              access_token: string;
              // eslint-disable-next-line @typescript-eslint/naming-convention
              expires_in: number;
            };
          }) => {
            console.log(res);
            this.setSession({ expiresIn: res.data.expires_in, accessToken: res.data.access_token });
            resolve();
          },
        )
        .catch(error => {
          const errorMessage = error instanceof Error ? error.message : String(error);
          reject(new Error(errorMessage));
        });
    });
  }

  signOut(): void {
    // Clear access token from local storage
    removeItem(LSKey.TOKEN);
    removeItem(LSKey.TOKEN_EXPIRATION);
    removeItem(LSKey.USER);
  }

  setAuthenticationHeaders(token: string): void {
    const bearer = `Bearer ${token}`;

    console.log('Changing Authorization header...');

    //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    API.instance.defaults.headers.common.Authorization = bearer;
    //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    IdentityAPI.instance.defaults.headers.common.Authorization = bearer;
  }

  setSession(authResult: { expiresIn: number; accessToken: string }): void {
    // Set the time that the access token will expire at
    console.log('Setting session...');
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    setItem(LSKey.TOKEN, authResult.accessToken);
    setItem(LSKey.TOKEN_EXPIRATION, expiresAt);
    this.setAuthenticationHeaders(authResult.accessToken);
  }

  isAuthenticated(): boolean {
    // Check whether the current time is past the access token's expiry time
    const expiry = getItem(LSKey.TOKEN_EXPIRATION);

    if (!expiry) {
      return false;
    }

    const currentTime = new Date().getTime();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const expirationTime: number = JSON.parse(expiry) as number;
    const authenticated = currentTime < expirationTime;

    console.log('Checking if authenticated...', expirationTime, currentTime, authenticated);

    if (!authenticated) {
      this.signOut();
      window.location.reload();
    }
    return authenticated;
  }
}
