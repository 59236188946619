import { IconButton, IconButton_IconSizes, IconButtonProps, IconButtonSize } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { TbPlaylistAdd } from 'react-icons/tb';

interface EditContentIconButton {
  disabled?: boolean;
  tooltip: string;
  tooltipPosition?: IconButtonProps['tooltipPosition'];
  onClick: () => void;
  size?: IconButtonSize;
}

const EditContentIconButton: React.FC<EditContentIconButton> = ({
  disabled = false,
  tooltip,
  tooltipPosition = 'left',
  onClick,
  size = 'normal',
  ...rest
}) => {
  const icon = useMemo(() => <TbPlaylistAdd size={IconButton_IconSizes[size]} />, [size]);

  return (
    <IconButton
      icon={icon}
      size={size}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      onClick={onClick}
      {...rest}
    />
  );
};

export default EditContentIconButton;
