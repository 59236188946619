import React, { PropsWithChildren, useCallback } from 'react';
import * as XLSX from 'xlsx';
import { get } from 'lodash';
import { styled } from 'styled-components';
import { YColumnsType } from '@ydistri/ds';
import { extractData } from '../../../lib/tables/tablesLib';

const ChildrenWrapper = styled.div`
  cursor: pointer;
`;

interface TableExportProps<T> extends PropsWithChildren {
  dataSource: T[];
  columns: YColumnsType<T>[];
  fileName: string;
  sheetName: string;
  level?: number;
}

const TableExport = <T extends object>({
  dataSource,
  columns,
  fileName,
  sheetName,
  children,
  level = 1,
}: TableExportProps<T>): React.ReactElement => {
  const exportData = useCallback(
    (sheetName: string, fileName: string) => {
      const wb = XLSX.utils.book_new();

      const columnTitles = columns.map(column => column.title);
      const ws = XLSX.utils.aoa_to_sheet([columnTitles]);

      const data = extractData(dataSource, level).map(row => {
        console.log('Table export: Extract data from row: ', row);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return columns.map((column, index) => {
          const dataIndex = get(column, ['dataIndex']);
          if (dataIndex && (typeof dataIndex === 'string' || Array.isArray(dataIndex))) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const value = get(row, dataIndex);

            if (column.render) {
              return column.render(value, row, index);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return value ?? '';
          }

          return '';
        });
      });

      //enable filter for all columns
      const filterRange = XLSX.utils.encode_range({
        s: { c: 0, r: 0 },
        e: { c: columnTitles.length - 1, r: data.length },
      });
      XLSX.utils.encode_col(columnTitles.length - 1);
      const ref = filterRange;
      ws['!autofilter'] = {
        ref,
      };

      if (data.length > 0) {
        XLSX.utils.sheet_add_aoa(ws, data, { origin: 'A2' });

        //format numbers
        data.forEach((row, rowIndex) => {
          row.forEach((cell, cellIndex) => {
            if (typeof cell === 'number') {
              const columnDef = columns[cellIndex];
              if (columnDef.exportNumberFormat) {
                const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 1, c: cellIndex }); //+1 for the header row
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                ws[cellAddress].z = columnDef.exportNumberFormat;
              }
            }
          });
        });

        ws['!cols'] = columns.map((column, index: number) => {
          const maxLength = data.reduce((acc, row) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            const length: number = row[index]?.toString().length ?? 0;
            return length > acc ? length : acc;
          }, 0);

          const columnTitleLength = column.title?.toString().length ?? 0;
          const props = {
            wch: maxLength > columnTitleLength ? maxLength : columnTitleLength + 4,
          };
          if (props.wch < 5) {
            props.wch = 5;
          }

          return props;
        });
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFileXLSX(wb, fileName);
    },
    [columns, dataSource, level],
  );

  const clickHandler: React.MouseEventHandler = useCallback(
    event => {
      event.stopPropagation();
      exportData(sheetName, fileName);
    },
    [exportData, fileName, sheetName],
  );

  return <ChildrenWrapper onClick={clickHandler}>{children}</ChildrenWrapper>;
};

export default TableExport;
