import { Text } from '@ydistri/ds';
import React from 'react';

export const NO_DATA_PLACEHOLDER = '-';

interface NoDataProps {
  placeholder?: React.ReactNode;
  empty?: boolean;
}

/**
 * Used when there is no data to show but the space should not be empty
 * as it may confuse the user thinking that the data is not loaded yet.
 * @constructor
 */
const NoData: React.FC<NoDataProps> = ({ placeholder, empty }) => {
  if (placeholder) {
    return <Text>{placeholder}</Text>;
  } else {
    if (!empty) {
      return <Text>{NO_DATA_PLACEHOLDER}</Text>;
    }
  }
};

export default NoData;
