import { createDebugLog } from '../../../lib/utils/logging';
import {
  ValidatorMessage,
  ValidatorMessageSeverity,
  ValidatorResponse,
  ValidatorSection,
} from './validatorLib';
import {
  CalculationConfigurationResponse,
  ConfigurationRuleScopeEntityType,
  ConfigurationRuleScopeMode,
  ConfigurationRuleSetupType,
  DepartmentResponse,
  RegionResponse,
  StoreResponse,
  StoreTypeResponse,
  SuperTargetRedistributionType,
} from '@ydistri/api-sdk';
import { Scope } from '../ConfigurationStoresAndDepartments/scopeLib';
import { ListType } from '../../../apis/apiLists';
import { initialValidatorInfoStoresAndDepartments } from './validatorInfoLib';
import { EntityTypeToResponseMap } from '../ConfigurationStoresAndDepartments/useEntityListData';
import {
  iterableConfigurationRuleScopeEntityType,
  iterableConfigurationRuleSetupType,
} from '../../../helpers/types/iterableEnums';

const debugLog = createDebugLog('Validator', 'validateStoresAndDepartments');

enum Messages {
  NO_SOURCE,
  NO_TARGET,
  NO_CONFIGURATION_IN_EXCEPTION,
  NO_SUBSCOPE_IN_EXCEPTION,
  EMPTY_SPECIFIED_FIELD,
  OUT_OF_SCOPE,
  IS_CLOSING_AND_SUPER_TARGET,
  DEPARTMENT_ONLY_CLOSING,
  DEPARTMENT_ONLY_SUPER_TARGET,
}

const section = ValidatorSection.STORES_AND_DEPARTMENTS;

type PartialMessage = Omit<ValidatorMessage, 'section'>;

const addMessage = (condition: boolean, messages: ValidatorMessage[], message: PartialMessage) => {
  if (!condition) return;
  messages.push({
    section,
    ...message,
  });
};

const MESSAGES = {
  noUseAsSource: {
    status: ValidatorMessageSeverity.ERROR,
    message: '"Use as source" setting is not used anywhere.',
    key: Messages.NO_SOURCE,
  },
  noUseAsTarget: {
    status: ValidatorMessageSeverity.ERROR,
    message: '"Use as target" setting is not used anywhere.',
    key: Messages.NO_TARGET,
  },
  noConfigurationInException: {
    status: ValidatorMessageSeverity.WARNING,
    message: 'Some exceptions have empty configuration.',
    key: Messages.NO_CONFIGURATION_IN_EXCEPTION,
  },
  noSubScopeInException: {
    status: ValidatorMessageSeverity.WARNING,
    message:
      'Some exceptions are using "All" stores, store types and departments. This will have no effect.',
    key: Messages.NO_SUBSCOPE_IN_EXCEPTION,
  },
  emptySpecifiedFieldException: {
    status: ValidatorMessageSeverity.WARNING,
    message:
      'Some exceptions have "Specified" or "Excluded" scope type, but no entity is selected.',
    key: Messages.EMPTY_SPECIFIED_FIELD,
  },
  isClosingAndSuperTarget: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'At least one exception is marked as closing and super target at once.',
    key: Messages.IS_CLOSING_AND_SUPER_TARGET,
  },
  departmentOnlyClosing: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'There is department-only exception with closing setting. This is not allowed.',
    key: Messages.DEPARTMENT_ONLY_CLOSING,
  },
  departmentOnlySuperTarget: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'There is department-only exception with super target setting. This is not allowed.',
    key: Messages.DEPARTMENT_ONLY_SUPER_TARGET,
  },
  outOfScope: {
    status: ValidatorMessageSeverity.WARNING,
    message:
      'Some exceptions contain stores, store types or departments that are not available in general configuration scope.',
    key: Messages.OUT_OF_SCOPE,
  },
} as const;

export type RecordOfListTypes = {
  [P in ConfigurationRuleScopeEntityType]: ListType<EntityTypeToResponseMap[P]>;
};

export const validateStoresAndDepartments = (
  scopeRules: Scope[],
  availableEntities: RecordOfListTypes,
  templateConfig: CalculationConfigurationResponse,
): ValidatorResponse<typeof section> => {
  const generalScope = scopeRules.find(sr => sr.priority === 0);

  const rsp: ValidatorResponse<typeof section> = {
    section,
    messages: [],
    info: { ...initialValidatorInfoStoresAndDepartments },
    overviewMessage: '',
  };

  const useIsClosing = templateConfig.useClosingStores;
  const useSuperTarget =
    templateConfig.superTargetRedistributionTypeId !== SuperTargetRedistributionType.DoNotUse;

  const isInsideGeneralScope = (
    entityId: number,
    entityType: ConfigurationRuleScopeEntityType,
  ): boolean => {
    const genScopeEntity = generalScope?.entities[entityType];
    if (genScopeEntity) {
      if (genScopeEntity.selectionOption === ConfigurationRuleScopeMode.All) return true;

      const isSelectedId = genScopeEntity.selectedIds.find(id => entityId === id) !== undefined;
      switch (genScopeEntity.selectionOption) {
        case ConfigurationRuleScopeMode.AllExcept:
          return !isSelectedId;
        case ConfigurationRuleScopeMode.Specified:
          return isSelectedId;
      }
    }
    return false;
  };

  const finalRegionsObject: Record<number, RegionResponse | undefined> = {};
  const finalDepartmentsObject: Record<number, DepartmentResponse | undefined> = {};
  const finalStoreTypesObject: Record<number, StoreTypeResponse | undefined> = {};
  const finalStoresObject: Record<number, StoreResponse | undefined> = {};

  availableEntities[ConfigurationRuleScopeEntityType.Regions].array.forEach(d => {
    if (isInsideGeneralScope(d.id, ConfigurationRuleScopeEntityType.Regions)) {
      finalRegionsObject[d.id] =
        availableEntities[ConfigurationRuleScopeEntityType.Regions].object[d.id];
    }
  });

  availableEntities[ConfigurationRuleScopeEntityType.Departments].array.forEach(d => {
    if (isInsideGeneralScope(d.id, ConfigurationRuleScopeEntityType.Departments)) {
      finalDepartmentsObject[d.id] =
        availableEntities[ConfigurationRuleScopeEntityType.Departments].object[d.id];
    }
  });

  const storeTypesInGeneralScope: Record<number, boolean | undefined> = availableEntities[
    ConfigurationRuleScopeEntityType.StoreTypes
  ].array.reduce<Record<number, boolean | undefined>>((p, c) => {
    p[c.id] = isInsideGeneralScope(c.id, ConfigurationRuleScopeEntityType.StoreTypes);
    return p;
  }, {});

  const availableStores = availableEntities[ConfigurationRuleScopeEntityType.Stores];
  availableStores.array.forEach(s => {
    const regionAvailable = finalRegionsObject[s.region?.id ?? 0] ?? false;
    const storeTypeId = s.storeType?.id ?? 0;
    if (regionAvailable) {
      if (
        isInsideGeneralScope(s.id, ConfigurationRuleScopeEntityType.Stores) &&
        storeTypesInGeneralScope[storeTypeId]
      ) {
        finalStoresObject[s.id] = availableStores.object[s.id];
      }

      if (!finalStoreTypesObject[storeTypeId]) {
        if (storeTypesInGeneralScope[storeTypeId]) {
          finalStoreTypesObject[storeTypeId] = s.storeType ?? undefined;
        }
      }
    }
  });

  debugLog('Final entities: ', finalStoresObject, finalStoreTypesObject, finalDepartmentsObject);

  let hasSource = false;
  let hasTarget = false;
  let exceptionCountEmptyConfiguration = 0;
  let exceptionCountAllTypeOnly = 0;
  let exceptionCountEmptySpecifiedScope = 0;
  let exceptionCountClosingSuperTarget = 0;
  let exceptionCountWrongScope = 0;

  const gc = <T extends ConfigurationRuleSetupType>(
    gen: Scope,
    exc: Scope,
    f: T,
  ): Scope['configuration'][T] => {
    const config1 = gen.configuration[f];
    const config2 = exc.configuration[f];

    if (config1 !== null && config2 !== null) return config2;
    if (config1 !== null && config2 === null) return config1;
    return config1;
  };

  const getFinalEntity = (id: number, entityType: ConfigurationRuleScopeEntityType) => {
    switch (entityType) {
      case ConfigurationRuleScopeEntityType.Regions:
        return finalRegionsObject[id];
      case ConfigurationRuleScopeEntityType.Stores:
        return finalStoresObject[id];
      case ConfigurationRuleScopeEntityType.StoreTypes:
        return finalStoreTypesObject[id];
      case ConfigurationRuleScopeEntityType.Departments:
        return finalDepartmentsObject[id];
    }
  };

  if (generalScope) {
    scopeRules.forEach(exc => {
      const isEnabled = gc(generalScope, exc, ConfigurationRuleSetupType.IsEnabled);

      if (gc(generalScope, exc, ConfigurationRuleSetupType.UseAsSource) && isEnabled) {
        hasSource = true;
      }

      if (gc(generalScope, exc, ConfigurationRuleSetupType.UseAsTarget) && isEnabled) {
        hasTarget = true;
      }

      const isClosing = exc.configuration[ConfigurationRuleSetupType.IsClosing] && useIsClosing;
      const isSuperTarget =
        exc.configuration[ConfigurationRuleSetupType.IsSuperTarget] && useSuperTarget;

      if (isClosing && isSuperTarget) {
        exceptionCountClosingSuperTarget++;
      }

      if (exc.id !== generalScope.id) {
        const isEmptyConfiguration =
          iterableConfigurationRuleSetupType.find(cr => exc.configuration[cr] !== null) ===
          undefined;
        if (isEmptyConfiguration) {
          exceptionCountEmptyConfiguration++;
        }

        let allOnly = true;
        let emptySpecified = false;
        let wrongScope = false;
        let departmentOnly = true;

        iterableConfigurationRuleScopeEntityType.forEach(et => {
          if (exc.entities[et].selectionOption !== ConfigurationRuleScopeMode.All) {
            if (et !== ConfigurationRuleScopeEntityType.Departments) {
              departmentOnly = false;
            }
            allOnly = false;
            if (exc.entities[et].selectedIds.length === 0) {
              emptySpecified = true;
            } else if (!wrongScope) {
              if (
                exc.entities[et].selectedIds.find(id => getFinalEntity(id, et) === undefined) !==
                undefined
              ) {
                wrongScope = true;
              }
            }
          }
        });

        if (departmentOnly) {
          if (isClosing) {
            addMessage(!hasSource, rsp.messages, MESSAGES.departmentOnlyClosing);
          }
          if (isSuperTarget) {
            addMessage(!hasSource, rsp.messages, MESSAGES.departmentOnlySuperTarget);
          }
        }

        // TODO: this is some weird eslint stuff, "allOnly" is definitely not only "true"
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (allOnly) {
          exceptionCountAllTypeOnly++;
        }

        // TODO: this is some weird eslint stuff, "emptySpecified" is definitely not only "false"
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (emptySpecified) {
          exceptionCountEmptySpecifiedScope++;
        }

        // TODO: this is some weird eslint stuff, "emptySpecified" is definitely not only "false"
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (wrongScope) {
          exceptionCountWrongScope++;
        }
      }
    });
  }

  addMessage(!hasSource, rsp.messages, MESSAGES.noUseAsSource);
  addMessage(!hasTarget, rsp.messages, MESSAGES.noUseAsTarget);
  addMessage(
    exceptionCountEmptySpecifiedScope > 0,
    rsp.messages,
    MESSAGES.emptySpecifiedFieldException,
  );
  addMessage(exceptionCountClosingSuperTarget > 0, rsp.messages, MESSAGES.isClosingAndSuperTarget);
  addMessage(exceptionCountAllTypeOnly > 0, rsp.messages, MESSAGES.noSubScopeInException);
  addMessage(
    exceptionCountEmptyConfiguration > 0,
    rsp.messages,
    MESSAGES.noConfigurationInException,
  );
  addMessage(exceptionCountWrongScope > 0, rsp.messages, MESSAGES.outOfScope);

  rsp.info.exceptionCount = scopeRules.length - 1;
  rsp.info.isClosingUsed = useIsClosing;
  rsp.info.isSuperTargetUsed = useSuperTarget;

  return rsp;
};
