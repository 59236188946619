import React, { useCallback, useMemo } from 'react';
import { Slider } from 'antd';
import { styled } from 'styled-components';
import { SliderMarks, SliderTooltipProps } from 'antd/es/slider';

import { DateRangeData } from './dailyTransactionsChartLib';

const ChartSliderRow = styled.div`
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
`;

interface SkuDailyTransactionsChartSliderProps {
  dateRangeData: DateRangeData;
  setDateRangeData: React.Dispatch<React.SetStateAction<DateRangeData>>;
}

const SkuDailyTransactionsChartSlider: React.FC<SkuDailyTransactionsChartSliderProps> = ({
  dateRangeData,
  setDateRangeData,
}) => {
  const { sliderMin, sliderMax, sliderValue, sliderMarks } = useMemo(() => {
    const sliderMax = dateRangeData.data.max.diff(dateRangeData.data.min, 'day');
    const sliderValueBottom = dateRangeData.chart.min.diff(dateRangeData.data.min, 'day');
    const sliderValueTop = dateRangeData.chart.max.diff(dateRangeData.data.min, 'day');

    const sliderMarks: SliderMarks = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '0': dateRangeData.data.min.format('DD MMM YYYY'),
      [sliderMax.toString()]: {
        style: {
          textWrap: 'nowrap',
        },
        label: dateRangeData.data.max.format('DD MMM YYYY'),
      },
      [sliderValueBottom.toString()]: dateRangeData.chart.min.format('DD MMM YYYY'),
      [sliderValueTop.toString()]: {
        style: {
          textWrap: 'nowrap',
        },
        label: dateRangeData.chart.max.format('DD MMM YYYY'),
      },
    };

    return {
      sliderMin: 0,
      sliderMax,
      sliderValue: [sliderValueBottom, sliderValueTop],
      sliderMarks,
    };
  }, [
    dateRangeData.chart.max,
    dateRangeData.chart.min,
    dateRangeData.data.max,
    dateRangeData.data.min,
  ]);

  const sliderChangeHandler = useCallback(
    (value: number[]) => {
      if (value.length === 2) {
        const minOffset = value[0];
        const maxOffset = value[1];

        setDateRangeData(prev => {
          return {
            ...prev,
            chart: {
              min: prev.data.min.add(minOffset, 'day'),
              max: prev.data.min.add(maxOffset, 'day'),
            },
          };
        });
      }
    },
    [setDateRangeData],
  );

  const sliderTooltip: SliderTooltipProps = useMemo(
    () => ({
      formatter: (value: number | undefined) => {
        return value !== undefined
          ? dateRangeData.data.min.add(value, 'day').format('DD MMM YYYY')
          : '';
      },
    }),
    [dateRangeData.data.min],
  );

  return (
    <ChartSliderRow>
      <Slider
        key={sliderValue.join('-')}
        marks={sliderMarks}
        range={{ draggableTrack: true }}
        min={sliderMin}
        max={sliderMax}
        defaultValue={sliderValue}
        onChangeComplete={sliderChangeHandler}
        tooltip={sliderTooltip}
      />
    </ChartSliderRow>
  );
};

export default SkuDailyTransactionsChartSlider;
