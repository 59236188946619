import React, { useCallback } from 'react';
import { IconButton, IconButton_IconSizes } from '@ydistri/ds';
import { MdClose } from 'react-icons/md';
import { resetSkuNotFound, setProductId } from '../../../detailSlice';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useDetail } from '../../../hooks/useDetail';
import { NO_VALUE } from '../../../../../lib/utils/utilsTypes';

const closeIcon = <MdClose size={IconButton_IconSizes.small} />;
const SKU_PARAM_NAME: string = 'sku';

const ClearSkuNotFoundAction: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { skuSearchId } = useDetail('skuSearchId');

  const clearParameter = useCallback(
    (parameterName: string) => {
      if (searchParams.has(parameterName)) {
        setSearchParams(prevParams => {
          prevParams.delete(parameterName);
          return prevParams;
        });
      }
    },
    [searchParams, setSearchParams],
  );

  const clickHandler = useCallback(() => {
    clearParameter(SKU_PARAM_NAME);
    if (skuSearchId === undefined) {
      clearParameter('product');
      dispatch(setProductId(NO_VALUE));
    } else {
      dispatch(resetSkuNotFound());
    }
  }, [clearParameter, dispatch, skuSearchId]);

  return (
    <IconButton
      icon={closeIcon}
      size="small"
      onClick={clickHandler}
      data-testid="clear-sku-not-found-button"
    />
  );
};

export default ClearSkuNotFoundAction;
