import { DropdownProps, Select, SelectProps, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useGetTemplatesQuery } from '../../apis/apiApplication';
import { getApiHeader } from '../../apis/getApiHeader';
import { API } from '../../swagger/collections';
import { skipToken } from '@reduxjs/toolkit/query';
import { styled, css } from 'styled-components';
import { SearchableSelectOptionType, selectFilterHandler } from '@ydistri/utils';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { COLORS, computeRemSize, IconButton_IconSizes, Column, Flex, Button } from '@ydistri/ds';
import { MdAdd } from 'react-icons/md';

const createIcon = <MdAdd size={IconButton_IconSizes.small} />;
const dropdownAlign: DropdownProps['align'] = { offset: [0, -6] };
const selectWidth = computeRemSize(500);

type SelectVariant = 'default' | 'grey';

interface StyledSelectProps {
  $variant?: SelectVariant;
}

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: ${computeRemSize(32)};
  font-size: ${computeRemSize(14)} !important;
  gap: 0.5rem;
  border-bottom: 1px solid ${COLORS.GREY_MEDIUM2};
`;

const OptionLeft = styled.div`
  flex-basis: 3.5rem;
  text-align: right;
  padding-right: 0.25rem;
  min-width: 3.5rem;
`;

const OptionTitle = styled.div`
  padding-left: 0.25rem;
  max-width: calc(${selectWidth} - 7rem);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;

const StyledSelect = styled(Select<number, DefaultOptionType>)<StyledSelectProps>`
  height: ${computeRemSize(40)} !important;
  width: ${selectWidth} !important;

  &.ant-select {
    margin-left: 0 !important;
  }

  .ant-select-selector {
    height: ${computeRemSize(40)} !important;
    font-size: ${computeRemSize(14)} !important;
    border-color: ${p => p.theme.colors.border} !important;
    ${p =>
      p.$variant === 'grey' &&
      css`
        background-color: ${p => p.theme.colors.pageBackground} !important;
      `}
  }

  .ant-select-selection-item {
    line-height: ${computeRemSize(16)} !important;
    display: flex !important;
    align-items: center !important;

    ${OptionRow} {
      border-bottom: 0;
    }
  }

  .ant-select-selection-search {
    display: flex !important;
    align-items: center !important;
  }

  .ant-select-selection-search-input {
    font-size: ${computeRemSize(14)} !important;
  }
`;

interface TemplateSelectProps {
  templateId?: number | null;
  createButtonAction?: () => void;
  variant?: SelectVariant;
  onChange: (value: number) => void;
  loading?: boolean;
  templateIdsToRemove?: number[];
  placeholder?: React.ReactNode;
}

export const TemplateSelect: React.FC<TemplateSelectProps> = ({
  onChange,
  createButtonAction,
  variant = 'default',
  templateId,
  loading = false,
  templateIdsToRemove,
  placeholder,
}) => {
  const { data: templates, isFetching: isTemplatesLoading } = useGetTemplatesQuery(
    getApiHeader(API, 'Project-Code') ?? skipToken,
  );

  const options: SearchableSelectOptionType[] = useMemo(() => {
    if (templates) {
      return templates
        .filter(t => (templateIdsToRemove ? !templateIdsToRemove.includes(t.id) : true))
        .map(t => ({
          value: t.id,
          label: (
            <OptionRow>
              <OptionLeft>#{t.id}</OptionLeft>
              <OptionTitle title={t.title}>{t.title}</OptionTitle>
            </OptionRow>
          ),
          searchableString: t.title,
        }));
    }
    return [];
  }, [templates, templateIdsToRemove]);

  /*
   * in case template (currently selected in this TemplateSelect) is deleted, we need to also change it here and invoke "onChange" callback
   */
  useEffect(() => {
    if (templateId && templates) {
      const template = templates.find(t => t.id === templateId);
      if (!template) {
        onChange(templates[0].id);
      }
    }
  }, [templates, templateId, onChange]);

  const templateSelectStyle = useMemo(() => ({ margin: '0.5rem' }), []);

  const dropdownStyle = useMemo(
    () => ({
      borderLeft: `${computeRemSize(1)} solid ${COLORS.BORDER}`,
      borderRight: `${computeRemSize(1)} solid ${COLORS.BORDER}`,
      borderBottom: `${computeRemSize(1)} solid ${COLORS.BORDER}`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      background: variant === 'grey' ? COLORS.PAGE_BACKGROUND : 'white',
      boxShadow: 'none',
    }),
    [variant],
  );

  const dropdownRender: SelectProps['dropdownRender'] = useCallback(
    (origin: React.ReactElement) => {
      return (
        <Column $gap="0.5rem">
          {origin}
          {createButtonAction && (
            <Flex $padding={1}>
              <Button
                $textVariant="semibold"
                icon={createIcon}
                type="primary"
                onClick={createButtonAction}
              >
                Create template
              </Button>
            </Flex>
          )}
        </Column>
      );
    },
    [createButtonAction],
  );

  return (
    <Spin spinning={isTemplatesLoading}>
      <StyledSelect
        $variant={variant}
        value={templateId}
        onChange={onChange}
        options={options}
        showSearch={true}
        variant="outlined"
        size="large"
        style={templateSelectStyle}
        filterOption={selectFilterHandler}
        dropdownAlign={dropdownAlign}
        dropdownStyle={dropdownStyle}
        dropdownRender={dropdownRender}
        listHeight={window.innerHeight * 0.6} //does not work right after resizing window
        data-testid="template-select"
        loading={loading}
        disabled={loading}
        placeholder={placeholder}
      />
    </Spin>
  );
};
