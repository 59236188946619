import { styled } from 'styled-components';
import { COLORS, computeRemSize, SectionBase } from '@ydistri/ds';

export const ValidatorButton = styled(SectionBase)`
  width: 125px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1rem;
  gap: 0.5rem;
  height: ${computeRemSize(32)};
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;
`;

export const StyledModal = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 1rem;
  right: 1.5rem;
  font-size: 1rem;
  color: ${COLORS.COLOR_DARK};
`;
