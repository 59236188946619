import { useSelector } from 'react-redux';
import { useMemo } from 'react';

interface SliderState {
  leftMenuAutomaticallyCollapsed: boolean;
  leftMenuManuallyCollapsed: boolean;
  isLeftMenuCollapsed: boolean;
  isLeftMenuWithContent: boolean;
}

export function useSider(): SliderState {
  const leftMenuAutomaticallyCollapsed = useSelector(
    state => state.menuReducer.leftMenuAutomaticallyCollapsed,
  );
  const leftMenuManuallyCollapsed = useSelector(
    state => state.menuReducer.leftMenuManuallyCollapsed,
  );
  const items = useSelector(state => state.menuReducer.items);

  const hasItems = items.length > 0;

  return useMemo(
    () => ({
      leftMenuAutomaticallyCollapsed,
      leftMenuManuallyCollapsed,
      isLeftMenuCollapsed: leftMenuAutomaticallyCollapsed || leftMenuManuallyCollapsed,
      isLeftMenuWithContent: hasItems,
    }),
    [leftMenuAutomaticallyCollapsed, leftMenuManuallyCollapsed, hasItems],
  );
}
