import React, { useEffect } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCategoryTreeQuery } from './apiCategoryTree';
import TreeCategory from './TreeCategory';
import { Spin } from 'antd';
import {
  computeRemSize,
  SectionHeaderWithActionsWrapper,
  SectionFixed,
  SectionHeader,
} from '@ydistri/ds';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOpenedCategory, setSelectedCategory } from './categoryTreeSlice';

interface CategoryTreeProps {
  displayExceptions?: boolean;
  onCategoryChange?: () => void;
  searchParamModifier?: (value: string) => string;
}

const CategoryTree: React.FC<CategoryTreeProps> = ({
  displayExceptions = true,
  onCategoryChange,
  searchParamModifier,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { slug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (slug) {
      const slugParts = slug.split('-');
      let partialSlug = '';
      slugParts.forEach(slugPart => {
        partialSlug += slugPart;
        dispatch(setOpenedCategory({ categorySlug: partialSlug, opened: true }));
        partialSlug += '-';
      });

      dispatch(setSelectedCategory(slug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]); //slug is not a dependency

  const { data: categories, isFetching } = useGetCategoryTreeQuery(templateOrCalculation);

  return (
    <SectionFixed $width={computeRemSize(240)}>
      <SectionHeaderWithActionsWrapper>
        <SectionHeader data-type="Sec">Categories</SectionHeader>
      </SectionHeaderWithActionsWrapper>
      &nbsp;
      <Spin spinning={isFetching}>
        {categories && (
          <TreeCategory
            onCategoryChange={onCategoryChange}
            searchParamModifier={searchParamModifier}
            displayExceptions={displayExceptions}
            categoryId={1}
          />
        )}
      </Spin>
    </SectionFixed>
  );
};

export default CategoryTree;
