import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';

export interface EvaluationSliceState {
  departmentId?: number;
  clusterType: CalculationStatisticClusterType;
}

const initialState: EvaluationSliceState = {
  departmentId: undefined,
  clusterType: CalculationStatisticClusterType.Store,
};

export const evaluationSlice = createSlice({
  name: 'evaluations',
  initialState,
  reducers: {
    setDepartmentId: (state, action: PayloadAction<number | undefined>) => {
      state.departmentId = action.payload;
    },
    setClusterType: (state, action: PayloadAction<CalculationStatisticClusterType>) => {
      state.clusterType = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setDepartmentId, setClusterType } = evaluationSlice.actions;

export const evaluationsReducer = evaluationSlice.reducer;
