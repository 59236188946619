import React from 'react';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { MdAppShortcut } from 'react-icons/md';
import { COLORS } from '../../../../styles/style';
import { ICON_SIZE_COMPACT, ICON_SIZE_NORMAL } from './calculationRowLib';

const PapCalculationIndicatorWrapper = styled.div`
  margin-left: auto;
  color: ${COLORS.PRIMARY};
`;

interface PapCalculationIndicatorProps {
  compact?: boolean;
}

const PapCalculationIndicator: React.FC<PapCalculationIndicatorProps> = ({ compact }) => {
  return (
    <PapCalculationIndicatorWrapper data-type="indicator-calculation-pap">
      <MdAppShortcut size={computeRemSize(compact ? ICON_SIZE_COMPACT : ICON_SIZE_NORMAL)} />
    </PapCalculationIndicatorWrapper>
  );
};

export default PapCalculationIndicator;
