import React, { useEffect, useMemo } from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';
import { ReduxState } from './store';
import Auth from './auth/Auth';
import { getItem, LSKey } from './store/localStore';
import ToastContainer from './components/toasts/ToastContainer';
import {
  ApplicationStatusOfflinePage,
  ApplicationStatusUpgradingPage,
  Global,
  logoY,
} from '@ydistri/ds';
import { useDispatch, useSelector } from 'react-redux';
import { signalrClient } from './signalr/client';
import { setStatus, setVersion, SignalSendCurrentFrontendStatus } from './store/appSlice';
import { AvailableModals, openModal } from './screens/Modals/modalSlice';
import { createDebugLog } from './lib/utils/logging';

const debugLog = createDebugLog('App');

export const auth = new Auth();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const appStatus = useSelector((state: ReduxState) => state.appReducer.status);
  const appVersionFromSignalr = useSelector((state: ReduxState) => state.appReducer.version);

  useEffect(() => {
    signalrClient.on('SendCurrentFrontendStatus', (response: SignalSendCurrentFrontendStatus) => {
      debugLog('Current frontend status changed! ' + response);
      dispatch(setStatus(response));
    });

    signalrClient.on('SendCurrentFrontendVersion', (response: string) => {
      debugLog('Current frontend version changed! ' + response);
      dispatch(setVersion(response));
    });

    return () => {
      signalrClient.off('SendCurrentFrontendStatus');
      signalrClient.off('SendCurrentFrontendVersion');
    };
  }, [dispatch]);

  useEffect(() => {
    const appVersionFromEnv = import.meta.env.VITE_REACT_APP_VERSION;
    debugLog(`App versions - ENV ${appVersionFromEnv} | SignalR: ${appVersionFromSignalr}`);
    if (appVersionFromSignalr && appVersionFromEnv) {
      if (appVersionFromEnv < appVersionFromSignalr) {
        dispatch(openModal({ type: AvailableModals.NEW_APP_VERSION, data: undefined }));
      }
    }
  }, [appVersionFromSignalr, dispatch]);

  const helmetLink: HelmetProps['link'] = useMemo(
    () => [
      {
        rel: 'icon',
        type: 'image/svg',
        href: logoY,
      },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Rubik:200,400,500,600&subset=latin-ext',
      },
    ],
    [],
  );

  const token = getItem(LSKey.TOKEN);
  auth.setAuthenticationHeaders(token ?? '');

  switch (appStatus) {
    case 'Upgrading':
      return <ApplicationStatusUpgradingPage />;
    case 'Offline':
      return <ApplicationStatusOfflinePage />;
    default:
      return (
        <Global>
          <Helmet titleTemplate="Ydistri - %s" defaultTitle="Ydistri" link={helmetLink} />
          <RouterProvider router={router} />
          <ToastContainer />
        </Global>
      );
  }
};

export default App;
