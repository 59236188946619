import React from 'react';
import { Column, computeRemSize, Row, SectionDivider, Text, Title } from '@ydistri/ds';
import ScopeEntitySelection from './ScopeEntitySelection';
import ScopeConfigurationView from './ScopeConfigurationView';
import { useScopeOptionsData } from './useScopeOptionsData';
import { ViewMode } from './scopeLib';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';
import { InfoIconTooltip } from '../../../components/icons/InfoIconTooltip';
import { closingTooltip, superTargetTooltip } from './Tooltips';
import ClosingSwitch from './ClosingSwitch';
import SuperTargetSelect from './SuperTargetSelect';
import ScopeMissingEntityView from './ScopeMissingEntityView';
import { useApplicationData } from '../../../hooks/useApplicationData';

interface ScopeDefinitionProps {
  scopeId: number;
  viewMode: ViewMode;
  disabled?: boolean;
}

const ScopeDefinition: React.FC<ScopeDefinitionProps> = ({
  scopeId,
  viewMode,
  disabled = false,
}) => {
  const { regionOptions, storeOptions, storeTypeOptions, departmentOptions, loading } =
    useScopeOptionsData(scopeId, viewMode);
  const { departments: hasDepartments } = useApplicationData();

  const inModal =
    viewMode === ViewMode.ADJUST_SCOPE_MODAL || viewMode === ViewMode.NEW_EXCEPTION_MODAL;

  return (
    <Column $gap="1rem">
      {inModal && <SectionDivider $modal />}
      <Column $gap="1rem" $alignItems="flex-end">
        <ScopeEntitySelection
          scopeId={scopeId}
          entityType={ConfigurationRuleScopeEntityType.Regions}
          options={regionOptions}
          loading={loading}
          viewMode={viewMode}
          disabled={disabled}
        />
        {inModal && <SectionDivider $modal />}
        <ScopeEntitySelection
          scopeId={scopeId}
          entityType={ConfigurationRuleScopeEntityType.StoreTypes}
          options={storeTypeOptions}
          loading={loading}
          viewMode={viewMode}
          disabled={disabled}
        />
        {inModal && <SectionDivider $modal />}
        <ScopeEntitySelection
          scopeId={scopeId}
          entityType={ConfigurationRuleScopeEntityType.Stores}
          options={storeOptions}
          loading={loading}
          viewMode={viewMode}
          disabled={disabled}
        />
        {inModal && <SectionDivider $modal />}
        {hasDepartments && (
          <ScopeEntitySelection
            scopeId={scopeId}
            entityType={ConfigurationRuleScopeEntityType.Departments}
            options={departmentOptions}
            loading={loading}
            viewMode={viewMode}
            disabled={disabled}
          />
        )}
        {viewMode === ViewMode.ADJUST_SCOPE_MODAL && (
          <ScopeMissingEntityView scopeId={scopeId} viewMode={viewMode} />
        )}
        <SectionDivider $modal={inModal} />
      </Column>
      {viewMode !== ViewMode.ADJUST_SCOPE_MODAL && (
        <ScopeConfigurationView
          scopeId={scopeId}
          viewMode={viewMode}
          // highlightError={viewMode === ViewMode.NEW_EXCEPTION_MODAL}
        />
      )}
      {viewMode === ViewMode.GENERAL_CONFIGURATION && (
        <>
          <SectionDivider />

          <Column $gap="0.5rem">
            <Title>Additional features</Title>
            <span> </span>
            <Row $minHeight="2rem" $gap="0.5rem" $alignItems="center" $flexWrap="wrap">
              <Row $minWidth={computeRemSize(20)}>
                <InfoIconTooltip content={closingTooltip} />
              </Row>
              <Row $minWidth={computeRemSize(210)}>
                <Text $size="large" $semiBold>
                  Use &quot;Closing store&quot;
                </Text>
              </Row>
              <Row $minWidth={computeRemSize(100)}>
                <ClosingSwitch />
              </Row>
            </Row>

            <Row $minHeight="2rem" $gap="0.5rem" $alignItems="center" $flexWrap="wrap">
              <Row $minWidth={computeRemSize(20)}>
                <InfoIconTooltip content={superTargetTooltip} />
              </Row>
              <Row $minWidth={computeRemSize(210)}>
                <Text $size="large" $semiBold>
                  Use &quot;Super target&quot; settings
                </Text>
              </Row>
              <Row $minWidth={computeRemSize(100)}>
                <SuperTargetSelect />
              </Row>
            </Row>
          </Column>
        </>
      )}
    </Column>
  );
};

export default ScopeDefinition;
