import {
  ConfigurationDataType,
  ConfigurationFieldType,
  ConfigurationSectionType,
} from '@ydistri/api-sdk';

export const isEntityListConfiguration = (
  fieldType: ConfigurationFieldType,
  dataType?: ConfigurationDataType,
): boolean =>
  dataType === ConfigurationDataType.SelectBoxMinLayerList ||
  fieldType === ConfigurationFieldType.PsMinLayerList ||
  fieldType === ConfigurationFieldType.PtMinLayerList;

export const isTargetConfiguration = (sectionType: ConfigurationSectionType): boolean =>
  sectionType === ConfigurationSectionType.PotentialTarget;
