import { useMemo } from 'react';
import { PartialExceptionExpanded } from './categoryExceptionsModalLib';
import {
  categoryStringRoute,
  CategoryTreeNode,
  useGetCategoryTreeQuery,
} from '../../../components/global/CategoryTree/apiCategoryTree';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';

export const useExceptions = (): PartialExceptionExpanded[] => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories } = useGetCategoryTreeQuery(templateOrCalculation);

  return useMemo(() => {
    if (categories) {
      const e: PartialExceptionExpanded[] = [];

      const findExceptionCategories = (
        node: CategoryTreeNode | undefined,
        e: PartialExceptionExpanded[],
      ) => {
        if (node === undefined) return;
        if (node.exceptions.length > 0) {
          e.push(
            ...node.exceptions.map(e => ({
              ...e,
              category: node,
              titleFull: categoryStringRoute(node),
            })),
          );
        }

        node.children.forEach(child => {
          if (
            (categories[child]?.childExceptionCount ?? 0) > 0 ||
            (categories[child]?.exceptions.length ?? 0) > 0
          ) {
            findExceptionCategories(categories[child], e);
          }
        });
      };

      findExceptionCategories(categories[1], e);

      return e;
    }
    return [];
  }, [categories]);
};
