import React, { useCallback, useMemo } from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const onClickHandler = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const extra = useMemo(
    () => (
      <Button type="primary" onClick={onClickHandler}>
        Back Home
      </Button>
    ),
    [onClickHandler],
  );

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you requested doesn't exist."
      extra={extra}
    />
  );
};

export default NotFound;
