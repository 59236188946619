import { TLineType } from '../../../../../lib/charts/saleChartsTypes';

export enum ContentDataType {
  DETAIL,
  TOTAL,
  SALE_USED_FOR_FORECAST,
}

export interface TooltipContentSection {
  columnNames: string[];
  data: ContentData[];
}

export interface ContentData {
  label: string;
  primaryColumnValue: string;
  secondaryColumnValue?: string;
  type: ContentDataType;
  line?: TLineType;
}

export interface TooltipPosition {
  visible: boolean;
  top: string | number;
  left: string | number;
  right: string | number;
}
