import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCalculationConfigurationQuery } from '../../../apis/apiGlobalConfiguration';
import { useMemo } from 'react';
import { getUseClosingBoolean, getUseSuperTargetBoolean } from './storesLib';

export interface CalculationConfiguration {
  useClosing: boolean;
  useSuperTarget: boolean;
}

export function useCalculationConfiguration(): CalculationConfiguration {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationConfiguration } =
    useGetCalculationConfigurationQuery(templateOrCalculation);

  return useMemo(
    () => ({
      useClosing: getUseClosingBoolean(calculationConfiguration?.useClosingStores),
      useSuperTarget: getUseSuperTargetBoolean(
        calculationConfiguration?.superTargetRedistributionTypeId,
      ),
    }),
    [
      calculationConfiguration?.useClosingStores,
      calculationConfiguration?.superTargetRedistributionTypeId,
    ],
  );
}
