import { ActionsBar, Section } from '@ydistri/ds';
import React, { useEffect, useMemo } from 'react';

import { getCategoryDetailType, getProductDetailType } from '../../detailLib';
import { CategoryDetailType, DetailType, ProductDetailType, UrlParams } from '../../detailTypes';
import useCategoryId from '../../../../hooks/useCategoryId';
import EntitiesTable from './components/EntitiesTable';
import RegionSelectorController from './components/RegionSelector/RegionSelectorController';
import StoreSelectorController from './components/StoreSelector/StoreSelectorController';
import DetailSkuAttributesController from './components/DetailSKUAttributes/DetailSkuAttributesController';
import useDetailScreenType from '../../hooks/useDetailScreenType';
import ClearSkuNotFoundAction from './components/ClearSkuNotFoundAction';
import { useDetail } from '../../hooks/useDetail';
import { useGetSingleStoreQuery } from '../../apiDetail';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import LoadingDataOverlay from '../../../../components/global/LoadingDataOverlay/LoadingDataOverlay';
import { NO_VALUE } from '../../../../lib/utils/utilsTypes';
import { styled } from 'styled-components';

//Makes spaces for the Loading data spinner so it not overlaps an empty section to empty space
const LoadingDataWrapper = styled.div`
  min-height: 60px;
`;

const EntityTablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
`;

const DetailContentHeaderSection: React.FC = () => {
  const selectedCategoryId = useCategoryId();
  const dispatch = useDispatch();
  const { storeId, skuId, productId, regionId, skuSearchId, isLoadingSalesData } = useDetail(
    'storeId',
    'skuId',
    'productId',
    'regionId',
    'skuSearchId',
    'isLoadingSalesData',
  );
  const { data: storeEntity } = useGetSingleStoreQuery(storeId);
  const [, setSearchParams] = useSearchParams();

  /**
   * If the store is not in the region, clear the storeId
   * Happens when store is selected and different region is selected
   */
  useEffect(() => {
    if (regionId !== NO_VALUE && storeId !== NO_VALUE && storeEntity) {
      if (storeEntity.region?.id !== regionId) {
        setSearchParams(prevParams => {
          prevParams.delete(UrlParams.STORE);
          return prevParams;
        });
      }
    }
  }, [storeId, regionId, storeEntity, dispatch, setSearchParams]);

  const detailType = useDetailScreenType(productId, storeId, skuId);

  const categoryDetailType = useMemo(
    () => getCategoryDetailType(productId, regionId, storeId),
    [productId, regionId, storeId],
  );
  const productDetailType = useMemo(
    () => getProductDetailType(productId, regionId, storeId),
    [productId, regionId, storeId],
  );

  const detailTypeHeader = useMemo(() => {
    switch (detailType) {
      case DetailType.CATEGORY: {
        let prefix = '';
        switch (categoryDetailType) {
          case CategoryDetailType.DEFAULT:
            prefix = 'Detail';
            break;
          case CategoryDetailType.STORE:
            prefix = 'Store detail';
            break;
          case CategoryDetailType.REGION:
            prefix = 'Region detail';
            break;
          default:
            prefix = 'Detail';
        }
        return `${prefix} ${selectedCategoryId === 1 ? `(Company)` : `(Category)`}`;
      }
      case DetailType.SKU: {
        let suffix = 'loading...';
        if (skuId !== -1) {
          suffix = skuId.toString();
        }
        return `SKU detail - ${suffix}`;
      }
      case DetailType.PRODUCT: {
        switch (productDetailType) {
          case ProductDetailType.DEFAULT:
            return 'Product detail (company)';
          case ProductDetailType.REGION:
            return 'Product detail (region)';
          default:
            return 'Product detail';
        }
      }
      case DetailType.SKU_NOT_FOUND: {
        const message = 'SKU detail - non-existing SKU';
        if (skuSearchId !== undefined) {
          return `${message}: ${skuSearchId}`;
        } else {
          return message;
        }
      }
    }
  }, [categoryDetailType, detailType, productDetailType, selectedCategoryId, skuId, skuSearchId]);

  const headerActions = useMemo(() => {
    const showRegionSelector = ![
      DetailType.PRODUCT,
      DetailType.SKU,
      DetailType.SKU_NOT_FOUND,
    ].includes(detailType);
    const showStoreSelector = ![
      DetailType.PRODUCT,
      DetailType.SKU,
      DetailType.SKU_NOT_FOUND,
    ].includes(detailType);

    const showResetSkuNotFound = [DetailType.SKU_NOT_FOUND].includes(detailType);

    return (
      <ActionsBar useDivider={true}>
        {showRegionSelector && <RegionSelectorController />}
        {showStoreSelector && <StoreSelectorController />}
        {showResetSkuNotFound && <ClearSkuNotFoundAction />}
      </ActionsBar>
    );
  }, [detailType]);

  return (
    <Section header={detailTypeHeader} $ratio={0} headerActions={headerActions}>
      {isLoadingSalesData ? (
        <LoadingDataWrapper>
          <LoadingDataOverlay active={true}></LoadingDataOverlay>
        </LoadingDataWrapper>
      ) : (
        <EntityTablesWrapper>
          <EntitiesTable />
          <DetailSkuAttributesController />
        </EntityTablesWrapper>
      )}
    </Section>
  );
};

export default DetailContentHeaderSection;
