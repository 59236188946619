import { SkuClassConfigurationResponse } from '@ydistri/api-sdk';
import {
  ValidatorMessage,
  ValidatorMessageSeverity,
  ValidatorResponse,
  ValidatorSection,
} from './validatorLib';
import { createDebugLog } from '../../../lib/utils/logging';
import { initialValidatorInfoSkuClasses } from './validatorInfoLib';
import { plural } from '@ydistri/utils';

const debugLog = createDebugLog('Validator', 'validateSkuClasses');

enum Messages {
  NO_ENABLED_SKU_CLASS,
  NO_USE_AS_SOURCE,
  NO_USE_AS_TARGET,
  ENABLED_BUT_NOT_USED,
}

const section = ValidatorSection.SKU_CLASSES;

type PartialMessage = Omit<ValidatorMessage, 'section'>;

const addMessage = (condition: boolean, messages: ValidatorMessage[], message: PartialMessage) => {
  if (!condition) return;
  messages.push({
    section,
    ...message,
  });
};

//: Record<string, PartialMessage | undefined>
const MESSAGES = {
  noEnabledStores: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'There is no enabled SKU class.',
    key: Messages.NO_ENABLED_SKU_CLASS,
  },
  noUseAsSource: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'There is no enabled SKU class that can be used as source.',
    key: Messages.NO_USE_AS_SOURCE,
  },
  noUseAsTarget: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'There is no enabled SKU class that can be used as target.',
    key: Messages.NO_USE_AS_TARGET,
  },
  enabledButNotUsed: {
    status: ValidatorMessageSeverity.WARNING,
    message: 'There is at least one SKU class that is enabled, but not used as source or target.',
    key: Messages.ENABLED_BUT_NOT_USED,
  },
} as const;

export const validateSkuClasses = (
  skuClassConfig: SkuClassConfigurationResponse[],
): ValidatorResponse<typeof section> => {
  debugLog('skuClassConfig', skuClassConfig);

  const rsp: ValidatorResponse<typeof section> = {
    section,
    messages: [],
    info: { ...initialValidatorInfoSkuClasses },
    overviewMessage: '',
  };

  const skuClassCount = skuClassConfig.length;

  skuClassConfig.forEach(skuClass => {
    if (skuClass.isEnabled) rsp.info.enabledCount++;
    if (skuClass.useAsSource && skuClass.isEnabled) rsp.info.useAsSourceCount++;
    if (skuClass.useAsTarget && skuClass.isEnabled) rsp.info.useAsTargetCount++;
    if (!skuClass.useAsSource && !skuClass.useAsTarget && skuClass.isEnabled)
      rsp.info.enabledButNotUsedCount++;
  });

  addMessage(rsp.info.enabledCount === 0, rsp.messages, MESSAGES.noEnabledStores);
  addMessage(
    rsp.info.enabledCount > 0 && rsp.info.useAsSourceCount === 0,
    rsp.messages,
    MESSAGES.noUseAsSource,
  );
  addMessage(
    rsp.info.enabledCount > 0 && rsp.info.useAsTargetCount === 0,
    rsp.messages,
    MESSAGES.noUseAsTarget,
  );
  addMessage(rsp.info.enabledButNotUsedCount > 0, rsp.messages, MESSAGES.enabledButNotUsed);

  const infoMessages: string[] = [];
  if (skuClassCount === rsp.info.enabledCount) {
    if (skuClassCount > 1) {
      infoMessages.push(`All ${skuClassCount} SKU classes are enabled.`);
    }
  } else {
    infoMessages.push(
      `${rsp.info.enabledCount} of ${skuClassCount} SKU classes ${plural(
        rsp.info.enabledCount,
        'is',
        'are',
      )} enabled.`,
    );
  }

  rsp.overviewMessage = infoMessages.join(' ');

  return rsp;
};
