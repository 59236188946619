import { IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { MdPerson, MdPersonOff } from 'react-icons/md';
import CalculationAction from './CalculationAction';

interface CurrentUserCalculationsActionProps {
  disabled?: boolean;
  loading?: boolean;
  tooltip: string;
  value: boolean;
  onClick: () => void;
  size?: IconButtonSize;
}

const CurrentUserCalculationsAction: React.FC<CurrentUserCalculationsActionProps> = ({
  value,
  size = 'normal',
  ...rest
}) => {
  const iconHide = useMemo(() => <MdPersonOff size={IconButton_IconSizes[size]} />, [size]);
  const iconShow = useMemo(() => <MdPerson size={IconButton_IconSizes[size]} />, [size]);

  return (
    <CalculationAction
      size={size}
      icon={value ? iconHide : iconShow}
      tooltipPosition="left"
      checked={value}
      {...rest}
    />
  );
};

export default CurrentUserCalculationsAction;
