export const DEFAULT_SALE_MONTH_COUNT = 36;

export enum DetailType {
  SKU,
  CATEGORY,
  PRODUCT,
  SKU_NOT_FOUND,
}

export enum CategoryDetailType {
  DEFAULT,
  STORE,
  REGION,
}

export enum ProductDetailType {
  DEFAULT,
  REGION,
}

export enum UrlParams {
  STORE = 'store',
  PRODUCT = 'product',
  REGION = 'region',
  SKU = 'sku',
  SEARCH = 'search',
}
