import React, {
  ClipboardEvent,
  ClipboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  computeRemSize,
  GenericConfirmationModal,
  INFINITE_PAGE_SIZE,
  InfiniteScrollTable,
  Row,
  Section,
  Text,
} from '@ydistri/ds';
import { styled } from 'styled-components';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { ColumnsType } from 'antd/es/table';
import { BrandConfigurationResponse } from '@ydistri/api-sdk';
import { useGetCalculationConfigurationQuery } from '../../../../apis/apiGlobalConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import {
  GetBrandConfigurationPayload,
  useGetBrandConfigurationQuery,
  useResetBrandConfigurationMutation,
} from '../apiBrands';
import { setSelectedBrandConfiguration } from '../brandsConfigurationSlice';
import BrandTypeOfException from './BrandTypeOfException';
import BrandConfigurationSwitch from './BrandConfigurationSwitch';
import SearchBox from '../../../../components/global/SearchBox/SearchBox';

const BrandExceptionsSection = styled(Section)`
  width: 55%;
  min-width: 55%;
`;

export type BrandConfigurationParams = GetBrandConfigurationPayload;

const BrandsTable = styled(
  InfiniteScrollTable<BrandConfigurationResponse, BrandConfigurationParams>,
)`
  .ant-table-row {
    cursor: pointer;
  }
`;

const BrandConfigurations: React.FC = () => {
  const dispatch = useDispatch();
  const selectedBrandConfiguration = useSelector(
    (state: ReduxState) => state.brandConfiguration.selectedBrandConfiguration,
  );

  const templateOrCalculation = useTemplateOrCalculation();

  const [showModal, setShowModal] = useState(false);
  const [pastedBrands, setPastedBrands] = useState<string[]>([]);
  const [params, setParams] = useState<BrandConfigurationParams>({
    templateOrCalculation,
    skip: 0,
    top: INFINITE_PAGE_SIZE,
  });

  useEffect(() => {
    setParams(p => ({
      ...p,
      pastedBrands,
    }));
  }, [pastedBrands]);

  const { data: calculationConfiguration, isFetching: isCalcConfigFetching } =
    useGetCalculationConfigurationQuery(templateOrCalculation);
  const { data: brandConfigurations, isFetching: isBrandConfigFetching } =
    useGetBrandConfigurationQuery(params);
  const [searchValue, setSearchValue] = useState<string>();
  const [resetConfiguration, { isLoading: isResetRunning }] = useResetBrandConfigurationMutation();

  const getRowClassName = useCallback(
    (brand: BrandConfigurationResponse) => {
      return brand.id === selectedBrandConfiguration?.id ? 'selected' : '';
    },
    [selectedBrandConfiguration?.id],
  );

  const hasExceptions = useMemo(
    () => (brandConfigurations?.filter(b => b.isConfigured).length ?? 0) > 0,
    [brandConfigurations],
  );

  const brandConfigurationColumns: ColumnsType<BrandConfigurationResponse> = useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: computeRemSize(50),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: computeRemSize(250),
      },
      {
        title: 'Product count',
        dataIndex: 'productCount',
        key: 'productCount',
        width: computeRemSize(100),
        align: 'right',
      },
      {
        title: calculationConfiguration?.brandsAllEnabled
          ? 'Create exception (disable)'
          : 'Create exception (enable)',
        dataIndex: 'isConfigured',
        key: 'isConfigured',
        render: (isConfigured: boolean | null, record: BrandConfigurationResponse) => {
          return (
            <BrandConfigurationSwitch
              disabled={templateOrCalculation.type === 'Calculation'}
              configured={record.isConfigured}
              brandId={record.id}
              calculationBrandConfiguration={calculationConfiguration?.brandsAllEnabled ?? true}
            />
          );
        },
      },
    ],
    [calculationConfiguration?.brandsAllEnabled, templateOrCalculation.type],
  );

  const toggleModal = useCallback(() => setShowModal(p => !p), []);

  const resetExceptionsCallback = useCallback(() => {
    resetConfiguration(templateOrCalculation);
    toggleModal();
  }, [resetConfiguration, templateOrCalculation, toggleModal]);

  const resetExceptionsButton = useMemo(() => {
    if (templateOrCalculation.type === 'Calculation' || !hasExceptions) return null;
    return (
      <Row $alignSelf="flex-end">
        <Button
          loading={isResetRunning}
          $colorVariant="ghostDanger"
          onClick={toggleModal}
          disabled={isResetRunning}
        >
          Reset exceptions
        </Button>
      </Row>
    );
  }, [hasExceptions, templateOrCalculation.type, toggleModal, isResetRunning]);

  const pasteHandler: ClipboardEventHandler<HTMLTextAreaElement | HTMLDivElement> = useCallback(
    (event: ClipboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
      const textData = event.clipboardData?.getData('text/plain');
      if (textData) {
        // Split the text by newlines to get each row
        const rows = textData.split('\n').filter(Boolean);
        const brands: string[] = [];

        // Process each row to remove unwanted spaces and add to brands
        rows.forEach(row => {
          const cleanedRow = row.trim(); // remove leading/trailing spaces
          if (cleanedRow) {
            brands.push(cleanedRow);
          }
        });

        // Log or set the parsed data
        console.log({ brands });
        setPastedBrands(brands);
      }
    },
    [],
  );

  const clearHandler = useCallback(() => {
    setPastedBrands([]);
  }, []);

  const headerActions = useMemo(
    () => (
      <Row $gap="1rem" $justifyContent="space-between" $width="100%">
        <Row $gap="1rem">
          <BrandTypeOfException hasException={hasExceptions} />
        </Row>
        <Row $gap="1rem" $alignItems="center">
          {pastedBrands.length > 0 ? (
            <Text>{pastedBrands.length} filtered </Text>
          ) : (
            resetExceptionsButton
          )}
          <SearchBox onSearch={setSearchValue} onPaste={pasteHandler} onClear={clearHandler} />
        </Row>
      </Row>
    ),
    [hasExceptions, pastedBrands.length, resetExceptionsButton, pasteHandler, clearHandler],
  );

  const tableOnRow = useCallback(
    (record: BrandConfigurationResponse) => {
      return {
        onClick: () => {
          dispatch(setSelectedBrandConfiguration(record));
        },
      };
    },
    [dispatch],
  );

  return (
    <>
      <BrandExceptionsSection
        header="Brands"
        headerActions={headerActions}
        headerActionsIncludeMarginBottom={false}
        $ratio={1}
      >
        <BrandsTable
          id="brand-configurations"
          searchValue={searchValue}
          height={`calc(100vh - ${computeRemSize(300)})`}
          rowKey="id"
          columns={brandConfigurationColumns}
          dataSource={brandConfigurations}
          loading={isCalcConfigFetching || isBrandConfigFetching}
          setParams={setParams}
          onRow={tableOnRow}
          rowClassName={getRowClassName}
        />
      </BrandExceptionsSection>
      {showModal && (
        <GenericConfirmationModal
          data-testid="ResetBrandExceptionsConfirmationModal"
          title="Reset all exceptions"
          message={`All exceptions ${
            searchValue ? `(not only filtered)` : ''
          } will be reset to the default configuration.`}
          onConfirmed={resetExceptionsCallback}
          onCanceled={toggleModal}
          running={isResetRunning}
        />
      )}
    </>
  );
};

export default BrandConfigurations;
