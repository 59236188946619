import { Scope } from './scopeLib';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetConfigurationRulesQuery } from './apiScopes';

export const useMainScope = (): Scope | undefined => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { scopes: scope } = useGetConfigurationRulesQuery(templateOrCalculation, {
    selectFromResult: ({ data }) => {
      return { scopes: data?.find(s => s.priority === 0) };
    },
  });

  return scope;
};
