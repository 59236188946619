import React, { useCallback, useMemo, useState } from 'react';
import { DetailSaleRowData } from '../../../../../../lib/sales/salesTypes';
import { ScrolledTable, YColumnsType } from '@ydistri/ds';
import { styled } from 'styled-components';
import { TablePaginationConfig } from 'antd/lib';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { extractData } from '../../../../../../lib/tables/tablesLib';

/* What level of data to display */
enum DataLevel {
  YEARS_SUMMARY = 1,
  MONTHS_ONLY = 2,
}

interface DetailSalesTableProps {
  loadingSalesData?: boolean;
  height?: string;
  columns: YColumnsType<DetailSaleRowData>[];
  data: DetailSaleRowData[];
}

const DetailSalesTable = styled(ScrolledTable<DetailSaleRowData>)`
  .yearSummary {
    font-weight: bold;
    cursor: pointer;
  }
`;

const DetailSalesTableComponent: React.FC<DetailSalesTableProps> = ({
  height = '480px',
  columns,
  data,
}) => {
  const [displayYearSummary, setDisplayYearSummary] = useState(true);

  const tableData = useMemo(
    () => extractData(data, displayYearSummary ? DataLevel.YEARS_SUMMARY : DataLevel.MONTHS_ONLY),
    [data, displayYearSummary],
  );

  const rowClassProvider = useCallback((record: DetailSaleRowData) => {
    let result = '';

    if (record.isFullYear) {
      result = 'yearSummary';
    }

    return result;
  }, []);

  const tableChangeHandler = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<DetailSaleRowData> | SorterResult<DetailSaleRowData>[],
    ) => {
      let switchFullYearOff = false;

      //process sorters if any
      const firstSorter = Array.isArray(sorter) ? sorter[0] : sorter;

      if (firstSorter.order) {
        switchFullYearOff = true;
      }

      //process filters if any
      Object.entries(filters).forEach(([, value]) => {
        if (value !== null) {
          switchFullYearOff = true;
        }
      });

      //set or remove full year
      if (switchFullYearOff) {
        setDisplayYearSummary(false);
      } else {
        setDisplayYearSummary(true);
      }
    },
    [],
  );

  const expandedProps = useMemo(() => {
    if (tableData.length > 0) {
      const firstItem = tableData[0];
      if (firstItem.isFullYear) {
        return {
          defaultExpandedRowKeys: [firstItem.month.getFullYear().toString()],
          expandRowByClick: true,
        };
      }
    }
  }, [tableData]);

  if (tableData.length > 0) {
    return (
      <DetailSalesTable
        columns={columns}
        dataSource={tableData}
        height={height}
        id="DetailSalesTable"
        size="small"
        rowClassName={rowClassProvider}
        expandable={expandedProps}
        onChange={tableChangeHandler}
      />
    );
  }
};

export default DetailSalesTableComponent;
