import React, { useCallback, useMemo } from 'react';
import { Column, Label, Row } from '@ydistri/ds';
import { styled } from 'styled-components';
import { Select } from 'antd';
import { InstructionType } from '../../../redistributionImportTypes';

interface YSelectProps<T> {
  id?: string;
  value?: T;
  onChange: (selectedValue: T) => void;
  width?: string;
}

const StyledSelect = styled(Select)<YSelectProps<InstructionType>>`
  //fill available space by default, if this was missing it would only take a few pixels.
  width: ${({ width }) => width ?? '100%'};
`;

/**
 * Select for choosing how the instruction for manual redistributions is given - either as quantity or description (like 'all' or 'everything above 5 quantities').
 * Uses event handler to pass the selected value to the parent component.
 * @param id
 * @param value
 * @param onChange
 * @param rest
 * @constructor
 */
const InstructionSelect: React.FC<YSelectProps<InstructionType>> = ({
  id,
  value,
  onChange,
  ...rest
}) => {
  const options = useMemo(() => {
    return [
      { label: 'Quantity', value: InstructionType.QUANTITY },
      { label: 'Description', value: InstructionType.DESCRIPTION },
      { label: 'Quantity with Description', value: InstructionType.QUANTITY_DESCRIPTION },
    ];
  }, []);

  const onSelectChange = useCallback(
    (value: unknown) => {
      const selectedValue = options.find(option => option.value === value)?.value;
      if (selectedValue) {
        onChange(selectedValue);
      }
    },
    [onChange, options],
  );

  return (
    <Row $alignItems="center" $gap="2rem">
      <Column $minWidth="13rem">
        <Label>Instruction is given as</Label>
      </Column>
      <Column $minWidth="30rem">
        <StyledSelect options={options} value={value} onChange={onSelectChange} id={id} {...rest} />
      </Column>
    </Row>
  );
};

export default InstructionSelect;
