import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import { useCallback, useMemo } from 'react';
import { getParserForContentType, markDuplicates } from './redistributionImportLib';
import useRedistributionImportKeys from './useRedistributionImportKeys';
import {
  setImportItems,
  setImportStep,
  setImportVerificationTimeStamp,
} from '../../../../Calculations/calculationsSlice';
import { InstructionType, ProcessingStep } from './redistributionImportTypes';
import {
  useCreateCalculationRedistributionImportMutation,
  usePutCalculationRedistributionImportItemsMutation,
} from './apiCalculationRedistributionImport';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { ApiOperationType } from '@ydistri/api-sdk';
import { addToast } from '@ydistri/utils';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { ErrorType } from '../../../../../apis/api';

export type ImportItemFunction = () => void;

export interface UseImportItemsData {
  isPending: boolean;
  callback: ImportItemFunction;
}

/**
 * Construct header of CSV file based on instruction type
 * @param instructionType
 */
const getCSVHeader = (instructionType: InstructionType) => {
  const headers = [
    'ProductIdentification',
    'SourceStoreIdentification',
    'TargetStoreIdentification',
  ];

  //Quantity column comes before Description column
  if (
    instructionType === InstructionType.QUANTITY ||
    instructionType === InstructionType.QUANTITY_DESCRIPTION
  ) {
    headers.push('Quantity');
  }

  if (
    instructionType === InstructionType.DESCRIPTION ||
    instructionType === InstructionType.QUANTITY_DESCRIPTION
  ) {
    headers.push('Description');
  }

  return headers.join(',');
};

/**
 * Hook provides a function to be used to pick up the pasted content, parse it, validate
 * what can be validated locally (duplicate rows, invalid quantity) and
 * submit valid data to backend for further validation and processing.
 */
const useImportRedistributionItems = (): UseImportItemsData => {
  const dispatch = useDispatch();
  const identificationData = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.identificationData,
  );
  const instructionSpec = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.instructionSpecification,
  );
  const importRawContent = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.inputContent,
  );
  const calculation = useTemplateOrCalculation();

  const [createCalculationRedistributionImport, createCalculationRedistributionImportStatus] =
    useCreateCalculationRedistributionImportMutation();

  const [putCalculationRedistributionImportItems, putCalculationRedistributionImportItemsStatus] =
    usePutCalculationRedistributionImportItemsMutation();

  const inputDataKeys = useRedistributionImportKeys();

  //handle Error from importing items - display message to the user and return process to the start
  const handleError = useCallback(
    (err: ErrorType) => {
      dispatch(
        addToast({
          message: `Import failed: ${err.response.data.Messages.join(', ')}`,
          isError: true,
        }),
      );
      dispatch(setImportStep({ stepIndex: ProcessingStep.IMPORT, stepRunning: false }));
    },
    [dispatch],
  );

  // Main callback to be returned to user
  const callback = useCallback(() => {
    if (importRawContent.length === 0) {
      return;
    }

    const parser = getParserForContentType('text/plain');
    if (!parser) {
      return;
    }

    const parsedRows = parser.parse(importRawContent, inputDataKeys);
    if (!(parsedRows.length > 0)) {
      return;
    }

    dispatch(setImportStep({ stepIndex: ProcessingStep.VALIDATION, stepRunning: true }));

    markDuplicates(parsedRows, [
      'productIdentifier',
      'sourceStoreIdentifier',
      'targetStoreIdentifier',
    ]);

    dispatch(setImportItems(parsedRows));

    const verificationTimeStamp = new Date().toISOString();

    const productIdentificationData = identificationData.find(
      identification => identification.type === 'Product',
    );
    const storeIdentificationData = identificationData.find(
      identification => identification.type === 'Store',
    );

    if (productIdentificationData && storeIdentificationData) {
      createCalculationRedistributionImport({
        calculationId: calculation.id,
        verificationTimeStamp,
        apiOperationType: ApiOperationType.Create,
        productIdentificationTypeId: productIdentificationData.value,
        storeIdentificationTypeId: storeIdentificationData.value,
      })
        .unwrap()
        .then(() => {
          dispatch(setImportVerificationTimeStamp(verificationTimeStamp));

          const header = getCSVHeader(instructionSpec);

          const itemsWithHeader = `${header}\n${importRawContent}`;
          const encodedItemsWithHeader = btoa(itemsWithHeader);

          putCalculationRedistributionImportItems({
            calculationId: calculation.id,
            importItemsEncodedBase64: encodedItemsWithHeader,
            verificationTimeStamp,
          })
            .unwrap()
            .then(() => {
              dispatch(setImportStep({ stepIndex: ProcessingStep.VALIDATION, stepRunning: false }));
            })
            .catch(handleError);
        })
        .catch(handleError);
    } else {
      dispatch(
        addToast({
          message: 'Product and Store identification must be specified',
          isError: true,
        }),
      );
      return;
    }
  }, [
    calculation.id,
    createCalculationRedistributionImport,
    dispatch,
    handleError,
    identificationData,
    importRawContent,
    inputDataKeys,
    instructionSpec,
    putCalculationRedistributionImportItems,
  ]);

  const isPending =
    createCalculationRedistributionImportStatus.status === QueryStatus.pending ||
    putCalculationRedistributionImportItemsStatus.status === QueryStatus.pending;

  return useMemo(() => ({ callback, isPending }), [callback, isPending]);
};

export default useImportRedistributionItems;
