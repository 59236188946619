import React, { useMemo } from 'react';
import {
  CategoryConfigurationDefinitionResponse,
  EntityListResponse,
  SkuType,
} from '@ydistri/api-sdk';
import ConfigurationRowTitle from '../ConfigurationRowTitle';
import { isSectionTypeSource, isSectionTypeTarget } from '../../configurationUtils';
import {
  ConfigurationFieldsWrapperCentered,
  ConfigurationFieldsWrapperTarget,
  ConfigurationRowWrapper,
} from '../ConfigurationRow';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import ConfigurationEntityListField from './ConfigurationEntityListField';
import useCategoryId from '../../../../../hooks/useCategoryId';
import { EntityListInfo } from '../../../../ProjectAdministration/EntityLists/entityListsTypes';

interface ConfigurationEntityListRowProps {
  definition: CategoryConfigurationDefinitionResponse;
  entityList: EntityListResponse;
  forcedTitle?: string;
  forcedCategoryId?: number;
  fullyFeatured?: boolean;
}

const ConfigurationEntityListRow: React.FC<ConfigurationEntityListRowProps> = ({
  definition,
  entityList,
  forcedTitle,
  forcedCategoryId,
  fullyFeatured = true,
}) => {
  const selectedCategoryId = useCategoryId();
  const categoryId = forcedCategoryId ?? selectedCategoryId;

  const { type: templateOrCalculationType } = useTemplateOrCalculation();
  const fieldsDisabled = useMemo(
    () => templateOrCalculationType === 'Calculation',
    [templateOrCalculationType],
  );

  const entityListInfo: EntityListInfo = useMemo(
    () => ({
      entityList,
      categoryId,
    }),
    [entityList, categoryId],
  );

  const title = (forcedTitle ? forcedTitle + ' - ' : '') + (entityList.name ?? '');

  return (
    <ConfigurationRowWrapper>
      <ConfigurationRowTitle
        definition={definition}
        forcedTitle={title}
        entityListInfo={entityListInfo}
        fullyFeatured={fullyFeatured}
      />
      {isSectionTypeSource(definition.sectionType) && (
        <ConfigurationFieldsWrapperCentered>
          <ConfigurationEntityListField
            skuTypeColumn={SkuType.DeadStock}
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            entityList={entityList}
            forcedCategoryId={forcedCategoryId}
          />
          <ConfigurationEntityListField
            skuTypeColumn={SkuType.SlowMover}
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            entityList={entityList}
            forcedCategoryId={forcedCategoryId}
          />
          <ConfigurationEntityListField
            skuTypeColumn={SkuType.FastMover}
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            entityList={entityList}
            forcedCategoryId={forcedCategoryId}
          />
        </ConfigurationFieldsWrapperCentered>
      )}
      {isSectionTypeTarget(definition.sectionType) && (
        <ConfigurationFieldsWrapperTarget>
          <ConfigurationEntityListField
            skuTypeColumn="Target"
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            entityList={entityList}
            forcedCategoryId={forcedCategoryId}
          />
        </ConfigurationFieldsWrapperTarget>
      )}
    </ConfigurationRowWrapper>
  );
};

export default ConfigurationEntityListRow;
