import React, { useCallback, useMemo } from 'react';
import RegionSelector, { RegionOption } from './RegionSelector';
import { Row } from '@ydistri/ds';
import { useSearchParams } from 'react-router-dom';
import { useDetail } from '../../../../hooks/useDetail';
import { UrlParams } from '../../../../detailTypes';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { useGetRegionsQuery } from '../../../../../../apis/apiLists';

interface RegionSelectorControllerProps {
  onChange?: (value: number) => void;
}

const RegionSelectorController: React.FC<RegionSelectorControllerProps> = ({ onChange }) => {
  const forcedTemplate = useTemplateOrCalculation('Template');

  const { data: regions } = useGetRegionsQuery(forcedTemplate);
  const { regionId } = useDetail('regionId');
  const [, setSearchParams] = useSearchParams();

  const regionOptions: RegionOption[] = useMemo(() => {
    if (!regions) {
      return [];
    }

    return regions.array;
  }, [regions]);

  const changeHandler = useCallback(
    (newValue: number) => {
      setSearchParams(prevParams => {
        if (newValue !== NO_VALUE) {
          prevParams.set(UrlParams.REGION, newValue.toString());
        } else {
          prevParams.delete(UrlParams.REGION);
        }
        return prevParams;
      });

      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, setSearchParams],
  );

  return (
    <Row $gap="0.5rem" $alignItems="center">
      <RegionSelector options={regionOptions} onChange={changeHandler} value={regionId} />
    </Row>
  );
};

export default RegionSelectorController;
