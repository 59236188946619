import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableModals, closeModal } from '../modalSlice';
import { ReduxState } from '../../../store';
import { Modal } from 'antd';
import SkuDailyTransactionsChart from '../../Detail/sections/SkuDailyTransactionsChart/SkuDailyTransactionsChart';
import { useGetSkuQuery } from '../../Detail/apiDetail';
import { ReadOnlyItem, Row, Title } from '@ydistri/ds';
import { SKU_ID_LABEL } from '../../../components/global/_constants';

const modalId = AvailableModals.SKU_DAILY_SALES;

const SkuDailySalesModal: React.FC = () => {
  const dispatch = useDispatch();

  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const modalData = useSelector((state: ReduxState) => state.modal.modalData[modalId]);
  const skuId = modalData?.skuId ?? 0;

  const { data: sku, isFetching: isSkuFetching } = useGetSkuQuery(skuId);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const title = useMemo(
    () =>
      isSkuFetching ? (
        'Loading...'
      ) : (
        <Row $gap="3rem">
          <Title>Product: {sku?.product?.name}</Title>
          <Title>Store: {sku?.store?.name}</Title>
          <ReadOnlyItem key="sku-id" label={SKU_ID_LABEL} value={skuId.toString()} />
        </Row>
      ),
    [isSkuFetching, sku?.product?.name, sku?.store?.name, skuId],
  );

  if (!open) return null;
  return (
    <Modal title={title} centered open={true} width="80%" onCancel={handleClose} footer={null}>
      <SkuDailyTransactionsChart skuId={skuId} />
    </Modal>
  );
};

export default SkuDailySalesModal;
