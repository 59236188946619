import React, { useMemo } from 'react';
import { ChartPropertyType } from './statisticsModalChartLib';
import { StatisticsModalType } from '../statisticsLib';
import { StatisticsModalChart } from './StatisticsModalChart';
import { Spin } from 'antd';
import StatisticsModalChartTooltip from './StatisticsModalChartTooltip';
import { useGetStatsSourceStoresQuery } from '../apiCalculationStatistics';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import { useApplicationData } from '../../../../hooks/useApplicationData';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';

interface StatisticsModalChartSourceStoreProps {
  chartValueType: ChartPropertyType<StatisticsModalType.SOURCE_STORE>;
  calculationId: number;
}

const StatisticsModalChartSourceStore: React.FC<StatisticsModalChartSourceStoreProps> = ({
  chartValueType,
  calculationId,
}) => {
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const { departments: hasDepartments } = useApplicationData();
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);
  const showDepartments =
    hasDepartments && clusterType === CalculationStatisticClusterType.StoreDepartment;

  const { data: sourceStoreData, isFetching } = useGetStatsSourceStoresQuery({
    calculationId,
    departmentId,
    tableOrChart: 'chart',
    skip: 0,
    clusterType,
  });

  const data = useMemo(() => sourceStoreData ?? [], [sourceStoreData]);

  const customTooltipId = 'chart-js-tooltip-source-store';

  return (
    <Spin spinning={isFetching}>
      <StatisticsModalChart
        statisticsModalType={StatisticsModalType.SOURCE_STORE}
        customTooltipId={customTooltipId}
        chartValueType={chartValueType}
        data={data}
      />
      <StatisticsModalChartTooltip
        chartValueType={chartValueType}
        showDepartments={showDepartments}
        modalType={StatisticsModalType.SOURCE_STORE}
        customTooltipId={customTooltipId}
      />
    </Spin>
  );
};

export default StatisticsModalChartSourceStore;
