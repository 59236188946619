export const closingTooltip = (
  <p>
    If enabled, the whole inventory of stores configured as &quot;Closing Store&quot; will be marked
    as &quot;forced&quot;
  </p>
);

export const superTargetTooltip = (
  <>
    <p>
      Behaviour of &quot;forced&quot; supply that can&apos;t be utilized within &quot;Standard&quot;
      redistribution
    </p>

    <p>
      <strong>Do not use SuperTarget Stores</strong> - &quot;Forced&quot; inventory without
      utilization on standard targets stay at the source stores
    </p>

    <p>
      <strong>All</strong> - &quot;Forced&quot; inventory from all enabled products and all enabled
      source stores and will be redistributed to SuperTarget Stores
    </p>

    <p>
      <strong>Source SKU</strong> - &quot;Forced&quot; inventory of all products directly involved
      in the standard redistribution (and not redistributed completely) will be redistributed to
      SuperTarget Stores
    </p>

    <p>
      <strong>Source Store</strong> - &quot;Forced&quot; inventory of all enabled products of the
      source stores involved in the standard redistribution will be redistributed to SuperTarget
      Stores
    </p>
  </>
);
