import React from 'react';
import { Button } from 'antd';
import TableExport from '../../../components/global/TableExport/TableExport';
import { ColumnType } from 'antd/lib/table';

interface TableTargetListItemsExportProps<T> {
  title?: string;
  dataSource: T[];
  columns: ColumnType<T>[];
}

const TableTargetListItemsExport = <T extends object>({
  title,
  dataSource,
  columns,
}: TableTargetListItemsExportProps<T>): React.ReactElement => {
  return (
    <TableExport
      columns={columns}
      dataSource={dataSource}
      fileName={`${title}.xlsx`}
      sheetName="Target List Statistics"
    >
      <Button>Export</Button>
    </TableExport>
  );
};

export default TableTargetListItemsExport;
