import {
  ForecastMonthlyOverviewResponse,
  OverviewValueType,
  SaleMonthlyOverviewResponse,
  TransactionOverviewType,
  TransactionValueResponse,
} from '@ydistri/api-sdk';

/**
 * Return given forecast overview based on the confidence, overviewValueType and transactionOverviewType and return
 * it as TransactionValueResponse.
 * @param forecast
 * @param confidence
 * @param overviewValueType
 * @param transactionOverviewType
 */
const getForecastOverviewForSale = (
  forecast: ForecastMonthlyOverviewResponse,
  confidence: number,
  overviewValueType: OverviewValueType,
  transactionOverviewType: TransactionOverviewType,
): TransactionValueResponse | undefined => {
  const forecastOverview = forecast.overviews.find(
    tmpForecast =>
      tmpForecast.confidence === confidence &&
      tmpForecast.overviewValueType === overviewValueType &&
      tmpForecast.transactionOverviewType === transactionOverviewType,
  );

  if (forecastOverview) {
    return {
      overviewValueType: forecastOverview.overviewValueType,
      transactionOverviewType: forecastOverview.transactionOverviewType,
      value: forecastOverview.value,
    };
  }
};

/**
 * Get forecast of given confidence, overviewValueType and transactionOverviewType and append it to overviews array if found
 * @param overviews
 * @param forecast
 * @param confidence
 * @param overviewValueType
 * @param transactionOverviewType
 */
const appendForecast = (
  overviews: TransactionValueResponse[],
  forecast: ForecastMonthlyOverviewResponse,
  confidence: number,
  overviewValueType: OverviewValueType,
  transactionOverviewType: TransactionOverviewType,
) => {
  const forecastOverview = getForecastOverviewForSale(
    forecast,
    confidence,
    overviewValueType,
    transactionOverviewType,
  );
  if (forecastOverview) {
    overviews.push(forecastOverview);
  }
};

/**
 * Append Value and Quantity Min and Max Forecasts to overviews array
 * @param overviews
 * @param forecast
 * @param minConfidence
 * @param maxConfidence
 */
const appendForecastsToOverviews = (
  overviews: TransactionValueResponse[],
  forecast: ForecastMonthlyOverviewResponse,
  minConfidence: number,
  maxConfidence: number,
) => {
  appendForecast(
    overviews,
    forecast,
    minConfidence,
    OverviewValueType.Value,
    TransactionOverviewType.ForecastMin,
  );
  appendForecast(
    overviews,
    forecast,
    minConfidence,
    OverviewValueType.Quantity,
    TransactionOverviewType.ForecastMin,
  );
  appendForecast(
    overviews,
    forecast,
    maxConfidence,
    OverviewValueType.Value,
    TransactionOverviewType.ForecastMax,
  );
  appendForecast(
    overviews,
    forecast,
    maxConfidence,
    OverviewValueType.Quantity,
    TransactionOverviewType.ForecastMax,
  );
};

/**
 * Combine sales and forecasts to one array. Sales is an array of timed data for specific year and month.
 * Forecasts is an array of timed data for specific year and month. This function will combine these two arrays.
 * For each timed data in forecasts, find appropriate timed data in sales and add the forecast Min and Max values to it.
 * There are more forecasts for one month and year, each with different confidence. The function will only add the forecasts
 * with confidence specified by minConfidence and maxConfidence.
 * Not all months in forecast are present in sales. If there is no sale for given month, the forecast will be added to the
 * sales array.
 * @param sales
 * @param forecasts
 * @param minConfidence
 * @param maxConfidence
 */
export const joinSalesAndForecast = (
  sales: SaleMonthlyOverviewResponse[],
  forecasts: ForecastMonthlyOverviewResponse[],
  minConfidence: number,
  maxConfidence: number,
): SaleMonthlyOverviewResponse[] => {
  //First add forecasts to sales if there is a forecast for month that is present in sales
  const salesWithForecasts = sales.map(sale => {
    const forecast = forecasts.find(
      tmpForecast =>
        tmpForecast.monthInterval.month === sale.monthInterval.month &&
        tmpForecast.monthInterval.year === sale.monthInterval.year,
    );
    if (forecast) {
      const overviews = [...sale.overviews];
      appendForecastsToOverviews(overviews, forecast, minConfidence, maxConfidence);

      //overviews is not writable, we can't simply replace the property with another array
      //we need to construct a new object
      return {
        ...sale,
        overviews: [...overviews],
      };
    } else {
      return sale;
    }
  });

  //Now add forecasts to sales if there is a forecast for month that is not present in sales
  forecasts.forEach(forecast => {
    const sale = salesWithForecasts.find(
      tmpSale =>
        tmpSale.monthInterval.month === forecast.monthInterval.month &&
        tmpSale.monthInterval.year === forecast.monthInterval.year,
    );
    if (!sale) {
      const overviews: TransactionValueResponse[] = [];
      appendForecastsToOverviews(overviews, forecast, minConfidence, maxConfidence);

      salesWithForecasts.push({
        monthInterval: forecast.monthInterval,
        overviews,
      });
    }
  });

  return salesWithForecasts;
};
