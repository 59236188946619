import { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { ConfigurationRuleScopeMode, StoreResponse } from '@ydistri/api-sdk';
import dayjs from 'dayjs';
import { useGetStoresQuery } from '../../../apis/apiLists';
import { useApplicationDate } from '../../../hooks/useApplicationDate';
import { useMainScope } from './useMainScope';

interface UseStoreSaleNotification {
  storesToNotify: StoreResponse[];
  isFetchingStores: boolean;
}

export const useStoreSaleNotification = (): UseStoreSaleNotification => {
  const applicationDate = useApplicationDate();
  const date1 = applicationDate ? dayjs(applicationDate) : undefined;
  const scope = useMainScope();

  const templateOrCalculation = useTemplateOrCalculation();

  const { data: availableStores, isFetching: isFetchingStores } =
    useGetStoresQuery(templateOrCalculation);

  const storeOption = scope?.entities.Stores.selectionOption;
  const selectedStores = scope?.entities.Stores.selectedIds;
  const storeTypeOption = scope?.entities.StoreTypes.selectionOption;
  const selectedStoreTypes = scope?.entities.StoreTypes.selectedIds;

  const isStoreSelected = useCallback(
    (store: StoreResponse) => {
      if (storeOption === ConfigurationRuleScopeMode.All) {
        return true;
      }
      if (storeOption === ConfigurationRuleScopeMode.Specified) {
        return selectedStores?.includes(store.id);
      }
      if (storeOption === ConfigurationRuleScopeMode.AllExcept) {
        return !selectedStores?.includes(store.id);
      }
      return false;
    },
    [selectedStores, storeOption],
  );

  const isStoreTypeSelected = useCallback(
    (store: StoreResponse) => {
      if (!store.storeType) {
        throw new Error('store has no store type!');
      }

      if (storeTypeOption === ConfigurationRuleScopeMode.All) {
        return true;
      } else if (storeTypeOption === ConfigurationRuleScopeMode.Specified) {
        return selectedStoreTypes?.includes(store.storeType.id);
      } else if (storeTypeOption === ConfigurationRuleScopeMode.AllExcept) {
        return !selectedStoreTypes?.includes(store.storeType.id);
      }
      return false;
    },
    [selectedStoreTypes, storeTypeOption],
  );

  return useMemo(() => {
    if (isFetchingStores) return { storesToNotify: [], isFetchingStores };
    const storesWithoutSale = (availableStores?.array ?? []).filter(
      s => (date1?.diff(dayjs(s.lastSaleDate), 'day') ?? 0) >= 14,
    );

    const storesToNotify: StoreResponse[] = [];

    storesWithoutSale.forEach(s => {
      const storeSelected = isStoreSelected(s);
      const storeTypeSelected = isStoreTypeSelected(s);

      if (storeTypeSelected && storeSelected) {
        storesToNotify.push(s);
      }
    });

    return { storesToNotify, isFetchingStores };
  }, [availableStores?.array, date1, isStoreSelected, isStoreTypeSelected, isFetchingStores]);
};
