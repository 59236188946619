import StatisticsSection from '../../../Statistics/components/StatisticsSection';
import { Column, computeRemSize, Row, Title } from '@ydistri/ds';
import React from 'react';
import StatisticsSourceStoreIcon from '../../../../../../assets/statistics-source-store.svg';
import CalculationOverallPickingPerformanceStatistics from './stats/CalculationOverallPickingPerformanceStatistics';

const CalculationPickingPerformanceHeader: React.FC = () => {
  return (
    <StatisticsSection $width="100%" icon={StatisticsSourceStoreIcon}>
      <Row $justifyContent="space-between" $gap="1rem">
        <Column $gap="1rem" $width={computeRemSize(450)}>
          <Title $size="large">Store picking performance</Title>
        </Column>
        <Row $gap="1rem">
          <CalculationOverallPickingPerformanceStatistics />
        </Row>
      </Row>
    </StatisticsSection>
  );
};

export default CalculationPickingPerformanceHeader;
