import React, { useMemo } from 'react';
import { COLORS } from '../../../styles/style';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';

interface UserAvatarProps {
  fullName: string;
}

const YAvatar = styled.div`
  font-size: 1rem;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;

  width: ${computeRemSize(30)};
  height: ${computeRemSize(30)};
  line-height: ${computeRemSize(30)};
`;

/**
 * Displays an Avatar with user initials taken from the first and last names (and middle name if present).
 */
const UserAvatar: React.FC<UserAvatarProps> = ({ fullName }: UserAvatarProps) => {
  const initials = useMemo(() => {
    return fullName
      .toUpperCase()
      .split(' ')
      .map(n => n[0])
      .join('');
  }, [fullName]);

  return <YAvatar data-testid={`avatar_${initials}`}>{initials}</YAvatar>;
};

export default UserAvatar;
