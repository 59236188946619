import React, { useCallback, useMemo } from 'react';
import { ActionsBar, Button, IconButton_IconSizes, Section } from '@ydistri/ds';
import ScopeDefinition from './ScopeDefinition';
import { ViewMode } from './scopeLib';
import { MdEdit, MdOpenInNew } from 'react-icons/md';
import { useMainScope } from './useMainScope';
import { useOpenScopeModal } from './useOpenScopeModal';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import SalesNoticeButton from './SalesNoticeButton';
import { batch, useDispatch } from 'react-redux';
import { AvailableModals, openModal } from '../../Modals/modalSlice';

const PlaygroundDefinition: React.FC = () => {
  const dispatch = useDispatch();
  const scope = useMainScope();
  const openModalHandler = useOpenScopeModal(scope);
  const templateOrCalculation = useTemplateOrCalculation();
  const isCalculation = templateOrCalculation.type === 'Calculation';

  const openStoreOverviewModalHandler = useCallback(() => {
    batch(() => {
      dispatch(openModal({ type: AvailableModals.STORE_OVERVIEW, data: undefined }));
    });
  }, [dispatch]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar moveToRight={true}>
        <SalesNoticeButton />
        <Button
          $colorVariant="ghost"
          onClick={openStoreOverviewModalHandler}
          data-testid="overview_exceptions"
        >
          <MdOpenInNew size={IconButton_IconSizes.small} />
          Overview
        </Button>
        {templateOrCalculation.type === 'Template' && (
          <Button $colorVariant="ghost" onClick={openModalHandler} data-testid="edit_playground">
            <MdEdit size={IconButton_IconSizes.small} />
            Adjust scope
          </Button>
        )}
      </ActionsBar>
    );
  }, [openModalHandler, openStoreOverviewModalHandler, templateOrCalculation.type]);

  return (
    <Section
      header="General configuration"
      headerActions={headerActions}
      $backgroundColor="transparent"
      showBulletPoint={false}
      $ratio={1}
      $basis={50}
    >
      {scope && (
        <ScopeDefinition
          scopeId={scope.id}
          viewMode={ViewMode.GENERAL_CONFIGURATION}
          disabled={isCalculation}
        />
      )}
    </Section>
  );
};

export default PlaygroundDefinition;
