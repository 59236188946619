import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../store';
import SkuDetailSection from './SkuDetailSection/SkuDetailSection';
import { SkuRedistributionSubset } from '../../../redistributionTypes';

interface CalculationRedistributionSourceSectionProps {
  onShowSkuInfoChanged?: (showSkuInfo: boolean) => void;
}

const CalculationRedistributionSourceSection: React.FC<
  CalculationRedistributionSourceSectionProps
> = ({ onShowSkuInfoChanged }) => {
  const skuRedistribution = useSelector(
    (state: ReduxState) => state.calculations.selectedSKURedistribution,
  );

  const redistributionData = useMemo((): SkuRedistributionSubset | undefined => {
    if (skuRedistribution) {
      return {
        skuId: skuRedistribution.sourceSkuId,
        brandName: skuRedistribution.product.brand?.name ?? '',
        brandId: skuRedistribution.product.brand?.id ?? 0,
        quantity: skuRedistribution.quantity,
        totalMovedQuantity: skuRedistribution.totalQuantityFromSourceSku,
        store: skuRedistribution.sourceStore,
      };
    }
  }, [skuRedistribution]);

  if (redistributionData) {
    return (
      <SkuDetailSection
        infoType="Source"
        redistributionData={redistributionData}
        onShowSkuInfoChanged={onShowSkuInfoChanged}
      />
    );
  } else {
    return null;
  }
};

export default CalculationRedistributionSourceSection;
