import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import CategoryTree from '../../components/global/CategoryTree/CategoryTree';
import { Helmet } from 'react-helmet';
import DetailContent from './sections/components/DetailContent';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  setIsLoadingSalesData,
  setProductId,
  setRegionId,
  setStoreId,
  skuFound,
  skuNotFound,
} from './detailSlice';
import { getSkuById } from './detailLib';
import { UrlParams } from './detailTypes';
import { useDetail } from './hooks/useDetail';
import DetailProductsListSection from './sections/DetailProductListSection/DetailProductsListSection';
import { NO_VALUE } from '../../lib/utils/utilsTypes';
import { createDebugLog } from '../../lib/utils/logging';
import { MainContent, MainContentWrapper } from '@ydistri/ds';

const debugLog = createDebugLog('Detail');

const Detail: React.FC = () => {
  debugLog('rendering Detail');
  const { slug } = useParams();
  const [prevCategorySlug, setPrevCategorySlug] = useState(slug);

  const [searchParams, setSearchParams] = useSearchParams();

  //TODO review 4 - make subcomponent
  if (slug !== prevCategorySlug) {
    setSearchParams(prevParams => {
      prevParams.delete(UrlParams.PRODUCT);
      prevParams.delete(UrlParams.STORE);
      prevParams.delete(UrlParams.REGION);
      prevParams.delete(UrlParams.SKU);
      return prevParams;
    });
    setPrevCategorySlug(slug);
  }

  const dispatch = useDispatch();
  const { storeId, skuId, regionId } = useDetail('storeId', 'skuId', 'regionId');

  useEffect(() => {
    debugLog(
      `useEffect values: searchParams=${JSON.stringify(
        searchParams,
      )}, skuId=${skuId}, regionId=${regionId}, storeId=${storeId}`,
    );

    const hasProduct = searchParams.has(UrlParams.PRODUCT);
    const hasSku = searchParams.has(UrlParams.SKU);

    let controller: AbortController | null = null;
    let signal: AbortSignal | null = null;

    if (hasProduct && !hasSku) {
      debugLog('has product and no SKU', searchParams.get(UrlParams.PRODUCT));
      dispatch(setProductId(parseInt(searchParams.get(UrlParams.PRODUCT) ?? '')));
    }

    if (hasSku) {
      const querySkuId = parseInt(searchParams.get(UrlParams.SKU) ?? '');
      if (querySkuId !== skuId) {
        debugLog('Fetching SKU for skuId', querySkuId);
        controller = new AbortController();
        signal = controller.signal;
        getSkuById(querySkuId, signal)
          .then(sku => {
            debugLog('Fetch finished');
            if (sku) {
              dispatch(skuFound(sku));
            } else {
              dispatch(skuNotFound(querySkuId));
            }
          })
          .finally(() => {
            dispatch(setIsLoadingSalesData(false));
          });
      }
    }

    if (!hasSku && !hasProduct) {
      dispatch(setProductId(NO_VALUE));
    }

    if (searchParams.has(UrlParams.REGION)) {
      const paramRegion = searchParams.get(UrlParams.REGION);
      if (paramRegion) {
        const regionId = parseInt(paramRegion);
        if (!Number.isNaN(regionId)) {
          dispatch(setRegionId(regionId));
        }
      }
    } else {
      if (regionId !== NO_VALUE) {
        dispatch(setRegionId(NO_VALUE));
      }
    }

    if (searchParams.has(UrlParams.STORE)) {
      const paramStore = searchParams.get(UrlParams.STORE);
      if (paramStore) {
        const storeId = parseInt(paramStore);
        if (!Number.isNaN(storeId)) {
          dispatch(setStoreId(storeId));
        }
      }
    } else {
      if (storeId !== NO_VALUE && !hasSku) {
        dispatch(setStoreId(NO_VALUE));
      }
    }

    return () => {
      if (controller !== null) {
        debugLog('Aborting request');
        controller.abort();
      }
    };
  }, [dispatch, searchParams, skuId, regionId, storeId]);

  useEffect(() => {
    dispatch(setPage(RoutingPage.DETAIL));
  }, [dispatch]);

  return (
    <>
      <Helmet title="Detail" />
      <MainContentWrapper>
        <CategoryTree displayExceptions={false} />
        <DetailProductsListSection />
        <MainContent>
          <DetailContent />
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default Detail;
