import { Section } from '@ydistri/ds';
import React from 'react';
import { Message } from '../../../components/global/Message';

interface NoAdministrationItemSelectedProps {
  message: string;
  secondaryMessage?: string;
}

const NoAdministrationItemSelected: React.FC<NoAdministrationItemSelectedProps> = React.memo(
  ({ message, secondaryMessage }) => {
    return (
      <Section $ratio={5}>
        <Message $size="1.5rem" $center>
          {message}
        </Message>
        {secondaryMessage && (
          <Message $size="1.1rem" $center>
            {secondaryMessage}
          </Message>
        )}
      </Section>
    );
  },
);
NoAdministrationItemSelected.displayName = 'NoAdministrationItemSelected';

export default NoAdministrationItemSelected;
