import { StatisticsModalType } from '../statisticsLib';
import {
  CalculationStatisticRouteResponse,
  CalculationStatisticStoreSourceResponse,
  CalculationStatisticStoreTargetResponse,
} from '@ydistri/api-sdk';
import { ChartData } from 'chart.js';
import { COLORS } from '@ydistri/ds';

export interface ChartButtons {
  [StatisticsModalType.ROUTE]: (keyof CalculationStatisticRouteResponse)[];
  [StatisticsModalType.SOURCE_STORE]: (keyof CalculationStatisticStoreSourceResponse)[];
  [StatisticsModalType.TARGET_STORE]: (keyof CalculationStatisticStoreTargetResponse)[];
}

export interface StatisticsChartDataType {
  [StatisticsModalType.ROUTE]: CalculationStatisticRouteResponse[];
  [StatisticsModalType.SOURCE_STORE]: CalculationStatisticStoreSourceResponse[];
  [StatisticsModalType.TARGET_STORE]: CalculationStatisticStoreTargetResponse[];
}

export const statisticsModalChartLib: ChartButtons = {
  [StatisticsModalType.ROUTE]: ['value', 'quantity', 'pickingPositionCount', 'filledStockoutValue'],
  [StatisticsModalType.SOURCE_STORE]: [
    'value',
    'quantity',
    'productCount',
    'pickingPositionCount',
    'targetStoreCount',
  ],
  [StatisticsModalType.TARGET_STORE]: [
    'value',
    'quantity',
    'productCount',
    'pickingPositionCount',
    'sourceStoreCount',
  ],
};

// export type ChartPropertyType<T extends StatisticsModalType> = (typeof statisticsModalChartLib)[T][number];

export type ChartPropertyType<T extends StatisticsModalType> =
  keyof StatisticsChartDataType[T][number];

function isNumber<T>(value: T): value is Extract<T, number> {
  return typeof value === 'number';
}

export type StatisticsModalChartTypes =
  | CalculationStatisticRouteResponse[]
  | CalculationStatisticStoreTargetResponse[]
  | CalculationStatisticStoreSourceResponse[];

export const valueSorter = <T extends StatisticsModalType>(
  a: StatisticsChartDataType[T][number],
  b: StatisticsChartDataType[T][number],
  chartValueType: keyof StatisticsChartDataType[T][number],
): number => {
  const val1 = a[chartValueType];
  const val2 = b[chartValueType];
  if (isNumber(val1) && isNumber(val2)) return val2 - val1;
  return 0;
};

export const defaultDatasetStyle: Partial<ChartData<'line', number[], string>['datasets'][number]> =
  {
    backgroundColor: COLORS.PRIMARY_LIGHT,
    fill: true,
    borderColor: 'rgba(59, 59, 59, 1)',
    pointHoverBorderColor: 'rgba(59, 59, 59, 1)',
    pointRadius: 0,
    pointHoverRadius: 4,
  };
