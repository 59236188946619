import React, { useMemo } from 'react';
import DetailSalesTableWrapper from '../DetailSalesTable/DetailSalesTableWrapper';
import { ActionsBar, Section, useInjectLocalFilters, YColumnsType } from '@ydistri/ds';
import { TParsedTransactions, Units } from '../../../../../../lib/charts/saleChartsTypes';
import { DetailSaleRowData } from '../../../../../../lib/sales/salesTypes';
import { extractOnlySalesFromParsedData } from '../../../../../../lib/charts/saleChartsLib';
import { parsedMonthlyTransactionsToTableData } from '../../../../../../lib/sales/salesLib';
import { format } from 'date-fns';
import { formatNumber } from '@ydistri/utils';
import { useCurrency } from '../../../../../../hooks/useCurrency';
import DetailSalesExport from './DetailSalesExport';
import LoadingDataOverlay from '../../../../../../components/global/LoadingDataOverlay/LoadingDataOverlay';

interface DetailSalesTableSectionProps {
  monthlyData: TParsedTransactions[];
  loadingSalesData?: boolean;
  units: Units;
}
const EXPORT_NUMBER_FORMAT = '#,##0';

const columns: YColumnsType<DetailSaleRowData>[] = [
  {
    title: 'Time interval',
    dataIndex: 'monthString',
    apiFilterable: false,
    render: (value: string, record: DetailSaleRowData) => {
      if (record.isFullYear) {
        return format(record.month, 'yyyy');
      } else {
        return format(record.month, 'yyyy - LLL');
      }
    },
  },
  {
    title: 'Sale regular []',
    dataIndex: 'saleRegular',
    align: 'right',
    sorter: (left: DetailSaleRowData, right: DetailSaleRowData) =>
      left.saleRegular - right.saleRegular,
    sortDirections: ['descend', 'ascend'],
    apiFilterable: true,
    apiFilterType: 'number',
    render: (value: number) => formatNumber(value),
    exportNumberFormat: EXPORT_NUMBER_FORMAT,
  },
  {
    title: 'Sale promo []',
    dataIndex: 'salePromo',
    align: 'right',
    sorter: (left: DetailSaleRowData, right: DetailSaleRowData) => left.salePromo - right.salePromo,
    sortDirections: ['descend', 'ascend'],
    apiFilterable: true,
    apiFilterType: 'number',
    render: (value: number) => formatNumber(value),
    exportNumberFormat: EXPORT_NUMBER_FORMAT,
  },
  {
    title: 'Sale sellout []',
    dataIndex: 'saleSellout',
    align: 'right',
    sorter: (left: DetailSaleRowData, right: DetailSaleRowData) =>
      left.saleSellout - right.saleSellout,
    sortDirections: ['descend', 'ascend'],
    apiFilterable: true,
    apiFilterType: 'number',
    render: (value: number) => formatNumber(value),
    exportNumberFormat: EXPORT_NUMBER_FORMAT,
  },
  {
    title: 'Total []',
    dataIndex: 'saleTotal',
    align: 'right',
    sorter: (left: DetailSaleRowData, right: DetailSaleRowData) => left.saleTotal - right.saleTotal,
    sortDirections: ['descend', 'ascend'],
    apiFilterable: true,
    apiFilterType: 'number',
    render: (value: number) => formatNumber(value),
    exportNumberFormat: EXPORT_NUMBER_FORMAT,
  },
];

const DetailSalesTableSection: React.FC<DetailSalesTableSectionProps> = ({
  monthlyData,
  loadingSalesData,
  units,
}) => {
  const currency = useCurrency();

  const tableData = useMemo(() => {
    const salesData = extractOnlySalesFromParsedData(monthlyData);
    return parsedMonthlyTransactionsToTableData(salesData, units);
  }, [monthlyData, units]);

  const tableColumns = useMemo(() => {
    return columns.map(column => {
      if (typeof column.title === 'string') {
        return {
          ...column,
          title: column.title.replace('[]', `[${units === Units.VALUE ? currency : 'MU'}]`),
        };
      } else {
        return column;
      }
    });
  }, [currency, units]);

  const filterableColumns = useInjectLocalFilters<DetailSaleRowData>(tableColumns);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <DetailSalesExport dataSource={tableData} columns={tableColumns} />
      </ActionsBar>
    );
  }, [tableColumns, tableData]);

  return (
    <Section header="Sales" headerActions={headerActions}>
      <LoadingDataOverlay active={loadingSalesData}>
        <DetailSalesTableWrapper
          loadingSalesData={loadingSalesData}
          columns={filterableColumns}
          data={tableData}
        />
      </LoadingDataOverlay>
    </Section>
  );
};

export default DetailSalesTableSection;
