import { Chart, TooltipModel } from 'chart.js';
import { formatNumber } from '@ydistri/utils';
import { StatisticsChartDataType, StatisticsModalChartTypes } from './statisticsModalChartLib';
import { StatisticsModalType } from '../statisticsLib';
import { computeRemSize } from '@ydistri/ds';

const tooltipElField = (
  customTooltipId: string,
  d: StatisticsModalChartTypes[number],
  idSuffix: string,
) => {
  const element = document.getElementById(`${customTooltipId}-${idSuffix}`);
  if (element) {
    let finalValue = '';
    let tempValue = d;

    const idSuffixArray = idSuffix.split('.');
    for (const property of idSuffixArray) {
      if (property in tempValue) {
        // @ts-expect-error tempValue is generated automatically and cannot be modified
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        tempValue = tempValue[property];
      }
    }

    if (typeof tempValue === 'number') {
      finalValue = formatNumber(tempValue);
    } else if (typeof tempValue === 'string') {
      finalValue = tempValue;
    }

    element.innerHTML = finalValue;
  }
};

export const customChartTooltip = <T extends StatisticsModalType>(
  chart: Chart,
  tooltipModel: TooltipModel<'line'>,
  tooltipId: string,
  data: StatisticsChartDataType[T][number][],
): void => {
  const tooltipEl = document.getElementById(tooltipId);

  if (tooltipEl) {
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '1';
      return;
    }

    const d = data[tooltipModel.dataPoints[0]?.dataIndex];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (d !== undefined) {
      tooltipElField(tooltipId, d, 'department.name');
      tooltipElField(tooltipId, d, 'sourceStore.name');
      tooltipElField(tooltipId, d, 'targetStore.name');
      tooltipElField(tooltipId, d, 'value');
      tooltipElField(tooltipId, d, 'quantity');
      tooltipElField(tooltipId, d, 'productCount');
      tooltipElField(tooltipId, d, 'pickingPositionCount');
      tooltipElField(tooltipId, d, 'filledStockoutValue');
      tooltipElField(tooltipId, d, 'forcedProductValue');
      tooltipElField(tooltipId, d, 'targetStoreCount');
      tooltipElField(tooltipId, d, 'sourceStoreCount');
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    const chartPosition = chart.canvas.getBoundingClientRect();
    tooltipEl.style.top = `${computeRemSize(chartPosition.top + 40)}`;

    if (tooltipModel.caretX > (chartPosition.width * 2) / 3 - chartPosition.width / 8) {
      tooltipEl.style.right = `${computeRemSize(
        document.body.clientWidth -
          chartPosition.right +
          (chartPosition.width - tooltipModel.caretX) +
          20,
      )}`;
      tooltipEl.style.left = 'auto';
    } else {
      tooltipEl.style.left = `${computeRemSize(chartPosition.left + tooltipModel.caretX + 15)}`;
      tooltipEl.style.right = 'auto';
    }
  }
};
