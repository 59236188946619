import React, { useCallback, useMemo } from 'react';
import { Empty, List, Segmented, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableModals, closeModal } from '../modalSlice';
import { ReduxState } from '../../../store';
import { COLORS, ColumnScroll, Row, Title } from '@ydistri/ds';
import {
  categoryStringRoute,
  useGetCategoryTreeQuery,
} from '../../../components/global/CategoryTree/apiCategoryTree';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCategoryConfigurationDefinitionsQuery } from '../../../apis/apiApplication';
import { SegmentedValue } from 'antd/es/segmented';
import {
  CATEGORY_EXCEPTIONS_MODAL_HEIGHT,
  displayTypeOptions,
  ExceptionsModalDisplayType,
  forcedTitleAsCategory,
  forcedTitleAsFieldType,
  getDefinitionByFieldType,
  getExceptionsBy,
  keyGetterCategoryId,
  keyGetterFieldType,
  PartialExceptionExpanded,
  renderer,
  StyledModal,
} from './categoryExceptionsModalLib';
import { sortConfigurationFieldTypes } from '../../../apis/applicationLib';
import ResetFormField from './ResetFormField';
import { styled } from 'styled-components';
import ResetFormCategory from './ResetFormCategory';

export const ColoredHeadlineRow = styled(Row)`
  background-color: ${COLORS.PRIMARY_LIGHT};
  padding: 0.5rem;
  margin: 0.25rem;
`;
import { useExceptions } from './useExceptions';

const CategoryExceptionsModal: React.FC = () => {
  const modalId = AvailableModals.CATEGORY_EXCEPTIONS;
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories } = useGetCategoryTreeQuery(templateOrCalculation);
  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const [displayType, setDisplayType] = React.useState<ExceptionsModalDisplayType>(
    ExceptionsModalDisplayType.CATEGORY,
  );

  const handleDisplayTypeChange = useCallback((value: SegmentedValue) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setDisplayType(value as ExceptionsModalDisplayType);
  }, []);

  const { data: configurationDefinitions } = useGetCategoryConfigurationDefinitionsQuery();
  const sortedFieldTypes = useMemo(
    () => sortConfigurationFieldTypes(configurationDefinitions ?? []),
    [configurationDefinitions],
  );

  const exceptions = useExceptions();

  const categorySorter = (a: PartialExceptionExpanded, b: PartialExceptionExpanded) => {
    return a.titleFull.localeCompare(b.titleFull);
  };

  const exceptionsByField = useMemo(() => {
    return getExceptionsBy(exceptions, keyGetterFieldType, categorySorter);
  }, [exceptions]);

  const exceptionsByCategory = useMemo(() => {
    const sorter = (a: PartialExceptionExpanded, b: PartialExceptionExpanded) => {
      return (sortedFieldTypes.obj[a.fieldType] ?? 0) - (sortedFieldTypes.obj[b.fieldType] ?? 0);
    };
    return getExceptionsBy(exceptions, keyGetterCategoryId, sorter);
  }, [sortedFieldTypes, exceptions]);

  const renderDefinitionByField = useCallback(
    (exceptionExpanded: PartialExceptionExpanded) =>
      renderer(exceptionExpanded, forcedTitleAsCategory, configurationDefinitions ?? []),
    [configurationDefinitions],
  );

  const renderDefinitionByCategory = useCallback(
    (exceptionExpanded: PartialExceptionExpanded) =>
      renderer(exceptionExpanded, forcedTitleAsFieldType, configurationDefinitions ?? []),
    [configurationDefinitions],
  );

  const sortedCategoriesWithExceptions = useMemo(() => {
    if (exceptionsByCategory) {
      return Object.entries(exceptionsByCategory)
        .map(([cid]) => {
          return {
            categoryId: Number(cid),
            titleFull: exceptionsByCategory[Number(cid)]?.[0]?.titleFull ?? '',
          };
        })
        .sort((a, b) => a.titleFull.localeCompare(b.titleFull));
    }
    return [];
  }, [exceptionsByCategory]);

  const title = useMemo(() => {
    return (
      <Row $justifyContent="space-between" $gap="1rem">
        <Title>Exception overview</Title>
        <Segmented
          options={displayTypeOptions}
          value={displayType}
          onChange={handleDisplayTypeChange}
          style={{ marginRight: '50px' }}
        />
      </Row>
    );
  }, [displayType, handleDisplayTypeChange]);

  return (
    <StyledModal title={title} open={open} width="60%" onCancel={handleClose} footer={null}>
      {exceptions.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No exceptions" />
      )}
      {displayType === ExceptionsModalDisplayType.FIELD && (
        <ColumnScroll $height={CATEGORY_EXCEPTIONS_MODAL_HEIGHT} $gap="2rem">
          {sortedFieldTypes.arr.map(cf => {
            if ((exceptionsByField[cf]?.length ?? 0) === 0) return null;
            const definition = getDefinitionByFieldType(cf, configurationDefinitions ?? []);
            if (!definition) return null;

            return (
              <Spin key={cf} spinning={false}>
                <ColoredHeadlineRow $justifyContent="space-between">
                  <Title $size="small" $marginTop="small" $marginBottom="none">
                    {definition.title}
                  </Title>
                  <ResetFormField
                    definition={definition}
                    exceptionsByField={exceptionsByField[cf] ?? []}
                  />
                </ColoredHeadlineRow>
                <List
                  dataSource={exceptionsByField[cf]}
                  loading={false}
                  size="small"
                  renderItem={renderDefinitionByField}
                  data-testid={`ConfigurationGroupExceptions-${cf}`}
                />
              </Spin>
            );
          })}
        </ColumnScroll>
      )}
      {displayType === ExceptionsModalDisplayType.CATEGORY && (
        <ColumnScroll $height={CATEGORY_EXCEPTIONS_MODAL_HEIGHT} $gap="2rem">
          {sortedCategoriesWithExceptions.map(({ categoryId }) => {
            const excs = exceptionsByCategory[categoryId];
            if ((excs?.length ?? 0) === 0) return null;

            return (
              <Spin key={categoryId} spinning={false}>
                <ColoredHeadlineRow $justifyContent="space-between">
                  <Title $size="small" $marginTop="small" $marginBottom="none">
                    {categoryStringRoute(categories?.[categoryId])}
                  </Title>
                  <ResetFormCategory categoryId={categoryId} />
                </ColoredHeadlineRow>
                <List
                  dataSource={exceptionsByCategory[categoryId]}
                  loading={false}
                  size="small"
                  renderItem={renderDefinitionByCategory}
                  data-testid={`ConfigurationGroupExceptionsByCategory-${categoryId}`}
                />
              </Spin>
            );
          })}
        </ColumnScroll>
      )}
    </StyledModal>
  );
};

export default CategoryExceptionsModal;
