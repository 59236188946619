import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { TChartBarInfo, TChartLineInfo } from '../../../../../lib/charts/saleChartsTypes';

/**
 * Renders a graphical representation of the line based on the line info
 */
const ChartLineLegendGraphic = styled.div<{ $lineInfo: TChartLineInfo | TChartBarInfo }>`
  width: ${computeRemSize(25)};
  height: ${({ $lineInfo }) => {
    if ('fill' in $lineInfo && $lineInfo.fill !== false) {
      return computeRemSize(12);
    } else {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return computeRemSize(($lineInfo.borderWidth ?? 2) as number);
    }
  }};

  background-color: ${({ $lineInfo }) => {
    if ('fill' in $lineInfo && $lineInfo.fill !== false) {
      return $lineInfo.backgroundColor?.toString() ?? 'black';
    } else {
      return $lineInfo.borderColor?.toString() ?? 'black';
    }
  }};

  ${({ $lineInfo }) => {
    if ('fill' in $lineInfo && $lineInfo.fill !== false) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return `border: ${computeRemSize(($lineInfo.borderWidth ?? 1) as number)} ${
        $lineInfo.borderDash && $lineInfo.borderDash.length > 0 ? 'dashed' : 'solid'
      } ${$lineInfo.borderColor?.toString() ?? 'black'}`;
    } else {
      if ('borderDash' in $lineInfo && $lineInfo.borderDash && $lineInfo.borderDash.length > 0) {
        return `border-left: ${computeRemSize(8)} solid ${
          $lineInfo.borderColor?.toString() ?? 'black'
        }; border-right: ${computeRemSize(8)} solid ${
          $lineInfo.borderColor?.toString() ?? 'black'
        }; background-color: white;`;
      }
    }
  }};
`;

export default ChartLineLegendGraphic;
