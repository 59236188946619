import { ProductResponse } from '@ydistri/api-sdk';
import React, { useMemo } from 'react';
import { Column, Row, Label, Text, Title } from '@ydistri/ds';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useGetCategoryTreeQuery } from '../../../../../components/global/CategoryTree/apiCategoryTree';
import { styled } from 'styled-components';

const CategoryList = styled.ul`
  margin: 0;
  list-style-type: '- ';
`;

interface ProductTooltipContentProps {
  product: ProductResponse;
}

interface CategoryInfo {
  id: number;
  title: string;
}

/**
 * Tooltip displayed when hovering over a product in the product list
 * @param product
 * @constructor
 */
const ProductTooltipContent: React.FC<ProductTooltipContentProps> = ({ product }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories, isFetching } = useGetCategoryTreeQuery(templateOrCalculation);

  const categoryData = useMemo((): CategoryInfo[] | undefined => {
    if (!isFetching && categories) {
      return product.allCategoryIds
        ?.filter(categoryId => categoryId !== 1)
        .map(categoryId => {
          if (categories[categoryId]) {
            return {
              id: categoryId,
              title: categories[categoryId]?.title ?? categoryId.toString(),
            };
          } else {
            return { id: categoryId, title: categoryId.toString() };
          }
        });
    }
  }, [isFetching, categories, product.allCategoryIds]);

  return (
    <Column $minWidth="35rem" $gap="1rem">
      <Title>{product.name}</Title>

      <Column>
        {product.customerId && (
          <Row $gap="1rem">
            <Label>ID:</Label>
            <Text>{product.customerId}</Text>
          </Row>
        )}
        {product.code && (
          <Row $gap="1rem">
            <Label>Code:</Label>
            <Text>{product.code}</Text>
          </Row>
        )}
        <Row $gap="1rem">
          <Label>Brand:</Label>
          <Text>{`${product.brand?.name} (ID: ${product.brand?.id})`}</Text>
        </Row>
        <Row>
          <Label>Categories:</Label>
          <CategoryList>
            {categoryData?.map(category => (
              <li key={`li-categories-${category.id}`}>{category.title}</li>
            ))}
          </CategoryList>
        </Row>
      </Column>
    </Column>
  );
};

export default ProductTooltipContent;
