import { useMemo } from 'react';
import {
  ImportFormatItem,
  InstructionType,
  RedistributionImportInputData,
} from './redistributionImportTypes';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import { EntityIdentificationType } from '@ydistri/api-sdk';

/**
 * Returns a list of keys that form the format of importContent. The content may changed based on user's choice of instruction.
 */
const useRedistributionImportFormat = (): ImportFormatItem<RedistributionImportInputData>[] => {
  const instructionSpecification = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.instructionSpecification,
  );

  const identificationData = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.identificationData,
  );

  return useMemo((): ImportFormatItem<RedistributionImportInputData>[] => {
    const productIdentifier =
      identificationData.find(identification => identification.type === 'Product')?.value ??
      EntityIdentificationType.CustomerId;
    const storeIdentifier =
      identificationData.find(identification => identification.type === 'Store')?.value ??
      EntityIdentificationType.CustomerId;

    const result: ImportFormatItem<RedistributionImportInputData>[] = [
      {
        id: 'productIdentifier',
        label: productIdentifier === EntityIdentificationType.Code ? 'Product code' : 'Product ID',
      },
      {
        id: 'sourceStoreIdentifier',
        label:
          storeIdentifier === EntityIdentificationType.Code
            ? 'Source Store Code'
            : 'Source Store ID',
      },
      {
        id: 'targetStoreIdentifier',
        label:
          storeIdentifier === EntityIdentificationType.Code
            ? 'Target Store Code'
            : 'Target Store ID',
      },
    ];

    if (
      instructionSpecification === InstructionType.QUANTITY ||
      instructionSpecification === InstructionType.QUANTITY_DESCRIPTION
    ) {
      result.push({
        id: 'count',
        label: 'Quantity',
      });
    }

    if (
      instructionSpecification === InstructionType.DESCRIPTION ||
      instructionSpecification === InstructionType.QUANTITY_DESCRIPTION
    ) {
      result.push({
        id: 'description',
        label: 'Description',
      });
    }

    return result;
  }, [identificationData, instructionSpecification]);
};

export default useRedistributionImportFormat;
