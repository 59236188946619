import { SuperTargetRedistributionType } from '@ydistri/api-sdk';
import { MultiselectCustomerPartialEntity } from './ConfigurationMultiselect';

export const getUseClosingBoolean = (config?: boolean): boolean => config ?? false;
export const getUseSuperTargetBoolean = (config?: SuperTargetRedistributionType): boolean =>
  config ? config !== SuperTargetRedistributionType.DoNotUse : false;

export const customerIDThenTitleSorter = (
  left: MultiselectCustomerPartialEntity,
  right: MultiselectCustomerPartialEntity,
): number => {
  const result = left.customerId.localeCompare(right.customerId);
  if (result !== 0) {
    return result;
  } else {
    return left.title.localeCompare(right.title);
  }
};
