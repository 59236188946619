import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { Spin } from 'antd';
import { ActionsBar, COLORS, computeRemSize, ConfigurationSelect, Title } from '@ydistri/ds';
import { RegionFlowGroupResponse } from '@ydistri/api-sdk';
import { DefaultOptionType } from 'rc-select/lib/Select';
import {
  usePostRegionToGroupMutation,
  useRemoveRegionFromGroupMutation,
  useRemoveRegionGroupMutation,
} from '../apiRegionFlowGroups';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import DeleteIconButton from '../../../../components/buttons/DeleteIconButton';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import type { RequestStatusFlags } from '@reduxjs/toolkit/src/query/core/apiState';
import { useGetRegionsQuery } from '../../../../apis/apiLists';

const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0.25rem;
  gap: 0.5rem;

  &:hover {
    background-color: ${COLORS.HOVER_GREY_LIGHT};
  }
`;

const GroupSpin = styled(Spin)`
  width: 100%;
  padding: 0.25rem;
`;

const GroupTitle = styled(Title)`
  width: ${computeRemSize(120)};
`;

interface RegionGroupSelectProps {
  regionGroup: RegionFlowGroupResponse;
}

const RegionGroup: React.FC<RegionGroupSelectProps> = ({ regionGroup }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [status, setStatus] = useState<ConfigurationFieldStatus>(ConfigurationFieldStatus.LOADING);
  const { data: regions } = useGetRegionsQuery(templateOrCalculation);
  const [postRegionToGroup, postRegionToGroupStatus] = usePostRegionToGroupMutation();
  const [removeRegionFromGroup, removeRegionFromGroupStatus] = useRemoveRegionFromGroupMutation();
  const [removeRegionGroup, removeRegionGroupStatus] = useRemoveRegionGroupMutation();

  const options: DefaultOptionType[] =
    regions?.array.map(region => ({
      value: region.id,
      label: region.name,
    })) ?? [];

  const deleteGroup = useCallback(() => {
    removeRegionGroup({ regionFlowGroupIds: [regionGroup.id] });
  }, [regionGroup.id, removeRegionGroup]);

  const handleChange = useCallback(
    (value: number[]) => {
      const newRegion: number[] = [];
      const deleteRegion: number[] = [];

      value
        .filter(v => !regionGroup.regions.map(r => r.id).includes(v))
        .forEach(v => {
          newRegion.push(v);
        });

      if (newRegion.length > 0) {
        postRegionToGroup({ regionId: newRegion[0], regionGroupId: regionGroup.id });
      }

      regionGroup.regions
        .map(r => r.id)
        .forEach(v => {
          if (!value.includes(v)) deleteRegion.push(v);
        });

      if (deleteRegion.length > 0) {
        removeRegionFromGroup({ regionId: deleteRegion[0], regionGroupId: regionGroup.id });
      }
    },
    [postRegionToGroup, regionGroup.id, regionGroup.regions, removeRegionFromGroup],
  );

  const value: number[] = useMemo(() => regionGroup.regions.map(r => r.id), [regionGroup]);

  const setStatusByLatestChange = useCallback((status: RequestStatusFlags) => {
    setStatus(rtkQueryPatchStatusToConfigurationFieldStatus(status));
  }, []);

  useEffect(() => {
    setStatusByLatestChange(postRegionToGroupStatus);
  }, [postRegionToGroupStatus, setStatusByLatestChange]);

  useEffect(() => {
    setStatusByLatestChange(removeRegionFromGroupStatus);
  }, [removeRegionFromGroupStatus, setStatusByLatestChange]);

  return (
    <GroupSpin key={regionGroup.id} spinning={removeRegionGroupStatus.isLoading}>
      <GroupRow>
        <GroupTitle>Group {regionGroup.id}</GroupTitle>
        <ConfigurationSelect<number[]>
          disabled={templateOrCalculation.type === 'Calculation'}
          mode="multiple"
          placeholder="Please select"
          value={value}
          onChange={handleChange}
          options={options}
          width="100%"
          status={status}
          disableOnLoading={false}
          size="large"
        />
        <ActionsBar>
          <DeleteIconButton
            size="normal"
            disabled={templateOrCalculation.type === 'Calculation'}
            tooltip={`Delete group ${regionGroup.id}`}
            onClick={deleteGroup}
            data-testid="DeleteRegionGroupButton"
          />
        </ActionsBar>
      </GroupRow>
    </GroupSpin>
  );
};

export default RegionGroup;
