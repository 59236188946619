import React, { useCallback, useMemo, useState } from 'react';
import {
  SectionHeaderRowSpaceBetween,
  SectionHeaderRowSpaceBetweenNoBorder,
  RowCentered,
} from '@ydistri/ds';
import { TemplateSelect } from './TemplateSelect';
import { Dropdown, DropdownProps, Space } from 'antd';
import { icons, IconType } from '../../components/icons/icons';
import { COLORS } from '../../styles/style';
import TemplatePotentialOverview from '../../components/global/HeaderPotentialOverview/TemplatePotentialOverview';
import EditTemplateModal from './EditTemplateModal';
import { generatePartialPath, RoutingPage } from '../../routes/routerSlice';
import { ROUTES } from '../../components/menu/menuLeftItemTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import useSubpage from '../../hooks/useSubpage';
import CreateTemplateModal from './CreateTemplateModal';
import DeleteTemplateModal from './DeleteTemplateModal';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { ConfigurationSubpage } from './Configuration';
import MenuSubpages from '../../components/menu/MenuSubpages';
import ConfigurationResetButton from './ConfigurationCategories/ConfigurationResetButton';
import MoreIconButton from '../../components/buttons/MoreIconButton';
import { CalculationDetailSubpages } from '../Calculations/calculationsLib';
import { ProjectAdministrationSubpage } from '../ProjectAdministration/projectAdministrationLib';
import { useValidator } from './Validator/useValidator';
import { API } from '../../swagger/collections';
import { resetState } from '../../components/global/CategoryTree/categoryTreeSlice';
import { useDispatch } from 'react-redux';
import { useApplicationData } from '../../hooks/useApplicationData';
import ConfigurationExceptions from './ConfigurationCategories/ConfigurationExceptions';
import { useUser } from '../../hooks/useUser';

enum TemplateAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  CREATE = 'CREATE',
}

export interface SubmenuOption {
  value: ConfigurationSubpage | CalculationDetailSubpages | ProjectAdministrationSubpage;
  label: string;
  appendDivider?: boolean;
  isVisible?: boolean;
  disabled?: boolean;
}

const ConfigurationHeader: React.FC = () => {
  const [openedActionModal, setOpenedActionModal] = useState<TemplateAction>();
  const navigate = useNavigate();
  const user = useUser();
  const subpage = useSubpage();
  const dispatch = useDispatch();
  const isValidatorLoading = useValidator();
  const { departments: hasDepartments } = useApplicationData();

  const params = useParams();
  const projectShortName = params.projectShortName;
  const templateId = params.templateId;
  const templateOrCalculation = useTemplateOrCalculation();

  const templateOptionDropdownItems: DropdownProps['menu'] = useMemo(
    () => ({
      items: [
        {
          label: 'Edit current template',
          key: '1',
          icon: icons[IconType.EDIT_OUTLINED],
          onClick: () => {
            setOpenedActionModal(TemplateAction.EDIT);
          },
          style: {
            backgroundColor: COLORS.PRIMARY_LIGHT,
          },
        },
        {
          label: 'Create new',
          key: '2',
          icon: icons[IconType.PLUS_OUTLINED],
          onClick: () => {
            setOpenedActionModal(TemplateAction.CREATE);
          },
        },
        {
          type: 'divider',
        },
        {
          label: 'Delete',
          key: '3',
          icon: icons[IconType.DELETE_OUTLINED],
          onClick: () => {
            setOpenedActionModal(TemplateAction.DELETE);
          },
          danger: true,
        },
      ],
    }),
    [],
  );

  const submenuOptions: SubmenuOption[] = useMemo(() => {
    const options: SubmenuOption[] = [
      {
        value: ConfigurationSubpage.CATEGORIES,
        label: 'Categories',
      },
      {
        value: ConfigurationSubpage.REGIONS,
        label: 'Regions',
      },
      {
        value: ConfigurationSubpage.STORES,
        label: hasDepartments ? 'Stores and departments' : 'Stores',
      },
      {
        value: ConfigurationSubpage.SKUCLASSES,
        label: 'SKU classes',
      },
      {
        value: ConfigurationSubpage.PRODUCTLISTS,
        label: 'Product lists',
      },
      {
        value: ConfigurationSubpage.BRANDS,
        label: 'Brands',
        appendDivider: !user?.isSuperUser,
      },
    ];

    if (user?.isSuperUser) {
      options.push({
        value: ConfigurationSubpage.ADVANCED,
        label: 'Advanced',
        appendDivider: true,
      });
    }

    options.push({
      value: ConfigurationSubpage.DRILLDOWN,
      label: 'Drill down',
    });

    return options;
  }, [hasDepartments, user?.isSuperUser]);

  const closeOpenedActionModal = useCallback(() => {
    setOpenedActionModal(undefined);
  }, []);

  const dropdownTrigger: DropdownProps['trigger'] = useMemo(() => ['click'], []);
  const dropdownAlign: DropdownProps['align'] = useMemo(() => ({ offset: [0, 5] }), []);

  const onChangeTemplateHandler = useCallback(
    (value: number) => {
      //TODO change template!!!
      const templateId = value.toString();
      API.instance.defaults.headers.common['Template-Id'] = value;
      dispatch(resetState());
      navigate(
        generatePartialPath(
          ROUTES.configurationChildRoute,
          { projectShortName, slug: '1', templateId },
          ConfigurationSubpage.CATEGORIES,
        ),
      );
    },
    [dispatch, navigate, projectShortName],
  );

  const openCreateModalHandler = useCallback(() => {
    setOpenedActionModal(TemplateAction.CREATE);
  }, []);

  return (
    <>
      <SectionHeaderRowSpaceBetween>
        <RowCentered>
          <TemplateSelect
            onChange={onChangeTemplateHandler}
            templateId={templateId ? parseInt(templateId) : undefined}
            createButtonAction={openCreateModalHandler}
            variant="grey"
            loading={isValidatorLoading}
          />
          <Dropdown
            trigger={dropdownTrigger}
            menu={templateOptionDropdownItems}
            align={dropdownAlign}
          >
            <div>
              <MoreIconButton />
            </div>
          </Dropdown>
        </RowCentered>
        <RowCentered $padding={1}>
          <Space>
            <TemplatePotentialOverview />
          </Space>
        </RowCentered>
      </SectionHeaderRowSpaceBetween>
      <SectionHeaderRowSpaceBetweenNoBorder>
        <RowCentered $gap="1rem">
          <MenuSubpages
            routingPage={RoutingPage.CONFIGURATION}
            submenuOptions={submenuOptions}
            selectedOption={subpage}
          />
        </RowCentered>
        {subpage === ConfigurationSubpage.CATEGORIES && (
          <RowCentered $gap="1rem">
            <ConfigurationExceptions />
            <ConfigurationResetButton />
          </RowCentered>
        )}
      </SectionHeaderRowSpaceBetweenNoBorder>
      {openedActionModal === TemplateAction.EDIT && (
        <EditTemplateModal
          open={true}
          onCancel={closeOpenedActionModal}
          templateId={templateOrCalculation.id}
        />
      )}
      {openedActionModal === TemplateAction.CREATE && (
        <CreateTemplateModal
          open={true}
          onCancel={closeOpenedActionModal}
          templateId={templateOrCalculation.id}
        />
      )}
      {openedActionModal === TemplateAction.DELETE && (
        <DeleteTemplateModal
          onCancel={closeOpenedActionModal}
          templateId={templateOrCalculation.id}
        />
      )}
    </>
  );
};

export default ConfigurationHeader;
