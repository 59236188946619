import { styled, css } from 'styled-components';
import { getColorByState, InputStates } from './_constants';

export interface MessageProps {
  $state?: InputStates;
  $center?: boolean;
  $bold?: boolean;
  $size?: string;
}

export const Message = styled.span<MessageProps>`
  color: ${p => getColorByState(p.$state ?? InputStates.DEFAULT)};
  font-style: italic;
  display: inline-block;
  font-size: ${p => p.$size ?? '0.6rem'};
  line-height: normal;
  padding: 0 0.25rem;
  ${p =>
    p.$center
      ? css`
          align-self: center;
        `
      : ''}
  ${p =>
    p.$bold
      ? css`
          font-weight: 700;
        `
      : ''}
`;
