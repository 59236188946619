import React, { useCallback, useMemo } from 'react';
import {
  CategoryConfigurationDefinitionResponse,
  EntityListType,
  FieldConfigurationRequest,
  SkuType,
} from '@ydistri/api-sdk';
import ConfigurationRowTitle from '../ConfigurationRowTitle';
import { isSectionTypeSource, isSectionTypeTarget } from '../../configurationUtils';
import {
  ConfigurationFieldsWrapperCentered,
  ConfigurationFieldsWrapperTarget,
  ConfigurationRowWrapper,
} from '../ConfigurationRow';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import useCategoryId from '../../../../../hooks/useCategoryId';
import { computeRemSize, Row } from '@ydistri/ds';
import ConfigurationEntityListSelect from './ConfigurationEntityListSelect';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import { useCategoryConfiguration } from '../../../../../hooks/useCategoryConfiguration';
import { usePatchCategoryConfigurationMutation } from '../../../apiTemplate';
import { iterableSkuTypes } from '../../../../../helpers/types/iterableEnums';
import { createEntityListConfig, EntityListConfiguration } from './entityListLib';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { isTargetConfiguration } from '../segmentPotentialDefinitionLib';
import {
  getDefaultEntityListsParams,
  useGetEntityListsQuery,
} from '../../../../ProjectAdministration/EntityListAdministration/apiEntityLists';

interface ConfigurationEntityListMainRowProps {
  definition: CategoryConfigurationDefinitionResponse;
  forcedTitle?: string;
  forcedCategoryId?: number;
  fullyFeatured?: boolean;
}

const ConfigurationEntityListMainRow: React.FC<ConfigurationEntityListMainRowProps> = ({
  definition,
  forcedTitle,
  forcedCategoryId,
  fullyFeatured = true,
}) => {
  const { type } = useTemplateOrCalculation();
  const selectedCategoryId = useCategoryId();
  const categoryId = forcedCategoryId ?? selectedCategoryId;
  const { rootCategoryConfiguration } = useCategoryConfiguration(categoryId);
  const [patchConfiguration, patchConfigurationStatus] = usePatchCategoryConfigurationMutation();

  const { data } = useGetEntityListsQuery(getDefaultEntityListsParams(EntityListType.MinLayerList));

  const status = useMemo(
    () => rtkQueryPatchStatusToConfigurationFieldStatus(patchConfigurationStatus),
    [patchConfigurationStatus],
  );

  const configuredEntityLists = useMemo(() => {
    if (!rootCategoryConfiguration) return [];
    const config = rootCategoryConfiguration[definition.fieldType];
    const usedEntityListObject: Record<number, boolean | undefined> = {};
    const usedEntityListIds: number[] = [];

    iterableSkuTypes.forEach(st => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const json = JSON.parse(config[st]) as EntityListConfiguration[];
      json.forEach(c => {
        if (usedEntityListObject[c.EntityListId] === undefined) {
          usedEntityListIds.push(c.EntityListId);
          usedEntityListObject[c.EntityListId] = c.IsEnabled === 1;
        }
      });
    });

    return (data ?? []).filter(
      d =>
        d.entityListTypeId === EntityListType.MinLayerList &&
        usedEntityListIds.includes(d.entityListId),
    );
  }, [data, definition.fieldType, rootCategoryConfiguration]);

  const notConfiguredEntityLists = useMemo(
    () =>
      (data ?? []).filter(
        l =>
          l.entityListTypeId === EntityListType.MinLayerList &&
          !configuredEntityLists.find(ml => ml.entityListId === l.entityListId),
      ),
    [configuredEntityLists, data],
  );

  const listOptions: DefaultOptionType[] = useMemo(() => {
    return [
      {
        id: -1,
        label: 'Add list',
        value: -1,
      },
      ...notConfiguredEntityLists
        .filter(o => !o.isArchived && !o.entityListImport)
        .map(option => ({
          id: option.entityListId,
          label: option.name,
          value: option.entityListId,
        })),
    ];
  }, [notConfiguredEntityLists]);

  //TODO: check for "Target" settings => SkuTypes can be problem
  const changeHandler = useCallback(
    (value: number) => {
      if (!rootCategoryConfiguration) return;
      if (categoryId > 1) return;
      const configRoot = rootCategoryConfiguration[definition.fieldType];
      const payloadRoot: FieldConfigurationRequest[] = [];
      const isTarget = isTargetConfiguration(definition.sectionType);

      iterableSkuTypes.forEach(st => {
        if (isTarget && st !== SkuType.FastMover) return;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const jsonRoot = JSON.parse(configRoot[st]) as EntityListConfiguration[];
        jsonRoot.push(createEntityListConfig(value, categoryId === 1 ? 1 : 0));

        payloadRoot.push({
          configurationFieldType: definition.fieldType,
          value: JSON.stringify(jsonRoot),
          skuType: isTarget ? undefined : st,
        });
      });

      if (payloadRoot.length > 0) {
        patchConfiguration({
          categoryId: 1,
          payload: payloadRoot,
        });
      }
    },
    [
      rootCategoryConfiguration,
      categoryId,
      definition.fieldType,
      definition.sectionType,
      patchConfiguration,
    ],
  );

  const { type: templateOrCalculationType } = useTemplateOrCalculation();

  const fieldsDisabled = useMemo(
    () => templateOrCalculationType === 'Calculation' || categoryId > 1,
    [templateOrCalculationType, categoryId],
  );

  const title = forcedTitle ?? definition?.title;

  return (
    <ConfigurationRowWrapper>
      <ConfigurationRowTitle
        definition={definition}
        forcedTitle={title}
        fullyFeatured={fullyFeatured}
      />
      {isSectionTypeSource(definition.sectionType) && type !== 'Calculation' && (
        <ConfigurationFieldsWrapperCentered>
          {fullyFeatured && (
            <Row $padding={1} $width="100%">
              <ConfigurationEntityListSelect
                options={listOptions}
                onChange={changeHandler}
                disabled={status === ConfigurationFieldStatus.LOADING || fieldsDisabled}
              />
            </Row>
          )}
        </ConfigurationFieldsWrapperCentered>
      )}
      {isSectionTypeTarget(definition.sectionType) && type !== 'Calculation' && (
        <ConfigurationFieldsWrapperTarget>
          {fullyFeatured && (
            <Row $padding={1} $width="100%" $justifyContent="center">
              <ConfigurationEntityListSelect
                options={listOptions}
                onChange={changeHandler}
                disabled={status === ConfigurationFieldStatus.LOADING || fieldsDisabled}
                width={computeRemSize(150)}
              />
            </Row>
          )}
        </ConfigurationFieldsWrapperTarget>
      )}
    </ConfigurationRowWrapper>
  );
};

export default ConfigurationEntityListMainRow;
