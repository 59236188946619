import {
  ExcessiveStockPotentialInfo,
  LOCKED_BY,
  sumLockedByOptions,
} from '../../categoryInsightsLib';

import { BarLinePartitionProps } from '@ydistri/ds';
import ExcessiveStockBarTooltipContent from './ExcessiveStockBarTooltipContent';
import { computePercentage } from '@ydistri/utils';
import { CSSProperties } from 'react';

export interface ExcessiveStockBarSection {
  title: string;
  fields: (typeof LOCKED_BY)[number][];
  highlighted?: boolean;
}

export const getBarDataFromSection = (
  section: ExcessiveStockBarSection,
  data: ExcessiveStockPotentialInfo,
  highlightedColor: CSSProperties['backgroundColor'] = undefined,
): BarLinePartitionProps => {
  const sum = section.fields.reduce((p, c) => p + data[c], 0);
  const totalSum = sumLockedByOptions(data);

  return {
    percentage: computePercentage(sum, totalSum, 2),
    tooltip: <ExcessiveStockBarTooltipContent data={data} section={section} />,
    minSizeToDisplayContent: 16,
    highlightedPercentage: section.highlighted && highlightedColor ? 100 : undefined,
    color: section.highlighted ? highlightedColor : undefined,
  };
};
