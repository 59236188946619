import React, { useCallback, useMemo } from 'react';
import {
  useGetSingleProductQuery,
  useGetSingleStoreQuery,
  useGetSkuClassesQuery,
} from '../../../apiDetail';
import { ColumnsType } from 'antd/es/table';
import { SimpleTable } from '../../../../CalculationDetail/Statistics/components/SimpleTable';
import { computeRemSize, EntityId, Label, ReadOnlyItem, Row, Text } from '@ydistri/ds';
import { useDetail, useDetailSkuEntity } from '../../../hooks/useDetail';
import SimpleList from './SimpleList';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import {
  CategoryTreeNode,
  useGetCategoryTreeQuery,
} from '../../../../../components/global/CategoryTree/apiCategoryTree';
import { styled } from 'styled-components';
import useCategoryId from '../../../../../hooks/useCategoryId';
import {
  constructCategoryPath,
  getCategories,
  isRootCategory,
} from '../../../../../lib/categories/categoriesLib';
import CategoriesList from './CategoriesList';
import { createSearchParams, generatePath, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../../../components/menu/menuLeftItemTemplate';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import { UrlParams } from '../../../detailTypes';
import LinkIcon from '../../../../../components/global/Link/LinkIcon';
import YLink from '../../../../../components/global/Link/YLink';
import { useGetRegionsQuery } from '../../../../../apis/apiLists';
import { useApplicationData } from '../../../../../hooks/useApplicationData';

interface CompoundValue {
  label: string;
  value: string | string[] | React.ReactNode;
}

interface EntityRecord extends CompoundValue {
  id?: number;
  code?: string | null;
  systemId?: number;
  additionalData?: CompoundValue;
}

export const EntitiesTableWrapper = styled.div`
  //min-width: 32%;
  width: min(100%, ${computeRemSize(500)});
  display: flex;
  flex-grow: 1;

  & > div {
    width: 100%;
  }
`;

export const EntitiesSimpleTable = styled(SimpleTable<EntityRecord>)`
  .ant-table-tbody .ant-table-cell {
    vertical-align: top;
  }
`;

const EntitiesTable: React.FC = () => {
  const { projectShortName } = useParams();
  const [searchParams] = useSearchParams();
  const skuEntity = useDetailSkuEntity();
  const selectedCategoryId = useCategoryId();
  const selectedCategorySlug = useSelector(
    (state: ReduxState) => state.categoryTreeReducer.currentlySelectedCategorySlug,
  );
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories } = useGetCategoryTreeQuery(templateOrCalculation);

  const { departments: hasDepartments } = useApplicationData();

  const { storeId, productId, regionId } = useDetail('storeId', 'productId', 'regionId');

  const skuStoreId = skuEntity?.values.WarehouseId
    ? parseInt(skuEntity?.values.WarehouseId)
    : undefined;

  const { data: productEntity } = useGetSingleProductQuery(productId);
  const { data: storeEntity } = useGetSingleStoreQuery(skuStoreId ?? storeId);
  const { data: regionEntities } = useGetRegionsQuery(templateOrCalculation);
  const { data: skuClasses } = useGetSkuClassesQuery({
    skuClassId: parseInt(skuEntity?.values.SkuClassId ?? '-1'),
  });

  const columns = useMemo((): ColumnsType<EntityRecord> => {
    return [
      {
        dataIndex: 'label',
        width: '7rem',
        render: (text: string) => {
          return <Label>{`${text}:`}</Label>;
        },
      },
      {
        dataIndex: 'value',
        width: '45rem',
        render: (value: string | string[] | React.ReactNode, record: EntityRecord) => {
          if (Array.isArray(value)) {
            return <SimpleList values={value} />;
          } else {
            if (typeof value === 'string') {
              return (
                <Row $gap={computeRemSize(8)} $alignItems="center">
                  <Text>{value}</Text>
                  {(record.id || record.systemId) && (
                    <EntityId
                      id={record.id ?? null}
                      code={record.code}
                      systemId={record.systemId ?? null}
                    />
                  )}
                </Row>
              );
            } else {
              return value;
            }
          }
        },
      },

      {
        dataIndex: 'additionalData',
        render: (additionalData: CompoundValue) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          additionalData ? (
            <ReadOnlyItem label={additionalData.label} value={additionalData.value} />
          ) : null,
      },
    ];
  }, []);

  const data = useMemo((): EntityRecord[] => {
    const result: EntityRecord[] = [];

    if (productEntity) {
      const productLabel = 'Product';
      const productId = productEntity.id;

      //only create the link when we are looking at a SKU
      const showProductAsLink =
        searchParams.has(UrlParams.SKU) ||
        (searchParams.has(UrlParams.PRODUCT) && searchParams.has(UrlParams.STORE));

      if (showProductAsLink) {
        const path = generatePath(ROUTES.detail, {
          projectShortName: projectShortName ?? '',
          slug: selectedCategorySlug,
        });

        const newSearchParams = createSearchParams([['product', productEntity.id.toString()]]);
        const pathWithSearch = `${path}?${newSearchParams.toString()}`;

        result.push({
          id: productId,
          label: productLabel,
          value: (
            <Row $gap="4px" $alignItems="center">
              <YLink to={pathWithSearch}>
                <Row $gap="0.6rem" $alignItems="center">
                  <Text>{productEntity.name}</Text>
                  <LinkIcon url={pathWithSearch} />
                </Row>
              </YLink>
              <EntityId
                id={productEntity.customerId ?? null}
                code={productEntity.code}
                systemId={productEntity.id}
              />
            </Row>
          ),
        });
      } else {
        result.push({
          id: productId,
          label: productLabel,
          value: (
            <Row $gap={computeRemSize(8)} $alignItems="center">
              <Text>{productEntity.name}</Text>
              <EntityId
                id={productEntity.customerId ?? null}
                code={productEntity.code}
                systemId={productEntity.id}
              />
            </Row>
          ),
        });
      }

      if (hasDepartments) {
        result.push({
          label: 'Department',
          value: (
            <Row $gap={computeRemSize(8)} $alignItems="center">
              <Text>{productEntity.department?.name}</Text>
              <EntityId
                id={productEntity.department?.customerDepartmentId ?? null}
                systemId={productEntity.department?.id ?? null}
              />
            </Row>
          ),
        });
      }
    }

    if (regionEntities && !storeEntity) {
      const region = regionEntities.object[regionId];
      if (region) {
        result.push({
          label: 'Region',
          value: region.name,
          systemId: region.id,
        });
      }
    }

    if (storeEntity) {
      result.push({
        id: storeEntity.id,
        label: 'Store',
        value: storeEntity.name,
        code: storeEntity.code,
        systemId: storeEntity.id,
      });
      result.push({
        label: 'Region',
        value: storeEntity.region?.name,
        systemId: storeEntity.region?.id,
      });
    }

    if (productEntity) {
      result.push({
        label: 'Brand',
        value: productEntity.brand?.name,
        systemId: productEntity.brand?.id,
      });
    }

    if (skuClasses && skuClasses.length > 0) {
      result.push({
        label: 'SKU Class',
        value: skuClasses[0].name,
        systemId: skuClasses[0].id,
      });
    }

    if (categories) {
      let categoryNodes: CategoryTreeNode[] = [];

      if (productEntity) {
        const productCategories = productEntity.allCategoryIds?.filter(id => !isRootCategory(id));
        if (productCategories) {
          categoryNodes = getCategories(productCategories, categories);
        }
      } else {
        //if the selected category is not the root category
        if (!isRootCategory(selectedCategoryId)) {
          const categoryPath = constructCategoryPath(selectedCategoryId, categories).filter(
            id => !isRootCategory(id),
          );
          categoryNodes = getCategories(categoryPath, categories);
        }
      }

      if (categoryNodes.length > 0) {
        result.push({
          label: 'Categories',
          value: <CategoriesList categories={categoryNodes} />,
        });
      }
    }

    return result;
  }, [
    categories,
    hasDepartments,
    productEntity,
    projectShortName,
    regionEntities,
    regionId,
    searchParams,
    selectedCategoryId,
    selectedCategorySlug,
    skuClasses,
    storeEntity,
  ]);

  const rowKeyProvider = useCallback((record: EntityRecord) => {
    return `${record.label}_${record.id ?? ''}`;
  }, []);

  const rowClassProvider = useCallback((record: EntityRecord) => {
    if (record.label === 'Categories') {
      return 'categories';
    }

    return '';
  }, []);

  if (data.length > 0) {
    return (
      <EntitiesTableWrapper>
        <EntitiesSimpleTable
          id="DetailEntityRecords"
          columns={columns}
          dataSource={data}
          rowKey={rowKeyProvider}
          rowClassName={rowClassProvider}
        />
      </EntitiesTableWrapper>
    );
  } else {
    return null;
  }
};

export default EntitiesTable;
