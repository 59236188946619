import { ActionsBar } from '@ydistri/ds';
import ExitImportDisplayModeAction from '../actions/ExitImportDisplayModeAction';
import React from 'react';

const RedistributionImportActionsBar: React.FC = () => {
  return (
    <ActionsBar>
      <ExitImportDisplayModeAction />
    </ActionsBar>
  );
};

export default RedistributionImportActionsBar;
