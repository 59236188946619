import React, { useCallback, useMemo } from 'react';
import { GenericConfirmationModal } from '@ydistri/ds';
import { useTemplate } from '../../hooks/useTemplate';
import { useDeleteTemplateMutation } from './apiTemplate';
import { useDispatch } from 'react-redux';
import { setIsDeletingTemplate } from '../../store/userSlice';

interface DeleteTemplateModalProps {
  templateId: number;
  onCancel: () => void;
}

const DeleteTemplateModal: React.FC<DeleteTemplateModalProps> = ({ templateId, onCancel }) => {
  const dispatch = useDispatch();
  const template = useTemplate(templateId);
  const [deleteTemplate, { isLoading }] = useDeleteTemplateMutation();
  const modalTitle = useMemo(
    () => `Delete template #${template?.id ?? ''} ${template?.title ?? ''}?`,
    [template?.id, template?.title],
  );

  const deleteTemplateHandler = useCallback(() => {
    dispatch(setIsDeletingTemplate(templateId));
    deleteTemplate(templateId).then(() => {
      onCancel();
    });
  }, [deleteTemplate, dispatch, onCancel, templateId]);

  return (
    <GenericConfirmationModal
      data-testid="ResetConfigurationConfirmationModal"
      title={modalTitle}
      message="Calculations based on this template will not be affected."
      onConfirmed={deleteTemplateHandler}
      onCanceled={onCancel}
      running={isLoading}
      confirmationButtonLabel="Delete"
    />
  );
};

export default DeleteTemplateModal;
