import React, { useMemo } from 'react';
import CreateCalculationModal, {
  FormFields,
} from '../../../Modals/CreateCalculationModal/CreateCalculationModal';
import useMergedCalculationSources from '../../hooks/useMergedCalculationSources';
import { CalculationType } from '@ydistri/api-sdk';
import { CalculationDataType } from '../../calculationsTypes';

interface CreateCalculationWithDefaultsModalProps {
  calculation: CalculationDataType;
  onClose: () => void;
}

/**
 * Opens a modal for creating a calculation with default values from the given calculation.
 * The modal is pre-filled with the title, description, the calculation mode of the given calculation
 * and the manual calculation and templates used in the given calculation.
 * @param calculation
 * @param onClose
 * @constructor
 */
const CreateCalculationWithDefaultsModal: React.FC<CreateCalculationWithDefaultsModalProps> = ({
  calculation,
  onClose,
}) => {
  const { sources: calculationSources, isPending } = useMergedCalculationSources(calculation.id);

  const createCalculationInitialValues = useMemo((): FormFields => {
    return {
      title: `${calculation.title} - copy`,
      description: calculation.description ?? '',
      private: calculation.type === CalculationType.Private,
      calculationMode: calculation.mode,
      manualCalculation: calculationSources.manualCalculation,
      mergedCalculationTemplates: calculationSources.templates,
      overlappingCalculations: calculationSources.overlappingCalculations,
    };
  }, [
    calculation.description,
    calculation.mode,
    calculation.title,
    calculation.type,
    calculationSources.manualCalculation,
    calculationSources.overlappingCalculations,
    calculationSources.templates,
  ]);

  if (!isPending) {
    return (
      <CreateCalculationModal
        open={true}
        onCancel={onClose}
        displayTemplateSelect={true}
        initialValues={createCalculationInitialValues}
      />
    );
  }
};

export default CreateCalculationWithDefaultsModal;
