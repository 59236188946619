import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../apiCalculationDetail';
import { setSubpage } from '../../../routes/routerSlice';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { CalculationType } from '@ydistri/api-sdk';
import { Helmet } from 'react-helmet';
import SectionStorePickingResults from './components/SectionStorePickingResults';
import { MainContent, MainContentWrapper } from '@ydistri/ds';

/**
 * Main component for this section. Displays a header and a table for each source store.
 * Header contains overall calculation's picking status and a set of values/totals
 * This part follows the design of CalculationEvaluation component.
 */
const CalculationPickingResults: React.FC = () => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData, isFetching } = useGetCalculationQuery(templateOrCalculation.id);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(setSubpage(CalculationDetailSubpages.PICKING_RESULTS));
  }, [dispatch]);

  if (isFetching) return <LoadingIndicator />;
  if (!(calculationData?.type === CalculationType.Production && calculationData?.isPap))
    return <div>Calculation is not set for picking, picking results are not available.</div>;

  return (
    <>
      <Helmet title={`Calculation ${templateOrCalculation.id} - Picking`} />
      <MainContentWrapper $flexDirection="column">
        <MainContent>
          <SectionStorePickingResults />
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default CalculationPickingResults;
