import React from 'react';
import BrandRedistributionFilter from './BrandRedistributionFilter';
import { Column } from '@ydistri/ds';
import OutOfStockSKUFilter from './OutOfStockSKUFilter';
import ResetFiltersButton from './ResetFiltersButton';
import ForcedRedistributionFilter from './ForcedRedistributionFilter';

/**
 * All filters for the calculation's redistribution and their reset button.
 * aligned vertically. Used as a content for a dialog or popover.
 */
const CalculationRedistributionFilters: React.FC = () => {
  return (
    <Column $alignItems="flex-start" $gap="1rem">
      <BrandRedistributionFilter />
      <OutOfStockSKUFilter />
      <ForcedRedistributionFilter />
      <ResetFiltersButton />
    </Column>
  );
};

export default CalculationRedistributionFilters;
