export interface TargetListItemsProductStatistics {
  /** How many products were fully filled */
  fullyFilledProductsCount: number;
  /** How many products were transferred in total */
  productsCount: number;
}

export interface TargetListItemStats {
  product: { id: number };
  receivedQuantityPercentage: number;
}

/**
 * Returns information about the products in the target list items.
 * We are interested in the following information:
 * - how many products were fully filled.
 *
 * One product may be in multiple target list items (sent from different stores).
 * Using the least common denominator, for the type of the product, we can compute, so
 * we can write tests for this function without having to mock the whole data structure.
 * @param items
 */
export const computeProductStatistics = (
  items: TargetListItemStats[],
): TargetListItemsProductStatistics => {
  /*
  Let's store products in Map, where key is product id and value is array of target list items
   */
  const products = new Map<number, TargetListItemStats[]>();
  items.forEach(item => {
    const productId = item.product.id;
    if (products.has(productId)) {
      products.get(productId)?.push(item);
    } else {
      products.set(productId, [item]);
    }
  });

  //now lets process the products and extract only products that have receivedQuantityPercentage === 100
  const fullyFilledProducts = new Map<number, TargetListItemStats[]>();
  products.forEach((items, productId) => {
    const fullyFilledItems = items.every(item => item.receivedQuantityPercentage === 100);
    if (fullyFilledItems) {
      fullyFilledProducts.set(productId, items);
    }
  });

  return {
    productsCount: products.size,
    fullyFilledProductsCount: fullyFilledProducts.size,
  };
};
