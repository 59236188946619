import { RegionFlowType } from '@ydistri/api-sdk';
import { ValidatorSection } from './validatorLib';

// ====================== CATEGORIES ======================
export interface ValidatorInfoCategories {
  totalExceptions: number;
  isStandard: boolean;
  isForced: boolean;
  isForcedIncreased: boolean;
  isTargetList: boolean;
  isForecast: boolean;
  isMinLayer: boolean;
  isStockout: boolean;
}

export const initialValidatorInfoCategories: ValidatorInfoCategories = {
  totalExceptions: 0,
  isStandard: false,
  isForced: false,
  isForecast: false,
  isForcedIncreased: false,
  isTargetList: false,
  isMinLayer: false,
  isStockout: false,
};

// ====================== REGIONS ======================

export interface ValidatorInfoRegions {
  flowType: RegionFlowType;
  customRegionGroupCount: number;
}

export const initialValidatorInfoRegions: ValidatorInfoRegions = {
  flowType: RegionFlowType.AllEnabled,
  customRegionGroupCount: 0,
};

// ====================== STORES AND DEPARTMENTS ======================

export interface ValidatorInfoStoresAndDepartments {
  exceptionCount: number;
  isClosingUsed: boolean;
  isSuperTargetUsed: boolean;
  isClosingAndSuperTargetAtOnce: number;
}

export const initialValidatorInfoStoresAndDepartments: ValidatorInfoStoresAndDepartments = {
  exceptionCount: 0,
  isClosingUsed: false,
  isSuperTargetUsed: false,
  isClosingAndSuperTargetAtOnce: 0,
};

// ====================== SKU CLASSES ======================

export interface ValidatorInfoSkuClasses {
  useAsSourceCount: number;
  useAsTargetCount: number;
  enabledCount: number;
  enabledButNotUsedCount: number;
}

export const initialValidatorInfoSkuClasses: ValidatorInfoSkuClasses = {
  useAsSourceCount: 0,
  useAsTargetCount: 0,
  enabledCount: 0,
  enabledButNotUsedCount: 0,
};

// ====================== PRODUCT LISTS ======================

export interface ValidatorInfoProductLists {
  allEnabled: boolean;
  exceptionCount: number;
}

export const initialValidatorInfoProductLists: ValidatorInfoProductLists = {
  allEnabled: true,
  exceptionCount: 0,
};

// ====================== BRANDS ======================

export interface ValidatorInfoBrands {
  allEnabled: boolean;
  exceptionCount: number;
}

export const initialValidatorInfoBrands: ValidatorInfoBrands = {
  allEnabled: true,
  exceptionCount: 0,
};

// ==================================================================

interface ValidatorMapping {
  [ValidatorSection.CATEGORIES]: ValidatorInfoCategories;
  [ValidatorSection.REGIONS]: ValidatorInfoRegions;
  [ValidatorSection.STORES_AND_DEPARTMENTS]: ValidatorInfoStoresAndDepartments;
  [ValidatorSection.SKU_CLASSES]: ValidatorInfoSkuClasses;
  [ValidatorSection.PRODUCT_LISTS]: ValidatorInfoProductLists;
  [ValidatorSection.BRANDS]: ValidatorInfoBrands;
}

export type ValidatorInfo = {
  [K in ValidatorSection]: ValidatorMapping[K];
};

export type ValidatorOverviewMessage = {
  [K in ValidatorSection]: string;
};

export const getInitialValidatorInfo = (): ValidatorInfo => ({
  [ValidatorSection.CATEGORIES]: { ...initialValidatorInfoCategories },
  [ValidatorSection.REGIONS]: { ...initialValidatorInfoRegions },
  [ValidatorSection.STORES_AND_DEPARTMENTS]: { ...initialValidatorInfoStoresAndDepartments },
  [ValidatorSection.SKU_CLASSES]: { ...initialValidatorInfoSkuClasses },
  [ValidatorSection.PRODUCT_LISTS]: { ...initialValidatorInfoProductLists },
  [ValidatorSection.BRANDS]: { ...initialValidatorInfoBrands },
});

export const initialValidatorOverviewMessage: ValidatorOverviewMessage = {
  [ValidatorSection.CATEGORIES]: '',
  [ValidatorSection.REGIONS]: '',
  [ValidatorSection.STORES_AND_DEPARTMENTS]: '',
  [ValidatorSection.SKU_CLASSES]: '',
  [ValidatorSection.PRODUCT_LISTS]: '',
  [ValidatorSection.BRANDS]: '',
};
