import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './app/app';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { StoreProvider } from './app/store';
import { registerChartJs } from '@ydistri/utils';

registerChartJs();

const environment = import.meta.env.VITE_REACT_APP_FRONTEND_ENVIRONMENT;

export const sentryReplay = Sentry.replayIntegration({
  maskAllText: false,
  blockAllMedia: false,
});

Sentry.init({
  dsn: 'https://83f37b4ea5914dec909c03916d71525d@o323022.ingest.sentry.io/5804134',
  environment,
  release: `ydistri-frontend@${import.meta.env.VITE_REACT_APP_VERSION ?? 'development'}`,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(import.meta.env.VITE_REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? '0.1'),

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: parseFloat(
    import.meta.env.VITE_REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? '0',
  ),

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: environment !== 'local' ? 1 : 0,

  //error filtering in sentry: https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    'Server returned handshake error: Handshake was canceled.',
    'Request failed with status code 404',
    'Request aborted',
    'ResizeObserver loop completed with undelivered notifications.',
    /^canceled$/, //exact match by regex
  ],

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    sentryReplay,
  ],
});

sentryReplay.startBuffering();

const rootElement = document.getElementById('root');
if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
      <StrictMode>
        <StoreProvider>
          <App />
        </StoreProvider>
      </StrictMode>
      <div id="chartjs-tooltip" className="graph-tooltip"></div>
    </Sentry.ErrorBoundary>,
  );
} else {
  alert('Cannot render application, root element was not found');
}
