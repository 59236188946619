import React from 'react';
import { ProjectSelect } from './ProjectSelect';
import UserMenu from './UserMenu';
import { MenuTopWrapper, TopMenuPart } from '@ydistri/ds';
import SignalrConnectionStatus from './SignalrConnectionStatus';

const MenuTop: React.FC = () => {
  return (
    <MenuTopWrapper>
      <TopMenuPart>
        <ProjectSelect />
      </TopMenuPart>
      <TopMenuPart>
        <SignalrConnectionStatus />
        <UserMenu />
      </TopMenuPart>
    </MenuTopWrapper>
  );
};

export default MenuTop;
