import { useTheme } from 'styled-components';
import ToggleIconButton, {
  ToggleIconButtonProps,
} from '../../../components/buttons/ToggleIconButton';
import React from 'react';

const CalculationAction: React.FC<ToggleIconButtonProps> = props => {
  const theme = useTheme();

  return (
    <ToggleIconButton
      colorScheme={theme.iconColorSchemes.none}
      colorSchemeChecked={theme.iconColorSchemes.default}
      {...props}
    />
  );
};

export default CalculationAction;
