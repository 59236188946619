import React, { PropsWithChildren, useMemo } from 'react';
import OverlayMessage, { OverlayMessageIcon } from '../ContentOverlay/OverlayComponents';
import { Overlay } from '../Overlay';

interface LoadingDataOverlayProps extends PropsWithChildren {
  title?: string;
  active?: boolean;
}

const LoadingDataOverlay: React.FC<LoadingDataOverlayProps> = ({
  title = 'Loading',
  active,
  children,
}) => {
  const content = useMemo(
    () => <OverlayMessage icon={OverlayMessageIcon.SPINNER} title={title} />,
    [title],
  );

  return (
    <Overlay
      active={active}
      overlayContent={content}
      contentIsCentered={false}
      contentIsFlex={false}
      contentIsRow={false}
    >
      {children}
    </Overlay>
  );
};

export default LoadingDataOverlay;
