import React, { useMemo } from 'react';
import { Column, computeRemSize, Row, Title } from '@ydistri/ds';
import ScopeTypeSelect from './ScopeTypeSelect';
import { MultiselectCustomerPartialEntity } from './ConfigurationMultiselect';
import {
  configurationRuleScopeModeColor,
  configurationRuleScopeModeTitle,
  scopeEntityTitle,
  ViewMode,
} from './scopeLib';
import ScopeConfigurationMultiselect from './ScopeConfigurationMultiselect';
import ScopeMultiselectCounter from './ScopeMultiselectCounter';
import ScopeViewMode from './ScopeViewMode';
import { ConfigurationRuleScopeEntityType, ConfigurationRuleScopeMode } from '@ydistri/api-sdk';
import { useScope } from './useScope';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { Tag } from 'antd';
import { styled } from 'styled-components';
import { useOpenScopeModal } from './useOpenScopeModal';

const SelectionOptionTag = styled(Tag)<{ $disabled: boolean }>`
  width: 80px;
  cursor: ${p => (p.$disabled ? 'initial' : 'pointer')};
  margin: ${computeRemSize(3)};
`;

interface ScopeEntitySelectionProps {
  scopeId: number;
  entityType: ConfigurationRuleScopeEntityType;
  options: MultiselectCustomerPartialEntity[];
  loading?: boolean;
  disabled?: boolean;
  viewMode: ViewMode;
}

const ScopeEntitySelection: React.FC<ScopeEntitySelectionProps> = ({
  scopeId,
  entityType,
  options,
  loading,
  disabled = false,
  viewMode,
}) => {
  const entityTitle = scopeEntityTitle[entityType];
  const scopeById = useScope(scopeId);
  const modalScope = useSelector((state: ReduxState) => state.scope.modalScope);

  const scope = useMemo(
    () =>
      [ViewMode.ADJUST_SCOPE_MODAL, ViewMode.NEW_EXCEPTION_MODAL].includes(viewMode)
        ? modalScope
        : scopeById,
    [modalScope, scopeById, viewMode],
  );

  const openModalHandler = useOpenScopeModal(scope);

  const selectionOption = scope?.entities[entityType].selectionOption;
  const selectedIds = scope?.entities[entityType].selectedIds;

  if (
    selectionOption === ConfigurationRuleScopeMode.All &&
    viewMode === ViewMode.EXCEPTION &&
    scope?.priority !== 0
  )
    return null;
  // && !isModal

  return (
    <Row $gap="1rem" $width="100%" $flexWrap="nowrap">
      <Row $width="230px" $minWidth="230px" $justifyContent="space-between">
        <Title $size="small">{entityTitle}</Title>
        {[ViewMode.EXCEPTION, ViewMode.GENERAL_CONFIGURATION].includes(viewMode) &&
          selectionOption && (
            <SelectionOptionTag
              onClick={openModalHandler}
              color={configurationRuleScopeModeColor[selectionOption]}
              $disabled={disabled}
            >
              {configurationRuleScopeModeTitle[selectionOption]}
            </SelectionOptionTag>
          )}
      </Row>
      {[ViewMode.EXCEPTION, ViewMode.GENERAL_CONFIGURATION].includes(viewMode) && (
        <ScopeViewMode scopeId={scopeId} entityType={entityType} options={options} />
      )}
      {[ViewMode.ADJUST_SCOPE_MODAL, ViewMode.NEW_EXCEPTION_MODAL].includes(viewMode) && (
        <Column $gap="1rem" $flexGrow={1} $alignItems="end">
          {selectionOption && (
            <ScopeTypeSelect
              playground={scope.priority === 0}
              selectionOption={selectionOption}
              entityType={entityType}
            />
          )}
          {selectionOption !== ConfigurationRuleScopeMode.All && (
            <ScopeConfigurationMultiselect
              selectedIds={selectedIds}
              entityType={entityType}
              data={options}
              loading={loading}
            />
          )}
          {selectionOption !== ConfigurationRuleScopeMode.All && (
            <ScopeMultiselectCounter selectedIds={selectedIds} entityType={entityType} />
          )}
        </Column>
      )}
    </Row>
  );
};

export default ScopeEntitySelection;
