import { TemplateResponse } from '@ydistri/api-sdk';
import { useGetTemplatesQuery } from '../apis/apiApplication';
import { getApiHeader } from '../apis/getApiHeader';
import { API } from '../swagger/collections';
import { skipToken } from '@reduxjs/toolkit/query';

export const useTemplate = (templateId: number): TemplateResponse | undefined => {
  const { template } = useGetTemplatesQuery(getApiHeader(API, 'Project-Code') ?? skipToken, {
    selectFromResult: ({ data }) => {
      return { template: data?.find(template => template.id === templateId) };
    },
  });

  return template;
};
