import React, { useMemo } from 'react';
import {
  Text,
  Title,
  BarLine,
  BarLineProps,
  BarLineSizes,
  Arrow,
  COLORS,
  Column,
  ArrayTooltipContent,
} from '@ydistri/ds';
import { InsightsSourceData } from '../categoryInsightsLib';
import { computePercentage, shortenNumber } from '@ydistri/utils';
import { useCurrency } from '../../../../hooks/useCurrency';

interface PortfolioStructureBarProps {
  data: InsightsSourceData;
}

const PortfolioStructureBar: React.FC<PortfolioStructureBarProps> = ({ data }) => {
  const currency = useCurrency();

  const tooltipData = useMemo(() => {
    return {
      deadstockTooltipData: [
        {
          title: 'Dead stock',
          value: data.excessiveStockTotals.deadstock,
          postfix: currency,
          isTitle: true,
        },
        {
          title: 'Potential',
          value: data.excessiveStock.deadstock.ExcessivePotentialTotalValue,
          postfix: currency,
        },
      ],

      slowmoversTooltipData: [
        {
          title: 'Slow movers',
          value: data.excessiveStockTotals.slowmovers,
          postfix: currency,
          isTitle: true,
        },
        {
          title: 'Potential',
          value: data.excessiveStock.slowmovers.ExcessivePotentialTotalValue,
          postfix: currency,
        },
      ],

      fastmoversTooltipData: [
        {
          title: 'Fast movers',
          value: data.excessiveStockTotals.fastmovers,
          postfix: currency,
          isTitle: true,
        },
        {
          title: 'Potential',
          value: data.excessiveStock.fastmovers.ExcessivePotentialTotalValue,
          postfix: currency,
        },
      ],

      forcedTooltipData: [
        {
          title: 'Forced',
          value: data.eligibleSupply.forced,
          postfix: currency,
          isTitle: true,
        },
      ],

      closingTooltipData: [
        {
          title: 'Closing',
          value: data.eligibleSupply.isClosing,
          postfix: currency,
          isTitle: true,
        },
      ],

      healthyTooltipData: [
        {
          title: 'Healthy stock',
          value: data.eligibleSupply.healthy,
          postfix: currency,
          isTitle: true,
        },
      ],

      outgoingTooltipData: [
        {
          title: 'Outgoing stock',
          value: data.eligibleSupply.outgoing,
          postfix: currency,
          isTitle: true,
        },
      ],
    };
  }, [
    currency,
    data.eligibleSupply.forced,
    data.eligibleSupply.healthy,
    data.eligibleSupply.isClosing,
    data.eligibleSupply.outgoing,
    data.excessiveStock.deadstock.ExcessivePotentialTotalValue,
    data.excessiveStock.fastmovers.ExcessivePotentialTotalValue,
    data.excessiveStock.slowmovers.ExcessivePotentialTotalValue,
    data.excessiveStockTotals.deadstock,
    data.excessiveStockTotals.fastmovers,
    data.excessiveStockTotals.slowmovers,
  ]);

  const barLineData: BarLineProps['data'] = useMemo(() => {
    const totalValues = data.sourceEligibleSupply;
    const percentageDeadStock = computePercentage(data.excessiveStockTotals.deadstock, totalValues);
    const highlightedPercentageDeadStock = computePercentage(
      data.excessiveStock.deadstock.ExcessivePotentialTotalValue,
      data.excessiveStockTotals.deadstock,
    );
    const percentageSlowMovers = computePercentage(
      data.excessiveStockTotals.slowmovers,
      totalValues,
    );
    const highlightedPercentageSlowMovers = computePercentage(
      data.excessiveStock.slowmovers.ExcessivePotentialTotalValue,
      data.excessiveStockTotals.slowmovers,
    );
    const percentageFastMovers = computePercentage(
      data.excessiveStockTotals.fastmovers,
      totalValues,
    );
    const highlightedPercentageFastMovers = computePercentage(
      data.excessiveStock.fastmovers.ExcessivePotentialTotalValue,
      data.excessiveStockTotals.fastmovers,
    );
    const percentageForced = computePercentage(data.eligibleSupply.forced, totalValues);
    const percentageIsClosing = computePercentage(data.eligibleSupply.isClosing, totalValues);
    const percentageOutgoing = computePercentage(data.eligibleSupply.outgoing, totalValues);
    const percentageHealthy =
      100 -
      percentageDeadStock -
      percentageSlowMovers -
      percentageFastMovers -
      percentageForced -
      percentageIsClosing -
      percentageOutgoing;

    return [
      {
        percentage: percentageDeadStock,
        highlightedPercentage: highlightedPercentageDeadStock,
        color: COLORS.BAR_DEADSTOCK,
        tooltip: <ArrayTooltipContent data={tooltipData.deadstockTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Dead stock
            </Text>
            <Text>{shortenNumber(data.excessiveStockTotals.deadstock)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 45,
      },
      {
        percentage: percentageSlowMovers,
        highlightedPercentage: highlightedPercentageSlowMovers,
        color: COLORS.BAR_SLOWMOVERS,
        tooltip: <ArrayTooltipContent data={tooltipData.slowmoversTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Slow movers
            </Text>
            <Text>{shortenNumber(data.excessiveStockTotals.slowmovers)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 50,
      },
      {
        percentage: percentageFastMovers,
        highlightedPercentage: highlightedPercentageFastMovers,
        color: COLORS.BAR_FASTMOVERS,
        tooltip: <ArrayTooltipContent data={tooltipData.fastmoversTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Fast movers
            </Text>
            <Text>{shortenNumber(data.excessiveStockTotals.fastmovers)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 50,
      },
      {
        percentage: percentageForced,
        color: COLORS.BAR_FORCED,
        tooltip: <ArrayTooltipContent data={tooltipData.forcedTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Forced
            </Text>
            <Text>{shortenNumber(data.eligibleSupply.forced)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 50,
      },
      {
        percentage: percentageIsClosing,
        color: COLORS.BAR_IS_CLOSING,
        tooltip: <ArrayTooltipContent data={tooltipData.closingTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Closing
            </Text>
            <Text>{shortenNumber(data.eligibleSupply.isClosing)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 50,
      },
      {
        percentage: percentageHealthy,
        color: COLORS.BAR_HEALTHY,
        tooltip: <ArrayTooltipContent data={tooltipData.healthyTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Healthy stock
            </Text>
            <Text>{shortenNumber(data.eligibleSupply.healthy)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 50,
      },
      {
        percentage: percentageOutgoing,
        color: COLORS.BAR_OUTGOING,
        tooltip: <ArrayTooltipContent data={tooltipData.outgoingTooltipData} />,
        content: (
          <Column $gap="0.125rem" $padding={1}>
            <Text $size="xSmall" $bold={true}>
              Outgoing stock
            </Text>
            <Text>{shortenNumber(data.eligibleSupply.outgoing)}</Text>
          </Column>
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: 50,
      },
    ];
  }, [
    data.eligibleSupply.forced,
    data.eligibleSupply.healthy,
    data.eligibleSupply.isClosing,
    data.eligibleSupply.outgoing,
    data.excessiveStock.deadstock.ExcessivePotentialTotalValue,
    data.excessiveStock.fastmovers.ExcessivePotentialTotalValue,
    data.excessiveStock.slowmovers.ExcessivePotentialTotalValue,
    data.excessiveStockTotals.deadstock,
    data.excessiveStockTotals.fastmovers,
    data.excessiveStockTotals.slowmovers,
    data.sourceEligibleSupply,
    tooltipData,
  ]);

  //(ds+sm+fm) / total sum
  const arrowWidth =
    computePercentage(data.excessiveStockTotals.deadstock, data.sourceEligibleSupply, 2) +
    computePercentage(data.excessiveStockTotals.slowmovers, data.sourceEligibleSupply, 2) +
    computePercentage(data.excessiveStockTotals.fastmovers, data.sourceEligibleSupply, 2);

  return (
    <Column $gap="0.5rem">
      <Title>Portfolio structure according to metrics in the calculation ({currency})</Title>
      <BarLine size={BarLineSizes.LARGE} data={barLineData} />
      <Arrow width={`${arrowWidth}%`} />
    </Column>
  );
};

export default PortfolioStructureBar;
