import { IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import React, { useMemo } from 'react';
import CalculationAction from './CalculationAction';
import { ImTruck } from 'react-icons/im';

interface ProductionCalculationIconProps {
  disabled?: boolean;
  loading?: boolean;
  tooltip: string;
  value: boolean;
  onClick: () => void;
  size?: IconButtonSize;
}

const ProductionCalculationIcon: React.FC<ProductionCalculationIconProps> = ({
  value,
  size = 'normal',
  ...rest
}: ProductionCalculationIconProps) => {
  const icon = useMemo(() => <ImTruck size={IconButton_IconSizes[size]} />, [size]);

  return (
    <CalculationAction
      data-type="ProductionCalculationIcon"
      data-checked={value}
      size={size}
      icon={icon}
      tooltipPosition="left"
      checked={value}
      {...rest}
    />
  );
};

export default ProductionCalculationIcon;
