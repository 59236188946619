import { CalculationResponse } from '@ydistri/api-sdk';
import CalculationsList from '../../../Calculations/common/CalculationsList';
import { Modal } from 'antd';
import { styled } from 'styled-components';
import { STYLE } from '@ydistri/ds';
import React from 'react';

interface ListCalculationsModalProps {
  calculations: CalculationResponse[];
  onDialogClosed: () => void;
}

const cancelButtonStyle = { style: { display: 'none' } };

const ListWrapper = styled.div`
  margin-top: 1rem;
  max-height: 60vh;
  overflow-y: auto;
  ${STYLE.SCROLLBAR};
`;

/**
 * Displays a modal with a list of calculations/configurations using the TargetList/ProductList.
 * @param calculations Lit of calculations/configurations to display
 * @param onDialogClosed called when user request to close the modal
 * @constructor
 */
const ListCalculationsModal: React.FC<ListCalculationsModalProps> = ({
  calculations,
  onDialogClosed,
}) => {
  return (
    <Modal
      title="List is used in these calculations/configurations:"
      open={true}
      onOk={onDialogClosed}
      onCancel={onDialogClosed}
      cancelButtonProps={cancelButtonStyle}
      width={960}
    >
      <ListWrapper data-type="CalculationsListWrapper">
        <CalculationsList calculations={calculations} categorized />
      </ListWrapper>
    </Modal>
  );
};

export default ListCalculationsModal;
