import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { ConfigurationSwitch } from '@ydistri/ds';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import {
  DeleteProductListConfigurationPayload,
  UpdateProductListConfigurationPayload,
  useDeleteProductListConfigurationMutation,
  useUpdateProductListConfigurationMutation,
} from '../apiProductLists';
import { Space } from 'antd';
import { InfoIconTooltip } from '../../../../components/icons/InfoIconTooltip';
import type { RequestStatusFlags } from '@reduxjs/toolkit/src/query/core/apiState';

interface ProductListConfigurationSwitchProps {
  disabled?: boolean;
  enabled: boolean | null;
  archived: boolean;
  configured: boolean;
  productListId: number;
  calculationProductListConfiguration: boolean;
}

const ProductListConfigurationSwitch: React.FC<ProductListConfigurationSwitchProps> = ({
  disabled = false,
  enabled,
  archived,
  configured,
  productListId,
  calculationProductListConfiguration,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [updateConfiguration, updateConfigurationStatus] =
    useUpdateProductListConfigurationMutation();
  const [deleteConfiguration, deleteConfigurationStatus] =
    useDeleteProductListConfigurationMutation();

  const [latestStatus, setLatestStatus] = useState<ConfigurationFieldStatus>(
    ConfigurationFieldStatus.DEFAULT,
  );

  const setStatusByLatestChange = useCallback((status: RequestStatusFlags) => {
    setLatestStatus(rtkQueryPatchStatusToConfigurationFieldStatus(status));
  }, []);

  useEffect(() => {
    setStatusByLatestChange(updateConfigurationStatus);
  }, [setStatusByLatestChange, updateConfigurationStatus]);

  useEffect(() => {
    setStatusByLatestChange(deleteConfigurationStatus);
  }, [deleteConfigurationStatus, setStatusByLatestChange]);

  const value = useMemo(() => {
    if (calculationProductListConfiguration) {
      return enabled === null ? true : enabled;
    }
    return enabled === null ? false : enabled;
  }, [calculationProductListConfiguration, enabled]);

  const updateProductList = useCallback(() => {
    if (!configured) {
      const updatePayload: UpdateProductListConfigurationPayload = {
        templateOrCalculation,
        productListId: productListId,
        request: {
          isEnabled: !value,
        },
      };
      updateConfiguration(updatePayload);
    } else {
      const deletePayload: DeleteProductListConfigurationPayload = {
        templateOrCalculation,
        productListId: productListId,
      };
      deleteConfiguration(deletePayload);
    }
  }, [
    configured,
    templateOrCalculation,
    productListId,
    value,
    updateConfiguration,
    deleteConfiguration,
  ]);

  const finalValue = useMemo(
    () => (calculationProductListConfiguration ? !value : value),
    [calculationProductListConfiguration, value],
  );

  const finalDisabled = useMemo(
    () => disabled || (!finalValue && archived),
    [archived, disabled, finalValue],
  );

  return (
    <Space>
      <ConfigurationSwitch
        disabled={finalDisabled}
        status={latestStatus}
        value={finalValue}
        onChange={updateProductList}
        includeEmptySpace={false}
        switchColor={calculationProductListConfiguration ? 'red' : undefined}
      />
      {finalValue && (
        <InfoIconTooltip
          content={
            calculationProductListConfiguration
              ? "Product list is disabled - products from this list won't be used in redistribution."
              : 'Product list is enabled - products from this list will be used in redistribution.'
          }
        />
      )}
    </Space>
  );
};

export default ProductListConfigurationSwitch;
