import React, { useCallback } from 'react';
import { NO_VALUE } from '../../../../../lib/utils/utilsTypes';
import { Select } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { CSSProperties, styled } from 'styled-components';

const StyledSelect = styled(Select<number>)<{ $width?: CSSProperties['width'] }>`
  width: ${p => p.$width ?? '100%'};
`;

interface ConfigurationEntityListSelectProps {
  options: DefaultOptionType[];
  onChange: (value: number) => void;
  disabled?: boolean;
  width?: CSSProperties['width'];
}

const ConfigurationEntityListSelect: React.FC<ConfigurationEntityListSelectProps> = ({
  options,
  onChange,
  disabled = false,
  width,
}) => {
  const changeHandler = useCallback(
    (value: number) => {
      onChange(value);
    },
    [onChange],
  );

  if (options.length === 1) {
    options = [
      {
        id: -1,
        label: '- no lists left - ',
        value: -1,
      },
    ];
    disabled = true;
  }

  return (
    <StyledSelect
      options={options}
      $width={width}
      id="configuration-entity-list-select"
      onChange={changeHandler}
      value={NO_VALUE}
      allowClear={false}
      showSearch={true}
      popupMatchSelectWidth={false}
      disabled={disabled}
    />
  );
};

export default ConfigurationEntityListSelect;
