import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableExport from '../../../../components/global/TableExport/TableExport';

interface PickingTableExportProps<T> {
  fileNameBase?: string;
  dataSource: T[];
  columns: ColumnsType<T>;
  sheetName: string;
  buttonLabel?: string;
}

/**
 * Button to export a table to an Excel file
 * @param title name of the file without extension
 * @param dataSource data to export
 * @param columns columns of the table
 * @param sheetName name of the sheet in the Excel file
 * @param buttonLabel label of the button, default is 'Export'
 * @constructor
 */
const PickingTableExport = <T extends object>({
  fileNameBase,
  dataSource,
  columns,
  sheetName,
  buttonLabel = 'Export',
}: PickingTableExportProps<T>): React.ReactElement => {
  return (
    <TableExport
      columns={columns}
      dataSource={dataSource}
      fileName={`${fileNameBase}.xlsx`}
      sheetName={sheetName}
    >
      <Button>{buttonLabel}</Button>
    </TableExport>
  );
};

export default PickingTableExport;
