import React, { Key, useCallback, useMemo } from 'react';
import { SkuResponse } from '@ydistri/api-sdk';
import {
  ActionsBar,
  computeRemSize,
  ScrolledTable,
  useInjectLocalFilters,
  YColumnsType,
} from '@ydistri/ds';
import { formatDate, formatNumber } from '@ydistri/utils';
import { styled } from 'styled-components';
import ScrolledTableWrapper from './ScrolledTableWrapper';
import { SkuResponseExt } from '../../../lib/sales/salesTypes';
import { extendSkuResponse } from '../../../lib/sales/salesLib';
import { Units } from '../../../lib/charts/saleChartsTypes';
import { parseSkuAttributes } from '../../../lib/sku/skuLib';
import SKUAttributes from './SKUAttributes';
import ShowSKUAsMain from './actions/ShowSKUAsMain';
import { useDetail } from '../../../screens/Detail/hooks/useDetail';
import SKUTableColumnHeaderTitle from './SKUTableColumnHeaderTitle';
import { setIsLoadingSalesData } from '../../../screens/Detail/detailSlice';
import { UrlParams } from '../../../screens/Detail/detailTypes';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createDebugLog } from '../../../lib/utils/logging';
import {
  EntityColumns,
  EntityColumnsConfig,
  useStoreTableColumnsProvider,
} from '../../../hooks/useEntityTableColumnsProvider';

const debugLog = createDebugLog('SKUTableComponent');

const frequencyMonthsTitle = ['Freq.', '(6 mos)'];

interface SKUTableProps {
  data: SkuResponse[];
  units: Units;
  currency: string;
}

const SKUTable = styled(ScrolledTable<SkuResponse>)`
  .ant-table-small .ant-table-tbody > tr:not(:first-child) > td {
    padding-top: ${computeRemSize(4)} !important;
    padding-bottom: ${computeRemSize(4)} !important;
  }

  .ant-table-row {
    cursor: pointer;

    &.highlightedRow {
      background-color: ${p => p.theme.item.selected.backgroundColor} !important;

      & > td {
        background-color: ${p => p.theme.item.selected.backgroundColor} !important;
      }
    }

    &.expandedRow {
      font-weight: bold;
      background-color: ${p => p.theme.item.selected.backgroundColor} !important;

      & > td {
        border-bottom: 0;
        background-color: ${p => p.theme.item.selected.backgroundColor} !important;
      }

      &:hover > td {
        background: ${p => p.theme.item.selected.backgroundColor} !important;
      }
    }
  }

  .ant-table-expanded-row {
    & > td {
      background-color: ${p => p.theme.item.selected.backgroundColor} !important;
    }
  }

  .disabled {
    color: ${p => p.theme.colors.textDisabled};
    background-color: ${p => p.theme.colors.backgroundDisabled};
  }
`;

const isRowExpanded = (rowKey: number, expandedRowKeys: Key[]): boolean => {
  return expandedRowKeys.includes(rowKey);
};

/**
 * Based on Units return either saleQuantityTotal or saleValueTotal
 * @param record
 * @param units
 */
function getTotalSalesValue(record: SkuResponseExt, units: Units) {
  let value: number | undefined = 0;
  switch (units) {
    case Units.QUANTITY:
      value = record.saleQuantityTotal;
      break;
    case Units.VALUE:
      value = record.saleValueTotal;
      break;
  }
  return value;
}

const SKUTableComponent: React.FC<SKUTableProps> = ({ data, units, currency }) => {
  //some attributes are moved to the top level of the object for easier access in the table (for filtering and sorting)
  const extendedData = useMemo(() => extendSkuResponse(data), [data]);

  const [expandedRowKeys, setExpandedRowKeys] = React.useState<Key[]>([]);
  const { skuId } = useDetail('skuId');
  const [, setSearchParameters] = useSearchParams();
  const dispatch = useDispatch();
  const storeColumnProvider = useStoreTableColumnsProvider();

  const showSkuAsMain = useCallback(
    (skuId: number) => {
      debugLog('setting SKU as main', skuId);
      dispatch(setIsLoadingSalesData(true));

      setSearchParameters(prevParams => {
        if (prevParams.has(UrlParams.PRODUCT)) {
          prevParams.delete(UrlParams.PRODUCT);
        }
        if (prevParams.has(UrlParams.STORE)) {
          prevParams.delete(UrlParams.STORE);
        }

        prevParams.set(UrlParams.SKU, skuId.toString());

        return prevParams;
      });
    },
    [dispatch, setSearchParameters],
  );

  const columns: YColumnsType<SkuResponseExt>[] = useMemo(() => {
    const storesColumnConfig: EntityColumnsConfig<SkuResponseExt> = {
      [EntityColumns.CODE]: {
        width: '8rem',
        title: <SKUTableColumnHeaderTitle title="Store Code" />,
      },
      [EntityColumns.NAME]: {
        width: '14rem',
        defaultSortOrder: 'ascend',
      },
      [EntityColumns.ID]: {
        width: computeRemSize(60),
      },
    };

    const storeColumns = storeColumnProvider(storesColumnConfig, 'store', 'Store');

    return [
      ...storeColumns,
      {
        title: 'SKU Class',
        dataIndex: ['skuClass', 'name'],
        width: '8rem',
      },
      {
        title: <SKUTableColumnHeaderTitle title="Avail. Supply" />,
        dataIndex: 'availableSupply',
        sorter: (left, right) => left.availableSupply - right.availableSupply,
        align: 'right',
        width: '6rem',
        apiFilterable: true,
        apiFilterType: 'number',
      },
      {
        title: <SKUTableColumnHeaderTitle title={frequencyMonthsTitle} />,
        dataIndex: 'frequency6Months',
        key: 'frequency6months',
        align: 'right',
        width: '7rem',
        sorter: (left, right) => {
          if (left.frequency6Months && right.frequency6Months) {
            return left.frequency6Months - right.frequency6Months;
          } else if (!left.frequency6Months && right.frequency6Months) {
            return -1;
          } else {
            return 1;
          }
        },
        sortDirections: ['descend', 'ascend'],
        apiFilterable: true,
        apiFilterType: 'number',
      },
      {
        title: `Total sales [${units === Units.QUANTITY ? 'MU' : currency}]`,
        dataIndex: `${units === Units.QUANTITY ? 'saleQuantityTotal' : 'saleValueTotal'}`,
        render: (value: number) => formatNumber(value),
        sorter: (left, right) => {
          const leftValue = getTotalSalesValue(left, units);
          const rightValue = getTotalSalesValue(right, units);
          if (leftValue !== undefined && rightValue !== undefined) {
            return leftValue - rightValue;
          } else if (leftValue === undefined && rightValue !== undefined) {
            return -1;
          } else {
            return 1;
          }
        },
        align: 'right',
        width: '10rem',
        sortDirections: ['descend', 'ascend'],
        apiFilterable: true,
        apiFilterType: 'number',
      },
      {
        title: <SKUTableColumnHeaderTitle title="First purchase" />,
        dataIndex: 'firstPurchase',
        render: (value: string) => formatDate(new Date(value)),
        width: '7rem',
        sorter: (left, right) => {
          if (left.firstPurchase && right.firstPurchase) {
            return new Date(left.firstPurchase).getTime() - new Date(right.firstPurchase).getTime();
          } else if (!left.firstPurchase && right.firstPurchase) {
            return -1;
          } else {
            return 1;
          }
        },
      },

      {
        title: <SKUTableColumnHeaderTitle title="Last purchase" />,
        dataIndex: 'lastPurchase',
        render: (value: string) => formatDate(new Date(value)),
        width: '7rem',
        sorter: (left, right) => {
          if (left.lastPurchase && right.lastPurchase) {
            return new Date(left.lastPurchase).getTime() - new Date(right.lastPurchase).getTime();
          } else if (!left.lastPurchase && right.lastPurchase) {
            return -1;
          } else {
            return 1;
          }
        },
      },
      {
        title: ' ',
        dataIndex: 'actions',
        width: '3rem',
        render: (value: unknown, record: SkuResponseExt) => {
          return (
            <ActionsBar>
              <ShowSKUAsMain skuId={record.skuId} onShowSkuAsMain={showSkuAsMain} />
            </ActionsBar>
          );
        },
      },
    ];
  }, [currency, showSkuAsMain, storeColumnProvider, units]);

  const filterableColumns = useInjectLocalFilters<SkuResponse>(columns);

  const rowClassProvider = useCallback(
    (record: SkuResponse) => {
      if (!record.isEnabled) {
        return 'disabled';
      } else if (isRowExpanded(record.skuId, expandedRowKeys)) {
        return 'expandedRow';
      } else if (record.skuId === skuId) {
        return 'highlightedRow';
      } else {
        return '';
      }
    },
    [expandedRowKeys, skuId],
  );

  const expandableConfig = useMemo(() => {
    return {
      expandRowByClick: true,
      showExpandColumn: false,
      expandedRowRender: (record: SkuResponse) => {
        const skuEntity = parseSkuAttributes(record.skuId, record.attributes);
        return <SKUAttributes sku={skuEntity} />;
      },
      expandedRowClassName: () => 'expandedRow',
      onExpandedRowsChange: (expandedRowKeys: readonly Key[]) => {
        setExpandedRowKeys([...expandedRowKeys]);
      },
    };
  }, []);

  return (
    <ScrolledTableWrapper offset={90}>
      <SKUTable
        id="SKUTable"
        columns={filterableColumns}
        dataSource={extendedData}
        size="small"
        rowClassName={rowClassProvider}
        rowKey="skuId"
        expandable={expandableConfig}
      />
    </ScrolledTableWrapper>
  );
};

export default SKUTableComponent;
