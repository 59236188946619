import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCategoryTreeQuery } from '../../../components/global/CategoryTree/apiCategoryTree';
import { useGetCalculationConfigurationQuery } from '../../../apis/apiGlobalConfiguration';
import { useGetRegionFlowGroupsQuery } from '../ConfigurationRegions/apiRegionFlowGroups';
import { useCallback, useEffect, useMemo } from 'react';
import {
  setPartialValidatorInfo,
  setPartialValidatorOverviewMessage,
  setPartialValidatorSectionMessages,
} from './validatorSlice';
import { ValidatorResponse, ValidatorSection } from './validatorLib';
import { validateRegions } from './validateRegions';
import { batch, useDispatch } from 'react-redux';
import { createDebugLog } from '../../../lib/utils/logging';
import { useGetSkuClassConfigurationQuery } from '../ConfigurationSkuClasses/apiSkuClasses';
import { validateSkuClasses } from './validateSkuClasses';
import { useGetProductListConfigurationQuery } from '../ConfigurationProductLists/apiProductLists';
import { validateProductLists } from './validateProductLists';
import { validateBrands } from './validateBrands';
import { useGetBrandConfigurationQuery } from '../ConfigurationBrands/apiBrands';
import { INFINITE_PAGE_SIZE } from '@ydistri/ds';
import { BrandConfigurationParams } from '../ConfigurationBrands/BrandConfiguration/BrandConfigurations';
import { useGetTargetListsForConfigurationQuery } from '../apiTargetListConfiguration';
import { validateCategories } from './validateCategories';
import { useGetRootCategoryConfigurationQuery } from '../apiTemplate';
import { useGetConfigurationRulesQuery } from '../ConfigurationStoresAndDepartments/apiScopes';
import {
  useGetDepartmentsQuery,
  useGetRegionsQuery,
  useGetStoresQuery,
  useGetStoreTypesQuery,
} from '../../../apis/apiLists';
import { validateStoresAndDepartments } from './validateStoresAndDepartments';
import { ConfigurationRuleScopeEntityType, SortDirection } from '@ydistri/api-sdk';

const debugLog = createDebugLog('Validator', 'useValidator');

export function useValidator(): boolean {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useDispatch();

  const brandConfigParams: BrandConfigurationParams = useMemo(
    () => ({
      templateOrCalculation,
      skip: 0,
      top: INFINITE_PAGE_SIZE,
    }),
    [templateOrCalculation],
  );

  const { data: configurationRules, isFetching: isFetchingConfigurationRules } =
    useGetConfigurationRulesQuery(templateOrCalculation);

  const { data: availableRegions, isFetching: isFetchingAvailableRegions } =
    useGetRegionsQuery(templateOrCalculation);
  const { data: availableStores, isFetching: isFetchingAvailableStores } =
    useGetStoresQuery(templateOrCalculation);
  const { data: availableStoreTypes, isFetching: isFetchingAvailableStoreTypes } =
    useGetStoreTypesQuery(templateOrCalculation);
  const { data: availableDepartments, isFetching: isFetchingAvailableDepartments } =
    useGetDepartmentsQuery(templateOrCalculation);

  const { data: regionGroups, isFetching: isLoadingRegions } =
    useGetRegionFlowGroupsQuery(templateOrCalculation);
  const { data: categories, isFetching: isLoadingCategories } =
    useGetCategoryTreeQuery(templateOrCalculation);
  const { data: rootCategoryConfiguration, isFetching: isLoadingRootCategoryConfiguration } =
    useGetRootCategoryConfigurationQuery(templateOrCalculation);
  const { data: templateConfiguration, isFetching: isLoadingCalculationConfig } =
    useGetCalculationConfigurationQuery(templateOrCalculation);
  const { data: skuClassConfigurations, isFetching: isLoadingSkuClasses } =
    useGetSkuClassConfigurationQuery(templateOrCalculation);
  const { data: productListConfigurations, isFetching: isLoadingProductLists } =
    useGetProductListConfigurationQuery({
      templateOrCalculation,
      sortings: [{ fieldName: 'CreatedAt', direction: SortDirection.Desc }],
    });
  const { data: brandConfigurations, isFetching: isLoadingBrands } =
    useGetBrandConfigurationQuery(brandConfigParams);
  const { data: targetListConfig, isFetching: isLoadingTargetList } =
    useGetTargetListsForConfigurationQuery(templateOrCalculation);

  const isLoading =
    isFetchingConfigurationRules ||
    isFetchingAvailableRegions ||
    isFetchingAvailableStores ||
    isFetchingAvailableStoreTypes ||
    isFetchingAvailableDepartments ||
    isLoadingCategories ||
    isLoadingRootCategoryConfiguration ||
    isLoadingCalculationConfig ||
    isLoadingRegions ||
    isLoadingSkuClasses ||
    isLoadingProductLists ||
    isLoadingBrands ||
    isLoadingTargetList;

  useEffect(() => {
    if (categories) {
      debugLog('Categories', categories);
    }
  }, [categories]);

  const dispatchValidatedData = useCallback(
    <T extends ValidatorSection>(section: T, response: ValidatorResponse<T>) => {
      batch(() => {
        dispatch(
          setPartialValidatorSectionMessages({
            section,
            messages: response.messages,
          }),
        );

        dispatch(
          setPartialValidatorInfo({
            section,
            data: response.info,
          }),
        );

        dispatch(
          setPartialValidatorOverviewMessage({
            section,
            data: response.overviewMessage,
          }),
        );
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (targetListConfig && categories && rootCategoryConfiguration) {
      const validated = validateCategories(categories, targetListConfig, rootCategoryConfiguration);

      dispatchValidatedData(ValidatorSection.CATEGORIES, validated);
    }
  }, [dispatch, dispatchValidatedData, targetListConfig, categories, rootCategoryConfiguration]);

  useEffect(() => {
    if (regionGroups && templateConfiguration) {
      const validated = validateRegions(regionGroups, templateConfiguration);

      dispatchValidatedData(ValidatorSection.REGIONS, validated);
    }
  }, [dispatch, dispatchValidatedData, regionGroups, templateConfiguration]);

  useEffect(() => {
    if (skuClassConfigurations) {
      const validated = validateSkuClasses(skuClassConfigurations);

      dispatchValidatedData(ValidatorSection.SKU_CLASSES, validated);
    }
  }, [dispatch, dispatchValidatedData, skuClassConfigurations]);

  useEffect(() => {
    if (productListConfigurations && templateConfiguration) {
      const validated = validateProductLists(productListConfigurations, templateConfiguration);

      dispatchValidatedData(ValidatorSection.PRODUCT_LISTS, validated);
    }
  }, [dispatch, dispatchValidatedData, productListConfigurations, templateConfiguration]);

  useEffect(() => {
    if (brandConfigurations && templateConfiguration) {
      const validated = validateBrands(brandConfigurations, templateConfiguration);

      dispatchValidatedData(ValidatorSection.BRANDS, validated);
    }
  }, [dispatch, dispatchValidatedData, brandConfigurations, templateConfiguration]);

  useEffect(() => {
    if (
      configurationRules &&
      availableRegions &&
      availableStores &&
      availableStoreTypes &&
      availableDepartments &&
      templateConfiguration
    ) {
      const validated = validateStoresAndDepartments(
        configurationRules,
        {
          [ConfigurationRuleScopeEntityType.Regions]: availableRegions,
          [ConfigurationRuleScopeEntityType.Stores]: availableStores,
          [ConfigurationRuleScopeEntityType.StoreTypes]: availableStoreTypes,
          [ConfigurationRuleScopeEntityType.Departments]: availableDepartments,
        },
        templateConfiguration,
      );

      dispatchValidatedData(ValidatorSection.STORES_AND_DEPARTMENTS, validated);
    }
  }, [
    dispatch,
    availableRegions,
    availableDepartments,
    availableStoreTypes,
    availableStores,
    configurationRules,
    dispatchValidatedData,
    templateConfiguration,
  ]);

  return isLoading;
}
