import React, { useCallback, useMemo } from 'react';
import { ActionsBar, Button, HalfScreenSection, IconButton_IconSizes } from '@ydistri/ds';
import { Alert, Spin } from 'antd';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import {
  useCreateNewRegionGroupMutation,
  useGetRegionFlowGroupsQuery,
} from '../apiRegionFlowGroups';
import RegionGroup from './RegionGroup';
import { MdAdd } from 'react-icons/md';

const RegionGroupConfigurations: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: regionGroups, isFetching } = useGetRegionFlowGroupsQuery(templateOrCalculation);

  const [createNewRegionGroup, createNewRegionGroupStatus] = useCreateNewRegionGroupMutation();

  const handleNewRegionGroup = useCallback(() => {
    createNewRegionGroup();
  }, [createNewRegionGroup]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar moveToRight={true}>
        <Button
          loading={createNewRegionGroupStatus.isLoading}
          $colorVariant="ghost"
          onClick={handleNewRegionGroup}
          data-testid="create_new_region_group_button"
          disabled={templateOrCalculation.type === 'Calculation'}
        >
          <MdAdd size={IconButton_IconSizes.small} />
          Create region group
        </Button>
      </ActionsBar>
    );
  }, [createNewRegionGroupStatus.isLoading, handleNewRegionGroup, templateOrCalculation.type]);

  return (
    <HalfScreenSection header="Region groups" headerActions={headerActions}>
      <Spin spinning={isFetching}>
        {regionGroups?.map(rg => <RegionGroup regionGroup={rg} key={rg.id} />)}
        {!isFetching && (!regionGroups || regionGroups.length === 0) && (
          <Alert
            message="No region groups"
            description="There are no region groups. Create at least one region group, or change Region flow type setting."
            type="warning"
            showIcon
          />
        )}
      </Spin>
    </HalfScreenSection>
  );
};

export default RegionGroupConfigurations;
