import React, { useCallback, useMemo, useState } from 'react';
import { NotLoggedInPage, FormInfo, InputFieldType, OrangeInfo } from '@ydistri/ds';
import { MeCollection } from '../../swagger/collections-identity';

const forgotPasswordInputs: InputFieldType[] = [
  {
    title: 'Email',
    type: 'text',
    name: 'login',
  },
];

const ForgotPassword: React.FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  const infoTop = useMemo(
    () => (
      <FormInfo>
        {success
          ? 'Check your email for the reset link.'
          : 'Enter your email address and we will send you a link to reset your password.'}
      </FormInfo>
    ),
    [success],
  );

  const infoBottom = useMemo(() => (error ? <OrangeInfo>{error}</OrangeInfo> : null), [error]);

  const onSubmit = useCallback((values: Record<string, string>) => {
    setError(undefined);
    setSubmitting(true);

    MeCollection.sendLinkForgetPasswordCreate({ email: values.login })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
      })
      .catch(e => {
        setSubmitting(false);
        setError(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/consistent-type-assertions
          (e.response?.data?.error_description === 'invalid_username_or_password'
            ? 'Wrong email or password'
            : //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
              (e.response.data.error_description ?? e.message)) as string,
        );
      });
  }, []);

  return (
    <NotLoggedInPage
      title={success ? 'Reset link sent' : 'Reset password'}
      inputs={forgotPasswordInputs}
      button="Send link"
      onSubmit={onSubmit}
      submitting={submitting}
      success={success}
      hideFormOnSuccess={true}
      infoTop={infoTop}
      infoBottom={infoBottom}
    >
      {null}
    </NotLoggedInPage>
  );
};

export default ForgotPassword;
