import InventoryTypeDefinitionPart from './InventoryTypeDefinitionPart';
import { ConfigurationDataType, ConfigurationFieldType, SkuType } from '@ydistri/api-sdk';
import React from 'react';
import ConfigurationField from '../../../../../components/global/ConfigurationField/ConfigurationField';
import { Row } from '@ydistri/ds';

interface DeadStockTypeDefinitionProps {
  disabled?: boolean;
}

const DeadStockTypeDefinition: React.FC<DeadStockTypeDefinitionProps> = ({ disabled }) => {
  return (
    <InventoryTypeDefinitionPart type={SkuType.DeadStock} title="Dead stock">
      <Row $flexWrap="wrap" $alignItems="center" $gap="1rem">
        <ConfigurationField
          skuTypeColumn={null}
          fieldType={ConfigurationFieldType.CatDeadStock}
          dataType={ConfigurationDataType.InputNumber}
          padding="0.25rem 0.25rem 0.25rem 0"
          inputWidth="4rem"
          noAdditionalSpace={true}
          disabled={disabled}
        />
        months without sale
      </Row>
    </InventoryTypeDefinitionPart>
  );
};

export default DeadStockTypeDefinition;
