import React, { useCallback } from 'react';
import {
  CategoryConfigurationDefinitionResponse,
  ConfigurationDataType,
  ConfigurationSectionType,
} from '@ydistri/api-sdk';
import ConfigurationRow from './ConfigurationRow';
import { CategoryConfigurationDefinitionGroup } from './SegmentPotentialDefinition';
import { List } from 'antd';
import ConfigurationRowHeader from './ConfigurationRowHeader';
import ConfigurationEntityLists from './ConfigurationEntityLists/ConfigurationEntityLists';

const categoryConfigurationDefinitionGroupTitles: Record<
  ConfigurationSectionType,
  string | undefined
> = {
  [ConfigurationSectionType.PotentialSource]: 'Redistribution settings',
  [ConfigurationSectionType.PotentialTarget]: undefined,
  [ConfigurationSectionType.General]: 'Inventory definition',
  [ConfigurationSectionType.PotentialSourceStandardSubsection]:
    'Settings for standard redistribution',
};

interface CategoryConfigurationGroupProps {
  sectionType: ConfigurationSectionType;
  definitionGroups?: CategoryConfigurationDefinitionGroup;
  displayTitle?: boolean;
}

const CategoryConfigurationGroup: React.FC<CategoryConfigurationGroupProps> = ({
  sectionType,
  definitionGroups,
  displayTitle = true,
}) => {
  const renderDefinition = useCallback((definition: CategoryConfigurationDefinitionResponse) => {
    if (definition.dataType === ConfigurationDataType.SelectBoxMinLayerList) {
      return <ConfigurationEntityLists definition={definition} key={definition.id} />;
    }
    return <ConfigurationRow definition={definition} key={definition.id} />;
  }, []);

  return (
    <>
      {displayTitle && (
        <ConfigurationRowHeader
          sectionType={sectionType}
          title={categoryConfigurationDefinitionGroupTitles[sectionType]}
        />
      )}
      <List
        dataSource={definitionGroups?.[sectionType]}
        loading={false}
        size="small"
        renderItem={renderDefinition}
        data-testid={`ConfigurationGroup-${sectionType}`}
      />
    </>
  );
};

export default CategoryConfigurationGroup;
