/* eslint-disable @typescript-eslint/naming-convention */

import {
  CalculationStatisticRouteSummaryResponse,
  CalculationStatisticStoreSourceSummaryResponse,
  CalculationStatisticStoreTargetSummaryResponse,
  StatisticType,
} from '@ydistri/api-sdk';

type SortType =
  | CalculationStatisticRouteSummaryResponse
  | CalculationStatisticStoreSourceSummaryResponse
  | CalculationStatisticStoreTargetSummaryResponse;

const numericStatisticType: Record<StatisticType, number> = {
  Minimal: 1,
  Median: 2,
  Average: 3,
  Maximum: 4,
};

export const sortByStatisticsType = (a: SortType, b: SortType): number =>
  numericStatisticType[a.type] - numericStatisticType[b.type];

export enum StatisticsModalType {
  ROUTE = 'route',
  SOURCE_STORE = 'sourceStore',
  TARGET_STORE = 'targetStore',
}
