import {
  ApiOperationType,
  CompareType,
  ProductListItemComparisonInformationResponse,
} from '@ydistri/api-sdk';
import {
  IProjectSignal,
  ITemplateSignal,
  ProjectConfigurationEntityType,
  SignalrUpdate,
} from '../../../signalr/signalrInterfaces';

/**
 * User entered data can be either added to the existing list or replace the existing list
 */
export enum AdministrationItemListHandlingMethod {
  ADD = 'add',
  REPLACE = 'replace',
}

export enum ModalMode {
  CREATE,
  EDIT,
}

export enum ResultsDisplayMode {
  SUMMARY,
  DETAIL,
}

/**
 * Possible actions that can be performed on an item for each of the possible handling methods
 */
export enum AdministrationItemAction {
  /** When the item is already in the list but the user entered it again, it will stay in the list */
  NONE = 'none',
  /** Item is new and will be inserted */
  INSERT = 'insert',
  /** Item is already in the list but new data was entered (a quantity for example) so it will be updated */
  UPDATE = 'update',
  /** Item is in the list but not in the user entered data and the handling method is Replace */
  REMOVE = 'remove',
}

/**
 * Backend returns data during validation that were not entered by the user.
 * Such data have origin BACKEND.
 * User entered data have origin USER.
 */
export enum AdministrationItemOrigin {
  USER = 'user',
  BACKEND = 'backend',
}

export type AdministrationItemActions = Record<
  AdministrationItemListHandlingMethod,
  AdministrationItemAction
>;

/**
 * Content of the administration item as entered by the user (pasted or typed in) and parsed by the frontend
 */
export interface AdministrationItemRow<T> {
  /** Where in the source content the item was found */
  rowNumber: number;
  /** The content of the item as entered by the user and parsed to specific attributes  */
  inputData: Partial<T>;
  /** True if there is an error in the data (invalid or missing values, duplicates) */
  isError?: boolean;
  /** List of errors in the data (if there are any) */
  errors?: string[];
  /** Specific attribute to indicate that the item is a duplicate of another item in the list. Duplicate item is also an error */
  isDuplicate?: boolean;
}

/**
 * Input data when validated by the backend and validation was processed
 */
export interface AdministrationValidatedItem<T, U> extends AdministrationItemRow<T> {
  /** Indicates whether the item is new or already exists in the backend */
  compareType?: CompareType;
  /** What actions will be performed on the item for each of the possible handling methods */
  actions: AdministrationItemActions;
  /** Indicates whether the data was entered by the user or retrieved from the backend */
  origin?: AdministrationItemOrigin;
  /** Data as returned by the backend */
  data: U;
}

export interface AdministrationItemContentCompared<T, U> {
  result?: ProductListItemComparisonInformationResponse;
  items: AdministrationValidatedItem<T, U>[];
}

export type AdministrationItemParserFunction<T> = (content: string, parser?: DOMParser) => T[];

export interface AdministrationItemParser<T> {
  contentType: string;
  parse: AdministrationItemParserFunction<T>;
}

export interface CountableDetail {
  count: number;
  description: string;
}

export enum StatisticsItemType {
  SUCCESS,
  ERROR,
  REMOVED,
  UNCHANGED,
}

export interface StatisticsItem {
  observation: number;
  label: string;
  type: StatisticsItemType;
}

export interface AdministrationItemValidationStatisticsData {
  total: number;
  updates: number;
  errors: number;
  removed: number;
  unchanged: number;
}

export type ValidatedItemsGridScreenMode = 'shared' | 'standalone';

export interface SignalProjectConfigurationChanged extends IProjectSignal, ITemplateSignal {
  entityType: ProjectConfigurationEntityType;
  entityId: number;
  operationType: ApiOperationType;
  projectCode: string;
  updates: SignalrUpdate[];
}
