import React, { useCallback, useMemo } from 'react';
import GenericSelector from '../../../../../../components/global/GenericSelector/GenericSelector';

export interface RegionOption {
  id: number;
  name: string;
  customerRegionId?: string | null;
}

interface RegionSelectorProps {
  options: RegionOption[];
  onChange?: (value: number) => void;
  value?: number;
  noSelectionLabel?: string;
}

const RegionSelector: React.FC<RegionSelectorProps> = ({
  options,
  onChange,
  value,
  noSelectionLabel,
}) => {
  const regionOptions = useMemo(() => {
    return options.map(option => ({
      id: option.id,
      label: option.name,
    }));
  }, [options]);

  const changeHandler = useCallback(
    (value: number) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <GenericSelector
      options={regionOptions}
      label="Region"
      onChange={changeHandler}
      value={value}
      noSelectionLabel={noSelectionLabel}
      showSearch={true}
    />
  );
};

export default RegionSelector;
