import { Column, Text } from '@ydistri/ds';
import { styled } from 'styled-components';
import { SkuType } from '@ydistri/api-sdk';
import React, { PropsWithChildren } from 'react';
import { skuColumnBackgroundColors } from '@ydistri/utils';

interface PartWrapperProps {
  $type: SkuType;
}

const PartTitle = styled.div``;

const PartWrapper = styled(Column)<PartWrapperProps>`
  flex-grow: 1;
  padding: 16px 16px 8px 16px;
  background-color: ${({ $type }) => skuColumnBackgroundColors[$type]};

  flex-basis: ${({ $type }) => ($type === SkuType.SlowMover ? '37%' : '31%')};
  height: ${({ $type }) => ($type === SkuType.SlowMover ? 'fit-content' : 'auto')};
  align-self: stretch;
  justify-self: stretch;
`;

interface InventoryDefinitionDataTypeProps extends PropsWithChildren {
  type: SkuType;
  title: string;
}

const InventoryTypeDefinitionPart: React.FC<InventoryDefinitionDataTypeProps> = ({
  title,
  type,
  children,
}) => {
  return (
    <PartWrapper $type={type} data-type="PartWrapper">
      <PartTitle data-type="PartTitle">
        <Text $bold $size="large">
          {title}
        </Text>
      </PartTitle>
      {children}
    </PartWrapper>
  );
};

export default InventoryTypeDefinitionPart;
