import React from 'react';
import { ConfigurationSectionType, SkuType } from '@ydistri/api-sdk';
import { ConfigurationFieldWrapper } from '../../../../components/global/ConfigurationField/ConfigurationField';
import { styled } from 'styled-components';
import { ConfigurationFieldsWrapperCentered, ConfigurationRowWrapper } from './ConfigurationRow';
import { ConfigurationTitleWrapper } from './ConfigurationRowTitle';
import { Title } from '@ydistri/ds';
import { isSectionTypeTarget } from '../configurationUtils';

const ConfigurationFieldColumnHeader = styled(ConfigurationFieldWrapper)`
  font-weight: 500;
  padding: 0 0 0 0.75rem;
  height: 3.5rem;
`;

const ConfigurationRowHeaderWrapper = styled(ConfigurationRowWrapper)`
  margin-top: 0;
  &:hover {
    background-color: transparent;
  }
`;

const ConfigurationRowHeaderTitleWrapper = styled(ConfigurationTitleWrapper)``;

interface ConfigurationRowHeaderProps {
  sectionType: ConfigurationSectionType;
  title?: string;
}

const ConfigurationRowHeader: React.FC<ConfigurationRowHeaderProps> = ({ sectionType, title }) => {
  const isTarget = isSectionTypeTarget(sectionType);

  return (
    <ConfigurationRowHeaderWrapper>
      <ConfigurationRowHeaderTitleWrapper $isTarget={isTarget}>
        <Title $marginTop="small" $marginBottom="none">
          {title}
        </Title>
        &nbsp;
      </ConfigurationRowHeaderTitleWrapper>
      <ConfigurationFieldsWrapperCentered>
        {!isTarget && (
          <>
            <ConfigurationFieldColumnHeader $skuTypeColumn={SkuType.DeadStock}>
              Dead stock
            </ConfigurationFieldColumnHeader>
            <ConfigurationFieldColumnHeader $skuTypeColumn={SkuType.SlowMover}>
              Slow mover
            </ConfigurationFieldColumnHeader>
            <ConfigurationFieldColumnHeader $skuTypeColumn={SkuType.FastMover}>
              Fast mover
            </ConfigurationFieldColumnHeader>
          </>
        )}
      </ConfigurationFieldsWrapperCentered>
    </ConfigurationRowHeaderWrapper>
  );
};

export default ConfigurationRowHeader;
