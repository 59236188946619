import { ActionsBar, Button } from '@ydistri/ds';
import React, { useCallback } from 'react';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import {
  useDeleteCalculationImportMutation,
  useRunCalculationImportMutation,
} from '../../../apiCalculationRedistributionImport';
import { ReduxState } from '../../../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorType } from '../../../../../../../../apis/api';
import { addToast } from '@ydistri/utils';
import { setImportStep } from '../../../../../../../Calculations/calculationsSlice';
import { ProcessingStep } from '../../../redistributionImportTypes';
import useResetImport from '../../common/useResetImport';

const RedistributionImportItemsActions: React.FC = () => {
  const calculation = useTemplateOrCalculation();
  const verificationTimeStamp = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.verificationTimeStamp,
  );
  const dispatch = useDispatch();
  const resetImport = useResetImport();

  const [runCalculationImport] = useRunCalculationImportMutation();
  const [deleteCalculationImport] = useDeleteCalculationImportMutation();

  const finalizeClickHandler = useCallback(() => {
    runCalculationImport({
      calculationId: calculation.id,
      verificationTimeStamp,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setImportStep({
            stepIndex: ProcessingStep.FINALIZED,
            stepRunning: true,
          }),
        );
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const error = err as ErrorType;
        dispatch(
          addToast({
            message: `Import failed with message: ${error.response.data.Messages.join(', ')}`,
            isError: true,
          }),
        );
      });
  }, [calculation.id, dispatch, runCalculationImport, verificationTimeStamp]);

  const deleteImportClickHandler = useCallback(() => {
    deleteCalculationImport({
      calculationId: calculation.id,
      verificationTimeStamp,
    })
      .unwrap()
      .then(() => {
        resetImport();
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const error = err as ErrorType;
        dispatch(
          addToast({
            message: `Removing import failed with message: ${error.response.data.Messages.join(', ')}`,
            isError: true,
          }),
        );
      });
  }, [calculation.id, deleteCalculationImport, dispatch, resetImport, verificationTimeStamp]);

  return (
    <ActionsBar>
      <Button onClick={finalizeClickHandler} $colorVariant="primary">
        Finalize import
      </Button>
      <Button onClick={deleteImportClickHandler} $colorVariant="ghostPrimary">
        Cancel
      </Button>
    </ActionsBar>
  );
};

export default RedistributionImportItemsActions;
