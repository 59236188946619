import React, { useCallback } from 'react';
import { Button, Row } from '@ydistri/ds';

interface Option<T> {
  label: string;
  value: T;
}

interface ButtonOptionsProps<T> {
  value: T;
  setValue: (o: T) => void;
  options: Option<T>[];
}

const ButtonOptions = <T,>({
  value,
  setValue,
  options,
}: ButtonOptionsProps<T>): React.ReactNode => {
  const clickHandler = useCallback((o: Option<T>) => () => setValue(o.value), [setValue]);

  return (
    <Row $gap="0.5rem">
      {options.map(o => (
        <Button
          key={o.label}
          $colorVariant={o.value === value ? 'selected' : 'ghost'}
          $sizeVariant="small"
          onClick={clickHandler(o)}
        >
          {o.label}
        </Button>
      ))}
    </Row>
  );
};

export default ButtonOptions;
