import {
  filterValidItems,
  getParseableString,
  getParserForContentType,
  validateProductListContent,
} from './productListsLib';
import { useDispatch } from 'react-redux';
import {
  setHandlingMethod,
  setIsLoading,
  setProductListItems,
  validationFinished,
} from './productListAdministrationSlice';
import { useCallback } from 'react';
import { IdentificationType, ProductResponse } from '@ydistri/api-sdk';
import {
  AdministrationItemContentCompared,
  AdministrationItemListHandlingMethod,
} from '../common/administrationItemsTypes';
import { addToast } from '@ydistri/utils';
import { useProductListAdministrationState } from './useProductListAdministrationState';
import { ProductListInputData, ProductListRow, ProductListValidatedItem } from './productListTypes';
import { ErrorType } from '../../../apis/api';

export type ValidateItems = (newHandlingMethod?: AdministrationItemListHandlingMethod) => void;

const isAllParsed = <T extends ProductListValidatedItem | ProductListRow>(
  parsedProductListItems: T[],
  validation: AdministrationItemContentCompared<ProductListInputData, ProductResponse>,
) =>
  filterValidItems(parsedProductListItems).every(item => {
    const exactItemValidated = validation.items.findIndex(
      validatedItem => validatedItem.inputData.productId === item.inputData.productId,
    );

    return exactItemValidated !== -1;
  });

const useValidateItems = (): ValidateItems => {
  const dispatch = useDispatch();
  const { validation, selectedProductList, productIdentificationType, productItemsContent } =
    useProductListAdministrationState();

  return useCallback(
    (newHandlingMethod?: AdministrationItemListHandlingMethod) => {
      if (productItemsContent.length === 0) {
        return;
      }

      const parser = getParserForContentType('text/plain');
      if (!parser) {
        return;
      }

      const parsedRows = parser.parse(productItemsContent);

      if (parsedRows.length > 0 && selectedProductList) {
        if (validation) {
          if (isAllParsed(parsedRows, validation)) {
            return;
          }
        }

        dispatch(setIsLoading(true));
        dispatch(setProductListItems(parsedRows));
        if (newHandlingMethod) {
          dispatch(setHandlingMethod(newHandlingMethod));
        }

        validateProductListContent(selectedProductList, parsedRows, productIdentificationType)
          .then(data => {
            const errorItems = data.items.filter(validatedItem => validatedItem.isError);
            const plainErrorData = getParseableString(
              errorItems,
              data.result?.productIdentificationTypeId ?? IdentificationType.CustomerId,
            );

            dispatch(validationFinished({ validation: data, errors: plainErrorData }));
          })
          .catch(error => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            dispatch(
              addToast({
                message: `Validation failed: ${err.response.data.Messages.join(', ')}`,
                isError: true,
              }),
            );
          })
          .finally(() => {
            dispatch(setIsLoading(false));
          });
      }
    },
    [dispatch, productIdentificationType, productItemsContent, selectedProductList, validation],
  );
};

export default useValidateItems;
