import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { ConfigurationSwitch, RowCentered } from '@ydistri/ds';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import {
  DeleteBrandConfigurationPayload,
  UpdateBrandConfigurationPayload,
  useDeleteBrandConfigurationMutation,
  useUpdateBrandConfigurationMutation,
} from '../apiBrands';
import { InfoIconTooltip } from '../../../../components/icons/InfoIconTooltip';
import type { RequestStatusFlags } from '@reduxjs/toolkit/src/query/core/apiState';

interface BrandConfigurationSwitchProps {
  disabled?: boolean;
  configured: boolean;
  brandId: number;
  calculationBrandConfiguration: boolean;
}

const BrandConfigurationSwitch: React.FC<BrandConfigurationSwitchProps> = ({
  disabled = false,
  configured,
  brandId,
  calculationBrandConfiguration,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [updateConfiguration, updateConfigurationStatus] = useUpdateBrandConfigurationMutation();
  const [deleteConfiguration, deleteConfigurationStatus] = useDeleteBrandConfigurationMutation();

  const [latestStatus, setLatestStatus] = useState<ConfigurationFieldStatus>(
    ConfigurationFieldStatus.DEFAULT,
  );

  const setStatusByLatestChange = useCallback((status: RequestStatusFlags) => {
    setLatestStatus(rtkQueryPatchStatusToConfigurationFieldStatus(status));
  }, []);

  useEffect(
    () => setStatusByLatestChange(updateConfigurationStatus),
    [setStatusByLatestChange, updateConfigurationStatus],
  );

  useEffect(
    () => setStatusByLatestChange(deleteConfigurationStatus),
    [deleteConfigurationStatus, setStatusByLatestChange],
  );

  const value = useMemo(() => {
    return configured;
  }, [configured]);

  const updateBrand = useCallback(() => {
    if (!configured) {
      const updatePayload: UpdateBrandConfigurationPayload = {
        templateOrCalculation,
        brandId,
      };
      updateConfiguration(updatePayload);
    } else {
      const deletePayload: DeleteBrandConfigurationPayload = {
        templateOrCalculation,
        brandId,
      };
      deleteConfiguration(deletePayload);
    }
  }, [configured, templateOrCalculation, brandId, updateConfiguration, deleteConfiguration]);

  return (
    <RowCentered $gap="1rem">
      <ConfigurationSwitch
        disabled={disabled}
        status={latestStatus}
        value={value}
        onChange={updateBrand}
        includeEmptySpace={false}
        switchColor={calculationBrandConfiguration ? 'red' : undefined}
      />
      {value && (
        <InfoIconTooltip
          content={
            calculationBrandConfiguration
              ? 'Brand is disabled - products from this brand will not be used in redistribution.'
              : 'Brand is enabled - products from this brand will be used in redistribution.'
          }
        />
      )}
    </RowCentered>
  );
};

export default BrandConfigurationSwitch;
