import { scopeEntityTitle } from './scopeLib';
import React from 'react';
import { Text } from '@ydistri/ds';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';
import { plural } from '@ydistri/utils';

interface ScopeMultiselectCounterProps {
  entityType: ConfigurationRuleScopeEntityType;
  selectedIds?: number[];
}

const ScopeMultiselectCounter: React.FC<ScopeMultiselectCounterProps> = ({
  entityType,
  selectedIds = [],
}) => {
  if (selectedIds.length === 0) return <Text $type="danger">Can not be empty</Text>;

  return (
    <Text $type="light">
      Selected {selectedIds.length}{' '}
      {plural(selectedIds.length, scopeEntityTitle[entityType].toLowerCase())}
    </Text>
  );
};

export default ScopeMultiselectCounter;
