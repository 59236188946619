import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSider } from '../../hooks/useSider';
import {
  setLeftMenuAutomaticallyCollapsed,
  toggleLeftMenuManuallyCollapsed,
} from '../../store/menuSlice';
import { useParams } from 'react-router-dom';
import useSubpage from '../../hooks/useSubpage';
import usePage from '../../hooks/usePage';
import { generatePartialPath } from '../../routes/routerSlice';
import { useSelectedTemplateId } from '../../hooks/useSelectedTemplateId';
import { menuLeftItemTemplate } from './menuLeftItemTemplate';
import MenuItem from './MenuItem';
import { Logo } from './Logo';
import { styled } from 'styled-components';
import { AvailableModals, openModal } from '../../screens/Modals/modalSlice';
import { BiMessageError } from 'react-icons/bi';
import { Popover } from 'antd';
import {
  Button,
  computeRemSize,
  logoWhite,
  logoY,
  MenuLogoWrapper,
  MenuSider,
  MenuWrapper,
} from '@ydistri/ds';
import { LeftMenuItem, Version } from '@ydistri/utils';

const ReportError = styled.div`
  text-align: center;
  font-size: 1rem;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
  align-self: center;
`;

const MenuLeft: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const page = usePage();
  const subpage = useSubpage();
  const { isLeftMenuCollapsed } = useSider();
  const selectedTemplateId = useSelectedTemplateId();

  const openReportErrorModal = useCallback(() => {
    dispatch(openModal({ type: AvailableModals.REPORT_ERROR, data: undefined }));
  }, [dispatch]);

  const onBreakHandler = useCallback(
    (broken: boolean) => dispatch(setLeftMenuAutomaticallyCollapsed(broken)),
    [dispatch],
  );

  const onCollapseHandler = useCallback(
    () => dispatch(toggleLeftMenuManuallyCollapsed()),
    [dispatch],
  );

  const items: LeftMenuItem[] = useMemo(() => {
    const resultItems: LeftMenuItem[] = [];

    const copiedParams = { ...params };
    if (!copiedParams.templateId) copiedParams.templateId = selectedTemplateId?.toString();
    if (!copiedParams.slug) copiedParams.slug = '1';

    menuLeftItemTemplate.forEach(item => {
      if (!item.showChildrenOn) {
        item.showChildrenOn = [item.id];
      }

      const isActive = page ? item.showChildrenOn.includes(page) || item.id === page : false;
      const showChildren = page ? item.showChildrenOn.includes(page) : false;
      const partialPath = generatePartialPath(item.to, copiedParams, undefined);

      resultItems.push({
        parentId: item.id,
        subpageId: undefined,
        key: `${item.id}_${isActive ? 1 : 0}_${partialPath.replaceAll('/', '_')}_00`,
        path: partialPath,
        label: item.label,
        shortcut: '', //main items have no shortcut
        isOpened: showChildren,
        isSelected: isActive,
        isChild: false,
        hasChild: item.children.length > 0,
        icon: item.icon,
      });

      if (showChildren) {
        item.children.forEach(child => {
          if (item.childRoute) {
            const isSelected = subpage === child.subpage;
            const partialPath = generatePartialPath(item.childRoute, copiedParams, child.subpage);
            resultItems.push({
              parentId: item.id,
              subpageId: child.subpage,
              key: `${item.id}_${child.subpage}_${partialPath.replaceAll('/', '_')}_${
                isSelected ? 1 : 0
              }`,
              path: partialPath,
              label: child.label,
              shortcut: child.shortcut, //shortcut is shown in case menu is collapsed
              isOpened: showChildren,
              isSelected,
              isChild: true,
              hasChild: false,
              icon: undefined,
            });
          }
        });
      }
    });

    return resultItems;
  }, [params, page, subpage, selectedTemplateId]);

  const displayedVersion = useMemo(() => {
    if (
      !['development', 'production'].includes(
        import.meta.env.VITE_REACT_APP_FRONTEND_ENVIRONMENT ?? 'unknown',
      )
    ) {
      return import.meta.env.VITE_REACT_APP_FRONTEND_ENVIRONMENT;
    }

    return import.meta.env.VITE_REACT_APP_VERSION;
  }, []);

  return (
    <MenuSider
      collapsible={true}
      collapsed={isLeftMenuCollapsed}
      onBreakpoint={onBreakHandler}
      onCollapse={onCollapseHandler}
      width={computeRemSize(140)}
    >
      <MenuLogoWrapper>
        <Logo src={isLeftMenuCollapsed ? logoY : logoWhite} />
      </MenuLogoWrapper>
      <MenuWrapper>
        <div>
          {items.map(item => (
            <MenuItem item={item} key={item.key} />
          ))}
        </div>
      </MenuWrapper>
      <ReportError>
        {isLeftMenuCollapsed && (
          <Popover content="Report issue" placement="right">
            <Button
              $sizeVariant="xSmall"
              $colorVariant="ghostPrimary"
              onClick={openReportErrorModal}
            >
              <BiMessageError />
            </Button>
          </Popover>
        )}
        {!isLeftMenuCollapsed && (
          <Button $sizeVariant="xSmall" $colorVariant="ghostPrimary" onClick={openReportErrorModal}>
            Report an issue
          </Button>
        )}
      </ReportError>
      <Version>
        {!isLeftMenuCollapsed && 'Version: '}
        {displayedVersion}
      </Version>
    </MenuSider>
  );
};

export default MenuLeft;
