import { Label } from '@ydistri/ds';
import TextArea from 'antd/es/input/TextArea';
import React, {
  ChangeEvent,
  ClipboardEvent,
  ClipboardEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { insertText } from '@ydistri/utils';
import { ImportedItemParser, ImportedItemRow } from '../../importLib';

interface ListItemsTextAreaProps<T extends object> {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  parserProvider: (data: DataTransfer) => ImportedItemParser<T> | null;
  onValueChanged: (newValue: string) => void;
  serializer: (data: ImportedItemRow<T>[]) => string;
  parsedObjectKeys: (keyof ImportedItemRow<T>['inputData'])[];
}

const isWholeTextSelected = (selectionStart: number, selectionEnd: number, text: string) => {
  if (text.length > 0) {
    return selectionEnd - selectionStart === text.length;
  }
  return false;
};

const ListItemsTextArea = <T extends object>({
  label,
  id,
  placeholder,
  parserProvider,
  value,
  onValueChanged,
  serializer,
  parsedObjectKeys,
}: ListItemsTextAreaProps<T>): React.ReactElement => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);

  const onTextSelect = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;

    setSelectionStart(start);
    setSelectionEnd(end);
  }, []);

  const pasteHandler: ClipboardEventHandler<HTMLTextAreaElement | HTMLDivElement> = useCallback(
    (event: ClipboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
      const clipboardData = event.clipboardData;
      const parser = parserProvider(clipboardData);
      if (parser !== null) {
        const pastedData = clipboardData.getData(parser.contentType);
        const rows = parser.parse(pastedData, parsedObjectKeys);

        const formatted = serializer(rows);

        if (value) {
          if (isWholeTextSelected(selectionStart, selectionEnd, value)) {
            onValueChanged(formatted);
          } else {
            const newContent = insertText(value, formatted, selectionStart, selectionEnd);
            onValueChanged(newContent);
          }
        } else {
          onValueChanged(formatted);
        }

        event.preventDefault();
      }
    },
    [
      parserProvider,
      parsedObjectKeys,
      serializer,
      value,
      selectionStart,
      selectionEnd,
      onValueChanged,
    ],
  );

  /**
   * Saves the content of the text area to state
   * Called on text area's change event
   */
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onValueChanged(e.target.value);
    },
    [onValueChanged],
  );

  return (
    <>
      {label && <Label>{label}</Label>}
      <TextArea
        ref={ref}
        rows={20}
        value={value}
        placeholder={placeholder}
        id={id ?? 'ListItemsInput'}
        onChange={changeHandler}
        onPaste={pasteHandler}
        onSelect={onTextSelect}
      />
    </>
  );
};

export default ListItemsTextArea;
