import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationRecordImportSummaryQuery } from '../../../apiCalculationRedistributionImport';
import {
  StatisticsItem,
  StatisticsItemType,
} from '../../../../../../../ProjectAdministration/common/administrationItemsTypes';
import { RecordImportAction } from '@ydistri/api-sdk';
import StatisticsItemComponent from '../../../../../../../ProjectAdministration/common/validation-results/StatisticsItemComponent';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../../store';
import { setImportActionFilter } from '../../../../../../../Calculations/calculationsSlice';

type ImportActionMapValue = Pick<StatisticsItem, 'label' | 'type'> & { position: number };

const importActionMap: Record<RecordImportAction, ImportActionMapValue> = {
  [RecordImportAction.NotSet]: {
    label: 'Not set',
    type: StatisticsItemType.ERROR,
    position: 8,
  },
  [RecordImportAction.Skip]: {
    label: 'Skip',
    type: StatisticsItemType.ERROR,
    position: 2,
  },
  [RecordImportAction.Insert]: {
    label: 'New records',
    type: StatisticsItemType.SUCCESS,
    position: 3,
  },
  [RecordImportAction.Update]: {
    label: 'Update',
    type: StatisticsItemType.SUCCESS,
    position: 5,
  },
  [RecordImportAction.CreateSkuAndInsert]: {
    label: 'New SKU',
    type: StatisticsItemType.SUCCESS,
    position: 4,
  },
  [RecordImportAction.Duplicate]: {
    label: 'Duplicates',
    type: StatisticsItemType.ERROR,
    position: 6,
  },
  [RecordImportAction.NoChange]: {
    label: 'No change',
    type: StatisticsItemType.UNCHANGED,
    position: 7,
  },
  [RecordImportAction.Delete]: {
    label: 'Remove',
    type: StatisticsItemType.REMOVED,
    position: 1,
  },
};

const convertImportActionToStatisticsItem = (
  action: RecordImportAction,
  count: number,
): StatisticsItem => ({
  observation: count,
  label: importActionMap[action].label,
  type: importActionMap[action].type,
});

/**
 * Displays a series of statistics items for each import action together with the number of items that will be affected by that action.
 * Clicking on an item will toggle the filter for that action (multiple actions can be selected at once).
 */
const RedistributionImportSummary: React.FC = () => {
  const calculation = useTemplateOrCalculation();
  const importActionFilter = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.importActionFilter,
  );
  const dispatch = useDispatch();

  const { data, isLoading } = useGetCalculationRecordImportSummaryQuery(calculation.id);

  const summaries = useMemo(() => {
    if (isLoading) return [];

    return (data ?? [])
      .filter(stat => stat.itemCount > 0)
      .toSorted(
        (a, b) =>
          importActionMap[a.recordImportAction].position -
          importActionMap[b.recordImportAction].position,
      );
  }, [data, isLoading]);

  const importActionStatClickHandler = useCallback(
    (stat: RecordImportAction) => {
      return () => dispatch(setImportActionFilter(stat));
    },
    [dispatch],
  );

  if (isLoading) return;

  return (
    <Space>
      {summaries.map(stat => {
        return (
          <StatisticsItemComponent
            onClick={importActionStatClickHandler(stat.recordImportAction)}
            item={convertImportActionToStatisticsItem(stat.recordImportAction, stat.itemCount)}
            selected={importActionFilter.includes(stat.recordImportAction)}
            key={stat.recordImportAction}
          />
        );
      })}
    </Space>
  );
};

export default RedistributionImportSummary;
