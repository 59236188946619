import { CalculationConfigurationExperimentalType } from '@ydistri/api-sdk';
import { BalancingGroupObject, BalancingObject } from './balancingLib';

export enum BalancingGroupV100 {
  ENABLED_V100 = 'ENABLED_V100',
  HFMQ_V100 = 'HFMQ_V100',
  BUFFER_V100 = 'BUFFER_V100',
}

export const balancingGroupV100: Record<BalancingGroupV100, BalancingGroupObject> = {
  [BalancingGroupV100.ENABLED_V100]: {
    id: BalancingGroupV100.ENABLED_V100,
    header: 'Enable HFMQ and buffers',
    display: false,
  },
  [BalancingGroupV100.HFMQ_V100]: {
    id: BalancingGroupV100.HFMQ_V100,
    header: 'Human feasible minimal quantities (HFMQ)',
    display: true,
  },
  [BalancingGroupV100.BUFFER_V100]: {
    id: BalancingGroupV100.BUFFER_V100,
    header: 'Source and target buffers',
    display: true,
  },
} as const;

export const emptyBalancingObjectV100: BalancingObject = {
  [CalculationConfigurationExperimentalType.IsBalancingEnabled]: {
    groupId: BalancingGroupV100.ENABLED_V100,
    fieldKey: CalculationConfigurationExperimentalType.IsBalancingEnabled,
    label: 'Enabled',
    description: '',
    type: 'Boolean',
    values: [],
  },
  [CalculationConfigurationExperimentalType.HfmqCeiling]: {
    groupId: BalancingGroupV100.HFMQ_V100,
    fieldKey: CalculationConfigurationExperimentalType.HfmqCeiling,
    label: 'Maximal HFMQ ',
    description: '',
    type: 'Int',
    values: [],
  },
  [CalculationConfigurationExperimentalType.HfmqIntervalPriceUpTo]: {
    groupId: BalancingGroupV100.HFMQ_V100,
    fieldKey: CalculationConfigurationExperimentalType.HfmqIntervalPriceUpTo,
    label: 'Min HFMQ percentage of supply for price',
    description: 'More than the last "Price up to" value is always one piece',
    type: 'Decimal',
    values: [],
    groupedWith: [CalculationConfigurationExperimentalType.HfmqIntervalMinPercentageOfTotalSupply],
    columnLabel: 'Price up to',
  },
  [CalculationConfigurationExperimentalType.UseSourceBufferForFastMovers]: {
    groupId: BalancingGroupV100.BUFFER_V100,
    fieldKey: CalculationConfigurationExperimentalType.UseSourceBufferForFastMovers,
    label: 'Use source buffer for fast movers',
    description: '',
    type: 'Boolean',
    values: [],
  },
  [CalculationConfigurationExperimentalType.AverageSourceNonBufferForecastBoostPercentage]: {
    groupId: BalancingGroupV100.BUFFER_V100,
    fieldKey:
      CalculationConfigurationExperimentalType.AverageSourceNonBufferForecastBoostPercentage,
    label: 'Max increment of decreased outlook for buffer',
    description:
      'Setting to avoid situation when we create buffer from seasonal month in the future',
    type: 'Int',
    values: [],
  },
  [CalculationConfigurationExperimentalType.HfmqIntervalMinPercentageOfTotalSupply]: {
    groupId: BalancingGroupV100.BUFFER_V100,
    fieldKey: CalculationConfigurationExperimentalType.HfmqIntervalMinPercentageOfTotalSupply,
    type: 'Int',
    values: [],
    columnLabel: 'Percentage',
  },
  [CalculationConfigurationExperimentalType.SourceBufferForecastConfigurationMonthsOfSupply]: {
    groupId: BalancingGroupV100.BUFFER_V100,
    fieldKey:
      CalculationConfigurationExperimentalType.SourceBufferForecastConfigurationMonthsOfSupply,
    label: 'Allowed buffer in outlooks for source',
    description: '',
    type: 'Int',
    values: [],
    groupedWith: [
      CalculationConfigurationExperimentalType.SourceBufferForecastConfigurationMaxMonthsUseAsBuffer,
    ],
    columnLabel: 'For outlook',
    alwaysDisabled: true,
  },
  [CalculationConfigurationExperimentalType.SourceBufferForecastConfigurationMaxMonthsUseAsBuffer]:
    {
      groupId: BalancingGroupV100.BUFFER_V100,
      fieldKey:
        CalculationConfigurationExperimentalType.SourceBufferForecastConfigurationMaxMonthsUseAsBuffer,
      type: 'Decimal',
      values: [],
      columnLabel: 'Number of outlooks',
    },
  [CalculationConfigurationExperimentalType.TargetBufferForecastConfigurationMonthsToSellout]: {
    groupId: BalancingGroupV100.BUFFER_V100,
    fieldKey:
      CalculationConfigurationExperimentalType.TargetBufferForecastConfigurationMonthsToSellout,
    label: 'Use source buffer to fill first X outlooks for outlook',
    description:
      'For defined outlook, this is number of outlooks that can be filled even when there is no potential directly at source (PsFinal) but there is some computed buffer. So if the suppply is "very low", lower than "Number of outlooks", buffer from source will be used and better balancing will be achieved.',
    type: 'Int',
    values: [],
    groupedWith: [
      CalculationConfigurationExperimentalType.TargetBufferForecastConfigurationMonthUseAsBuffer,
    ],
    columnLabel: 'For outlook',
    alwaysDisabled: true,
  },
  [CalculationConfigurationExperimentalType.TargetBufferForecastConfigurationMonthUseAsBuffer]: {
    groupId: BalancingGroupV100.BUFFER_V100,
    fieldKey:
      CalculationConfigurationExperimentalType.TargetBufferForecastConfigurationMonthUseAsBuffer,
    type: 'Decimal',
    values: [],
    columnLabel: 'Number of outlooks',
  },
};
