import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryDetailType } from '../../../../detailLib';
import {
  SaleMonthlyOverviewResponse,
  SaleMonthlyOverviewResponseCollectionResponse,
} from '@ydistri/api-sdk';
import { CategoryDetailType, DEFAULT_SALE_MONTH_COUNT } from '../../../../detailTypes';
import useCategoryId from '../../../../../../hooks/useCategoryId';
import { CategoriesCollection } from '../../../../../../swagger/collections';
import { AxiosResponse } from 'axios';
import { setIsLoadingSalesData } from '../../../../detailSlice';
import DetailSales from '../DetailSales/DetailSales';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';

const DetailCategoryBody: React.FC = () => {
  const dispatch = useDispatch();
  const selectedCategoryId = useCategoryId();
  const storeId = useSelector(state => state.detailReducer.storeId);
  const productId = useSelector(state => state.detailReducer.productId);
  const regionId = useSelector(state => state.detailReducer.regionId);
  const isLoadingSalesData = useSelector(state => state.detailReducer.isLoadingSalesData);

  const categoryDetailType = useMemo(
    () => getCategoryDetailType(productId, regionId, storeId),
    [productId, regionId, storeId],
  );

  const [salesData, setSalesData] = useState<SaleMonthlyOverviewResponse[]>();

  //TODO review 3 - add new query, which checks params and starts 1 of 3 queries?
  useEffect(() => {
    if (!(selectedCategoryId && productId === NO_VALUE)) {
      return;
    }

    //TODO: cancel previous request
    (async () => {
      let salesResponse: AxiosResponse<SaleMonthlyOverviewResponseCollectionResponse> | undefined =
        undefined;

      //TODO: add abort mechanism to requests
      switch (categoryDetailType) {
        case undefined: {
          return;
        }
        case CategoryDetailType.DEFAULT:
          {
            salesResponse = await CategoriesCollection.getCategoryMonthlySalesWithinMonthsRange(
              selectedCategoryId,
              DEFAULT_SALE_MONTH_COUNT,
            );
          }
          break;
        case CategoryDetailType.STORE:
          {
            if (storeId) {
              salesResponse =
                await CategoriesCollection.getWarehouseCategoryMonthlySalesWithinMonthsRange(
                  selectedCategoryId,
                  storeId,
                  DEFAULT_SALE_MONTH_COUNT,
                );
            }
          }
          break;
        case CategoryDetailType.REGION:
          {
            salesResponse =
              await CategoriesCollection.getRegionCategoryMonthlySalesWithinMonthsRange(
                selectedCategoryId,
                regionId,
                DEFAULT_SALE_MONTH_COUNT,
              );
          }
          break;
      }

      if (salesResponse) {
        // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect
        setSalesData(salesResponse.data.data);
        dispatch(setIsLoadingSalesData(false));
      }
    })();
  }, [categoryDetailType, dispatch, productId, regionId, selectedCategoryId, storeId]);

  return (
    <DetailSales salesData={salesData} loadingSalesData={isLoadingSalesData} hasForecasts={false} />
  );
};

export default DetailCategoryBody;
