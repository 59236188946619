import React, { useCallback } from 'react';
import { Column, Row, ActionsBar } from '@ydistri/ds';
import IdentificationTypeComponent from '../../../common/IdentificationTypeComponent';
import { batch, useDispatch, useSelector } from 'react-redux';
import { IdentificationType } from '@ydistri/api-sdk';
import {
  resetTargetListItems,
  setHandlingMethod,
  setIdentificationType,
  setWarehouseIdentificationType,
} from '../../targetListAdministrationSlice';
import { Button } from 'antd';
import { AdministrationItemListHandlingMethod } from '../../../common/administrationItemsTypes';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import TargetListItemsTextArea from './TargetListItemsTextArea';
import useValidateItems from '../../useValidateItems';
import { ReduxState } from '../../../../../store';

const TargetListEditor: React.FC = () => {
  const warehouseIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.warehouseIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.productIdentificationType,
  );
  const isLoading = useSelector((state: ReduxState) => state.targetListAdministration.isLoading);
  const productItemsContent = useSelector(
    (state: ReduxState) => state.targetListAdministration.productItemsContent,
  );
  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );

  const dispatch = useDispatch();

  const validateItems = useValidateItems();

  const selectWarehouseIdentificationHandler = useCallback(
    (newValue: IdentificationType) => {
      batch(() => {
        dispatch(setWarehouseIdentificationType(newValue));
        dispatch(resetTargetListItems());
        dispatch(setHandlingMethod(undefined));
      });
    },
    [dispatch],
  );
  const selectProductIdentificationHandler = useCallback(
    (newValue: IdentificationType) => {
      batch(() => {
        dispatch(setIdentificationType(newValue));
        dispatch(resetTargetListItems());
        dispatch(setHandlingMethod(undefined));
      });
    },
    [dispatch],
  );

  const addItems = useCallback(() => {
    validateItems(AdministrationItemListHandlingMethod.ADD);
  }, [validateItems]);
  const replaceItems = useCallback(() => {
    validateItems(AdministrationItemListHandlingMethod.REPLACE);
  }, [validateItems]);

  if (!selectedTargetList) {
    return null;
  }

  if (isLoading) {
    return (
      <Column $gap="0.5rem" $minWidth="45rem">
        <LoadingIndicator text="Validating..." />
      </Column>
    );
  } else {
    return (
      <Column $gap="1rem">
        <Row>
          <IdentificationTypeComponent
            type="Store"
            value={warehouseIdentificationType}
            onChange={selectWarehouseIdentificationHandler}
            id="WarehouseIdentificationTypeSelect"
          />
        </Row>
        <Row>
          <IdentificationTypeComponent
            type="Product"
            value={productIdentificationType}
            onChange={selectProductIdentificationHandler}
            id="ProductIdentificationTypeSelect"
          />
        </Row>
        <Row $gap="1rem">
          <Column $gap="0.5rem" $minWidth="45rem">
            <TargetListItemsTextArea label="Items" />
            <ActionsBar data-testid="TargetListEditorActions">
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="add-to-target-list"
                type="primary"
                onClick={addItems}
              >
                Add to Target List...
              </Button>
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="replace-target-list"
                type="primary"
                onClick={replaceItems}
              >
                Replace Target List...
              </Button>
            </ActionsBar>
          </Column>
        </Row>
      </Column>
    );
  }
};

export default TargetListEditor;
