import { ActionsBar } from '@ydistri/ds';
import React from 'react';
import ShowActualDataAction from './actions/ShowActualDataAction';
import ToggleCumulativeForecast from './actions/ToggleCumulativeForecast';
import ToggleShowSkuInfoAction from './actions/ToggleShowSkuInfoAction';
import OpenDailySalesAction from './actions/OpenDailySalesAction';

interface SkuDetailActionsProps {
  onShowActualData: () => void;
  showActualData: boolean;
  onToggleCumulativeForecast: () => void;
  cumulativeForecast: boolean;
  onToggleShowSkuInfo: () => void;
  showSkuInfo: boolean;
  manualCalculation?: boolean;
  skuId: number;
}

const SkuDetailActions: React.FC<SkuDetailActionsProps> = ({
  onShowActualData,
  showActualData,
  onToggleCumulativeForecast,
  cumulativeForecast,
  onToggleShowSkuInfo,
  showSkuInfo,
  manualCalculation = false,
  skuId,
}) => {
  return (
    <ActionsBar wrap={false}>
      {!manualCalculation && (
        <ToggleShowSkuInfoAction
          onClick={onToggleShowSkuInfo}
          checked={showSkuInfo}
          size="xSmall"
        />
      )}
      <ToggleCumulativeForecast
        onClick={onToggleCumulativeForecast}
        checked={cumulativeForecast}
        size="xSmall"
      />
      <ShowActualDataAction onClick={onShowActualData} checked={showActualData} size="xSmall" />
      <OpenDailySalesAction skuId={skuId} size="xSmall" />
    </ActionsBar>
  );
};

export default SkuDetailActions;
