import { Column, Section } from '@ydistri/ds';
import React, { useMemo } from 'react';
import RedistributionImportActionsBar from './RedistributionImportActionsBar';
import ProcessingSteps from './ProcessingSteps';
import { styled } from 'styled-components';
import { COLORS } from '../../../../../../styles/style';
import RedistributionImportStepImport from './steps/1_import/RedistributionImportStepImport';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../store';
import RedistributionImportStepValidation from './steps/2_validate/RedistributionImportStepValidation';
import RedistributionImportStepFinalized from './steps/3_finalize/RedistributionImportStepFinalized';
import { ProcessingStep } from '../redistributionImportTypes';

const ContentWrapper = styled(Column)`
  padding: 1rem;
  background-color: ${COLORS.GREY_LIGHT};
  border-radius: 1rem;
  border: 1px dashed ${COLORS.GREY_BORDER};
  margin-top: 16px;
  flex-grow: 1;
`;

const CalculationRedistributionImportContent: React.FC = () => {
  const currentStepIndex = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.currentStepIndex,
  );
  const currentStepRunning = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.currentStepRunning,
  );

  const currentStepNode = useMemo(() => {
    switch (currentStepIndex) {
      case ProcessingStep.IMPORT:
        return <RedistributionImportStepImport />;
      case ProcessingStep.VALIDATION:
        return <RedistributionImportStepValidation />;
      case ProcessingStep.FINALIZED:
        return <RedistributionImportStepFinalized />;
    }
  }, [currentStepIndex]);

  return (
    <Section header="Import redistribution" headerActions={<RedistributionImportActionsBar />}>
      <ProcessingSteps
        steps={['Import items', 'Validation', 'Finalized']}
        currentStepIndex={currentStepIndex}
        currentStepRunning={currentStepRunning}
      />
      <ContentWrapper>{currentStepNode}</ContentWrapper>
    </Section>
  );
};

export default CalculationRedistributionImportContent;
