import React from 'react';
import { Button } from 'antd';
import TableExport from '../../../components/global/TableExport/TableExport';
import { ColumnsType } from 'antd/es/table';

interface TableStoreEvaluationsExportProps<T> {
  title?: string;
  dataSource: T[];
  columns: ColumnsType<T>;
}

const TableStoreEvaluationsExport = <T extends object>({
  title,
  dataSource,
  columns,
}: TableStoreEvaluationsExportProps<T>): React.ReactElement => {
  return (
    <TableExport
      columns={columns}
      dataSource={dataSource}
      fileName={`${title}.xlsx`}
      sheetName="Store evaluations"
    >
      <Button>Export</Button>
    </TableExport>
  );
};

export default TableStoreEvaluationsExport;
