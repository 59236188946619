import { CalculationDetailResponse, CalculationMode, CalculationType } from '@ydistri/api-sdk';
import React, { useCallback } from 'react';
import { Button } from '@ydistri/ds';
import { TbFileImport } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import {
  RedistributionDisplayMode,
  setRedistributionDisplayMode,
} from '../../../../../Calculations/calculationsSlice';

interface ManualCalculationImportActionProps {
  calculation: CalculationDetailResponse;
}

const ManualCalculationImportAction: React.FC<ManualCalculationImportActionProps> = ({
  calculation,
}) => {
  const dispatch = useDispatch();

  const clickHandler = useCallback(() => {
    dispatch(setRedistributionDisplayMode(RedistributionDisplayMode.IMPORT));
  }, [dispatch]);

  //Import is enabled only for manual and not production calculations
  if (
    !(
      calculation.mode === CalculationMode.Manual &&
      (calculation.type === CalculationType.Simulation ||
        calculation.type === CalculationType.Private)
    )
  ) {
    return null;
  }

  return (
    <Button
      type="primary"
      $textVariant="semibold"
      icon={<TbFileImport size={18} />}
      onClick={clickHandler}
    >
      Import...
    </Button>
  );
};

export default ManualCalculationImportAction;
