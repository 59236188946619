import { CalculationInfo, CalculationTableParams, PrivateFilterType } from '../calculationsTypes';
import { CalculationWithRelatedEntitiesResponse, Operation, SortDirection } from '@ydistri/api-sdk';
import { CalculationSelect } from '../../../components/global/CalculationSelect/CalculationSelect';
import React, { useCallback } from 'react';
import { Row } from '@ydistri/ds';
import { InfoIconTooltip } from '../../../components/icons/InfoIconTooltip';

export interface ManualCalculationSelectProps {
  selectedCalculationId?: number | null;
  onChange: (value?: CalculationInfo) => void;
}

const manualSelectQueryParams: CalculationTableParams = {
  sortings: [
    {
      fieldName: 'Created',
      direction: SortDirection.Desc,
    },
  ],
  includePrivateCalculations: PrivateFilterType.NONE,
  conditions: [
    {
      fieldName: 'Mode',
      operation: Operation.Eq,
      value: 'Manual',
    },
  ],
};

/**
 * Select for manual calculations. Allows to select just one manual calculation.
 * @param onChange
 * @param selectedValue
 * @constructor
 */
const ManualCalculationSelect: React.FC<ManualCalculationSelectProps> = ({
  onChange,
  selectedCalculationId,
}) => {
  const onCalculationSelected = useCallback(
    (calculation: CalculationWithRelatedEntitiesResponse | null) => {
      if (calculation === null) {
        onChange();
      } else {
        onChange({
          id: calculation.id,
          title: calculation.title,
        });
      }
    },
    [onChange],
  );

  return (
    <Row>
      <CalculationSelect
        selectedCalculationId={selectedCalculationId}
        onChange={onCalculationSelected}
        queryParams={manualSelectQueryParams}
        placeholder="Select manual calculation (optional)"
      />
      <InfoIconTooltip content="Optionally select one of all manual calculations except private and submerged" />
    </Row>
  );
};

export default ManualCalculationSelect;
