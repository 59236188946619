import React from 'react';

import TargetListItemsSection from './detail/TargetListItemsSection';
import TargetListEditModeSection from './edit/TargetListEditModeSection';
import TargetListValidationSection from './validation/TargetListValidationSection';
import { ActiveScreenType } from '../../common/administrationItemsLib';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import NoTargetListSelected from '../components/NoTargetListSelected';

/**
 * Main part of the screen for Target Lists. Shows different content based on current screen type.
 * @constructor
 */
const TargetListAdministrationContent: React.FC = () => {
  const activeScreenType = useSelector(
    (state: ReduxState) => state.targetListAdministration.activeScreenType,
  );

  switch (activeScreenType) {
    case ActiveScreenType.NO_SELECTION:
      return <NoTargetListSelected />;
    case ActiveScreenType.DETAIL:
      return <TargetListItemsSection />;
    case ActiveScreenType.EDIT:
      return <TargetListEditModeSection />;
    case ActiveScreenType.VALIDATION: {
      return <TargetListValidationSection />;
    }
    case ActiveScreenType.DONE: {
      throw new Error('Not implemented yet: ActiveScreenType.DONE case');
    }
  }
};

export default TargetListAdministrationContent;
