import React, { useMemo } from 'react';
import { computeRemSize, ReadOnlyItem, Row } from '@ydistri/ds';
import { computeFinalSupply } from '../../../../../redistributionLib';
import {
  SkuData,
  SkuRedistributionPaneType,
  SkuRedistributionSubset,
} from '../../../../../redistributionTypes';
import { InfoIconTooltip } from '../../../../../../../../components/icons/InfoIconTooltip';
import SupplyInfoTooltip from './SupplyInfoTooltip';
import { styled, useTheme } from 'styled-components';
import { MdWarningAmber } from 'react-icons/md';
import SupplyCoverage from '../SupplyCoverage';

const SkuDataComponentWrapper = styled(Row)`
  padding-left: ${() => computeRemSize(8)};
  padding-right: ${() => computeRemSize(8)};
  min-height: 18px;
`;

/**
 * This component is used for the Inventory and Final Supply values. It sets
 * a min-width to avoid the values jumping around when the numbers change from
 * single digit to three digits and vice versa (the Final Sypply label is moved
 * to the left without this component).
 */
const SkuDataComponentValue = styled.span`
  min-width: 1.5rem;
  display: inline-block;
`;

interface SkuDataComponentProps {
  infoType: SkuRedistributionPaneType;
  /** May be undefined when being loaded, but we need to display empty labels */
  skuData?: SkuData;
  redistributionData: SkuRedistributionSubset;
  showingActualData?: boolean;
  manualCalculation?: boolean;
  hideTooltip?: boolean;
}

interface ForcedQuantityRedistributionWarningProps {
  infoType: SkuRedistributionPaneType;
  skuData: SkuData;
}

/**
 * Warning icon for forced quantity redistribution
 * @param infoType
 * @param skuData
 * @constructor
 */
const ForcedQuantityRedistributionWarning: React.FC<ForcedQuantityRedistributionWarningProps> = ({
  infoType,
  skuData,
}) => {
  const shouldRenderForcedQuantityRedistributionWarning = useMemo(() => {
    return infoType === 'Source' && (skuData.config.isForced || skuData.config.isClosing);
  }, [infoType, skuData]);

  const theme = useTheme();
  const forcedQuantityRedistributionWarningIcon = useMemo(() => {
    return <MdWarningAmber color={theme.colors.primary} />;
  }, [theme]);

  const tooltipContent = useMemo(() => {
    const text = `${
      skuData.config.isClosing ? 'Closing store' : 'Forced quantity'
    } to redistribute`;

    const availableSupply = skuData.attributes.values.AvailableSupply;
    if (availableSupply) {
      return <ReadOnlyItem label={text} value={`${parseFloat(availableSupply).toFixed(0)} MU`} />;
    }
  }, [skuData]);

  if (shouldRenderForcedQuantityRedistributionWarning) {
    return (
      <InfoIconTooltip content={tooltipContent} icon={forcedQuantityRedistributionWarningIcon} />
    );
  }
};

const FinalSupplyComponent: React.FC<SkuDataComponentProps> = ({
  infoType,
  skuData,
  redistributionData,
  manualCalculation = false,
  hideTooltip = false,
}) => {
  const tooltipContent = useMemo(() => {
    if (skuData) {
      return (
        <SupplyInfoTooltip
          sku={skuData}
          infoType={infoType}
          quantity={redistributionData.quantity}
          totalMovedQuantity={redistributionData.totalMovedQuantity}
        />
      );
    }
  }, [infoType, redistributionData.quantity, redistributionData.totalMovedQuantity, skuData]);

  const finalSupplyValue = useMemo(() => {
    if (skuData) {
      return computeFinalSupply(skuData, redistributionData, infoType).toString();
    } else {
      return '-';
    }
  }, [infoType, redistributionData, skuData]);

  return (
    <Row $gap="0.5rem">
      <ReadOnlyItem label="Final supply">
        <SkuDataComponentValue>{finalSupplyValue}</SkuDataComponentValue>
        {skuData && !manualCalculation && (
          <SupplyCoverage
            skuId={skuData?.id}
            supply={parseInt(finalSupplyValue)}
            infoType={infoType}
            displayType="popover"
          />
        )}
      </ReadOnlyItem>
      {skuData && (
        <>
          {!hideTooltip && <InfoIconTooltip content={tooltipContent} />}
          <ForcedQuantityRedistributionWarning infoType={infoType} skuData={skuData} />
        </>
      )}
    </Row>
  );
};

const SkuDataComponent: React.FC<SkuDataComponentProps> = ({
  infoType,
  skuData,
  redistributionData,
  showingActualData = false,
  manualCalculation = false,
  hideTooltip = false,
}) => {
  const openPurchaseOrdersComponent = useMemo(() => {
    if (skuData) {
      const openPurchaseOrdersQuantity = parseFloat(
        skuData.attributes.values.OpenPurchaseOrdersQuantity ?? '0',
      );

      const addOpenPurchaseOrdersToAvailableSupply = skuData.config
        .PtAddOpenPurchaseOrdersToAvailableSupply
        ? parseInt(skuData.config.PtAddOpenPurchaseOrdersToAvailableSupply)
        : 0;

      if (
        infoType === 'Target' &&
        openPurchaseOrdersQuantity > 0 &&
        addOpenPurchaseOrdersToAvailableSupply === 1
      ) {
        return (
          <ReadOnlyItem
            label="Open purchase order"
            value={
              skuData.attributes.values.OpenPurchaseOrdersQuantity
                ? parseFloat(skuData.attributes.values.OpenPurchaseOrdersQuantity).toFixed(0)
                : '...'
            }
          />
        );
      }
    }
  }, [infoType, skuData]);

  const inventoryValue = useMemo((): string => {
    if (skuData?.attributes.values.AvailableSupply) {
      return parseFloat(skuData.attributes.values.AvailableSupply).toFixed(0);
    } else {
      return '-';
    }
  }, [skuData]);

  return (
    <SkuDataComponentWrapper $justifyContent="space-between">
      <Row $gap="2rem">
        <ReadOnlyItem label="Inventory">
          <SkuDataComponentValue>{inventoryValue}</SkuDataComponentValue>
        </ReadOnlyItem>
        {openPurchaseOrdersComponent}
        {!showingActualData && (
          <FinalSupplyComponent
            skuData={skuData}
            infoType={infoType}
            redistributionData={redistributionData}
            manualCalculation={manualCalculation}
            hideTooltip={hideTooltip}
          />
        )}
      </Row>
    </SkuDataComponentWrapper>
  );
};

export default SkuDataComponent;
