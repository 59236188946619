import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductListConfigurationResponse } from '@ydistri/api-sdk';

interface ProductListConfigurationState {
  selectedProductListConfiguration?: ProductListConfigurationResponse | undefined;
}

const initialState: ProductListConfigurationState = {
  selectedProductListConfiguration: undefined,
};

export const productListConfigurationSlice = createSlice({
  name: 'productListConfiguration',
  initialState,
  reducers: {
    setSelectedProductListConfiguration: (
      state,
      action: PayloadAction<ProductListConfigurationState['selectedProductListConfiguration']>,
    ) => {
      state.selectedProductListConfiguration = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setSelectedProductListConfiguration } = productListConfigurationSlice.actions;

export const productListConfigurationReducer = productListConfigurationSlice.reducer;
