import {
  MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
  ParsedCategoryRedistribution,
} from '../categoryInsightsLib';
import React, { useMemo } from 'react';
import { BarPartitionContent, BarLineProps, COLORS, SimpleTooltipContent } from '@ydistri/ds';
import BarRedistribution from './BarRedistribution';
import { computePercentage } from '@ydistri/utils';

const TITLE_ROWS = ['Target', 'by inventory type'];

interface BarTargetByInventoryTypeProps {
  data: ParsedCategoryRedistribution;
}

const BarTargetByInventoryType: React.FC<BarTargetByInventoryTypeProps> = ({ data }) => {
  const barLineData: BarLineProps['data'] = useMemo(() => {
    const percentageDeadstock = computePercentage(data.bySkuType.DeadStock.totalTarget, data.total);
    const percentageSlowmover = computePercentage(data.bySkuType.SlowMover.totalTarget, data.total);
    const percentageFastmover = 100 - percentageDeadstock - percentageSlowmover;

    return [
      {
        percentage: percentageFastmover,
        color: COLORS.BAR_FASTMOVERS,
        tooltip: (
          <SimpleTooltipContent title="Fast movers" value={data.bySkuType.FastMover.totalTarget} />
        ),
        content: (
          <BarPartitionContent
            title="Fast movers"
            percentage={percentageFastmover}
            value={data.bySkuType.FastMover.totalTarget}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageSlowmover,
        color: COLORS.BAR_SLOWMOVERS,
        tooltip: (
          <SimpleTooltipContent title="Slow movers" value={data.bySkuType.SlowMover.totalTarget} />
        ),
        content: (
          <BarPartitionContent
            title="Slow movers"
            percentage={percentageSlowmover}
            value={data.bySkuType.SlowMover.totalTarget}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageDeadstock,
        color: COLORS.BAR_DEADSTOCK,
        tooltip: (
          <SimpleTooltipContent title="Dead stock" value={data.bySkuType.DeadStock.totalTarget} />
        ),
        content: (
          <BarPartitionContent
            title="Dead stock"
            percentage={percentageDeadstock}
            value={data.bySkuType.DeadStock.totalTarget}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
    ];
  }, [
    data.bySkuType.DeadStock.totalTarget,
    data.bySkuType.FastMover.totalTarget,
    data.bySkuType.SlowMover.totalTarget,
    data.total,
  ]);

  return <BarRedistribution barLineData={barLineData} title={TITLE_ROWS} />;
};

export default BarTargetByInventoryType;
