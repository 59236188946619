import React, { useMemo } from 'react';
import { useGetStatusesQuery } from '../../../apis/apiApplication';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { ApplicationStatus } from '@ydistri/api-sdk';
import OverlayMessage, { OverlayCoverDiv, OverlayMessageIcon } from './OverlayComponents';

/**
 * Overlay that is displayed when the application is closed
 * Must be placed inside div with "position: relative"
 * @constructor
 */
const ApplicationClosedOverlay: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: statuses } = useGetStatusesQuery(templateOrCalculation);

  const isActive = useMemo(
    () => statuses?.applicationStatus === ApplicationStatus.Closed,
    [statuses?.applicationStatus],
  );

  return isActive ? (
    <OverlayCoverDiv $topOffset={60}>
      <OverlayMessage
        icon={OverlayMessageIcon.LOCKED}
        title="Computations temporarily unavailable"
        content={statuses?.statusInfo ?? ''}
      />
    </OverlayCoverDiv>
  ) : null;
};

export default ApplicationClosedOverlay;
