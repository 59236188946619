import React from 'react';
import { formatNumber } from '@ydistri/utils';
import { styled } from 'styled-components';
import { useCurrency } from '../../../../../hooks/useCurrency';

const HeaderRow = styled.tr<{ border?: boolean }>`
  td {
    font-size: 1.2rem;
  }
`;

const NumberCell = styled.td`
  text-align: right;
  padding-left: 1rem;
`;

interface TargetPotentialBarTooltipProps {
  title: string;
  value: number;
}

const TargetPotentialBarTooltip: React.FC<TargetPotentialBarTooltipProps> = ({ title, value }) => {
  const currency = useCurrency();

  return (
    <table>
      <thead>
        <HeaderRow>
          <td>{title}</td>
          <NumberCell>
            {formatNumber(value)} {currency}
          </NumberCell>
        </HeaderRow>
      </thead>
    </table>
  );
};

export default TargetPotentialBarTooltip;
