import { CalculationStatus } from '@ydistri/api-sdk';
import { useMemo } from 'react';
import {
  isTemplateEditable,
  isTemplateOutdated,
  isTemplateRunning,
  calculationStatusToNiceString,
} from '@ydistri/utils';
import { useTemplateOrCalculation } from './useTemplateOrCalculation';
import { useGetStatusesQuery } from '../apis/apiApplication';

export interface TemplateStatusInfo {
  isRunning: boolean;
  isEditable: boolean;
  isOutdated: boolean;
  niceName: string;
  status: CalculationStatus;
}

export const useTemplateStatus = (): TemplateStatusInfo => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: statuses } = useGetStatusesQuery(templateOrCalculation);

  const templateStatus: CalculationStatus = statuses?.templateStatus ?? CalculationStatus.Outdated;

  return useMemo(
    () => ({
      isRunning: isTemplateRunning(templateStatus),
      isEditable: isTemplateEditable(templateStatus),
      isOutdated: isTemplateOutdated(templateStatus),
      niceName: calculationStatusToNiceString(templateStatus),
      status: templateStatus,
    }),
    [templateStatus],
  );
};
