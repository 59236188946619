import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { HiOutlineDocumentAdd } from 'react-icons/hi';

interface CreateIconButtonProps {
  disabled?: boolean;
  tooltip: string;
  onClick: () => void;
  size?: IconButtonSize;
}

const CreateIconButton: React.FC<CreateIconButtonProps> = ({
  disabled = false,
  tooltip,
  onClick,
  size = 'normal',
  ...rest
}: CreateIconButtonProps) => {
  const icon = useMemo(() => <HiOutlineDocumentAdd size={IconButton_IconSizes[size]} />, [size]);

  return (
    <IconButton
      icon={icon}
      size={size}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPosition="left"
      onClick={onClick}
      {...rest}
    />
  );
};

export default CreateIconButton;
