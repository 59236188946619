import { ConfigurationFieldType, SkuType } from '@ydistri/api-sdk';
import { useTemplateOrCalculation } from './useTemplateOrCalculation';
import {
  CategoryTreeNode,
  useGetCategoryTreeQuery,
} from '../components/global/CategoryTree/apiCategoryTree';
import { useGetRootCategoryConfigurationQuery } from '../screens/Configuration/apiTemplate';
import { useCallback, useMemo } from 'react';
import { iterableSkuTypes } from '../helpers/types/iterableEnums';
import { isEntityListConfiguration } from '../screens/Configuration/ConfigurationCategories/SegmentPotentialDefinition/segmentPotentialDefinitionLib';

export interface ExceptionInfo {
  categoryId: number;
  categoryName: string;
  value: string;
}

interface FinalConfigurationValues {
  value: string;
  overwritten: boolean;
  exceptions: ExceptionInfo[];
}

type SkuTypeConfigData = Record<SkuType, FinalConfigurationValues | undefined>;

export type BaseRootConfiguration = Record<ConfigurationFieldType, Record<SkuType, string>>;

interface CategoryConfigurationData {
  rootCategoryConfiguration: BaseRootConfiguration | undefined;
  categoryConfigurationData: Record<ConfigurationFieldType, SkuTypeConfigData> | undefined;
  potentialDefinitionExceptionCount: number;
  inventoryDefinitionExceptionCount: number;
}

const emptySkuTypeConfigData: FinalConfigurationValues = {
  value: '0',
  overwritten: false,
  exceptions: [],
};

const configurationInventoryDefinition: ConfigurationFieldType[] = [
  ConfigurationFieldType.CatDeadStock,
  ConfigurationFieldType.CatSlowMoverSales,
  ConfigurationFieldType.CatSlowMoverMonths,
];

const configurationPotentialDefinitionTarget: ConfigurationFieldType[] = [
  ConfigurationFieldType.PtMonthsOfSupplyToGetByForecast,
  ConfigurationFieldType.PtMinForecastConfidence,
  ConfigurationFieldType.PtMinimal6MonthsFrequencyOfSales,
  ConfigurationFieldType.PtAddOpenPurchaseOrdersToAvailableSupply,
  ConfigurationFieldType.PtTargetStockoutsOnly,
  ConfigurationFieldType.PtForcingLevelTypeId,
  ConfigurationFieldType.PtTargetList,
  ConfigurationFieldType.PtMinLayerList,
];

export const useCategoryConfiguration = (categoryId: number): CategoryConfigurationData => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories } = useGetCategoryTreeQuery(templateOrCalculation);
  const { data: rootCategoryConfiguration } =
    useGetRootCategoryConfigurationQuery(templateOrCalculation);

  const calculateTotalExceptions = useCallback((c: CategoryTreeNode | undefined) => {
    let totalValue = 0;
    let parent: CategoryTreeNode | null = c ?? null;
    while (parent !== null) {
      //sum value of all exceptions
      totalValue += parent.exceptions.reduce((acc, e) => acc + parseInt(e.value), 0);
      parent = parent.parent;
    }

    return totalValue;
  }, []);

  const allExceptionsValueTotal = calculateTotalExceptions(categories?.[categoryId]);

  const getValidSkuType = useCallback(
    (st: SkuType | null | undefined) => st ?? SkuType.FastMover,
    [],
  );

  const baseRootConfiguration: BaseRootConfiguration | undefined = useMemo(() => {
    const base: Partial<CategoryConfigurationData['rootCategoryConfiguration']> = {};

    if (rootCategoryConfiguration) {
      rootCategoryConfiguration.forEach(field => {
        const value = field.value;
        const fieldType = field.fieldType;
        const skuType = getValidSkuType(field.skuType);

        if (!base[fieldType]) {
          let defaultValue = '0';

          if (isEntityListConfiguration(fieldType)) {
            defaultValue = '[]';
          }

          base[fieldType] = {
            [SkuType.DeadStock]: defaultValue,
            [SkuType.SlowMover]: defaultValue,
            [SkuType.FastMover]: defaultValue,
          };
        }

        const fieldTypeData = base[fieldType]; // Temporary variable to hold the reference
        if (fieldTypeData) {
          fieldTypeData[skuType] = value;
        }
      });
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return base as BaseRootConfiguration;
  }, [getValidSkuType, rootCategoryConfiguration]);

  return useMemo(() => {
    const category = categories?.[categoryId];
    if (rootCategoryConfiguration && category) {
      const base: Partial<CategoryConfigurationData['categoryConfigurationData']> = {};

      rootCategoryConfiguration.forEach(field => {
        let f = base[field.fieldType];

        if (!f) {
          f = iterableSkuTypes.reduce((acc, skuType) => {
            acc[skuType] = { ...emptySkuTypeConfigData };
            return acc;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/prefer-reduce-type-parameter
          }, {} as SkuTypeConfigData);
        }

        const value = field.value;
        f[getValidSkuType(field.skuType)] = {
          value,
          overwritten: false,
          exceptions: [
            /*{
              categoryId: 1,
              categoryName: 'Global company setting',
              value,
            },*/
          ],
        };

        base[field.fieldType] = f;
      });

      let potentialDefinitionExceptionCount = 0;
      let inventoryDefinitionExceptionCount = 0;

      category.exceptions.forEach(e => {
        const skuType = e.skuType ?? null;
        if (configurationInventoryDefinition.includes(e.fieldType)) {
          inventoryDefinitionExceptionCount++;
        } else {
          //for TARGET, we want to count only fast mover exceptions, because every field is saved 3 times (for each sku type)
          //this is because of our DB architecture, but would be nice to change in the future
          if (
            !configurationPotentialDefinitionTarget.includes(e.fieldType) ||
            getValidSkuType(skuType) === SkuType.FastMover
          ) {
            potentialDefinitionExceptionCount++;
          }
        }
        const f = base[e.fieldType];
        if (f !== undefined) {
          f[getValidSkuType(skuType)] = {
            value: e.value,
            overwritten: true,
            exceptions: f[getValidSkuType(skuType)]?.exceptions ?? [],
          };
        }
      });

      let parent: CategoryTreeNode | null = category;

      while (parent !== null) {
        parent = categories[parent.id] ?? null;
        if (parent) {
          if (parent.exceptions.length > 0) {
            parent.exceptions.forEach(exc => {
              const f = base[exc.fieldType];
              const fSku = f?.[getValidSkuType(exc.skuType)];
              if (fSku && parent) {
                if (fSku.exceptions.length === 0) {
                  fSku.value = exc.value;
                }

                fSku.exceptions.push({
                  categoryId: parent.id,
                  categoryName: parent.title,
                  value: exc.value,
                });
              }
            });
          }
          parent = parent.parent;
        }
      }

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return {
        rootCategoryConfiguration: baseRootConfiguration,
        categoryConfigurationData: base,
        potentialDefinitionExceptionCount,
        inventoryDefinitionExceptionCount,
      } as CategoryConfigurationData;
    }

    return {
      rootCategoryConfiguration: undefined,
      entityListRootCategoryConfiguration: undefined,
      categoryConfigurationData: undefined,
      potentialDefinitionExceptionCount: 0,
      inventoryDefinitionExceptionCount: 0,
    };

    // !!!!!!!!!! allExceptionsValueTotal needs to be to re-run the hook when the exceptions change !!!!!!!!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseRootConfiguration,
    rootCategoryConfiguration,
    getValidSkuType,
    allExceptionsValueTotal,
    categoryId,
    categories,
  ]);
};
