import { ConfigurationFieldType } from '@ydistri/api-sdk';

export type EntityListConfiguration = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EntityListId: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IsEnabled: number;
};

export const parseEntityListConfigs = (input: string): EntityListConfiguration[] =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  JSON.parse(input) as EntityListConfiguration[];

export const isEntityListTarget = (fieldType: ConfigurationFieldType): boolean =>
  fieldType === ConfigurationFieldType.PtMinLayerList;

export const createEntityListConfig = (
  entityListId: number,
  isEnabled: number,
): EntityListConfiguration => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EntityListId: entityListId,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IsEnabled: isEnabled,
});
