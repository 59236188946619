import React from 'react';
import { ActiveScreenType } from '../../common/administrationItemsLib';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import NoEntityListSelected from '../components/NoEntityListSelected';
import { EntityListType } from '@ydistri/api-sdk';
import EntityListItemsSection from './detail/EntityListItemsSection';
import EntityListEditModeSection from './edit/EntityListEditModeSection';

interface EntityListAdministrationContentProps {
  entityListType: EntityListType;
}

const EntityListAdministrationContent: React.FC<EntityListAdministrationContentProps> = ({
  entityListType,
}) => {
  const activeScreenType = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].activeScreenType,
  );

  switch (activeScreenType) {
    case ActiveScreenType.NO_SELECTION:
      return <NoEntityListSelected entityListType={entityListType} />;
    case ActiveScreenType.DETAIL:
      return <EntityListItemsSection entityListType={entityListType} />;
    case ActiveScreenType.EDIT:
      return <EntityListEditModeSection entityListType={entityListType} />;
    case ActiveScreenType.VALIDATION:
      return 'Not implemented!';
    default:
      return null;
  }
};

export default EntityListAdministrationContent;
