import React, { useCallback } from 'react';
import { ConfigurationSwitch } from '@ydistri/ds';
import { rtkQueryPatchStatusToConfigurationFieldStatus } from '@ydistri/utils';
import {
  UpdateSkuClassConfigurationPayload,
  useUpdateSkuClassConfigurationMutation,
} from './apiSkuClasses';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { SkuClassConfigurationRequest } from '@ydistri/api-sdk';

interface SkuClassConfigurationSwitchProps {
  value: boolean;
  skuClassId: number;
  field: keyof SkuClassConfigurationRequest;
}

const SkuClassConfigurationSwitch: React.FC<SkuClassConfigurationSwitchProps> = ({
  value,
  skuClassId,
  field,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [updateConfiguration, updateConfigurationStatus] = useUpdateSkuClassConfigurationMutation();

  const onChangeHandler = useCallback(() => {
    const updatePayload: UpdateSkuClassConfigurationPayload = {
      templateOrCalculation,
      skuClassId,
      request: {
        [field]: !value,
      },
    };
    updateConfiguration(updatePayload);
  }, [templateOrCalculation, skuClassId, field, value, updateConfiguration]);

  return (
    <ConfigurationSwitch
      disabled={templateOrCalculation.type === 'Calculation'}
      status={rtkQueryPatchStatusToConfigurationFieldStatus(updateConfigurationStatus)}
      value={value}
      onChange={onChangeHandler}
      includeEmptySpace={false}
    />
  );
};

export default SkuClassConfigurationSwitch;
