import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import { computePercentage, formatNumber } from '@ydistri/utils';
import StatisticsRegionsIcon from '../../../../assets/statistics-regions.svg';
import { SimpleTable, SimpleTableDivider } from './components/SimpleTable';
import StatisticsSection from './components/StatisticsSection';
import { RowWrap, computeRemSize } from '@ydistri/ds';
import TitleWithModalButton from './components/TitleWithModalButton';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { useGetStatsRegionsQuery, useGetStatisticsQuery } from './apiCalculationStatistics';

export interface RegionStat {
  title: string;
  key: string;
  sourceValue: number;
  sourceValuePercentage: number;
  sourceQuantity: number;
  sourceQuantityPercentage: number;
  targetValue: number;
  targetValuePercentage: number;
  targetQuantity: number;
  targetQuantityPercentage: number;
}

type RegionStatistics = RegionStat | SimpleTableDivider;

const getRegionsStatisticsColumns = (currencyId: string): ColumnsType<RegionStatistics> => {
  return [
    {
      title: 'Region',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => {
        if ('title' in a && 'title' in b) return a.title.localeCompare(b.title);
        return 0;
      },
    },
    {
      title: 'Source value',
      dataIndex: 'sourceValue',
      key: 'sourceValue',
      align: 'right',
      render: (sourceValue: number) => `${formatNumber(sourceValue)} ${currencyId}`,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        'sourceValue' in a && 'sourceValue' in b ? a.sourceValue - b.sourceValue : 0,
    },
    {
      title: '',
      dataIndex: 'sourceValuePercentage',
      key: 'sourceValuePercentage',
      align: 'right',
      width: computeRemSize(35),
      render: (sourceValuePercentage: number) => `(${sourceValuePercentage}%)`,
    },
    {
      title: 'Source quantity',
      dataIndex: 'sourceQuantity',
      key: 'sourceQuantity',
      align: 'right',
      render: (sourceQuantity: number) => `${formatNumber(sourceQuantity)} MU`,
      sorter: (a, b) =>
        'sourceQuantity' in a && 'sourceQuantity' in b ? a.sourceQuantity - b.sourceQuantity : 0,
    },
    {
      title: '',
      dataIndex: 'sourceQuantityPercentage',
      key: 'sourceQuantityPercentage',
      align: 'right',
      width: computeRemSize(35),
      render: (sourceQuantityPercentage: number) => `(${sourceQuantityPercentage}%)`,
    },
    {
      title: 'Target value',
      dataIndex: 'targetValue',
      key: 'targetValue',
      align: 'right',
      render: (targetValue: number) => `${formatNumber(targetValue)} ${currencyId}`,
      sorter: (a, b) =>
        'targetValue' in a && 'targetValue' in b ? a.targetValue - b.targetValue : 0,
    },
    {
      title: '',
      dataIndex: 'targetValuePercentage',
      key: 'targetValuePercentage',
      align: 'right',
      width: computeRemSize(35),
      render: (targetValuePercentage: number) => `(${targetValuePercentage}%)`,
    },
    {
      title: 'Target quantity',
      dataIndex: 'targetQuantity',
      key: 'targetQuantity',
      align: 'right',
      render: (targetQuantity: number) => `${formatNumber(targetQuantity)} MU`,
      sorter: (a, b) =>
        'targetQuantity' in a && 'targetQuantity' in b ? a.targetQuantity - b.targetQuantity : 0,
    },
    {
      title: '',
      dataIndex: 'targetQuantityPercentage',
      key: 'targetQuantityPercentage',
      align: 'right',
      width: computeRemSize(35),
      render: (targetQuantityPercentage: number) => `(${targetQuantityPercentage}%)`,
    },
  ];
};

const SectionRegions: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency() ?? '...';
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);
  const { data, isLoading } = useGetStatsRegionsQuery({
    calculationId: templateOrCalculation.id,
    departmentId,
  });

  const { data: overallStatisticsData, isFetching: isFetchingStatistics } = useGetStatisticsQuery({
    calculationId: templateOrCalculation.id,
    departmentId,
    clusterType,
  });

  const regionStatisticsColumns: ColumnsType<RegionStatistics> = useMemo(
    () => getRegionsStatisticsColumns(currencyId),
    [currencyId],
  );

  const regionData: RegionStatistics[] = useMemo(() => {
    if (!data || !overallStatisticsData) return [];

    const quantity = overallStatisticsData?.quantity ?? 0;
    const value = overallStatisticsData?.value ?? 0;

    return data.map(item => ({
      title: item.region.name,
      key: `${item.region.id}`,
      sourceValue: item.valueSource,
      sourceQuantity: item.quantitySource,
      targetValue: item.valueTarget,
      targetQuantity: item.quantityTarget,
      sourceValuePercentage: computePercentage(item.valueSource, value),
      sourceQuantityPercentage: computePercentage(item.quantitySource, quantity),
      targetValuePercentage: computePercentage(item.valueTarget, value),
      targetQuantityPercentage: computePercentage(item.quantityTarget, quantity),
    }));
  }, [data, overallStatisticsData]);

  return (
    <RowWrap>
      <StatisticsSection icon={StatisticsRegionsIcon}>
        <TitleWithModalButton title="Regions" />
        <SimpleTable<RegionStatistics>
          id="region-statistics"
          rowKey="key"
          dataSource={regionData}
          columns={regionStatisticsColumns}
          loading={isLoading || isFetchingStatistics}
          showHeader={true}
        />
      </StatisticsSection>
    </RowWrap>
  );
};

export default SectionRegions;
