/* eslint-disable @typescript-eslint/naming-convention */
import { CategoryTreeNode } from '../../../components/global/CategoryTree/apiCategoryTree';
import {
  getSourcePotentialFromSummary,
  getTargetPotentialFromSummary,
  ParsedCategoryInsights,
} from '../../../components/global/CategoryInsights/categoryInsightsLib';

export const DRILL_DOWN_TYPE_URL_PARAM = 'drillDownType';

export type SkuTypeForDrilldown = 'total' | 'DeadStock' | 'SlowMover' | 'FastMover';

export type TCategoryOverviewLoaded = Record<number, ParsedCategoryInsights | undefined>;

export interface DrillDownData {
  availableSupply: number;
  revenue: number;
  margin: number;
  skuCount: number;
  potentialSource: number;
  potentialTarget: number;
}

export type StoreDrillDownData = DrillDownData & {
  customerStoreId: string;
  storeName: string;
  storeType: string;
  storeRegion: string;
};

export type CategoryDrillDownData = DrillDownData & {
  categoryId: number;
  categorySlug: string;
  parentCategoryId: number | null;
  categoryLevel: number;
  categoryName: string;
  addressableRedistribution: number;
  addressableRedistributionForStockout: number;
  pairingFinal: number;
  pairingFilledStockout: number;
};

export function createCategoryDrillDownData(
  skuType: SkuTypeForDrilldown,
  neededCategories: CategoryTreeNode[],
  loadedCategoryOverviews: TCategoryOverviewLoaded,
  takeSupplyAndRevenueFromAttributes: boolean,
): CategoryDrillDownData[] {
  const categoryDrillDownData: CategoryDrillDownData[] = [];

  const neededCategoryIdsForSorting = neededCategories.map(nc => nc.id);

  neededCategories.forEach(nc => {
    const overview = loadedCategoryOverviews[nc.id];
    if (overview) {
      console.log('overview', nc.id, overview.redistributions);

      const data: CategoryDrillDownData = {
        categoryId: nc.id,
        categorySlug: nc.slug,
        parentCategoryId: nc.parentCategoryId ?? null,
        categoryLevel: nc.level,
        categoryName: nc.title,
        availableSupply: takeSupplyAndRevenueFromAttributes
          ? overview.attributes.AvailableSupplyValue
          : (overview.summaries?.EligibleAvailableSupplyValue[skuType] ?? 0),
        revenue: takeSupplyAndRevenueFromAttributes
          ? overview.attributes.MonthlyRevenueSalePriceValue
          : (overview.summaries?.EligibleMonthlyRevenue[skuType] ?? 0),
        skuCount: overview.summaries?.EligibleSourceSkuCount[skuType] ?? 0,
        potentialSource: getSourcePotentialFromSummary(overview.summaries, skuType),
        potentialTarget: getTargetPotentialFromSummary(overview.summaries, skuType),
        addressableRedistribution: overview.summaries?.TheoreticalPotentialValue[skuType] ?? 0,
        addressableRedistributionForStockout:
          overview.summaries?.TheoreticalPotentialStockoutValue[skuType] ?? 0,
        pairingFinal:
          (skuType === 'total'
            ? overview.redistributions?.total
            : overview.redistributions?.bySkuType[skuType].totalSource) ?? 0,
        pairingFilledStockout:
          overview.redistributions?.byRedistributionType.SendToStockoutValue[skuType] ?? 0,
        margin: overview.summaries?.EligibleMargin[skuType] ?? 0,
      };

      categoryDrillDownData.push(data);
    }
  });

  return categoryDrillDownData.sort((a, b) => {
    if (
      neededCategoryIdsForSorting.indexOf(a.categoryId) <
      neededCategoryIdsForSorting.indexOf(b.categoryId)
    ) {
      return -1;
    }
    return 1;
  });
}
