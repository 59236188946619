import React from 'react';
import DetailSalesTable from './DetailSalesTable';
import ScrolledTableWrapper from '../../../../../../components/domain/SKUTable/ScrolledTableWrapper';
import { YColumnsType } from '@ydistri/ds';
import { DetailSaleRowData } from '../../../../../../lib/sales/salesTypes';

interface DetailSalesTableWrapperProps {
  loadingSalesData?: boolean;
  columns: YColumnsType<DetailSaleRowData>[];
  data: DetailSaleRowData[];
}

const DetailSalesTableWrapper: React.FC<DetailSalesTableWrapperProps> = props => {
  return (
    <ScrolledTableWrapper offset={80}>
      <DetailSalesTable {...props} />
    </ScrolledTableWrapper>
  );
};

export default DetailSalesTableWrapper;
