import { apiSlice, TemplateOrCalculation, wrongTemplateInHeaders } from './api';
import {
  ApiOperationType,
  CalculationConfigurationRequest,
  CalculationConfigurationResponse,
  RegionFlowType,
  SuperTargetRedistributionType,
} from '@ydistri/api-sdk';
import { CalculationsCollection, CurrentSetupCollection } from '../swagger/collections';
import { SignalConfigurationChanged } from '../signalr/signalrInterfaces';
import { signalrClient } from '../signalr/client';
import { isEnumValue } from '@ydistri/utils';
import { getTags } from './apiLib';

const { TAGS, TAGS_ARRAY } = getTags('globalConfiguration');

export interface UpdateCalculationConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  request: CalculationConfigurationRequest;
}

export const apiGlobalConfiguration = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getCalculationConfiguration: builder.query<
        CalculationConfigurationResponse,
        TemplateOrCalculation
      >({
        queryFn: async arg => {
          if (wrongTemplateInHeaders(arg))
            return {
              data: {
                productListsAllEnabled: true,
                brandsAllEnabled: true,
                regionFlowTypeId: RegionFlowType.AllEnabled,
                superTargetRedistributionTypeId: SuperTargetRedistributionType.DoNotUse,
                useClosingStores: false,
                isExperimentConfigurationsEnabled: false,
              },
            };
          const {
            data: { data: calculationConfiguration },
          } = await (arg.type === 'Template'
            ? CurrentSetupCollection.calculationConfigurationsList()
            : CalculationsCollection.getCalculationConfigurations(arg.id));

          //TODO - useClosingStores was removed from api, temporary solution
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (calculationConfiguration.useClosingStores === undefined) {
            calculationConfiguration.useClosingStores = false;
          }

          return { data: calculationConfiguration };
        },
        providesTags: [TAGS.calculationConfiguration],
        async onCacheEntryAdded(
          // eslint-disable-next-line no-unused-vars
          { type: _, id: selectedTemplateId },
          api,
        ) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              console.log('CONFIGURATION CHANGED', signal);
              if (selectedTemplateId !== signal.templateId) return;
              if (
                signal.entityType === 'CalculationConfiguration' &&
                signal.operationType === ApiOperationType.Patch
              )
                api.updateCachedData(draft => {
                  signal.updates.forEach(update => {
                    const field = update.fieldName;
                    const value = update.value;

                    if (field in draft && value) {
                      switch (field) {
                        case 'productListsAllEnabled':
                        case 'brandsAllEnabled':
                        case 'useClosingStores':
                          if (value === '0' || value === '1') {
                            draft[field] = value === '1';
                          } else {
                            throw new Error("Value from signalR is not '0' or '1'!");
                          }
                          break;
                        case 'regionFlowTypeId':
                          if (isEnumValue(RegionFlowType, value)) {
                            draft[field] = value;
                          } else {
                            throw new Error('Value from signalR is not regionFlowType!');
                          }
                          break;
                        case 'superTargetRedistributionTypeId':
                          if (isEnumValue(SuperTargetRedistributionType, value)) {
                            draft[field] = value;
                          } else {
                            throw new Error(
                              'Value from signalR is not SuperTargetRedistributionType!',
                            );
                          }
                          break;
                      }
                    }
                  });
                });
            };

            signalrClient.on('TemplateConfigurationChanged', listener);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),

      updateCalculationConfiguration: builder.mutation<
        CalculationConfigurationResponse,
        UpdateCalculationConfigurationPayload
      >({
        queryFn: async payload => {
          const { data } = await CurrentSetupCollection.calculationConfigurationsPartialUpdate(
            payload.request,
          );

          return data;
        },
        onQueryStarted(payload, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiGlobalConfiguration.util.updateQueryData(
              'getCalculationConfiguration',
              payload.templateOrCalculation,
              draft => {
                Object.assign(draft, payload.request);
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      }),
    }),
  });

export const { useGetCalculationConfigurationQuery, useUpdateCalculationConfigurationMutation } =
  apiGlobalConfiguration;
