import { DropdownProps, Select, Spin } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { css, styled } from 'styled-components';
import { SearchableSelectOptionType, selectFilterHandler } from '@ydistri/utils';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { COLORS, computeRemSize } from '@ydistri/ds';
import { useGetCalculationsQuery } from '../../../screens/Calculations/apiCalculations';
import { CalculationTableParams } from '../../../screens/Calculations/calculationsTypes';
import { CalculationWithRelatedEntitiesResponse } from '@ydistri/api-sdk';
import { useSelectedProjectCode } from '../../../hooks/useSelectedProjectCode';

const dropdownAlign: DropdownProps['align'] = { offset: [0, -6] };
const selectWidth = computeRemSize(500);

type SelectVariant = 'default' | 'grey';

interface StyledSelectProps {
  $variant?: SelectVariant;
}

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: ${computeRemSize(32)};
  font-size: ${computeRemSize(14)} !important;
  gap: 0.5rem;
  border-bottom: 1px solid ${COLORS.GREY_MEDIUM2};
`;

const OptionLeft = styled.div`
  flex-basis: 3.5rem;
  text-align: right;
  padding-right: 0.25rem;
  min-width: 3.5rem;
`;

const OptionTitle = styled.div`
  padding-left: 0.25rem;
  max-width: calc(${selectWidth} - 7rem);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;

const StyledSelect = styled(Select<number, DefaultOptionType>)<StyledSelectProps>`
  height: ${computeRemSize(40)} !important;
  width: ${selectWidth} !important;

  &.ant-select {
    margin-left: 0 !important;
  }

  .ant-select-selector {
    height: ${computeRemSize(40)} !important;
    font-size: ${computeRemSize(14)} !important;
    border-color: ${p => p.theme.colors.border} !important;
    ${p =>
      p.$variant === 'grey' &&
      css`
        background-color: ${p => p.theme.colors.pageBackground} !important;
      `}
  }

  .ant-select-selection-item {
    line-height: ${computeRemSize(16)} !important;
    display: flex !important;
    align-items: center !important;

    ${OptionRow} {
      border-bottom: 0;
    }
  }

  .ant-select-selection-search {
    display: flex !important;
    align-items: center !important;
  }

  .ant-select-selection-search-input {
    font-size: ${computeRemSize(14)} !important;
  }
`;

const selectElementStyle = { margin: '0.5rem' };

interface CalculationSelectProps {
  selectedCalculationId?: number | null;
  variant?: SelectVariant;
  onChange: (calculation: CalculationWithRelatedEntitiesResponse | null) => void;
  placeholder?: React.ReactNode;
  queryParams?: CalculationTableParams;
  allowClear?: boolean;
}

export const CalculationSelect: React.FC<CalculationSelectProps> = ({
  onChange,
  variant = 'default',
  selectedCalculationId,
  placeholder,
  queryParams = {},
  allowClear = true,
}) => {
  const projectCode = useSelectedProjectCode();

  const queryParamsWithProjectCode = useMemo(() => {
    if (queryParams.projectCode) {
      return queryParams;
    } else {
      return {
        ...queryParams,
        projectCode,
      };
    }
  }, [projectCode, queryParams]);

  const { data: calculations, isLoading } = useGetCalculationsQuery(queryParamsWithProjectCode);

  const options: SearchableSelectOptionType[] = useMemo(() => {
    if (calculations) {
      return calculations.data.map(calculation => ({
        value: calculation.id,
        label: (
          <OptionRow>
            <OptionLeft>#{calculation.id}</OptionLeft>
            <OptionTitle title={calculation.title}>{calculation.title}</OptionTitle>
          </OptionRow>
        ),
        searchableString: calculation.title,
      }));
    }
    return [];
  }, [calculations]);

  const dropdownStyle = useMemo(
    () => ({
      borderLeft: `${computeRemSize(1)} solid ${COLORS.BORDER}`,
      borderRight: `${computeRemSize(1)} solid ${COLORS.BORDER}`,
      borderBottom: `${computeRemSize(1)} solid ${COLORS.BORDER}`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      background: variant === 'grey' ? COLORS.PAGE_BACKGROUND : 'white',
      boxShadow: 'none',
    }),
    [variant],
  );

  const onChangeHandler = useCallback(
    (value: number) => {
      if (calculations) {
        const calculation = calculations.data.find(c => c.id === value);
        if (calculation) {
          onChange(calculation);
        } else {
          onChange(null);
        }
      }
    },
    [calculations, onChange],
  );

  return (
    <Spin spinning={isLoading}>
      <StyledSelect
        $variant={variant}
        value={selectedCalculationId}
        onChange={onChangeHandler}
        options={options}
        showSearch={true}
        variant="outlined"
        size="large"
        style={selectElementStyle}
        filterOption={selectFilterHandler}
        dropdownAlign={dropdownAlign}
        dropdownStyle={dropdownStyle}
        listHeight={window.innerHeight * 0.6} //does not work right after resizing window
        data-testid="calculation-select"
        loading={isLoading}
        disabled={isLoading}
        placeholder={placeholder}
        allowClear={allowClear}
      />
    </Spin>
  );
};
