import React from 'react';
import { styled } from 'styled-components';

interface SimpleListProps {
  values: React.ReactNode[];
}

const ListWrapper = styled.ul`
  margin: 0;
  padding-inline-start: 16px;
`;

/**
 * Render simple list of string without any margin and slightly moved to the left
 * @param values
 * @constructor
 */
const SimpleList: React.FC<SimpleListProps> = ({ values }) => {
  return (
    <ListWrapper>
      {values.map((value, index) => {
        const key = `ListItem_${index}`;
        return <li key={key}>{value}</li>;
      })}
    </ListWrapper>
  );
};

export default SimpleList;
