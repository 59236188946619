import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import AdministrationHeader from './navigation/AdministrationHeader';
import { MainContent, MainContentWrapper } from '@ydistri/ds';

const ProjectAdministration: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(RoutingPage.PROJECT_ADMINISTRATION));
  }, [dispatch]);

  return (
    <>
      <AdministrationHeader />
      <MainContentWrapper data-type="ProjectAdministrationContentWrapper">
        <MainContent data-type="ProjectAdministrationContent">
          <Outlet />
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default ProjectAdministration;
