import {
  CalculationConfigurationResponse,
  RegionFlowGroupResponse,
  RegionFlowType,
} from '@ydistri/api-sdk';
import {
  ValidatorMessage,
  ValidatorMessageSeverity,
  ValidatorResponse,
  ValidatorSection,
} from './validatorLib';
import { createDebugLog } from '../../../lib/utils/logging';
import { initialValidatorInfoRegions } from './validatorInfoLib';

const debugLog = createDebugLog('Validator', 'validateRegions');

enum Messages {
  CUSTOM_GROUPS_BUT_NO_GROUP,
  EMPTY_REGION_GROUP,
}

const section = ValidatorSection.REGIONS;

type PartialMessage = Omit<ValidatorMessage, 'section'>;

const addMessage = (condition: boolean, messages: ValidatorMessage[], message: PartialMessage) => {
  if (!condition) return;
  messages.push({
    section,
    ...message,
  });
};

const MESSAGES = {
  customGroupButNoGroup: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'Custom setup of redistribution flow, but no region group is defined.',
    key: Messages.CUSTOM_GROUPS_BUT_NO_GROUP,
  },
  emptyRegionGroup: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'At least one Region Group is empty.',
    key: Messages.EMPTY_REGION_GROUP,
  },
} as const;

export const validateRegions = (
  regionGroups: RegionFlowGroupResponse[],
  templateConfig: CalculationConfigurationResponse,
): ValidatorResponse<typeof section> => {
  debugLog('regionGroups', regionGroups);
  debugLog('templateConfig', templateConfig);

  const rsp: ValidatorResponse<typeof section> = {
    section,
    messages: [],
    info: { ...initialValidatorInfoRegions },
    overviewMessage: '',
  };

  rsp.info.flowType = templateConfig.regionFlowTypeId;
  rsp.info.customRegionGroupCount = regionGroups.length;

  const emptyRegionGroupExists =
    templateConfig.regionFlowTypeId === RegionFlowType.CustomRedistribution &&
    regionGroups.some(rg => rg.regions.length === 0);

  addMessage(emptyRegionGroupExists, rsp.messages, MESSAGES.emptyRegionGroup);
  addMessage(
    rsp.info.flowType === RegionFlowType.CustomRedistribution && regionGroups.length === 0,
    rsp.messages,
    MESSAGES.customGroupButNoGroup,
  );

  const infoMessages: string[] = [];
  switch (rsp.info.flowType) {
    case RegionFlowType.AllEnabled:
      infoMessages.push(`Regions are redistributed between each other.`);
      break;
    case RegionFlowType.WithinRegion:
      infoMessages.push(`Redistribution only within each region individually.`);
      break;
    case RegionFlowType.CustomRedistribution:
      infoMessages.push(`Redistribution based on custom region groups.`);
      if (rsp.info.customRegionGroupCount === 1) {
        infoMessages.push(`There is 1 region group defined.`);
      } else {
        infoMessages.push(`There are ${rsp.info.customRegionGroupCount} region groups defined.`);
      }
      break;
  }

  rsp.overviewMessage = infoMessages.join(' ');

  return rsp;
};
