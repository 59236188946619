import React, { PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { computeRemSize, Row } from '@ydistri/ds';

interface ActionsBarProps extends PropsWithChildren {
  /** Move the actions to the right side of the bar; must be placed within flexbox */
  moveToRight?: boolean;
  /**
   * Use a vertical line as divider between the actions to give them more space
   */
  useDivider?: boolean;
  /** Wrap the actions if they don't fit in one line (defaults to true) */
  wrap?: boolean;
  id?: string;
}

interface ActionsBarWrapperProps {
  $moveToRight?: boolean;
}

const ActionsBarWrapper = styled(Row)<ActionsBarWrapperProps>`
  ${p => p.$moveToRight && 'margin-left: auto;'}
  gap: ${computeRemSize(6)} !important;
`;

/**
 * Container for actions that should be placed in a horizontal bar.
 * <p>Use the ActionBar for buttons or other controls that should be placed next
 * to each other in a standardized way.</p>
 * @param moveToRight Move the actions to the right side of the bar; must be placed within flexbox
 * @param useDivider Use a vertical line as divider between the actions to give them more space
 * @param children The actions to be placed in the bar
 * @param wrap Wrap the actions if they don't fit in one line (defaults to true)
 * @param rest Additional props to be passed to the container
 * @constructor
 */
export const ActionsBar: React.FC<ActionsBarProps> = ({
  moveToRight,
  children,
  wrap = true,
  ...rest
}) => {
  return (
    <ActionsBarWrapper
      $alignItems="center"
      $flexWrap={wrap ? 'wrap' : 'nowrap'}
      data-type="ActionsBar"
      $moveToRight={moveToRight}
      {...rest}
    >
      {children}
    </ActionsBarWrapper>
  );
};
