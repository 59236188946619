import React, { useCallback, useMemo } from 'react';
import { useGetSourceStorePickingIssuesQuery } from '../../../apiCalculationPickingResults';
import LoadingIndicator from '../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { getSourceStorePickingIssuesColumns } from '../pickingIssuesLib';
import { SimpleTable } from '../../../../Statistics/components/SimpleTable';
import { computeRemSize, tableScroll } from '@ydistri/ds';
import { PickingIssueResponse } from '@ydistri/api-sdk';
import EmbeddedTableWrapper from '../../../components/EmbeddedTableWrapper';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../../hooks/useEntityTableColumnsProvider';

interface SourceStorePickingIssuesTableProps {
  calculationId: number;
  sourceStoreId: number;
}

const SourceStorePickingIssuesTable: React.FC<SourceStorePickingIssuesTableProps> = ({
  calculationId,
  sourceStoreId,
}) => {
  const { data, isFetching } = useGetSourceStorePickingIssuesQuery({
    calculationId,
    sourceStoreId,
  });
  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const tableColumns = useMemo(() => {
    const columnsConfig = {
      [EntityColumns.ID]: {
        width: computeRemSize(110),
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(100),
      },
    };

    const storeColumns = storeColumnsProvider<PickingIssueResponse>(
      columnsConfig,
      'targetStore',
      'Target Store',
    );

    const productColumnsConfig: EntityColumnsConfig<PickingIssueResponse> = {
      [EntityColumns.ID]: {
        width: computeRemSize(110),
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(100),
      },
    };
    const productColumns = productColumnsProvider<PickingIssueResponse>(productColumnsConfig);

    return getSourceStorePickingIssuesColumns(storeColumns, productColumns);
  }, [productColumnsProvider, storeColumnsProvider]);

  const rowKeyProvider = useCallback(
    (record: PickingIssueResponse) => `${record.sourceSkuId}-${record.targetSkuId}`,
    [],
  );

  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (data) {
    return (
      <EmbeddedTableWrapper title="Issues">
        <SimpleTable<PickingIssueResponse>
          id="store-picking-issues"
          scroll={tableScroll}
          dataSource={data}
          columns={tableColumns}
          loading={isFetching}
          showHeader={true}
          rowKey={rowKeyProvider}
          showBorder={true}
        />
      </EmbeddedTableWrapper>
    );
  }
};

export default SourceStorePickingIssuesTable;
