import React, { useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCategoryTreeQuery } from './apiCategoryTree';
import TreeLeaf from './TreeLeaf';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';

interface TreeCategoryProps {
  categoryId: number | undefined;
  displayExceptions?: boolean;
  onCategoryChange?: () => void;
  searchParamModifier?: (value: string) => string;
}

const TreeCategory: React.FC<TreeCategoryProps> = ({
  categoryId,
  displayExceptions = true,
  onCategoryChange,
  searchParamModifier,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories, isFetching } = useGetCategoryTreeQuery(templateOrCalculation);

  const category = useMemo(() => {
    return categories?.[categoryId ?? 0];
  }, [categories, categoryId]);

  const isOpened = useSelector(
    (state: ReduxState) => !!state.categoryTreeReducer.openedCategories[category?.slug ?? '0'],
  );

  const selectedCategory = useSelector(
    (state: ReduxState) =>
      state.categoryTreeReducer.selectedCategory[category?.slug ?? '0'] ?? false,
  );

  const title = useMemo(
    () => (category?.id === 1 ? 'Company' : (category?.title ?? '- unknown -')),
    [category?.id, category?.title],
  );

  if (!category) return null;

  if (isFetching) {
    return <Spin spinning={true}></Spin>;
  }

  return (
    <>
      <TreeLeaf
        slug={category.slug}
        title={title}
        selectionType={selectedCategory}
        opened={isOpened}
        hasException={displayExceptions && category.exceptions.length > 0}
        subcategoryHasException={displayExceptions && category.childExceptionCount > 0}
        level={category.level}
        lastLevel={category.children.length === 0}
        onCategoryChange={onCategoryChange}
        searchParamModifier={searchParamModifier}
      />
      {isOpened &&
        category.children.map(c => (
          <TreeCategory
            key={c}
            displayExceptions={displayExceptions}
            categoryId={c}
            onCategoryChange={onCategoryChange}
            searchParamModifier={searchParamModifier}
          />
        ))}
    </>
  );
};

export default TreeCategory;
