import InventoryTypeDefinitionPart from './InventoryTypeDefinitionPart';
import { ConfigurationDataType, ConfigurationFieldType, SkuType } from '@ydistri/api-sdk';
import React from 'react';
import ConfigurationField from '../../../../../components/global/ConfigurationField/ConfigurationField';
import { Row, Text } from '@ydistri/ds';

interface SlowMoversTypeDefinitionProps {
  disabled?: boolean;
}

const SlowMoversTypeDefinition: React.FC<SlowMoversTypeDefinitionProps> = ({ disabled }) => {
  return (
    <InventoryTypeDefinitionPart type={SkuType.SlowMover} title="Slow movers">
      <Row $flexWrap="wrap" $alignItems="center" $gap="0.5rem">
        <ConfigurationField
          disabled={disabled}
          skuTypeColumn={null}
          fieldType={ConfigurationFieldType.CatSlowMoverSales}
          dataType={ConfigurationDataType.InputNumber}
          padding="0"
          inputWidth="5rem"
          noAdditionalSpace={true}
        />
        <Text>sales within</Text>
        <ConfigurationField
          disabled={disabled}
          skuTypeColumn={null}
          fieldType={ConfigurationFieldType.CatSlowMoverMonths}
          dataType={ConfigurationDataType.SelectBoxMonthIdentifySkuType}
          inputWidth="6rem"
          padding="0"
          noAdditionalSpace={true}
        />
        <Text>months</Text>
      </Row>
    </InventoryTypeDefinitionPart>
  );
};

export default SlowMoversTypeDefinition;
