import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SignalSendCurrentFrontendStatus = 'Upgrading' | 'Online' | 'Offline';

export interface AppState {
  status: SignalSendCurrentFrontendStatus | undefined;
  version: string;
  signalrConnected: boolean | undefined;
}

const initialState: AppState = {
  status: undefined,
  version: '1.0.0',
  signalrConnected: undefined,
};

export const appSlice = createSlice({
  name: 'appReducer',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<SignalSendCurrentFrontendStatus>) => {
      state.status = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    setSignalrConnected: (state, action: PayloadAction<boolean>) => {
      state.signalrConnected = action.payload;
    },
    resetState: state => state,
  },
});

export const { setStatus, setVersion, setSignalrConnected, resetState } = appSlice.actions;

export const appReducer = appSlice.reducer;
