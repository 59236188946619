import { MdClear } from 'react-icons/md';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { Tooltip } from 'antd';

const ActionWrapper = styled.div`
  cursor: pointer;
`;

interface CloseSelectedProductActionProps {
  onClick?: () => void;
}

const CloseSelectedProductAction: React.FC<CloseSelectedProductActionProps> = ({ onClick }) => {
  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Tooltip title="Deselect product">
      <ActionWrapper data-testid="close-selected-product-action" onClick={clickHandler}>
        <MdClear size={20} />
      </ActionWrapper>
    </Tooltip>
  );
};

export default CloseSelectedProductAction;
