import React from 'react';
import { ScopeConfiguration, ScopeConfigurationDefinition } from './scopeLib';

interface ScopeConfigurationDisplayProps {
  definition: ScopeConfigurationDefinition;
  scopeConfiguration: ScopeConfiguration;
}

const ScopeConfigurationDisplay: React.FC<ScopeConfigurationDisplayProps> = ({
  definition,
  scopeConfiguration,
}) => {
  return definition.render(scopeConfiguration);
};

export default ScopeConfigurationDisplay;
