import React, { useCallback } from 'react';
import useImportRedistributionItems from '../../../useImportRedistributionItems';
import { ActionsBar, Button } from '@ydistri/ds';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../../store';
import useResetImport from '../../common/useResetImport';

const RedistributionImportEditorActions: React.FC = () => {
  const resetImport = useResetImport();

  const inputRawContent = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.inputContent,
  );

  const { callback: importItems, isPending } = useImportRedistributionItems();

  const processImport = useCallback(() => {
    importItems();
  }, [importItems]);

  const cancelImport = useCallback(() => {
    resetImport();
  }, [resetImport]);

  return (
    <ActionsBar data-testid="RedistributionImportActions">
      <Button
        disabled={inputRawContent.length === 0 || isPending}
        data-testid="importRedistributionButton"
        $colorVariant="primary"
        onClick={processImport}
      >
        Import
      </Button>
      <Button
        disabled={inputRawContent.length === 0 || isPending}
        data-testid="cancelImportRedistributionButton"
        $colorVariant="ghost"
        onClick={cancelImport}
      >
        Cancel
      </Button>
    </ActionsBar>
  );
};

export default RedistributionImportEditorActions;
