import { useGetApplicationDataQuery } from '../apis/apiApplication';
import { useMemo } from 'react';
import { ApplicationDataType, lowercaseFirstLetter } from '@ydistri/utils';

type ApplicationDataAvailability = Record<ApplicationDataType, boolean>;

export function useApplicationData(): ApplicationDataAvailability {
  const { data: applicationData } = useGetApplicationDataQuery(undefined);

  return useMemo(() => {
    const base: Record<ApplicationDataType, boolean> = {
      [ApplicationDataType.BRANDS]: false,
      [ApplicationDataType.DEPARTMENTS]: false,
      [ApplicationDataType.FORCE_QUANTITIES]: false,
      [ApplicationDataType.MINIMAL_LAYERS]: false,
      [ApplicationDataType.OPEN_PURCHASE_ORDERS]: false,
      [ApplicationDataType.SKU_CLASSES]: false,
    };

    applicationData?.reduce((acc, curr) => {
      const field = lowercaseFirstLetter(curr.applicationDataType);
      if (field in acc) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        acc[field as ApplicationDataType] = curr.isAvailable;
      }
      return acc;
    }, base);

    return base;
  }, [applicationData]);
}
