import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import { capitalizeFirstLetter, formatNumber, minimalToMinimum } from '@ydistri/utils';
import StatisticsSourceStoreIcon from '../../../../assets/statistics-source-store.svg';
import StatisticsTargetStoreIcon from '../../../../assets/statistics-target-store.svg';
import { SimpleTableDivider, SimpleTable } from './components/SimpleTable';
import StatisticsSection from './components/StatisticsSection';
import { sortByStatisticsType, StatisticsModalType } from './statisticsLib';
import { RowWrap } from '@ydistri/ds';
import TitleWithModalButton from './components/TitleWithModalButton';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { useGetStatsStoreSummariesQuery } from './apiCalculationStatistics';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import { useApplicationData } from '../../../hooks/useApplicationData';

enum StoreType {
  SOURCE = 'source',
  TARGET = 'target',
}

type StoreStatistics =
  | {
      type: StoreType.SOURCE;
      title: string;
      key: string;
      value: number;
      quantity: number;
      productCount: number;
      pickingPositionCount: number;
      targetStoreCount: number;
    }
  | {
      type: StoreType.TARGET;
      title: string;
      key: string;
      value: number;
      quantity: number;
      productCount: number;
      pickingPositionCount: number;
      sourceStoreCount: number;
    }
  | SimpleTableDivider;

const getStoresStatisticsColumns = (
  currencyId: string,
  storeType: StoreType,
  displayDepartmentText: string,
): ColumnsType<StoreStatistics> => {
  const cols: ColumnsType<StoreStatistics> = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'right',
      render: (value: number) => `${formatNumber(value)} ${currencyId}`,
      sorter: (a, b) => ('value' in a && 'value' in b ? a.value - b.value : 0),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      render: (quantity: number) => `${formatNumber(quantity)} MU`,
      sorter: (a, b) => ('quantity' in a && 'quantity' in b ? a.quantity - b.quantity : 0),
    },
    {
      title: 'Picking positions',
      dataIndex: 'pickingPositionCount',
      key: 'pickingPositionCount',
      align: 'right',
      sorter: (a, b) =>
        'pickingPositionCount' in a && 'pickingPositionCount' in b
          ? a.pickingPositionCount - b.pickingPositionCount
          : 0,
    },
  ];

  switch (storeType) {
    case StoreType.SOURCE:
      cols.push({
        title: `Target store${displayDepartmentText}s`,
        dataIndex: 'targetStoreCount',
        key: 'targetStoreCount',
        align: 'right',
        sorter: (a, b) =>
          'targetStoreCount' in a && 'targetStoreCount' in b
            ? a.targetStoreCount - b.targetStoreCount
            : 0,
      });
      break;
    case StoreType.TARGET:
      cols.push({
        title: `Source store${displayDepartmentText}s`,
        dataIndex: 'sourceStoreCount',
        key: 'sourceStoreCount',
        align: 'right',
        sorter: (a, b) =>
          'sourceStoreCount' in a && 'sourceStoreCount' in b
            ? a.sourceStoreCount - b.sourceStoreCount
            : 0,
      });
      break;
  }

  return cols;
};

const SectionStores: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency() ?? '...';
  const { departments: hasDepartments } = useApplicationData();
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);
  const { data: storeSummaries, isFetching } = useGetStatsStoreSummariesQuery({
    calculationId: templateOrCalculation.id,
    departmentId,
    clusterType,
  });

  const displayDepartmentText =
    hasDepartments && clusterType === CalculationStatisticClusterType.StoreDepartment
      ? '-department'
      : '';

  const sourceStoreStatisticsColumns: ColumnsType<StoreStatistics> = useMemo(
    () => getStoresStatisticsColumns(currencyId, StoreType.SOURCE, displayDepartmentText),
    [currencyId, displayDepartmentText],
  );
  const targetStoreStatisticsColumns: ColumnsType<StoreStatistics> = useMemo(
    () => getStoresStatisticsColumns(currencyId, StoreType.TARGET, displayDepartmentText),
    [currencyId, displayDepartmentText],
  );

  const sourceStoresData: StoreStatistics[] = useMemo(() => {
    return [...(storeSummaries?.sourceSummaries ?? [])].sort(sortByStatisticsType).map(item => ({
      type: StoreType.SOURCE,
      key: item.type,
      title: capitalizeFirstLetter(minimalToMinimum(item.type)),
      value: item.value,
      quantity: item.quantity,
      productCount: item.productCount,
      pickingPositionCount: item.pickingPositionCount,
      targetStoreCount: item.targetStoreCount,
    }));
  }, [storeSummaries]);

  const targetStoresData: StoreStatistics[] = useMemo(() => {
    return [...(storeSummaries?.targetSummaries ?? [])].sort(sortByStatisticsType).map(item => ({
      type: StoreType.TARGET,
      key: item.type,
      title: capitalizeFirstLetter(minimalToMinimum(item.type)),
      value: item.value,
      quantity: item.quantity,
      productCount: item.productCount,
      pickingPositionCount: item.pickingPositionCount,
      sourceStoreCount: item.sourceStoreCount,
    }));
  }, [storeSummaries]);

  return (
    <RowWrap>
      <StatisticsSection icon={StatisticsSourceStoreIcon}>
        <TitleWithModalButton
          title={`Source store${displayDepartmentText}s`}
          modalType={StatisticsModalType.SOURCE_STORE}
        />
        <SimpleTable<StoreStatistics>
          id="store-source-statistics"
          rowKey="key"
          dataSource={sourceStoresData}
          columns={sourceStoreStatisticsColumns}
          loading={isFetching}
          showHeader={true}
        />
      </StatisticsSection>
      <StatisticsSection icon={StatisticsTargetStoreIcon}>
        <TitleWithModalButton
          title={`Target store${displayDepartmentText}s`}
          modalType={StatisticsModalType.TARGET_STORE}
        />
        <SimpleTable<StoreStatistics>
          id="store-target-statistics"
          rowKey="key"
          dataSource={targetStoresData}
          columns={targetStoreStatisticsColumns}
          loading={isFetching}
          showHeader={true}
        />
      </StatisticsSection>
    </RowWrap>
  );
};

export default SectionStores;
