import { ConfigurationSectionType } from '@ydistri/api-sdk';

export const isSectionTypeTarget = (st: ConfigurationSectionType): boolean =>
  [ConfigurationSectionType.PotentialTarget].includes(st);

export const isSectionTypeSource = (st: ConfigurationSectionType): boolean =>
  [
    ConfigurationSectionType.PotentialSource,
    ConfigurationSectionType.PotentialSourceStandardSubsection,
  ].includes(st);
