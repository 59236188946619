import React from 'react';
import { useGetSkuListQuery } from '../../../screens/Detail/apiDetail';
import { Spinner } from '@ydistri/ds';
import { useDispatch } from 'react-redux';
import { addToast } from '@ydistri/utils';
import SKUTableComponent from './SKUTableComponent';
import { Units } from '../../../lib/charts/saleChartsTypes';
import { useCurrency } from '../../../hooks/useCurrency';
import { NO_VALUE } from '../../../lib/utils/utilsTypes';

interface SKUTableComponentProps {
  productId?: number;
  storeId?: number;
  units: Units;
  loadingSalesData?: boolean;
}

const SKUTableController: React.FC<SKUTableComponentProps> = ({
  productId = NO_VALUE,
  storeId = NO_VALUE,
  units,
}) => {
  const dispatch = useDispatch();
  const { data, isFetching, isError } = useGetSkuListQuery({ productId, storeId });
  const currency = useCurrency();

  if (isError) {
    dispatch(addToast({ message: 'Unable to load SKUs', isError: true }));
    return;
  }

  if (isFetching) {
    return <Spinner />;
  }

  if (data) {
    return <SKUTableComponent data={data.data} units={units} currency={currency} />;
  }
};

export default SKUTableController;
