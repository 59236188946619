import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import { COLORS, Column, computeRemSize, Text, Title } from '@ydistri/ds';
import { Popover } from 'antd';
import { TbChartCircles } from 'react-icons/tb';
import { CalculationWithOverviewResponse } from '@ydistri/api-sdk';
import CalculationsList from '../../common/CalculationsList';
import { ICON_SIZE_BIG, ICON_SIZE_COMPACT } from './calculationRowLib';

const OverlapIndicatorWrapper = styled.div`
  margin-left: auto;
  color: ${() => COLORS.PRIMARY};
`;

interface OverlapIndicatorProps {
  overlappingCalculations: CalculationWithOverviewResponse[];
  compact?: boolean;
}

const OverlapIndicator: React.FC<OverlapIndicatorProps> = ({
  overlappingCalculations,
  compact,
}) => {
  const popoverContent = useMemo(() => {
    if (overlappingCalculations.length === 0) return null;

    return (
      <Column $maxWidth="35rem">
        <Title>Overlapping calculations</Title>
        <Text>
          Available supply of SKUs in this calculation was affected by these calculations:
        </Text>
        <br />
        <CalculationsList calculations={overlappingCalculations.toReversed()} categorized={false} />
      </Column>
    );
  }, [overlappingCalculations]);

  if (overlappingCalculations.length === 0) return null;

  return (
    <Popover content={popoverContent} placement="left">
      <OverlapIndicatorWrapper data-type="indicator-calculation-private">
        <TbChartCircles size={computeRemSize(compact ? ICON_SIZE_COMPACT : ICON_SIZE_BIG)} />
      </OverlapIndicatorWrapper>
    </Popover>
  );
};

export default OverlapIndicator;
