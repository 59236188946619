import { Select } from 'antd';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { ReactSelectType, SelectionType } from './administrationItemsLib';

interface YSelectProps<T extends SelectionType> {
  id?: string;
  disabled?: boolean;
  value?: T;
  onChange: (selectedValue: T) => void;
  options: ReactSelectType<T>[];
  width?: string;
}

const StyledSelect = styled(Select)<YSelectProps<SelectionType>>`
  //fill available space by default, if this was missing it would only take a few pixels.
  width: ${({ width }) => width ?? '100%'};
`;

const AdministrationItemSelect: React.FC<YSelectProps<SelectionType>> = ({
  options,
  disabled,
  value,
  onChange,
  id,
  ...rest
}) => {
  const onSelectChange = useCallback(
    (value: unknown) => {
      const selectedValue = options.find(option => option.value === value)?.value;
      if (selectedValue) {
        onChange(selectedValue);
      }
    },
    [onChange, options],
  );

  return (
    <StyledSelect
      options={options}
      disabled={disabled}
      value={value}
      onChange={onSelectChange}
      id={id}
      {...rest}
    />
  );
};

export default AdministrationItemSelect;
