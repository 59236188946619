import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../../store';
import LoadingDataOverlay from '../../../../../../../../components/global/LoadingDataOverlay/LoadingDataOverlay';
import RedistributionImportItemsTable from './RedistributionImportItemsTable';
import RedistributionImportSummary from './RedistributionImportSummary';
import { Column } from '@ydistri/ds';
import RedistributionImportItemsActions from './RedistributionImportItemsActions';

const RedistributionImportStepValidation: React.FC = () => {
  const currentStepRunning = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.currentStepRunning,
  );

  if (currentStepRunning) {
    return <LoadingDataOverlay active={true} title="Validating"></LoadingDataOverlay>;
  }

  return (
    <Column $gap="8px" $flexGrow={1}>
      <RedistributionImportSummary />
      <RedistributionImportItemsTable />
      <RedistributionImportItemsActions />
    </Column>
  );
};

export default RedistributionImportStepValidation;
