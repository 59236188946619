import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AvailableModals, closeModal } from '../modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { Button, Modal, Table } from 'antd';
import {
  useDeleteCalculationMutation,
  useGetCalculationsQuery,
  usePatchCalculationMutation,
} from '../../Calculations/apiCalculations';
import {
  setCalculationsActionStatuses,
  updateCalculationActionStatus,
} from './calculationsActionSlice';
import CalculationActionStatusComponent, {
  CalculationActionStatuses,
} from './CalculationActionStatusComponent';
import { COLORS, computeRemSize, ScrollTableWrapper, tableScroll, YColumnsType } from '@ydistri/ds';
import {
  CalculationMode,
  CalculationStatus,
  CalculationType,
  CalculationWithRelatedEntitiesResponse,
} from '@ydistri/api-sdk';
import CalculationTableText from '../../Calculations/components/row/CalculationTableText';
import CalculationRowTitle from '../../Calculations/components/row/CalculationRowTitle';
import CalculationStatusComponent from '../../Calculations/components/row/CalculationStatusComponent';
import { getRedistributionValues } from '../../Calculations/calculationsLib';
import CalculationFinalRedistribution from '../../Calculations/components/row/CalculationFinalRedistribution';
import CalculationOwner from '../../Calculations/components/row/CalculationOwner';
import { format } from 'date-fns';
import { useCurrency } from '../../../hooks/useCurrency';
import { styled } from 'styled-components';
import { ErrorType } from '../../../apis/api';
import { setSelectedRowKeys } from '../../Calculations/calculationsSlice';
import { CalculationActionType } from '../../Calculations/calculationsTypes';
import {
  getLabelBasedOnCalculationActionStatus,
  getTitleBasedOnCalculationActionType,
} from './calculationsActionLib';

const ErrorList = styled.ul`
  color: ${COLORS.DANGER};
`;

const CalculationsTableStyled = styled(Table<CalculationWithRelatedEntitiesResponse>)`
  .ant-table-wrapper {
    max-height: 70vh;
  }

  .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0;
  }

  .ant-table-cell.final-redistribution-cell {
    position: relative;
    padding: 0;
  }

  .calculationRow {
    &.privateRow {
      background-color: #fffcf6;

      &:hover > td {
        background: #ffefcf !important;
      }
    }

    &.productionRow {
      background-color: #fafdfa;

      &:hover > td {
        background: #dff1df !important;
      }
    }

    td.final-redistribution-cell {
      padding: 0;
    }
  }

  .ant-table-middle .ant-table-tbody > tr:not(:first-child) > td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

type ModalStatus = 'ready' | 'running' | 'done';

const modalId = AvailableModals.MULTI_CALCULCATION_ACTIONS;
const calculationsTableId = 'calculationActionModalTable';

const CalculationsActionModal: React.FC = () => {
  const dispatch = useDispatch();
  const currency = useCurrency();

  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const calculationIds = useSelector(
    (state: ReduxState) => state.modal.modalData[modalId]?.calculationIds,
  );
  const calculationTableParams = useSelector(
    (state: ReduxState) => state.modal.modalData[modalId]?.calculationTableParams,
  );
  const calculationActionType = useSelector(
    (state: ReduxState) =>
      state.modal.modalData[AvailableModals.MULTI_CALCULCATION_ACTIONS]?.calculationActionType,
  );
  const [deleteCalculation] = useDeleteCalculationMutation();
  const [patchCalculation] = usePatchCalculationMutation();

  const { data } = useGetCalculationsQuery(calculationTableParams ?? {});

  const selectedCalculationData = useMemo(
    () => (data?.data ?? []).filter(c => calculationIds?.includes(c.id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const statuses: CalculationActionStatuses = {};
    calculationIds?.forEach(cid => (statuses[cid] = 'ready'));
    dispatch(setCalculationsActionStatuses(statuses));
  }, [calculationIds, dispatch]);

  const [modalStatus, setModalStatus] = useState<ModalStatus>('ready');
  const [errors, setErrors] = useState<string[]>([]);
  const [errorCalcIds, setErrorCalcIds] = useState<number[]>([]);

  const handleClose = useCallback(() => {
    if (modalStatus === 'done') {
      dispatch(setSelectedRowKeys({ tableId: calculationsTableId, data: errorCalcIds }));
    }
    dispatch(closeModal());
  }, [errorCalcIds, modalStatus, dispatch]);

  const actionHandler = useCallback(() => {
    const run = async () => {
      if (modalStatus === 'ready') {
        setModalStatus('running');
        const errorCalcs: number[] = [];
        if (calculationIds) {
          const sorted = calculationIds.toSorted((a, b) => b - a);
          for (const cid of sorted) {
            dispatch(
              updateCalculationActionStatus({
                calculationId: cid,
                status: 'progress',
              }),
            );

            try {
              if (calculationActionType === CalculationActionType.DELETE) {
                await deleteCalculation(cid).unwrap();
              } else if (
                calculationActionType === CalculationActionType.PRIVATE ||
                calculationActionType === CalculationActionType.UNSET_PRIVATE
              ) {
                await patchCalculation({
                  calculationId: cid,
                  data: {
                    calculationType:
                      calculationActionType === CalculationActionType.PRIVATE
                        ? CalculationType.Private
                        : CalculationType.Simulation,
                  },
                }).unwrap();
              }
              dispatch(
                updateCalculationActionStatus({
                  calculationId: cid,
                  status: 'success',
                }),
              );
            } catch (e) {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              const err = e as ErrorType;
              if (err.response.data.Messages) {
                setErrors(p => [...p, err.response.data.Messages[0]]);
              }
              errorCalcs.push(cid);
              dispatch(
                updateCalculationActionStatus({
                  calculationId: cid,
                  status: 'error',
                }),
              );
            }
          }
        }
        setErrorCalcIds(errorCalcs);
        setModalStatus('done');
      } else if (modalStatus === 'done') {
        handleClose();
      }
    };

    void run();
  }, [
    calculationActionType,
    calculationIds,
    deleteCalculation,
    dispatch,
    handleClose,
    modalStatus,
    patchCalculation,
  ]);

  const columns: YColumnsType<CalculationWithRelatedEntitiesResponse>[] = useMemo(() => {
    return [
      {
        title: 'ID',
        key: 'id',
        width: computeRemSize(50),
        render: (calculation: CalculationWithRelatedEntitiesResponse) => (
          <CalculationTableText>{calculation.id}</CalculationTableText>
        ),
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: ['title'],
        render: (title: string, calculation: CalculationWithRelatedEntitiesResponse) => {
          return <CalculationRowTitle calculation={calculation} showActions={false} />;
        },
      },
      {
        title: 'Final Redistribution',
        key: 'finalRedistribution',
        className: 'final-redistribution-cell',
        width: computeRemSize(360),
        render: (calculation: CalculationWithRelatedEntitiesResponse) => {
          if (calculation.status === CalculationStatus.Completed) {
            const redistributionValues = getRedistributionValues(
              calculation.rootCategoryRedistributions,
              calculation.type,
            );

            return (
              <CalculationFinalRedistribution
                production={redistributionValues.isProduction}
                totalValue={redistributionValues.totalValue}
                deadStockValue={redistributionValues.deadStockValue}
                slowMoverValue={redistributionValues.slowMoverValue}
                fastMoverValue={redistributionValues.fastMoverValue}
                calculationId={calculation.id}
                currency={currency}
                manualCalculation={calculation.mode === CalculationMode.Manual}
              />
            );
          } else {
            return (
              <CalculationStatusComponent status={calculation.status} mode={calculation.mode} />
            );
          }
        },
      },
      {
        title: 'Owner',
        key: 'owner',
        render: (calculation: CalculationWithRelatedEntitiesResponse) => {
          if (calculation.owner) {
            return <CalculationOwner fullName={calculation.owner.fullName} />;
          }
        },
        width: computeRemSize(200),
        shouldCellUpdate: () => false,
      },
      {
        title: 'Created',
        key: 'createdAt',
        width: '11rem',
        render: (calculation: CalculationWithRelatedEntitiesResponse) => (
          <CalculationTableText>
            {format(new Date(calculation.created), 'd. M. yyyy HH:mm')}
          </CalculationTableText>
        ),
        shouldCellUpdate: () => false,
      },
      {
        title: 'Status',
        key: 'status',
        width: '11rem',
        render: (calculation: CalculationWithRelatedEntitiesResponse) => (
          <CalculationActionStatusComponent calculationId={calculation.id} />
        ),
        shouldCellUpdate: () => false,
      },
    ];
  }, [currency]);

  const getRowClassName = useCallback((calculation: CalculationWithRelatedEntitiesResponse) => {
    if (calculation.type === CalculationType.Production) {
      return `calculationRow productionRow`;
    } else if (calculation.type === CalculationType.Private) {
      return `calculationRow privateRow`;
    } else if (calculation.status !== CalculationStatus.Completed) {
      return 'calculationRowIncomplete';
    }
    return '';
  }, []);

  const footer = useMemo(() => {
    let okText = 'Continue';
    if (modalStatus === 'running') {
      okText = getLabelBasedOnCalculationActionStatus('progress', calculationActionType);
    } else if (modalStatus === 'done') {
      okText = 'Close';
    }

    return (
      <>
        <Button onClick={handleClose}>Close</Button>
        {modalStatus !== 'done' && (
          <Button disabled={modalStatus === 'running'} onClick={actionHandler}>
            {okText}
          </Button>
        )}
      </>
    );
  }, [actionHandler, calculationActionType, handleClose, modalStatus]);

  const tableId = 'delete-calculations-table';

  return (
    <Modal
      closeIcon={false}
      title={getTitleBasedOnCalculationActionType(calculationActionType)}
      centered
      open={open}
      width="80%"
      footer={footer}
    >
      <ScrollTableWrapper $id={tableId} $maxHeight="60vh">
        <CalculationsTableStyled
          id={tableId}
          columns={columns}
          dataSource={selectedCalculationData}
          size="small"
          rowClassName={getRowClassName}
          pagination={false}
          scroll={tableScroll}
        />
      </ScrollTableWrapper>
      {errors.length > 0 && (
        <ErrorList>
          {errors.map(e => (
            <li key={e}>{e}</li>
          ))}
        </ErrorList>
      )}
    </Modal>
  );
};

export default CalculationsActionModal;
