import { Scope } from './scopeLib';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetConfigurationRulesQuery } from './apiScopes';
import { useMemo } from 'react';

export const useScope = (scopeId: number): Scope | undefined => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { scopes: scope } = useGetConfigurationRulesQuery(templateOrCalculation, {
    selectFromResult: ({ data }) => {
      return { scopes: data?.find(s => s.id === scopeId) };
    },
  });
  return useMemo(() => scope, [scope]);
};
