import React, { CSSProperties, useMemo } from 'react';
import { ExcessiveStockPotentialInfo, sumLockedByOptions } from '../../categoryInsightsLib';
import { Column, Title, BarLine, BarLineProps, BarLineSizes, Row } from '@ydistri/ds';
import { shortenNumber } from '@ydistri/utils';
import { CategorySummaryType } from '@ydistri/api-sdk';
import { ExcessiveStockBarSection, getBarDataFromSection } from './excessiveStockBarLib';
import { useCurrency } from '../../../../../hooks/useCurrency';

interface ExcessiveStockBarProps {
  data: ExcessiveStockPotentialInfo;
  title: string;
  baseColor?: CSSProperties['backgroundColor'];
  highlightedColor?: CSSProperties['backgroundColor'];
}

const sections: ExcessiveStockBarSection[] = [
  {
    title: 'Locked by minimum layers',
    fields: [CategorySummaryType.ExcessiveBlockedByMinLayersValue],
  },
  {
    title: 'Locked by new items',
    fields: [CategorySummaryType.ExcessiveBlockedByFirstPurchaseDateValue],
  },
  {
    title: 'Locked by last purchase protection',
    fields: [CategorySummaryType.ExcessiveBlockedByLastPurchaseDateValue],
  },
  {
    title: 'Locked because of low value of items',
    fields: [CategorySummaryType.ExcessiveBlockedByMinValue],
  },
  {
    title: 'Locked because of other reasons',
    fields: [
      CategorySummaryType.ExcessiveBlockedByPromoValue,
      CategorySummaryType.ExcessiveBlockedByMinimalQuantityValue,
      CategorySummaryType.ExcessiveBlockedByMinimalSetValue,
    ],
  },
  {
    title: 'Potential for redeployment',
    fields: [CategorySummaryType.ExcessivePotentialTotalValue],
    highlighted: true,
  },
];

const ExcessiveStockBar: React.FC<ExcessiveStockBarProps> = ({
  data,
  title,
  baseColor,
  highlightedColor = 'transparent',
}) => {
  const currency = useCurrency();
  const sum = useMemo(() => sumLockedByOptions(data), [data]);

  const barData: BarLineProps['data'] = useMemo(
    () => sections.map(s => getBarDataFromSection(s, data, highlightedColor)),
    [data, highlightedColor],
  );

  return (
    <Column $gap="0.5rem">
      <Row $justifyContent="space-between">
        <Title>
          {title} ({shortenNumber(sum)} {currency})
        </Title>
        <Title>
          Potential ({shortenNumber(data.ExcessivePotentialTotalValue)} {currency})
        </Title>
      </Row>
      <BarLine
        size={BarLineSizes.MEDIUM}
        data={barData}
        dividers={true}
        backgroundColor={baseColor}
      />
    </Column>
  );
};

export default ExcessiveStockBar;
