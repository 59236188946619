import React, { useCallback, useMemo, useState } from 'react';
import {
  Column,
  GenericConfirmationModal,
  IconButton_IconSizes,
  RowCentered,
  SectionHeaderRowSpaceBetween,
  SectionHeaderRowSpaceBetweenNoBorder,
  Title,
} from '@ydistri/ds';
import { Dropdown, DropdownProps, Popover, Space } from 'antd';
import { icons, IconType } from '../../components/icons/icons';
import { COLORS } from '../../styles/style';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from './apiCalculationDetail';
import CalculationPotentialOverview from '../../components/global/HeaderPotentialOverview/CalculationPotentialOverview';
import PrivateCalculationsIcon from '../Calculations/actions/PrivateCalculationsIcon';
import LoadIntoTemplateAction from '../Calculations/actions/LoadIntoTemplateAction';
import LoadIntoTemplateModal from '../Calculations/modals/LoadIntoTemplateModal';
import EditCalculationModal from './EditCalculationModal';
import {
  PatchCalculationPayload,
  useDeleteCalculationMutation,
  usePatchCalculationMutation,
} from '../Calculations/apiCalculations';
import { CalculationMode, CalculationType } from '@ydistri/api-sdk';
import { addToast } from '@ydistri/utils';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MenuSubpages from '../../components/menu/MenuSubpages';
import { RoutingPage } from '../../routes/routerSlice';
import { CalculationDetailSubpages } from '../Calculations/calculationsLib';
import useSubpage from '../../hooks/useSubpage';
import MoreIconButton from '../../components/buttons/MoreIconButton';
import { SubmenuOption } from '../Configuration/ConfigurationHeader';
import ProductionCalculationIcon from '../Calculations/actions/ProductionCalculationIcon';
import { useUser } from '../../hooks/useUser';
import { styled } from 'styled-components';
import CalculationTitleItem from './components/CalculationTitleItem';
import { useApplicationData } from '../../hooks/useApplicationData';
import { AvailableModals, openModal } from '../Modals/modalSlice';
import ConfigurationExceptions from '../Configuration/ConfigurationCategories/ConfigurationExceptions';
import { useGetTargetListsForConfigurationQuery } from '../Configuration/apiTargetListConfiguration';
import PapSyncButton from './PapSyncButton';
import RecomputeStatisticsButton from '../Calculations/RecomputeStatisticsButton';
import { MdCopyAll } from 'react-icons/md';
import CreateCalculationWithDefaultsModal from '../Calculations/components/CreateCalculationWithDefaultsModal/CreateCalculationWithDefaultsModal';
import { ItemType } from 'antd/es/menu/interface';

enum CalculationAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  CREATE_MERGED = 'CREATE_MERGED',
}

const CalculationTitle = styled(Title)`
  max-width: 60rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CalculationHeader: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subpage = useSubpage();
  const user = useUser();
  const { projectShortName } = useParams();
  const { departments: hasDepartments } = useApplicationData();

  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);
  const { data: targetListConfig } = useGetTargetListsForConfigurationQuery(templateOrCalculation);
  const [openedActionModal, setOpenedActionModal] = useState<CalculationAction>();
  const [showLoadIntoTemplateModal, setShowLoadIntoTemplateModal] = React.useState(false);
  const [editCalculation, { isLoading }] = usePatchCalculationMutation();
  const [deleteCalculation] = useDeleteCalculationMutation();

  const isPrivate = useMemo(
    () => calculationData?.type === CalculationType.Private,
    [calculationData?.type],
  );

  const isProduction = useMemo(
    () => calculationData?.type === CalculationType.Production,
    [calculationData?.type],
  );

  const manualCalculation = calculationData?.mode === CalculationMode.Manual;
  const mergedCalculation = calculationData?.mode === CalculationMode.Merged;
  const submergedCalculation = calculationData?.type === CalculationType.Submerged;

  const hasTargetList = useMemo(() => (targetListConfig ?? []).length > 0, [targetListConfig]);

  const showLoadIntoTemplateModalHandler = useCallback(
    () => setShowLoadIntoTemplateModal(true),
    [],
  );
  const closeLoadIntoTemplateModal = useCallback(() => setShowLoadIntoTemplateModal(false), []);
  const onConfirmed = useCallback(() => {}, []);

  const closeOpenedActionModal = useCallback(() => {
    setOpenedActionModal(undefined);
  }, []);

  const deleteCalculationHandler = useCallback(() => {
    if (templateOrCalculation.type === 'Calculation' && projectShortName) {
      navigate(`/${projectShortName}/calculations`);
      deleteCalculation(templateOrCalculation.id)
        .unwrap()
        .then(() => {
          dispatch(addToast({ message: 'Calculation deleted' }));
        })
        .catch(() => {
          dispatch(addToast({ message: 'Calculation was not deleted', isError: true }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectShortName,
    templateOrCalculation.type,
    templateOrCalculation.id,
    deleteCalculation,
    dispatch,
  ]);

  const calculationOptionDropdownItems: DropdownProps['menu'] = useMemo(() => {
    const menuItems: ItemType[] = [
      {
        label: 'Edit calculation',
        key: '1',
        icon: icons[IconType.EDIT_OUTLINED],
        onClick: () => {
          setOpenedActionModal(CalculationAction.EDIT);
        },
        style: {
          backgroundColor: COLORS.PRIMARY_LIGHT,
        },
      },
    ];

    if (calculationData?.mode === CalculationMode.Merged) {
      menuItems.push({
        label: 'Create using this merged calculation...',
        key: 'createNewMergedBasedOnThis',
        icon: <MdCopyAll size={IconButton_IconSizes.xSmall} />,
        onClick: () => {
          setOpenedActionModal(CalculationAction.CREATE_MERGED);
        },
      });
    }

    menuItems.push(
      {
        type: 'divider',
      },
      {
        label: 'Delete',
        key: '2',
        icon: icons[IconType.DELETE_OUTLINED],
        onClick: () => {
          setOpenedActionModal(CalculationAction.DELETE);
        },
        danger: true,
      },
    );

    return {
      items: menuItems,
    };
  }, [calculationData?.mode]);

  const dropdownTrigger: DropdownProps['trigger'] = useMemo(() => ['click'], []);
  const dropdownAlign: DropdownProps['align'] = useMemo(() => ({ offset: [0, 0] }), []);

  const togglePrivate = useCallback(() => {
    const payload: PatchCalculationPayload = {
      calculationId: templateOrCalculation.id,
      data: {
        calculationType: isPrivate ? CalculationType.Simulation : CalculationType.Private,
      },
    };
    editCalculation(payload);
  }, [editCalculation, isPrivate, templateOrCalculation.id]);

  const toggleProduction = useCallback(() => {
    dispatch(
      openModal({
        type: isProduction ? AvailableModals.UNSET_PRODUCTION : AvailableModals.SET_PRODUCTION,
        data: {
          calculationId: templateOrCalculation.id,
        },
      }),
    );
  }, [dispatch, isProduction, templateOrCalculation.id]);

  const submenuOptions: SubmenuOption[] = useMemo(() => {
    const options: SubmenuOption[] = [];
    const configurationsDisabled = manualCalculation || mergedCalculation;

    options.push(
      {
        value: CalculationDetailSubpages.CATEGORIES,
        label: 'Categories',
        disabled: configurationsDisabled,
      },
      {
        value: CalculationDetailSubpages.REGIONS,
        label: 'Regions',
        disabled: configurationsDisabled,
      },
      {
        value: CalculationDetailSubpages.STORES_AND_DEPARTMENTS,
        label: hasDepartments ? 'Stores and departments' : 'Stores',
        disabled: configurationsDisabled,
      },
      {
        value: CalculationDetailSubpages.SKUCLASSES,
        label: 'SKU classes',
        disabled: configurationsDisabled,
      },
      {
        value: CalculationDetailSubpages.PRODUCTLISTS,
        label: 'Product lists',
        disabled: configurationsDisabled,
      },
      {
        value: CalculationDetailSubpages.BRANDS,
        label: 'Brands',
        disabled: configurationsDisabled,
        appendDivider: !user?.isSuperUser,
      },
    );

    if (user?.isSuperUser) {
      options.push({
        value: CalculationDetailSubpages.ADVANCED,
        label: 'Advanced',
        disabled: configurationsDisabled,
        appendDivider: true,
      });
    }

    options.push(
      ...[
        {
          value: CalculationDetailSubpages.DRILLDOWN,
          label: 'Drill down',
        },
        {
          value: CalculationDetailSubpages.REDISTRIBUTION,
          label: 'Redistribution',
        },
        {
          value: CalculationDetailSubpages.STATISTICS,
          label: 'Statistics',
          appendDivider: isProduction && !hasTargetList,
        },
        {
          value: CalculationDetailSubpages.TARGET_LIST_STATISTICS,
          label: 'Target list',
          isVisible: hasTargetList,
          appendDivider: isProduction,
        },
        {
          value: CalculationDetailSubpages.EVALUATION,
          label: 'Evaluation',
          isVisible: isProduction && !calculationData?.isPap,
        },
        {
          value: CalculationDetailSubpages.PICKING_RESULTS,
          label: 'Picking',
          isVisible: isProduction && calculationData?.isPap,
        },
      ],
    );

    return options;
  }, [
    manualCalculation,
    mergedCalculation,
    hasDepartments,
    user?.isSuperUser,
    isProduction,
    hasTargetList,
    calculationData?.isPap,
  ]);

  const calculationTitle = `#${calculationData?.id} ${calculationData?.title}`;

  const canBeProduction = !submergedCalculation;
  const canBePrivate = !submergedCalculation;
  const canBeLoadIntoTemplate = !mergedCalculation;

  return (
    <>
      <SectionHeaderRowSpaceBetween>
        <RowCentered $gap="1rem">
          <Column $maxWidth="60rem">
            <RowCentered>
              <Popover content={calculationTitle}>
                <CalculationTitle $size="large">{calculationTitle}</CalculationTitle>
              </Popover>
            </RowCentered>
            <RowCentered $gap="1rem" $flexWrap="nowrap" $maxWidth="60rem">
              {calculationData?.mode === CalculationMode.Computed && (
                <CalculationTitleItem
                  label="Template"
                  labelWidth="5rem"
                  content={
                    /* WARNING !!! Source template actually CAN be null, in case template is deleted */
                    calculationData?.sourceTemplate?.title ?? 'deleted template'
                  }
                  contentWidth={calculationData?.description ? '25rem' : undefined}
                />
              )}
              {calculationData?.description && (
                <CalculationTitleItem
                  label="Description"
                  labelWidth="6rem"
                  content={calculationData.description}
                  contentWidth="23.5rem"
                />
              )}
            </RowCentered>
          </Column>
          <Dropdown
            trigger={dropdownTrigger}
            menu={calculationOptionDropdownItems}
            align={dropdownAlign}
          >
            <div>
              <MoreIconButton />
            </div>
          </Dropdown>
        </RowCentered>
        <RowCentered $padding={1}>
          <Space>
            {calculationData && (
              <CalculationPotentialOverview
                calculationMode={calculationData.mode}
                overlappingCalculations={calculationData.overlappingCalculations}
              />
            )}
            {user?.isSuperUser && canBePrivate && (
              <PrivateCalculationsIcon
                size="small"
                loading={isLoading}
                tooltip={
                  isProduction
                    ? `Production calculation can not be set as private`
                    : `Make ${isPrivate ? 'public' : 'private'}`
                }
                onClick={togglePrivate}
                value={isPrivate}
                disabled={isProduction}
              />
            )}
            <PapSyncButton />
            {canBeProduction && (
              <ProductionCalculationIcon
                size="small"
                loading={isLoading}
                tooltip={
                  isProduction
                    ? `Remove "production" flag`
                    : `Mark this calculation as "production", meaning it was really redistributed.`
                }
                onClick={toggleProduction}
                value={isProduction}
              />
            )}
            {canBeLoadIntoTemplate && (
              <LoadIntoTemplateAction
                size="small"
                tooltipPosition="left"
                onClick={showLoadIntoTemplateModalHandler}
                disabled={manualCalculation}
              />
            )}
          </Space>
        </RowCentered>
      </SectionHeaderRowSpaceBetween>
      <SectionHeaderRowSpaceBetweenNoBorder>
        <MenuSubpages
          routingPage={RoutingPage.CALCULATIONS}
          submenuOptions={submenuOptions}
          selectedOption={subpage}
        />
        {subpage === CalculationDetailSubpages.CATEGORIES && <ConfigurationExceptions />}
        {(manualCalculation || user?.isSuperUser) && (
          <RecomputeStatisticsButton
            calculationStatus={calculationData?.status}
            calculationType={calculationData?.type}
          />
        )}
      </SectionHeaderRowSpaceBetweenNoBorder>
      {openedActionModal === CalculationAction.EDIT && calculationData && (
        <EditCalculationModal
          open={true}
          onCancel={closeOpenedActionModal}
          calculationId={calculationData.id}
        />
      )}
      {openedActionModal === CalculationAction.DELETE && calculationData && (
        <GenericConfirmationModal
          title="Delete calculation"
          message={`Calculation '${calculationData.title}' will be deleted. Continue? `}
          onConfirmed={deleteCalculationHandler}
          onCanceled={closeOpenedActionModal}
        />
      )}
      {openedActionModal === CalculationAction.CREATE_MERGED && calculationData && (
        <CreateCalculationWithDefaultsModal
          calculation={calculationData}
          onClose={closeOpenedActionModal}
        />
      )}

      {showLoadIntoTemplateModal && calculationData && (
        <LoadIntoTemplateModal
          calculationId={calculationData.id}
          calculationTitle={calculationData.title}
          onCanceled={closeLoadIntoTemplateModal}
          onConfirmed={onConfirmed}
        />
      )}
    </>
  );
};

export default CalculationHeader;
