import React from 'react';
import { Column, Row, Text, Title } from '@ydistri/ds';
import { DisplayEntityIdType, ProductResponse } from '@ydistri/api-sdk';
import { styled } from 'styled-components';
import CloseSelectedProductAction from './CloseSelectedProductAction';
import { Tooltip } from 'antd';
import { useApplicationConfiguration } from '../../../../../hooks/useApplicationConfiguration';

const ProductDetailsWrapper = styled(Column)`
  background-color: ${p => p.theme.item.selected.backgroundColor};
  padding: 0.5rem;
`;

interface SelectedProductDetailsProps {
  product: ProductResponse;
  onProductDeselected?: () => void;
}

const ProductNameRow = styled(Row)`
  overflow: hidden;
`;

const ProductName = styled(Title)`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 15rem;
`;

const SelectedProductDetails: React.FC<SelectedProductDetailsProps> = ({
  product,
  onProductDeselected,
}) => {
  const { displayProductId } = useApplicationConfiguration();

  return (
    <ProductDetailsWrapper $gap="8px">
      <Row $justifyContent="space-between">
        <Title $size="small">Currently selected</Title>
        <CloseSelectedProductAction onClick={onProductDeselected} />
      </Row>
      <ProductNameRow>
        <Tooltip title={product.name}>
          <ProductName $size="small">{product.name}</ProductName>
        </Tooltip>
      </ProductNameRow>
      <Row $justifyContent="space-between">
        {product.customerId && <Text>{`ID: ${product.customerId}`}</Text>}
        {displayProductId === DisplayEntityIdType.Code && product.code && (
          <Text>{`Code: ${product.code}`}</Text>
        )}
      </Row>
    </ProductDetailsWrapper>
  );
};

export default SelectedProductDetails;
