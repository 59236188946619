import { Units } from '../../../../../../lib/charts/saleChartsTypes';
import { Row, Label } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { styled } from 'styled-components';

const SelectWrapper = styled(Select<Units>)`
  min-width: 10rem;
`;

interface UnitSelectorProps {
  value: Units;
  onValueChange: (value: Units) => void;
  currency: string;
}

const UnitSelector: React.FC<UnitSelectorProps> = ({ value, onValueChange, currency }) => {
  const options: SelectProps['options'] = useMemo(() => {
    return [
      {
        label: `Value (${currency})`,
        value: Units.VALUE,
      },
      {
        label: 'Quantity (MU)',
        value: Units.QUANTITY,
      },
    ];
  }, [currency]);

  const changeHandler = useCallback(
    (newValue: Units) => {
      onValueChange(newValue);
      localStorage.setItem('units', newValue);
    },
    [onValueChange],
  );

  return (
    <Row $gap="1rem" $alignItems="center">
      <Label>Unit:</Label>
      <SelectWrapper
        defaultValue={value}
        value={value}
        options={options}
        onChange={changeHandler}
        variant="outlined"
      />
    </Row>
  );
};

export default UnitSelector;
