import { styled } from 'styled-components';

/**
 * Can be used for similar components as DragHandle to keep the same styling
 */
export const DragHandleBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
`;
