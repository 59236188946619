import React, { useCallback, useMemo } from 'react';
import { MultiselectCustomerPartialEntity } from './ConfigurationMultiselect';
import { ConfigurationRuleScopeEntityType, ConfigurationRuleScopeMode } from '@ydistri/api-sdk';
import { useScope } from './useScope';
import { Column, Row, Text } from '@ydistri/ds';
import MultiselectTag from './MultiselectTag';
import { useOpenScopeModal } from './useOpenScopeModal';
import { getScopeErrorText } from './scopeLib';
import { Popover } from 'antd';

interface ScopeViewModeProps {
  scopeId: number;
  entityType: ConfigurationRuleScopeEntityType;
  loading?: boolean;
  options: MultiselectCustomerPartialEntity[];
}

const visibleLimit = 5;

const ScopeViewMode: React.FC<ScopeViewModeProps> = ({ scopeId, entityType, options }) => {
  const scope = useScope(scopeId);
  const openModalHandler = useOpenScopeModal(scope);

  const selectionOption = scope?.entities[entityType].selectionOption;

  const selectedOptions = useMemo(() => {
    const selectedIds = scope?.entities[entityType].selectedIds ?? [];
    return selectedIds.map(o => ({ id: o, error: !options.find(x => x.id === o) }));
  }, [entityType, options, scope?.entities]);

  const tagRenderer = useCallback(
    (option: { id: number; error: boolean }) => {
      return (
        <MultiselectTag
          key={option.id}
          entityType={entityType}
          closable={false}
          entityId={option.id}
          error={getScopeErrorText(scope?.priority ?? 0, option.error, entityType)}
          onClick={openModalHandler}
          label={options.find(item => item.id === option.id)?.label}
        />
      );
    },
    [entityType, openModalHandler, options, scope?.priority],
  );

  const popoverContent = useMemo(() => {
    return (
      <Row $gap="0.25rem" $flexWrap="wrap" $maxWidth="25rem">
        {selectedOptions.map(tagRenderer)}
      </Row>
    );
  }, [selectedOptions, tagRenderer]);

  if (selectionOption === ConfigurationRuleScopeMode.All) {
    return '';
  } else {
    return (
      <Column $gap="0.5rem">
        <Row $gap="0.25rem" $flexGrow={1} $flexWrap="wrap" $alignItems="center">
          {selectedOptions.length === 0 && 'None'}
          {selectedOptions.slice(0, visibleLimit).map(tagRenderer)}
          {selectedOptions.length > visibleLimit && (
            <Popover content={popoverContent}>
              <Text $semiBold>+{selectedOptions.length - visibleLimit} more</Text>
            </Popover>
          )}
        </Row>
      </Column>
    );
  }
};

export default ScopeViewMode;
