import { CalculationConfigurationExperimentalType } from '@ydistri/api-sdk';
import { BalancingGroupObject, BalancingObject } from './balancingLib';

export enum BalancingGroupV200 {
  HFMQ_V200 = 'HFMQ_V200',
  SOURCE_BUFFER_V200 = 'SOURCE_BUFFER_V200',
  TARGET_URGENT_V200 = 'TARGET_URGENT_V200',
  SOURCE_URGENT_V200 = 'SOURCE_URGENT_V200',
  TARGET_BUFFER_V200 = 'TARGET_BUFFER_V200',
}

export const balancingGroupV200: Record<BalancingGroupV200, BalancingGroupObject> = {
  [BalancingGroupV200.HFMQ_V200]: {
    id: BalancingGroupV200.HFMQ_V200,
    header: 'Human feasible minimal quantities (HFMQ)',
    display: true,
    isMainGroup: true,
    enableSetting: CalculationConfigurationExperimentalType.HfmqIsEnabled,
  },
  [BalancingGroupV200.SOURCE_BUFFER_V200]: {
    id: BalancingGroupV200.SOURCE_BUFFER_V200,
    header: 'Source buffer',
    display: true,
    enableSetting: CalculationConfigurationExperimentalType.SourceBufferIsEnabled,
  },
  [BalancingGroupV200.TARGET_URGENT_V200]: {
    id: BalancingGroupV200.TARGET_URGENT_V200,
    header: 'Target urgent',
    display: true,
    enableSetting: CalculationConfigurationExperimentalType.TargetUrgentIsEnabled,
  },
  [BalancingGroupV200.SOURCE_URGENT_V200]: {
    id: BalancingGroupV200.SOURCE_URGENT_V200,
    header: 'Source urgent',
    display: true,
    enableSetting: CalculationConfigurationExperimentalType.SourceUrgentIsEnabled,
  },
  [BalancingGroupV200.TARGET_BUFFER_V200]: {
    id: BalancingGroupV200.TARGET_BUFFER_V200,
    header: 'Target buffer',
    display: true,
    enableSetting: CalculationConfigurationExperimentalType.TargetBufferIsEnabled,
  },
} as const;

export const emptyBalancingObjectV200: BalancingObject = {
  [CalculationConfigurationExperimentalType.HfmqIsEnabled]: {
    groupId: BalancingGroupV200.HFMQ_V200,
    fieldKey: CalculationConfigurationExperimentalType.HfmqIsEnabled,
    label: 'Enable functionality',
    description: '',
    type: 'Boolean',
    values: [],
  },
  [CalculationConfigurationExperimentalType.HfmqMaximal]: {
    groupId: BalancingGroupV200.HFMQ_V200,
    fieldKey: CalculationConfigurationExperimentalType.HfmqMaximal,
    label: 'Maximal HFMQ',
    description: '',
    type: 'Int',
    values: [],
  },
  [CalculationConfigurationExperimentalType.HfmqPriceUpTo]: {
    groupId: BalancingGroupV200.HFMQ_V200,
    fieldKey: CalculationConfigurationExperimentalType.HfmqPriceUpTo,
    label: 'Min HFMQ percentage of supply for price',
    description: '',
    type: 'Decimal',
    values: [],
    groupedWith: [CalculationConfigurationExperimentalType.HfmqMinPercentageOfTotalSupply],
    columnLabel: 'Price up to',
    lastValuePlaceholder: 'Above {prevValue}',
  },
  [CalculationConfigurationExperimentalType.HfmqMinPercentageOfTotalSupply]: {
    groupId: BalancingGroupV200.HFMQ_V200,
    fieldKey: CalculationConfigurationExperimentalType.HfmqMinPercentageOfTotalSupply,
    label: '',
    description: '',
    type: 'Int',
    columnLabel: 'Percentage',
    values: [],
  },
  [CalculationConfigurationExperimentalType.SourceBufferIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceBufferIsEnabled,
    label: 'Enable functionality',
    description: 'Buffers on source can be used to fulfil HFMQ or supply target urgents',
    type: 'Boolean',
    values: [],
  },
  [CalculationConfigurationExperimentalType.SourceBufferSkuTypeDeadStockIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceBufferSkuTypeDeadStockIsEnabled,
    label: 'Source SKU type',
    description: '',
    type: 'Boolean',
    values: [],
    groupedWith: [
      CalculationConfigurationExperimentalType.SourceBufferSkuTypeSlowMoverIsEnabled,
      CalculationConfigurationExperimentalType.SourceBufferSkuTypeFastMoverIsEnabled,
    ],
    columnLabel: 'DeadStock',
  },
  [CalculationConfigurationExperimentalType.SourceBufferSkuTypeSlowMoverIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceBufferSkuTypeSlowMoverIsEnabled,
    label: '',
    description: '',
    type: 'Boolean',
    columnLabel: 'SlowMover',
    values: [],
  },
  [CalculationConfigurationExperimentalType.SourceBufferSkuTypeFastMoverIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceBufferSkuTypeFastMoverIsEnabled,
    label: '',
    description: '',
    type: 'Boolean',
    columnLabel: 'FastMover',
    values: [],
  },
  [CalculationConfigurationExperimentalType.SourceBufferOutlook]: {
    groupId: BalancingGroupV200.SOURCE_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceBufferOutlook,
    label: 'Outlook',
    description: 'Untouchable months of supply to keep = cannot be part of buffer',
    type: 'Decimal',
    values: [],
    min: 1,
    max: 5,
  },
  [CalculationConfigurationExperimentalType.SourceBufferSeasonalProtection]: {
    groupId: BalancingGroupV200.SOURCE_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceBufferSeasonalProtection,
    label: 'Seasonal protection',
    description: 'Percentage of the maximum possible forecast increase',
    type: 'Int',
    values: [],
  },

  // TARGET URGENT
  [CalculationConfigurationExperimentalType.TargetUrgentIsEnabled]: {
    groupId: BalancingGroupV200.TARGET_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.TargetUrgentIsEnabled,
    label: 'Enable functionality',
    description:
      'Target urgents reduce risk of going stockout for very low target supply. It is supplied by source buffers.',
    type: 'Boolean',
    values: [],
  },
  [CalculationConfigurationExperimentalType.TargetUrgentOutlook]: {
    groupId: BalancingGroupV200.TARGET_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.TargetUrgentOutlook,
    label: 'Outlook',
    description: 'Months to sellout of very low supply',
    type: 'Decimal',
    values: [],
    min: 1,
    max: 5,
  },

  // SOURCE URGENT
  [CalculationConfigurationExperimentalType.SourceUrgentIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceUrgentIsEnabled,
    label: 'Enable functionality',
    description:
      'Turn on/off definition of super over-stock that must be urgently balanced = can fulfil target buffers',
    type: 'Boolean',
    values: [],
  },

  [CalculationConfigurationExperimentalType.SourceUrgentSkuTypeDeadStockIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceUrgentSkuTypeDeadStockIsEnabled,
    label: 'Source SKU type',
    description: '',
    type: 'Boolean',
    values: [],
    groupedWith: [
      CalculationConfigurationExperimentalType.SourceUrgentSkuTypeSlowMoverIsEnabled,
      CalculationConfigurationExperimentalType.SourceUrgentSkuTypeFastMoverIsEnabled,
    ],
    columnLabel: 'DeadStock',
  },
  [CalculationConfigurationExperimentalType.SourceUrgentSkuTypeSlowMoverIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceUrgentSkuTypeSlowMoverIsEnabled,
    label: '',
    description: '',
    type: 'Boolean',
    columnLabel: 'SlowMover',
    values: [],
  },
  [CalculationConfigurationExperimentalType.SourceUrgentSkuTypeFastMoverIsEnabled]: {
    groupId: BalancingGroupV200.SOURCE_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceUrgentSkuTypeFastMoverIsEnabled,
    label: '',
    description: '',
    type: 'Boolean',
    columnLabel: 'FastMover',
    values: [],
  },

  [CalculationConfigurationExperimentalType.SourceUrgentOutlook]: {
    groupId: BalancingGroupV200.SOURCE_URGENT_V200,
    fieldKey: CalculationConfigurationExperimentalType.SourceUrgentOutlook,
    label: 'Outlook',
    description: 'Months of supply to define over-stocks',
    type: 'Int',
    values: [],
    min: 2,
    max: 6,
  },

  //TARGET BUFFER
  [CalculationConfigurationExperimentalType.TargetBufferIsEnabled]: {
    groupId: BalancingGroupV200.TARGET_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.TargetBufferIsEnabled,
    label: 'Enable functionality',
    description:
      'Target buffers reduce risk of going overstock on sources. It is supplied by source urgents.',
    type: 'Boolean',
    values: [],
  },
  [CalculationConfigurationExperimentalType.TargetBufferOutlook]: {
    groupId: BalancingGroupV200.TARGET_BUFFER_V200,
    fieldKey: CalculationConfigurationExperimentalType.TargetBufferOutlook,
    label: 'Outlook',
    description: 'Months until buffered supply is sold out',
    type: 'Int',
    values: [],
    min: 2,
    max: 6,
  },
};
