import React, { useMemo } from 'react';
import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import { MdOutlineBarChart } from 'react-icons/md';
import { useSelector } from 'react-redux';

interface CloseAllStatisticsActionProps {
  onClick: () => void;
  calculationTableId: string;
  disabled?: boolean;
  size?: IconButtonSize;
}

const CloseAllStatisticsAction: React.FC<CloseAllStatisticsActionProps> = ({
  onClick,
  calculationTableId,
  size = 'normal',
}) => {
  const icon = useMemo(() => <MdOutlineBarChart size={IconButton_IconSizes[size]} />, [size]);
  const expandedRowKeys = useSelector(
    state => state.calculations.expandedRowKeys[calculationTableId],
  );

  return (
    <IconButton
      size={size}
      tooltip="Close all statistics details"
      tooltipPosition="left"
      icon={icon}
      onClick={onClick}
      disabled={!expandedRowKeys?.length}
    />
  );
};
export default CloseAllStatisticsAction;
