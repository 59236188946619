import { BrandConfigurationResponse, CalculationConfigurationResponse } from '@ydistri/api-sdk';
import {
  ValidatorMessage,
  ValidatorMessageSeverity,
  ValidatorResponse,
  ValidatorSection,
} from './validatorLib';
import { createDebugLog } from '../../../lib/utils/logging';
import { initialValidatorInfoBrands } from './validatorInfoLib';
import { plural } from '@ydistri/utils';

const debugLog = createDebugLog('Validator', 'validateBrands');

enum Messages {
  NO_ENABLED_BRANDS,
}

const section = ValidatorSection.BRANDS;

type PartialMessage = Omit<ValidatorMessage, 'section'>;

const addMessage = (condition: boolean, messages: ValidatorMessage[], message: PartialMessage) => {
  if (!condition) return;
  messages.push({
    section,
    ...message,
  });
};

const MESSAGES = {
  noEnabledBrands: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'General setting is "All disabled", but no brand is enabled.',
    key: Messages.NO_ENABLED_BRANDS,
  },
} as const;

export const validateBrands = (
  brandConfig: BrandConfigurationResponse[],
  templateConfig: CalculationConfigurationResponse,
): ValidatorResponse<typeof section> => {
  debugLog('brandConfig', brandConfig);

  const rsp: ValidatorResponse<typeof section> = {
    section,
    messages: [],
    info: { ...initialValidatorInfoBrands },
    overviewMessage: '',
  };

  const allEnabled = templateConfig.brandsAllEnabled;
  let exceptionCount = 0;

  brandConfig.forEach(b => {
    if (b.isConfigured && b.productCount > 0) {
      exceptionCount++;
    }
  });

  addMessage(exceptionCount === 0 && !allEnabled, rsp.messages, MESSAGES.noEnabledBrands);

  const infoMessages: string[] = [];
  if (allEnabled) {
    if (exceptionCount > 0) {
      infoMessages.push(
        `${exceptionCount} brand${plural(exceptionCount, ' is', 's are')} disabled`,
      );
    } else {
      infoMessages.push(`All brands are enabled`);
    }
  } else {
    if (exceptionCount > 0) {
      infoMessages.push(`${exceptionCount} brand${plural(exceptionCount, ' is', 's are')} enabled`);
    } else {
      //this is an error, no need to add another message
    }
  }

  rsp.info.allEnabled = allEnabled;
  rsp.info.exceptionCount = exceptionCount;
  rsp.overviewMessage = infoMessages.join(' ');

  return rsp;
};
