import { CategoryDetailType, DetailType, ProductDetailType } from './detailTypes';
import { SkuAttrs, SkuAttrsStored } from '../../lib/sku/skuTypes';
import { SkusCollection } from '../../swagger/collections';
import { Operation, SkuResponse } from '@ydistri/api-sdk';

/**
 * Decide based on the combination of productId, storeId and skuId what type of detail we are looking at
 * @param productId -1 if not set
 * @param storeId -1 if not set
 * @param skuId -1 if not set
 */
export const getDetailType = (productId?: number, storeId?: number, skuId?: number): DetailType => {
  if (skuId && skuId > 0) {
    return DetailType.SKU;
  }

  if (!productId || productId < 1) {
    return DetailType.CATEGORY;
  } else {
    if (storeId && storeId > 0) {
      return DetailType.SKU;
    } else {
      return DetailType.PRODUCT;
    }
  }
};

export const getCategoryDetailType = (
  productId?: number,
  regionId?: number,
  storeId?: number,
): CategoryDetailType | undefined => {
  if (getDetailType(productId, storeId) !== DetailType.CATEGORY) {
    return undefined;
  }

  if (storeId && storeId > 0) {
    return CategoryDetailType.STORE;
  } else {
    if (regionId && regionId > 0) {
      return CategoryDetailType.REGION;
    } else {
      return CategoryDetailType.DEFAULT;
    }
  }
};

export const getProductDetailType = (
  productId?: number,
  regionId?: number,
  storeId?: number,
): ProductDetailType | undefined => {
  if (getDetailType(productId, storeId) !== DetailType.PRODUCT) {
    return undefined;
  }
  return regionId && regionId > 0 ? ProductDetailType.REGION : ProductDetailType.DEFAULT;
};

export const makeSKUAttributesStorable = (skuAttributes: SkuAttrs): SkuAttrsStored => {
  return {
    ...skuAttributes,
    dates: {
      dateOfFirstSale: skuAttributes.dates.dateOfFirstSale?.getTime() ?? undefined,
      dateOfLastSale: skuAttributes.dates.dateOfLastSale?.getTime() ?? undefined,
      dateOfFirstPurchase: skuAttributes.dates.dateOfFirstPurchase?.getTime() ?? undefined,
      dateOfLastPurchase: skuAttributes.dates.dateOfLastPurchase?.getTime() ?? undefined,
    },
  };
};

export const restoreSKUAttributes = (skuAttributes: SkuAttrsStored): SkuAttrs => {
  return {
    ...skuAttributes,
    dates: {
      dateOfFirstSale: skuAttributes.dates.dateOfFirstSale
        ? new Date(skuAttributes.dates.dateOfFirstSale)
        : undefined,
      dateOfLastSale: skuAttributes.dates.dateOfLastSale
        ? new Date(skuAttributes.dates.dateOfLastSale)
        : undefined,
      dateOfFirstPurchase: skuAttributes.dates.dateOfFirstPurchase
        ? new Date(skuAttributes.dates.dateOfFirstPurchase)
        : undefined,
      dateOfLastPurchase: skuAttributes.dates.dateOfLastPurchase
        ? new Date(skuAttributes.dates.dateOfLastPurchase)
        : undefined,
    },
  };
};

/*
  TODO - we should probably use RTK query instead of using this function, should be also cancellable:
  https://stackoverflow.com/questions/71870135/how-to-cancel-the-rtk-query-requests
 */
export const getSku = async (
  productId: number,
  storeId: number,
  signal?: AbortSignal,
): Promise<SkuResponse | undefined> => {
  const { data } = await SkusCollection.skusList(
    {
      inlineCount: true,
      conditions: [
        {
          fieldName: 'StoreId',
          operation: Operation.Eq,
          value: storeId,
        },
        {
          fieldName: 'ProductId',
          operation: Operation.Eq,
          value: productId,
        },
      ],
    },
    { signal: signal },
  );

  if (data.totalCount === 1) {
    return data.data[0];
  } else {
    return undefined;
  }
};

export const getSkuById = async (
  skuId: number,
  signal?: AbortSignal,
): Promise<SkuResponse | undefined> => {
  const { data } = await SkusCollection.skusList(
    {
      inlineCount: true,
      conditions: [
        {
          fieldName: 'SkuId',
          operation: Operation.Eq,
          value: skuId,
        },
      ],
    },
    { signal: signal },
  );

  if (data.totalCount === 1) {
    return data.data[0];
  } else {
    return undefined;
  }
};
