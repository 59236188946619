import React, { useMemo, useState } from 'react';
import { InfoIconTooltip } from '../../../../../../../components/icons/InfoIconTooltip';
import { Column, Row, Text } from '@ydistri/ds';
import {
  CalculationCategoryPairingRequest,
  useGetCalculationCategoryPairingsQuery,
} from '../../../../../../Calculations/apiCalculationsCategories';
import { LogicalOperator, Operation } from '@ydistri/api-sdk';

interface SubordinateCalculationSkuAvailabilityRendererProps {
  sourceOrTarget: 'Source' | 'Target';
  available: boolean;
  manual?: boolean;
  calculationId: number;
  skuId: number;
}

const SubordinateCalculationSkuAvailabilityRenderer: React.FC<
  SubordinateCalculationSkuAvailabilityRendererProps
> = ({ sourceOrTarget, available, manual = false, calculationId, skuId }) => {
  const [params] = useState<CalculationCategoryPairingRequest>({
    calculationId,
    categoryId: 1,
    skip: 0,
    top: 100,
    conditions: [
      {
        fieldName: `${sourceOrTarget}SkuId`,
        operation: Operation.Eq,
        value: skuId,
        logicalOperator: LogicalOperator.Or,
      },
    ],
  });

  const { data: redistributionData } = useGetCalculationCategoryPairingsQuery(params);

  const lineQuantities = useMemo(
    () => (redistributionData?.data ?? []).map(d => d.quantity),
    [redistributionData?.data],
  );
  const quantitySum = lineQuantities.reduce((p, c) => p + c, 0);

  const content = useMemo(
    () =>
      manual ? (
        <Text>Manual calculations do not have configurations.</Text>
      ) : (
        <Column $gap="0.5rem">
          {(quantitySum === 0 || !available) && (
            <Text>
              {sourceOrTarget} SKU of this picking line is {available ? '' : 'not'} available as{' '}
              {sourceOrTarget.toLowerCase()} in this calculation
            </Text>
          )}
          {available && quantitySum === 0 && (
            <>
              <Text $type="light">
                This SKU is in the scope and can have {sourceOrTarget.toLowerCase()} potential.
              </Text>
              <Text $type="light">
                This does <Text $bold>not</Text> mean it is in the redistribution.
              </Text>
            </>
          )}
          {!available && (
            <Text $type="light">
              This SKU is probably out of scope and can not have {sourceOrTarget.toLowerCase()}{' '}
              potential.
            </Text>
          )}

          {quantitySum > 0 && (
            <>
              <Text $bold>Picking lines of this SKU as {sourceOrTarget.toLowerCase()}:</Text>
              <table>
                <tbody>
                  {redistributionData?.data.map(d => (
                    <tr key={`${d.sourceSkuId}-${d.targetSkuId}`}>
                      <td style={{ textAlign: 'right' }}>
                        <Text $bold>{d.quantity} MU</Text>
                      </td>
                      <td>{sourceOrTarget === 'Source' ? 'to' : 'from'}</td>
                      <td>
                        <Text $bold>
                          {sourceOrTarget === 'Source' ? d.targetStore.name : d.sourceStore.name}
                        </Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Column>
      ),
    [manual, redistributionData, sourceOrTarget, available],
  );

  return (
    <Row $alignItems="center" $gap="0.25rem">
      <InfoIconTooltip content={content} />
      {manual ? (
        <Text>
          Manual{' '}
          {available && quantitySum > 0 && (
            <Text>
              {' '}
              ({quantitySum}MU / {lineQuantities.length})
            </Text>
          )}
        </Text>
      ) : (
        <Text $type={available ? 'success' : 'danger'}>
          <Text $bold>{available ? 'Available' : 'Not available'}</Text>
          {available && quantitySum > 0 && (
            <Text>
              {' '}
              ({quantitySum}MU / {lineQuantities.length})
            </Text>
          )}
        </Text>
      )}
    </Row>
  );
};

export default SubordinateCalculationSkuAvailabilityRenderer;
