import { styled } from 'styled-components';
import { Label } from '@ydistri/ds';
import React from 'react';

interface CurrencyTextDisplayProps {
  $fontSize?: string;
  $color?: string;
}

interface CurrencyTextProps extends CurrencyTextDisplayProps {
  currency: string;
}

const CurrencyTextDisplay = styled(Label)<CurrencyTextDisplayProps>`
  text-transform: capitalize;
  font-size: ${props => props.$fontSize ?? 'inherit'};
  color: ${props => props.$color ?? 'inherit'};
`;

/**
 * Capitalizes the given currency and displays it in given size and color
 * @param $fontSize Size of the text
 * @param $color Color of the text
 * @param currency Currency to display
 * @constructor
 */
export const CurrencyText: React.FC<CurrencyTextProps> = ({
  $fontSize = 'inherit',
  $color = 'inherit',
  currency,
}) => {
  return (
    <CurrencyTextDisplay $fontSize={$fontSize} $color={$color}>
      {currency}
    </CurrencyTextDisplay>
  );
};
