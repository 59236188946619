import { useMemo } from 'react';
import { getDetailType } from '../detailLib';
import { DetailType } from '../detailTypes';
import { useSelector } from 'react-redux';

const useDetailScreenType = (productId?: number, store?: number, skuId?: number): DetailType => {
  const skuNotFound = useSelector(state => state.detailReducer.skuNotFound);

  return useMemo(
    () => (skuNotFound ? DetailType.SKU_NOT_FOUND : getDetailType(productId, store, skuId)),
    [skuNotFound, productId, store, skuId],
  );
};

export default useDetailScreenType;
