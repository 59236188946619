import { styled } from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Link with standard style
 * When hovered the color of the link and its children is changed to the primary color
 */
const YLink = styled(Link)`
  color: ${({ theme }) => theme.colors.dark};
  transition: ${({ theme }) => theme.item.transition};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    * {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default YLink;
