import { useCallback, useEffect, useState } from 'react';
import { createDebugLog } from '../lib/utils/logging';
const debugLog = createDebugLog('hooks', 'useWindowDimension');

export interface WindowDimension {
  width: number;
  height: number;
}

/**
 * Returns the inner width and height of the window.
 * Uses resize listener to update the values.
 */
export const useWindowDimension = (): WindowDimension => {
  const [windowSize, setWindowSize] = useState<WindowDimension>(() => {
    if (typeof window !== 'undefined') {
      return { width: window.innerWidth, height: innerHeight };
    } else {
      return { width: 1920, height: 1080 };
    }
  });

  const resizeHandler = useCallback(() => {
    const newSize = { width: window.innerWidth, height: window.innerHeight };
    debugLog('newSize from resize handler %o', newSize);
    setWindowSize(newSize);
  }, []);

  useEffect(() => {
    debugLog('adding resize handler to window');
    window.addEventListener('resize', resizeHandler);

    return () => {
      debugLog('removing resize handler from window');
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return windowSize;
};
