import React, { useMemo } from 'react';
import { ImStatsDots } from 'react-icons/im';
import ToggleIconButton from '../../../../../../../../components/buttons/ToggleIconButton';
import { IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';

interface ToggleCumulativeForecastProps {
  onClick: () => void;
  checked?: boolean;
  size?: IconButtonSize;
}

const ToggleCumulativeForecast: React.FC<ToggleCumulativeForecastProps> = ({
  onClick,
  checked = false,
  size = 'small',
}) => {
  const icon = useMemo(() => <ImStatsDots size={IconButton_IconSizes[size]} />, [size]);

  return (
    <ToggleIconButton
      size={size}
      tooltip="Cumulative forecast"
      onClick={onClick}
      icon={icon}
      checked={checked}
    />
  );
};

export default ToggleCumulativeForecast;
