import { styled } from 'styled-components';
import { List } from 'antd';
import React, { PropsWithChildren } from 'react';

interface YListItemProps extends PropsWithChildren {
  selected?: boolean;
  onClick?: React.MouseEventHandler;
  $backgroundColor?: string;
}

/**
 * Component to be used together with antd's List in ints render function.
 * Extends the antd's List.Item with hover, selected and other properties.
 */
const YListItem = styled(List.Item)<YListItemProps>`
  cursor: ${p => (p.onClick ? 'pointer' : 'normal')};
  background-color: ${p => {
    if (p.selected) {
      return p.theme.item.selected.backgroundColor;
    } else {
      return p.$backgroundColor ?? 'initial';
    }
  }};
  transition: ${p => p.theme.item.transition};

  &:hover {
    background-color: ${p => p.theme.item.hovered.backgroundColor};
  }
`;

export default YListItem;
