import { CurrencyText } from '../../../../components/domain/CurrencyText';
import { formatNumber, formatValueOrQuantity } from '@ydistri/utils';
import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { Label, Text } from '@ydistri/ds';

import CalculationTableText from './CalculationTableText';

interface StyledPairingValueTextProps {
  $color: CSSProperties['color'];
}

export const StyledPairingValueText = styled(Label)<StyledPairingValueTextProps>`
  color: ${props => props.$color};
`;

interface PairingValueTextProps {
  value: number;
  color: CSSProperties['color'];
  currency: string;
  valueDisplay?: 'short' | 'full';
  manual?: boolean;
}

export const PairingValueText: React.FC<PairingValueTextProps> = ({
  value,
  color,
  currency,
  valueDisplay = 'short',
  manual = false,
}) => {
  if (manual) {
    return (
      <StyledPairingValueText $color={color}>
        <Text $size="large">-</Text>
      </StyledPairingValueText>
    );
  }

  return (
    <StyledPairingValueText $color={color}>
      <CalculationTableText>
        {valueDisplay === 'short' ? formatValueOrQuantity(value) : formatNumber(value)}
      </CalculationTableText>{' '}
      <CurrencyText $fontSize="0.9rem" $color={color} currency={currency} />
    </StyledPairingValueText>
  );
};
