import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { Row, Label } from '@ydistri/ds';
import { styled } from 'styled-components';
import { NO_VALUE } from '../../../lib/utils/utilsTypes';
import { selectFilterHandler } from '@ydistri/utils';
import { DefaultOptionType } from 'rc-select/lib/Select';

export interface SelectorOption {
  id: number;
  label?: string;
}

interface SelectorProps {
  options: SelectorOption[];
  onChange?: (value: number) => void;
  value?: number;
  label: string;
  noSelectionLabel?: string;
  showSearch?: boolean;
}

const SelectWrapper = styled(Select<number>)`
  min-width: 20rem;
`;

/**
 * Generic selector component to display a list of objects with an id and a label.
 * Adds generic 'All values' option at the top of the list with value SELECTOR_NO_VALUE.
 * The select itself is prefixed with a Label.
 * @param options
 * @param onChange
 * @param value
 * @param label
 * @param noSelectionLabel
 * @param showSearch Allow search in options
 * @constructor
 */
const GenericSelector: React.FC<SelectorProps> = ({
  options,
  onChange,
  value,
  label,
  noSelectionLabel,
  showSearch,
}) => {
  const changeHandler = useCallback(
    (value: number | undefined) => {
      //when cleared the handler is called with undefined, we ignore it
      //because the handler was already called with SELECTOR_NO_VALUE
      if (typeof value !== 'undefined') {
        if (onChange) {
          onChange(value);
        }
      }
    },
    [onChange],
  );

  const allOptions: DefaultOptionType[] = useMemo(() => {
    const noValueOption: DefaultOptionType[] = [
      {
        value: NO_VALUE,
        label: `${noSelectionLabel ?? `All ${label}s`} (${options.length})`,
      },
    ];

    return noValueOption.concat(
      options.map(option => ({
        value: option.id,
        label: option.label ?? option.id.toString(),
      })),
    );
  }, [noSelectionLabel, label, options]);

  const clearHandler = useCallback(() => {
    changeHandler(NO_VALUE);
  }, [changeHandler]);

  const filterOption = useCallback(
    (input: string, option?: DefaultOptionType) => selectFilterHandler(input, option, false, false),
    [],
  );

  return (
    <Row $gap="1rem" $alignItems="center">
      <Label>{`${label}:`}</Label>
      <SelectWrapper
        options={allOptions}
        id={`${label.toLowerCase()}-select`}
        onChange={changeHandler}
        defaultValue={NO_VALUE}
        value={value ?? NO_VALUE}
        onClear={clearHandler}
        allowClear={value !== NO_VALUE}
        showSearch={showSearch}
        filterOption={filterOption}
        popupMatchSelectWidth={false}
      />
    </Row>
  );
};

export default GenericSelector;
