import { Button, Drawer, Space } from 'antd';
import React, { PropsWithChildren, useMemo } from 'react';
import { Column } from '@ydistri/ds';

interface TargetListDrawerEditorProps extends PropsWithChildren {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  title: string;
}

const ListItemDrawerEditor: React.FC<TargetListDrawerEditorProps> = ({
  onConfirm,
  onCancel,
  open,
  children,
  title,
}) => {
  const actions = useMemo(
    () => (
      <Space>
        <Button onClick={onCancel} key="cancel">
          Cancel
        </Button>
        <Button type="primary" onClick={onConfirm} key="ok">
          OK
        </Button>
      </Space>
    ),
    [onCancel, onConfirm],
  );

  return (
    <Drawer
      title={title}
      placement="right"
      width={500}
      onClose={onCancel}
      open={open}
      extra={actions}
    >
      <Column $gap="0.5rem">{children}</Column>
    </Drawer>
  );
};

export default ListItemDrawerEditor;
