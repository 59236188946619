import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const COLUMN_SIZE_MODIFIER = 0.65;

export interface ApplicationColumnWidths {
  productIdLength: number;
  storeIdLength: number;
  productIdColumnWidth: string;
  storeIdColumnWidth: string;
}

export function useColumnWidth(): ApplicationColumnWidths {
  const { projectShortName } = useParams();

  //TODO: there should be endpoint for this in the future - for now, we just hardcode it
  return useMemo(() => {
    let productIdLength = 14; //14 is default - when multiplied with COLUMN_SIZE_MODIFIER, it will be 9.1rem => Product ID column doesn't break
    const storeIdLength = 14;

    if (projectShortName && ['galeriade', 'galeriade-fashion'].includes(projectShortName)) {
      productIdLength = 20;
    }

    return {
      productIdLength,
      storeIdLength,
      productIdColumnWidth: `${productIdLength * COLUMN_SIZE_MODIFIER}rem`,
      storeIdColumnWidth: `${storeIdLength * COLUMN_SIZE_MODIFIER}rem`,
    };
  }, [projectShortName]);
}
