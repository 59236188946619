import { YButton } from '@ydistri/ds';
import React from 'react';
import { ButtonProps } from 'antd';

interface ValidationResultsConfirmUpdateButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  size: ButtonProps['size'];
}

const ValidationResultsConfirmUpdateButton: React.FC<ValidationResultsConfirmUpdateButtonProps> = ({
  label,
  onClick,
  disabled = false,
  size,
}) => {
  return (
    <YButton
      onClick={onClick}
      data-type="submit"
      type="primary"
      size={size}
      tooltip={
        disabled
          ? 'There are no items to insert or update. Insert items that will change the list'
          : 'New items and updates will be written to the list'
      }
      tooltipPlacement="topRight"
      disabled={disabled}
    >
      {label}
    </YButton>
  );
};

export default ValidationResultsConfirmUpdateButton;
