import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetailType } from '../../../../detailLib';
import { SaleMonthlyOverviewResponse } from '@ydistri/api-sdk';
import DetailSales from '../DetailSales/DetailSales';
import { DEFAULT_SALE_MONTH_COUNT, ProductDetailType } from '../../../../detailTypes';
import { ProductsCollection } from '../../../../../../swagger/collections';
import { setIsLoadingSalesData } from '../../../../detailSlice';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';

const DetailProductBody: React.FC = () => {
  const dispatch = useDispatch();
  const storeId = useSelector(state => state.detailReducer.storeId);
  const productId = useSelector(state => state.detailReducer.productId);
  const regionId = useSelector(state => state.detailReducer.regionId);
  const isLoadingSalesData = useSelector(state => state.detailReducer.isLoadingSalesData);

  const productDetailType = useMemo(
    () => getProductDetailType(productId, regionId, storeId),
    [productId, regionId, storeId],
  );

  const [salesData, setSalesData] = useState<SaleMonthlyOverviewResponse[]>();

  useEffect(() => {
    if (!(productId && storeId === NO_VALUE)) {
      return;
    }

    //TODO: cancel previous request
    (productDetailType === ProductDetailType.REGION && regionId
      ? ProductsCollection.getRegionProductMonthlySalesWithinMonthsRange(
          productId,
          regionId,
          DEFAULT_SALE_MONTH_COUNT,
        )
      : ProductsCollection.getProductMonthlySalesWithinMonthsRange(
          productId,
          DEFAULT_SALE_MONTH_COUNT,
        )
    ).then(({ data }) => {
      setSalesData(data.data);
      dispatch(setIsLoadingSalesData(false));
    });
  }, [dispatch, productDetailType, productId, regionId, storeId]);

  return (
    <DetailSales
      salesData={salesData}
      loadingSalesData={isLoadingSalesData}
      hasForecasts={false}
      showAverageValueInChartTooltip={true}
    />
  );
};

export default DetailProductBody;
