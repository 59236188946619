import { apiSlice, errorTransform, ErrorType } from '../../../../../apis/api';
import { getTags } from '../../../../../apis/apiLib';
import {
  CalculationImportRequest,
  CalculationImportResponse,
  CalculationRecordImportItemResponse,
  CalculationRecordImportSummaryResponse,
  RecordImportItemsRequest,
  RecordImportVerificationRequest,
} from '@ydistri/api-sdk';
import { CalculationsCollection } from '../../../../../swagger/collections';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
} from '@ydistri/ds';

const { TAGS, TAGS_ARRAY } = getTags('calculationRedistributionImport');
const { TAGS: TAGS_CALC } = getTags('calculationCategories');

interface WithCalculationId {
  calculationId: number;
}

export interface CreateCalculationRedistributionImportRequest extends CalculationImportRequest {
  calculationId: number;
}

export interface CreateCalculationRedistributionImportItemsRequest
  extends RecordImportItemsRequest {
  calculationId: number;
}

export interface GetCalculationImportItemsRequest extends InfiniteScrollParams {
  calculationId: number;
}

export interface PutCalculationItemsRequest extends RecordImportVerificationRequest {
  calculationId: number;
}

export interface DeleteCalculationImportRequest
  extends RecordImportVerificationRequest,
    WithCalculationId {}

export const apiCalculationRedistributionImport = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      createCalculationRedistributionImport: builder.mutation<
        CalculationImportResponse,
        CreateCalculationRedistributionImportRequest
      >({
        queryFn: async ({ calculationId, ...payload }) => {
          try {
            console.log(payload);
            const response = await CalculationsCollection.createCalculationImport(calculationId, {
              ...payload,
            });
            const data = response.data.data;
            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: errorTransform(err) };
          }
        },
      }),
      getCalculationRedistributionImport: builder.query<CalculationImportResponse, number>({
        queryFn: async calculationId => {
          try {
            const response = await CalculationsCollection.getCalculationImport(calculationId);
            return { data: response.data.data };
          } catch (err) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const error = err as ErrorType;
            return { error: errorTransform(error) };
          }
        },
        providesTags: [TAGS.redistributionImport],
      }),
      putCalculationRedistributionImportItems: builder.mutation<
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        void,
        CreateCalculationRedistributionImportItemsRequest
      >({
        queryFn: async ({ calculationId, ...payload }) => {
          try {
            const { data } = await CalculationsCollection.putCalculationImportItems(calculationId, {
              ...payload,
            });
            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: errorTransform(err) };
          }
        },
        invalidatesTags: [TAGS.redistributionImportSummary, TAGS.redistributionImportItems],
      }),
      getCalculationRecordImportItems: builder.query<
        CalculationRecordImportItemResponse[] | undefined,
        GetCalculationImportItemsRequest
      >({
        queryFn: async ({ calculationId, ...queryArgs }) => {
          const query = {
            top: queryArgs.top,
            skip: queryArgs.skip,
            inlineCount: true,
            sortings: queryArgs.sortings,
            conditions: queryArgs.conditions,
            search: queryArgs.search,
          };
          const response = await CalculationsCollection.getCalculationImportItems(
            calculationId,
            query,
          );
          return { data: response.data.data };
        },
        providesTags: [TAGS.redistributionImportItems],
        merge: mergeForInfiniteScroll<CalculationRecordImportItemResponse, InfiniteScrollParams>(),
        serializeQueryArgs: args => {
          const conditions = args.queryArgs.conditions;
          let txtConditions = '';
          if (conditions && conditions.length > 0) {
            txtConditions = conditions
              .map(c => `${c.fieldName} ${c.operation} ${c.value}`)
              .join('; ');
          }

          let txtSortings = '';
          if (args.queryArgs.sortings && args.queryArgs.sortings.length > 0) {
            txtSortings = args.queryArgs.sortings
              .map(s => `${s.fieldName} ${s.direction}`)
              .join('; ');
          }
          return `${args.endpointName}(calculationId=${args.queryArgs.calculationId}, conditions=${txtConditions}, search=${
            args.queryArgs.search ?? ''
          }, sortings=${txtSortings})`;
        },
        forceRefetch: forceRefetchForInfiniteScroll<InfiniteScrollParams | undefined>(),
      }),
      getCalculationRecordImportSummary: builder.query<
        CalculationRecordImportSummaryResponse[],
        number
      >({
        queryFn: async calculationId => {
          const response = await CalculationsCollection.getCalculationImportSummary(calculationId);
          return { data: response.data.data };
        },
        providesTags: [TAGS.redistributionImportSummary],
      }),
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      runCalculationImport: builder.mutation<void, PutCalculationItemsRequest>({
        queryFn: async ({ calculationId, ...payload }) => {
          try {
            const { data } = await CalculationsCollection.putCalculationItems(calculationId, {
              ...payload,
            });

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: [
          {
            type: TAGS_CALC.calculationCategoryPairings,
            id: 'LIST',
          },
          TAGS.redistributionImport,
          TAGS.redistributionImportSummary,
          TAGS.redistributionImportItems,
        ],
      }),
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      deleteCalculationImport: builder.mutation<void, DeleteCalculationImportRequest>({
        queryFn: async ({ calculationId, ...payload }) => {
          try {
            const { data } = await CalculationsCollection.deleteCalculationImport(calculationId, {
              ...payload,
            });

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: [
          TAGS.redistributionImportSummary,
          TAGS.redistributionImportItems,
          TAGS.redistributionImport,
        ],
      }),
    }),
  });

export const {
  useCreateCalculationRedistributionImportMutation,
  usePutCalculationRedistributionImportItemsMutation,
  useGetCalculationRecordImportItemsQuery,
  useGetCalculationRecordImportSummaryQuery,
  useRunCalculationImportMutation,
  useDeleteCalculationImportMutation,
  useGetCalculationRedistributionImportQuery,
} = apiCalculationRedistributionImport;
