import React from 'react';
import LoadingIndicator, { LoadingIndicatorProps } from '../LoadingIndicator/LoadingIndicator';
import { styled } from 'styled-components';

const FullPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingFullPage: React.FC<LoadingIndicatorProps> = props => {
  return (
    <FullPage>
      <LoadingIndicator {...props} />
    </FullPage>
  );
};

export default LoadingFullPage;
