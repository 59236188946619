import React, { useMemo } from 'react';
import {
  computeRemSize,
  Section,
  Text,
  Title,
  BarLine,
  BarLineSizes,
  Column,
  Row,
  BarLinePartitionProps,
  marginPopoverContent,
} from '@ydistri/ds';
import useCategoryId from 'apps/ydistri/src/app/hooks/useCategoryId';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import {
  GetCategoryInsightsParams,
  useGetCategoryInsightsQuery,
} from '../../../../components/global/CategoryInsights/apiCategoryInsights';
import { Spin } from 'antd';
import {
  computeAnnualRevenueInStockPrice,
  computePercentage,
  computeTurnover,
  formatPercentage,
  shortenNumber,
} from '@ydistri/utils';
import { useCurrency } from '../../../../hooks/useCurrency';
import { InfoIconTooltip } from '../../../../components/icons/InfoIconTooltip';

interface CategorySummary {
  availableSupply: number;
  availableSupplyPercentage: number;
  annualRevenue: number;
  annualRevenuePercentage: number;
  margin: number;
  turnover: number;
}

const createSummaryBarData = (percentage: number): BarLinePartitionProps[] => {
  return [
    {
      percentage: percentage,
      minSizeToDisplayContent: 16,
      color: 'rgba(243, 152, 25, 1)',
      highlightedPercentage: 0,
    },
    {
      percentage: 100 - percentage,
      minSizeToDisplayContent: 16,
      color: 'rgba(243, 152, 25, 0.2)',
      highlightedPercentage: 100,
    },
  ];
};

const columnFlexBasis = {
  flexBasis: computeRemSize(170),
};

const sectionCategoryInsightsRow = {
  minHeight: computeRemSize(80),
  paddingTop: '2.2rem',
};

const turnoverMarginTop = { marginTop: '-2.2rem' };

const SegmentCategorySummary: React.FC = () => {
  const categoryId = useCategoryId();
  const templateOrCalculation = useTemplateOrCalculation();
  const currency = useCurrency();

  const categoryInsightsParam: GetCategoryInsightsParams = useMemo(
    () => ({
      categoryId,
      templateOrCalculation,
    }),
    [categoryId, templateOrCalculation],
  );

  const rootCategoryInsightsParam: GetCategoryInsightsParams = useMemo(
    () => ({
      categoryId: 1,
      templateOrCalculation,
    }),
    [templateOrCalculation],
  );

  const { data: rootCategoryData, isFetching: isRootCategoryInsightsLoading } =
    useGetCategoryInsightsQuery(rootCategoryInsightsParam);
  const { data: categoryData, isFetching: isCategoryInsightsLoading } =
    useGetCategoryInsightsQuery(categoryInsightsParam);

  const data: CategorySummary = useMemo(() => {
    const rootAvailableSupply = rootCategoryData?.attributes.AvailableSupplyValue ?? 0;
    const rootAnnualRevenue = rootCategoryData?.attributes.AnnualRevenueSalePriceValue ?? 0;

    const availableSupply = categoryData?.attributes.AvailableSupplyValue ?? 0;
    const annualRevenue = categoryData?.attributes.AnnualRevenueSalePriceValue ?? 0;
    const margin = categoryData?.attributes.Margin ?? 0;

    const annualRevenueInStockPrice = computeAnnualRevenueInStockPrice(annualRevenue, margin);
    const turnover = computeTurnover(annualRevenueInStockPrice, availableSupply);

    const availableSupplyPercentage = computePercentage(availableSupply, rootAvailableSupply, 2);
    const annualRevenuePercentage = computePercentage(annualRevenue, rootAnnualRevenue, 2);

    return {
      availableSupply,
      availableSupplyPercentage,
      annualRevenue,
      annualRevenuePercentage,
      margin,
      turnover,
    };
  }, [
    rootCategoryData?.attributes.AvailableSupplyValue,
    rootCategoryData?.attributes.AnnualRevenueSalePriceValue,
    categoryData?.attributes.AvailableSupplyValue,
    categoryData?.attributes.AnnualRevenueSalePriceValue,
    categoryData?.attributes.Margin,
  ]);

  const availableSupplyBarData = useMemo(
    () => createSummaryBarData(data.availableSupplyPercentage),
    [data.availableSupplyPercentage],
  );

  const annualRevenueBarData = useMemo(
    () => createSummaryBarData(data.annualRevenuePercentage),
    [data.annualRevenuePercentage],
  );

  return (
    <Section header="Category insights" $contentMarginTop={false} $ratio={0}>
      <Spin spinning={isCategoryInsightsLoading || isRootCategoryInsightsLoading}>
        <Row $gap="2rem" $justifyContent="space-evenly" $padding={0} style={turnoverMarginTop}>
          <Row
            $flexGrow={3}
            $alignSelf="stretch"
            $gap="2rem"
            $justifyContent="space-evenly"
            $padding={0}
            style={sectionCategoryInsightsRow}
          >
            <Column style={columnFlexBasis} $alignItems="center" $justifyContent="center">
              <BarLine size={BarLineSizes.SMALL} data={availableSupplyBarData} dividers={false} />
              <Title $size="xLarge">{shortenNumber(data.availableSupply)}</Title>
              <Row $alignItems="center" $justifyContent="center" $gap="0.5rem">
                <Text $size="large">inventory ({currency})</Text>
              </Row>
            </Column>
            <Column style={columnFlexBasis} $alignItems="center" $justifyContent="center">
              <BarLine size={BarLineSizes.SMALL} data={annualRevenueBarData} dividers={false} />
              <Title $size="xLarge">{shortenNumber(data.annualRevenue)}</Title>
              <Row $alignItems="center" $justifyContent="center" $gap="0.5rem">
                <Text $size="large">annual revenue ({currency})</Text>
                <InfoIconTooltip content="In sale price without VAT" />
              </Row>
            </Column>
          </Row>
          <Column $flexGrow={1} $alignItems="center" $justifyContent="center" $gap="2rem">
            <Column $alignItems="center" $justifyContent="center">
              <Title $size="large">{data.turnover} month</Title>
              <Row $alignItems="center" $justifyContent="center" $gap="0.5rem">
                <Text $size="large">turnover</Text>
                <InfoIconTooltip content="Turnover = Available Supply in cost price / (Annual Cost of Goods Sold / 12 months)" />
              </Row>
            </Column>
            <Column $alignItems="center" $justifyContent="center">
              <Title $size="large">{formatPercentage(data.margin * 100, 1)}</Title>
              <Row $alignItems="center" $justifyContent="center" $gap="0.5rem">
                <Text $size="large">margin</Text>
                <InfoIconTooltip content={marginPopoverContent} />
              </Row>
            </Column>
          </Column>
        </Row>
      </Spin>
    </Section>
  );
};

export default SegmentCategorySummary;
