import { DisplayEntityIdType } from '@ydistri/api-sdk';

/**
 * Format store label based on the requested display type.
 * Label is usually a combination of id and name or code and name.
 * In some cases, the id already part of the name so it should
 * be displayed as is.
 * @param displayType Type used to display store id
 * @param storeName
 * @param storeCustomerId
 * @param storeCode
 */
export const formatStoreLabel = (
  displayType: DisplayEntityIdType,
  storeName: string,
  storeCustomerId?: string | null,
  storeCode?: string | null,
): string => {
  switch (displayType) {
    case DisplayEntityIdType.CustomerId:
      if (storeCustomerId) {
        return formatLabel(storeCustomerId, storeName);
      } else {
        return storeName;
      }
    case DisplayEntityIdType.Code:
      if (storeCode) {
        return formatLabel(storeCode, storeName);
      } else {
        return storeName;
      }
    case DisplayEntityIdType.None:
      return storeName;
  }
};

/**
 * Formatter function to format the label in unified format.
 * @param name
 * @param id
 */
const formatLabel = (id: string, name: string) => {
  return `${id} - ${name}`;
};
