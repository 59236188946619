import React from 'react';
import PickingIssuesTable from './table/PickingIssuesTable';
import PickingIssuesExport from './export/PickingIssuesExport';
import { Column, Row } from '@ydistri/ds';

const PickingIssues: React.FC = () => {
  return (
    <Column $gap="1rem" $flexGrow={1}>
      <Row $justifyContent="right">
        <PickingIssuesExport />
      </Row>
      <PickingIssuesTable />
    </Column>
  );
};

export default PickingIssues;
