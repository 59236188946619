import React from 'react';
import { DateRange, DateRangeData } from './dailyTransactionsChartLib';
import { Dayjs } from 'dayjs';
import { Chart as ChartJS } from 'chart.js';

export const chartScrollZoomHandler =
  (
    setDateRangeData: React.Dispatch<React.SetStateAction<DateRangeData>>,
    chartRef?: React.RefObject<ChartJS<'line'>>,
  ): React.WheelEventHandler<HTMLCanvasElement> =>
  (e: React.WheelEvent<HTMLCanvasElement>) => {
    if (chartRef) {
      const width = chartRef.current?.width ?? 1;
      const scrollPosition = e.nativeEvent.offsetX;
      const ratio = scrollPosition / width;

      const getScrollDiffs = (currentRange: DateRange): number => {
        return Math.max(Math.ceil((currentRange.max.diff(currentRange.min, 'day') - 30) / 7), 2);
      };

      const stayInRange = (
        newMin: Dayjs,
        newMax: Dayjs,
        minMaxRange: DateRange,
        lastChartRange: DateRange,
      ): DateRange => {
        //no zooming in case the range is less than 30 days
        if (minMaxRange.max.diff(minMaxRange.min, 'day') <= 30) {
          return { ...minMaxRange };
        } else if (newMax.diff(newMin, 'day') <= 30) {
          return { ...lastChartRange };
        }

        newMin = newMin.isBefore(minMaxRange.min) ? minMaxRange.min : newMin;
        newMax = newMax.isAfter(minMaxRange.max) ? minMaxRange.max : newMax;

        return {
          min: newMin,
          max: newMax,
        };
      };

      if (e.deltaY > 0) {
        //zoom out

        setDateRangeData(prev => {
          const scrollDiff = getScrollDiffs(prev.chart);
          return {
            ...prev,
            chart: stayInRange(
              prev.chart.min.subtract(Math.floor(scrollDiff * ratio), 'day'),
              prev.chart.max.add(Math.floor(scrollDiff * (1 - ratio)), 'day'),
              prev.data,
              prev.chart,
            ),
          };
        });
      } else {
        //zoom in
        setDateRangeData(prev => {
          const scrollDiff = getScrollDiffs(prev.chart);
          return {
            ...prev,
            chart: stayInRange(
              prev.chart.min.add(Math.floor(scrollDiff * ratio), 'day'),
              prev.chart.max.subtract(Math.floor(scrollDiff * (1 - ratio)), 'day'),
              prev.data,
              prev.chart,
            ),
          };
        });
      }
    }
  };
