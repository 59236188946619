import { LabeledToggle, LabelPlacement, ToggleProps } from '@ydistri/ds';
import React, { useMemo } from 'react';

interface ShowArchivedItemsToggleProps extends ToggleProps {
  archivedItemsCount?: number;
}

const ShowArchivedItemsToggle: React.FC<ShowArchivedItemsToggleProps> = ({
  archivedItemsCount,
  ...props
}) => {
  const label = useMemo(() => {
    const base = 'Show archived';
    if (archivedItemsCount) {
      return `${base} (${archivedItemsCount})`;
    } else {
      return base;
    }
  }, [archivedItemsCount]);

  return (
    <LabeledToggle
      label={label}
      {...props}
      id="ShowArchivedItemsToggle"
      labelPlacement={LabelPlacement.RIGHT}
    />
  );
};

export default ShowArchivedItemsToggle;
