import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../store';
import React, { useCallback, useMemo, useState } from 'react';
import { ActionsBar, Button, Section, Text } from '@ydistri/ds';
import SubordinateCalculationSelector from './SubordinateCalculationSelector';
import { useTemplateOrCalculation } from '../../../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../../../../../apiCalculationDetail';
import {
  mergeCalculationWithSubordinateCalculations,
  SubcalculationSelectorCalculationData,
} from './subordinateCalculationSelectorLib';

interface SubordinateCalculationSelectorSectionProps {
  sourceSkuId: number;
  targetSkuId: number;
}

export const SUBCALCULATION_SELECTOR_TABLE_ID = 'subcalculation-selector';

const SubordinateCalculationSelectorSection: React.FC<
  SubordinateCalculationSelectorSectionProps
> = ({ sourceSkuId, targetSkuId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculation, isFetching: calculationFetching } = useGetCalculationQuery(
    templateOrCalculation.id,
  );

  const data: SubcalculationSelectorCalculationData[] = useMemo(
    () => mergeCalculationWithSubordinateCalculations(calculation),
    [calculation],
  );

  const selectedCalculationId = useSelector(
    (state: ReduxState) =>
      state.calculations.selectedRowKeys[SUBCALCULATION_SELECTOR_TABLE_ID]?.arr[0],
  );

  const selectedCalculation = useMemo(
    () => data.find(c => c.id === selectedCalculationId),
    [data, selectedCalculationId],
  );

  const buttonClickHandler = useCallback(() => {
    setOpen(p => !p);
  }, []);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <Text>
          Currently showing configuration from <Text $bold>{selectedCalculation?.title}</Text>
        </Text>
        <Button $sizeVariant="small" onClick={buttonClickHandler}>
          {open ? 'Close' : 'Change'}
        </Button>
      </ActionsBar>
    );
  }, [buttonClickHandler, open, selectedCalculation?.title]);

  return (
    <Section
      header="Configurations"
      headerActionsJustifyContent="start"
      headerActions={headerActions}
      collapsible={true}
      open={open}
      onOpen={setOpen}
    >
      <SubordinateCalculationSelector sourceSkuId={sourceSkuId} targetSkuId={targetSkuId} />
    </Section>
  );
};

export default SubordinateCalculationSelectorSection;
