import React from 'react';
import { computeRemSize, YColumnsType } from '@ydistri/ds';
import ScopeConfigurationField from './ScopeConfigurationField';
import { StoreDepartmentTableRow } from './StoreDepartmentTable';
import { ConfigurationRuleSetupType } from '@ydistri/api-sdk';
import { Popover } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import { ColumnsType } from 'antd/es/table';

const iconLeftMargin = { marginLeft: '0.8rem' };

const renderUseAsSource = (
  record: StoreDepartmentTableRow,
  useClosing: boolean,
  useSuperTarget: boolean,
): React.ReactNode => {
  if (useClosing && record[ConfigurationRuleSetupType.IsClosing]) {
    return (
      <Popover content="Use as source is always ON for closing stores">
        <AiOutlineCheck color="green" size={computeRemSize(18)} style={iconLeftMargin} />
      </Popover>
    );
  }
  if (useSuperTarget && record[ConfigurationRuleSetupType.IsSuperTarget]) {
    return (
      <Popover content="Use as source is always OFF for super target stores">
        <ImCross color="grey" size={computeRemSize(18)} style={iconLeftMargin} />
      </Popover>
    );
  }

  return (
    <ScopeConfigurationField
      value={record[ConfigurationRuleSetupType.UseAsSource]}
      field={ConfigurationRuleSetupType.UseAsSource}
      disabledFinal={true}
    />
  );
};

const renderUseAsTarget = (
  record: StoreDepartmentTableRow,
  useClosing: boolean,
): React.ReactNode => {
  if (useClosing && record[ConfigurationRuleSetupType.IsClosing]) {
    return (
      <Popover content="Use as target is always OFF for closing stores">
        <ImCross color="grey" size={computeRemSize(18)} style={iconLeftMargin} />
      </Popover>
    );
  }

  return (
    <ScopeConfigurationField
      value={record[ConfigurationRuleSetupType.UseAsTarget]}
      field={ConfigurationRuleSetupType.UseAsTarget}
      disabledFinal={true}
    />
  );
};

const sortUseAsSource = (
  a: StoreDepartmentTableRow,
  b: StoreDepartmentTableRow,
  useIsClosing: boolean,
  useSuperTarget: boolean,
) => {
  const getVal = (record: StoreDepartmentTableRow): number => {
    if (useIsClosing && record[ConfigurationRuleSetupType.IsClosing]) return 2;
    if (useSuperTarget && record[ConfigurationRuleSetupType.IsSuperTarget]) return -1;
    if (record[ConfigurationRuleSetupType.UseAsSource]) return 1;
    return 0;
  };
  return getVal(a) - getVal(b);
};

const sortUseAsTarget = (
  a: StoreDepartmentTableRow,
  b: StoreDepartmentTableRow,
  useIsClosing: boolean,
) => {
  const getVal = (record: StoreDepartmentTableRow): number => {
    if (useIsClosing && record[ConfigurationRuleSetupType.IsClosing]) return -1;
    if (record[ConfigurationRuleSetupType.UseAsTarget]) return 1;
    return 0;
  };
  return getVal(a) - getVal(b);
};

const baseConfigSorter = (
  a: StoreDepartmentTableRow,
  b: StoreDepartmentTableRow,
  config: ConfigurationRuleSetupType,
) => {
  const getVal = (record: StoreDepartmentTableRow): number => {
    const val = record[config];
    if (!val) return 0;
    if (val === true) return 1;
    return val;
  };
  return getVal(a) - getVal(b);
};

export const getStoreDepartmentConfigurationColumns = (
  useIsClosing: boolean,
  useSuperTarget: boolean,
  hasDepartments: boolean,
  storeColumns: ColumnsType<StoreDepartmentTableRow>,
): YColumnsType<StoreDepartmentTableRow>[] => {
  const response: YColumnsType<StoreDepartmentTableRow>[] = [...storeColumns];

  if (hasDepartments) {
    response.push({
      title: 'Department',
      dataIndex: 'departmentName',
      key: 'departmentName',
      sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
      filterSearch: true,
      filters: [],
      showSorterTooltip: false,
      apiFilterable: true,
      apiFilterType: 'text',
    });
  }

  response.push({
    title: 'Use as source',
    dataIndex: ConfigurationRuleSetupType.UseAsSource,
    key: 'useAsSource',
    width: computeRemSize(80),
    align: 'left',
    showSorterTooltip: false,
    sorter: (a, b) => sortUseAsSource(a, b, useIsClosing, useSuperTarget),
    render: (_: boolean, record: StoreDepartmentTableRow) =>
      renderUseAsSource(record, useIsClosing, useSuperTarget),
    apiFilterable: true,
    apiFilterType: 'boolean',
  });

  response.push({
    title: 'Use as target',
    dataIndex: ConfigurationRuleSetupType.UseAsTarget,
    key: 'useAsTarget',
    width: computeRemSize(80),
    align: 'left',
    showSorterTooltip: false,
    sorter: (a, b) => sortUseAsTarget(a, b, useIsClosing),
    render: (_: boolean, record: StoreDepartmentTableRow) =>
      renderUseAsTarget(record, useIsClosing),
    apiFilterable: true,
    apiFilterType: 'boolean',
  });

  response.push({
    title: 'Max. incoming routes',
    dataIndex: ConfigurationRuleSetupType.MaxNumberOfSourceStores,
    key: 'maxNumberOfSourceStores',
    width: computeRemSize(140),
    align: 'center',
    showSorterTooltip: false,
    sorter: (a, b) => baseConfigSorter(a, b, ConfigurationRuleSetupType.MaxNumberOfSourceStores),
    render: (value: number) => {
      return (
        <ScopeConfigurationField
          value={value}
          field={ConfigurationRuleSetupType.MaxNumberOfSourceStores}
          disabledFinal={true}
        />
      );
    },
    apiFilterable: true,
    apiFilterType: 'number',
  });

  response.push({
    title: 'Max. outgoing routes',
    dataIndex: ConfigurationRuleSetupType.MaxNumberOfTargetStores,
    key: 'maxNumberOfTargetStores',
    width: computeRemSize(140),
    align: 'center',
    showSorterTooltip: false,
    sorter: (a, b) => baseConfigSorter(a, b, ConfigurationRuleSetupType.MaxNumberOfTargetStores),
    render: (value: number) => {
      return (
        <ScopeConfigurationField
          value={value}
          field={ConfigurationRuleSetupType.MaxNumberOfTargetStores}
          disabledFinal={true}
        />
      );
    },
    apiFilterable: true,
    apiFilterType: 'number',
  });

  response.push({
    title: 'Max. outgoing picking positions',
    dataIndex: ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions,
    key: 'maxNumberOfDistinctProducts',
    width: computeRemSize(140),
    align: 'center',
    showSorterTooltip: false,
    sorter: (a, b) =>
      baseConfigSorter(a, b, ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions),
    render: (value: number) => {
      return (
        <ScopeConfigurationField
          value={value}
          field={ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions}
          disabledFinal={true}
        />
      );
    },
    apiFilterable: true,
    apiFilterType: 'number',
  });

  response.push({
    title: 'Minimum route value',
    dataIndex: ConfigurationRuleSetupType.MinimumRouteValue,
    key: 'minimalPackageValue',
    width: computeRemSize(140),
    align: 'center',
    showSorterTooltip: false,
    sorter: (a, b) => baseConfigSorter(a, b, ConfigurationRuleSetupType.MinimumRouteValue),
    render: (value: number) => {
      return (
        <ScopeConfigurationField
          value={value}
          field={ConfigurationRuleSetupType.MinimumRouteValue}
          disabledFinal={true}
        />
      );
    },
    apiFilterable: true,
    apiFilterType: 'number',
  });

  response.push({
    title: 'Enabled',
    dataIndex: ConfigurationRuleSetupType.IsEnabled,
    key: 'isEnabled',
    width: computeRemSize(80),
    align: 'center',
    showSorterTooltip: false,
    sorter: (a, b) => baseConfigSorter(a, b, ConfigurationRuleSetupType.IsEnabled),
    render: (value: boolean) => {
      return (
        <ScopeConfigurationField
          value={value}
          field={ConfigurationRuleSetupType.IsEnabled}
          disabledFinal={true}
        />
      );
    },
  });

  if (useIsClosing) {
    response.push({
      title: 'Is Closing',
      dataIndex: ConfigurationRuleSetupType.IsClosing,
      key: 'isClosing',
      width: computeRemSize(80),
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => baseConfigSorter(a, b, ConfigurationRuleSetupType.IsClosing),
      render: (value: boolean) => {
        return (
          <ScopeConfigurationField
            value={value}
            field={ConfigurationRuleSetupType.IsClosing}
            disabledFinal={true}
          />
        );
      },
    });
  }

  if (useSuperTarget) {
    response.push({
      title: 'Is Super target',
      dataIndex: ConfigurationRuleSetupType.IsSuperTarget,
      key: 'isSuperTarget',
      width: computeRemSize(80),
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => baseConfigSorter(a, b, ConfigurationRuleSetupType.IsSuperTarget),
      render: (value: boolean) => {
        return (
          <ScopeConfigurationField
            value={value}
            field={ConfigurationRuleSetupType.IsSuperTarget}
            disabledFinal={true}
          />
        );
      },
    });
  }

  response.push({
    title: 'Store type',
    dataIndex: 'storeTypeName',
    key: 'storeTypeName',
    showSorterTooltip: false,
    width: computeRemSize(170),
    ellipsis: true,
    sorter: (a, b) => a.storeTypeName.localeCompare(b.storeTypeName),
    hideable: true,
    apiFilterable: true,
    apiFilterType: 'text',
  });

  response.push({
    title: 'Region',
    dataIndex: 'regionName',
    key: 'regionName',
    showSorterTooltip: false,
    width: computeRemSize(170),
    ellipsis: true,
    sorter: (a, b) => a.regionName.localeCompare(b.regionName),
    hideable: true,
    apiFilterable: true,
    apiFilterType: 'text',
  });

  return response;
};
