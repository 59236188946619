import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setSubpage } from '../../../routes/routerSlice';
import {
  ConfigurationSwitch,
  RowCentered,
  Section,
  MainContent,
  computeFontSize,
  GridCol,
  GridRow,
} from '@ydistri/ds';
import BalancingFieldsTable from './BalancingFieldsTable';
import { ConfigurationSubpage } from '../Configuration';
import { BalancingGroupKey, BalancingVersions, versionedBalancingGroups } from './balancingLib';
import { useGetBalancingConfigurationQuery } from './apiBalancingConfiguration';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  UpdateCalculationConfigurationPayload,
  useGetCalculationConfigurationQuery,
  useUpdateCalculationConfigurationMutation,
} from '../../../apis/apiGlobalConfiguration';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import { useUser } from '../../../hooks/useUser';

const ConfigurationBalancing: React.FC = () => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const currentBreakpoint = useBreakpoint();
  const user = useUser();

  const { data: balancingData, isFetching } =
    useGetBalancingConfigurationQuery(templateOrCalculation);
  const { data: calculationConfiguration, isFetching: isFetchingCalculationConfiguration } =
    useGetCalculationConfigurationQuery(templateOrCalculation);
  const [updateConfiguration, updateConfigurationStatus] =
    useUpdateCalculationConfigurationMutation();

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.ADVANCED));
  }, [dispatch]);

  const onChangeExperimentalModeHandler = useCallback(
    (value: string) => {
      const payload: UpdateCalculationConfigurationPayload = {
        templateOrCalculation,
        request: {
          isExperimentConfigurationsEnabled: value === '1',
        },
      };

      updateConfiguration(payload);
    },
    [templateOrCalculation, updateConfiguration],
  );

  const status = useMemo(() => {
    return isFetching || isFetchingCalculationConfiguration
      ? ConfigurationFieldStatus.LOADING
      : rtkQueryPatchStatusToConfigurationFieldStatus(updateConfigurationStatus);
  }, [updateConfigurationStatus, isFetching, isFetchingCalculationConfiguration]);

  const version = balancingData?.version;
  const isExperimentalConfigEnabled =
    calculationConfiguration?.isExperimentConfigurationsEnabled ?? false;

  const headerActionForEnabledSection = useMemo(
    () =>
      version ? (
        <RowCentered $gap="1rem">
          Enable advanced configurations
          <ConfigurationSwitch
            disabled={templateOrCalculation.type === 'Calculation' || !user?.isSuperUser}
            status={status}
            value={isExperimentalConfigEnabled}
            onChange={onChangeExperimentalModeHandler}
          />
        </RowCentered>
      ) : (
        'This version was not recognized'
      ),
    [
      version,
      templateOrCalculation.type,
      user?.isSuperUser,
      status,
      isExperimentalConfigEnabled,
      onChangeExperimentalModeHandler,
    ],
  );

  const balancingGroups = versionedBalancingGroups[version ?? BalancingVersions.V_UNKNOWN];
  const balancingGroupKeys = useMemo(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    () => Object.keys(balancingGroups) as unknown as BalancingGroupKey[],
    [balancingGroups],
  );
  const mainGroupKey = balancingGroupKeys.find(gk => balancingGroups[gk]?.isMainGroup);
  const mainGroup = mainGroupKey ? balancingGroups[mainGroupKey] : undefined;

  if (isFetching) return <LoadingIndicator />;

  return (
    <>
      <Helmet title="Advanced configuration" />
      <MainContent>
        <Section
          header="Advanced configurations"
          headerActions={headerActionForEnabledSection}
          $ratio={0}
        />
        {version && balancingGroups && isExperimentalConfigEnabled && (
          <GridRow>
            {mainGroup && (
              <GridCol
                span={24}
                xl={8}
                style={currentBreakpoint.xl ? { minHeight: '100%' } : undefined}
              >
                <Section
                  header={mainGroup.header}
                  key={mainGroup.id}
                  $ratio={1}
                  $height={currentBreakpoint.xl ? '100%' : undefined}
                >
                  <BalancingFieldsTable groups={balancingGroups} groupId={mainGroup.id} />
                </Section>
              </GridCol>
            )}
            <GridCol span={24} xl={mainGroup ? 16 : 24}>
              <GridRow gutter={[0, computeFontSize()]}>
                {Object.keys(balancingGroups).map(g => {
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  const groupKey = g as unknown as BalancingGroupKey;
                  const group = balancingGroups[groupKey];
                  if (!group || groupKey === mainGroup?.id) return null;

                  return (
                    <GridCol span={24} md={12} xl={mainGroup ? 12 : 8} key={group.id}>
                      <Section header={group.header} $height="100%">
                        <BalancingFieldsTable groups={balancingGroups} groupId={group.id} />
                      </Section>
                    </GridCol>
                  );
                })}
              </GridRow>
            </GridCol>
          </GridRow>
        )}
      </MainContent>
    </>
  );
};

export default ConfigurationBalancing;
