import React from 'react';
import { useParams } from 'react-router-dom';

const CalculationDetailSubpage: React.FC = () => {
  const { calculationId } = useParams();

  return <div>This is Calculation detail subpage - {calculationId}</div>;
};

export default CalculationDetailSubpage;
