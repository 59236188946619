import { useTheme } from 'styled-components';
import React, { useCallback } from 'react';
import ToggleIconButton from '../../../../../../components/buttons/ToggleIconButton';
import { getForecastApproachLabel } from '@ydistri/utils';

interface ForecastConfidenceButtonProps {
  option: number;
  onClicked: (option: number) => void;
  selected: boolean;
}

const ForecastConfidenceButton: React.FC<ForecastConfidenceButtonProps> = ({
  option,
  onClicked,
  selected,
}) => {
  const theme = useTheme();

  const clickHandler = useCallback(() => {
    onClicked(option);
  }, [option, onClicked]);

  return (
    <ToggleIconButton
      onClick={clickHandler}
      size="small"
      checked={selected}
      colorScheme={theme.iconColorSchemes.none}
    >
      {getForecastApproachLabel(option, 'shortcut')}
    </ToggleIconButton>
  );
};

export default ForecastConfidenceButton;
