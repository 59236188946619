import { computeRemSize, ReadOnlyItem, Row, SectionHeader } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import { JSX } from 'react/jsx-runtime';
import { isSameId } from '../../../../../../lib/utils/utils';
import { SKU_ID_LABEL, SYSTEM_ID_LABEL } from '../../../../../../components/global/_constants';
import LinkIcon from '../../../../../../components/global/Link/LinkIcon';

const HeaderWrapper = styled(Row)`
  min-height: ${computeRemSize(26)};
`;

interface CalculationRedistributionSectionHeaderProps {
  prefix?: string;
  content: string;
  customerId?: string | null;
  systemId?: number;
  skuId?: number;
  code?: string;
  link?: string;
}

const CalculationRedistributionSectionHeader: React.FC<
  CalculationRedistributionSectionHeaderProps
> = ({ content, prefix, systemId, customerId, skuId, code, link }) => {
  const renderIds = () => {
    const result: JSX.Element[] = [];
    if (customerId && systemId) {
      result.push(<ReadOnlyItem key="id" label="Id" value={customerId} />);
      if (!isSameId(systemId, customerId)) {
        result.push(
          <ReadOnlyItem key="system-id" label={SYSTEM_ID_LABEL} value={systemId.toString()} />,
        );
      }
    }

    if (code) {
      result.push(<ReadOnlyItem key="code" label="Code" value={code} />);
    }

    if (skuId) {
      result.push(<ReadOnlyItem key="sku-id" label={SKU_ID_LABEL} value={skuId.toString()} />);
    }

    return result;
  };

  const sectionHeaderContent = useMemo(() => {
    const sectionTitle = prefix ? `${prefix}: ${content}` : content;
    if (link) {
      return (
        <Row $alignItems="center" $gap="1rem">
          {sectionTitle}
          {link && <LinkIcon url={link} />}
        </Row>
      );
    } else {
      return sectionTitle;
    }
  }, [content, link, prefix]);

  return (
    <HeaderWrapper $gap="1rem" $alignItems="center">
      <SectionHeader>{sectionHeaderContent}</SectionHeader>
      {renderIds()}
    </HeaderWrapper>
  );
};

export default CalculationRedistributionSectionHeader;
