import React, { useEffect, useMemo, useState } from 'react';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import { computeRemSize, INFINITE_PAGE_SIZE, InfiniteScrollTable } from '@ydistri/ds';
import {
  CalculationRecordImportItemResponse,
  Condition,
  LogicalOperator,
  Operation,
  RecordImportAction,
} from '@ydistri/api-sdk';
import {
  GetCalculationImportItemsRequest,
  useGetCalculationRecordImportItemsQuery,
} from '../../../apiCalculationRedistributionImport';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../../../../hooks/useEntityTableColumnsProvider';
import { useColumnWidth } from '../../../../../../../../hooks/useColumnWidth';
import ScrolledTableWrapper from '../../../../../../../../components/domain/SKUTable/ScrolledTableWrapper';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../../store';

const createActionFilterConditions = (importActionFilters: RecordImportAction[]): Condition[] => {
  const logicalOperator = importActionFilters.length > 1 ? LogicalOperator.Or : LogicalOperator.And;
  return importActionFilters.map(importActionFilter => ({
    fieldName: 'RecordImportActionCode',
    operation: Operation.Eq,
    value: importActionFilter.toString(),
    logicalOperator: logicalOperator,
  }));
};

const RedistributionImportItemsTable: React.FC = () => {
  const calculation = useTemplateOrCalculation();
  const productColumnsProvider = useProductTableColumnsProvider();
  const storeColumnsProvider = useStoreTableColumnsProvider();
  const { productIdColumnWidth } = useColumnWidth();

  const importActionFilter = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.importActionFilter,
  );

  const [params, setParams] = useState<GetCalculationImportItemsRequest>({
    calculationId: calculation.id,
    skip: 0,
    top: INFINITE_PAGE_SIZE,
  });

  useEffect(() => {
    setParams(prevParams => {
      //other conditions may have been set by the table, we need to keep them
      const otherConditions =
        prevParams.conditions?.filter(
          condition => condition.fieldName !== 'RecordImportActionCode',
        ) ?? [];
      const newConditions = [
        ...otherConditions,
        ...createActionFilterConditions(importActionFilter),
      ];

      return {
        ...prevParams,
        skip: 0,
        top: INFINITE_PAGE_SIZE,
        conditions: newConditions,
      };
    });
  }, [importActionFilter]);

  const { data } = useGetCalculationRecordImportItemsQuery(params);

  const columns = useMemo(() => {
    const productColumnsConfig: EntityColumnsConfig<CalculationRecordImportItemResponse> = {
      [EntityColumns.ID]: {
        width: productIdColumnWidth,
      },
      [EntityColumns.NAME]: {},
      [EntityColumns.CODE]: {
        width: computeRemSize(100),
      },
    };
    const productColumns =
      productColumnsProvider<CalculationRecordImportItemResponse>(productColumnsConfig);

    const storeColumnsConfig: EntityColumnsConfig<CalculationRecordImportItemResponse> = {
      [EntityColumns.ID]: {
        width: computeRemSize(160),
      },
      [EntityColumns.NAME]: {},
      [EntityColumns.CODE]: {
        width: computeRemSize(100),
      },
    };
    const sourceStoreColumns = storeColumnsProvider<CalculationRecordImportItemResponse>(
      storeColumnsConfig,
      'sourceStore',
      'Source store',
    );

    const targetStoreColumns = storeColumnsProvider<CalculationRecordImportItemResponse>(
      storeColumnsConfig,
      'targetStore',
      'Target store',
    );

    return [
      {
        title: 'Sequence',
        dataIndex: 'sequence',
        width: computeRemSize(80),
        key: 'sequence',
      },
      ...productColumns,
      ...sourceStoreColumns,
      ...targetStoreColumns,
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        width: computeRemSize(80),
        apiFilterable: true,
        apiFilterType: 'number',
        apiColumnName: 'Quantity',
        key: 'quantity',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: computeRemSize(160),
        key: 'description',
      },
      {
        title: 'Action',
        dataIndex: 'recordImportActionCode',
        width: '6rem',
        render: (value: string) => {
          if (value === RecordImportAction.CreateSkuAndInsert.toString()) {
            return 'Insert';
          } else {
            return value;
          }
        },
      },
    ];
  }, [productColumnsProvider, productIdColumnWidth, storeColumnsProvider]);

  return (
    <ScrolledTableWrapper offset={140}>
      <InfiniteScrollTable<CalculationRecordImportItemResponse, GetCalculationImportItemsRequest>
        id="redistribution-import-items"
        rowKey="sequence"
        columns={columns}
        dataSource={data}
        setParams={setParams}
      />
    </ScrolledTableWrapper>
  );
};

export default RedistributionImportItemsTable;
