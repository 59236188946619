import React, { useMemo } from 'react';
import { SaleMonthlyOverviewResponse } from '@ydistri/api-sdk';
import { ChartData, ChartDataset } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { TLineType, TParsedTransactions } from '../../../../../../../lib/charts/saleChartsTypes';
import {
  addSalesUsedForForecastToSaleTypes,
  changeFillInLineTypesBasedOnHiddenLines,
  copyLineTypeToParsedMonthlyTransactionsByAnotherLineType,
  createChartDataFromDatasetsAndParsedMonthlyTransactions,
  createDatasetsFromLineTypesAndGraphLines,
  getForecastLineDefinitions,
  getSaleLineDefinitions,
  hideNullLineTypes,
  parseTransactions,
  saleUsedForForecast,
  setupChartLines,
} from '../../../../../../../lib/charts/saleChartsLib';
import SaleChart from '../../../../../../../components/global/SaleChart/SaleChart';

interface SkuSaleChartProps {
  rawData: SaleMonthlyOverviewResponse[];
  applicationDate: Date;
  cumulativeMode: boolean;
  vat?: number;
  width?: number;
  height?: number;
}

const SkuSaleChart: React.FC<SkuSaleChartProps> = ({
  rawData,
  applicationDate,
  width,
  height,
  cumulativeMode,
  vat = 0,
}) => {
  const tmpSaleTypes = useMemo(
    () => addSalesUsedForForecastToSaleTypes(saleUsedForForecast, getSaleLineDefinitions()),
    [],
  );
  const forecastTypes: TLineType[] = useMemo(() => getForecastLineDefinitions(), []);

  /**   monthlyTransactionsWithSellout   */
  const parsedMonthlyTransactions: TParsedTransactions[] = useMemo(
    () =>
      copyLineTypeToParsedMonthlyTransactionsByAnotherLineType(
        parseTransactions({
          type: 'monthly',
          transactions: rawData,
          lineTypes: tmpSaleTypes.concat(forecastTypes),
          dateToStartCalculatingCumulativeValues: applicationDate,
        }),
        saleUsedForForecast,
        'SaleSellout',
      ),
    [applicationDate, forecastTypes, rawData, tmpSaleTypes],
  );

  const chartLines = useMemo(
    () =>
      setupChartLines(
        tmpSaleTypes.concat(forecastTypes),
        parsedMonthlyTransactions,
        cumulativeMode,
        applicationDate,
        'Quantity',
      ),
    [applicationDate, cumulativeMode, forecastTypes, parsedMonthlyTransactions, tmpSaleTypes],
  );

  /** tmpSaleTypesWithLineTypes */
  const saleTypes: TLineType[] = useMemo(
    () =>
      changeFillInLineTypesBasedOnHiddenLines(
        hideNullLineTypes(tmpSaleTypes, parsedMonthlyTransactions),
      ),
    [parsedMonthlyTransactions, tmpSaleTypes],
  );

  const chartData: ChartData<'line'> = useMemo(
    () =>
      createChartDataFromDatasetsAndParsedMonthlyTransactions(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        createDatasetsFromLineTypesAndGraphLines(
          saleTypes.concat(getForecastLineDefinitions()),
          chartLines,
        ) as ChartDataset<'line'>[],
        parsedMonthlyTransactions,
      ),
    [chartLines, parsedMonthlyTransactions, saleTypes],
  );

  const lines = useMemo(() => saleTypes.concat(forecastTypes), [forecastTypes, saleTypes]);

  return (
    <SaleChart
      chartData={chartData}
      width={width}
      height={height}
      currentDate={applicationDate}
      transactions={parsedMonthlyTransactions}
      lines={lines}
      animate={true}
      vat={vat}
      valueColumnType="avg"
    />
  );
};

export default SkuSaleChart;
