import { styled } from 'styled-components';
import { Link } from 'react-router-dom';

const AdministrationItemLink = styled(Link)`
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: black;
  }
`;

export default AdministrationItemLink;
