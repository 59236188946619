import { useGetTemplatesQuery } from '../../../../apis/apiApplication';
import { getApiHeader } from '../../../../apis/getApiHeader';
import { API } from '../../../../swagger/collections';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { useCallback, useMemo, useState } from 'react';
import { Row } from '@ydistri/ds';
import DeleteIconButton from '../../../../components/buttons/DeleteIconButton';
import { styled } from 'styled-components';
import { MdDragIndicator } from 'react-icons/md';
import { DragHandle } from '../../../Configuration/ConfigurationStoresAndDepartments/ExceptionDefinition';
import { Reorder, useDragControls } from 'framer-motion';

const TemplateRowStyled = styled(Row)`
  width: 60rem;
  max-width: 60rem;
  flex-wrap: nowrap;
  margin-bottom: 0.5rem;
`;

const TemplateTitleWrapper = styled(Row)`
  width: 30rem;
  min-width: 30rem;
`;

interface TemplateRowProps {
  templateId: number;
  children?: React.ReactNode;
  onRemoveTemplate: (x: number) => void;
}

const TemplateRow: React.FC<TemplateRowProps> = ({ templateId, children, onRemoveTemplate }) => {
  const controls = useDragControls();
  const [grabbing, setGrabbing] = useState(false);

  const { data: templates } = useGetTemplatesQuery(getApiHeader(API, 'Project-Code') ?? skipToken);
  const template = useMemo(
    () => (templates ?? []).find(template => template.id === templateId),
    [templates, templateId],
  );

  const deleteHandler = useCallback(
    () => onRemoveTemplate(templateId),
    [templateId, onRemoveTemplate],
  );

  const onDragStart = useCallback(() => {
    setGrabbing(true);
  }, []);

  const onDragEnd = useCallback(() => {
    setGrabbing(false);
  }, []);

  const onPointerDownHandler = useCallback(
    (e: PointerEvent | React.PointerEvent) => controls.start(e),
    [controls],
  );

  if (!template) return null;

  return (
    <Reorder.Item
      as="div"
      value={templateId}
      dragListener={false}
      dragControls={controls}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <TemplateRowStyled $alignItems="center" $gap="1rem">
        <DragHandle $grabbing={grabbing} onPointerDown={onPointerDownHandler}>
          <MdDragIndicator size={20} />
        </DragHandle>
        <TemplateTitleWrapper $gap="0.5rem" $alignItems="center" $flexWrap="nowrap">
          {children}
        </TemplateTitleWrapper>
        <DeleteIconButton
          onClick={deleteHandler}
          tooltip="Remove template"
          size="xSmall"
          iconType="x"
        />
      </TemplateRowStyled>
    </Reorder.Item>
  );
};

export default TemplateRow;
