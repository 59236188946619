import { ColumnsType } from 'antd/es/table';
import { StoreDrillDownData } from '../drillDownLib';
import { formatNumber } from '@ydistri/utils';
import { YColumnsType } from '@ydistri/ds';

export const getStoreDrillDownColumns = (
  currency: string,
  storeColumns: YColumnsType<StoreDrillDownData>[],
): ColumnsType<StoreDrillDownData> => {
  return [
    ...storeColumns,
    {
      title: 'Store type',
      dataIndex: ['storeType'],
    },
    {
      title: 'Region',
      dataIndex: ['storeRegion'],
    },
    {
      title: `Revenue [${currency}]`,
      dataIndex: ['revenue'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    },
    {
      title: `Available supply [${currency}]`,
      dataIndex: ['availableSupply'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    },
    {
      title: `Source potential [${currency}]`,
      dataIndex: ['potentialSource'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    },
    {
      title: `Target potential [${currency}]`,
      dataIndex: ['potentialTarget'],
      render: (value: number) => formatNumber(value),
      align: 'right',
    },
    {
      title: `Margin`,
      dataIndex: ['margin'],
      render: (value: number) => (value ? `${Math.floor(value * 100)}%` : '-'),
      align: 'right',
    },
  ];
};
